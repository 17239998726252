import React, { useState, useEffect, useContext } from "react";
import CalendarModal from "../JobPost/ScheduleCalendar";
import Textinput from "../elements/TextInput/TextInput";
import Selectbox from "../elements/SelectBox/SelectBox";
import ButtonHallow from "../elements/Button/ButtonHallow";
import { useCommonContext } from "../../context/CommonContext";
import CryptoJS from "crypto-js";
import { AuthContext } from "../../context/AuthContext";
import './CalendarList.css';
import arrow from '../../assets/arrow-down.png';
const RefreshIcon = () => {
    return(<svg id="Layer_1" className="mr-2"height="15" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.04 122.88"><path d="M16.08,59.26A8,8,0,0,1,0,59.26a59,59,0,0,1,97.13-45V8a8,8,0,1,1,16.08,0V33.35a8,8,0,0,1-8,8L80.82,43.62a8,8,0,1,1-1.44-15.95l8-.73A43,43,0,0,0,16.08,59.26Zm22.77,19.6a8,8,0,0,1,1.44,16l-10.08.91A42.95,42.95,0,0,0,102,63.86a8,8,0,0,1,16.08,0A59,59,0,0,1,22.3,110v4.18a8,8,0,0,1-16.08,0V89.14h0a8,8,0,0,1,7.29-8l25.31-2.3Z"/></svg>
      )
}
const CalendarIcon = () => {
    return(<svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="icon-calendar mr-2"
        width="18"
        height="18"
      >
        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
        
        <line x1="3" y1="10" x2="21" y2="10"></line>
        
        <line x1="8" y1="14" x2="8" y2="14"></line>
        <line x1="12" y1="14" x2="12" y2="14"></line>
        <line x1="16" y1="14" x2="16" y2="14"></line>
        <line x1="8" y1="18" x2="8" y2="18"></line>
        <line x1="12" y1="18" x2="12" y2="18"></line>
        <line x1="16" y1="18" x2="16" y2="18"></line>
        
        <line x1="8" y1="2" x2="8" y2="6"></line>
        <line x1="16" y1="2" x2="16" y2="6"></line>
      </svg>
      )
}
const CalendarList = () => {
    const { token } = useContext(AuthContext)
    const { validationErrors } = useCommonContext();
    const WeekDay = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ]
    const [TimeZone, SetTimeZone] = useState("");
    const [jobData, setJobData] = useState([]);

    useEffect(()=>{
        const fetchAPI = async () => {
            const encryptedMetadata = localStorage.getItem('metadata');
            const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            const companyId = decryptedMetadata.cid;
            const JLData = {
                company_id: companyId,
                year: 2024,
              };
          
              const encryptedData = CryptoJS.AES.encrypt(
                JSON.stringify(JLData),
                process.env.REACT_APP_SECRETKEY
              ).toString();
              const encryptjson = { data: encryptedData };
            try {
                const serverURL = process.env.REACT_APP_SERVER_URL;
                const response = await fetch(`${serverURL}/job/getScheduleListData`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      token: token
                    },
                    body: JSON.stringify(encryptjson)
                });
                const responsedata = await response.json();
        
                const decryptedData = JSON.parse(CryptoJS.AES.decrypt(responsedata.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                // console.log(decryptedData);
                if (!response.ok) {
                    var errorMessage = "Something Went Wrong";
                    if(responsedata.error)
                        errorMessage = responsedata.error;
                    throw new Error(responsedata.error);
                }
                else{
                      // Transform data
                      const rawData = Array.isArray(decryptedData) ? decryptedData : [];
                      console.log(decryptedData);
                      setJobData(decryptedData);
                }
            } catch (error) {
                console.log('Error:', error);
                let errorMessage = "Something went wrong.";
                if (error.response && error.response.data && error.response.data.msg) {
                    errorMessage = error.response.data.msg;
                } 
                else if (error.message) {
                    errorMessage = error.message;
                }
            } finally {
            //   setIsLoadingLoader(false);
            }
        }
        fetchAPI();
    },[])


    const TimeZones = [
        {id: 0, label: "Choose Timezone"},
        {id: 1, label: "Indian Standard Time"},
        {id: 2, label: "UTC"},
    ]
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [scheduleData, setScheduleData] = useState({});
  
    const handleOpenModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  
    const handleSaveSchedule = (updatedSchedule) => {
        setScheduleData(updatedSchedule);
        setIsModalOpen(false);
      };
      const setValueChange = (e) => {
        const { id, value } = e.target;
        switch (id) {
            case "SetTimeZone":
                SetTimeZone(value);
                break;
            default:
                console.warn(`No Value Updated :${id}`);
        }
    }
    const handleTimeChange = (e, day, type) => {
        const timeValue = e.target.value;
        };

        const [expanded, setExpanded] = useState(null);

        // Get the current week's start and end dates
        const currentDate = new Date();
        const startOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
    
        // Filter jobData for current week's slots
        const filteredJobs = Object.values(jobData).filter(job => {
            return job.slots?.some(slot => {
                const slotDate = new Date(slot.created_at);
                return slotDate >= startOfWeek && slotDate <= endOfWeek;
            });
        });
    
        const toggleAccordion = (index) => {
            setExpanded(expanded === index ? null : index);
        };
    return(
        <div className={`w-full flex mobile-flex-column`}>
    {/* Weekly Hours Section */}
    <div className={`Weekly-Div mobile-full-width ${validationErrors.slots ? "!border-red-500 border-2" : ""}`}>
        <span className={`mt-4 font-bold text-nowrap flex`}><RefreshIcon /> Weekly hours</span>
        <p>Set when you are typically available for meetings</p>
        {filteredJobs.map((job, jobIndex) => (
                <div key={jobIndex} className="accordion-card">
                    {/* Accordion Header */}
                    <div
                        className="accordion-header"
                        onClick={() => toggleAccordion(jobIndex)}
                    >
                    {/* {startOfWeek.toDateString()} to {endOfWeek.toDateString()} */}
                    {job.job_title || 'No Title Available'} 
                    <img src={arrow} style={{maxHeight:'15px'}}/>
                    </div>
                    {/* Accordion Content */}
                    {expanded === jobIndex && (
                        <div className="accordion-content">
                            {job.slots?.map((slot, slotIndex) => (
                                <div key={slotIndex} className="slot-container flex mt-4">
                                    <div className="circle-div">
                                        {WeekDay[slot.day_of_week - 1]?.toLowerCase().slice(0, 3) || 'N/A'}
                                    </div>
                                    <div className="time-picker-container">
                                        <Textinput
                                            value={slot.start_time}
                                            readOnly
                                            type="time"
                                            id={`FromTime-${jobIndex}-${slotIndex}`}
                                            className="bg-gray-50 border !w-[120px] leading-none text-gray-900 text-sm rounded-lg p-2.5"
                                        />
                                        <Textinput
                                            value={slot.end_time}
                                            readOnly
                                            type="time"
                                            id={`ToTime-${jobIndex}-${slotIndex}`}
                                            className="bg-gray-50 border !w-[120px] leading-none text-gray-900 text-sm rounded-lg p-2.5"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        {/* {Object.values(jobData).map((job, jobIndex) =>\
            job.slots?.map((slot, index) => (
                <div key={`${jobIndex}-${index}`} className="circle-container">
                    <div className="circle-div"> {WeekDay?.[slot?.day_of_week]?.toLowerCase().slice(0, 3) || 'N/A'}</div>
                    <div className="time-picker-container">
                        <Textinput
                            value={slot.start_time}
                            readOnly
                            type="time"
                            id={`FromTime-${jobIndex}-${index}`}
                            className={`bg-gray-50 border !w-[120px] leading-none text-gray-900 text-sm rounded-lg p-2.5`}
                        />
                        <Textinput
                            value={slot.end_time}
                            readOnly
                            type="time"
                            id={`ToTime-${jobIndex}-${index}`}
                            className={`bg-gray-50 border !w-[120px] leading-none text-gray-900 text-sm rounded-lg p-2.5`}
                        />
                    </div>
                </div>
            ))
        )} */}
        <Selectbox
            id="SetTimeZone"
            value={TimeZone}
            onChange={(e) => setValueChange(e)}
            className={`!w-[150px] mt-4 text-emerald-600 !bg-transparent p-2 ${validationErrors.timezone ? "!border-red-500 border-2" : "!border-none"}`}
            list={TimeZones}
        />
    </div>

    {/* Date-Specific Hours Section */}
    <div className={`Weekly-Div mobile-full-width`}>
        <div className={`Date-Specific-Header flex`}>
            <div className={`w-9/12`}>
                <span className={`mt-4 font-bold text-nowrap flex`}><CalendarIcon /> Date-specific hours</span>
                <p>Adjust hours for specific days</p>
            </div>
            <ButtonHallow
                disabled={true}
                onClick={() => handleOpenModal()}
                className={`!w-[20%] mt-[25px] mb-[25px]`}
                content="+ Hours"
            />
        </div>
        <CalendarModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onSave={handleSaveSchedule}
        />
        <div className="mt-4">
            <ul className="list-disc pl-6">
                {Object.values(jobData).map((job, jobIndex) =>
                    job.date_overrides?.map((override, index) => (
                        <li key={`${jobIndex}-${index}`} className="mb-1">
                            {override.date}: {override.start_time} - {override.end_time}
                        </li>
                    ))
                )}
            </ul>
        </div>
    </div>
</div>

    )
}
export default CalendarList;