import React, { useContext, useEffect, useState } from "react"
import CryptoJS from "crypto-js";
import { AuthContext } from "../../context/AuthContext";
import ButtonSolid from "../elements/Button/ButtonSolid";
import ButtonHallow from "../elements/Button/ButtonHallow";
import Notification from "../Notification/Notification";
import ShareJobModal from "../../components/Team/ShareJob";
export default function Openjobs () {
    const { token } = useContext(AuthContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [openJobs, setOpenJobs] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalJobs, setTotalJobs] = useState(0);
    const [fetchedJobcount, setfetchedJobcount] = useState(0);
    const [notification, setNotification] = useState({ message: "", type: "" });
    const showNotification = (message, type) => {
        setNotification({ message, type });
        setTimeout(() => {
          setNotification({message: "", type: ""});
        }, 3000);
    }
    const fetchJobList = async () => {
        let loc = [];
        let created_by = [];
        let job_status = ["active"];
        const page_start = (currentPage - 1) * 10; 
        const offset = 10;
        const encryptedMetadata = localStorage.getItem('metadata');
        const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
    
        const JLData = {
          company_id: decryptedMetadata.cid,
          page_start: page_start,
          offset:offset, loc:loc, created_by:created_by, job_status:job_status
        };
    
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(JLData),
          process.env.REACT_APP_SECRETKEY
        ).toString();
        const encryptjson = { data: encryptedData };
        // setIsLoadingLoader(true);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/job/fetchJobListing`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                token: token,
              },
              body: JSON.stringify(encryptjson),
            }
          );
    
          const status = await response.json();
          const JobListData = JSON.parse(CryptoJS.AES.decrypt(status.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
          console.log(JobListData)
          const openJobDetails = JobListData.jobs.map((job) => {
            return {
              id: job.job_id,
              title: job.title,
              location: job.location,
              WorkplaceType: job.workplace_type,
              JobType: job.job_type,
              Department: job.department,
              JobDescription: job.job_desc,
              jobDescId: job.jd_id,
              interview_duration: job.interview_duration,
            };
          });
          setOpenJobs(openJobDetails);
          const totalJobs = JobListData.totalCount;
          setfetchedJobcount(openJobDetails.length);
          setTotalJobs(totalJobs);
          setTotalPages(Math.ceil(totalJobs / offset));
        } catch (err) {
          console.error("Error Fetching Job List:", err);
        }finally {
            // setIsLoadingLoader(false);
        }
      };
      const handleNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
        setTimeout(() => window.scrollTo(0, 0), 0);
      };
    
      const handlePreviousPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
      };
      useEffect(()=>{
        fetchJobList();
      },[])
      const handlesharelink = (id) => {
        const url = `${window.location.origin}/viewBooking/${id}`;
        navigator.clipboard.writeText(url);
        showNotification("Link Copied to Clipboard", "success");
      }
          const [showShareJobModal, setShowShareJobModal] = useState(false);
        const [shareProject, setshareProject] = useState(null); // Holds the selected project data
      
        const handleSharedJobLists = (project) => {
          setshareProject(project); // Store the selected project
          setShowShareJobModal(true);
        };
        const closeSharejobModal = () => {
          setShowShareJobModal(false);
        };
    return (
        <div className="ml-[5%] mt-8 py-4 px-4 rounded-xl bg-white bg-opacity-50">
                {showShareJobModal && (
                  <ShareJobModal
                    project={shareProject}
                    closeSharejobModal={closeSharejobModal}
                  />
                )}

            <Notification
                message={notification.message}
                type={notification.type}
                onClose={() => setNotification({ message: "", type: "" })}
            />
            <h4 className="text-xl font-semibold mb-0">Open Jobs </h4>
            <span className={`font-dark mb-4`}>(Showing {fetchedJobcount} of {totalJobs} Jobs)</span>
            <div className="grid grid-cols-2 gap-4">
                {openJobs.map((value, index)=>(
                    <div key={index} className="p-[30px] flex flex-col gap-[32px] bg-white" style={{
                            borderRadius: '20px',
                            boxShadow: "0px 4px 40px 0px #0000000A"
                        }}>
                        <div>
                            <p className="m-0 plusJakartaSansFont font-bold text-2xl text-[#1D1D1D]">{value.title}</p>
                            <div className="flex items-center gap-[5px]">
                                <span className="text-[#898989] text-base font-medium plusJakartaSansFont">{value.interview_duration || "30 Mins"}</span>
                                <span className="w-[6px] h-[6px] rounded-full bg-[#898989] mt-1"></span>
                                <span className="text-[#898989] text-base font-medium plusJakartaSansFont">One-on-one</span>
                            </div>
                            <p className="m-0 plusJakartaSansFont text-base font-medium text-[#059669]">View booking page</p>
                        </div>
                        <div className="flex items-center gap-[16px]">
                            <ButtonHallow 
                              className="text-[#059669] border-2 border-[#059669] text-base font-medium plusJakartaSansFont text-center flex items-center !w-auto" 
                              style={{ borderRadius: '8px', padding: '16px', height: '40px' }} 
                              onClick={()=> handlesharelink(value.id)}
                              content="Copy Link" />
                            <ButtonSolid 
                              className="bg-[#059669] text-white text-base font-medium plusJakartaSansFont flex items-center !w-auto" 
                              style={{ borderRadius: '8px', padding: '16px', height: '40px' }} 
                              onClick={()=> handleSharedJobLists(value)}
                              content="Share" />
                            <div className="flex gap-1 items-start">
                                <span className="w-[3px] h-[3px] rounded-full bg-[#898989] mt-1"></span>
                                <span className="w-[3px] h-[3px] rounded-full bg-[#898989] mt-1"></span>
                                <span className="w-[3px] h-[3px] rounded-full bg-[#898989] mt-1"></span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

          <div className={`flex w-11/12 mt-8 mb-8`} style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            <div className="flex" style={{ alignItems: 'center' }}>
              <ButtonSolid
                disabled={currentPage === 1}
                onClick={handlePreviousPage}
                style={{ padding: '10px 30px', marginRight: '10px' }}
                className={`overflow-visible mt-0 border rounded-l-md ${currentPage === 1 ? '!bg-gray-200 hover:bg-gray-300' : ''}`}
                content="Previous"
              />
              <span className="text-nowrap px-4 py-2">
                Page {currentPage} of {totalPages}
              </span>
              <ButtonSolid
                disabled={currentPage === totalPages}
                onClick={handleNextPage}
                style={{ padding: '10px 30px', marginLeft: '10px' }}
                className={`overflow-visible mt-0 border rounded-r-md ${currentPage === totalPages ? '!bg-gray-200 hover:bg-gray-300' : ''}`}
                content="Next"
              />
            </div>
          </div>
        </div>
    )
}