// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-radio-input {
    border: 0.75px solid #059669 !important;
}
input[type='radio']:after {
    border: 0.75px solid #059669 !important;
}
input[type='radio'] {
    appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 100px;
    accent-color: #059669;
}

input[type="radio"]:checked {
    background-color: #059669;
    padding: 5px;
  }
  input[type='checkbox'] {
    accent-color: #059669;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/RadioInput/RadioInput.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;AAC3C;AACA;IACI,uCAAuC;AAC3C;AACA;IAGI,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,oBAAoB;IACpB,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;IACzB,YAAY;EACd;EACA;IACE,qBAAqB;AACzB","sourcesContent":[".custom-radio-input {\n    border: 0.75px solid #059669 !important;\n}\ninput[type='radio']:after {\n    border: 0.75px solid #059669 !important;\n}\ninput[type='radio'] {\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    appearance: none;\n    height: 15px;\n    width: 15px;\n    border-radius: 100px;\n    accent-color: #059669;\n}\n\ninput[type=\"radio\"]:checked {\n    background-color: #059669;\n    padding: 5px;\n  }\n  input[type='checkbox'] {\n    accent-color: #059669;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
