import React,{useEffect, useState,useContext, useRef} from "react";
import axios from 'axios';
import Textinput from "../elements/TextInput/TextInput";
import Button from "../elements/Button/Button";
import { AuthContext } from "../../context/AuthContext";
import CryptoJS from "crypto-js";
import ClipLoader from "react-spinners/ClipLoader";
import Swal from 'sweetalert2';
import { useCommonContext } from "../../context/CommonContext";
// import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Triage = () => {
    const { section, setSection } = useCommonContext();
    const [isChecked, setIsChecked] = useState(false);
    var [resumeFiles, setResumeFiles] = useState([]);
    const [viewTrue,setViewTrue]=useState("true");
    const [viewFalse,setViewFalse]=useState("false");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [downloadBtnURL,setDownlaodUrl]=useState('');
    const [fileUrl, setFileUrl] = useState(null); 
    const dropdownRef=useRef(null);
    const serverURL = process.env.REACT_APP_SERVER_URL;
    const triageLocal="https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.modify&response_type=code&client_id=1084365103149-udnjg4dcl59d73uv5butfqeh3d8gb37v.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Ftriage"
    const betaeeezo="https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.modify&response_type=code&client_id=1084365103149-udnjg4dcl59d73uv5butfqeh3d8gb37v.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fbeta.eeezo.com%2Ftriage"
    const [currentEmails, setCurrentEmails] = useState([]);
    const [triageResult, setTriageResult] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const [isActionOpen, setIsActionOpen] = useState(false);
    const [synchedMail,setSynchedMail]=useState('');
    const [lastSync,setLastSync]=useState('');
    const [deleteArray,setDeleteArray]=useState([]);
    const navigate = useNavigate();
    const currentDetails = [
        {Sender:"aravind@gmail.com", Subject:"Subject 1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i", Classification:"Classification 1", Sentiment:"Sentiment 1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i", Action:"Action 1"},
        {Sender:"arun@gmail.com", Subject:"Subject 2", Classification:"Classification 2", Sentiment:"Sentiment 2", Action:"Action 2"},
        {Sender:"rakesh@gmail.com", Subject:"Subject 3", Classification:"Classification 3", Sentiment:"Sentiment 3", Action:"Action 3"},
        {Sender:"prakash@gmail.com", Subject:"Subject 4", Classification:"Classification 4", Sentiment:"Sentiment 4", Action:"Action 4"},
    ]
    

    const emailSync=async()=>{
                    const response = await fetch(`${serverURL}/Triage/triageAuth`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          token: token
                        },
                    });
                    const result = await response.json();
                    console.log("Server Response:", result);
                    if (!response.ok) {
                        throw new Error(result.error);
                    }
                    const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                    console.log("Decrypted Data:", decryptedData);
    }
    // useEffect(() => { emailSync() }, []);

    const handleActionDropDown = (index) => {
        setIsActionOpen((prev)=>prev===index?false:index);
    };
    const handleDeleteEmail = async(mid) => {
        setIsActionOpen(false)
        console.log("list of Deletion: ",mid);
        if(!mid.length){
            return;
        }
        const userConfirmed = await Swal.fire({
                 icon: "warning",
                title: `Are you sure to Delete?`,
                text: "",
                confirmButtonText: "OK",
                 showCancelButton: true,
            });
                                                   
                 if (!userConfirmed.isConfirmed) {
                        console.log("User cancelled Mail Send.");
                        return;
                     }
        try{
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(mid), process.env.REACT_APP_SECRETKEY).toString();
            const response = await fetch(`${serverURL}/Triage/deleteTriageEmails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    token: token
                },
                body: JSON.stringify({data: encryptedData}),
            });
            const result = await response.json();
            console.log("Server Response delete:", result);
            if (response.ok) {
                setDeleteArray([])
                getTriageEmails();
            }
            // if (!response.ok) {
            //     throw new Error(response.error);
            // }
        }
        catch (error) {
            console.error("Error uploading file:", error);
        }
        finally {
            // setIsLoading(false);
            getTriageEmails();
            Swal.fire({
                icon: "success",
               title: `Deleted Successfully?`,
               text: "",
               confirmButtonText: "OK",
                showCancelButton: true,
           });
        }
    };


    useState(() => {setCurrentEmails(currentDetails)}, [currentEmails]);
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get("code");
        
        if (code) {
            console.log("Authorization Code:", code);
            exchangeCodeForTriageTokens(code);
            window.history.replaceState({}, document.title, window.location.pathname);
        }
        if(!code){
        getTriageEmails(section);
      }
    }, []);

    const setSynchedValues=async(userID,email,syncDate,autoreply)=>{
      console.log("inside set sync values");
      const syncDateData={userId:userID,email_id:email,last_sync:syncDate,auto_reply:autoreply?autoreply:""}; 
      try{
        console.log("Fetching sync details...");
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(syncDateData), process.env.REACT_APP_SECRETKEY).toString();
        const response = await fetch(`${serverURL}/Triage/setSyncValues`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            token: token
          },
          body: JSON.stringify({data:encryptedData}), 
      });

      const result = await response.json();
                        // console.log("Server Response:", result.data);
                        console.log("synched mail from db:", result.data.syncmail);
                        console.log("last Synched on DB: ",result.data.lastsync);
                        console.log("auto reply on DB: ",result.data.auto_reply);
       }
       catch (error) {
        console.error("Error in sync:", error);
        // setIsLoading(false);
    } 
    }
    const getSynchedDetails = async (sychedmail) => {
      var userId;
              const encryptedMetadata = localStorage.getItem('metadata');
              if (encryptedMetadata){
                  const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                  if(decryptedMetadata.cid){
                      userId = Number(decryptedMetadata.uid);
                  }
              }

        let fetchSyncDetails={userId:userId};
        try {
            console.log("Fetching getsync details...");
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(fetchSyncDetails), process.env.REACT_APP_SECRETKEY).toString();
            
            const response = await fetch(`${serverURL}/Triage/getSyncDetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    token: token
                },
                body: JSON.stringify({ data: encryptedData }),
            });
            const result = await response.json();
            console.log("Server Response getsync:", result);
            if (!response.ok) {
                throw new Error(result.error);
            }
            
            // const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            console.log(result.data[0],'data for sync');
            if(response.ok){
              setSynchedMail(result.data[0].syncmail);
              setLastSync(result.data[0].lastsync);
              if(result.data[0].auto_reply===true){
                setIsChecked(true);
              }
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    }



    const exchangeCodeForTriageTokens = async (code) => {
      var userId;
              const encryptedMetadata = localStorage.getItem('metadata');
              if (encryptedMetadata){
                  const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                  if(decryptedMetadata.cid){
                      userId = Number(decryptedMetadata.uid);
                  }
              }
        setIsLoading(true);
        try {
            console.log("Exchanging code for tokens...");
            const response = await axios.post(`${serverURL}/Triage/getTriageTokens`, { code });

            // Decrypt the response data
            const decryptedData = JSON.parse(
                CryptoJS.AES.decrypt(response.data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8)
            );
            console.log("Decrypted Token Data:", decryptedData);

            if (decryptedData.refresh_token) {
              console.log("Refresh Token:", decryptedData.refresh_token);
                const encryptedMetadata = CryptoJS.AES.encrypt(
                    JSON.stringify(decryptedData.refresh_token),
                    process.env.REACT_APP_SECRETKEY
                ).toString();

                localStorage.setItem("refreshToken_metadata", encryptedMetadata);
            }
            

            let refresh_tokens;
            const encryptedTokenMetadata = localStorage.getItem("refreshToken_metadata");
            if (encryptedTokenMetadata) {
                const decryptedMetadata = JSON.parse(
                    CryptoJS.AES.decrypt(encryptedTokenMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8)
                );
                refresh_tokens=decryptedMetadata
                console.log("Stored Refresh Token Metadata:", decryptedMetadata);
            }
            const tokenData = {
                access_token: decryptedData.access_token,
                refresh_token: refresh_tokens,
                scope: decryptedData.scope,
                token_type: decryptedData.token_type,
                expiry_date: decryptedData.expiry_date,
                userId: userId
            };

            if(code && refresh_tokens){
              try{
                const encryptedData = CryptoJS.AES.encrypt(
                    JSON.stringify(tokenData),
                    process.env.REACT_APP_SECRETKEY
                ).toString();
            
                var triageData = { data: encryptedData };
                console.log("Encrypted Triage Data:", triageData);
                        const response = await fetch(`${serverURL}/Triage/getTriageResponse`, {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                              token: token
                            },
                            body: JSON.stringify(triageData), 
                        });
                        const result = await response.json();
                        // console.log("Server Response:", result.data);
                        console.log("synched mail:", result.data.email_id);
                        console.log("last Synched on: ",result.data.last_sync);
                        
                            let syncDate=new Date(result.data.last_sync);
                            let formattedSyncDate = syncDate.toLocaleString();
                            console.log("formatted date: ",formattedSyncDate);
                            setSynchedValues(userId,result.data.email_id,formattedSyncDate);
                            const encryptedSyncdata = CryptoJS.AES.encrypt(
                                JSON.stringify(formattedSyncDate),
                                process.env.REACT_APP_SECRETKEY
                            ).toString();
                            console.log("synched storage: ",encryptedData);
                            localStorage.setItem("synched_date", encryptedSyncdata);
                            setSynchedMail(result.data.email_id);
                        
                        if (!(result.data.result).length) {
                            console.log("from synching process...")
                            Swal.fire({
                                        icon:'warning',
                                        title: `No New Mails Found!`,
                                        text: "",
                                        confirmButtonText: "OK",
                                        showCancelButton: true,
                                  });
                                  await getSynchedDetails();
                                  getTriageEmails();
                                  console.log("isChecking...<<__") 
                                  throw new Error(result.error);
                        }
                        if (result.data.email_id) {
                              const encryptedMetadata = CryptoJS.AES.encrypt(
                                  JSON.stringify(result.data.email_id),
                                  process.env.REACT_APP_SECRETKEY
                              ).toString();
              
                              localStorage.setItem("synched_email_id", encryptedMetadata);
                          }
                        

                          
                        
                        if(response.ok){
                            
                            setTriageResult(result.data.result);
                        }
                          
                        // const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                        // console.log(decryptedData,'data for triage send')

              }
              catch (error) {
                console.error("Error uploading file:", error);
                setIsLoading(false);
            }  
            }
        } catch (err) {
            console.error("Error exchanging tokens:", err);
        }
        finally {
            setIsLoading(false);
            getTriageEmails();
        }
    };

    
    const setRepliedTrue = async(mid,value) =>{
        
            try{
    
                const replied={mid:mid,reply:value}
                console.log("replied:",replied);
                const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(replied), process.env.REACT_APP_SECRETKEY).toString();
                const serverURL = process.env.REACT_APP_SERVER_URL;
                const response = await fetch(
                    `${serverURL}/Triage/setReplied`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        token: token,
                      },
                      body: JSON.stringify({data:encryptedData}),
                    }
                  );
              
                  const data = await response.json();
                  console.log(data,"response data");
    
            }
            catch(error){
                console.error("Error occurred:", error);
            }
    
        }

    const autoReplyMail = async (mid,synched,sender,subjectReply,reply,jobid,candidateid ) => {
        console.log("inside auto-reply mail>>>>>")
        try {
            console.log("Sending auto-reply mail...");
            const MailData = {mailSender:synched,mailReciever:sender,mailSubject:subjectReply,mailContent:reply,job_id:jobid,db_cand_id:candidateid};
            console.log("Mail Data:", MailData);
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(MailData), process.env.REACT_APP_SECRETKEY).toString();
            const response = await fetch(`${serverURL}/Triage/setSmartReply`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    token: token
                },
                body: JSON.stringify({ data: encryptedData }),
            });
            const result = await response.json();
            console.log("Mail Response:", result);
            if (!response.ok) {
                throw new Error(result.error);
            }
            setRepliedTrue(mid,true);
        }
        catch (error) {
            console.error("Error uploading file:", error);
        }
      }



    const autoReply = async () => {
      console.log("Inside auto-reply");
      try {
        for (const email of Object.values(triageResult)) {
          console.log("autoreply ----")
          if (email["sentiment"] !== "Unhappy" && email["section"]==="eeezo" && email["is_replied"]===false) {
            const encryptedData = CryptoJS.AES.encrypt(
              JSON.stringify(email["candidate_email"]),
              process.env.REACT_APP_SECRETKEY
            ).toString();
    
            const response = await fetch(`${serverURL}/Triage/getAiTemplates`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                token: token,
              },
              body: JSON.stringify({ data: encryptedData }),
            });
    
            const result = await response.json();
            console.log("Server Response:", result);
            if(email["is_replied"]===false){
            autoReplyMail(email["mid"],synchedMail,email["sender"],result.data.subjectReply,result.data.reply,email["job_id"],email["candidate_id"] );
            }
            if (!response.ok) {
              throw new Error(result.error || "Failed to set smart reply");
            }
          }
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    };
    const getTriageEmails = async (section) => {
      console.log("inside get mail>>>>>")
    var userId;
            const encryptedMetadata = localStorage.getItem('metadata');
            if (encryptedMetadata){
                const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                if(decryptedMetadata.cid){
                    userId = Number(decryptedMetadata.uid);
                }
            }
          let fetchTriageEmails;
          
              fetchTriageEmails={
                  userID:userId,
                  section:section?section:""
              }
          

      try {
          console.log("Fetching Triage Emails...");
          const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(fetchTriageEmails?fetchTriageEmails:""), process.env.REACT_APP_SECRETKEY).toString();
          
          const response = await fetch(`${serverURL}/Triage/getTriageEmails`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  token: token
              },
              body: JSON.stringify({ data: encryptedData }),
          });
          const result = await response.json();
          console.log("Server Response:", result);
          if (!response.ok) {
              // getTriageEmails()
              setTriageResult([]);
          //     Swal.fire({
          //         icon:'warning',
          //         title: `No New Mails Found!`,
          //         text: "",
          //         confirmButtonText: "OK",
          //         showCancelButton: true,
          //   });
              throw new Error(result.error);
              
          }
          
          const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
          console.log(decryptedData,'data for triage recieve')
          
            setTriageResult(decryptedData);
            if(isChecked){
              console.log("inside ischecked")
              autoReply();
            } 
        
        let mailData="";
                      const encryptedMaildata = localStorage.getItem("synched_email_id");
                        if (encryptedMaildata) {
                            const decryptedMaildata = JSON.parse(
                                CryptoJS.AES.decrypt(encryptedMaildata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8)
                            );
                            mailData=decryptedMaildata;
                            console.log("Stored email_id that synched:", mailData);
                            
                        }
                        // setSynchedMail(mailData);
                        getSynchedDetails(mailData);


        let syncData=""
                        const encryptedSyncdata = localStorage.getItem("synched_date");
                        if (encryptedSyncdata) {
                            const decryptedSyncdata = JSON.parse(
                                CryptoJS.AES.decrypt(encryptedSyncdata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8)
                            );
                            syncData=decryptedSyncdata;
                            console.log("Stored last synched:", syncData);
                            
                        }
                        setLastSync(syncData)
                        setSection(null)
        
      } catch (error) {
          console.error("Error uploading file:", error);
      }
  }
    
    // if(section){
    //     getTriageEmails(section);
    //     console.log("<<<<>>>>",section);
    // }

    const handleSmartReply = (id,subjectval,fromMail,toMail,jobID,candidateID) => {
        navigate("/ViewMessage", { state: { mid:id ,subject:subjectval,fromMail:fromMail,toMail:toMail,jobID:jobID,candidateID:candidateID} });
    }
    const handleView = async(mid,viewstatus,sender,sentiment,content,subject,synchedOne,job_id,candidate_id,candidateMail,parentMail) => {
        let changeViewStatus;
            
        changeViewStatus={
                    mid:mid,
                    viewstatus:viewstatus
                }

            try{
                console.log("changing read status...");
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(changeViewStatus), process.env.REACT_APP_SECRETKEY).toString();
            
            const response = await fetch(`${serverURL}/Triage/setReadStatus`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    token: token
                },
                body: JSON.stringify({ data: encryptedData }),
            });
            const result = await response.json();
            console.log("Server Response:", result);
            if(viewstatus==="false"){
                getTriageEmails();
                setIsActionOpen(false)
            }
            }
            catch (error) {
                console.error("Error uploading file:", error);
            }
        if(viewstatus==="true"){
            navigate("/EmailView", { state: {viewMID:mid, sender:sender,sentiment:sentiment,content:content,subjectView:subject,viewsynchedOne:synchedOne,view_jid:job_id,view_cid:candidate_id ,view_cmail:candidateMail,view_pmail:parentMail} });
        }
    }
    // let currentArray = [];

    const handleCheckbox = (e) => {
        const checkboxId = e.target.id;
    
        setDeleteArray((prevArray) => {
            let updatedArray;
            if (e.target.checked) {
                console.log("Checked, id: ", checkboxId);
                updatedArray = [...prevArray, checkboxId]; // Add the checkboxId
            } else {
                console.log("Unchecked, id: ", checkboxId);
                updatedArray = prevArray.filter((id) => id !== checkboxId); // Remove the checkboxId
            }
            console.log("Updated array: ", updatedArray);
            return updatedArray; // Return the updated array to update the state
        });
    };
    const pdfIcon=(<svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 500 511.56"><path fill-rule="nonzero" d="M117.91 0h201.68c3.93 0 7.44 1.83 9.72 4.67l114.28 123.67c2.21 2.37 3.27 5.4 3.27 8.41l.06 310c0 35.43-29.4 64.81-64.8 64.81H117.91c-35.57 0-64.81-29.24-64.81-64.81V64.8C53.1 29.13 82.23 0 117.91 0zM325.5 37.15v52.94c2.4 31.34 23.57 42.99 52.93 43.5l36.16-.04-89.09-96.4zm96.5 121.3l-43.77-.04c-42.59-.68-74.12-21.97-77.54-66.54l-.09-66.95H117.91c-21.93 0-39.89 17.96-39.89 39.88v381.95c0 21.82 18.07 39.89 39.89 39.89h264.21c21.71 0 39.88-18.15 39.88-39.89v-288.3z"/><path fill="red" d="M28.04 194.61h443.92c15.43 0 28.04 12.63 28.04 28.04v188.54c0 15.4-12.63 28.04-28.04 28.04H28.04C12.64 439.23 0 426.61 0 411.19V222.65c0-15.43 12.62-28.04 28.04-28.04z"/><path fill="#fff" fill-rule="nonzero" d="M150.36 348.17H125.2v29.21H86.5V256.45h60.95c27.74 0 41.6 14.9 41.6 44.7 0 16.38-3.61 28.51-10.83 36.37-2.71 2.97-6.45 5.49-11.22 7.55-4.78 2.07-10.32 3.1-16.64 3.1zm-25.16-60.76v29.8h8.9c4.65 0 8.03-.49 10.16-1.45 2.13-.97 3.19-3.2 3.19-6.68v-13.54c0-3.49-1.06-5.71-3.19-6.68-2.13-.97-5.51-1.45-10.16-1.45h-8.9zm79.82 89.97V256.45h54.17c21.8 0 36.77 4.65 44.89 13.93 8.13 9.29 12.19 24.8 12.19 46.54 0 21.73-4.06 37.24-12.19 46.53-8.12 9.29-23.09 13.93-44.89 13.93h-54.17zm54.75-89.97h-16.06v59.02h16.06c5.29 0 9.13-.62 11.52-1.84 2.38-1.23 3.58-4.03 3.58-8.42v-38.5c0-4.39-1.2-7.2-3.58-8.42-2.39-1.23-6.23-1.84-11.52-1.84zm145.99 45.08h-32.89v44.89h-38.7V256.45h79.33l-4.84 30.96h-35.79v16.25h32.89v28.83z"/></svg>);
    const docIcon=(<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className="w-5 h-5" viewBox="0 0 50 50">
        <path d="M 7 2 L 7 48 L 43 48 L 43 14.59375 L 42.71875 14.28125 L 30.71875 2.28125 L 30.40625 2 Z M 9 4 L 29 4 L 29 16 L 41 16 L 41 46 L 9 46 Z M 31 5.4375 L 39.5625 14 L 31 14 Z M 15 22 L 15 24 L 35 24 L 35 22 Z M 15 28 L 15 30 L 31 30 L 31 28 Z M 15 34 L 15 36 L 35 36 L 35 34 Z"></path>
        </svg>)
    // const handleIconClick =  (index) => {
    //     console.log("Clicked icon value: ", index);
    
    //     try {
    //         // Construct the file URL to directly open it
    //         const fileURL = `http://127.0.0.1:5000/attachments/${index}`;
    
    //         // Open the file in a new tab
    //         window.open(fileURL, "_blank");
    
    //         console.log("File opened in new tab successfully.");
    //     } catch (error) {
    //         console.error("Error during API call:", error);
    //     }
    // };


    const handleIconClick = (index) => {
        console.log("Clicked icon value: ", index);
    
        // Construct the file URL
        const fileURL = `https://triage.aixiomatic.com/triage/attachments/${index}`;
        const downloadURL = `https://triage.aixiomatic.com/triage/download/${index}`;
        setFileUrl(fileURL);
        setDownlaodUrl(downloadURL)
        setIsModalOpen(true);
        setFileName(index);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
        setFileUrl(null);
        setDownlaodUrl(null);
        setFileName(null);
      };
    
    
    const handleClickOutside = (event) => {
        if ((isActionOpen && dropdownRef.current && !dropdownRef.current.contains(event.target))) {
           console.log("inside handle------>>>>>>")
          setIsActionOpen(false);
        }
        
    };
    useEffect(() => {
              document.addEventListener("mousedown", handleClickOutside);
              return () => {
                  document.removeEventListener("mousedown", handleClickOutside);
              };
          }, [isActionOpen]);


          const [tooltipIndex, setTooltipIndex] = useState(null);
          const [tooltipIndexMail, setTooltipIndexMail] = useState(null);

          const handleMouseEnter = (index) => {
            setTooltipIndex(index);
          };
        
          const handleMouseLeave = () => {
            setTooltipIndex(null);
          };


          const handleMouseEnterMail = (index) => {
            setTooltipIndexMail(index);
          };
        
          const handleMouseLeaveMail = () => {
            setTooltipIndexMail(null);
          };

          

          const handleCheckboxChange = async (event) => {
            var userId;
              const encryptedMetadata = localStorage.getItem('metadata');
              if (encryptedMetadata){
                  const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                  if(decryptedMetadata.cid){
                      userId = Number(decryptedMetadata.uid);
                  }
              }
            if (event.target.checked) {
              // Show Swal confirmation dialog
              const result = await Swal.fire({
                title: "Do you want to enable auto-reply?",
                text: "Emails Except unhappy replies will be replied automatically",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, enable it!",
                cancelButtonText: "No, cancel!",
              });
        
              if (!result.isConfirmed) {
                // User clicked cancel, set the checkbox to "off"
                setIsChecked(false);
                setSynchedValues(userId,synchedMail,lastSync,false);
              }
              if (result.isConfirmed) {
                // User clicked cancel, set the checkbox to "off"
                setIsChecked(true);
                setSynchedValues(userId,synchedMail,lastSync,true);
                autoReply();
              }
            } else {
              // If unchecked, allow the checkbox to be off
              setIsChecked(false);
              setSynchedValues(userId,synchedMail,lastSync,false);
            }
          };

          const handleResumeImport = async (filepath) => {
            let cid;
            let userId;

            const result = await Swal.fire({
              title: "Do you want to import This Resume?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, import it!",
              cancelButtonText: "No, cancel!",
            });
      
            if (!result.isConfirmed) {
              // User clicked cancel, set the checkbox to "off"
              return;
            }
        
            // Retrieve metadata from localStorage
            const encryptedMetadata = localStorage.getItem("metadata");
            if (encryptedMetadata) {
                const decryptedMetadata = JSON.parse(
                    CryptoJS.AES.decrypt(
                        encryptedMetadata,
                        process.env.REACT_APP_SECRETKEY
                    ).toString(CryptoJS.enc.Utf8)
                );
                if (decryptedMetadata.cid) {
                    console.log(decryptedMetadata.cid);
                    cid = decryptedMetadata.cid;
                    userId = decryptedMetadata.uid;
                }
            }
        
            const formData = new FormData();
        
            
            const fileURL = filepath;
        
            try {
                
                const fileResponse = await fetch(fileURL);
                if (!fileResponse.ok) {
                    throw new Error(`Failed to fetch the file from ${fileURL}`);
                }
        
                
                const blob = await fileResponse.blob();
                const fileNameSet = fileName; // Give the file a meaningful name
                const file = new File([blob], fileNameSet, { type: blob.type });
        
                
                formData.append("file", file);
        
                formData.append("userId", userId);
                formData.append("cid", cid);
        
                setIsLoading(true);
        
                
                const serverURL = process.env.REACT_APP_SERVER_URL;
                const response = await fetch(`${serverURL}/company/uploadResume`, {
                    method: "POST",
                    headers: {
                        token: token,
                    },
                    body: formData,
                });
        
                const result = await response.json();
                console.log("Server Response:", result);
        
                if (!response.ok) {
                    const errorMessage = result.error?.message || JSON.stringify(result.error);
                    throw new Error(errorMessage);
                }
        
                // Success notification or further actions
                console.log("File uploaded successfully!");
            } catch (error) {
                console.error("Error uploading file:", error);
                // Optionally show a notification
                // showNotification(error.message, "error");
            } finally {
                // setIsLoading(false); // Hide loader in all cases
                const docClose = await Swal.fire({
                  title: "Resume Imported Successfully!",
                  icon: "success",
                  showCancelButton: true,
                  confirmButtonText: "ok",
                  cancelButtonText: "cancel",
                });
          
                if (docClose) {
                  setIsModalOpen(false);
                  return;
                }
            }
        };
        
    return (
        <>
        {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        <span className="text-white mt-4">Fetching...</span>
                    </div>
                </div>
            )}
        <div>
        {isModalOpen && (
        <div
          className={`fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-40`}
        >
          <div className={`bg-white rounded-lg shadow-lg relative p-6 max-w-3xl w-full`}>
           
            <div className={`flex justify-between items-center border-b pb-2 mb-4`}>
              <h2 className="text-lg font-semibold">Document Viewer</h2>
              <button
                onClick={closeModal}
                className="text-gray-400 hover:text-gray-600"
              >
                ✕
              </button>
            </div>

           
            {fileUrl ? (
              <iframe
                src={fileUrl}
                className="w-full h-[420px] border rounded"
                title="File Viewer"
              ></iframe>
            ) : (
              <p>No file to display</p>
            )}

            
            <div className={`flex justify-end mt-4`}>
              <a
                href={downloadBtnURL}
                download
                className="bg-[#059669] text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 no-underline"
              >
                Download File
              </a>
              <button className="bg-[#059669] text-white px-4 py-2 rounded-lg shadow ml-2 hover:bg-green-600" onClick={()=>handleResumeImport(fileUrl)}>
                Import To ResumeBox
              </button>
              <button
                onClick={closeModal}
                className="bg-gray-500 text-white px-4 py-2 rounded-lg shadow ml-2 hover:bg-gray-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
            <div className="w-full mt-4 h-auto mr-1">    
              <div className="w-full mb-4 flex-col flex justify-between">
              
                <div>
                    
                <label className="inline-flex items-center cursor-pointer">
                    <span className="text-sm font-medium text-gray-900 dark:text-gray-300 mr-2">Auto Reply</span>
                    <input
                        id="autoreply"
                        type="checkbox"
                        value=""
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className="sr-only peer"
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#059669] dark:peer-checked:bg-blue-600"></div>
                    </label>

                </div>
                <div className="mb-2 flex">
                <p className="text-black mb-0 font-medium">Synched Mail: <span className="text-sm ml-2 text-gray-500">{synchedMail?synchedMail:"No Data"}</span></p>
                {!section &&( <a href={betaeeezo} className="float-right ml-auto mr-2 no-underline text-green-700">Sync Emails</a>)}
                </div>
                <div className="flex">
                <p className="text-black mb-0 font-medium">Last Synched On: <span className="text-sm ml-2 text-gray-500">{lastSync?lastSync:"No Data"}</span></p>
                <p className="float-right ml-auto mr-2 text-red-500 no-underline hover:cursor-pointer mb-0" onClick={()=>handleDeleteEmail(deleteArray)}>Delete Selected</p>
                </div>
                
              </div> 
              <div className="overflow-x-auto w-12/12">
                <table className="table-auto overflow-x-auto w-full">
                    <thead className="">
                        <tr>
                        <th className="pr-1 pt-4 pb-4">
                            <Textinput type="checkbox" className="!w-4 !h-4" labelclass="hidden" divclass="w-3"/>
                        </th>
                        <th className="px-4 py-2">Sender</th>
        <th className="px-4 py-2">Subject</th>
        <th className="px-4 py-2">Classification</th>
        <th className="px-4 py-2">Document</th>
        <th className="px-4 py-2">Sentiment</th>
        <th className="px-4 py-2 text-center">Action</th>

                        </tr>
                    </thead>
                    <tbody className="mt-4 ">
                    {
  Object.values(triageResult).map((email, index) => (
    <tr className={`rounded-xl ${tooltipIndex===index || tooltipIndexMail===index ? "relative":""} ${index % 2 === 0 && email["view_status"]!==true ? "bg-white" : ""} ${email["view_status"]===true?"!bg-gray-200":""}`} key={index}>
      <td className="pr-2 pl-1 pt-2 pb-2 w-1/12">
        <Textinput type="checkbox" className="!w-4 !h-4" id={email["mid"]} onChange={handleCheckbox} labelclass="hidden" divclass="w-3"/>
      </td>
      <td className="text-sm w-4/12 max-w-[200px] overflow-hidden whitespace-nowrap px-4 py-2 hover:text-[#059669]"
            onMouseEnter={() => handleMouseEnterMail(index)}
            onMouseLeave={handleMouseLeaveMail}>
              <div 
              className={`absolute left-32 hover:cursor-pointer bottom-12 z-50 w-3/12 px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-[#059669] rounded-lg shadow-sm tooltip ${tooltipIndexMail === index ? 'visible opacity-100' : 'invisible opacity-0'}`}
              role="tooltip"
            >
              {email["sender"]}
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
  <p className="truncate mb-0 hover:cursor-pointer">
    {email["sender"]} 
  </p>
  {email["is_replied"] === true && (
      <span className="text-[0.5rem] font-[500] px-2 p-1 text-white rounded-lg bg-green-700">●<span className="ml-2">Replied</span></span>
    )}
</td>

      <td 
            className="text-sm w-3/12 max-w-[200px] overflow-hidden whitespace-nowrap px-4 py-2 hover:text-[#059669]"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <div 
              className={`absolute left-72 bottom-12 z-50 w-3/12 px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-[#059669] rounded-lg shadow-sm tooltip ${tooltipIndex === index ? 'visible opacity-100' : 'invisible opacity-0'}`}
              role="tooltip"
            >
              {email["subject"]}
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
            <p className="truncate mb-0 hover:cursor-pointer">{email["subject"]}</p>
          </td>

      <td className="text-sm w-4/12 max-w-[100px] overflow-hidden whitespace-nowrap px-4 py-2">
        <p className="truncate mb-0">{email["classification"]}</p>
      </td>
      <td className="px-4 py-2 flex gap-2 mt-3">
        
        {email["attachment"] && email["attachment"].map((val)=>(
                <p className="mb-0 flex whitespace-nowrap" onClick={()=>handleIconClick(val)}>{val.split('.').pop().toLowerCase()==="pdf"?pdfIcon:docIcon}</p>
            ))}
        
      </td>
      <td className="text-sm w-4/12 max-w-[150px] overflow-hidden whitespace-nowrap px-4 py-2">
        <div className="w-10/12">
        <p className=" truncate mb-0">{email["sentiment"]}</p>
        </div>
      </td>
      <td className="w-2/12 rounded-r-xl pr-2 px-4 py-2">
        <Button className="bg-white border-[#059669] text-[#059669] rounded-lg pl-4 pr-4 pt-1 pb-1 flex" onClick={() => handleActionDropDown(index)} >
          Actions 
          <svg
            className="-mr-2 size-6 ml-4 h-6 text-gray-400"
            viewBox="0 0 20 20"
            fill="#059669"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
                        {isActionOpen===index && (  
                        <div
                          className="absolute right-6  z-10 !w-[130px] mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="menu-button"
                          tabIndex="-1"
                          ref={dropdownRef}
                        >
                          <div class="p-1 space-y-0.5">
                      <a class="flex text-center w-full text-green-700 gap-x-3.5 border-b-2 border-gray-100 py-2 px-3 hover:cursor-pointer  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" onClick={()=>handleView(email["mid"],viewTrue,email["sender"],email["sentiment"],email["content"],email["subject"],synchedMail,email["job_id"],email["candidate_id"],email["candidate_email"],email["parent_email"])}>
                        View
                      </a>
                      <a class="flex items-center gap-x-3.5 py-2 text-green-700  px-3 border-b-2 border-gray-100 hover:cursor-pointer text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" onClick={()=>handleSmartReply(email["mid"],email["subject"],synchedMail,email["sender"],email["job_id"],email["candidate_id"])}>
                        Smart Reply
                      </a>
                      {
                        email["view_status"]===true &&(
                            <a class="flex text-center w-full text-green-700 gap-x-3.5 border-b-2 border-gray-100 py-2 px-3 hover:cursor-pointer  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" onClick={()=>handleView(email["mid"],viewFalse,email["sender"],email["sentiment"],email["content"],email["subject"],synchedMail,email["job_id"],email["candidate_id"],email["candidate_email"],email["parent_email"])}>
                            Mark Unread
                          </a> 
                        )
                      }
                      <a class="flex items-center  py-2 px-3 text-red-500  text-sm text-gray-800 hover:cursor-pointer hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" onClick={()=>handleDeleteEmail(email["mid"])}> 
                        Delete<svg  xmlns="http://www.w3.org/2000/svg" width="1.0em" height="1.0em" viewBox="0 0 16 16" className="bi bi-trash ml-1 mt-0.5" fill="red">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                      </a>
                      
                      
                    </div>
                        </div>)}
      </td>
    </tr>
  ))
}

                    </tbody>
                </table>
                </div>
            </div>
        </div>
        </>
    );
};
export default Triage;