// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nsm7Bb-HzV7m-LgbsSe {
    border: none !important;
}
#signInButton {
    width: 100%;
}

/* new button class */

.custom-google-button {
    display: inline-flex;
    align-items: center;
    background-color: #4285f4;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-google-button:hover {
    background-color: #357ae8;
  }
  
  .custom-google-button .google-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/GmailSignIn/GmailSignIn.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;AACA;IACI,WAAW;AACf;;AAEA,qBAAqB;;AAErB;IACI,oBAAoB;IACpB,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,YAAY;IACZ,WAAW;IACX,kBAAkB;EACpB","sourcesContent":[".nsm7Bb-HzV7m-LgbsSe {\n    border: none !important;\n}\n#signInButton {\n    width: 100%;\n}\n\n/* new button class */\n\n.custom-google-button {\n    display: inline-flex;\n    align-items: center;\n    background-color: #4285f4;\n    color: white;\n    font-size: 16px;\n    font-weight: bold;\n    border: none;\n    border-radius: 4px;\n    padding: 10px 20px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .custom-google-button:hover {\n    background-color: #357ae8;\n  }\n  \n  .custom-google-button .google-icon {\n    height: 20px;\n    width: 20px;\n    margin-right: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
