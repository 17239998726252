//basic Import
import React from 'react';
import ReactDOM from 'react-dom/client';
// Import BrowserRouter
import { BrowserRouter } from 'react-router-dom'; 
import './index.css';
import './dashboard.css'

// APP js import
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';

// APP with BrowserRouter 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter> {/* Wrap App with BrowserRouter */}
      <App />
    </BrowserRouter>
  // </React.StrictMode> 
);

