import { useState, useEffect, useContext } from "react"
import CryptoJS from "crypto-js";
import ButtonSolid from "../elements/Button/ButtonSolid";
import ButtonHallow from "../elements/Button/ButtonHallow";
import ModalBox from "../elements/ModalBox/ModalBox";
import { AuthContext } from "../../context/AuthContext";
import Swal from 'sweetalert2';

export default function Meeting_Default ({email,qus,index,zone,create_at, meeting_link, job_id,status, job_desc,
    interviewer,
    date,
    time,
    attendees,
    title,name,candidate_id,startTime}) {
        const [companyName, setCompanyName] = useState("");
        useEffect(()=>{
                const encryptedMetadata = localStorage.getItem("metadata");
                const decryptedMetadata = JSON.parse(
                  CryptoJS.AES.decrypt(
                    encryptedMetadata,
                    process.env.REACT_APP_SECRETKEY
                  ).toString(CryptoJS.enc.Utf8)
                );
                setCompanyName(decryptedMetadata.company_name);
        })
    const [meeting , setMeeting] = useState(false);
    const { token } = useContext(AuthContext);
    const [meetingContent, setMeetingContent] = useState(false);
    const [content, setContent] = useState('');

    const encryptedMetadata = localStorage.getItem('metadata');
    var decryptedMetadata = ''
    useEffect(() => {
        if (encryptedMetadata){
            decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            if(decryptedMetadata && decryptedMetadata['meetings'] && decryptedMetadata['meetings'][index] && decryptedMetadata['meetings'][index]['meeting']){
                setMeeting(decryptedMetadata.meetings[index]['meeting'])
            }
            if(decryptedMetadata && decryptedMetadata['meetings'] && decryptedMetadata['meetings'][index] && decryptedMetadata['meetings'][index]['meetingContent']){
                setMeetingContent(decryptedMetadata.meetings[index]['meetingContent'])
            }
            if(decryptedMetadata && decryptedMetadata['meetings'] && decryptedMetadata['meetings'][index] && decryptedMetadata['meetings'][index]['content']){
                setContent(decryptedMetadata.meetings[index]['content'])
            }
        }
    },[])

    function setmeeting_fn(){
        setMeetingContent(true)
        decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        if(decryptedMetadata && !decryptedMetadata['meetings']){
            decryptedMetadata['meetings'] = {}
        }
        if(decryptedMetadata && decryptedMetadata['meetings'] && !decryptedMetadata['meetings'][index]){
            decryptedMetadata['meetings'][index] = {}
        }
        decryptedMetadata['meetings'][index]['meetingContent'] = true
        decryptedMetadata['meetings'][index]['content'] = content
        const encryptedUpdatedMetadata = CryptoJS.AES.encrypt(JSON.stringify(decryptedMetadata), process.env.REACT_APP_SECRETKEY).toString();
        localStorage.setItem('metadata', encryptedUpdatedMetadata);
    }

    function set_meeting(state){
        setMeeting(state)
        decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        if(decryptedMetadata && !decryptedMetadata['meetings']){
            decryptedMetadata['meetings'] = {}
        }
        if(decryptedMetadata && decryptedMetadata['meetings'] && !decryptedMetadata['meetings'][index]){
            decryptedMetadata['meetings'][index] = {}
        }
        decryptedMetadata['meetings'][index]['meeting'] = meeting
        const encryptedUpdatedMetadata = CryptoJS.AES.encrypt(JSON.stringify(decryptedMetadata), process.env.REACT_APP_SECRETKEY).toString();
        localStorage.setItem('metadata', encryptedUpdatedMetadata);
    }

    function set_textcontent(e){
        setContent(e.target.value)
    }
    const generateMeetingLink = () => {
        console.log("job_details :",job_id,job_desc, interviewer, date, time, attendees, title);
        localStorage.setItem(
            'job_create_event_details', 
            JSON.stringify({ job_id, job_desc, interviewer, date, time, attendees, title })
          );          
        window.open('https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events&prompt=consent&response_type=code&client_id=1062697773487-2jlq54gs6fj8qnjdaqr3fdi6ajnhkrhu.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fbeta.eeezo.com%2Fgmeet%3F');
    }
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalHtmlContent, setModalHtmlContent] = useState(null);
  //   {link,cand_id,name,jobTitle,companyName,interviewDate,interviewTime,timeZone,platform}

    const handleShareViaMail = async () => {
      // Replace this with your email sharing logic
      console.log('Share via mail:', meeting_link);
      setIsModalVisible(false);
	let company_id="";
	    const encryptedMetadata = localStorage.getItem("metadata");
        if (!encryptedMetadata) return;

        const decryptedMetadata = JSON.parse(
            CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8)
        );

	company_id=decryptedMetadata.cid;
      const LinkData = {
        link: meeting_link,
        cand_id: candidate_id,
        name: name,
        jobTitle: title,
        companyName: companyName,
	      company_id:company_id,
        interviewDate: date,
        interviewTime: startTime,
        timeZone: zone,
        platform: 'Google Meet',
        };
   console.log(LinkData,"LinkData") 
        const encryptedData = CryptoJS.AES.encrypt(
            JSON.stringify(LinkData),
            process.env.REACT_APP_SECRETKEY
        ).toString();
        const encryptjson = { data: encryptedData };
        try {
            const serverURL = process.env.REACT_APP_SERVER_URL;
            const response = await fetch(`${serverURL}/calendly/sendMeetLink`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                token: token
                },
                body: JSON.stringify(encryptjson)
            });
            const responsedata = await response.json();

            if (!response.ok) {
              let errorMessage = 'Something Went Wrong';
            //   if (responsedata.error) {
            //     errorMessage = responsedata.message;
            //   }
              // Show error message using SweetAlert2
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: errorMessage,
              });
              throw new Error(responsedata.error);
            }
        
            // If response is OK, show success message
            Swal.fire({
              icon: 'success',
              title: 'Meeting Link Shared',
              text: 'The meeting link has been successfully shared via email!',
            });
        
          } catch(e){
        console.log("error sending mail :",e);
      }
    };
    const handleCopyLink = () => {
      console.log('link copied')
      navigator.clipboard.writeText(meeting_link);
    };
    const openModal = () => {
      const modalContent = (
        <div className="p-4">
          <h3 className="text-lg font-bold">Share Meeting Link</h3>
          <p className="mt-2">
            Copy the meeting link below or share via email:
          </p>
          <div className="mt-4 flex">
          <input
            type="text"
            readOnly
            value={meeting_link}
            className="w-full p-2 border border-gray-300 rounded-l"
        />
        {/* <button
            onClick={handleCopyLink}
            className="bg-[#059669] text-white px-3 py-2 rounded-r flex items-center justify-center"
        >copy
        </button> */}
          </div>
          <div className="mt-4 flex justify-end space-x-4">
            <button
              onClick={handleShareViaMail}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Mail To Candidate
            </button>
            <button
              onClick={() => setIsModalVisible(false)}
              className="bg-gray-300 text-black px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      );
      setModalHtmlContent(modalContent);
      setIsModalVisible(true);
    };
    return (
            <div className="bg-white w-full my-4">
                      {isModalVisible && (
        <ModalBox
          cardClass="no-max-width JobCreationModalBodyCard"
          Modal_Body={modalHtmlContent}
          Modal_Close_Func={() => setIsModalVisible(false)}
          modal_Header_Class=""
        />
      )}
                <div className="flex flex-col gap-3">
                    <div className="flex justify-between">
                        <div className="flex flex-col gap-1">
                            <span className="font-normal text-sm">Email</span>
                            <span className="font-normal text-sm text-[#747474]">{email ? email : ''}</span>
                        </div>
                        <div className="flex items-start justify-end gap-3">
                           {status === 'upcoming' &&  ((meeting_link !== '' && meeting_link !== null && meeting_link !== undefined) ? 
                            <ButtonSolid content="Share Meeting" className="bg-[#059669] text-white mt-0" onClick={() => openModal()} />
                            :
                            <ButtonHallow content="Generate Meeting Link" className="bg-[#059669] mt-0" onClick={() => generateMeetingLink()} />)
                           }
                            {(status === 'upcoming' &&  (meeting_link !== '' && meeting_link !== null && meeting_link !== undefined)) && <button className="bg-white text-[#059669] font-medium border-1 border-[#059669] rounded-lg py-2 px-4 flex gap-2 items-center">
                                Reschedule
                                <svg width="16" height="16" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M1.02237 13C1.56713 13 2 12.5448 2 12C2 8.28751 2.89076 5.87413 4.31898 4.38381C5.73197 2.90938 7.99714 2 11.5 2C15.0029 2 17.268 2.90938 18.681 4.38381C19.3723 5.10519 19.9377 6.04284 20.3371 7.23941C20.4847 7.68145 20.8858 8 21.3518 8C22.0005 8 22.4866 7.39837 22.2908 6.7799C20.8596 2.25997 17.2627 0 11.5 0C3.83333 0 0 4 0 12C0 12.0147 1.29138e-05 12.0294 3.87415e-05 12.044C0.000980817 12.5786 0.442527 13 0.977131 13H1.02237ZM2.66286 16.7606C2.51531 16.3185 2.11425 16 1.64823 16C0.999492 16 0.513417 16.6016 0.709241 17.2201C2.14036 21.74 5.73728 24 11.5 24C19.1667 24 23 20 23 12C23 11.9853 23 11.9706 23 11.956C22.999 11.4214 22.5575 11 22.0229 11H21.9776C21.4329 11 21 11.4552 21 12C21 15.7125 20.1092 18.1259 18.681 19.6162C17.268 21.0906 15.0029 22 11.5 22C7.99714 22 5.73197 21.0906 4.31898 19.6162C3.62766 18.8948 3.06226 17.9572 2.66286 16.7606Z" fill="#059669"/> <path d="M22 1V7H16" stroke="#059669" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M1 23L0.999999 17L7 17" stroke="#059669" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                            </button>}
                            <button className="bg-white text-[#059669] font-medium border-1 border-[#059669] rounded-lg py-2 px-4 flex gap-2 items-center">
                                Cancel
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_650_16976)"> <path d="M5 9.5L6.08702 17.5362C6.31029 19.1868 6.42192 20.012 6.98722 20.506C7.55252 21 8.38532 21 10.0509 21H13.9491C15.6147 21 16.4475 21 17.0128 20.506C17.5781 20.012 17.6897 19.1868 17.913 17.5362L19 9.5" stroke="#059669" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M9 6V6C9 5.06812 9 4.60218 9.15224 4.23463C9.35523 3.74458 9.74458 3.35523 10.2346 3.15224C10.6022 3 11.0681 3 12 3V3C12.9319 3 13.3978 3 13.7654 3.15224C14.2554 3.35523 14.6448 3.74458 14.8478 4.23463C15 4.60218 15 5.06812 15 6V6" stroke="#059669" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M19 6L5 6" stroke="#059669" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </g> <path d="M9.5 10L10 17.5" stroke="#059669" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M14.5 10L14 17.5" stroke="#059669" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <defs> <clipPath id="clip0_650_16976"> <rect width="24" height="24" fill="white"/> </clipPath> </defs> </svg>
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <span className="font-normal text-sm">Invitee Time Zone</span>
                        <span className="font-normal text-sm text-[#747474]">{zone ? zone : ''}</span>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <span className="font-normal text-sm gap-1 flex items-center">
                        Questions
                        <span className="text-[#747474] text-[10px] pt-1">(Please share anything that will help prepare for our meeting)</span></span>
                        <span className="font-normal text-sm text-[#747474]">{qus ? qus : ''}</span>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                    {/* {meeting&&!meetingContent&&(
                        <div className="mt-2">
                            <span>Meeting Notes</span>
                            <textarea className="w-full h-[247px] border-1 border-[#BFE0E2] rounded-xl mt-2 p-2 outline-none" onInput={set_textcontent}></textarea>
                            <div className="flex gap-2 mt-2">
                                <button className="bg-white text-[#059669] font-medium border-1 border-[#059669] outline-none rounded-lg py-2 px-4 flex gap-2 items-center" onClick={setmeeting_fn}>update</button>
                                <button className="bg-white text-[#059669] font-medium rounded-lg py-2 px-4 outline-none flex gap-2 items-center" onClick={() => set_meeting(false)}>Cancel</button>
                            </div>
                        </div>
                    )} */}
                    {meeting&&meetingContent&&(
                        <div className="mt-2 flex flex-col">
                            <span>Meeting Notes</span>
                            <span className="mt-1 font-normal text-sm text-[#747474] w-5/6">{content}</span>
                        </div>
                    )}
                    {!meeting&&(
                        <span className="font-normal text-sm flex gap-1 items-center cursor-pointer" >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.2413 2.9915L12.366 1.86616C12.6005 1.63171 12.9184 1.5 13.25 1.5C13.5816 1.5 13.8995 1.63171 14.134 1.86616C14.3685 2.10062 14.5002 2.4186 14.5002 2.75016C14.5002 3.08173 14.3685 3.39971 14.134 3.63416L7.05467 10.7135C6.70222 11.0657 6.26758 11.3246 5.79 11.4668L4 12.0002L4.53333 10.2102C4.67552 9.73259 4.93442 9.29795 5.28667 8.9455L11.2413 2.9915ZM11.2413 2.9915L13 4.75016M12 9.3335V12.5002C12 12.898 11.842 13.2795 11.5607 13.5608C11.2794 13.8421 10.8978 14.0002 10.5 14.0002H3.5C3.10218 14.0002 2.72064 13.8421 2.43934 13.5608C2.15804 13.2795 2 12.898 2 12.5002V5.50016C2 5.10234 2.15804 4.72081 2.43934 4.4395C2.72064 4.1582 3.10218 4.00016 3.5 4.00016H6.66667" stroke="#059669" stroke-linecap="round" stroke-linejoin="round"/></svg>
                            <span className="text-[#059669] text-[14px] font-medium">Add Meeting Notes</span>
                            <span className="text-[#747474] text-[10px] pt-1">(only the host will see these)</span>
                        </span>
                    )}
                    </div>
                </div>
                <div className="pt-5">
                    <span className="text-[14px] text-[#747474]">Created {create_at ? create_at:'' }</span>
                </div>
            </div>
    )
}
