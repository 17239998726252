import React from 'react';
import './TextInput.css';
const Textinput = (props)=>{
    return (
        <>
            <label className={`mb-2 font-bold ${props.labelclass || ''}`}>{props.label || ''}<span className={`text-red-600 ${props.spanclass || ''}`}>{props.span}</span></label>
                <div className={props.divclass}> 
                {props.inputPre && (
                    <span className={`${props.inputPreClass || ''}`}>{props.inputPre}</span>
                )}
                <input
                    type={props.type || 'text'} 
                    checked={props.checked}
                    name={props.name || ''}
                    id={props.id}
                    value={props.value}
                    onChange={props.onChange || (() => {})} 
                    placeholder={props.placeholder || ''}
                    className={` border-[2px] w-full rounded-lg focus:ring-0 p-2.5 ${props.className || ''}`}
                    required={props.required || false}
                    maxLength={props.InputMaxLength}
                    ref={props.InputRef}
                    onInput={props.OnInput}
                    onKeyDown={props.OnKeyDown}
                    onPaste={props.OnPaste}
                    disabled = {props.disable || false}
                />
                {props.inputPassPostClass ? (
                    <div className={`${props.inputPassPostClass || ''}`} onClick={props.inputPassPostOnclick}>{props.svgicon}</div>
                    ):(
                    props.svgicon
                )}
                <p className={`${props.postlabelclass?props.postlabelclass:"hidden"}`}>{props.postlabel}</p>
            </div>
        </>
    )
}
export default Textinput;