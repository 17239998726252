import React, { useEffect, useContext, useState } from "react";
import Card from "../elements/Card/Card";
import Button from "../elements/Button/Button";
import Webenza from "../../assets/Images/webenza.jpg"
import Vector from "../../assets/Images/Vector.png"
import person from "../../assets/Images/person.jpg"
import ClipLoader from "react-spinners/ClipLoader";
import Linkedin from "../../assets/Images/linkedin.jpg";
import { AuthContext } from "../../context/AuthContext";
import CryptoJS from 'crypto-js';
import { useLocation } from "react-router-dom";
    
const ProjectJob = () =>{
    const { token } = useContext(AuthContext);
    const [jobArray,setJobPOst]=useState([]);
    const location = useLocation();
    const [teamArray,setTeam]=useState([]);
    const [companyProfile,setCompanyProfile]=useState('');
    const jobId = location.state?.jobId;
    const status = location.state?.status;
    const [isLoading, setIsLoading] = useState(false);
    var photo;
    const listJobDetails = async () =>{

        const job_id={job_id : jobId};
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(job_id), process.env.REACT_APP_SECRETKEY).toString();
        var encryptjson = { data:encryptedData}
        setIsLoading(true);
        try{
        const serverURL = process.env.REACT_APP_SERVER_URL;
                      const response = await fetch(`${serverURL}/job/fetchJobDetails`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            token: token
                          },
                          body: JSON.stringify(encryptjson),
                      });
                      const data = await response.json();
              
                      const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                      setJobPOst(decryptedData["job_details"]);
                      console.log("Team: ",decryptedData["job_team"])
                      setTeam(decryptedData["job_team"]);
                    }
                    catch(e){
                        console.log(e);
                    }
                    finally{
                        setIsLoading(false);
                    }
                    
    }

    const listCompnyProfile = async () =>{

        var email_id = ""
            const encryptedMetadata = localStorage.getItem('metadata');
            if (encryptedMetadata){
                const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                if(decryptedMetadata.cid){
                    email_id = decryptedMetadata.email;
                }
            }
            console.log("emaillll: ", email_id);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(email_id), process.env.REACT_APP_SECRETKEY).toString();
        var encryptjson = { data:encryptedData}
        const serverURL = process.env.REACT_APP_SERVER_URL;
                      const response = await fetch(`${serverURL}/company/fetchCompanyDetails`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            token: token
                          },
                          body: JSON.stringify(encryptjson),
                      });
                      const data = await response.json();
              
                      const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                      console.log("profileS: ",decryptedData);
                      const photoUrl = process.env.REACT_APP_SERVER_BASEURL+"/companyprofile/"+decryptedData[0]["profile"];
                      console.log(photoUrl,"photo")
                      setCompanyProfile(photoUrl);
                    //   setJobPOst(decryptedData["job_details"]);
                    //   console.log("Team: ",decryptedData["job_team"])
                    //   setTeam(decryptedData["job_team"]);
                    
    }

    useEffect(()=>{
        listCompnyProfile()
        listJobDetails()        
    },[]);

    const formatDescription = (text) => {
        if (!text || text.trim() === "") {
        return <p className="text-[14px] text-[#747474]">No description available</p>;
        }

        return text.split('\n').map((line, index) => {
        // If the line is empty, we want to return just a <br /> for spacing
        if (line.trim() === "") {
            return <br key={index} />;
        }

        // Otherwise, return a <p> tag for the line of text
        return <p key={index} className="text-[14px] text-[#747474]">{line}</p>;
        });
    };
    
    
    // const listTeamDetails = async () =>{
    //     const serverURL = process.env.REACT_APP_SERVER_URL;
    //                   const response = await fetch(`${serverURL}/job/fetchTeamDetails`, {
    //                       method: 'POST',
    //                       headers: {
    //                         'Content-Type': 'application/json',
    //                         token: token
    //                       },
    //                   });
    //                   const data = await response.json();
              
    //                   const teamdecryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
    //                   console.log("Team Details: ",teamdecryptedData);
    //                   setTeam(teamdecryptedData);
    // }
    // useEffect(()=>{
    //     listTeamDetails()        
    // },[]);


    let userName;
    const encryptedMetadata = localStorage.getItem('metadata');
           // console.log(encryptedMetadata,"encryptedMetadatai ")
              if (encryptedMetadata){// && encryptedMetadata.data) {
               // console.log(encryptedMetadata,"encryptedMetadata")
                  const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                userName=decryptedMetadata.name;
                console.log("currrr: ",decryptedMetadata);
            }
            // let postedAt = new Date(jobArray[0]["created_at"]);
            let dateTime;
            let date;
            jobArray.map((time)=>{
                dateTime=new Date(time.created_at);
                date=dateTime.toLocaleString()
            })
            console.log("jobarrray: ",jobArray);
    return(
        <div className="w-[70%] ml-14 rounded-xl bg-white bg-opacity-50 p-[30px] grid gap-4">
        {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        <span className="text-white mt-4">fetching Details...</span>
                    </div>
                </div>
            )}
        {Array.isArray(jobArray) &&
            jobArray.map((item,i) =>(
                <div key={i}>
           <Card CardClass="!rounded-2xl p-2 border-1 border-white shadow-md"
           body={
            <>
            <div className="flex">
            <div className="w-[80%]">
                <div className="flex">
                    <div>
                    <p className="text-[24px] font-[500] mb-2">{item.title?item.title:''}</p></div>
                    <div className=" mt-[12px] ">
                    <li 
             className={`before:content-['•'] before:mr-2 list-none pl-2 pr-2 pt-0.5 rounded-full text-xs ml-4 ${
              status === 'In Review'
                ? 'before:text-yellow-700 bg-yellow-200 text-yellow-900'
                : status === 'Active'
                ? 'before:text-green-700 bg-green-200 text-green-900'
                : status === 'Closed'
                ? 'before:text-red-700 bg-red-200 text-red-900'
                : ''
            }`}
            >{status}</li>
                    </div>
                </div>
                <div>
                     {/* <p className="text-[16px] mb-0">Created By: <span className="font-[400]">{userName}</span> ● Posted: {item.created_at}</p> */}
                     <div className="flex gap-4">
                        <p className="text-black  mb-0 font-medium"><span className="font-[500]">Created By : </span> {userName}</p>
                        <p className="before:content-['•'] before:text-green-700 before:mr-2 mb-2 list-none font-medium"><span className="font-[500]">Posted : </span> {date}</p>
                    </div>
                     {/* <p className="text-[16px] mb-0">Location: <span className="font-[400]">{item.location?item.location:''}</span></p> */}
                     <p className="text-black mb-2 font-medium"><span className="font-[500]">Location : </span> {item.location?item.location:''}</p>
                     <p className="text-black mb-2 font-medium">Workspace: <span className="font-[500]">{item.workplace_type?item.workplace_type:''}</span></p>
                     {/* <p className="text-[16px]">Project: <span className="font-[400]">{`UX Designer`}</span></p> */}
                 </div>
             </div>
             <div className="w-[50%] mt-2 justify-end flex hidden">
                 <div className="">
             <Button className="bg-[#059669]  pl-4 pr-4 h-[40px] text-white">View</Button></div>
             <div className="mt-2 h-6">
            
            
             <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={20}
            height={20}
            className="mt-1 ml-6 bg-tean-500 rounded-full"
            fill="black"
            aria-hidden="true"
            >
            <circle cx="5" cy="12" r="2" />
            <circle cx="12" cy="12" r="2" />
            <circle cx="19" cy="12" r="2" />
            </svg>
            </div>
            </div>
            </div>
            </>
           }
           />
           <Card CardClass="!rounded-2xl p-2 mt-4 border-1 border-white shadow-md" CardBodyClass="grid gap-4"
                body={
                    <>
                     <div className="border-2 border-gray-50 rounded-xl p-3 shadow-md">
                        <div className="flex gap-4 pb-6  border-b-2 border-gray-200">
                            <div className="py-3">
                            <img src={companyProfile} className="w-12 h-12 border-1 border-gray-300 rounded-lg object-cover"/>
                            <p>{item.profile?item.profile:''}</p>
                            </div>
                            <div>
                                <p className="text-[16px] text-gray-400 mb-0">{item.department?item.department:''}</p>
                                <p className="text-[24px] font-[500] mb-0">{item.title?item.title:''}</p>
                                <div className="flex gap-2 mt-2">
                                    {item?.experiance && (
                                    <span className="text-xs bg-green-50 px-2 py-1 rounded-lg">
                                        {item.experiance || "Fresher"}
                                    </span>
                                    )}
                                    {item?.WorkplaceType && (
                                    <span className="text-xs bg-green-50 px-2 py-1 rounded-lg">
                                        {item.WorkplaceType || "On-Site"}
                                    </span>
                                    )}
                                    {item?.location && (
                                    <span className="text-xs bg-green-50 px-2 py-1 rounded-lg">
                                        {item.location}
                                    </span>
                                    )}
                                    {item?.salary && (
                                    <span className="text-xs bg-green-50 px-2 py-1 rounded-lg">
                                        {item.salary}
                                    </span>
                                    )}
                                </div>
                            </div>
                            <div className="w-[30%] ml-auto">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" className=" float-right mt-3" y="0px" width="20px" height="20px" viewBox="0 0 24 24">
                            <path d="M 6.0097656 2 C 4.9143111 2 4.0097656 2.9025988 4.0097656 3.9980469 L 4 22 L 12 19 L 20 22 L 20 20.556641 L 20 4 C 20 2.9069372 19.093063 2 18 2 L 6.0097656 2 z M 6.0097656 4 L 18 4 L 18 19.113281 L 12 16.863281 L 6.0019531 19.113281 L 6.0097656 4 z"></path>
                            </svg>
                            </div>
                        </div>
                        <div className="flex mt-3 hidden">
                            <div>
                                <img src={Vector}/>
                            </div>
                            <div className="">
                                <p className="text-xs mb-0">job matchrate: {`high`}</p>
                                <p className="font-medium text-[18px]">You were born for something like this! </p>
                            </div>
                            <div className="w-1/3 ml-auto">
                                <Button className="float-right bg-[#059669] text-white w-[130px] h-[40px]">Apply Now</Button>
                            </div>
                        </div>
                     </div>
                     <div className="border-2  border-gray-50 rounded-xl p-3 shadow-md">
                        <p className="text-lg font-medium">Job Description</p>
                        {formatDescription(item.job_desc)}
                        {/* <p className="text-[14px] text-[#747474] justify-content">{item.job_desc?item.job_desc:''}</p> */}
                     </div>
                     <div className="border-2 border-gray-50 rounded-xl p-3 shadow-md">
                            <p className="text-lg font-medium">Skills and Requirements</p>
                            {/* Check if item.skills is an array */}
                            {Array.isArray(item.skills) ? (
                                item.skills.map((val, k) => (
                                    <li
                                        className="text-[14px] text-[#747474]"
                                        key={k}
                                    >
                                        {val}
                                    </li>
                                ))
                            ) : (
                                <p className="text-[14px] text-[#747474]">
                                    No skills listed
                                </p>
                            )}
                        </div>
                     <div className="border-2  border-gray-50 rounded-xl p-3 shadow-md hidden">
                     <p className="text-lg font-medium">Your Role</p>
                        <li className="text-[14px] text-[#747474]">Lead and develop key projects</li>
                        <li className="text-[14px] text-[#747474]">Collaborate with cross-functional teams</li>
                        <li className="text-[14px] text-[#747474]">Review code and mentor juniors</li>
                        <li className="text-[14px] text-[#747474]">Optimize and manage cloud applications</li>
                     </div>
                     <div className="border-2  border-gray-50 rounded-xl p-3 shadow-md hidden">
                     <p className="text-lg font-medium">Benefits</p>
                        <li className="text-[14px] text-[#747474]">Competitive Salary</li>
                        <li className="text-[14px] text-[#747474]">Flexible Remote Work</li>
                        <li className="text-[14px] text-[#747474]">Health Insurance</li>
                        <li className="text-[14px] text-[#747474]">Learning and Development Opportunities</li>
                        <li className="text-[14px] text-[#747474]">Work-Life Balance</li>
                        <li className="text-[14px] text-[#747474]">Annual Bonus</li>
                     </div>
                     <div className="border-2  border-gray-50 rounded-xl p-3 shadow-md">
                        <p className="text-lg font-[500]">Meet Your Team</p>
                        <div className="flex gap-[26px]">
                        {Array.isArray(teamArray) &&
                        teamArray
                            .map((teamMember, g) => (
                                <div className="grid gap-[10px] relative" key={g}>
                                    {/* <img
                                        src={person}
                                        className="w-[120px] h-[120px] rounded-full object-cover"
                                    /> */}
                                    {teamMember.url ? (
                                        <div className="bg-white  p-[1.05px] w-[55px] h-[55px] hover:cursor-pointer rounded-full">
                                            <a href={teamMember.url} target="_blank" rel="noopener noreferrer">
                                                <img src={Linkedin} alt="LinkedIn" className="w-full h-full" />
                                            </a>
                                        </div>
                                    ) : null}

                                    <div className="grid gap-[8px]">
                                        <p className="text-base font-[500] mb-0">
                                            {teamMember.name ? teamMember.name : ""}
                                        </p>
                                        <p className="text-xs font-[400]">
                                            {teamMember.designation
                                                ? teamMember.designation
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                            ))}

                        </div>
                     </div>
                     <div className="border-2 grid gap-4 border-gray-50 rounded-xl p-3 shadow-md">
                     <div className="flex gap-4  border-b-2 border-gray-200 items-center">
                            <div className="py-3">
                            <img src={companyProfile} className="w-12 h-12 border-1 border-gray-300 rounded-lg"/>
                            </div>
                            <div className="items-center justify-center">
            
                                <p className="text-[24px] font-[500] mb-0">{item.department?item.department:''}</p>
                            </div>
                            <div className="w-[30%] ml-auto">
                            
                            </div>
                         </div>
                         <div className="hidden">
                             <p className="text-sm font-[400] text-[#747474]">Aerolite is a leading development company specializing in cutting-edge solutions for web, mobile, and cloud-based applications. With a focus on innovation and quality, Aerolite partners with global clients to deliver high-performance software products tailored to their needs. Our team of experts excels in crafting scalable, efficient, and future-ready solutions, ensuring businesses thrive in the digital age. At Aerolite, we are committed to pushing the boundaries of development to create technology that drives success.</p>
                         </div>
                      </div>
                     </>
                }
           /> 
           </div>
           ))}
        </div>
    );
};
export default ProjectJob;