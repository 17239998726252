// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-gradient-custom {
    background: linear-gradient(53.77deg, rgba(235, 255, 245, 0.5) -77.35%, rgba(255, 255, 255, 0.5) 6.85%, rgba(148, 231, 249, 0.5) 46.52%, rgba(52, 211, 153, 0.5) 111.95%);
}
.linkedin-button {
    box-shadow: 0px 4px 46px 0px #BFE0E2;

}

.light-shadow {
    box-shadow: 10px 24px 46px 0px #BFE0E266 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyProfile/CompanyDetail.css"],"names":[],"mappings":"AAAA;IACI,yKAAyK;AAC7K;AACA;IACI,oCAAoC;;AAExC;;AAEA;IACI,mDAAmD;AACvD","sourcesContent":[".bg-gradient-custom {\n    background: linear-gradient(53.77deg, rgba(235, 255, 245, 0.5) -77.35%, rgba(255, 255, 255, 0.5) 6.85%, rgba(148, 231, 249, 0.5) 46.52%, rgba(52, 211, 153, 0.5) 111.95%);\n}\n.linkedin-button {\n    box-shadow: 0px 4px 46px 0px #BFE0E2;\n\n}\n\n.light-shadow {\n    box-shadow: 10px 24px 46px 0px #BFE0E266 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
