// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile_sidebar_progress_text {
    /* border: 2px solid #BFE0E2; */
    /* border-width: 1.5px !important; */
}`, "",{"version":3,"sources":["webpack://./src/components/elements/TextInput/TextInput.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,oCAAoC;AACxC","sourcesContent":[".profile_sidebar_progress_text {\n    /* border: 2px solid #BFE0E2; */\n    /* border-width: 1.5px !important; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
