import React from "react";
import { useState, useEffect, useRef } from "react";
import Textinput from "../elements/TextInput/TextInput";
import Card from "../elements/Card/Card";
import Button from "../elements/Button/Button";
import Person from "../../assets/Images/person.jpg";

const messages = [
    {
        id: 1,
        name: "Emily Brown",
        recentTime: "07.13 AM",
        lastMsg: "Can you send me the report?",
        message: [
            { from: "Emily", text: "Can you send me the report?" }
        ]
    },
    {
        id: 2,
        name: "John Doe",
        recentTime: "09.13 AM",
        lastMsg: "Ok..",
        message: [
            { from: "John", text: "Hey, I'm not available for today's call?" },
            { from: "Me", text: "Ok.." }
        ]
    },
    {
        id: 3,
        name: "Sarah Davis",
        recentTime: "21/12/2024 10.30 AM",
        lastMsg: "You're welcome!",
        message: [
            { from: "Sarah", text: "Thanks for the update!" },
            { from: "Me", text: "You're welcome!" }
        ]
    },
    {
        id: 4,
        name: "Brian Adams",
        recentTime: "21/12/2024 10.30 AM",
        lastMsg: "On track, almost done.",
        message: [
            { from: "Brian", text: "Hows the progress?" },
            { from: "Me", text: "On track, almost done." }
        ]
    },
    {
        id: 5,
        name: "James Lee",
        recentTime: "21/12/2024 10.30 AM",
        lastMsg: "Morning! How are you?",
        message: [
            { from: "James", text: "Good morning!" },
            { from: "Me", text: "Morning! How are you?" }
        ]
    },
    {
        id: 6,
        name: "Laura Scott",
        recentTime: "21/12/2024 10.30 AM",
        lastMsg: "Noted, thanks!",
        message: [
            { from: "Laura", text: "Dont forget about the deadline." },
            { from: "Me", text: "Noted, thanks!" }
        ]
    },
    {
        id: 7,
        name: "Isabella Young",
        recentTime: "21/12/2024 10.30 AM",
        lastMsg: "Got it, thanks!",
        message: [
            { from: "Isabella", text: "Check your email for the update." },
            { from: "Me", text: "Got it, thanks!" }
        ]
    },
    {
        id: 8,
        name: "Liam Walker",
        recentTime: "21/12/2024 10.30 AM",
        lastMsg: "Sounds good!",
        message: [
            { from: "Liam", text: "Lets connect tomorrow." },
            { from: "Me", text: "Sounds good!" }
        ]
    },
    {
        id: 9,
        name: "Jane Smith",
        recentTime: "21/12/2024 10.30 AM",
        lastMsg: "Sure, what time?",
        message: [
            { from: "Jane", text: "Lets catch up tomorrow." },
            { from: "Me", text: "Sure, what time?" }
        ]
    },
    {
        id: 10,
        name: "David Williams"
    },
    {
        id: 11,
        name: "Chris Wilson",
        recentTime: "21/12/2024 10.30 AM",
        lastMsg: "Sure, Ill look into it.",
        message: [
            { from: "Chris", text: "Please review the document." },
            { from: "Me", text: "Sure, Ill look into it." }
        ]
    },
    {
        id: 12,
        name: "Daniel Hernandez",
        recentTime: "21/12/2024 10.30 AM",
        lastMsg: "Me too!",
        message: [
            { from: "Daniel", text: "Looking forward to the meeting." },
            { from: "Me", text: "Me too!" }
        ]
    },
    {
        id: 13,
        name: "Nathan Carter",
        recentTime: "21/12/2024 10.30 AM",
        lastMsg: "Yes, everythings in place.",
        message: [
            { from: "Nathan", text: "Can you confirm the details?" },
            { from: "Me", text: "Yes, everythings in place." }
        ]
    },
    {
        id: 14,
        name: "Linda Clark"
    },
    {
        id: 15,
        name: "Michael Johnson"
    },
    {
        id: 16,
        name: "Anna Martinez"
    },
    {
        id: 17,
        name: "Olivia Robinson"
    },
    {
        id: 18,
        name: "Sophia Green"
    },
    {
        id: 19,
        name: "Kevin Hall"
    },
    {
        id: 20,
        name: "Mia Perez"
    }
];
const Inbox = () => {
    const [UserDetails, setUserDetails] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState({});
    const [searchUserValue, setSearchUserValue] = useState();
    const [messageOptionsView, setMessageOptionsView] = useState(false);
    const messageInput = useRef(null);
    const [chatArray, setChatArray] = useState([
        { name: "Sanjay" },
        { name: "Aaron" },
        { name: "Rose" },
        { name: "Jason" },
        { name: "Smith" },
        { name: "Bose" }
    ]
    )
    const SearchFront = (<svg
        className="w-5 h-5 ml-1 text-blue-500"
        fill="none"
        stroke="#9effef"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
        />
    </svg>);

    const documentSvg = (<div className="relative">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className="mt-1 mr-2" onClick={() => setMessageOptionsView(!messageOptionsView)} viewBox="0 0 16 16" fill="none">
            <path d="M14.6972 7.75679L7.97767 14.5328C7.71695 14.7984 7.40589 15.0095 7.06268 15.1535C6.71946 15.2976 6.35098 15.3718 5.97875 15.3718C5.60653 15.3718 5.23805 15.2976 4.89483 15.1535C4.55162 15.0095 4.24056 14.7984 3.97984 14.5328L1.96963 12.4887C1.44339 11.9542 1.14844 11.2342 1.14844 10.4841C1.14844 9.73405 1.44339 9.01406 1.96963 8.47956L9.12959 1.29701C9.33956 1.08531 9.58937 0.917282 9.86461 0.802613C10.1398 0.687944 10.4351 0.628906 10.7332 0.628906C11.0314 0.628906 11.3266 0.687944 11.6019 0.802613C11.8771 0.917282 12.1269 1.08531 12.3369 1.29701L13.1387 2.09884C13.3504 2.30881 13.5184 2.55862 13.6331 2.83386C13.7478 3.1091 13.8068 3.40432 13.8068 3.70249C13.8068 4.00066 13.7478 4.29588 13.6331 4.57112C13.5184 4.84636 13.3504 5.09617 13.1387 5.30614L6.79187 11.6756C6.68689 11.7814 6.56198 11.8654 6.42436 11.9228C6.28674 11.9801 6.13913 12.0096 5.99005 12.0096C5.84096 12.0096 5.69335 11.9801 5.55573 11.9228C5.41811 11.8654 5.29321 11.7814 5.18822 11.6756L4.79296 11.269C4.68711 11.164 4.60309 11.0391 4.54576 10.9015C4.48842 10.7639 4.4589 10.6163 4.4589 10.4672C4.4589 10.3181 4.48842 10.1705 4.54576 10.0329C4.60309 9.89525 4.68711 9.77035 4.79296 9.66536L9.05054 5.44166" stroke="#059669" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div className={`${messageOptionsView ? 'block' : 'hidden'} absolute bottom-[30px] shadow-sm bg-[#F2F9F9] p-3 rounded-[8px] right-[-50px]`}>
            <p className="my-2 cursor-pointer  hover:transform transition duration-150 hover:scale-105 text-sm font-normal plusJakartaSansFont text-[#000000] text-nowrap">Add a Job Post</p>
            <p className="my-2 cursor-pointer  hover:transform transition duration-150 hover:scale-105 text-sm font-normal plusJakartaSansFont text-[#000000]">Document</p>
            <p className="my-2 cursor-pointer  hover:transform transition duration-150 hover:scale-105 text-sm font-normal plusJakartaSansFont text-[#000000]">Message Template</p>
        </div>
    </div>);


    const handleMessageSend = (UserInput, userId) => {

        UserInput = UserInput.trim()
        if (UserInput.length === 0 || UserInput.length === '') {
            return;
        }

        var userIndex = UserDetails.findIndex((item) => item.id === userId);

        if (userIndex !== -1) {
            var updatedUserDetails = [...UserDetails];
            var selectedUser = { ...updatedUserDetails[userIndex] };

            var currentTime = new Date();
            var formattedTime = currentTime.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            });
            selectedUser.recentTime = formattedTime;
            selectedUser.lastMsg = UserInput;

            if (!selectedUser.message) {
                selectedUser.message = [
                    {
                        from: "Me",
                        text: UserInput,
                    },
                ];
            } else {
                selectedUser.message = [
                    ...selectedUser.message,
                    {
                        from: "Me",
                        text: UserInput,
                    },
                ];
            }

            updatedUserDetails[userIndex] = selectedUser;

            setSelectedUsers(selectedUser);
            setUserDetails(updatedUserDetails);
            var updatedData = sortMessagesByTime(updatedUserDetails);
            setUserDetails(updatedData);

            messageInput.current.value = '';
        }
    };


    const sortMessagesByTime = (messages) => {
        return messages.sort((a, b) => {
            const timeA = parseRecentTime(a.recentTime);
            const timeB = parseRecentTime(b.recentTime);
            return timeA - timeB;
        });
    };

    const parseRecentTime = (timeString) => {
        if (!timeString) return 0;

        const now = new Date();

        const dateTimeRegex = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2})\.(\d{2}) (AM|PM)$/i;
        const timeOnlyRegex = /^(\d{2})\.(\d{2}) (AM|PM)$/i;

        if (dateTimeRegex.test(timeString)) {
            const [, day, month, year, hours, minutes, period] = timeString.match(dateTimeRegex);
            const hour = period.toUpperCase() === "PM" ? parseInt(hours) % 12 + 12 : parseInt(hours) % 12;
            return new Date(year, month - 1, day, hour, minutes).getTime();
        }

        if (timeOnlyRegex.test(timeString)) {
            const [, hours, minutes, period] = timeString.match(timeOnlyRegex);
            const hour = period.toUpperCase() === "PM" ? parseInt(hours) % 12 + 12 : parseInt(hours) % 12;
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, minutes);
            return today.getTime();
        }

        return 0;
    };

    useEffect(() => {
        if (Object.keys(selectedUsers).length === 0) {
            var firstUserWithMessage = UserDetails.find((key) => key.message && key.message.length > 0);
            if (firstUserWithMessage) {
                setSelectedUsers(firstUserWithMessage);
            }
        }
    }, [UserDetails, selectedUsers]);

    useEffect(() => {
        setUserDetails(messages)
    }, [])

    var searchInput = (e) => {
        setSearchUserValue(e.target.value)
    }
    var selectUserFromSearch = (key) => {
        setSearchUserValue('')
        setSelectedUsers(key)
    }
    const handleUSerSelect = (key) => {
        console.log(selectedUsers);
        setMessageOptionsView(false)
        setSelectedUsers(key);
    }
    return (
        <div className="rounded-xl w-9/12 flex gap-2">
            <div className="p-3  w-5/12 overflow-hidden">
                <div className="relative">
                    <Textinput OnInput={searchInput} value={searchUserValue} placeholder="Search" inputPre={SearchFront} inputPreClass="ml-1" className="plusJakartaSansFont w-full outline-none  border-0  text-sm font-[400]" labelclass="hidden" divclass="flex items-center profile_sidebar_progress_text rounded-[16px] bg-white h-[48px] border-[1px]" />

                    {searchUserValue && (
                        <div className={`bg-white mt-1 absolute z-50 w-full h-96 overflow-y-auto overflow-x-hidden ${searchUserValue && searchUserValue.length > 0 && searchUserValue !== '' ? 'block' : 'hidden'}`}>
                            {UserDetails.map((key, index) => (
                                typeof key.name === 'string' &&
                                key.name.toLowerCase().includes((searchUserValue || '').toLowerCase()) && (
                                    <div onClick={() => selectUserFromSearch(key)} key={index} className="cursor-pointer text-start hover:transform transition duration-150 hover:scale-105 font-medium text-base plusJakartaSansFont p-3">
                                        {key.name}
                                    </div>
                                )
                            ))}
                        </div>
                    )}
                </div>
                <div className="overflow-y-auto w-full p-1 h-[70vh] no-scrollbar">
                    {UserDetails.length <= 0 && <div className="w-full text-center mt-4"><span>No messages received !!!</span></div>}
                    {UserDetails.map((key, index) => (
                        key.message && key.message.length > 0 && (

                            <div className="mt-2" key={index} onClick={() => handleUSerSelect(key)}>
                                <Card CardClass="bg-white h-auto !rounded-[16px] px-2 py-2 border-white shadow-md hover:cursor-pointer hover:transform transition duration-150 hover:scale-105" CardBodyClass="p-2"
                                    body={
                                        <div className="flex gap-3">
                                            <div className="w-2/12 flex items-center justify-center h-auto">
                                                <img src={Person} className="w-full h-full object-cover rounded-[12px]" />
                                            </div>
                                            <div className="w-8/12">
                                                <div className="w-full flex justify-between">
                                                    <p className="text-sm w-full mb-0 plusJakartaSansFont items-center">{key.name}<span className="float-right text-xs mt-0.5 font-[400] opacity-[50%]">{key.recentTime}</span></p>
                                                </div>
                                                <div className="w-full mt-1">
                                                    <p className="text-sm w-full mb-0 plusJakartaSansFont items-center font-[400] opacity-[50%] whitespace-nowrap overflow-ellipsis overflow-hidden">{key.lastMsg}</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                            </div>
                        )
                    ))}


                </div>

            </div>
            <div className="w-8/12 h-full overflow-hidden ">
                {/* {Object.keys(selectedUsers).map((user)=> (  */}
                    {selectedUsers && selectedUsers.name &&
                    <Card CardHeaderClass="!border-none !bg-white !rounded-2xl" CardClass="!rounded-2xl border-white !h-full" CardBodyClass="px-2" CardFooterClass="!border-none !bg-white pb-3 !rounded-2xl"
                    header={
                        <div className="flex gap-4 border-b-2 pt-1 pb-2.5">
                            <div>
                                <img src={Person} className="w-12 h-12 object-cover rounded-[12px]" />
                            </div>
                            <div>
                                <p className="mb-0 text-black font-[400] plusJakartaSansFont">{selectedUsers && selectedUsers.name ? selectedUsers.name : ''}</p>
                                <p className="plusJakartaSansFont mb-0 text-black opacity-[50%] text-sm font-[400]">{`Designation`} | {`Company`}</p>
                            </div>
                            <div className="ml-auto items-center">
                                <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" className="p-1 h-5 mb-1 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 pt-2 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600 items-center" type="button">
                                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
                                        <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    }
                    body={
                        <div className="">
                            {selectedUsers && selectedUsers.message && selectedUsers.message ?

                                <>
                                    {selectedUsers.message.map((elements) => (
                                        <div className={`flex mb-3 w-full ${elements.from.toLowerCase() === 'me' ? 'justify-end text-end' : 'justify-start text-start'}`}>
                                            <p className={`m-0 px-3 py-2 text-xs font-normal plusJakartaSansFont w-2/4 bg-[#F2F9F9] rounded-[10px]`}>{elements.text}</p>
                                        </div>
                                    ))}
                                </>
                                :
                                ''
                            }
                        </div>

                    }
                    footer={
                        <>
                            <div className="border-t-2 items-center">
                                <div key={selectedUsers.id} className="pt-3 flex gap-2">
                                    <Textinput labelclass="hidden" InputRef={messageInput} placeholder="Type Message.." className="!w-12/12 !border-none outline-none" svgicon={documentSvg} inputPassPostClass="ml-auto w-4 mr-2 mt-2 hover:cursor-pointer" divclass="flex border-2  border-[#BFE0E2]  w-11/12 rounded-lg" />
                                    <Button className="!rounded-lg bg-white border-[#059669] flex items-center justify-center px-2.5 py-2" onClick={() => handleMessageSend(messageInput.current.value, selectedUsers.id)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M6.92308 9.07692L1 6.38462L15 1L9.61539 15L6.92308 9.07692Z" stroke="#059669" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M7 9L10 6" stroke="#059669" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></Button>
                                </div>
                            </div>
                        </>
                    }
                />}
                    {/* ))} */}
            </div>
        </div>
    )
}
export default Inbox;