import React, { useState, useRef,useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ButtonSolid from "../elements/Button/ButtonSolid";
import Notification from "../Notification/Notification";
import Button from "../elements/Button/Button";
import Card from "../elements/Card/Card";
import Textinput from "../elements/TextInput/TextInput";
import CryptoJS from 'crypto-js';
import ClipLoader from "react-spinners/ClipLoader";
import GoogleSignIn from "../GmailSignIn/GmailSignIn";
import './SignIn.css';

const SignIn = () => {
  const [signInEmail, setEmail] = useState('');
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [error, setError] = useState({ email: '', password: '' }); // Track field errors
  const { setUserMetaData,setToken, setAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate()
  const pswdRef = useRef(null);
  const emailRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  // const handleOnEnter = (event) =>{
  //   if (event.key === "Enter") {
  //     // Trigger the action on Enter key press
  //     // console.log("Enter key pressed with input value:", inputValue);
  //     // Optionally, reset input or handle form submission here
  //     // auth_user(emailRef.current.value, pswdRef.current.value);
  //     console.log("enter pressed")
  //   }
    
  // }
  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification({ message: "", type: "" });
    }, 3000); // Auto-hide after 3 seconds
  };
  const validateEmail = (email) => {             //Mail Validation Fun
      const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{1,3}$/;
      return emailRegex.test(email);
  };
  const validateFields = () => {
    const errors = {};
    if (!pswdRef.current.value) {
      showNotification("Password in Required","error");
      errors.password = "Password is required.";
    }
    if (!emailRef.current.value) {
      showNotification("Enter Valid Email","error");
      errors.email = "Enter Valid Email.";
    }else if(!validateEmail(emailRef.current.value)){
      showNotification("Enter Valid Email","error");
      errors.email = "Enter Valid Email";
    }
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const auth_user = async (email, pswd) => {
    if (!validateFields()) return;

    console.log("in auth_user", email, pswd);
    const serverURL = process.env.REACT_APP_SERVER_URL;
    console.log(serverURL);
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({ email:email,password:pswd}), process.env.REACT_APP_SECRETKEY).toString();
    var postData={
      "data": encryptedData
    };
	  let response;
    setIsLoading(true);
    try {
      response = await fetch(`${serverURL}/signIn`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });
      const data = await response.json();
	    console.log(data,"data")
      if (data.token) {
       setToken(data.token) 
	     localStorage.setItem('metadata',data.data);// JSON.stringify(data.data))
  		  localStorage.setItem('token',data.token)
        if(data.data){
            console.log("data.data")
            const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            console.log(decryptedData,"dc");//.dbResponse);

        }
	      //setUserMetaData(data);
        //setAuthenticated(true);
        navigate("/Dashboard");
      }else{
        var errorMessage = "Something Went Wrong";
        if(data.error)
          errorMessage = data.error;
          showNotification(errorMessage, "error");
      }

    } catch (error) {
      var errorMessage = "Something Went Wrong";
      if (error.response && error.response.data && error.response.data.msg)
          errorMessage = error.response.data.msg;
        showNotification(errorMessage, "error");
      console.error("Error sending request:", error);
      if (error.response && error.response.data && error.response.data.msg) {
        console.error("Error message:", error.response.data.msg);
      }
      throw error;
    }
    finally {
        setIsLoading(false); // Always hide loader after the request
    }

/*	  // Mock authentication
    if (email === "demo@gmail.com" && pswd === "1234") {
      const data = { status: "success", token: "121212" };
      setUserMetaData(data);
      setAuthenticated(true);
      navigate("/Dashboard")
    } else {
        showNotification("Incorrect email or password.", "error");
    }
    */
  };
  const eyeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-5 h-5 text-teal-300"
      fill="none"
      stroke="gray"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z"
      />
    </svg>
  );
  const eyeOffIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-5 h-5 text-teal-300"
      fill="none"
      stroke="gray"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        d="m17.069 6.546 2.684-2.359c.143-.125.32-.187.497-.187.418 0 .75.34.75.75 0 .207-.086.414-.254.562l-16.5 14.501c-.142.126-.319.187-.496.187-.415 0-.75-.334-.75-.75 0-.207.086-.414.253-.562l2.438-2.143c-1.414-1.132-2.627-2.552-3.547-4.028-.096-.159-.144-.338-.144-.517s.049-.358.145-.517c2.111-3.39 5.775-6.483 9.853-6.483 1.815 0 3.536.593 5.071 1.546zm2.319 1.83c.966.943 1.803 2.014 2.474 3.117.092.156.138.332.138.507s-.046.351-.138.507c-2.068 3.403-5.721 6.493-9.864 6.493-1.297 0-2.553-.313-3.729-.849l1.247-1.096c.795.285 1.626.445 2.482.445 3.516 0 6.576-2.622 8.413-5.5-.595-.932-1.318-1.838-2.145-2.637zm-3.434 3.019c.03.197.046.399.046.605 0 2.208-1.792 4-4 4-.384 0-.756-.054-1.107-.156l1.58-1.389c.895-.171 1.621-.821 1.901-1.671zm-.058-3.818c-1.197-.67-2.512-1.077-3.898-1.077-3.465 0-6.533 2.632-8.404 5.5.853 1.308 1.955 2.567 3.231 3.549l1.728-1.519c-.351-.595-.553-1.289-.553-2.03 0-2.208 1.792-4 4-4 .925 0 1.777.315 2.455.843zm-2.6 2.285c-.378-.23-.822-.362-1.296-.362-1.38 0-2.5 1.12-2.5 2.5 0 .36.076.701.213 1.011z"
      />
    </svg>
  );
  const [showPassword, set_Show_Password] = useState(false);
  const toggle_Password_Visibility = () => {                  //Password visibility and hidden
    set_Show_Password(!showPassword);
  };
  const handleKeyPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      auth_user(emailRef.current.value, pswdRef.current.value)
      console.log("hiii");
    }
  }

  return (
    

    <Card CardClass="SignIn-Card w-3/4 border-white ml-2 !rounded-xl"
       customdiv={<> 
        {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        <span className="text-white mt-4">Signing...</span>
                    </div>
                </div>
            )}
            <Notification
            message={notification.message}
            type={notification.type}
            onClose={() => setNotification({ message: "", type: "" })}
        />
      <div className="signup-card">
        <div className="left-side">
          <p className="mb-0 text-black text-xl font-bold">Welcome Back!</p>
          <p className="text-gray-400 mb-8">Enter Your Details to Sign In</p>
          <form className="signin-form" onSubmit={(e) => e.preventDefault()}>
            <div className="form-group">
              {/* <p className="font-normal mb-2">Email ID Or Phone Number*</p>
              <input
                type="email"
                placeholder="Enter your email"
                value={signInEmail}
                onInput={(e) => {
                  setEmail(e.target.value);
                  setError((prev) => ({ ...prev, email: '' }));
                }}
              />
              {error.email && <p className="error-message">{error.email}</p>} */}
              <p className="mb-2 font-bold">Email ID<span style={{color:'red'}}>*</span></p>
              <Textinput
                          labelclass="font-bold"
                          name="Email"
                          divclass = "relative"
                          placeholder="Enter your Email"
                          className="profile_sidebar_progress_text border-[2px] h-12 border-sky-100 w-full rounded-lg focus:ring-0  pr-10"
                          required = {true}
                          InputRef={emailRef}
                          onInput={(e) => {
                            setError((prev) => ({ ...prev, email: '' }));
                          }}
                          OnKeyDown={handleKeyPress}
                          type="text"
                        />
              {error.email && <p className="error-message">{error.email}</p>}
            </div>
            <div className="form-group">
              {/* <p className="mb-2">Password*</p>
              <input
                type="password"
                placeholder="Enter your password"
                ref={pswdRef}
                onInput={() => setError((prev) => ({ ...prev, password: '' }))}
              /> */}
              <p className="mb-2 font-bold">Password<span style={{color:'red'}}>*</span></p>
              <Textinput
                          labelclass="font-bold"
                          name="Password"
                          divclass = "relative"
                          placeholder="Enter your Password"
                          className="profile_sidebar_progress_text border-[2px] h-12 border-sky-100 w-full rounded-lg focus:ring-0  pr-10"
                          required = {true}
                          svgicon ={showPassword ? eyeIcon : eyeOffIcon}
                          inputPassPostClass="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                          inputPassPostOnclick={toggle_Password_Visibility}
                          OnInput={() => setError((prev) => ({ ...prev, password: '' }))}
                          InputRef={pswdRef}
                          OnKeyDown={handleKeyPress}
                          type={showPassword ? 'text' : 'password'}
                        />
              {error.password && <p className="error-message">{error.password}</p>}
            </div>
            <div className="form-options">
              <p className="text-gray-500 ">
                <input type="checkbox" /> Remember me
              </p>
              <a href="#" className="mt-0">
                Forgot password?
              </a>
            </div>
            <div className="sigin-button-page flex items-baseline">
            <ButtonSolid
        content="Login"
        className="my-custom-button"
        onClick={() => auth_user(emailRef.current.value, pswdRef.current.value)}
        style={{ maxWidth: '120px' }}
        type="button"
      />
              <p className="signup-link ml-4">
                Don't have an account? <a href="/SignUp">Create account</a>
              </p>
            </div>
          </form>
        </div>

        <div className="or-divider">
          <div className="line"></div>
          <span className="font-normal">Or</span>
          <div className="line"></div>
        </div>

        <div className="right-side">
          {/* <button className="google-button flex">
          <svg xmlns="http://www.w3.org/2000/svg" className="mr-4" enableBackground="new 0 0 48 48" height="25" viewBox="0 0 48 48" width="25"><path d="m43.611 20.083h-1.611v-.083h-18v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657c-3.572-3.329-8.35-5.382-13.618-5.382-11.045 0-20 8.955-20 20s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z" fill="#ffc107"/><path d="m6.306 14.691 6.571 4.819c1.778-4.402 6.084-7.51 11.123-7.51 3.059 0 5.842 1.154 7.961 3.039l5.657-5.657c-3.572-3.329-8.35-5.382-13.618-5.382-7.682 0-14.344 4.337-17.694 10.691z" fill="#ff3d00"/><path d="m24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238c-2.008 1.521-4.504 2.43-7.219 2.43-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025c3.31 6.477 10.032 10.921 17.805 10.921z" fill="#4caf50"/><path d="m43.611 20.083h-1.611v-.083h-18v8h11.303c-.792 2.237-2.231 4.166-4.087 5.571.001-.001.002-.001.003-.002l6.19 5.238c-.438.398 6.591-4.807 6.591-14.807 0-1.341-.138-2.65-.389-3.917z" fill="#1976d2"/></svg>
          Sign in with Google</button> */}
          <GoogleSignIn showNotification={showNotification} setIsLoading={setIsLoading} />
          <button className="linkedin-button flex">
          <svg xmlns="http://www.w3.org/2000/svg" className="mr-4" fill="none" height="24" viewBox="0 0 24 24" width="24"><path d="m19.0413 19.0413h-2.9635v-4.6409c0-1.1067-.0197-2.5313-1.5412-2.5313-1.5435 0-1.7797 1.2057-1.7797 2.4507v4.7211h-2.96337v-9.54349h2.84487v1.30419h.0399c.5792-.99038 1.6602-1.5841 2.8069-1.54152 3.0035 0 3.5573 1.97562 3.5573 4.54582zm-12.59151-10.84845c-.94342 0-1.72009-.77622-1.72009-1.71964s.77631-1.71973 1.71973-1.71973c.94333 0 1.71955.77613 1.71973 1.71937 0 .94324-.77613 1.71991-1.71937 1.72zm1.48169 10.84845h-2.96651v-9.54389h2.96651zm12.58712-17.0398h-17.04282c-.80002-.00903-1.46603.6415-1.47578 1.44152v17.11358c.00939.8004.67531 1.4517 1.47578 1.4431h17.04282c.8024.0101 1.4707-.641 1.4814-1.4431v-17.11475c-.0107-.80171-.6799-1.45216-1.4814-1.4416z" fill="#0a66c2"/></svg>
          Sign in with LinkedIn</button>
        </div>
        {/* <div className="bg-gradient-to-r w-2/5 from-sky-50 to-teal-400 mt-3 pb-8 pt-3 pl-1 pr-1 rounded-xl">
        <Button className="bg-white border-none text-black flex w-4/5 ml-7 pl-4 mt-24 pt-2.5 pb-2 pr-4 mb-4 text-sm"><svg xmlns="http://www.w3.org/2000/svg" className="mr-4" enableBackground="new 0 0 48 48" height="25" viewBox="0 0 48 48" width="25"><path d="m43.611 20.083h-1.611v-.083h-18v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657c-3.572-3.329-8.35-5.382-13.618-5.382-11.045 0-20 8.955-20 20s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z" fill="#ffc107"/><path d="m6.306 14.691 6.571 4.819c1.778-4.402 6.084-7.51 11.123-7.51 3.059 0 5.842 1.154 7.961 3.039l5.657-5.657c-3.572-3.329-8.35-5.382-13.618-5.382-7.682 0-14.344 4.337-17.694 10.691z" fill="#ff3d00"/><path d="m24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238c-2.008 1.521-4.504 2.43-7.219 2.43-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025c3.31 6.477 10.032 10.921 17.805 10.921z" fill="#4caf50"/><path d="m43.611 20.083h-1.611v-.083h-18v8h11.303c-.792 2.237-2.231 4.166-4.087 5.571.001-.001.002-.001.003-.002l6.19 5.238c-.438.398 6.591-4.807 6.591-14.807 0-1.341-.138-2.65-.389-3.917z" fill="#1976d2"/></svg>Log in with Google</Button>
        <Button className="bg-white text-black border-none flex pl-4 w-4/5 ml-7 pt-2.5 pb-2.5 pr-2.5  mb-2 text-sm"><svg xmlns="http://www.w3.org/2000/svg" className="mr-4" fill="none" height="24" viewBox="0 0 24 24" width="24"><path d="m19.0413 19.0413h-2.9635v-4.6409c0-1.1067-.0197-2.5313-1.5412-2.5313-1.5435 0-1.7797 1.2057-1.7797 2.4507v4.7211h-2.96337v-9.54349h2.84487v1.30419h.0399c.5792-.99038 1.6602-1.5841 2.8069-1.54152 3.0035 0 3.5573 1.97562 3.5573 4.54582zm-12.59151-10.84845c-.94342 0-1.72009-.77622-1.72009-1.71964s.77631-1.71973 1.71973-1.71973c.94333 0 1.71955.77613 1.71973 1.71937 0 .94324-.77613 1.71991-1.71937 1.72zm1.48169 10.84845h-2.96651v-9.54389h2.96651zm12.58712-17.0398h-17.04282c-.80002-.00903-1.46603.6415-1.47578 1.44152v17.11358c.00939.8004.67531 1.4517 1.47578 1.4431h17.04282c.8024.0101 1.4707-.641 1.4814-1.4431v-17.11475c-.0107-.80171-.6799-1.45216-1.4814-1.4416z" fill="#0a66c2"/></svg>Log in with LinkedIn</Button>
        </div> */}
      </div>
    </>}
    />
    
      );
};

export default SignIn;
