import React, { useEffect, useState, useContext } from "react";
import Textinput from "../elements/TextInput/TextInput";
import Selectbox from "../elements/SelectBox/SelectBox";
import Button from "../elements/Button/Button";
import { useCommonContext } from "../../context/CommonContext";
import { useNavigate } from "react-router-dom";
import Textarea from "../elements/TextArea/TextArea";
import CryptoJS from "crypto-js";
import { AuthContext } from "../../context/AuthContext";

const Jobscreen = () => {
  const navigate = useNavigate();
  const { jobCreationData, setJobCreationData, validationErrors } =
    useCommonContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (Object.keys(validationErrors).length > 0) {
      const errorElement = document.querySelector(".validation-check-failed");
      console.log("Validation error found but not found the div")
      if (errorElement) {
        console.log("Validation error found, scrolling...");
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        errorElement.focus();
      }
    }
  }, [validationErrors]);

  const gobacktochatbot = () => {
    navigate("/JobCreation");
  };

  const [skills, setSkills] = useState(["Education",
    "Background Check",
    "Expertise With Skill",
    "Driver's License",
    "Hybrid Work",
    "Drug Test",
    "Industry Experience"
]);
  const [customSkill, setCustomSkill] = useState("");
  const [showCustomInput, setShowCustomInput] = useState(false);
  const { token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const showNotification = (message, type) => {
    //For Notification
    setNotification({ message, type });
    setTimeout(() => {
      setNotification({ message: "", type: "" });
    }, 3000); // Auto-hide after 3 seconds
  };
  const [WorkplaceTypeVal, SetWorkplaceTypeVal] = useState();
  const [jobTypeVal, SetJobTypeVal] = useState();
  const [company, SetCompanyVal] = useState();

  useEffect(() => {
    fetchSuggestions();
  }, []);

  const fetchSuggestions = async () => {
    const encryptedMetadata = localStorage.getItem("metadata");
    let company_id = "";
    if (encryptedMetadata) {
      const decryptedMetadata = JSON.parse(
        CryptoJS.AES.decrypt(
          encryptedMetadata,
          process.env.REACT_APP_SECRETKEY
        ).toString(CryptoJS.enc.Utf8)
      );
      company_id = decryptedMetadata?.cid || "";
    }

    const serverURL = process.env.REACT_APP_SERVER_URL;
    const jsonData = { cid: company_id, type: "Skills" };
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonData),
      process.env.REACT_APP_SECRETKEY
    ).toString();

    setIsLoading(true);
    try {
      const response = await fetch(`${serverURL}/masters/selectData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token,
        },
        body: JSON.stringify({ data: encryptedData }),
      });

      const data = await response.json();
      if (data.status !== "success") {
        showNotification(data.message || "Failed to fetch data.", "error");
        return;
      }

      let decryptedData = data.data;
      if (typeof data.data === "string") {
        decryptedData = JSON.parse(
          CryptoJS.AES.decrypt(
            data.data,
            process.env.REACT_APP_SECRETKEY
          ).toString(CryptoJS.enc.Utf8)
        );
      }
      const fetchedSuggestions = decryptedData.map((item) => item.skill);
      // setSkills(fetchedSuggestions);
      if(fetchedSuggestions.length > 0){
        setSkills(fetchedSuggestions);
      }
    } catch (error) {
      showNotification("Something went wrong while loading data.", "error");
    } finally {
      setIsLoading(false);
    }
  };


  const toggleSkill = (skill) => {
    setJobCreationData((prev) => ({
      ...prev,
      selectedSkills: prev.selectedSkills?.includes(skill)
        ? prev.selectedSkills.filter((s) => s !== skill)
        : [...prev.selectedSkills, skill],
    }));
  };

  const addCustomSkill = () => {
    if (customSkill && !skills.includes(customSkill)) {
      setSkills((prev) => [...prev, customSkill]);
      setJobCreationData((prev) => ({
        ...prev,
        selectedSkills: [...prev.selectedSkills, customSkill],
      }));
      setCustomSkill("");
      setShowCustomInput(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setJobCreationData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const workplaceTypeData = [
    { id: "0", label: "" },
    { id: "1", label: "On-Site" },
    { id: "2", label: "Hybrid" },
    { id: "3", label: "Remote" },
  ];

  const jobTypeData = [
    { id: "0", label: "" },
    { id: "1", label: "Full - time" },
    { id: "2", label: "Part - time" },
    { id: "3", label: "Contract" },
    { id: "4", label: "Temporary" },
    { id: "5", label: "Other" },
    { id: "6", label: "Volunteer" },
    { id: "7", label: "Internship" },
  ];

  const ValueFind = (value, type) => {
    if (type != "" && type != undefined && type != '_blank') {
      const data = value.find((key) => key.label === type);
      return data.id;
    } else {
      return 0;
    }
  };

  const FindCompany = () => {
    const encryptedMetadata = localStorage.getItem("metadata");
    const decryptedMetadata = JSON.parse(
      CryptoJS.AES.decrypt(
        encryptedMetadata,
        process.env.REACT_APP_SECRETKEY
      ).toString(CryptoJS.enc.Utf8)
    );

    if (
      decryptedMetadata &&
      decryptedMetadata.company_name &&
      decryptedMetadata.company_name != ""
    ) {
      var companyName = decryptedMetadata.company_name;
      jobCreationData.company = companyName;
      return companyName;
    } else {
      return "";
    }
  };

  const setValueChange = (e) => {
    console.log(jobCreationData);
    const { id, value } = e.target;
    switch (id) {
      case "SetWorkplaceTypeVal":
        SetWorkplaceTypeVal(value);
        setJobCreationData((prev) => ({
          ...prev,
          ["workplaceType"]: workplaceTypeData.find((item) => item.id === value)
            .label,
        }));
        break;
      case "SetJobTypeVal":
        SetJobTypeVal(value);
        setJobCreationData((prev) => ({
          ...prev,
          ["jobType"]: jobTypeData.find((item) => item.id === value).label,
        }));
        break;
      default:
        console.warn(`No Value Updated :${id}`);
    }
  };


  return (
    <div className="ml-7">
      <p className="text-lg font-medium mb-0">Review Job Description</p>
      <p className=" text-gray-600">
        Add members below which will be visible to the Job seekers under ‘Meet
        your teams’ sections
      </p>
      <div className="flex mobile-flex-column">
        <div className="w-[26%]  mobile-full-width mobile-no-margin">
          <Textinput
            disable={true}
            label="Company"
            value={FindCompany() || jobCreationData.company}
            name="company"
            labelclass="font-medium"
            className={`w-[90%] outline-none border-[#BFE0E2] ${
              validationErrors.company ? "!border-red-500 validation-check-failed" : ""
            }`}
          />
        </div>
        <div className="w-[26%] ml-4 mobile-full-width">
          <Textinput
            label="Job Title"
            value={jobCreationData.jobTitle}
            name="jobTitle"
            onChange={handleInputChange}
            labelclass="font-medium"
            className={`w-[90%] outline-none border-[#BFE0E2] ${
              validationErrors.jobTitle ? "!border-red-500 validation-check-failed" : ""
            }`}
          />
        </div>
        <div className="w-[26%] ml-4 mobile-full-width mobile-no-margin">
          <Selectbox
            label="Workplace Type"
            labelClass="mb-2 font-semibold font-medium"
            list={workplaceTypeData}
            id="SetWorkplaceTypeVal"
            value={ValueFind(workplaceTypeData, jobCreationData.workplaceType)}
            onChange={(e) => setValueChange(e)}
            customClass={` border-2 rounded-lg focus:ring-0 p-[11px] mt-0 w-[90%] outline-none border-[#BFE0E2] ${
              validationErrors.workplaceType ? "!border-red-500 validation-check-failed" : ""
            }`}
          />
        </div>
      </div>
      <div className="flex">
        <div className="w-[26%] mobile-full-width">
          <Textinput
            label="Job Location"
            value={jobCreationData.jobLocation}
            name="jobLocation"
            onChange={handleInputChange}
            labelclass="font-medium"
            className={`w-[90%] outline-none border-[#BFE0E2] ${
              validationErrors.jobLocation ? "!border-red-500 validation-check-failed" : ""
            }`}
          />
        </div>
        <div className="w-[26%] ml-4 mobile-full-width">
          <Selectbox
            label="Job Type"
            labelClass="mb-2 font-semibold font-medium"
            list={jobTypeData}
            id="SetJobTypeVal"
            value={ValueFind(jobTypeData, jobCreationData.jobType)}
            onChange={(e) => setValueChange(e)}
            customClass={` border-2 rounded-lg focus:ring-0 p-[11px] mt-0 w-[90%] outline-none border-[#BFE0E2] ${
              validationErrors.jobType ? "!border-red-500 validation-check-failed" : ""
            }`}
          />
        </div>
      </div>

      <div className="w-full mt-12 grid gap-1">
        <div className="flex">
          <p className="mb-1 font-semibold">Description*</p>
          <Button
            onClick={() => gobacktochatbot()}
            className="bg-white text-[#059669] ml-auto  text-sm !border-1 border-[#059669] pl-1 w-36 rounded-lg pr-1 pt-1 pb-1"
          >
            Rewrite with AI +
          </Button>
        </div>
        <Textarea
          labelclass="hidden"
          className={`w-[90%] !px-8 outline-none !border-[#BFE0E2] resize-none ${
            validationErrors.description ? "!border-red-500 validation-check-failed" : ""
          }`}
          value={jobCreationData.description}
          name="description"
          style={{ height: "300px", fontSize: "15px" }}
          onChange={handleInputChange}
        />
      </div>
      <div className="mt-12">
        <p className="text-lg font-medium">Skills</p>
        <div
          className={`w-[60%] outline-none mobile-full-width ${
            validationErrors.selectedSkills ? "border-2 border-red-500" : ""
          }`}
        >
        {skills.length === 0 ? (skills.map((skill) => (
            <button key={skill}
              className={`!p-[12px] text-[14px] !rounded-full ${
                jobCreationData.selectedSkills?.includes(skill)
                  ? "bg-[#F2F9F9]"
                  : "!bg-[#F2F2F2]"
              } ml-[12px] mb-[12px]`}
              onClick={() => toggleSkill(skill)}
            >{skill} +</button>
          ))
          ) : (
          skills.map((skill) => (
            <button key={skill}
              className={`!p-[12px] text-[14px] !rounded-full ${
                jobCreationData.selectedSkills?.includes(skill)
                ? "bg-[#F2F9F9]"
                : "!bg-[#F2F9F9]"
            } ml-[12px] mb-[12px]`}
            onClick={() => toggleSkill(skill)}
          >{skill} +</button>
          ))
        )}
          <button
            className="!p-[12px] text-[14px] !rounded-full bg-[#F2F9F9] ml-[12px] mb-[12px]"
            onClick={() => setShowCustomInput(true)}
          >
            Custom +
          </button>

        </div>

        {showCustomInput && (
          <div className="flex mt-[12px]">
            <input
              type="text"
              className="border p-[8px] rounded text-[14px] mr-[8px]"
              placeholder="Enter custom skill"
              value={customSkill}
              onChange={(e) => setCustomSkill(e.target.value)}
            />
            <button
              className="!p-[12px] !shadow-lg text-[14px] !rounded-full bg-teal-200"
              onClick={addCustomSkill}
            >
              Add Skill
            </button>
          </div>
        )}
        <div className="mt-[16px]">
          <p className="text-md font-medium">Selected Skills</p>
          <div className="flex flex-wrap mt-[8px] w-[50%] mobile-full-width">
            {jobCreationData.selectedSkills?.map((skill) => (
              <div
                key={skill}
                className="flex items-center bg-[#F2F9F9] p-[8px] rounded-full mr-[8px] mb-[8px]"
              >
                <span className="text-[14px] mr-[4px]">{skill}</span>
                <button
                  style={{ cursor: "pointer" }}
                  className=" text-[14px] my-auto"
                  onClick={() => toggleSkill(skill)}
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jobscreen;
