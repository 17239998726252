import React, { useState } from "react";
import Calendar from "./Calendar";
import Selectbox from "../elements/SelectBox/SelectBox";
import CalendarList from "./CalendarList";
import './Availability.css'

const ListIcon = (props) => {
    return(<svg fill="none" height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8 6.00067L21 6.00139M8 12.0007L21 12.0015M8 18.0007L21 18.0015M3.5 6H3.51M3.5 12H3.51M3.5 18H3.51M4 6C4 6.27614 3.77614 6.5 3.5 6.5C3.22386 6.5 3 6.27614 3 6C3 5.72386 3.22386 5.5 3.5 5.5C3.77614 5.5 4 5.72386 4 6ZM4 12C4 12.2761 3.77614 12.5 3.5 12.5C3.22386 12.5 3 12.2761 3 12C3 11.7239 3.22386 11.5 3.5 11.5C3.77614 11.5 4 11.7239 4 12ZM4 18C4 18.2761 3.77614 18.5 3.5 18.5C3.22386 18.5 3 18.2761 3 18C3 17.7239 3.22386 17.5 3.5 17.5C3.77614 17.5 4 17.7239 4 18Z" stroke={props.colour} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>)
}
const CalendarIcon = (props) => {
    return(<svg fill="none" height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 10H21M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" stroke={props.colour} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>)
}

const Availability = () => {

    const [IsList, setIsList] = useState(false);
    const [IsCalendar, setIsCalendar] = useState(true);
    const [DaysCountval, setDaysCountval] = useState("");
    const [activeTab, setActiveTab] = useState("Default Schedule");
    const eventTypes = [
        {id:0, label: "select type"},
        {id:1, label: "Event type 1"},
        {id:2, label: "Event type 2"},
        {id:3, label: "Event type 3"},
    ]
    const setValueChange = (e) => {
        setDaysCountval(e.target.value)
        console.log(e.target)
    }
    const Setbutton = (name) => {
        if(name === 'List'){
            setIsList(true);
            setIsCalendar(false);
        }else {
            setIsList(false);
            setIsCalendar(true);
        }
    }

  const navItems = ["Default Schedule", "Calendar Settings"];

  return (
    <div className="ml-[5%] mt-8 py-4 px-4 rounded-xl bg-white bg-opacity-50">
      <h4 className="text-xl font-semibold mb-4">Availability</h4>
      
      <div className="flex w-full justify-left mt-6" style={{borderBottom: '1px solid grey'}}>
        {navItems.map((item) => (
          <button
            key={item}
            onClick={() => setActiveTab(item)} // Update active tab
            className={`mx-4 py-2 text-lg ${
              activeTab === item
                ? "text-green-600 border-b-4 border-green-600"
                : "text-gray-600"
            }`}
          >
            {item}
          </button>
        ))}
      </div>

      
      {activeTab === "Default Schedule" && 
      <>
        <div className={`w-full py-2 flex justify-between`} style={{borderBottom: '2px solid #74747433'}}>
            <div className={`flex flex-col !justify-center`}>
                <h4 className="mb-0">Default Schedule</h4>
                <p className="mb-0">Set when you are available for meetings</p>
            </div>
            <div className={`flex`}>
                <p className={`text-nowrap flex text-center align-items-center mb-0`}>Active on : 
                <Selectbox id="SetDays" value={DaysCountval} onChange={(e)=> setValueChange(e)} className={`!mt-0 !w-[110px] !text-[15px] text-emerald-600 !bg-transparent  !ring-transparent !foucs:ring-0 !outline-none p-2 !border-none`} list={eventTypes} />
                </p>
                <div className="flex py-4 ml-2">
                    <div onClick={() => Setbutton('List')} className={`cursor-pointer List-button gap-2 flex ${IsList ? `Active`: ``}`} style={{color : `${IsList ? `white`: `#059669`}`}}><ListIcon colour={!IsList ? "#059669" : "#ffffff"} /> List</div>
                    <div onClick={() => Setbutton('Calendar')} className={`cursor-pointer Calendar-button gap-2 flex ${IsCalendar ? `Active`: ``}`} style={{color : `${IsCalendar ? `white`: `#059669`}`}}><CalendarIcon colour={!IsCalendar ? "#059669" : "#ffffff"}/> Calendar</div>
                </div>
            </div>
        </div>
        {IsCalendar ? <Calendar /> : <CalendarList />}
      </>}
      
      {activeTab === "Calendar Settings" && (
        <div className="p-4 border rounded bg-gray-50">Details Page Content</div>
      )}
    </div>
  );
};

export default Availability;
