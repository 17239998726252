import React,{useState,useEffect,useRef,useContext} from "react";
import Button from "../elements/Button/Button";
import Card from "../elements/Card/Card";
import Textinput from "../elements/TextInput/TextInput";
import RadioInput from "../elements/RadioInput/RadioInput";
import Selectbox from "../elements/SelectBox/SelectBox";
import Person from "../../assets/Images/person.jpg"
import { useCommonContext } from '../../context/CommonContext';
import { useNavigate } from "react-router-dom";
import Notification from "../Notification/Notification";
import ClipLoader from "react-spinners/ClipLoader";
import CryptoJS from 'crypto-js';
import { AuthContext } from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import ModalBox from "../elements/ModalBox/ModalBox";
const HomeSearchProfiles = () =>{
    const serverSocketUrl = process.env.REACT_APP_SERVERSOCKET_URL;
    const location = useLocation();
    const { searchTerm, setSearchTerm, setIsAIsearch, isAIsearch, showUpload, setShowUpload,aiSearchResult,setAiSearchResult } = useCommonContext();
    const [isOpen, setIsOpen] = useState('');
    const [ResumeDetails,setResumeDetails] = useState([]);
    const [jobDetails,setJobDetails] = useState([])
    const navigate = useNavigate()
    const [notification, setNotification] = useState({ message: "", type: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [processingMessage, setProcessingMessage] = useState('No resume data available.');
    const { token } = useContext(AuthContext);
    const [locationArr,setLocationArr] = useState([]);
    const [skillArr,setSkillArr] = useState([]);
    const [companyArr,setCompanyArr] = useState([]);
    const [keywordArr,setKeywordArr] = useState([]);
    const [degreeArr,setDegreeArr] = useState([]);
    const[seeLocations,showMoreLocations]=useState(false)
    const[seeAllJobs,showMoreJobs]=useState(false)
    const [seeAllText, setSeeAllText] = useState({'location': 'view all','job':'view all','skill':'view all','company':'view all','keyword':'view all','degree':'view all'});
    const [checkedValue, setcheckedValue] = useState({});
    const [JDvalue,setJDvalue] = useState("");
    const [userIdCom,setUserIdCom] = useState(false);
    const [allowFilter,setAllowFilter]=useState(false);
    const [resumeIdPipeline,setResumeIdPipeline]=useState(false);
    const [candidateNamePipeline,setcandidateNamePipeline]=useState(false);
    const [candidateEmailPipeline,setcandidateEmailPipeline]=useState(false);
    const [resumeIdPipelineType,setResumeIdPipelineType]=useState(false);
    const [JobModal,setJobModal]=useState(false);
    const [selectedJob, setSelectedJob] = useState("");
    const [selectedJobTitle, setSelectedJobTitle] = useState("");
    const [searchText, setSearchText] = useState("");
    const [isFiltered, setIsFiltered] = useState(false);
    const [Locations,setLocation] = useState(false);
    const [assessments,setSkill] = useState(false);
    const [Companies,setCompanies] = useState(false);
    const [Keywords,setKeywords] = useState(false);
    const [Degrees,setDegrees] = useState(false);
    const [viewResumeOpen,setViewResumeOpen] = useState(false);
    const [fitment,setFitment] = useState(false);
    // const { searchTermState } = location.state || {};
    const [searchTermState,setsearchTermState]=useState(false);
    const showNotification = (message, type) => {   //For Notification
      setNotification({ message, type });
      setTimeout(() => {
        setNotification({ message: "", type: "" });
      }, 3000); // Auto-hide after 3 seconds
    };
    const encryptedMetadataAll = localStorage.getItem('metadata');
	var decryptedMetadataAll;
    if (encryptedMetadataAll){
       decryptedMetadataAll = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadataAll, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
    }


       const toggleDropdownPipeline = (index) => {
         setIsOpen((prev) => prev===index?null:index);
        };
	    const toggleDropdownViewResume = (index) => {
         setViewResumeOpen((prev) => prev===index?null:index);
       };
        const [isOpenMessage, setIsOpenMessage] = useState(false);
      
        const toggleDropdownMessage = (index) => {
          setIsOpenMessage((prev) =>  prev===index?null:index);
        };
        const [filterOpen, setfilter]=useState(false);
        const handleAllFilters = () =>{
          setfilter(true)
        }
  const sortTypes=[
    {id:'0',label:'Last viewed by me'},
    {id:'1',label:'Date posted: Newest to oldest'},
    {id:'2',label:'Date posted: oldest to Newest'}
  ]
  const dropdownRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(null);
  const handleClickOutside = (event) => {
    // Check if the click is outside the dropdown and the dropdown is open
    if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
    }
};
useEffect(() => {
   /* let userId;
    const encryptedMetadata = localStorage.getItem('metadata');
    if (encryptedMetadata){
        const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        if(decryptedMetadata.uid){
            userId = decryptedMetadata.uid;
        }
    }
    setUserIdCom(userId)
    */
	getResponse();
  getJobDetail();
    //console.log(aiSearchResult)
    //setResumeDetails(aiSearchResult.resumedetail)   
    // Attach the event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        // Cleanup the event listener on component unmount
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, []);

useEffect(() => {
    setcheckedValue(prevText => ({
      ...prevText,
      locations: locationArr,skill:skillArr,companies:companyArr,keywords:keywordArr,degrees:degreeArr
    }));
}, [locationArr,skillArr,companyArr,keywordArr,degreeArr]);

useEffect(() => {
    filterData();
}, [checkedValue]);

useEffect(() => {
    setcheckedValue(prevText => ({
      ...prevText,
      jd_id: JDvalue
    }));
}, [JDvalue]);

//    let tryReconnect_ws;
const [trying_ws, setTrying_ws] = useState(false);
    const [clientConnected, setClientConnected] = useState(false);
    const socketClientRef = useRef(null); // Use a ref to persist WebSocket across re-renders
    let tryReconnect_ws = useRef(null); // Use ref for reconnect timer
    function connect_ws() {
        console.log("in connect_ws")
	    console.log("Connecting...");
        // Determine the WebSocket URL based on serverSocketUrl
        const protocol = serverSocketUrl.indexOf("localhost") >= 0 ? "ws://" : "wss://";
        const url = protocol + serverSocketUrl;
        // Create WebSocket
        socketClientRef.current = new WebSocket(url, "server-socket-protocol");
        socketClientRef.current.onerror = function () {
            console.log("Connection Error");
        };
        socketClientRef.current.onopen = function () {
            console.log("WebSocket Client Connected");
            clearTimeout(tryReconnect_ws.current);
            setClientConnected(true);
            let userId="";
		const encryptedMetadata = localStorage.getItem('metadata');
    if (encryptedMetadata){
        const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        console.log(decryptedMetadata,"decryptedMetadata")
	    if(decryptedMetadata.uid){
		let userId = decryptedMetadata.uid;
       	console.log(userId,"userIduserIduserId");
	const req = {
                mode: "register-client",
                userId:userId,// "user-id-goes-here", // to-do
            };
           console.log(req,"reqqqq")
		    socketClientRef.current.send(JSON.stringify(req));
	    }
    }
    //setUserIdCom(userId)
		console.log("userId",userId)
//		if(userId == "" )
	//		return;
		// Register client
           /* const req = {
                mode: "register-client",
                userId:userId,// "user-id-goes-here", // to-do
            };
            socketClientRef.current.send(JSON.stringify(req));
       */
       };
        socketClientRef.current.onclose = function () {
            console.log("Socket Client Closed");
            setClientConnected(false);
            if (!trying_ws) {
                setTrying_ws(true);
                tryReconnect_ws.current = setTimeout(() => {
                    console.log("Reconnecting WebSocket...");
                    connect_ws();
                }, 2000);
            }
        };
        socketClientRef.current.onmessage = function (e) {
            try {
                const data = JSON.parse(e.data);
                if (
                    data &&
                    data.status === 1 &&
                    data.type === "response" &&
                    data.mode === "webhook"
                ) {
                    window.location.reload();
                }
            } catch (error) {
                console.error("Error parsing WebSocket message:", error);
            }
        };
    }
    // Cleanup on unmount
    useEffect(() => {
        connect_ws();
        return () => {
            if (socketClientRef.current) {
                socketClientRef.current.close();
            }
            clearTimeout(tryReconnect_ws.current);
        };
    }, []);

/*  const [trying_ws, setTrying_ws] = useState(false);
    const [socketClient, setSocketClient] = useState(null);


    if (serverSocketUrl.indexOf('localhost') >= 0) {
     var _socketClient = new WebSocket("ws://"+serverSocketUrl, 'server-socket-protocol');
     setSocketClient(_socketClient)
    }
    else {
     
   //  var _socketClient = new WebSocket("wss://"+serverSocketUrl, 'server-socket-protocol');
    // setSocketClient(_socketClient)
   // }

    const [clientConnected, setClientConnected] = useState(false);
  
  function connect_ws() {
     console.log('Connecting...');
     socketClient.onerror = function () {
         console.log('Connection Error');
     };

     socketClient.onopen = async function () {
         console.log('WebSocket Client Connected');
         clearTimeout(tryReconnect_ws);
         if (socketClient.readyState === socketClient.OPEN) {
             setClientConnected(true);
         }
            
         function registerClient() {
             var req = {}
             req['mode'] = 'register-client';
             req['userId'] = 'user-id-goes-here'; // to-do
             socketClient.send(JSON.stringify(req));
         }
         if (clientConnected) {
             registerClient();
         }
     };

     socketClient.onclose = function () {
         setTrying_ws(false);
         console.log('Socket Client Closed');
         if (!trying_ws) {
             setTrying_ws(true);
             /*tryReconnect_ws = setTimeout(() => {
                 socketClient.close();
                 if (serverSocketUrl.indexOf('localhost') >= 0) {
                     // to-do (you can change protocol name [server-socket-protocol] - need to update the same on server)
                     socketClient = new WebSocket("ws://"+serverSocketUrl, 'server-socket-protocol'); 
                 }
                 else {
                     socketClient = new WebSocket("wss://"+serverSocketUrl, 'server-socket-protocol');
                 }
                 //connect_ws();
             }, 2000);
         }
     };

     socketClient.onmessage = async function (e) {
         var data = JSON.parse(e.data) ? JSON.parse(e.data) : e.data;
         if (data && data.status && data.status == 1 && data.type && data.type == "response" && data.mode == "webhook") {
            window.location.reload()
         }
     };
    }
    useEffect(() => {
      var _socketClient = new WebSocket("wss://"+serverSocketUrl, 'server-socket-protocol');
     setSocketClient(_socketClient)
setTimeout(() => {
	    connect_ws();
},100);
    }, []);
*/
const filterData = async () => {
  console.log("newwwwww")
  console.log(checkedValue,"checked");
  if(checkedValue.jd_id == ""){
    return;
  }
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(checkedValue), process.env.REACT_APP_SECRETKEY).toString();
    var searchData = { data:encryptedData}
    setIsLoading(true);
    try {
        const serverURL = process.env.REACT_APP_SERVER_URL;
        const response = await fetch(`${serverURL}/JD/filterResume`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              token: token
            },
            body: JSON.stringify(searchData), 
        });
        const result = await response.json();
        console.log("Server Response:", result);
        if (!response.ok) {
            throw new Error(result.error);
        }
        const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        if(decryptedData['message']){
            setProcessingMessage(decryptedData['message'])
            return;
        }
       console.log(decryptedData,"diccc");
        setAiSearchResult(decryptedData[JDvalue]);
        setResumeDetails(decryptedData[JDvalue]['resumedetail']);
        setProcessingMessage("No resume data available.");
        setIsFiltered(true);
        // console.log(decryptedData)
        // showNotification(result.message, "success");
    } catch (error) {
        console.error("Error uploading file:", error);
        showNotification(error.message, "error");
    }finally {
        setIsLoading(false); // Always hide loader after the request
    }
  };

  // const Locations=[
  //   "chennai",
  //   "kolkata",
  //   "Banglore",
  //   "kerala",
  //   "coimbatore",
  //   "Delhi",
  //   "kannur"
  // ]
  // const assessments=[
  //   "Project Management",
  //   "Testing",
  //   "Programming",
  //   "Code Development",
  //   "Web Development",
  //   "System Design",
  // ]
  // const Companies=[
  //   "Amazon",
  //   "Amazon Logistics",
  //   "TCS",
  //   "ZOHO",
  //   "WIPRO",
  //   "Atlassian",
  //   "Google"
  // ]

  // const Keywords=[
  //   "Ananya (17)",
  //   "Jhon Doe",
  //   "micheal",
  //   "antony",
  //   "Raj",
  //   "David"
  // ]
  // const Degrees =[
  //   "B.Tech",
  //   "Master Degree",
  //   "Post Graduate",
  //   "Diploma"
  // ]
  const [projects, setProjects] = useState([
    {
      id: 1,
      createdBy: "Ananya",
      postedDate: "24/09/2024",
      workplace: "Alchemy",
      jobTitle: "Senior UX Designer",
      status: "Active",
      pipelineCount: 17,
      applicants: { total: 15, new: 5 },
    },
    {
      id: 2,
      createdBy: "Jhon Doe",
      postedDate: "20/09/2024",
      workplace: "NovaByte Solutions",
      jobTitle: "Frontend Developer",
      status: "Inactive",
      pipelineCount: 10,
      applicants: { total: 8, new: 3 },
    },
    {
      id: 3,
      createdBy: "Sarah Connor",
      postedDate: "18/09/2024",
      workplace: "ZenithSoft Labs",
      jobTitle: "Backend Engineer",
      status: "Active",
      pipelineCount: 12,
      applicants: { total: 6, new: 2 },
    },
  ]);

  const toggleState = () => {
  showMoreLocations(prevState => {
    const newShowMoreState = !prevState;
    setSeeAllText(prevText => ({
      ...prevText,
      location: newShowMoreState ? 'view less' : 'view all'
    }));
    return newShowMoreState;
  });
};

const jobAllViewtoggle = () => {
  showMoreJobs(prevState => {
    const newShowMoreState = !prevState;
    setSeeAllText(prevText => ({
      ...prevText,
      job: newShowMoreState ? 'view less' : 'view all'
    }));
    return newShowMoreState;
  });
};

  const[seeAssessments,showMoreAssessments]=useState(false)
  const toggleAssesment = () => {
    showMoreAssessments(prevState => {
    const newShowMoreState = !prevState;
    setSeeAllText(prevText => ({
      ...prevText,
      skill: newShowMoreState ? 'view less' : 'view all'
    }));
    return newShowMoreState;
  });
    
  };
  const[seeCompanies,showMoreCompanies]=useState(false)
  const toggleCompany = () => {
    // showMoreCompanies((prevState) => !prevState);
    showMoreCompanies(prevState => {
      const newShowMoreState = !prevState;
      setSeeAllText(prevText => ({
        ...prevText,
        company: newShowMoreState ? 'view less' : 'view all'
      }));
      return newShowMoreState;
    });
    
  };
  const[seeKeywords,showMoreKeywords]=useState(false)
  const toggleKeywords = () => {
    // showMoreKeywords((prevState) => !prevState);
    showMoreKeywords(prevState => {
      const newShowMoreState = !prevState;
      setSeeAllText(prevText => ({
        ...prevText,
        keyword: newShowMoreState ? 'view less' : 'view all'
      }));
      return newShowMoreState;
    });
    
  };
  const[seeDegree,showMoreDegrees]=useState(false)
  const toggleDegree = () => {
    // showMoreDegrees((prevState) => !prevState);
    showMoreDegrees(prevState => {
      const newShowMoreState = !prevState;
      setSeeAllText(prevText => ({
        ...prevText,
        degree: newShowMoreState ? 'view less' : 'view all'
      }));
      return newShowMoreState;
    });
    
  };
  const goToSearch = () =>{
    navigate(-1)
  }
  const refreshJD = async() =>{
    var refreshData = {};
    if(aiSearchResult && aiSearchResult.basicdetail){
        refreshData = aiSearchResult.basicdetail;
    }
    console.log(aiSearchResult.basicdetail)

    if(!aiSearchResult || !aiSearchResult.basicdetail || !aiSearchResult.basicdetail.jd_id || !aiSearchResult.basicdetail.userId || !aiSearchResult.basicdetail.cid ){
        showNotification("Somedetails missing", "error");
        return
    }
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(refreshData), process.env.REACT_APP_SECRETKEY).toString();
            var searchData = { data:encryptedData}
            setIsLoading(true);
            try {
                const serverURL = process.env.REACT_APP_SERVER_URL;
                const response = await fetch(`${serverURL}/JD/refreshJd`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      token: token
                    },
                    body: JSON.stringify(searchData), 
                });
                const result = await response.json();
                console.log("Server Response:", result);
                if (!response.ok) {
                    throw new Error(result.error);
                }
                const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                // console.log(decryptedData);
                setAiSearchResult(decryptedData);
                console.log(decryptedData)
                showNotification(result.message, "success");
            } catch (error) {
                console.error("Error uploading file:", error);
                showNotification(error.message, "error");
            }finally {
                setIsLoading(false); // Always hide loader after the request
            }
  }



  const getResponse = async() =>{
    var jd_id = ""
    const encryptedMetadata = localStorage.getItem('metadata');
    if (encryptedMetadata){
        const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        if(decryptedMetadata.jd_id){
            jd_id = decryptedMetadata.jd_id;
        }
	if(decryptedMetadata && decryptedMetadata.search){
       	    setsearchTermState(decryptedMetadata.search)
        }
    }
    setJDvalue(jd_id);
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({jd_id:jd_id}), process.env.REACT_APP_SECRETKEY).toString();
    var searchData = { data:encryptedData}
    setIsLoading(true);
    try {
        const serverURL = process.env.REACT_APP_SERVER_URL;
        const response = await fetch(`${serverURL}/JD/getJdDetail`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              token: token
            },
            body: JSON.stringify(searchData), 
        });
        const result = await response.json();
        console.log("Server Response:", result);
        if (!response.ok) {
            throw new Error(result.error);
        }
        const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        if(decryptedData['message']){
            setProcessingMessage(decryptedData['message'])
            return;
        }
	     console.log(decryptedData,"diccc");
            setAiSearchResult(decryptedData?.[jd_id] ||'');
	    setResumeDetails(decryptedData[jd_id]?.['resumedetail'] || []);
      setLocation(decryptedData[jd_id]?.['filter_location'] || []);
      setSkill(decryptedData[jd_id]?.['filter_skill'] || []);
      setCompanies(decryptedData[jd_id]?.['filter_company'] || []);
      setKeywords(decryptedData[jd_id]?.['filter_keyword'] || []);
      setDegrees(decryptedData[jd_id]?.['filter_degree'] || []);
      setFitment(decryptedData[jd_id]?.['fitment'] || []);
        // console.log(decryptedData)
        // showNotification(result.message, "success");
    } catch (error) {
        console.error("Error uploading file:", error);
        showNotification(error.message, "error");
    }finally {
        setIsLoading(false); // Always hide loader after the request
    }
  }


  const getJobDetail = async() =>{
    var company_id = ""
    const encryptedMetadata = localStorage.getItem('metadata');
    if (encryptedMetadata){
        const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        if(decryptedMetadata.cid){
            company_id = decryptedMetadata.cid;
        }
    }
    console.log(company_id)
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({company_id:company_id,status:"active"}), process.env.REACT_APP_SECRETKEY).toString();
    var searchData = { data:encryptedData}
    setIsLoading(true);
    try {
        const serverURL = process.env.REACT_APP_SERVER_URL;
        const response = await fetch(`${serverURL}/job/fetchJobByStatus`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              token: token
            },
            body: JSON.stringify(searchData), 
        });
        const result = await response.json();
        console.log("Server Response:", result);
        if (!response.ok) {
            throw new Error(result.error);
        }
        const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        console.log(decryptedData,'jobbbb')
        setJobDetails(decryptedData);

        
        // console.log(decryptedData)
        // showNotification(result.message, "success");
    } catch (error) {
        console.error("Error uploading file:", error);
        showNotification(error.message, "error");
    }finally {
        setIsLoading(false); // Always hide loader after the request
    }
  }




  const locationCheckboxChange = (id, isChecked) => {
    console.log(id, isChecked);
    setLocationArr(prevItems => {
      if (isChecked) {
        return [...prevItems, Locations[id]];
      } else {
        return prevItems.filter(item => item !== Locations[id]);
      }
    });
  }
  const skillCheckboxChange = (id, isChecked) => {
    console.log(id, isChecked);
    setSkillArr(prevItems => {
      if (isChecked) {
        return [...prevItems, assessments[id]];
      } else {
        return prevItems.filter(item => item !== assessments[id]);
      }
    });
  }
  const companyCheckboxChange = (id, isChecked) => {
    console.log(id, isChecked);
    setCompanyArr(prevItems => {
      if (isChecked) {
        return [...prevItems, Companies[id]];
      } else {
        return prevItems.filter(item => item !== Companies[id]);
      }
    });
  }
  const keywordCheckboxChange = (id, isChecked) => {
    console.log(id, isChecked);
    setKeywordArr(prevItems => {
      if (isChecked) {
        return [...prevItems, Keywords[id]];
      } else {
        return prevItems.filter(item => item !== Keywords[id]);
      }
    });
  }
  const degreeCheckboxChange = (id, isChecked) => {
    console.log(id, isChecked);
    setDegreeArr(prevItems => {
      if (isChecked) {
        return [...prevItems, Degrees[id]];
      } else {
        return prevItems.filter(item => item !== Degrees[id]);
      }
    });
  }

    const isResumeDetailsEmpty = !ResumeDetails || Object.keys(ResumeDetails).length < 1;
    var totalResumeCount = 0;
    if(!isResumeDetailsEmpty){
        totalResumeCount = Object.keys(ResumeDetails).length
    }
    const saveToPipeline = (index,type,name,email) => {
           console.log(index,type)
           setResumeIdPipeline(index)
           setcandidateNamePipeline(name)
           setcandidateEmailPipeline(email)
           setResumeIdPipelineType(type)
           setJobModal(true);
           toggleDropdownPipeline(index)

   }
   const Modal_on_Close = () => {
        setJobModal(false);
        showMoreJobs(false);
        setSeeAllText(prevText => ({
          ...prevText,
          job: 'view all'
        }));
        setSelectedJob('');
        setSelectedJobTitle('');
    }
  const selectedJobChange = (event,title) => {
    setSelectedJob(event.target.value); // Update state on change
    setSelectedJobTitle(title);
  };

  const handlePipelineStatus = async() =>{
      if(!selectedJob){
        showNotification("Please select a job", "error");
        return;
      }
      const matchedResume = fitment.resumes.find(resume => resume.resume_id === resumeIdPipeline);
      const score = matchedResume ? matchedResume.score : null;
      const scoreFloat = Math.round(parseFloat(score) * 100);
      const JdData = {
            resume_id: resumeIdPipeline,
            candidate_id: "",
            job_id:selectedJob,
            type:resumeIdPipelineType,
            job_title:selectedJobTitle,
            name:candidateNamePipeline,
            candidate_mail:candidateEmailPipeline,
            score:scoreFloat
          };
          console.log("jddata:",JdData)
      
          const encryptedData = CryptoJS.AES.encrypt(
            JSON.stringify(JdData),
            process.env.REACT_APP_SECRETKEY
          ).toString();
          const encryptjson = { data: encryptedData };
          setIsLoading(true);
            try {
              const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/job/addPipeline`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    token: token,
                  },
                  body: JSON.stringify(encryptjson),
                }
              );
          
              const data = await response.json();
              if (!response.ok) {
                throw new Error(result.error);
              }
              const decryptedData = JSON.parse(
                CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8)
              );
              showNotification(
                `Candidate added to pipeline as ${resumeIdPipelineType}` ,
                "success"
              );
              setJobModal(false)
              showMoreJobs(false);
              setSeeAllText(prevText => ({
              ...prevText,
              job: 'view all'
            }));
              setSelectedJob('');
            } catch (error) {
              console.error("Error occurred:", error);
            }finally {
                setIsLoading(false); // Always hide loader after the request
            }
          
          
    }

    const filteredJobs = searchText.trim()
      ? jobDetails.filter((job) =>
          job.job_title && job.job_title.toLowerCase().includes(searchText.toLowerCase())
        )
      : jobDetails;

   const modalHTMLcontent = (
    <div>
      <div className="w-5/6 relative mb-2">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3">
          <svg
            class="w-5 h-5 mt-[0px] text-gray-400"
            fill="none"
            stroke="#d1fae5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
            ></path>
          </svg>
        </div>
        <Textinput onChange={(e) => setSearchText(e.target.value)} placeholder="Choose a job" labelclass="navbar_content hidden" label='Job Name' className="text-style-search pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-200 w-full !text-left"/>
      </div>
      <div className={`h-[150px] ${seeAllJobs ? "overflow-y-scroll" : ""}`}>
      {(seeAllJobs ? filteredJobs : filteredJobs.slice(0, 4)).map((job) => (
        <div key={job.job_id} className="">
          <RadioInput
            type="radio"
            className="hidden"
            name="selectedJob"
            checked={selectedJob == job.job_id}
            onChange={(e) =>selectedJobChange(e,job.job_title)}
            value={job.job_id}
            label={
              <div className="flex items-center">
                <span
                  className={`w-5 h-5 border-2 mr-2 rounded-lg ${
                    selectedJob == job.job_id
                      ? "bg-emerald-700 border-emerald-700"
                      : "border-gray-300"
                  }`}
                ></span>
                <span>{job.job_title}</span>
              </div>
            }
          />
        </div>
      ))}
    </div>
    {filteredJobs?.length > 5 && (
    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={jobAllViewtoggle}>{seeAllText['job']}...</p>
    )}
    <div className='flex flex-end justify-end gap-2'>
    <Button children="Cancel" onClick={Modal_on_Close} className="border-emerald-700 px-4 py-2 text-emerald-700 bg-white" />
    <Button children="Save" onClick={handlePipelineStatus} className="border-emerald-700 px-4 py-2 text-white" />
    </div>
    </div>
  )

	 console.log(ResumeDetails,"ressssss");
    return(<>
        <div>
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        <span className="text-white mt-4">Fetching...</span>
                    </div>
                </div>
            )}
            <Notification
                message={notification.message}
                type={notification.type}
                onClose={() => setNotification({ message: "", type: "" })}
            />
            <div className="bg-white p-4 m-4 rounded-lg flex gap-2 items-center">
                <div className="mb-0 flex gap-2 w-9/12">
                    <p className="mb-0 w-1/12">Filter By :</p>
                    <p className="mb-0 w-11/12 hidden">{aiSearchResult && aiSearchResult.basicdetail && aiSearchResult.basicdetail.search?aiSearchResult.basicdetail.search:"No Filter"}</p>
                    <p className="mb-0 w-11/12">{searchTermState ?searchTermState:"No Filter"}</p>
                </div>
                <div className="flex justify-end w-3/12 gap-2 items-center">
                    <Button
                      type="button"
                      className="bg-green-700 px-4 py-2 text-white"
                      onClick={goToSearch}
                      children="Back To Search"
                    />
                    <Button
                      type="button"
                      className="bg-transparent border-[1px] border-green-700 text-green-700 px-4 py-2 hidden"
                      onClick={refreshJD}
                      children="Refresh"
                    />
                </div>
            </div>
            {isResumeDetailsEmpty && !isFiltered ? (
              processingMessage == "Data Updation Processing" ? (
              <div className="bg-white m-4 rounded-lg text-center py-32">
      <p>Please wait while we process your request. Resume matching is in progress... Loading.. </p>
      <ClipLoader color="#3498db" size={50} />
    </div>
              ):(
              <div className="bg-white  m-4 rounded-lg text-center py-32">{processingMessage}</div>)
          ) : (
        <div className="flex">
            <div className="w-[24%] bg-white p-2 ml-6">
                <div className="flex px-4"><p className="text-[21px] font-bold mt-4">Filters</p>
                <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={20}
                height={20}
                className="ml-auto mt-[30px] hidden"
                fill="black"
                aria-hidden="true"
                >
                <circle cx="5" cy="12" r="2" />
                <circle cx="12" cy="12" r="2" />
                <circle cx="19" cy="12" r="2" />
                </svg></div>
                <Card CardFooterClass="px-6 py-2 bg-gray-100 justify-end gap-2 "  CardClass="min-w-md mx-auto !hidden bg-white shadow rounded-lg overflow-hidden w-11/12  mt-2 !rounded-xl"
                    body={<>
                    <Textinput placeholder="Search for a Project" labelclass="hidden" className="!w-full border-2 pl-10 pr-4 py-2.5 !border border-gray-150 rounded-md focus:outline-none focus:ring-2 text-black mt-1 focus:ring-cyan-200"/>
                    <div className="absolute h-10 inset-y-[32%]  flex pt-1  pl-3">
                    <svg
                      className="w-5 h-5 text-blue-500"
                      fill="none" 
                      stroke="#9effef"  
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
                      />
                    </svg>
                    
                </div>
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Location</p>
                    {
			Array.isArray(Locations) && Locations.length > 0 && (
                        Locations.slice(0,3).map((locationNew,index) => (
                          locationNew && locationNew != "" && locationNew != " " && (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex mt-2"
                            postlabel={locationNew}
                            postlabelclass="ml-2 leading-normal mb-0"
                            key={locationNew}
                            onChange={e => locationCheckboxChange(index, e.target.checked)}
                            />
                          )
                        ))
			)
                    }
                    
                    {seeLocations && Array.isArray(Locations) && Locations.length > 0 && (
                    Locations.slice(3).map((locationNew,index) => (
                      locationNew && locationNew != "" && locationNew != " " && (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex mt-2"
                        postlabel={locationNew}
                        postlabelclass="ml-2 leading-normal mb-0"
                        key={locationNew}
                        onChange={e => locationCheckboxChange(index + 3, e.target.checked)}
                        />
                    ))))}

			    {Array.isArray(Locations) && Locations.length > 3 && (
			<p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleState}>{seeAllText['location']}...</p>
			    )}
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Skills and assessments</p>
                    {
                       Array.isArray(assessments) && assessments.length > 0 && (
			assessments.slice(0,4).map((assessmentsNew,index) => (
        assessmentsNew && assessmentsNew != "" && assessmentsNew != " " && (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-5 h-5 mb-0 mt-1"
                            divclass="flex mt-2 items-start"
                            postlabel={assessmentsNew}
                            postlabelclass="ml-2 leading-normal mb-0"
                            key={assessmentsNew}
                            onChange={e => skillCheckboxChange(index, e.target.checked)}
                            />
                        ))))
                    }
                    
                    {seeAssessments && Array.isArray(assessments) && assessments.length > 0 && (
                    assessments.slice(4).map((assessmentsNew,index) => (
                      assessmentsNew && assessmentsNew != "" && assessmentsNew != " " && (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-5 h-5 mb-0 mt-1"
                        divclass="flex mt-2 items-start"
                        postlabel={assessmentsNew}
                        postlabelclass="ml-2 leading-normal mb-0"
                        key={assessmentsNew}
                        onChange={e => skillCheckboxChange(index + 4, e.target.checked)}
                        />
                    ))))}

                    {Array.isArray(assessments) && assessments.length > 4 && (
			    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleAssesment}>{seeAllText['skill']}...</p>
		    )}
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Companies</p>
                    {
                        Array.isArray(Companies) && Companies.length > 0 && (
			Companies.slice(0,2).map((company,index) => (
        company && company != "" && company != " " && (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-5 h-5 mb-0 mt-1"
                            divclass="flex mt-2 items-start"
                            postlabel={company}
                            postlabelclass="ml-2 leading-normal mb-0"
                            key={company}
                            onChange={e => companyCheckboxChange(index, e.target.checked)}
                            />
                        ))))
                    }
                    
                    {seeCompanies && Array.isArray(Companies) && Companies.length > 0 && (
                    Companies.slice(2).map((company,index) => (
                      company && company != "" && company != " " && (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-5 h-5 mb-0 mt-1"
                        divclass="flex mt-2 items-start"
                        postlabel={company}
                        postlabelclass="ml-2 leading-normal mb-0"
                        key={company}
                        onChange={e => companyCheckboxChange(index + 2, e.target.checked)}
                        />
                    ))))}

                    {Array.isArray(Companies) && Companies.length > 2 && (
			    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleCompany}>{seeAllText['company']}...</p>
		    )}
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Keywords</p>
                    {
                       Array.isArray(Keywords) && Keywords.length > 0 && (
			Keywords.slice(0,2).map((keyword,index) => (
        keyword && keyword != "" && keyword != " " && (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-5 h-5 mb-0 mt-1"
                            divclass="flex mt-2 items-start"
                            postlabel={keyword}
                            postlabelclass="ml-2 leading-normal mb-0"
                            key={keyword}
                            onChange={e => keywordCheckboxChange(index, e.target.checked)}
                            />
                        ))))
                    }
                    
                    {seeKeywords && Array.isArray(Keywords) && Keywords.length > 0 && (
                    Keywords.slice(2).map((keyword,index) => (
                      keyword && keyword != "" && keyword != " " && (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-5 h-5 mb-0 mt-1"
                        divclass="flex mt-2 items-start"
                        postlabel={keyword}
                        postlabelclass="ml-2 leading-normal mb-0"
                        key={keyword}
                        onChange={e => keywordCheckboxChange(index + 2, e.target.checked)}
                        />
                    ))))}

                    {Array.isArray(Keywords) && Keywords.length > 2 && (
			    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleKeywords}>{seeAllText['keyword']}...</p>
		    )}
                    
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Degrees</p>
                    {
                       Array.isArray(Degrees) && Degrees.length > 0 && (
			Degrees.slice(0,3).map((degree,index) => (
        degree && degree != "" && degree != " " &&(
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-5 h-5 mb-0 mt-1"
                            divclass="flex mt-2 items-start"
                            postlabel={degree}
                            postlabelclass="ml-2 leading-normal mb-0"
                            key={degree}
                            onChange={e => degreeCheckboxChange(index, e.target.checked)}
                            />
                        ))))
                    }
                    
                    {seeDegree && Array.isArray(Degrees) && Degrees.length > 0 && (
                    Degrees.slice(3).map((degree,index) => (
                      degree && degree != "" && degree != " " &&(
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-5 h-5 mb-0 mt-1"
                        divclass="flex mt-2 items-start"
                        postlabel={degree}
                        postlabelclass="ml-2 leading-normal mb-0"
                        key={degree}
                        onChange={e => degreeCheckboxChange(index + 3, e.target.checked)}
                        />
                    ))))}

                    {Array.isArray(Degrees) && Degrees.length > 3 && (
			    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleDegree}>{seeAllText['degree']}...</p>
		    )}
                    
                    </>}
                />
                {/* <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Candidates</p>
                    <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel="All"
                            postlabelclass="ml-2"
                            />
                             <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel="Eeezo Profiles"
                            postlabelclass="ml-2"
                            />
                             <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel="Resumebox"
                            postlabelclass="ml-2"
                            />
                    </>}
                /> */}
                
            </div>
            <div className="w-[72%] bg-white rounded-xl bg-opacity-50 p-1 ml-4">
             <div className="mt-4 px-[56px]">
             <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-4 !h-4 mb-0 mt-2 rounded-xl hidden"
                            divclass="flex h-6 mt-2"
                            postlabel={`${totalResumeCount} Matching Profiles Found`}
                            postlabelclass="ml-0 mb-0 text-[20px] font-bold"
                            />
            </div>
            <div id="divid">
          {isResumeDetailsEmpty && isFiltered ? (
            <div>No Resume Data</div>
            ):( <div>
          {projects.map((project,index) => (
            <Card CardFooterClass="py-2 bg-gray-100 flex justify-end gap-2 " CardClass="min-w-md mx-auto px-2 bg-white shadow rounded-lg overflow-hidden mt-4 mb-4 !rounded-xl shadow-none border w-[850px] !hidden"
            body={<>
            <div className="flex">
            <div className="w-[60%]">    
            <div className="flex ml-2">
                <div className="flex"><Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-4 !h-4 mb-0 mt-10 rounded-xl"
                            divclass="flex h-6 mt-2"
                            /><img src={Person} className="w-[86px] h-[86px] rounded-full object-cover ml-4 mt-2"></img>
                </div>
                

                <div className="ml-4">
                    <p className="text-xl font-medium text-black mt-3 mb-0">Sharad Bhat</p>
                    <p className="text-sm mb-0">UI/UX Designer</p>
                    <p className="text-xs mt-1">Bengaluru,Karnataka</p>
                </div>
            </div>
            <div className="mt-4 flex">
              <p className="ml-2 text-black text-[12px]">Experience</p>
              <div className="ml-14">
                <p className="text-[#000000] opacity-50 mb-0 text-[12px] font-medium">4+ years of experience in UX design</p>
                <p className="text-black text-[12px] flex mb-0"><span className="text-green-700 font-medium mr-1">Senior UX Designer</span> at NovaByte Solutions <li className="ml-2 mb-0 text-gray-700">Current</li></p>
                <p className="text-black text-[12px] flex mb-0">UX Designer CodeSphere Technologies <li className=" mb-0 text-gray-700 ml-2">2021-2022</li></p>
                <p className="text-black text-[12px] flex mb-0">UX Designer BlueShift Dynamics <li className=" mb-0 text-gray-700 ml-2">2019-2021</li></p>
                <p className="text-[10px] mt-1 flex opacity-60 hover:cursor-pointer">Show more ({8})<svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" className="mt-[5px] ml-[1px]" viewBox="0 0 8 5" fill="none">
                <path d="M4 4.5C3.85938 4.5 3.73438 4.45312 3.64062 4.35938L0.640625 1.35938C0.4375 1.17188 0.4375 0.84375 0.640625 0.65625C0.828125 0.453125 1.15625 0.453125 1.34375 0.65625L4 3.29688L6.64062 0.65625C6.82812 0.453125 7.15625 0.453125 7.34375 0.65625C7.54688 0.84375 7.54688 1.17188 7.34375 1.35938L4.34375 4.35938C4.25 4.45312 4.125 4.5 4 4.5Z" fill="#747474"/>
                </svg></p>
              </div>
              <div className="absolute ml-[560px] px-[16px] py-[16px] inset-28 mt-8  bg-green-50 shadow-lg shadow-none  w-[135px] h-[74px] pb-1  rounded-xl ">
                <div className="relative size-10 mt-1">
                <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">

                    <circle cx="18" cy="18" r="16" fill="none" className="stroke-current w-[42px] h-[42px] text-gray-200 dark:text-neutral-700" strokeWidth="4"></circle>

                    <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-teal-400 w-[42px] h-[42px] dark:text-blue-500" strokeWidth="4" stroke-dasharray="100" stroke-dashoffset="6" stroke-linecap="round"></circle>
                </svg>

                </div>
  
     
                <div className="absolute rounded-lg inset-2 ml-8  mr-2 flex   justify-center  text-gray-700 dark:text-white">
                    
                    <div className="h-14">
                      <p className="mb-0 text-black text-[24px] font-medium mt-1 ml-3">{94}%</p>
                      
                    </div>
                </div>
                <p className="text-[14px] mb-0 absolute inset-y-9 ml-12">Match</p>
                
                </div>
            </div>
            <div className="mt-2 flex">
              <p className="ml-2 text-black text-[12px]">Education</p>
              <div className="ml-14">
                
                <p className="text-black flex text-[12px] mb-0 pl-1">Bachelor of Design, Srishti Manipal Institute<li className=" mb-0 text-gray-700 ml-2">2014-2017</li></p>
                <p className="text-black flex text-[12px] mb-0 pl-1">Masters Strategic Design Management, NID<li className=" mb-0 text-gray-700 ml-2">2017-2019</li></p>
              </div>
            </div>
            <div className="mt-4 flex">
                <div className="w-[25%]">
              <p className="ml-2 text-black text-[12px]">Skills Match</p></div>
              <div className="whitespace-nowrap">
                
                <p className="flex text-[12px] text-green-700 mb-0 font-medium">User Research <li className="text-[12px] ml-2">User-Centered Design Thinking</li></p>
              </div>
            </div>
            <div className="mt-1 flex mb-4">
              <p className="ml-2 text-black text-[12px]">Activity</p>
              <div className="ml-16">
                
                <p className="text-black flex text-[12px] ml-2 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, Masters StrLorem ipsum dolor sit amet, consectetur adipiscing elit, ategic Design Management, NID</p>
                
              </div>
            </div>
            </div>
            <div className="w-[40%] mt-1 flex">
                <div className=" text-left">
  <div className="pl-8">
    <Button
      type="button"
      className="inline-flex w-[190px] justify-center gap-x-1.5 rounded-md bg-white px-3 border-green-500  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      id="menu-button"
      aria-expanded={isOpen}
      aria-haspopup="true"
      onClick={()=>toggleDropdownPipeline(index)}
    >
      <p className="mt-2 mb-0 text-[16px] font-medium text-green-600">Save to Pipeline</p>
      <svg
        className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-green-600"
        viewBox="0 0 20 20"
        fill="green"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
          clipRule="evenodd"
        />
      </svg>
    </Button>
  
  

  
  {isOpen===index && (
    <div
      className="absolute left-34  z-10 !w-[190px] mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div className="p-1 space-y-0.5">
  <a className="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
    Uncontacted
  </a>
  <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
    Contacted
  </a>
  <a className="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
    Replied
  </a>
  
</div>
    </div>
  )}
  {isOpenMessage===index && (
    <div 
      className="absolute left-[58%]  z-10 w-4/12 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div className="p-1 space-y-0.5">
  <a className="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
    Archieve Candidate
  </a>
  <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
    Add Note
  </a>
  <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
  Share For Review
  </a>
  <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
  Save To Another Project
  </a>
  <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
  Save To Pdf
  </a>
  <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
  Save To Another Project
  </a>
  <a className="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
  Find More People Like This
  </a>
  
</div>
    </div>
  )}
</div>

                </div>
                <Button type="button" className="!bg-gray-100 inline-flex ml-3 h-9 mt-1 justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={()=>toggleDropdownMessage(index)}>
            <img src="/image/message.png" className='w-5' />
        </Button>
        <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" className="inline-flex items-center p-1 h-3 ml-2   text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
        <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={20}
                height={20}
                className="ml-auto mt-[30px]"
                fill="black"
                aria-hidden="true"
                >
                <circle cx="5" cy="12" r="2" />
                <circle cx="12" cy="12" r="2" />
                <circle cx="19" cy="12" r="2" />
                </svg>
</button>






            </div>
            </div>
            </>}
            />
          ))}
          
            {Object.entries(ResumeDetails).map(([project, data], index) => (
          <Card CardFooterClass=" !bg-[#059669] !rounded-l-none w-1/5 ml-auto flex justify-end" CardClass="min-w-md mx-auto pl-2 bg-white shadow rounded-lg overflow-hidden mt-4 mb-4 !rounded-3xl shadow-none border w-[850px]"
           body={
            <>
            <div className="flex">
            <div className="">
            <p className="text-[24px] font-medium mb-0">{data?.candidate?.name || "Name Not Provided"}</p>
            <p className="text-[14px]">{data?.candidate?.objective || ""}</p>
            <p className="text-[16px] font-semibold mb-0">Contact Details</p>
            
            <p className="flex text-[16px]"><div className="flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" className="mt-2 mr-2" viewBox="0 0 15 11" fill="none">
<path d="M14 2V9C14 9.39782 13.842 9.77936 13.5607 10.0607C13.2794 10.342 12.8978 10.5 12.5 10.5H2.5C2.10218 10.5 1.72064 10.342 1.43934 10.0607C1.15804 9.77936 1 9.39782 1 9V2M14 2C14 1.60218 13.842 1.22064 13.5607 0.93934C13.2794 0.658035 12.8978 0.5 12.5 0.5H2.5C2.10218 0.5 1.72064 0.658035 1.43934 0.93934C1.15804 1.22064 1 1.60218 1 2M14 2V2.162C14 2.4181 13.9345 2.66994 13.8096 2.89353C13.6848 3.11712 13.5047 3.30502 13.2867 3.43933L8.28667 6.516C8.05014 6.66169 7.7778 6.73883 7.5 6.73883C7.2222 6.73883 6.94986 6.66169 6.71333 6.516L1.71333 3.44C1.49528 3.30569 1.31525 3.11779 1.19038 2.8942C1.06551 2.6706 0.999972 2.41876 1 2.16267V2" stroke="#059669" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
              
              <p className="mt-[1px]">{data?.candidate?.email || "Email Not Provided"}</p></div>
<div className="flex ml-4">
<svg xmlns="http://www.w3.org/2000/svg" className="mt-1.5 mr-2" width="15" height="15" viewBox="0 0 15 15" fill="none">
<path d="M1 4C1 9.52267 5.47733 14 11 14H12.5C12.8978 14 13.2794 13.842 13.5607 13.5607C13.842 13.2794 14 12.8978 14 12.5V11.5853C14 11.2413 13.766 10.9413 13.432 10.858L10.4833 10.1207C10.19 10.0473 9.882 10.1573 9.70133 10.3987L9.05467 11.2607C8.86667 11.5113 8.542 11.622 8.248 11.514C7.15659 11.1128 6.16544 10.4791 5.34319 9.65681C4.52094 8.83457 3.88725 7.84341 3.486 6.752C3.378 6.458 3.48867 6.13333 3.73933 5.94533L4.60133 5.29867C4.84333 5.118 4.95267 4.80933 4.87933 4.51667L4.142 1.568C4.10143 1.4058 4.00781 1.2618 3.87604 1.15889C3.74426 1.05598 3.58187 1.00006 3.41467 1H2.5C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V4Z" stroke="#059669" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  <p className='break-words w-full'>{data?.candidate?.phone_number ? (
    data.candidate.phone_number.split(",").map((phone, index) => (
      <p key={index} className="leading-normal break-words">{phone.trim()}</p>
    ))
  ) : (
    <p>Phone No Not Provided</p>
  )}</p>
  </div></p></div>
    <div className="ml-auto">
      <div className="flex">
    <div className="pl-8">
    <Button
      type="button"
      className="inline-flex w-[190px] justify-center gap-x-1.5 rounded-md bg-white px-3 border-green-500  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      id="menu-button"
      aria-expanded={isOpen}
      aria-haspopup="true"
      onClick={()=>toggleDropdownPipeline(index)}
    >
      <p className="mt-2 mb-0 text-[14px] font-medium text-green-600">Save to Pipeline</p>
      <svg
        className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-green-600"
        viewBox="0 0 20 20"
        fill="green"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
          clipRule="evenodd"
        />
      </svg>
    </Button>
  
  

  
  {isOpen===index && (
    <div
      className="absolute left-34  z-10 !w-[190px] mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div className="p-1 space-y-0.5">
  <a className="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" onClick={() => saveToPipeline(data.doc_id,'uncontacted',data?.candidate?.name||'',data?.candidate?.email||'')}>
    Uncontacted
  </a>
  <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" onClick={() => saveToPipeline(data.doc_id,'contacted',data?.candidate?.name||'',data?.candidate?.email||'')}>
    Contacted
  </a>
  <a className="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" onClick={() => saveToPipeline(data.doc_id,'replied',data?.candidate?.name||'',data?.candidate?.email||'')}>
    Replied
  </a>
  
</div>
    </div>
  )}
    </div>
    <div>
    <button onClick={()=>toggleDropdownViewResume(index)} id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" className="inline-flex items-center p-1 h-3 ml-2   text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
        <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={20}
                height={20}
                className="ml-auto mt-[30px]"
                fill="black"
                aria-hidden="true"
                >
                <circle cx="5" cy="12" r="2" />
                <circle cx="12" cy="12" r="2" />
                <circle cx="19" cy="12" r="2" />
                </svg>
</button>
{viewResumeOpen===index && (
    <div
      className="absolute right-0  z-10 !w-[150px] mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div className="p-1 space-y-0.5">
      <a
          className="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3 text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline"
          target="_blank"
          href={
            data && data["doc_id"] && decryptedMetadataAll && decryptedMetadataAll['cid'] && decryptedMetadataAll['uid']
              ? `${process.env.REACT_APP_SERVER_BASEURL}/resumes/${decryptedMetadataAll['cid']}/resume/${data.doc_id}.pdf`
              : "#"
          }
        >
          View Resume
    </a>
  
</div>
    </div>
  )}
</div>
</div>

    </div>
  </div>
            </>
           }
           footer={
            <>
            <p className="mb-0 text-white">From Resumebox</p>
            </>
           }
          />
          ))}
          </div>)}
        </div>
            </div>
            </div>
            )}
            {JobModal && 
              <ModalBox Modal_Header="Choose Job" modal_Header_Text="text-2xl mb-0" cardClass="no-max-width w-3/6" Modal_Body={modalHTMLcontent} Modal_Close_Func={Modal_on_Close} modal_Header_Class="!px-0 pb-4" />}
            </div>

        </> 
    );
}
export default HomeSearchProfiles;
