// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font-family{
	font-family: Sans-serif;
}
.border-radient-green {
	border:3px solid rgba(52, 211, 153, 0.9);
	box-shadow: 0px 4px 40px 0px #34d3995e;
}`, "",{"version":3,"sources":["webpack://./src/components/JobPost/JobDashboard.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;AACxB;AACA;CACC,wCAAwC;CACxC,sCAAsC;AACvC","sourcesContent":[".font-family{\n\tfont-family: Sans-serif;\n}\n.border-radient-green {\n\tborder:3px solid rgba(52, 211, 153, 0.9);\n\tbox-shadow: 0px 4px 40px 0px #34d3995e;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
