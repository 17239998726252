import { useState } from "react";
export default function Filterdrop({filterClass,drop_data,id,search}){
    
    const [searchTerm, setSearchTerm] = useState('');
    const [showFilters, setShowFilters] = useState(4)

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const ulItems = Object.keys(drop_data).map(key => drop_data[key]).slice(0, showFilters);

    const filterDropData = () => {
        if (!searchTerm) {
            return ulItems.map((item, index) => (
                <li className={`hover:bg-gray-100 py-2 pl-2 cursor-pointer ${filterClass}`} key={index}>
                    <label className={`text-[#1D1D1D] text-[16px] plusJakartaSansFont flex gap-2 cursor-pointer ${filterClass}`}>
                        <input type="checkbox" className={`h-[20px] w-[20px] cursor-pointer ${filterClass}`} id={item} />
                        {item}
                    </label>
                </li>
            ));
        }

        const filteredData = ulItems
            .filter(item => item.toLowerCase().startsWith(searchTerm.toLowerCase()))
            .map((item, index) => (
                <li className={`hover:bg-gray-100 py-2 pl-2 cursor-pointer ${filterClass}`} key={index}>
                    <label className={`text-[#1D1D1D] text-[16px] plusJakartaSansFont flex gap-2 cursor-pointer ${filterClass}`}>
                        <input type="checkbox" className={`h-[20px] w-[20px] cursor-pointer ${filterClass}`} id={item} />
                        {item}
                    </label>
                </li>
            ));

        return filteredData;
    };
    return(
        <div id={id} className={`z-10 p-[20px] bg-white rounded-lg shadow dark:bg-gray-700 absolute ${filterClass}`} >
            {search&&(
                <div className={`flex gap-2 border-1 border-[#BFE0E2] p-2 rounded-lg items-center ${filterClass}`}>
                    <svg className={`w-5 h-5 ${filterClass}`} fill="none" stroke="#0000004D" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"/></svg>
                    <input className={`border-0 outline-none ${filterClass}`} type="text" placeholder="Choose a job" onInput={handleSearchChange} />
                </div>
            )}
            <ul className={`text-sm p-0 pt-2 ${filterClass}`} aria-labelledby="dropdownDefaultButton" id={id+'_drops'} >
                {filterDropData()}
            </ul>
            <div className={filterClass}>
                {drop_data.length > showFilters &&(
                    <span className={`text-[#059669] text-[12px] pl-2 cursor-pointer ${filterClass}`} onClick={() => setShowFilters(drop_data.length)}>View All</span>
                )}
                {drop_data.length == showFilters &&(
                    <span className={`text-[#059669] text-[12px] pl-2 cursor-pointer ${filterClass}`} onClick={() => setShowFilters(4)}>View Less</span>
                )}
            </div>
        </div>
    )
}