import React from "react";
import ManageWorkspace from "../../components/CandidateProfile/ManageWorkspace"
import Topbar from "../../components/Recruiter/Topbar/Topbar";

const Workspace = () =>{
    return(
        <div className="radial-gradient-bg">
            <Topbar />
            <ManageWorkspace/>
        </div>
    );
};
export default Workspace;