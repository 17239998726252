// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
    .mobile-no-margin {
        margin: 0 !important;
    }
    .mobile-full-width {
        width: 100% !important;
    }
    .mobile-flex-column {
        flex-direction: column !important;
    }
    .MinimumCheckbox {
        width: 5% !important;
    }
    .RequireControl {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
    .MinimumTextValue {
        width: 50% !important;
        height: 30px;
    }
    .ResponseType {
        width: 30% !important;
    }
    .mobilepadding-10 {
        padding: 10px !important;
    }
    .mobile-gap-15 {
        gap: 15px;
    }
}

/* Custom class for the lazy-loading container */
.lazy-load {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.6s ease, transform 0.6s ease;
    visibility: hidden;
  }
  
  /* Class to trigger the animation */
  .lazy-load.show {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Web.css"],"names":[],"mappings":"AAAA;IACI;QACI,oBAAoB;IACxB;IACA;QACI,sBAAsB;IAC1B;IACA;QACI,iCAAiC;IACrC;IACA;QACI,oBAAoB;IACxB;IACA;QACI,aAAa;QACb,yBAAyB;QACzB,WAAW;IACf;IACA;QACI,qBAAqB;QACrB,YAAY;IAChB;IACA;QACI,qBAAqB;IACzB;IACA;QACI,wBAAwB;IAC5B;IACA;QACI,SAAS;IACb;AACJ;;AAEA,gDAAgD;AAChD;IACI,UAAU;IACV,2BAA2B;IAC3B,kDAAkD;IAClD,kBAAkB;EACpB;;EAEA,mCAAmC;EACnC;IACE,UAAU;IACV,wBAAwB;IACxB,mBAAmB;EACrB","sourcesContent":["@media only screen and (max-width: 600px) {\n    .mobile-no-margin {\n        margin: 0 !important;\n    }\n    .mobile-full-width {\n        width: 100% !important;\n    }\n    .mobile-flex-column {\n        flex-direction: column !important;\n    }\n    .MinimumCheckbox {\n        width: 5% !important;\n    }\n    .RequireControl {\n        display: flex;\n        justify-content: flex-end;\n        width: 100%;\n    }\n    .MinimumTextValue {\n        width: 50% !important;\n        height: 30px;\n    }\n    .ResponseType {\n        width: 30% !important;\n    }\n    .mobilepadding-10 {\n        padding: 10px !important;\n    }\n    .mobile-gap-15 {\n        gap: 15px;\n    }\n}\n\n/* Custom class for the lazy-loading container */\n.lazy-load {\n    opacity: 0;\n    transform: translateY(30px);\n    transition: opacity 0.6s ease, transform 0.6s ease;\n    visibility: hidden;\n  }\n  \n  /* Class to trigger the animation */\n  .lazy-load.show {\n    opacity: 1;\n    transform: translateY(0);\n    visibility: visible;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
