// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle-div {
    width: 40px;
    height: 40px;
    background: #059669;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 15px;
  }
  
  .circle-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .time-picker-container {
    display: flex;
    gap: 10px; 
    margin-left: 20px; 
  }
  
  .time-picker {
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .Weekly-Div {
    width: 50%;
  }`, "",{"version":3,"sources":["webpack://./src/components/JobPost/JobSchedule.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,SAAS;IACT,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,sBAAsB;IACtB,kBAAkB;EACpB;EACA;IACE,UAAU;EACZ","sourcesContent":[".circle-div {\n    width: 40px;\n    height: 40px;\n    background: #059669;\n    color: white;\n    font-weight: bold;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 50%;\n    font-size: 15px;\n  }\n  \n  .circle-container {\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n  }\n  \n  .time-picker-container {\n    display: flex;\n    gap: 10px; \n    margin-left: 20px; \n  }\n  \n  .time-picker {\n    padding: 5px;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  }\n  .Weekly-Div {\n    width: 50%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
