import React, { useState, useEffect, useRef, useContext } from "react";
import Button from "../elements/Button/Button";
import Card from "../elements/Card/Card";
import Textinput from "../elements/TextInput/TextInput";
import RadioInput from "../elements/RadioInput/RadioInput";
import Selectbox from "../elements/SelectBox/SelectBox";
import Overlay from "../Team/Overlayer";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import ButtonSolid from "../elements/Button/ButtonSolid";
import { useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import ClipLoader from "react-spinners/ClipLoader";

export default function ResumeUpload() {
  const { token } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [showoverlay, setshowoverlay] = useState(true);
  const [seeLocations, showMoreLocations] = useState(false);
  const [seeAllText, setSeeAllText] = useState({
    location: "view all",
    job: "view all",
  });
  const [seeAssessments, showMoreAssessments] = useState(false);
  const [locationArr, setLocationArr] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResume, setTotalResume] = useState(0);
  const [isLoadingLoader, setIsLoadingLoader] = useState(false);
  const [lastDocID, setlastDocID] = useState(["", ""]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resumeDetails, setResumeDetails] = useState([]);
  const [addPageCount, setAddpageCount] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [searchText, setSearchText] = useState("");
  const [resumeId, setResumeId] = useState(0);

  const toggleAssesment = () => {
    showMoreAssessments((prevState) => !prevState);
  };

  const [seeCompanies, showMoreCompanies] = useState(false);
  const toggleCompany = () => {
    showMoreCompanies((prevState) => !prevState);
  };
  const [seeKeywords, showMoreKeywords] = useState(false);
  const toggleKeywords = () => {
    showMoreKeywords((prevState) => !prevState);
  };
  const [seeDegree, showMoreDegrees] = useState(false);
  const toggleDegree = () => {
    showMoreDegrees((prevState) => !prevState);
  };

  const locationCheckboxChange = (type,id, isChecked,key) => {
    console.log(filteredResumes);
    console.log(type,id, isChecked,key);
    setLocationArr((prevItems) => {
      if (isChecked) {
        return [...prevItems, Locations[id]];
      } else {
        return prevItems.filter((item) => item !== Locations[id]);
      }
    });
  };
  const containerRef = useRef(null);
  var [progressBar, setProgressBar] = useState("94");

  var [detailedView, setDetailedView] = useState(false);

  const closeDetailedView = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setDetailedView(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    // getAllResume();
  }, []);

  useEffect(() => {
    if (detailedView) {
      document.addEventListener("click", closeDetailedView);
    } else {
      document.removeEventListener("click", closeDetailedView);
    }

    return () => {
      document.removeEventListener("click", closeDetailedView);
    };
  }, [detailedView]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const filterData = async () => {
    console.log(checkedValue, "checked");
    if (checkedValue.jd_id == "") {
      return;
    }
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(checkedValue),
      process.env.REACT_APP_SECRETKEY
    ).toString();
    var searchData = { data: encryptedData };
    setIsLoadingLoader(true);
    try {
      const serverURL = process.env.REACT_APP_SERVER_URL;
      const response = await fetch(`${serverURL}/JD/filterResume`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify(searchData),
      });
      const result = await response.json();
      console.log("Server Response:", result);
      if (!response.ok) {
        throw new Error(result.error);
      }
      const decryptedData = JSON.parse(
        CryptoJS.AES.decrypt(
          result.data,
          process.env.REACT_APP_SECRETKEY
        ).toString(CryptoJS.enc.Utf8)
      );
      if (decryptedData["message"]) {
        setProcessingMessage(decryptedData["message"]);
        return;
      }
      console.log(decryptedData, "diccc");
      setAiSearchResult(decryptedData[JDvalue]);
      setResumeDetails(decryptedData[JDvalue]["resumedetail"]);
      // console.log(decryptedData)
      showNotification(result.message, "success");
    } catch (error) {
      console.error("Error uploading file:", error);
      showNotification(error.message, "error");
    } finally {
      setIsLoadingLoader(false); // Always hide loader after the request
    }
  };

  const Locations = [
    "chennai",
    "kolkata",
    "Banglore",
    "kerala",
    "coimbatore",
    "Delhi",
    "kannur",
  ];
  const assessments = [
    "Project Management",
    "Testing",
    "Programming",
    "Code Development",
    "Web Development",
    "System Design",
  ];
  const Companies = [
    "Amazon",
    "Amazon Logistics",
    "TCS",
    "ZOHO",
    "WIPRO",
    "Atlassian",
    "Google",
  ];

  const Keywords = [
    "Ananya (17)",
    "Jhon Doe",
    "micheal",
    "antony",
    "Raj",
    "David",
  ];
  const Degrees = ["B.Tech", "Master Degree", "Post Graduate", "Diploma"];
  const [projects, setProjects] = useState([
    {
      id: 1,
      createdBy: "Ananya",
      postedDate: "24/09/2024",
      workplace: "Alchemy",
      jobTitle: "Senior UX Designer",
      status: "Active",
      pipelineCount: 17,
      applicants: { total: 15, new: 5 },
    },
    {
      id: 2,
      createdBy: "Jhon Doe",
      postedDate: "20/09/2024",
      workplace: "NovaByte Solutions",
      jobTitle: "Frontend Developer",
      status: "Inactive",
      pipelineCount: 10,
      applicants: { total: 8, new: 3 },
    },
    {
      id: 3,
      createdBy: "Sarah Connor",
      postedDate: "18/09/2024",
      workplace: "ZenithSoft Labs",
      jobTitle: "Backend Engineer",
      status: "Active",
      pipelineCount: 12,
      applicants: { total: 6, new: 2 },
    },
  ]);

  const toggleState = () => {
    showMoreLocations((prevState) => {
      const newShowMoreState = !prevState;
      setSeeAllText((prevText) => ({
        ...prevText,
        location: newShowMoreState ? "view less" : "view all",
      }));
      return newShowMoreState;
    });
  };
  useEffect(() => {
    getAllResume();
  }, [currentPage]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [skillsFilter, setSkillsFilter] = useState([]);
  const [companiesFilter, setCompaniesFilter] = useState([]);
  const [keywordFilter, setKeywordFilter] = useState([]);
  const getAllResume = async () => {
    let user_id;
    const encryptedMetadata = localStorage.getItem("metadata");
    const decryptedMetadata = JSON.parse(
      CryptoJS.AES.decrypt(
        encryptedMetadata,
        process.env.REACT_APP_SECRETKEY
      ).toString(CryptoJS.enc.Utf8)
    );
    if (decryptedMetadata.uid) user_id = decryptedMetadata.uid;
    // const page_start = (currentPage - 1) * 10;
    const offset = 10;
    const JLData = {
      user_id: user_id,
      last_doc_id: lastDocID[currentPage],
      offset: offset,
    };

    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(JLData),
      process.env.REACT_APP_SECRETKEY
    ).toString();
    const encryptjson = { data: encryptedData };
    setIsLoadingLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/JD/getAllResume`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
          body: JSON.stringify(encryptjson),
        }
      );

      const status = await response.json();
      const JobListData = JSON.parse(
        CryptoJS.AES.decrypt(
          status.data,
          process.env.REACT_APP_SECRETKEY
        ).toString(CryptoJS.enc.Utf8)
      );
      let lastDocIDGet;
      if (JobListData.resumes && JobListData.resumes.length > 0) {
        setLocationFilter((prev) => [
          ...new Set([
            ...prev,
            ...JobListData.resumes
              .map((loc) => 
                loc.location && loc.location.includes(",") 
                  ? loc.location.split(",")[0]?.trim()
                  : null
              )
              .filter((loc) => loc),
          ]),
        ]);
        setSkillsFilter((prev) => [
          ...new Set([
            ...prev,
            ...JobListData.resumes
              .map((skill) => 
                skill.skills && skill.skills.includes(",") 
                  ? skill.skills.split(",")[0]?.trim()
                  : null
              )
              .filter((skill) => skill),
          ]),
        ]);
        setCompaniesFilter((prev) => [
          ...new Set([
            ...prev,
            ...JobListData.resumes
              .map((com) => 
                com.companies_worked_at && com.companies_worked_at.includes(",") 
                  ? com.companies_worked_at.split(",")[0]?.trim()
                  : null
              )
              .filter((com) => com),
          ]),
        ]);
        setKeywordFilter((prev) => [
          ...new Set([
            ...prev,
            ...JobListData.resumes
              .map((key) => 
                key.profile && key.profile.includes(",") 
                  ? key.profile.split(",")[0]?.trim()
                  : null
              )
              .filter((key) => key),
          ]),
        ]);
        setResumeDetails(JobListData.resumes);
        let lengthOfData = JobListData.resumes.length - 1;
        lastDocIDGet = JobListData.resumes[lengthOfData]?.doc_id || null;
        if (addPageCount) setTotalPages(totalPages + 1);
      }
      setlastDocID((prevNumbers) => {
        if (!prevNumbers.includes(lastDocIDGet)) {
          return [...prevNumbers, lastDocIDGet];
        }
        return prevNumbers;
      });
      console.log(JobListData, "JobListData");
      // const totalJobs = JobListData.totalCount;
      // setTotalResume(totalJobs);
      // setTotalPages(Math.ceil(totalJobs / offset));
    } catch (err) {
      console.error("Error Fetching Resume List:", err);
    } finally {
      setIsLoadingLoader(false); // Always hide loader after the request
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    setTimeout(() => window.scrollTo(0, 0), 0);
    setAddpageCount(true);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
    setAddpageCount(false);
  };

  const filteredResumes = searchText.trim()
    ? resumeDetails.filter(
        (resume) =>
          (resume.name &&
            resume.name.toLowerCase().includes(searchText.toLowerCase())) ||
          (resume.email &&
            resume.email.toLowerCase().includes(searchText.toLowerCase())) ||
          (resume.phone &&
            resume.phone.toLowerCase().includes(searchText.toLowerCase()))
      )
    : resumeDetails;
  let uid;
  let cid;
  const encryptedMetadata = localStorage.getItem("metadata");
  const decryptedMetadata = JSON.parse(
    CryptoJS.AES.decrypt(
      encryptedMetadata,
      process.env.REACT_APP_SECRETKEY
    ).toString(CryptoJS.enc.Utf8)
  );
  if (decryptedMetadata.cid) {
    cid = decryptedMetadata.cid;
  }
  if (decryptedMetadata.uid) {
    uid = decryptedMetadata.uid;
  }

  // console.log(filteredResumes[resumeId]['name'])
  return (
    <div>
      {isLoadingLoader && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="flex flex-col items-center">
            <ClipLoader
              color="#3498db"
              loading={setIsLoadingLoader}
              size={50}
            />
            <span className="text-white mt-4">Loading...</span>
          </div>
        </div>
      )}
      <div className="grid grid-cols-[330px_auto] px-4">
        <div className="p-4">
          <div className="flex justify-between items-center">
            <h4 className="m-0 plusJakartaSansFont font-bold text-[21.83px] text-[#181F47]">
              Filters
            </h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={25}
              height={25}
              className=""
              fill="#8A8A8A"
              aria-hidden="true"
            >
              <circle cx="5" cy="12" r="2" />
              <circle cx="12" cy="12" r="2" />
              <circle cx="19" cy="12" r="2" />
            </svg>
          </div>
          <div
            className="mt-4"
            style={{
              padding: "16px 24px",
              boxShadow: "1px 4px 14px 0px #0000000D",
            }}
          >
            <div className="relative">
              <svg
                className="w-5 h-5 absolute top-1/4 left-3"
                fill="none"
                stroke="#059669"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
                ></path>
              </svg>
              <input
                type="text"
                placeholder="Search"
                className="w-full !ps-[40px] bg-white font-normal text-sm text-[#747474] outline-none"
                style={{
                  border: "1px solid #DBDBDB80",
                  borderRadius: "6px",
                  padding: "10px 16px",
                }}
              />
            </div>
          </div>
          <Card
            CardFooterclassName="px-2 py-2 bg-gray-100 flex justify-end  "
            CardclassName="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
            body={
              <>
                <p className="mb-0 font-medium">Location</p>
                {locationFilter.slice(0, 3).map((location, index) => (
                  <Textinput
                    type="checkbox"
                    labelclass="hidden"
                    className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                    divclass="flex h-6 mt-2 truncate"
                    postlabel={location}
                    postlabelclass="ml-2 mb-0 truncate"
                    key={location}
                    onChange={(e) =>
                      locationCheckboxChange("location", index, e.target.checked, location)
                    }
                  />
                ))}

                {seeLocations &&
                  locationFilter.slice(3).map((location, index) => (
                    <Textinput
                      type="checkbox"
                      labelclass="hidden"
                      className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                      divclass="flex h-6 mt-2 truncate"
                      postlabel={location}
                      postlabelclass="ml-2 mb-0 truncate"
                      key={location}
                      onChange={(e) =>
                        locationCheckboxChange("location",index + 3, e.target.checked, location)
                      }
                    />
                  ))}

                <p
                  className="text-sm hover:cursor-pointer mt-2 text-[#059669]"
                  onClick={toggleState}
                >
                  {seeAllText["location"]}...
                </p>
              </>
            }
          />
          <Card
            CardFooterclassName="px-2 py-2 bg-gray-100 flex justify-end  "
            CardclassName="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
            body={
              <>
                <p className="mb-0 font-medium">Skills and assessments</p>
                {skillsFilter.slice(0, 4).map((assessments) => (
                  <Textinput
                    type="checkbox"
                    labelclass="hidden"
                    className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                    divclass="flex h-6 mt-2 truncate"
                    postlabel={assessments}
                    postlabelclass="ml-2 mb-0 truncate"
                    key={assessments}
                  />
                ))}

                {seeAssessments &&
                  assessments
                    .slice(4)
                    .map((assessments, index) => (
                      <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2 truncate"
                        postlabel={assessments}
                        postlabelclass="ml-2 mb-0 truncate"
                        key={assessments}
                      />
                    ))}

                <p
                  className="text-sm hover:cursor-pointer mt-2 text-[#059669]"
                  onClick={toggleAssesment}
                >
                 {seeAssessments ? "View less...":"View all..."}
                </p>
              </>
            }
          />
          <Card
            CardFooterclassName="px-2 py-2 bg-gray-100 flex justify-end  "
            CardclassName="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
            body={
              <>
                <p className="mb-0 font-medium">Companies</p>
                {companiesFilter.slice(0, 2).map((company) => (
                  <Textinput
                    type="checkbox"
                    labelclass="hidden"
                    className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                    divclass="flex h-6 mt-2 truncate"
                    postlabel={company}
                    postlabelclass="ml-2 mb-0 truncate"
                    key={company}
                  />
                ))}

                {seeCompanies &&
                  Companies.slice(2).map((company, index) => (
                    <Textinput
                      type="checkbox"
                      labelclass="hidden"
                      className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                      divclass="flex h-6 mt-2 truncate"
                      postlabel={company}
                      postlabelclass="ml-2 mb-0 truncate"
                      key={company}
                    />
                  ))}

                <p
                  className="text-sm hover:cursor-pointer mt-2 text-[#059669]"
                  onClick={toggleCompany}
                >
                  view all...
                </p>
              </>
            }
          />
          <Card
            CardFooterclassName="px-2 py-2 bg-gray-100 flex justify-end  "
            CardclassName="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
            body={
              <>
                <p className="mb-0 font-medium">Keywords</p>
                {keywordFilter.slice(0, 2).map((keyword) => (
                  <Textinput
                    type="checkbox"
                    labelclass="hidden"
                    className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                    divclass="flex h-6 mt-2 truncate"
                    postlabel={keyword}
                    postlabelclass="ml-2 mb-0 truncate"
                    key={keyword}
                  />
                ))}

                {seeKeywords &&
                  Keywords.slice(2).map((keyword, index) => (
                    <Textinput
                      type="checkbox"
                      labelclass="hidden"
                      className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                      divclass="flex h-6 mt-2 truncate"
                      postlabel={keyword}
                      postlabelclass="ml-2 mb-0 truncate"
                      key={keyword}
                    />
                  ))}

                <p
                  className="text-sm hover:cursor-pointer mt-2 text-[#059669]"
                  onClick={toggleKeywords}
                >
                  view all...
                </p>
              </>
            }
          />
        </div>
        <div className="p-4 bg-white rounded-xl bg-opacity-50">
          <div className="relative">
            <svg
              className="w-5 h-5 absolute top-[30%] left-3"
              fill="none"
              stroke="#059669"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
              ></path>
            </svg>
            <input
              type="text"
              onChange={(e) => setSearchText(e.target.value)}
              className="w-full outline-none font-normal text-lg !ps-[40px]"
              placeholder="Enter keywords or Boolean expressions to find matching profiles"
              style={{
                border: "1px solid #34D39999",
                boxShadow: "0px 4px 40px 0px #34D39933",
                padding: "10px 18px",
                borderRadius: "12px",
              }}
            />
          </div>
          <div className="grid grid-cols-2 mt-3 gap-4">
            {filteredResumes.map((project, index) => (
              <div
                className="p-[30px] flex flex-col gap-[32px] bg-white"
                style={{
                  borderRadius: "20px",
                  boxShadow: "0px 4px 40px 0px #0000000A",
                }}
              >
                <div>
                  <p className="m-0 plusJakartaSansFont font-medium text-2xl text-[#000000]">
                    {project.name}
                  </p>
                  <p className="m-0 plusJakartaSansFont font-small text-xs text-[#000000]">
                    {project.profile}
                  </p>
                </div>
                <div>
                  <p className="m-0 plusJakartaSansFont font-semibold text-base text-[#1D1D1D]">
                    Contact Details
                  </p>
                  <div className="flex justify-between items-center">
                    <p className="m-0 plusJakartaSansFont font-small text-xs text-base text-[#3D3D3D]">
                      {project.email}
                    </p>
                    <p className="m-0 plusJakartaSansFont font-small text-xs text-base text-[#3D3D3D]">
                      {project.phone &&
                        project.phone.split(", ").map((number, index) => (
                          <span key={index}>
                            {number}
                            {index < project.phone.split(", ").length - 1 && ""}
                            <br />
                          </span>
                        ))}
                    </p>
                  </div>
                </div>
                <div
                  className="p-[20px]"
                  style={{ borderTop: "1px solid #EAEAEA" }}
                >
                  <p className="plusJakartaSansFont font-medium text-sm text-[#000000]">
                    This profile matched to couple of your listed projects
                  </p>
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      setDetailedView(!detailedView);
                      setIsOpen(false);
                      setResumeId(index);
                    }}
                    className="text-white bg-[#059669] w-full text-base font-medium hover:bg-emerald-700"
                    style={{ padding: "12px", borderRadius: "8px" }}
                  >
                    View
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={`flex w-11/12 mt-8 mb-8`}
        style={{ justifyContent: "flex-end", alignItems: "center" }}
      >
        <div className="flex" style={{ alignItems: "center" }}>
          <ButtonSolid
            disabled={currentPage === 1}
            onClick={handlePreviousPage}
            style={{ padding: "10px 30px", marginRight: "10px" }}
            className={`overflow-visible mt-0 border rounded-l-md ${
              currentPage === 1 ? "!bg-gray-200 hover:bg-gray-300" : ""
            }`}
            content="Previous"
          />
          <span className="text-nowrap px-4 py-2 hidden">
            Page {currentPage} of {totalPages}
          </span>
          <ButtonSolid
            disabled={currentPage === totalPages}
            onClick={handleNextPage}
            style={{ padding: "10px 30px", marginLeft: "10px" }}
            className={`overflow-visible mt-0 border rounded-r-md ${
              currentPage === totalPages ? "!bg-gray-200 hover:bg-gray-300" : ""
            }`}
            content="Next"
          />
        </div>
      </div>

      {/* detaild view div */}

      <div className={`${detailedView ? "block" : "hidden"}`}>
        <div
          data-modal-placement="top-right"
          tabIndex="-1"
          className="fixed top-0 flex justify-end bg-black bg-opacity-50 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div className="relative w-full max-w-lg h-full">
            <div
              ref={containerRef}
              className="relative p-4 h-full bg-white shadow flex flex-col gap-[30px]"
            >
              <div className="flex justify-between items-center">
                <div>
                  <p className="m-0 plusJakartaSansFont font-medium text-2xl text-[#000000]">
                    {filteredResumes &&
                    filteredResumes[resumeId] &&
                    filteredResumes[resumeId]["name"]
                      ? filteredResumes[resumeId]["name"]
                      : ""}
                  </p>
                  <p className="m-0 plusJakartaSansFont font-normal text-sm text-[#000000]">
                    {filteredResumes &&
                    filteredResumes[resumeId] &&
                    filteredResumes[resumeId]["profile"]
                      ? filteredResumes[resumeId]["profile"]
                      : ""}
                  </p>
                </div>
                <div className="text-base font-bold plusJakartaSansFont underline text-[#000000]">
                  <a
                    className="border-b-0 text-black"
                    target="_blank"
                    href={
                      filteredResumes &&
                      filteredResumes[resumeId] &&
                      filteredResumes[resumeId]["doc_id"]
                        ? `${process.env.REACT_APP_SERVER_BASEURL}/resumes/${cid}/resume/${uid}/${filteredResumes[resumeId].doc_id}.pdf`
                        : "#"
                    }
                  >
                    View Resume
                  </a>
                </div>
              </div>
              <div className="flex flex-col gap-[10px]">
                <p className="text-lg font-medium plusJakartaSansFont text-[#000000]">
                  Matching Jobs
                </p>
                {showoverlay ? (
                  <Overlay message="Development is in progress " />
                ) : (
                  <div className="overlay">
                    <div className="overlay-text">{message}</div>
                  </div>
                  //   <div>
                  //     <div
                  //       className="p-[30px]"
                  //       style={{
                  //         borderRadius: "20px",
                  //         boxShadow: "0px 4px 40px 0px #00000014",
                  //       }}
                  //     >
                  //       <div className="flex flex-col md:flex-row">
                  //         <div className="w-3/5 flex flex-col gap-[3px]">
                  //           <h3 className="plusJakartaSansFont font-bold text-2xl text-[#1D1D1D]">
                  //             UX Designer
                  //           </h3>
                  //           <div className="plusJakartaSansFont text-sm font-medium text-[#1D1D1D]">
                  //             Created by:{" "}
                  //             <span className="font-normal"> Ananya</span>
                  //           </div>
                  //           <div className="plusJakartaSansFont text-sm font-medium text-[#1D1D1D]">
                  //             Posted:{" "}
                  //             <span className="font-normal"> 24/09/2024</span>
                  //           </div>
                  //           <div className="plusJakartaSansFont text-sm font-medium text-[#1D1D1D]">
                  //             Workspace: Alchemy
                  //             <span className="font-normal"></span>
                  //           </div>
                  //           <div className="plusJakartaSansFont text-sm font-medium text-[#1D1D1D] flex items-center gap-[4px]">
                  //             Job: Senior UX Designer
                  //             <span className="font-normal"></span>
                  //             <span
                  //               className="w-3 h-3 rounded-full block"
                  //               style={{
                  //                 background:
                  //                   "linear-gradient(60.98deg, #EBFFF5 -195.51%, #FFFFFF -56.62%, #94E7F9 14.08%, #34D399 116.74%)",
                  //               }}
                  //             ></span>
                  //             <span className="text-[#747474] plusJakartaSansFont text-xs font-medium">
                  //               Active
                  //             </span>
                  //           </div>
                  //         </div>
                  //         <div className="w-2/4 flex flex-col items-end justify-between">
                  //           <Button
                  //             type="button"
                  //             className="inline-flex border-[1px] border-[#059669]  justify-center gap-x-1.5 rounded-md bg-white px-3  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  //             id="menu-button"
                  //             aria-haspopup="true"
                  //           >
                  //             <p className="mt-2 mb-0 text-[#059669]">
                  //               Save to pipeline
                  //             </p>
                  //             <svg
                  //               className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-[#059669]"
                  //               viewBox="0 0 20 20"
                  //               fill="#059669"
                  //               aria-hidden="true"
                  //             >
                  //               <path
                  //                 fillRule="evenodd"
                  //                 d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                  //                 clipRule="evenodd"
                  //               />
                  //             </svg>
                  //           </Button>
                  //           <div
                  //             className="bg-[#F2F9F9] p-[8px]"
                  //             style={{ borderRadius: "6px" }}
                  //           >
                  //             <div className="flex items-center gap-2">
                  //               <div>
                  //                 <svg
                  //                   className="h-6 w-6 rotate-[270deg]"
                  //                   viewBox="0 0 36 36"
                  //                   xmlns="http://www.w3.org/2000/svg"
                  //                 >
                  //                   <defs>
                  //                     <linearGradient
                  //                       id="gradientStroke"
                  //                       gradientTransform="rotate(60)"
                  //                     >
                  //                       <stop offset="0%" stopColor="#EBFFF5" />
                  //                       <stop offset="0%" stopColor="#94E7F9" />
                  //                       <stop offset="100%" stopColor="#34D399" />
                  //                     </linearGradient>
                  //                   </defs>
                  //                   <circle
                  //                     cx="18"
                  //                     cy="18"
                  //                     r="16"
                  //                     fill="none"
                  //                     stroke="url(#gradientStroke)"
                  //                     strokeWidth="4"
                  //                     strokeDasharray={`${progressBar} 100`}
                  //                   ></circle>
                  //                 </svg>
                  //               </div>
                  //               <div>
                  //                 <p className="m-0 text-[#000000] plusJakartaSansFont text-xs font-medium">
                  //                   {progressBar}%
                  //                 </p>
                  //                 <p className="m-0 text-[#898989] plusJakartaSansFont text-[7.5px] font-normal">
                  //                   Match
                  //                 </p>
                  //               </div>
                  //             </div>
                  //           </div>
                  //         </div>
                  //       </div>
                  //     </div>
                  //     <div className="w-full my-5 flex items-center justify-center text-black">
                  //       <div>
                  //         <button
                  //           id="dropdownDefaultButton"
                  //           onClick={toggleDropdown}
                  //           className="text-white plusJakartaSansFont font-medium rounded-lg text-base py-2 px-3 text-center flex items-center justify-between w-full"
                  //           type="button"
                  //           style={{ background: "#059669" }}
                  //         >
                  //           Contact Candidate
                  //           <svg
                  //             className="w-2.5 h-2.5 ms-3"
                  //             aria-hidden="true"
                  //             xmlns="http://www.w3.org/2000/svg"
                  //             fill="none"
                  //             viewBox="0 0 10 6"
                  //           >
                  //             <path
                  //               stroke="currentColor"
                  //               strokeLinecap="round"
                  //               strokeLinejoin="round"
                  //               strokeWidth="2"
                  //               d="m1 1 4 4 4-4"
                  //             />
                  //           </svg>
                  //         </button>

                  //         {isOpen && (
                  //           <div
                  //             id="dropdown"
                  //             className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-full dark:bg-gray-700 mt-2"
                  //           >
                  //             <ul
                  //               className="py-2 text-sm text-gray-700 dark:text-gray-200 px-0"
                  //               aria-labelledby="dropdownDefaultButton"
                  //             >
                  //               <li className="hover:bg-gray-100">
                  //                 <span className="py-2 px-4 text-[#444446] font-normal plusJakartaSansFont text-sm flex items-center gap-4 cursor-pointer">
                  //                   IN Progress
                  //                 </span>
                  //               </li>
                  //             </ul>
                  //           </div>
                  //           // <div id="dropdown" className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-full dark:bg-gray-700 mt-2" >
                  //           //     <ul className="py-2 text-sm text-gray-700 dark:text-gray-200 px-0" aria-labelledby="dropdownDefaultButton" >
                  //           //         <li className='hover:bg-gray-100'>
                  //           //             <span className='py-2 px-4 text-[#444446] font-normal plusJakartaSansFont text-sm flex items-center gap-4 cursor-pointer'>
                  //           //                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  //           //                     <path d="M8.38028 8.85335C9.07627 10.303 10.0251 11.6616 11.2266 12.8632C12.4282 14.0648 13.7869 15.0136 15.2365 15.7096C15.3612 15.7694 15.4235 15.7994 15.5024 15.8224C15.7828 15.9041 16.127 15.8454 16.3644 15.6754C16.4313 15.6275 16.4884 15.5704 16.6027 15.4561C16.9523 15.1064 17.1271 14.9316 17.3029 14.8174C17.9658 14.3864 18.8204 14.3864 19.4833 14.8174C19.6591 14.9316 19.8339 15.1064 20.1835 15.4561L20.3783 15.6509C20.9098 16.1824 21.1755 16.4481 21.3198 16.7335C21.6069 17.301 21.6069 17.9713 21.3198 18.5389C21.1755 18.8242 20.9098 19.09 20.3783 19.6214L20.2207 19.779C19.6911 20.3087 19.4263 20.5735 19.0662 20.7757C18.6667 21.0001 18.0462 21.1615 17.588 21.1601C17.1751 21.1589 16.8928 21.0788 16.3284 20.9186C13.295 20.0576 10.4326 18.4332 8.04466 16.0452C5.65668 13.6572 4.03221 10.7948 3.17124 7.76144C3.01103 7.19699 2.93092 6.91477 2.9297 6.50182C2.92833 6.0436 3.08969 5.42311 3.31411 5.0236C3.51636 4.66357 3.78117 4.39876 4.3108 3.86913L4.46843 3.7115C4.99987 3.18006 5.2656 2.91433 5.55098 2.76999C6.11854 2.48292 6.7888 2.48292 7.35636 2.76999C7.64174 2.91433 7.90747 3.18006 8.43891 3.7115L8.63378 3.90637C8.98338 4.25597 9.15819 4.43078 9.27247 4.60655C9.70347 5.26945 9.70347 6.12403 9.27247 6.78692C9.15819 6.96269 8.98338 7.1375 8.63378 7.4871C8.51947 7.60142 8.46231 7.65857 8.41447 7.72538C8.24446 7.96281 8.18576 8.30707 8.26748 8.58743C8.29048 8.66632 8.32041 8.72866 8.38028 8.85335Z" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  //           //                 </svg>
                  //           //                 +91 9999 99999
                  //           //             </span>
                  //           //         </li>
                  //           //         <li className='hover:bg-gray-100'>
                  //           //             <span className='py-2 px-4 text-[#444446] font-normal plusJakartaSansFont text-sm flex items-center gap-4 cursor-pointer'>
                  //           //                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  //           //                     <path d="M2 7L10.1649 12.7154C10.8261 13.1783 11.1567 13.4097 11.5163 13.4993C11.8339 13.5785 12.1661 13.5785 12.4837 13.4993C12.8433 13.4097 13.1739 13.1783 13.8351 12.7154L22 7M6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20Z" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  //           //                 </svg>
                  //           //                 abc@xyz.com
                  //           //             </span>
                  //           //         </li>
                  //           //         <li className='hover:bg-gray-100'>
                  //           //             <span className='py-2 px-4 text-[#444446] font-normal plusJakartaSansFont text-sm flex items-center gap-4 cursor-pointer'>
                  //           //             <svg
                  //           //                 xmlns="http://www.w3.org/2000/svg"
                  //           //                 xmlnsXlink="http://www.w3.org/1999/xlink"
                  //           //                 width="25"
                  //           //                 height="25"
                  //           //                 viewBox="0 0 17 16"
                  //           //                 fill="none"
                  //           //             >
                  //           //                 <g clipPath="url(#clip0_2564_22177)">
                  //           //                     <g clipPath="url(#clip1_2564_22177)">
                  //           //                     <g clipPath="url(#clip2_2564_22177)">
                  //           //                         <mask
                  //           //                         id="mask0_2564_22177"
                  //           //                         style={{ maskType: "luminance" }}
                  //           //                         maskUnits="userSpaceOnUse"
                  //           //                         x="0"
                  //           //                         y="0"
                  //           //                         width="17"
                  //           //                         height="17"
                  //           //                         >
                  //           //                         <path d="M16.7969 0.000366211H0.796875V16.0004H16.7969V0.000366211Z" fill="white" />
                  //           //                         </mask>
                  //           //                         <g mask="url(#mask0_2564_22177)">
                  //           //                         <path d="M16.7969 0.000366211H0.796875V16.0004H16.7969V0.000366211Z" fill="url(#pattern0_2564_22177)" />
                  //           //                         <path d="M15.8322 0.867554H1.70312V15.0325H15.8322V0.867554Z" fill="url(#pattern1_2564_22177)" />
                  //           //                         <path
                  //           //                             d="M2.02344 14.5987L2.97828 11.1218C2.39118 10.1046 2.07828 8.9488 2.0815 7.76733C2.0815 4.06511 5.10086 1.05505 8.81053 1.05505C10.6105 1.05505 12.3008 1.75365 13.5686 3.02205C14.8396 4.29046 15.5396 5.97739 15.5364 7.77054C15.5364 11.4728 12.517 14.4828 8.80733 14.4828H8.80405C7.67828 14.4828 6.57183 14.1995 5.58796 13.6651L2.02344 14.5987ZM5.7557 12.4482L5.95893 12.5706C6.81699 13.0792 7.80086 13.3464 8.80405 13.3496H8.80733C11.888 13.3496 14.3976 10.8482 14.3976 7.77054C14.3976 6.28001 13.817 4.8796 12.7621 3.82366C11.7073 2.76773 10.3008 2.18825 8.80733 2.18825C5.72344 2.18825 3.21377 4.68966 3.21377 7.76733C3.21377 8.82008 3.50731 9.84704 4.06861 10.7355L4.20086 10.948L3.63957 13.0019L5.7557 12.4482Z"
                  //           //                             fill="black"
                  //           //                         />
                  //           //                         <path d="M15.9319 0.676147H1.60938V15.0343H15.9319V0.676147Z" fill="url(#pattern2_2564_22177)" />
                  //           //                         <path
                  //           //                             d="M2.02344 14.5987L2.97828 11.1218C2.39118 10.1046 2.07828 8.9488 2.0815 7.76733C2.0815 4.06511 5.10086 1.05505 8.81053 1.05505C10.6105 1.05505 12.3008 1.75365 13.5686 3.02205C14.8396 4.29046 15.5396 5.97739 15.5364 7.77054C15.5364 11.4728 12.517 14.4828 8.80733 14.4828H8.80405C7.67828 14.4828 6.57183 14.1995 5.58796 13.6651L2.02344 14.5987ZM5.7557 12.4482L5.95893 12.5706C6.81699 13.0792 7.80086 13.3464 8.80405 13.3496H8.80733C11.888 13.3496 14.3976 10.8482 14.3976 7.77054C14.3976 6.28001 13.817 4.8796 12.7621 3.82366C11.7073 2.76773 10.3008 2.18825 8.80733 2.18825C5.72344 2.18825 3.21377 4.68966 3.21377 7.76733C3.21377 8.82008 3.50731 9.84704 4.06861 10.7355L4.20086 10.948L3.63957 13.0019L5.7557 12.4482Z"
                  //           //                             fill="black"
                  //           //                         />
                  //           //                         <path d="M12.6295 4.31677H4.98438V11.4637H12.6295V4.31677Z" fill="url(#pattern3_2564_22177)" />
                  //           //                         <path
                  //           //                             fillRule="evenodd"
                  //           //                             clipRule="evenodd"
                  //           //                             d="M7.12611 4.95347C7.00031 4.67339 6.86804 4.66696 6.74869 4.66374C6.65191 4.66052 6.53901 4.66052 6.42611 4.66052C6.31321 4.66052 6.13256 4.70237 5.97772 4.86978C5.82288 5.03718 5.39062 5.44281 5.39062 6.27018C5.39062 7.09432 5.99385 7.89271 6.07772 8.00536C6.16159 8.11808 7.24224 9.86616 8.94872 10.539C10.3681 11.0991 10.6584 10.9865 10.9648 10.9575C11.2745 10.9286 11.9584 10.5518 12.1003 10.1591C12.239 9.76632 12.239 9.43152 12.197 9.36072C12.1551 9.28992 12.0422 9.24808 11.8745 9.16432C11.7067 9.08064 10.881 8.67504 10.7261 8.61704C10.5713 8.56232 10.4584 8.53336 10.3487 8.70072C10.2358 8.86816 9.9132 9.2448 9.8164 9.35752C9.71968 9.47016 9.61968 9.48304 9.45192 9.39936C9.28416 9.31568 8.74223 9.13856 8.1003 8.56552C7.6003 8.12128 7.26159 7.57078 7.16482 7.40338C7.06804 7.23597 7.15515 7.14583 7.23901 7.06213C7.31321 6.98808 7.40675 6.86575 7.49063 6.76917C7.5745 6.67259 7.60353 6.60177 7.65837 6.48909C7.71321 6.37642 7.6874 6.27983 7.64547 6.19613C7.60031 6.11565 7.2745 5.28507 7.12611 4.95347Z"
                  //           //                             fill="black"
                  //           //                         />
                  //           //                         </g>
                  //           //                     </g>
                  //           //                     </g>
                  //           //                 </g>
                  //           //                 <defs>
                  //           //                     <pattern id="pattern0_2564_22177" patternContentUnits="objectBoundingBox" width="1" height="1">
                  //           //                     <use xlinkHref="#image0_2564_22177" />
                  //           //                     </pattern>
                  //           //                     <pattern id="pattern1_2564_22177" patternContentUnits="objectBoundingBox" width="1" height="1">
                  //           //                     <use xlinkHref="#image1_2564_22177" />
                  //           //                     </pattern>
                  //           //                     <pattern id="pattern2_2564_22177" patternContentUnits="objectBoundingBox" width="1" height="1">
                  //           //                     <use xlinkHref="#image2_2564_22177" />
                  //           //                     </pattern>
                  //           //                     <pattern id="pattern3_2564_22177" patternContentUnits="objectBoundingBox" width="1" height="1">
                  //           //                     <use xlinkHref="#image3_2564_22177" />
                  //           //                     </pattern>
                  //           //                     <clipPath id="clip0_2564_22177">
                  //           //                     <rect width="16" height="16" fill="white" transform="translate(0.796875 0.000366211)" />
                  //           //                     </clipPath>
                  //           //                     <clipPath id="clip1_2564_22177">
                  //           //                     <rect width="16" height="16" fill="white" transform="translate(0.796875 0.000366211)" />
                  //           //                     </clipPath>
                  //           //                     <clipPath id="clip2_2564_22177">
                  //           //                     <rect width="16" height="16" fill="white" transform="translate(0.796875 0.000366211)" />
                  //           //                     </clipPath>
                  //           //                     <image id="image0_2564_22177" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfAAAAHxCAYAAACS8O5DAAAACXBIWXMAAAsSAAALEgHS3X78AAAA" />
                  //           //                     <image id="image1_2564_22177" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAbYAAAG4CAYAAAApA8IAAAAACXBIWXMAAAsSAAALEgHS3X78AAAA" />
                  //           //                     <image id="image2_2564_22177" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAbwAAAG+CAYAAADoeLHUAAAACXBIWXMAAAsSAAALEgHS3X78AAAA" />
                  //           //                     <image id="image3_2564_22177" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAO0AAADeCAYAAADRo4eMAAAACXBIWXMAAAsSAAALEgHS3X78AAAA" />
                  //           //                 </defs>
                  //           //                 </svg>
                  //           //                 +91 9999 99999
                  //           //             </span>
                  //           //         </li>
                  //           //     </ul>
                  //           // </div>
                  //         )}
                  //       </div>
                  //     </div>
                  //   </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
