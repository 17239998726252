// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
    .SignUpCard-Body{
        position: relative;
        flex-direction: column;

    }
    .SignUp-Page {
        width: 100%;
        margin-left: 0;
        padding-left: 1.5rem;
    }
    .SignUp-Flex-Card-Content {
        flex-direction: column;

    }
    .SignUp-Flex-Card {
        width: 100%;
        padding: 0;
    }
    .SignUp-OR {
        display: none;
    }
    .Flex-Mobile {
        flex-direction: column-reverse;
    }
    .SignUP-Profile_img{
        padding: 0;
        margin-bottom: 1rem;
    }
    .mobile-w-full {
        width: 100%;
    }
    .mobile-margin-no {
        margin: 0;
    }
    .mobile-flex-center {
        display: flex;
        justify-content: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/JobSeeker/SignUp/SignUp.css"],"names":[],"mappings":"AAAA;IACI;QACI,kBAAkB;QAClB,sBAAsB;;IAE1B;IACA;QACI,WAAW;QACX,cAAc;QACd,oBAAoB;IACxB;IACA;QACI,sBAAsB;;IAE1B;IACA;QACI,WAAW;QACX,UAAU;IACd;IACA;QACI,aAAa;IACjB;IACA;QACI,8BAA8B;IAClC;IACA;QACI,UAAU;QACV,mBAAmB;IACvB;IACA;QACI,WAAW;IACf;IACA;QACI,SAAS;IACb;IACA;QACI,aAAa;QACb,uBAAuB;IAC3B;AACJ","sourcesContent":["@media only screen and (max-width: 600px) {\n    .SignUpCard-Body{\n        position: relative;\n        flex-direction: column;\n\n    }\n    .SignUp-Page {\n        width: 100%;\n        margin-left: 0;\n        padding-left: 1.5rem;\n    }\n    .SignUp-Flex-Card-Content {\n        flex-direction: column;\n\n    }\n    .SignUp-Flex-Card {\n        width: 100%;\n        padding: 0;\n    }\n    .SignUp-OR {\n        display: none;\n    }\n    .Flex-Mobile {\n        flex-direction: column-reverse;\n    }\n    .SignUP-Profile_img{\n        padding: 0;\n        margin-bottom: 1rem;\n    }\n    .mobile-w-full {\n        width: 100%;\n    }\n    .mobile-margin-no {\n        margin: 0;\n    }\n    .mobile-flex-center {\n        display: flex;\n        justify-content: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
