import React, { useState,useEffect, useRef, useContext } from "react";
import CryptoJS from 'crypto-js';
import Notification from "../Notification/Notification";
import ClipLoader from "react-spinners/ClipLoader";
import { AuthContext } from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function ResumeUpload() {
    const [state1Hidden, setState1Hidden] = useState(true);
    const [state2Hidden, setState2Hidden] = useState(true);
    const [state3Hidden, setState3Hidden] = useState(true);
    const [state4Hidden, setState4Hidden] = useState(true);
    const { token } = useContext(AuthContext);
    const location = useLocation();
    const [notification, setNotification] = useState({ message: "", type: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [jsonJobData, setjsonJobData] = useState({});
    const [interviewDuration,setInterviewDuration]=useState('');
    const [interviewer,setInterviewer]=useState([]);
    const [interviewLocation,setInterviewLocation]=useState('');
    const [dayOfTheWeek, setDayOfTheWeek] = useState(['','Mon','Tue','Wed','Thu','Fri','Sat','Sun']);
    const { job_id_Get } = location.state || {};
    const addNotes = useRef(null); 
    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState(false);
    const [companyProfile, setCompanyProfile] = useState('');
    const [profilePath, setProfilePath] = useState('');

    const showNotification = (message, type) => {   //For Notification
        setNotification({ message, type });
        setTimeout(() => {
          setNotification({ message: "", type: "" });
        }, 3000); // Auto-hide after 3 seconds
      };

    useEffect(()=>{
      getJobDetailLoad();
      getCompanyDetailLoad();
    },[job_id_Get])

    const getJobDetailLoad = async() =>{
        console.log(job_id_Get)
        let cid;
        let userId;
        const encryptedMetadata = localStorage.getItem('metadata');
        if (encryptedMetadata){
            const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            if(decryptedMetadata && decryptedMetadata.cid)
                cid = decryptedMetadata.cid
            if(decryptedMetadata && decryptedMetadata.uid)
                userId = decryptedMetadata.uid
         }
        const serverURL = process.env.REACT_APP_SERVER_URL;
        let jsonData = {job_id:job_id_Get,approveJob:true,userId:userId};
        let url=`${serverURL}/job/fetchJobDetails`;
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData), process.env.REACT_APP_SECRETKEY).toString();
        var encryptjson = { data:encryptedData}
        setIsLoading(true);
        try {
            const response = await fetch(`${url}`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  token: token
                },
                body: JSON.stringify(encryptjson),
            });
            const data = await response.json();
            console.log(data)

            const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            console.log(decryptedData,"decrypt");
            if (!response.ok) {
                var errorMessage = "Something Went Wrong";
                if(data.error)
                    errorMessage = data.error;
                showNotification(errorMessage,"error");
                throw new Error(data.error);
            }
            else{
                console.log(decryptedData.job_details[0]["interview_duration"],'dissss')
                console.log(decryptedData.job_interviewer)
                setInterviewer(decryptedData.job_interviewer)
                setInterviewDuration(decryptedData.job_details[0]["interview_duration"])
                setInterviewLocation(decryptedData.job_details[0]["interview_location"])
                setjsonJobData((prevDetails) => ({
                        ...prevDetails,
                        jobDetails: decryptedData,
                    }));
            }
        } catch (error) {
            console.log('Error:', error);
            let errorMessage = "Something went wrong.";
            if (error.response && error.response.data && error.response.data.msg) {
                errorMessage = error.response.data.msg;
            } 
            else if (error.message) {
                errorMessage = error.message;
            }
            showNotification(errorMessage, "error");
        } finally {
            setIsLoading(false); // Always hide loader after the request
        }
    }

    const getCompanyDetailLoad = async() =>{
        const serverURL = process.env.REACT_APP_SERVER_URL;
        let url=`${serverURL}/company/fetchCompanyDetails`;
        setIsLoading(true);
        try {
            const response = await fetch(`${url}`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  token: token
                },
            });
            const data = await response.json();
            console.log(data)

            const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            if (!response.ok) {
                var errorMessage = "Something Went Wrong";
                if(data.error)
                    errorMessage = data.error;
                showNotification(errorMessage,"error");
                throw new Error(data.error);
            }
            else{
                console.log(decryptedData,'pissss')
                setCompanyName(decryptedData?.[0]?.name);
                setCompanyProfile(decryptedData?.[0]?.profile);
                if(decryptedData?.[0]?.profile){
                    setProfilePath(`${process.env.REACT_APP_SERVER_BASEURL}/companyprofile/${decryptedData[0]['profile']}`);
                }
            }
        } catch (error) {
            console.log('Error:', error);
            let errorMessage = "Something went wrong.";
            if (error.response && error.response.data && error.response.data.msg) {
                errorMessage = error.response.data.msg;
            } 
            else if (error.message) {
                errorMessage = error.message;
            }
            showNotification(errorMessage, "error");
        } finally {
            setIsLoading(false); // Always hide loader after the request
        }
    }


const addNotesOnchange = () =>{
    addNotes.current.classList.remove("!border-red-600");
}

const ApproveJob = async(status) =>{
    var notes=addNotes.current.value
    let job_id=job_id_Get;
    var cid = "";
    var userId = "";
    if(!notes){
        showNotification("Enter Notes","error");
        addNotes.current.classList.add("!border-red-600");
        addNotes.current.focus();
        return;
    }
    const encryptedMetadata = localStorage.getItem('metadata');
    if (encryptedMetadata){
        const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        if(decryptedMetadata && decryptedMetadata.cid)
            cid = decryptedMetadata.cid
        if(decryptedMetadata && decryptedMetadata.uid)
            userId = decryptedMetadata.uid
     }
    const serverURL = process.env.REACT_APP_SERVER_URL;
    let jsonData = {job_id:job_id,cid:cid,userId:userId,status:status,notes:notes};
    let url=`${serverURL}/job/approveJob`;
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData), process.env.REACT_APP_SECRETKEY).toString();
    var encryptjson = { data:encryptedData}
    setIsLoading(true);
    try {
        const response = await fetch(`${url}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              token: token
            },
            body: JSON.stringify(encryptjson),
        });
        const data = await response.json();
        console.log(data)

        // const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        // console.log(decryptedData);
        if (!response.ok) {
            var errorMessage = "Something Went Wrong";
            if(data.error)
                errorMessage = data.error;
            showNotification(errorMessage,"error");
            throw new Error(data.error);
        }
        else{
            showNotification("Job "+status+" Successfully","success");
            navigate("/Dashboard")
        }
    } catch (error) {
        console.log('Error:', error);
        let errorMessage = "Something went wrong.";
        if (error.response && error.response.data && error.response.data.msg) {
            errorMessage = error.response.data.msg;
        } 
        else if (error.message) {
            errorMessage = error.message;
        }
        showNotification(errorMessage, "error");
    } finally {
        setIsLoading(false); // Always hide loader after the request
    }
  }
    const convertToIndianTimeFormat = (time) => {
      const [hours, minutes, seconds] = time.split(':');
      const date = new Date();
      date.setHours(hours, minutes, seconds);

      return date.toLocaleTimeString('en-IN', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true, // Ensures 12-hour format
      });
    };
    const convertToIndianDate = (utcDate) => {
      const date = new Date(utcDate); 
      const formattedDate = date.toLocaleDateString('en-IN'); // Format as DD/MM/YYYY
      return formattedDate.replace(/\//g, '-'); // Replace slashes with hyphens
    };
    const goBack = () => {
        navigate(-1); // This goes back to the previous page
    };
    return (
        <div className="flex flex-col gap-[20px] mx-5 pt-5 bg-white bg-opacity-50 rounded-2xl h-full">
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        <span className="text-white mt-4">Loading...</span>
                    </div>
                </div>
            )}
            <Notification
                message={notification.message}
                type={notification.type}
                onClose={() => setNotification({ message: "", type: "" })}
            />

            <h3 className="font-bold text-[22px] plusJakartaSansFont flex px-2 items-center">
              <svg
                  className="mr-2 text-emerald-700 cursor-pointer"
                  onClick={goBack}
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                >
                  <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm3 5.753l-6.44 5.247 6.44 5.263-.678.737-7.322-6 7.335-6 .665.753z"/>
            </svg>Review Job: {jsonJobData?.jobDetails?.job_details?.[0]?.job_type ?? "No Title"}
            </h3>

            {/* first accordian start */}
            <div className="bg-white mr-7 px-4 py-2" style={{borderRadius:'20px'}}>
                <div className="flex flex-col gap-[20px]">
                    <h2 onClick={()=>setState1Hidden((hidden)=>!hidden)} className="cursor-pointer flex justify-between items-baseline">
                        <p className="font-semibold text-xl plusJakartaSansFont">1. Job Description</p>
                        <svg className="w-3 h-3 rotate-180 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5"/>
                        </svg>
                    </h2>
                    <div
                        className={`overflow-hidden transition-all duration-500 ${
                        state1Hidden ? "max-h-auto opacity-100" : "max-h-0 opacity-0"
                        }`}
                    >
                    <div className="flex flex-col gap-[20px] px-4 py-2 pt-0" style={{borderRadius:'20px',boxShadow: '0px 4px 40px 0px #0000000A'}}>

                        <div className="flex items-start gap-8 mb-4">
                            <div style={{width:'64px',height:'64px',border:'1px solid #EAEAEA',borderRadius:'16px',padding:'2px'}}>
                                <img src={profilePath} alt="" className="w-full h-full object-cover rounded-xl" />
                            </div>
                            <div className="flex flex-col gap-[10px]">
                                <p className="m-0 text-base font-normal text-[#747474] plusJakartaSansFont">{companyName?companyName:"No Company Name"}</p>
                                    <p className="m-0 text-xl font-medium text-gray-900 plusJakartaSansFont">{jsonJobData?.jobDetails?.job_details?.[0]?.title ?? "No Title"}</p>
                                <div className="m-0 flex items-center gap-[8px]">
                                {jsonJobData && jsonJobData.jobDetails && jsonJobData.jobDetails.job_details && jsonJobData.jobDetails.job_details[0] && jsonJobData.jobDetails.job_details[0].skills &&
                                    jsonJobData.jobDetails.job_details[0].skills.map((element, index) => (
                                        <div
                                        key={index}
                                        className="text-xs font-normal plusJakartaSansFont"
                                        style={{
                                            background: '#F2F9F9',
                                            padding: '6px',
                                            borderRadius: '8px',
                                            color: '#242A2C'
                                        }}
                                        >
                                        {element}
                                        </div>
                                    ))
                                }
                                </div>
                            </div>
                        </div>

                        <div>
                            <p className="text-lg font-medium mb-1 plusJakartaSansFont">Job Description</p>
                            <p className="m-0 text-sm font-normal text-[#747474] plusJakartaSansFont">{jsonJobData?.jobDetails?.job_details?.[0]?.job_desc ?? "No Description"}</p>
                        </div>

                        <div className='hidden'>
                            <p className="text-lg font-medium mb-1 plusJakartaSansFont">Your Role</p>
                            <ul className="list-disc pl-5">
                                {
                                    [   'Lead and develop key projects',
                                        'Collaborate with cross-functional teams',
                                        'Review code and mentor juniors',
                                        'Optimize and manage cloud applications'
                                    ].map((element, index) => (
                                        <li className="plusJakartaSansFont font-normal text-sm text-[#747474]" style={{lineHeight:'30px'}} key={index}>
                                            {element}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>

                        <div className='hidden'>
                            <p className="text-lg font-medium mb-1 plusJakartaSansFont">Benefits</p>
                            <ul className="list-disc pl-5">
                                {
                                    [  'Competitive Salary',
                                        'Flexible Remote Work',
                                        'Health Insurance',
                                        'Learning & Development Opportunities',
                                        'Work-Life Balance',
                                        'Annual Bonus'
                                    ].map((element, index) => (
                                        <li className="plusJakartaSansFont font-normal text-sm text-[#747474]" style={{lineHeight:'30px'}} key={index}>
                                            {element}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>

                        <div>
                            <p className="text-lg font-medium mb-1 plusJakartaSansFont">Skills and Requirements</p>
                            <ul className="list-disc pl-5">
                                {jsonJobData && jsonJobData.jobDetails && jsonJobData.jobDetails.job_details && jsonJobData.jobDetails.job_details[0] && jsonJobData.jobDetails.job_details[0].skills &&
                                    jsonJobData.jobDetails.job_details[0].skills.map((element, index) => (
                                        <li className="plusJakartaSansFont font-normal text-sm text-[#747474]" style={{lineHeight:'30px'}} key={index}>
                                            {element}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            {/* first accordian end */}
            {/* second accordian start */}
            <div className="bg-white mr-7 px-4 py-2" style={{borderRadius:'20px'}}>
                <div className="flex flex-col gap-[20px]">
                    <h2 onClick={()=>setState2Hidden((hidden)=>!hidden)} className="cursor-pointer flex justify-between items-baseline">
                        <p className="font-semibold text-xl plusJakartaSansFont">2. Screening Questions</p>
                        <svg className="w-3 h-3 rotate-180 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5"/>
                        </svg>
                    </h2>
                    <div
                        className={`overflow-hidden transition-all duration-500 ${
                        state2Hidden ? "max-h-auto opacity-100" : "max-h-0 opacity-0"
                        }`}
                    >
                        <div className="flex flex-col gap-[24px]">
                            {jsonJobData && jsonJobData.jobDetails && jsonJobData.jobDetails.job_screen_question &&
                                jsonJobData.jobDetails.job_screen_question.map((element, index) => (
                                    <div className="bg-white px-4 py-2" style={{borderRadius:'20px', boxShadow: '0px 4px 40px 0px #00000014'}}>
                                        <p className="text-lg font-medium plusJakartaSansFont">{index + 1 + '.'} {element.question_desc}</p>
                                        {element.questiontype === 'input' ? 
                                        
                                        <div>
                                            <input type="text" className="plusJakartaSansFont w-full text-sm font-medium outline-none" style={{border:'1px solid #BFE0E2',padding:'12px 16px',borderRadius:'8px',color:'#A0A0A0'}} />
                                        </div>

                                        : element.questiontype === 'select' ? 

                                        <div>
                                            <select className="w-full outline-none text-sm font-medium plusJakartaSansFont" style={{border:'1px solid #BFE0E2',padding:'12px 16px',borderRadius:'8px',color:'#A0A0A0'}}>
                                                <option className="text-sm font-medium plusJakartaSansFont" disabled selected>Select</option>
                                                <option className="text-sm font-medium plusJakartaSansFont" style={{color:'#A0A0A0'}} value={element.option1}>{element.option1}</option>
                                                <option className="text-sm font-medium plusJakartaSansFont" style={{color:'#A0A0A0'}} value={element.option2}>{element.option2}</option>
                                                <option className="text-sm font-medium plusJakartaSansFont" style={{color:'#A0A0A0'}} value={element.option3}>{element.option3}</option>
                                                <option className="text-sm font-medium plusJakartaSansFont" style={{color:'#A0A0A0'}} value={element.option4}>{element.option4}</option>
                                            </select>
                                        </div>

                                        : '' 
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* second accordian end */}
            {/* third accordian start */}

            <div className="bg-white mr-7 px-4 py-2" style={{borderRadius:'20px'}}>
                <div className="flex flex-col gap-[0px]">
                    <h2 onClick={()=>setState3Hidden((hidden)=>!hidden)} className="cursor-pointer flex justify-between items-baseline">
                        <p className="font-semibold text-xl plusJakartaSansFont">3. Schedule(Calendly)</p>
                        <svg className="w-3 h-3 rotate-180 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5"/>
                        </svg>
                    </h2>
                    <div
                        className={`overflow-hidden transition-all duration-500 ${
                        state3Hidden ? "max-h-auto opacity-100" : "max-h-0 opacity-0"
                        }`}
                    >
                    <p className="text-medium text-center plusJakartaSansFont">Schedule from {convertToIndianDate(jsonJobData?.jobDetails?.job_schedule?.[0]?.created_date)} to {convertToIndianDate(jsonJobData?.jobDetails?.job_schedule?.[0]?.end_date)}</p>
                    <div
                      className="relative flex mb-2 justify-center  text-gray-700 bg-white  rounded-xl bg-clip-border">
                      <table className="text-left mt-4 w-3/4 table-auto text-center">
                        <thead>

                          <tr className="bg-sky-50">
                            <th className="p-1 border-b border-blue-gray-100 bg-blue-gray-50">
                              <p className="block mt-2 ml-4 mb-2 font-sans text-md antialiased font-normal leading-none text-black ">
                                Day
                              </p>
                            </th>
                            <th className="p-1 border-b border-blue-gray-100 bg-blue-gray-50">
                              <p className="block mt-2 ml-4 mb-2 font-sans text-md antialiased font-normal leading-none text-black ">
                                  Start
                              </p>
                            </th>
                            <th className="p-1 border-b  border-blue-gray-100 bg-blue-gray-50">
                              <p className="block mt-2 ml-4 mb-2 font-sans text-md antialiased font-normal leading-none text-black ">
                                End
                              </p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                        {jsonJobData?.jobDetails?.job_collab &&
                            Object.values(jsonJobData.jobDetails.job_weeklyslot).map((element, index) => (
                          <tr className="border-b border-blue-gray-50">
                            <td className="p-1 border-b border-blue-gray-50">
                              <p className="block ml-4 my-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                                {dayOfTheWeek[element.day_of_week]}
                              </p>
                            </td>
                            <td className="p-1 border-b border-blue-gray-50">
                              <p className="block ml-4 my-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                                {convertToIndianTimeFormat(element.start_time)}
                              </p>
                            </td>
                            <td className="p-1 border-b border-blue-gray-50">
                              <p className="block ml-4 my-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                                {convertToIndianTimeFormat(element.end_time)}
                              </p>
                            </td>
                          </tr>
                        ))}
                        {jsonJobData?.jobDetails?.job_collab &&
                            Object.values(jsonJobData.jobDetails.job_dateoverride).map((element, index) => (
                          <tr className="border-b border-blue-gray-50">
                            <td className="p-1 border-b border-blue-gray-50">
                              <p className="block ml-4 my-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                                {convertToIndianDate(element.date)}
                              </p>
                            </td>
                            <td className="p-1 border-b border-blue-gray-50">
                              <p className="block ml-4 my-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                                {convertToIndianTimeFormat(element.start_time)}
                              </p>
                            </td>
                            <td className="p-1 border-b border-blue-gray-50">
                              <p className="block ml-4 my-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                                {convertToIndianTimeFormat(element.end_time)}
                              </p>
                            </td>
                          </tr>
                        ))}

                        </tbody>
                      </table>
                      </div>
                      {/* {jsonJobData && 
                       Object.values(jsonJobData).map((key)=>(
                        console.log("newwww: ",jsonJobData.jobDetails.job_details),
                        setInterviewDetails(jsonJobData.jobDetails.job_details)
                       ))} */}
                       {/* <p>{interviewDuration}</p> */}
                       {/* {Object.values(interviewDuration).map((key,index)=>(
                        console.log("current: ",interviewDuration[index]["interview_duration"])
                       ))} */}
                       <p className="text-lg font-medium mt-4">
                        Interviewers:   {" "}
                        {interviewer.map((interviewer_ctr, index) => (
                            <span key={index} className="text-sm font-normal">
            
                            {interviewer_ctr.interviewer_name}
                            {index < (interviewer.length)-1 && <span>, </span>}
                            </span>
                        ))}
                        </p>
                       <p className="text-lg font-medium">Interview Duration: <span className="text-sm font-normal">{interviewDuration}</span></p>
                       <p className="text-lg font-medium">Interview Location:  <span className="text-sm font-normal">{interviewLocation}</span></p>
                    </div>
                </div>
                
            </div>

            {/* third accordian end */}
            {/* fourth accordian start */}

            <div className="bg-white mr-7 px-4 py-2" style={{borderRadius:'20px'}}>
                <div className="flex flex-col gap-[20px]">
                    <h2 onClick={()=>setState4Hidden((hidden)=>!hidden)} className="cursor-pointer flex justify-between items-baseline">
                        <p className="font-semibold text-xl plusJakartaSansFont">4. Additional Information</p>
                        <svg className="w-3 h-3 rotate-180 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5"/>
                        </svg>
                    </h2>
                    <div
                        className={`overflow-hidden transition-all duration-500 ${
                        state4Hidden ? "max-h-auto opacity-100" : "max-h-0 opacity-0"
                        }`}
                    >
                    <div className="flex flex-col gap-[20px]">
                        <div className="bg-white px-4 py-2 pt-0" style={{borderRadius:'20px', boxShadow: '0px 4px 40px 0px #00000014'}}>
                            <p className="text-lg font-medium mb-1 plusJakartaSansFont">Collaborators</p>
                            <ul className="list-disc pl-5">
                                {jsonJobData?.jobDetails?.job_collab &&
                                    Object.values(jsonJobData.jobDetails.job_collab).map((collab, index) => (
                                        <li className="plusJakartaSansFont font-normal text-sm text-[#747474]" style={{lineHeight:'30px'}} key={index}>
                                            {collab.user_name}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="bg-white px-4 py-2" style={{borderRadius:'20px', boxShadow: '0px 4px 40px 0px #00000014'}}>
                            <p className="text-lg font-medium plusJakartaSansFont">Meet your team</p>
                            <ul className="p-0 flex items-center gap-[26px]">
                                {jsonJobData?.jobDetails?.job_team &&
                                    Object.values(jsonJobData.jobDetails.job_team).map((team, index) => (
                                        <li className="flex flex-col gap-[20px]" key={index}>
                                            <div className="flex flex-col items-center gap-[8px]">
                                                <p className="plusJakartaSansFont m-0 text-base font-medium text-[#000000]">{team.name}</p>
                                                <p className="plusJakartaSansFont m-0 text-xs font-normal text-[#747474]">{team.designation}</p>
                                                <p className="plusJakartaSansFont m-0 text-xs font-normal text-[#747474]">{team.url}</p>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            {/* fourth accordian end */}

            {/* Add notes textarea */}
            <div className="bg-white mr-7 px-4 py-2" style={{borderRadius:'20px'}}>
                <div className="bg-white px-4 py-2" style={{borderRadius:'20px', boxShadow: '0px 4px 40px 0px #00000014'}}>
                    <p className="text-lg font-medium plusJakartaSansFont">Add Notes</p>
                    <textarea ref={addNotes} onChange={addNotesOnchange}  className="plusJakartaSansFont text-xs font-medium w-full outline-none" style={{padding:'12px 16px',height:'186px',color:'#A0A0A0',border:'1px solid #BFE0E2',borderRadius:'8px'}} placeholder="Click to add Notes"></textarea>
                </div>
            </div>

            {/* sticky footer  */}
            <div className="sticky bottom-0 flex justify-end items-center gap-[20px] bg-[#242A2C] py-4 px-10" style={{borderTopLeftRadius:'16px',borderTopRightRadius:'16px'}}>
                <button onClick={() =>  ApproveJob("rejected")} className="text-base font-medium" style={{padding:'8px 14px',border:'1px solid #E55253',color:'#E55253',borderRadius:'8px'}}>Reject</button>
                <button className="hidden text-base font-medium" style={{padding:'8px 14px',border:'1px solid #059669',color:'#059669',borderRadius:'8px'}}>Submit Feedback</button>
                <button className="text-base font-medium text-white bg-[#059669]" style={{padding:'8px 14px',borderRadius:'8px'}} onClick={() =>  ApproveJob("active")}>Approve</button>
            </div>
        </div>
    );
}