import React, { useEffect,useContext, useRef } from "react";
import { jwtDecode } from 'jwt-decode';
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import CryptoJS from "crypto-js"; // For encryption
import './GmailSignIn.css';

const GoogleSignIn = ({ showNotification, setIsLoading }) => {
  const Gmailbutton = (props) => {
    return(
      <svg className={props.className} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
  <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
  </svg>
    )
  }
  const hiddenButtonRef = useRef(null);
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID; // Use environment variable for security
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const frontURL = process.env.REACT_APP_FRONTEND_URL;
  const { setUserMetaData,setToken, setAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate()

  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);
    };

    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id: CLIENT_ID,
	callback: handleCredentialResponse,login_uri: frontURL,
      });

      window.google.accounts.id.renderButton(
        document.getElementById("class-signin"),
        { theme: "outline", size: "large" }
      );
    };
    
    loadGoogleScript();
  },[]);

    const handleCredentialResponse = async (response) => {
      try {
        // console.log("Encoded JWT ID token:", response.credential);

        // Decode the JWT token to extract user info
        const decodedToken = jwtDecode(response.credential);
        // console.log("Decoded Token:", decodedToken);

        // Extract user details from the token
        const { name, email, picture } = decodedToken;

        // Prepare signIn data object
        const signInData = {
          email,
          googleAuth:true,
        };

        // Encrypt the signIn data
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(signInData),
          process.env.REACT_APP_SECRETKEY
        ).toString();

        const encryptjson = { data: encryptedData };
        console.log("Encrypted data to send:", encryptjson);

        // Send signIn request to backend
        setIsLoading(true);
        const signInResponse = await fetch(`${serverURL}/signIn`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(encryptjson),
        });

        const data = await signInResponse.json();
        console.log("SignIn Result:", data);

        if (!signInResponse.ok) {
          throw new Error(data.error || "SignIn failed");
        }

        // Success notification

        // const data = await signInResponse.json();
        // console.log(data,"data")
        if (data.token) {
         setToken(data.token) 
         localStorage.setItem('metadata',data.data);// JSON.stringify(data.data))
          localStorage.setItem('token',data.token)
          if(data.data){
              console.log("data.data")
              const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
              console.log(decryptedData,"dc");//.dbResponse);

          }
          //setUserMetaData(data);
          //setAuthenticated(true);
          navigate("/Dashboard");
        }else{
          var errorMessage = "Something Went Wrong";
          if(data.error)
            errorMessage = data.error;
            showNotification(errorMessage, "error");
      }
      } catch (error) {
        console.error("Error during Google signIn:", error.message);
        showNotification(error.message || "Google signIn failed", "error");
      } finally {
        setIsLoading(false); // Always stop loading
      }
    };

    const handleClick = () => {
      const hiddenDiv = document.getElementById("class-signin");
      if (hiddenDiv) {
        const button = hiddenDiv.querySelector(
          '.nsm7Bb-HzV7m-LgbsSe.hJDwNd-SxQuSe.i5vt6e-Ia7Qfc.uaxL4e-RbRzK'
        );
        if (button) {
          console.log("Button found, triggering click");
          button.click();
        } else {
          console.log("Button not available");
        }
      } else {
        console.log("Hidden div not found");
      }
    };
    

  return (
    // <div className="google-button flex" style={{ padding: '3px' }}>
    //   <div id="signInButton"></div>
    // </div>
    <>
    <div
      className="google-button flex "
      onClick={handleClick}
      role="button"
      tabIndex={0}
    >
      <Gmailbutton className="mr-4" />
      {/* <img
        src={Gmailbutton}
        alt="Google Icon"
        className="google-icon"
      /> */}
      <span>Sign in with Google</span>
    </div>

    <div ref={hiddenButtonRef} id="class-signin" style={{ display: 'none' }} />
  </>
  );
};

export default GoogleSignIn;
