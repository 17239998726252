// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-default {
    padding: 10px;
    display: flex;
    gap: 1rem; /* gap-4 */
    align-items: center;
    text-wrap: nowrap;
    border-radius: 0.75rem; /* rounded-xl */
    width: 100%; /* w-full */
    margin-top: 0.5rem; /* mt-2 */
    background-color: white; /* bg-white */
    padding-right: 120px !important;
    border: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.button-default:first-child {
    margin-top: 2.5rem; /* mt-10 */
}

.button-active {
    /* background: linear-gradient(to right, #7dd3fc, #bbf7d0); */
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    background: linear-gradient(53.77deg, #EBFFF5 -77.35%, #FFFFFF 6.85%, #94E7F9 46.52%, #34D399 111.95%);

}
`, "",{"version":3,"sources":["webpack://./src/components/Calendar/Calendly.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,SAAS,EAAE,UAAU;IACrB,mBAAmB;IACnB,iBAAiB;IACjB,sBAAsB,EAAE,eAAe;IACvC,WAAW,EAAE,WAAW;IACxB,kBAAkB,EAAE,SAAS;IAC7B,uBAAuB,EAAE,aAAa;IACtC,+BAA+B;IAC/B,YAAY;IACZ,4DAA4D;AAChE;;AAEA;IACI,kBAAkB,EAAE,UAAU;AAClC;;AAEA;IACI,6DAA6D;IAC7D,8CAA8C;IAC9C,sGAAsG;;AAE1G","sourcesContent":[".button-default {\n    padding: 10px;\n    display: flex;\n    gap: 1rem; /* gap-4 */\n    align-items: center;\n    text-wrap: nowrap;\n    border-radius: 0.75rem; /* rounded-xl */\n    width: 100%; /* w-full */\n    margin-top: 0.5rem; /* mt-2 */\n    background-color: white; /* bg-white */\n    padding-right: 120px !important;\n    border: none;\n    transition: background-color 0.3s ease, box-shadow 0.3s ease;\n}\n\n.button-default:first-child {\n    margin-top: 2.5rem; /* mt-10 */\n}\n\n.button-active {\n    /* background: linear-gradient(to right, #7dd3fc, #bbf7d0); */\n    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */\n    background: linear-gradient(53.77deg, #EBFFF5 -77.35%, #FFFFFF 6.85%, #94E7F9 46.52%, #34D399 111.95%);\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
