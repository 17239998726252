import React from "react";
import EmailView from "../../components/Triage/emailview";
import Topbar from "../../components/Recruiter/Topbar/Topbar";
const TriageSidebar = () => {
    return (
        <div className="radial-gradient-bg">
                <div>
                <Topbar/>
                </div>
                <EmailView />
            </div>
    );
}
export default TriageSidebar;