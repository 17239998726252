// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-scrollbar::-webkit-scrollbar {
    display: none;
}
.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
@media only screen and (max-width: 600px) {
    .mobile-hidden {
        display: none;
    }    
    .mobile-full-width {
        width: 100%;
    }
    .Notification-mobile{
        width: 350px;
        right: -200%;
    }
    .mobile-create-button{
        padding: 5px 15px !important;
    }
}
.mobile-create-button{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .25rem ;
    padding-bottom: .25rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Recruiter/Topbar/Topbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,wBAAwB;IACxB,qBAAqB;AACzB;AACA;IACI;QACI,aAAa;IACjB;IACA;QACI,WAAW;IACf;IACA;QACI,YAAY;QACZ,YAAY;IAChB;IACA;QACI,4BAA4B;IAChC;AACJ;AACA;IACI,oBAAoB;IACpB,qBAAqB;IACrB,oBAAoB;IACpB,sBAAsB;AAC1B","sourcesContent":[".no-scrollbar::-webkit-scrollbar {\n    display: none;\n}\n.no-scrollbar {\n    -ms-overflow-style: none;\n    scrollbar-width: none;\n}\n@media only screen and (max-width: 600px) {\n    .mobile-hidden {\n        display: none;\n    }    \n    .mobile-full-width {\n        width: 100%;\n    }\n    .Notification-mobile{\n        width: 350px;\n        right: -200%;\n    }\n    .mobile-create-button{\n        padding: 5px 15px !important;\n    }\n}\n.mobile-create-button{\n    padding-left: 1.5rem;\n    padding-right: 1.5rem;\n    padding-top: .25rem ;\n    padding-bottom: .25rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
