import React from "react";
import ResumeMatch from "../../components/ResumeBox/ResumeMatch";
import Topbar from '../../components/Recruiter/Topbar/Topbar';

const ResumeMatchPage = () =>{
    return(
        <div className="radial-gradient-bg">
            <Topbar />
            <ResumeMatch />
        </div>
    )
}
export default ResumeMatchPage;