import React, { useState, useEffect, useRef, useContext } from "react";
import Button from "../elements/Button/Button";
import Textinput from "../elements/TextInput/TextInput";
import Card from "../elements/Card/Card";
import Selectbox from "../elements/SelectBox/SelectBox";
import Toggle from "../elements/ToggleButton/ToggleButton";
import { useCommonContext } from "../../context/CommonContext";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../context/AuthContext";
import CryptoJS from "crypto-js";

const Jobscreening = () => {
    const { jobCreationData, setJobCreationData, validationErrors } = useCommonContext();
    const { token } = useContext(AuthContext);
    const minimumInputRef = useRef(null);
    const [screeningQuestions, setScreeningQuestions] = useState(null);
      useEffect(() => {
        if (Object.keys(validationErrors).length > 0) {
          const errorElement = document.querySelector(".validation-check-failed");
          console.log("Validation error found but not found the div")
          if (errorElement) {
            console.log("Validation error found, scrolling...");
            errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
            errorElement.focus();
          }
        }
      }, [validationErrors]);
    const fetchScreeningQuestions = async ()=>{
          const encryptedMetadata = localStorage.getItem('metadata');
          const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
          const cid = decryptedMetadata.cid;
          const QuestionReq = {
            cid: cid,
            type: "Questions",
          };
      
          const encryptedData = CryptoJS.AES.encrypt(
            JSON.stringify(QuestionReq),
            process.env.REACT_APP_SECRETKEY
          ).toString();
          const encryptjson = { data: encryptedData };
          try {
            const response = await fetch(
              `${process.env.REACT_APP_SERVER_URL}/masters/selectData`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  token: token,
                },
                body: JSON.stringify(encryptjson),
              }
            );
      
            const status = await response.json();
            // const ScreeningQuestions = JSON.parse(CryptoJS.AES.decrypt(status.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            console.log(status.data," Screening Questions");
            setScreeningQuestions(status.data);
          } catch (err) {
            console.error("Error Fetching Job List:", err);
          }finally {
            console.log("Fetched Done");
          }
    }

    useEffect(()=>{
      if(screeningQuestions === null){
        fetchScreeningQuestions();
      }
    },[screeningQuestions]);
    const screenquestions = {
        "Education": { question: "Did you have the following level of education [value] ?" },
        "Background Check": { question: "Question Related to Background Check ?" },
        "Expertise With Skill": { question: "Question Related to Expertise with Skill ?" },
        "Driver's License": { question: "Do you have a valid [value] government's Driver's License ?" },
        "Hybrid Work": { question: "Question Related to Hybrid Work ?" },
        "Drug Test": { question: "Question Related to Drug Test ?" },
        "Industry Experience": { question: "Question Related to Industry Experience ?" },
    };
    const [isCustom, setIsCustom] = useState({});

    const dataTypes = [
        { id: '0', label: 'Yes/No' },
        { id: '1', label: 'Text' },
        // { id: '2', label: 'Array' },
    ];
    const YesorNo =[
      { id: '0', label: 'Yes' },
      { id: '1', label: 'No' },
    ]
    const [fieldType, setFieldType]= useState('Text');

    const [selectedQuestions, setSelectedQuestions] = useState([]);

    const handleCustomToggle = (questionId) => {
        console.log("custom key passed : ",questionId)
        setIsCustom((prev) => ({
          ...prev,
          [questionId]: "yes", // Toggle the isCustom value for the specific question
        }));
      };

    const handleCustom = () => {
        const newQuestionID = uuidv4();
        handleCustomToggle(newQuestionID);
        // handleCustomToggle(jobCreationData.questions.length);
        setJobCreationData((prevData) => ({
            ...prevData,
            questions: [
                ...prevData.questions,
                { newQuestionID, questiontype: "Text", question_desc: "", required: true },
            ],
        }));
    };

    const handleDelete = (indexToRemove) => {
        const tempKeyToRemove = jobCreationData.questions[indexToRemove].newQuestionID
        setJobCreationData((prevData) => ({
            ...prevData,
            questions: prevData.questions.filter((_, index) => index !== indexToRemove),
        }));
        setIsCustom((prev) => {
            const updatedIsCustom = { ...prev };
            delete updatedIsCustom[tempKeyToRemove]; // Remove the deleted question's custom state
            return updatedIsCustom;
          });
    };

    const handleInputChange = (index, field, value) => {
        setJobCreationData((prevData) => ({
            ...prevData,
            questions: prevData.questions.map((q, i) =>
                i === index ? { ...q, [field]: value } : q
            ),
        }));
    };
    const [minimumHidden, setHiddendiv] = useState([]);
    const [requiredControl, setControl] = useState(72);
    // const handleCheckBox = (index) => {
    //     setHiddendiv((prev) => (prev === index ? null : index));
    //     setControl(72);
    // };
    const handleCheckBox = (index) => {
        setJobCreationData((prevState) => {
          const updatedQuestions = [...prevState.questions];
      
          if (updatedQuestions[index]?.minimum === undefined || updatedQuestions[index].minimum === null) {
            updatedQuestions[index] = {
                ...updatedQuestions[index],
                minimum: '1',  // Default value for minimum
              };
          } else {
            delete updatedQuestions[index].minimum; 
          }
      
          return {
            ...prevState,
            questions: updatedQuestions,
          };
        });
      
        setTimeout(() => setControl(72), 0);
      };
      
      
      useEffect(() => {
        // Set default questions on component mount
        const defaultQuestions = [
            { questiontype: "Text", question_desc: "Have you completed the following level of degree [value]?", required: true, recommented: true, hasValue: true },
            { questiontype: "Text", question_desc: "How many years of work experience do you have?", required: true, recommented: true, hasValue: false },
            { questiontype: "Text", question_desc: "Do you have a excellent communication skill ? ", required: true, recommented: true, hasValue: false },
        ];

        if(jobCreationData.questions.length === 0){
        setJobCreationData((prevData) => ({
            ...prevData,
            questions: defaultQuestions,
        }));
      }
    }, []);

    const SelectScreeningQuestion = (key) => {
        const selectedQuestion = key.question_desc || "Default Question";
        let hasValue = key.value;
        // if(selectedQuestion.includes("[value]")){
        //   hasValue = true;
        // }
        setJobCreationData((prevData) => ({
            ...prevData,
            questions: [
                ...prevData.questions,
                { questiontype: key.questiontype, question_desc: selectedQuestion, required: true, hasValue: hasValue },
            ],
        }));
        setSelectedQuestions((prev) => [...prev, key]); // Add the selected question to the state
    };

    useEffect(() => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
        });
    }, [jobCreationData]);
    const [selecedDataType, setSelectedDataType] = useState("");
    const handleSelectDataType = (index,e) => {
      if (minimumInputRef.current) {
        minimumInputRef.current.focus();
    }
        const DataTypeSelected = dataTypes[e.target.value].label;
        setFieldType(DataTypeSelected);
        setSelectedDataType(e.target.value)
        // console.log(e.target.value);
        // const questionType = DataTypeSelected === 'Text'? 'String': DataTypeSelected
        setJobCreationData((prevData) => ({
            ...prevData,
            questions: 
                    prevData.questions.map((old, idx) =>
                          idx === index
                              ? { ...old, questiontype:  DataTypeSelected} // Update the specific question
                              : old // Keep other questions unchanged
                      )

        }))
        if (DataTypeSelected === "Yes/No") {
          setJobCreationData((prevData) => {
            const updatedQuestions = [...prevData.questions];
            updatedQuestions[index].minimum = "Yes";
            return { ...prevData, questions: updatedQuestions };
          });
        }
        else{
        setJobCreationData((prevData) => {
          const updatedQuestions = [...prevData.questions];
          updatedQuestions[index].minimum = "";
          return { ...prevData, questions: updatedQuestions };
        });
      }
    }
    const getValueofKey = (e) => {
      const value = e==='String' ? 'Text' : e==='Number' ? 'Yes/No': e;
      const data = dataTypes.find((key) => key.label === value);
      return data.id;
    }
        
    const updateQuestionAndRequired = (index, newQuestion) => {
        setJobCreationData((prevData) => ({
            ...prevData,
            questions: 
                    prevData.questions.map((old, idx) =>
                          idx === index
                              ? { ...old, question_desc: newQuestion } // Update the specific question
                              : old // Keep other questions unchanged
                      )

        }))
    };
const updateMinimum = (index, value, fieldType) => {
if (fieldType === "Text") {
      setJobCreationData((prevData) => {
        const updatedQuestions = [...prevData.questions];
        updatedQuestions[index].minimum = value;
        return { ...prevData, questions: updatedQuestions };
      });
    }
else if (fieldType === "Yes/No") {
  const lable = YesorNo[value].label;
  setJobCreationData((prevData) => {
    const updatedQuestions = [...prevData.questions];
    updatedQuestions[index].minimum = lable;
    return { ...prevData, questions: updatedQuestions };
  });
}
  };
  const findValueofyesorno = (label) => {
    if(label != "" && label != undefined){
    const data = YesorNo.find((key) => key.label === label);
    return data.id;
    }else{
      return 0;
    }
  }

    const handleRequired = (index, required_value) => {
        setJobCreationData((prevData) => ({
            ...prevData,
            questions: 
                    prevData.questions.map((old, idx) =>
                          idx === index
                              ? { ...old, required: !old.required } // Update the specific question
                              : old // Keep other questions unchanged
                      )

        }))
    };
    const [value, setValue] = useState([
      {index: 0, value: ""}
    ]);
    const [oldValue, setoldValue] = useState([
      {index: 0, value: ""}
    ])
    const handleQuestionValueChange = (currentValue, index) => {
      setoldValue((prevOldValues) => {
        const updatedList = [...prevOldValues];
        const existingIndex = updatedList.findIndex(item => item.index === index);
        if (existingIndex !== -1) {
          updatedList[existingIndex].value = currentValue;
        } else {
          updatedList.push({ index, value: currentValue });
        }
        return updatedList;
      });
      const prevE = oldValue.find((value)=> value.index === index)?.value || ""; // Ensure `oldValue` fallback
      console.log("Old Value:", prevE, "Current Value:", currentValue, "Index:", index);
    
      setJobCreationData((prevData) => {
        const updatedQuestions = [...prevData.questions];
        const targetQuestion = updatedQuestions[index];
    
        if (targetQuestion.question_desc.includes("[value]")) {
          targetQuestion.question_desc = targetQuestion.question_desc.replace("[value]", currentValue);
        } else if (targetQuestion.question_desc.includes(prevE)) {
          targetQuestion.question_desc = targetQuestion.question_desc.replace(prevE, currentValue);
        }
    
        return { ...prevData, questions: updatedQuestions };
      });
    };
    const handleValueChangecustom = (e, index) => {
      setValue((prevValue) => {
        const updatedList = [...prevValue];
        const existingIndex = updatedList.findIndex(item => item.index === index);
    
        if (existingIndex !== -1) {
          updatedList[existingIndex].value = e.target.value;
        } else {
          updatedList.push({ index, value: e.target.value});
        }
        return updatedList;
      });
    };
    

    return (
        <div className="ml-7">
            <div className="w-[93%]">
                <p className="mt-[15px] font-medium text-[28px]">2. Screening Questions</p>
                <p className="text-[14px]">
                    By using screening questions your job post is highlighted to matching members, while screened
                    applicants are also highlighted to ensure qualified candidates are not missed, and applicants are
                    sorted based on qualifications met.
                </p>
            </div>
            <div>
                <p className="text-[20px] font-[500]">Add Screening Questions</p>
                <div className={`mobile-full-width flex w-[50%] flex-wrap ${validationErrors.questions ? "border-red-500 validation-check-failed border-2" : ""}`}>
                    {/* {Object.keys(screenquestions).map((key, index) => (
                        <Button
                            key={index}
                            className={`!p-[12px] !shadow-lg text-[14px] !rounded-full ml-[12px] text-nowrap mt-[12px] ${
                                // selectedQuestions.includes(key) ? "bg-gray-300 cursor-not-allowed" : "bg-teal-50"
                                jobCreationData.questions.some(
                                    (question) => question.question_desc === screenquestions[key].question) ? "bg-gray-300 !cursor-not-allowed !cursor-none	" : "bg-teal-50"
                            }`}
                            onClick={() => {
                                if (
                                  !jobCreationData.questions.some(
                                    (question) => question.question_desc === screenquestions[key].question
                                  )
                                ) {
                                  SelectScreeningQuestion(key); // Trigger the action only if not disabled
                                }
                              }}                            
                        >
                            {key} +
                        </Button>
                    ))} */}
                    {screeningQuestions?.map((value, index) => (
                        <Button
                            key={index}
                            className={`!p-[12px] !shadow-lg text-[14px] !rounded-full ml-[12px] text-nowrap mt-[12px]  ${
                                // selectedQuestions.includes(key) ? "bg-gray-300 cursor-not-allowed" : "bg-teal-50"
                                jobCreationData.questions.some(
                                    (question) => question.question_desc === value.question_desc) ? "bg-gray-300 !cursor-not-allowed !cursor-none	" : "bg-teal-50"
                            }`}
                            onClick={() => {
                                if (
                                  !jobCreationData.questions.some(
                                    (question) => question.question_desc === screeningQuestions.question_desc
                                  )
                                ) {
                                  SelectScreeningQuestion(value); // Trigger the action only if not disabled
                                }
                              }}                            
                        >
                            {value.question_key} +
                        </Button>
                    ))}
                    <Button
                        className="!p-[12px] !shadow-lg text-[14px] !rounded-full bg-teal-50 ml-[12px] text-nowrap mt-[12px]"
                        onClick={handleCustom}
                    >
                        Custom +
                    </Button>
                </div>
            </div>
            <span className="mt-4 block text-green-800">We recomment adding 3 or more questions. Applicants must answer each question.</span>
            <div>
            {Object.values(jobCreationData.questions).map((item, index) => (
                    <Card key={index} CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end" CardBodyClass={"gap-8"}
                        CardClass="mobile-full-width min-w-md bg-white relative shadow rounded-lg w-[full]  mt-7 !rounded-xl"
                        body={<>
                            <div className="w-[99%]">
                            <div className="w-[8%] h-[34%] relative  left-[97.7%] top-[4px]">
                         <svg xmlns="http://www.w3.org/2000/svg" className="absolute hover:cursor-pointer" onClick={()=>handleDelete(index)} x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
                            <path d="M 24 4 C 20.491685 4 17.570396 6.6214322 17.080078 10 L 10.238281 10 A 1.50015 1.50015 0 0 0 9.9804688 9.9785156 A 1.50015 1.50015 0 0 0 9.7578125 10 L 6.5 10 A 1.50015 1.50015 0 1 0 6.5 13 L 8.6386719 13 L 11.15625 39.029297 C 11.427329 41.835926 13.811782 44 16.630859 44 L 31.367188 44 C 34.186411 44 36.570826 41.836168 36.841797 39.029297 L 39.361328 13 L 41.5 13 A 1.50015 1.50015 0 1 0 41.5 10 L 38.244141 10 A 1.50015 1.50015 0 0 0 37.763672 10 L 30.919922 10 C 30.429604 6.6214322 27.508315 4 24 4 z M 24 7 C 25.879156 7 27.420767 8.2681608 27.861328 10 L 20.138672 10 C 20.579233 8.2681608 22.120844 7 24 7 z M 11.650391 13 L 36.347656 13 L 33.855469 38.740234 C 33.730439 40.035363 32.667963 41 31.367188 41 L 16.630859 41 C 15.331937 41 14.267499 40.033606 14.142578 38.740234 L 11.650391 13 z M 20.476562 17.978516 A 1.50015 1.50015 0 0 0 19 19.5 L 19 34.5 A 1.50015 1.50015 0 1 0 22 34.5 L 22 19.5 A 1.50015 1.50015 0 0 0 20.476562 17.978516 z M 27.476562 17.978516 A 1.50015 1.50015 0 0 0 26 19.5 L 26 34.5 A 1.50015 1.50015 0 1 0 29 34.5 L 29 19.5 A 1.50015 1.50015 0 0 0 27.476562 17.978516 z"></path>
                            </svg>
                         </div>
                         {isCustom[item.newQuestionID] ? <Textinput label="Enter Question:" labelclass="font-[500] text-[20px]" 
                         className={` w-full bg-teal-50 ${
                            validationErrors['desc'+index] ? "border-red-500 validation-check-failed" : "outline-none"
                          }`}
                          
                         value={item.question_desc}
                         onChange={(e) => updateQuestionAndRequired(index, e.target.value)}
                         /> : <h4 style={{display:`${item.recommented ? `flex`: ``}`}}>{item.question_desc}{item.recommented && <div style={{marginLeft: '10px'}}><span style={{padding: '5px 20px',color:'white',backgroundColor: '#15803d',borderRadius:'20px'}} className="text-[14px] text-gray-500">Recommended</span></div>}</h4>}
                         
                         </div>
                         
                         {/* <Textinput type="checkbox" labelclass="hidden" 
                           checked={jobCreationData.questions && jobCreationData.questions[index]?.minimum !== undefined} 
                         onChange={()=>handleCheckBox(index)} className="MinimumCheckbox float-left  !w-[2.2%] mt-[1px]" postlabel="Minimum Requirement" postlabelclass="ml-2 mb-0" divclass="flex mt-[12px]"/> */}
                         {(jobCreationData.questions  &&
                         <div>
                         {item.hasValue && 
                         <div className="mt-4">
                            <Textinput label="Enter Value" 
                            className={`!w-[20%] mt-2 pt-[12px] pr-[16px] pb-[12px] pl-[16px] !outline-none ${validationErrors['value-'+index] ? "border-red-500 validation-check-failed" : ""}`}
                            onChange={(e)=> handleValueChangecustom(e,index)}
                            divclass="flex items-center"
                            svgicon={
                              <div className="mt-2 ml-2" onClick={()=>handleQuestionValueChange(value.find((value)=>value.index === index)?.value,index)}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M20 6L9 17l-5-5" />
                              </svg></div>}/>
                              <span style={{fontSize: '13px'}}>(click the tick icon once you entered all the values you needed to show in question)</span>
                         </div>
                         }
                          <div className="mt-4 flex" >
                            <div className="flex" style={{alignItems: 'center',justifyContent: 'center'}}>
                              <h5  className="text-center mr-4">Minimum requirement : </h5>
                              {jobCreationData.questions[index]?.questiontype === "Yes/No" ? 
                              <Selectbox 
                                  InputRef={minimumInputRef} 
                                  list={YesorNo} 
                                  value={findValueofyesorno(jobCreationData?.questions[index]?.minimum) || 0} 
                                  onChange={(e) => updateMinimum(index, e.target.value, jobCreationData.questions[index]?.questiontype)} 
                                  className={`ResponseType !w-[auto] mt-0 pt-[12px] pr-[16px] pb-[12px] pl-[16px]  ${validationErrors['minimum'] ? `validation-check-failed border-red=500`:`!outline-none`}`}/>
                                  :
                              <Textinput InputRef={minimumInputRef} 
                                  labelclass="hidden" 
                                  value={jobCreationData.questions[index]?.minimum || ""} 
                                  onChange={(e) => updateMinimum(index, e.target.value, jobCreationData.questions[index]?.questiontype)} 
                                  className={`w-full bg-teal-50 !rounded-[6px] h-full MinimumTextValue ${validationErrors['minimum-'+index] ? "validation-check-failed border-red-500" : ""}`} 
                                  divclass="m-0 p-0"/>
                              }
                            </div>
                              <div className=" flex">
                                  <Selectbox label="Response Type" 
                                  labelClass="flex items-center ml-4 mr-2" 
                                  list={dataTypes} 
                                  value={getValueofKey(jobCreationData.questions[index]?.questiontype)} 
                                  onChange={(e) => handleSelectDataType(index,e)} 
                                  className="ResponseType !w-[auto] mt-0 pt-[12px] pr-[16px] pb-[12px] pl-[16px] !outline-none"/>
                              </div>
                          </div>
                          </div>)}
                          <div className={`RequireControl w-[12%] h-[6%]  left-[87%] top-[${requiredControl}%] float-right`}><Toggle onChange={(e) => handleRequired(index,e.target.value)} toggled={jobCreationData.questions && jobCreationData.questions[index]?.required === true} className="ml-2 " spanbefore="Required"/></div>
                         </>}
                    />
                ))}
            </div>
        </div>
    );
};

export default Jobscreening;
