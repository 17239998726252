import React from 'react';
const ModalBox = (props)=>{
  const cardClass = props.cardClass || "";
    return (
        <>
            <div className={`fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-40 w-full ${props.ModalTopClass}`}>
              <div className={`bg-white rounded-lg shadow-lg relative ${props.cardClassCustom ? props.cardClassCustom : 'p-6'} ${cardClass}`}>
                <div className={`flex justify-between ${props.modal_Header_Class}`}>
                  <p className={`${props.modal_Header_Text}`}>{props.Modal_Header}</p>
                  <button className={`text-gray-400 hover:text-gray-600 ${props.modal_Close_Class}`} onClick={props.Modal_Close_Func}>✕</button>  
                </div>
                  <div className={`${props.modal_Body_Class}`}>
                  {props.Modal_Body}
                  </div>
                  <div className={`${props.modal_Fooder_Class}`}>
                  {props.Modal_Footer}
                  </div>
              </div>
            </div>
        </>
    )
}
export default ModalBox;
