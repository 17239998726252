import React,{ useState } from "react";
import ModalBox from "../elements/ModalBox/ModalBox";
import Button from "../elements/Button/Button";
import Textinput from "../elements/TextInput/TextInput";
import RadioInput from "../elements/RadioInput/RadioInput";
import Logo from "../Common/Logo";
import TeamMember from "../TeamMember/TeamMember";
const OngoingAddTeam = () => {

  const profile_navbar_dropdown = () => {                   //Dropdown in navbar
    setIsOpen(!isOpen);
  };
  
    return(
 <div>   
<div className="flex gap-5 ">
<div className="w-3/12 pr-2 mr-3 bg-gradient-to-r from-white to-white-50 to-emerald-50 ...">
        <div className="ml-4">
        <Logo/>
        </div>
        
        
            <p className="ml-8 mt-8 mb-8 text-xl text-black font-medium">Steps For Profile Registering</p>
            <div className="bg-white flex rounded-lg  w-10/12 ml-6 h-16 mt-1  pr-4 pl-2">
            <div className="bg-white pr-2 w-11 ml-2 h-11 mt-2  ">
              <div className="bg-white w-1/8 border-2 border-gray-500 rounded-full ml-1.5 p-2 w-8 h-8 mt-1.5">
                
              </div>
              </div>
              <p className="text-black absolute pt-3 text-lg  pl-6 ">1</p>
                <div>
                <p className="text-black  mt-2.5 ml-4 text-sm">Company Details<p className="text-gray-400 text-xs ">Step desciption</p></p>
                
                </div>
            </div>
            <div className="h-14 ml-32 border-l-2 border-green-500"></div>
            <div className="bg-black flex rounded-lg  w-10/12 ml-6 h-16   pr-4 pl-2">
            <div className="bg-black pr-2 w-11 ml-2 h-11 mt-2 border-dashed border-2 border-green-500 rounded-full">
              <div className="bg-green-800 w-1/8 rounded-full ml-1.5 p-2 w-7 h-7 mt-1.5">
                
              </div>
              </div>
              <p className="text-white absolute mt-3 ml-1.5 text-lg  pl-5">2</p>
                <div>
                <p className="text-white  mt-2.5 ml-4 text-sm">Add Collaborators<p className="text-gray-400 text-xs ">Step desciption</p></p>
                
                </div>
            </div>
            
        </div>
        <TeamMember className="mt-24" />
    

  </div>
</div>
)
};
export default OngoingAddTeam;