import React, { useEffect, useState } from "react";
import Card from "../components/elements/Card/Card";
import Button from "../components/elements/Button/Button";
import Webenza from "../assets/Images/webenza.jpg";
import Vector from "../assets/Images/Vector.png";
import person from "../assets/Images/person.jpg";
import ClipLoader from "react-spinners/ClipLoader";
import Linkedin from "../assets/Images/linkedin.jpg";
import CryptoJS from "crypto-js";
import { useLocation } from "react-router-dom";

const ShowJob = () => {
  const [jobArray, setJobPOst] = useState([]);
  const [teamArray, setTeam] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jobId = searchParams.get("id");
  const companyProfile = searchParams.get("profile");
  const userName = searchParams.get("userName");
  const status = searchParams.get("status");

  // Function to fetch job details
  const listJobDetails = async () => {
    if (!jobId) return; // Prevent unnecessary API call if no jobId exists

    const job_id = { job_id: jobId };
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(job_id),
      process.env.REACT_APP_SECRETKEY
    ).toString();

    const encryptjson = { data: encryptedData };

    setIsLoading(true);

    try {
      const serverURL = process.env.REACT_APP_SERVER_URL;
      const response = await fetch(`${serverURL}/job/fetchSharedJobDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(encryptjson),
      });

      const data = await response.json();

      const decryptedData = JSON.parse(
        CryptoJS.AES.decrypt(
          data.data,
          process.env.REACT_APP_SECRETKEY
        ).toString(CryptoJS.enc.Utf8)
      );

      setJobPOst(decryptedData["job_details"]);
      setTeam(decryptedData["job_team"]);
      console.log('jobArray',jobArray);
    } catch (error) {
      console.error("Error fetching job details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    listJobDetails();
  }, [jobId]);

  // Helper function to format the date
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const formatDescription = (text) => {
    if (!text || text.trim() === "") {
      return <p className="text-[14px] text-[#747474]">No description available</p>;
    }

    return text.split('\n').map((line, index) => {
      // If the line is empty, we want to return just a <br /> for spacing
      if (line.trim() === "") {
        return <br key={index} />;
      }

      // Otherwise, return a <p> tag for the line of text
      return <p key={index} className="text-[14px] text-[#747474]">{line}</p>;
    });
  };

  return (
    <div className="w-[70%] ml-14 rounded-xl bg-white bg-opacity-50 p-[30px] grid gap-4">
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <ClipLoader color="#3498db" loading={isLoading} size={50} />
          <span className="text-white mt-4">Fetching details...</span>
        </div>
      )}

      {Array.isArray(jobArray) &&
        jobArray.map((item, index) => (
          <div key={index}>
            <Card
              CardClass="!rounded-2xl p-2 border-1 border-white shadow-md"
              body={
                <>
                  <div className="flex">
                    <div className="w-[80%]">
                      <div className="flex justify-between">
                        <div>
                          <p className="text-[24px] font-[500] mb-2">
                            {item.title || ""}
                          </p>
                        </div>
                        <div className="mt-[12px]">
                          <li
                            className={`before:content-['•'] before:mr-2 list-none pl-2 pr-2 pt-0.5 rounded-full text-xs ml-4 ${
                              status === "In Review"
                                ? "before:text-yellow-700 bg-yellow-200 text-yellow-900"
                                : status === "Active"
                                ? "before:text-green-700 bg-green-200 text-green-900"
                                : status === "Closed"
                                ? "before:text-red-700 bg-red-200 text-red-900"
                                : ""
                            }`}
                          >
                            {status}
                          </li>
                        </div>
                      </div>
                      <div>
                        <div className="flex gap-4">
                          <p className="text-black font-medium">
                            <span className="font-[500]">Created By: </span>{" "}
                            {userName || ""}
                          </p>
                          <p className="before:content-['•'] before:text-green-700 before:mr-2 mb-2 list-none font-medium">
                            <span className="font-[500]">Posted: </span>{" "}
                            {formatDate(item.created_at)}
                          </p>
                        </div>
                        <p className="text-black font-medium">
                          <span className="font-[500]">Location: </span>{" "}
                          {item.location || " "}
                        </p>
                        <p className="text-black font-medium">
                          Workspace:{" "}
                          <span className="font-[500]">
                            {item.workplace_type || " "}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="w-[50%] mt-2 justify-end flex hidden">
                      <div>
                        <Button className="bg-[#059669] pl-4 pr-4 h-[40px] text-white">
                          View
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              }
            />

            <Card
              CardClass="!rounded-2xl p-2 mt-4 border-1 border-white shadow-md"
              CardBodyClass="grid gap-4"
              body={
                <>
                  <div className="border-2 border-gray-50 rounded-xl p-3 shadow-md">
                    <div className="flex gap-4 pb-6  border-b-2 border-gray-200">
                        <div className="py-3">
                         <img
                          src={`${process.env.REACT_APP_SERVER_BASEURL}/companyprofile/${companyProfile}`}
                          className="w-12 h-12 border-1 border-gray-300 rounded-lg object-cover"
                          alt="Company Profile"
                        />
                        <p>{item.profile?item.profile:''}</p>
                        </div>
                        <div>
                            <p className="text-[16px] text-gray-400 mb-0">{item.department?item.department:''}</p>
                            <p className="text-[24px] font-[500] mb-0">{item.title?item.title:''}</p>
                            <div className="flex gap-2 mt-2">
                                {item?.experiance && (
                                  <span className="text-xs bg-green-50 px-2 py-1 rounded-lg">
                                    {item.experiance || "Fresher"}
                                  </span>
                                )}
                                {item?.WorkplaceType && (
                                  <span className="text-xs bg-green-50 px-2 py-1 rounded-lg">
                                    {item.WorkplaceType || "On-Site"}
                                  </span>
                                )}
                                {item?.location && (
                                  <span className="text-xs bg-green-50 px-2 py-1 rounded-lg">
                                    {item.location}
                                  </span>
                                )}
                                {item?.salary && (
                                  <span className="text-xs bg-green-50 px-2 py-1 rounded-lg">
                                    {item.salary}
                                  </span>
                                )}
                            </div>
                        </div>
                        {/* <div className="w-[30%] ml-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" className=" float-right mt-3" y="0px" width="20px" height="20px" viewBox="0 0 24 24">
                        <path d="M 6.0097656 2 C 4.9143111 2 4.0097656 2.9025988 4.0097656 3.9980469 L 4 22 L 12 19 L 20 22 L 20 20.556641 L 20 4 C 20 2.9069372 19.093063 2 18 2 L 6.0097656 2 z M 6.0097656 4 L 18 4 L 18 19.113281 L 12 16.863281 L 6.0019531 19.113281 L 6.0097656 4 z"></path>
                        </svg>
                        </div> */}
                    </div>
                    <p className="text-lg font-medium">Job Description</p>
                    {formatDescription(item.job_desc || "")}
                  </div>

                  <div className="border-2 border-gray-50 rounded-xl p-3 shadow-md">
                    <p className="text-lg font-medium">
                      Skills and Requirements
                    </p>
                    {Array.isArray(item.skills) && item.skills.length > 0 ? (
                      item.skills.map((skill, idx) => (
                        <li key={idx} className="text-[14px] text-[#747474]">
                          {skill}
                        </li>
                      ))
                    ) : (
                      <p className="text-[14px] text-[#747474]">
                        No skills listed
                      </p>
                    )}
                  </div>

                  <div className="border-2  border-gray-50 rounded-xl p-3 shadow-md">
                    <p className="text-lg font-medium">Meet Your Team</p>
                    <div className="flex gap-[26px]">
                      {Array.isArray(teamArray) &&
                        teamArray.map((teamMember, idx) => (
                          <div className="grid gap-[10px] relative" key={idx}>
                            {teamMember.url && (
                              <div className="bg-white p-[1.05px] w-[55px] h-[55px] hover:cursor-pointer rounded-full">
                                <a
                                  href={teamMember.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={Linkedin}
                                    alt="LinkedIn"
                                    className="w-full h-full"
                                  />
                                </a>
                              </div>
                            )}
                            <div className="grid gap-[8px]">
                              <p className="text-base font-[500] mb-0">
                                {teamMember.name || " "}
                              </p>
                              <p className="text-xs font-[400]">
                                {teamMember.designation || " "}
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              }
            />
          </div>
        ))}
    </div>
  );
};

export default ShowJob;
