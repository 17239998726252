// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  h4 {
    font-family: Arial, sans-serif;
    color: #555;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Calendar/meetings.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,6BAA6B;IAC7B,kBAAkB;IAClB,kCAAkC;IAClC,mBAAmB;EACrB;;EAEA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF;;EAEA;IACE,8BAA8B;IAC9B,WAAW;EACb","sourcesContent":[".loader-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    text-align: center;\n  }\n  \n  .spinner {\n    width: 50px;\n    height: 50px;\n    border: 6px solid #f3f3f3;\n    border-top: 6px solid #3498db;\n    border-radius: 50%;\n    animation: spin 1s linear infinite;\n    margin-bottom: 10px;\n  }\n  \n  @keyframes spin {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  \n  h4 {\n    font-family: Arial, sans-serif;\n    color: #555;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
