// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.List-button{
    border: 1px solid #059669;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-right: none;
    border-radius: 10px 0 0 10px;
}
.Calendar-button {
    border: 1px solid #059669;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 0 10px 10px 0;
}
.Active {
    background-color: #059669;
}`, "",{"version":3,"sources":["webpack://./src/components/Calendar/Availability.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,4BAA4B;AAChC;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,4BAA4B;AAChC;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".List-button{\n    border: 1px solid #059669;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n    padding: 10px;\n    border-right: none;\n    border-radius: 10px 0 0 10px;\n}\n.Calendar-button {\n    border: 1px solid #059669;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n    padding: 10px;\n    border-radius: 0 10px 10px 0;\n}\n.Active {\n    background-color: #059669;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
