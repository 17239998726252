import React from "react";
import MessageTemplate from "../../components/MessageTemplate/messageTemplate";

const Message=()=>{
    return(
        <>
        <MessageTemplate/>
        </>
    );
}
export default Message