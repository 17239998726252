import React, { useState, useRef, useEffect, useContext } from "react";
import Button from "../elements/Button/Button";
import Notification from "../Notification/Notification";
import ClipLoader from "react-spinners/ClipLoader";
import CryptoJS from "crypto-js";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import SearchInput from "../elements/SearchInput/SearchInput";
import { useCommonContext } from "../../context/CommonContext";
import star from "../../assets/star-search.png";
import ButtonSolid from "../elements/Button/ButtonSolid";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";

export default function ResumeUpload() {
  const {
    searchTerm,
    setSearchTerm,
    setIsAIsearch,
    isAIsearch,
    showUpload,
    setShowUpload,
    aiSearchResult,
    setAiSearchResult,
  } = useCommonContext();
  var [isUploaded, setIsUploaded] = useState(false);
  var [resumeFiles, setResumeFiles] = useState([]);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [haveData, setHaveData] = useState(false);
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState([
    "AI Engineer",
    "Data Scientist",
    "Machine Learning",
    "Deep Learning",
    "Natural Language Processing",
  ]);

  // for change the page
  var handleUploadClick = () => {
    setIsUploaded(true);
  };

  useEffect(() => {
    getResumeData();
  }, []);
  const [resumeLatestDate, setresumeLatestDate] = useState("");

  const getResumeData = async () => {
    var cid;
    var userId;
    const encryptedMetadata = localStorage.getItem("metadata");
    if (encryptedMetadata) {
      // && encryptedMetadata.data) {
      const decryptedMetadata = JSON.parse(
        CryptoJS.AES.decrypt(
          encryptedMetadata,
          process.env.REACT_APP_SECRETKEY
        ).toString(CryptoJS.enc.Utf8)
      );
      if (decryptedMetadata.cid) {
        console.log(decryptedMetadata.cid);
        cid = Number(decryptedMetadata.cid);
        userId = Number(decryptedMetadata.uid);
      }
    }
    if (!userId || userId == "") {
      showNotification("User ID Not Stored", "success");
    }
    var formData = { userId: userId, cid: cid };
    // console.log(formData.get("file"));
    setIsLoading(true);
    try {
      const serverURL = process.env.REACT_APP_SERVER_URL;
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(formData),
        process.env.REACT_APP_SECRETKEY
      ).toString();
      var searchData = { data: encryptedData };
      const response = await fetch(`${serverURL}/company/getResumeData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify(searchData), // Automatically sets the correct Content-Type
      });
      const result = await response.json();
      console.log("Server Response:", result);
      if (!response.ok) {
        throw new Error(errorMessage);
      }
      const decryptedData = JSON.parse(
        CryptoJS.AES.decrypt(
          result.data,
          process.env.REACT_APP_SECRETKEY
        ).toString(CryptoJS.enc.Utf8)
      );
      console.log(decryptedData);
      if (decryptedData.length > 0) {
        console.log("nilll");
        setresumeLatestDate(decryptedData[0].created_at);
        setHaveData(true);
      }
      // showNotification(result.message, "success");

      // signUpImageFun(result.files.file[0]['newFilename'])
      // signUpImageNameFun(result.files.file[0]['originalFilename'])
      // console.log(result.files.file[0]['newFilename'])
    } catch (error) {
      console.error("Error uploading file:", error);
      showNotification(error.message, "error");
    } finally {
      setIsLoading(false); // Always hide loader after the request
    }
  };

  const showNotification = (message, type) => {
    //For Notification
    setNotification({ message, type });
    setTimeout(() => {
      setNotification({ message: "", type: "" });
    }, 3000); // Auto-hide after 3 seconds
  };

  // for handle drag and drop
  var handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  var handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    var droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length > 0) {
      setResumeFiles((prev) => [...prev, ...Array.from(droppedFiles)]);
    }
  };

  // save the file using state
  var handleFileInputChange = (e) => {
    var selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      setResumeFiles((prev) => [...prev, ...Array.from(selectedFiles)]);
    }
    console.log(selectedFiles);
  };

  // trigger the input via click the div
  var handleDivClick = () => {
    document.getElementById("fileInput").click();
  };

  // save resume api calling
  var saveResume = async () => {
    console.log(resumeFiles);
    if (!resumeFiles || resumeFiles.length < 1) {
      showNotification("Please Select The Resume", "error");
      return;
    }
    var cid;
    var userId;
    const encryptedMetadata = localStorage.getItem("metadata");
    if (encryptedMetadata) {
      // && encryptedMetadata.data) {
      const decryptedMetadata = JSON.parse(
        CryptoJS.AES.decrypt(
          encryptedMetadata,
          process.env.REACT_APP_SECRETKEY
        ).toString(CryptoJS.enc.Utf8)
      );
      if (decryptedMetadata.cid) {
        console.log(decryptedMetadata.cid);
        cid = decryptedMetadata.cid;
        userId = decryptedMetadata.uid;
      }
    }
    const formData = new FormData();
    resumeFiles.forEach((file) => {
      console.log("Appending file:", file.name);
      formData.append("file", file);
    });

    formData.append("userId", userId);
    formData.append("cid", cid);
    // console.log(formData.get("file"));
    setIsLoading(true);
    try {
      const serverURL = process.env.REACT_APP_SERVER_URL;
      const response = await fetch(`${serverURL}/company/uploadResume`, {
        method: "POST",
        headers: {
          // 'Content-Type': 'application/json',
          token: token,
        },
        body: formData, // Automatically sets the correct Content-Type
      });
      const result = await response.json();
      console.log("Server Response:", result);
      if (!response.ok) {
        const errorMessage =
          result.error?.message || JSON.stringify(result.error);
        throw new Error(errorMessage);
      }
      showNotification(result.message, "success");
      setIsUploaded(true);
      setResumeFiles([]);

      // signUpImageFun(result.files.file[0]['newFilename'])
      // signUpImageNameFun(result.files.file[0]['originalFilename'])
      // console.log(result.files.file[0]['newFilename'])
    } catch (error) {
      console.error("Error uploading file:", error);
      showNotification(error.message, "error");
    } finally {
      setIsLoading(false); // Always hide loader after the request
    }
  };
  const navigateUploadResume = () => {
    setIsUploaded(true);
  };
  const backTODash = () => {
    // setIsUploaded(false)
    setResumeFiles([]);
  };

  const changesearchvalue = (value) => {
    setSearchTerm(value);
  };

  const searchProject = async () => {
    if (isAIsearch) {
      if (!searchTerm) {
        showNotification("Please Enter Search Data", "error");
        return;
      }
      var userId = "";
      var cid = "";
      const encryptedMetadata = localStorage.getItem("metadata");
      if (encryptedMetadata) {
        const decryptedMetadata = JSON.parse(
          CryptoJS.AES.decrypt(
            encryptedMetadata,
            process.env.REACT_APP_SECRETKEY
          ).toString(CryptoJS.enc.Utf8)
        );
        if (decryptedMetadata.uid) {
          userId = decryptedMetadata.uid;
        }
        if (decryptedMetadata.cid) {
          cid = decryptedMetadata.cid;
        }
      }
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify({ search: searchTerm, userId: userId, cid: cid }),
        process.env.REACT_APP_SECRETKEY
      ).toString();
      var searchData = { data: encryptedData };
      setIsLoading(true);
      try {
        const serverURL = process.env.REACT_APP_SERVER_URL;
        const response = await fetch(`${serverURL}/JD/searchResumeData`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
          body: JSON.stringify(searchData),
        });
        const result = await response.json();
        console.log("Server Response:", result);
        if (!response.ok) {
          throw new Error(result.error);
        }
        const decryptedData = JSON.parse(
          CryptoJS.AES.decrypt(
            result.data,
            process.env.REACT_APP_SECRETKEY
          ).toString(CryptoJS.enc.Utf8)
        );
        const encryptedMetadata = localStorage.getItem("metadata");
        if (encryptedMetadata) {
          const decryptedMetadata = JSON.parse(
            CryptoJS.AES.decrypt(
              encryptedMetadata,
              process.env.REACT_APP_SECRETKEY
            ).toString(CryptoJS.enc.Utf8)
          );
          decryptedMetadata["jd_id"] = decryptedData.jd_id;
          decryptedMetadata["search"] = searchTerm;
          const encryptedUpdatedMetadata = CryptoJS.AES.encrypt(
            JSON.stringify(decryptedMetadata),
            process.env.REACT_APP_SECRETKEY
          ).toString();
          localStorage.setItem("metadata", encryptedUpdatedMetadata);
        }
        navigate("/Homesearchresults", {
          state: { searchTermState: searchTerm },
        });
      } catch (error) {
        console.error("Error uploading file:", error);
        showNotification(error.message, "error");
      } finally {
        setIsLoading(false); // Always hide loader after the request
      }
    }
  };

  // Function to generate random color in Hex format
  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Data for different filters with random background colors
  const filterData = {
    "Potential Candidate by Role": {
      labels: [
        "Software Development and Engineering",
        "Sales and Marketing",
        "R&D and Innovation",
        "Quality Assurance and Testing",
        "Project and Program Management",
        "Product and Solution Management",
        "Operations and Supply Chain Management",
        "Legal and Compliance",
        "Leadership and Executive Management",
        "Cloud and Infrastructure Engineering",
        "Consulting and Strategy",
        "Content and Media",
        "Data Science, Analytics, and AI",
        "Digital Marketing and E-commerce",
        "Governance, Risk, and Compliance (GRC)",
        "Health, Safety, and Environmental (HSE)",
        "Healthcare and Public Health",
        "Human Resources and Talent",
        "IT Services and Support",
      ],
      data: [
        126, 32, 23, 8, 56, 6, 34, 22, 18, 45, 38, 12, 67, 54, 29, 35, 60, 50,
        42,
      ],
      backgroundColor: [
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
      ],
    },
    "Experience Level": {
      labels: ["Junior", "Mid-level", "Senior", "Lead", "Intern"],
      data: [50, 100, 30, 25, 10],
      backgroundColor: [
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
      ],
    },
    "Industry Backgrounds": {
      labels: [
        "Tech",
        "Healthcare",
        "Finance",
        "Education",
        "Retail",
        "Manufacturing",
      ],
      data: [200, 150, 80, 45, 60, 120],
      backgroundColor: [
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
      ],
    },
    "Industry Average Work ex": {
      labels: ["0-5 years", "5-10 years", "10+ years", "15+ years"],
      data: [180, 120, 90, 50],
      backgroundColor: [
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
      ],
    },
    "Duration in Role": {
      labels: ["<1 year", "1-3 years", "3-5 years", "5+ years", "10+ years"],
      data: [80, 110, 75, 60, 40],
      backgroundColor: [
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
        generateRandomColor(),
      ],
    },
  };

  // State for active card
  const [activeJobCard, setActiveJobCard] = useState(null);

  // Default filter
  const [activeFilter, setActiveFilter] = useState(
    "Potential Candidate by Role"
  );

  // Handle filter click (toggle active state)
  const handleFilterClick = (filter) => {
    setActiveFilter(filter === activeFilter ? null : filter); // Toggle active state
  };

  // Function to handle card click
  const handleCardClick = (jobTitle) => {
    setActiveJobCard(jobTitle === activeJobCard ? null : jobTitle); // Toggle active state
    navigate("/ResumeMatch");
  };

  // Chart data for the active filter
  const chartData = {
    labels: filterData[activeFilter]?.labels || [],
    datasets: [
      {
        data: filterData[activeFilter]?.data || [],
        backgroundColor: filterData[activeFilter]?.backgroundColor || [],
        hoverBackgroundColor: filterData[activeFilter]?.backgroundColor?.map(
          (color) => color + "CC"
        ), // Lighter hover effect
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    cutout: "84%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          // Display the label and the value or percentage
          label: (context) => {
            const percentage = (
              (context.raw / context.dataset.data.reduce((a, b) => a + b)) *
              100
            ).toFixed(2);
            return `${context.label}: ${context.raw} Candidates (${percentage}%)`;
          },
        },
        // Customize tooltip appearance
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        borderRadius: 15,
        padding: 10,
        displayColors: true,
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      },
    },
    elements: {
      arc: {
        borderWidth: 2, // Adjust this value for thicker/thinner ring
        borderColor: "#fff", // Optionally set a color for the border
        borderJoinStyle: "round", // Rounded joints between the arcs
        borderCapStyle: "round", // Rounded ends of the arcs
        borderRadius: 10, // Adds rounded corners to the arc
      },
    },
  };

  // Filters Section
  const filters = [
    "Potential Candidate by Role",
    "Experience Level",
    "Industry Backgrounds",
    "Industry Average Work ex",
    "Duration in Role",
  ];

  // Candidates Data Section
  const candidatesByJobs = [
    { jobTitle: "UX Designer", count: 71 },
    { jobTitle: "Software Developer", count: 177 },
    { jobTitle: "Sales & Marketing", count: 27 },
    { jobTitle: "Content and Media", count: 14 },
    { jobTitle: "Strategy and Consulting", count: 12 },
    { jobTitle: "Product Manager", count: 15 },
    { jobTitle: "Data Scientist", count: 18 },
    { jobTitle: "Machine Learning Engineer", count: 21 },
    { jobTitle: "Frontend Developer", count: 42 },
    { jobTitle: "Backend Developer", count: 37 },
    { jobTitle: "Graphic Designer", count: 29 },
    { jobTitle: "Quality Assurance", count: 24 },
    { jobTitle: "HR Specialist", count: 10 },
  ];
  const getTodayDate = () => {
    const today = new Date(resumeLatestDate);
    // const istOffset = 5.5 * 60; // IST is UTC+5:30
    // const adjustedDate = new Date(today.getTime() + istOffset);
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const yyyy = today.getFullYear();
    return `${dd}-${mm}-${yyyy}`; // Format as DD-MM-YYYY
  };
  const todayDate = getTodayDate();

  return (
    <div className="flex items-center justify-center flex-col gap-4 mx-5 p-5 bg-white bg-opacity-50 rounded-t-2xl h-full">
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="flex flex-col items-center">
            <ClipLoader color="#3498db" loading={isLoading} size={50} />
            <span className="text-white mt-4">Please Wait...</span>
          </div>
        </div>
      )}
      <Notification
        message={notification.message}
        type={notification.type}
        onClose={() => setNotification({ message: "", type: "" })}
      />

      {!isUploaded ? (
        !haveData ? (
          <>
            <div className="flex items-center flex-col justify-center gap-2">
              <h1 className="plusJakartaSansFont text-3xl text-center font-bold">
                No resumes here yet!
              </h1>
              <p className="w-3/4 plusJakartaSansFont m-0 text-base font-normal text-center text-[#3D3D3D]">
                Let our AI help you find the perfect candidates. Upload your
                database to get started.
              </p>
            </div>
            <Button
              onClick={handleUploadClick}
              children={"Upload"}
              className={
                "text-white font-medium text-lg px-4 py-2 rounded !bg-[#059669] plusJakartaSansFont"
              }
            />
            <img src="./image/ResumeBoxBg.svg" alt="" />
          </>
        ) : (
          <div className="w-full">
            <div className="flex justify-between mb-8">
              <div>
                <span>
                  Resume box was last updated on
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <span style={{ color: "#059669" }}>{todayDate}</span>
                  </div>
                </span>
              </div>
              <div className="flex justify-end">
                <Button
                  className="border-[1px] !border-green-700 !bg-transparent rounded-lg px-4 py-2 text-green-700"
                  type="button"
                  onClick={navigateUploadResume}
                  children="Add Resumes +"
                />
              </div>
            </div>
            <div
              className="flex justify-center min-w-md mx-auto search-box-container"
              style={{ height: "110px", width: "100%" }}
            >
              <SearchInput />
            </div>
            {!showUpload && (
              <>
                {isAIsearch && (
                  <div className="suggestions-container">
                    {suggestions.map((suggestion, index) => (
                      <span
                        value={suggestion}
                        onClick={() => changesearchvalue(suggestion)}
                        key={index}
                        className="suggestion-pill"
                      >
                        <img className="search-star" src={star} />
                        {suggestion}
                      </span>
                    ))}
                  </div>
                )}
                <center>
                  <ButtonSolid
                    content="Find Profile"
                    className="my-custom-button"
                    onClick={() => searchProject()}
                    style={{ maxWidth: "120px", overflow: "visible" }}
                    id="button-search"
                    type="button"
                  />
                </center>
              </>
            )}

            {/* Filters Section */}
            <div
              className="mt-8 w-full"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "15px",
                marginBottom: "30px",
                overflowX: "auto",
                scrollbarWidth: "none",
                paddingBottom: "10px",
              }}
            >
              {filters.map((filter, index) => (
                <div
                  key={index}
                  className="relative flex flex-col sm:flex-row items-center justify-between sm:space-x-4"
                >
                  <div
                    onClick={() => handleFilterClick(filter)} // Toggle active filter
                    style={{
                      height: "121px",
                      padding: "20px 16px 20px 16px",
                      borderRadius: "16.67px",
                      gap: "24px",
                      fontFamily: "Space Grotesk",
                      fontSize: "18px",
                      fontWeight: "700",
                      lineHeight: "25.52px",
                      textAlign: "center",
                      border:
                        activeFilter === filter
                          ? "2px solid #0596694D"
                          : "none",
                      background:
                        activeFilter === filter
                          ? "linear-gradient(60.98deg, #EBFFF5 -195.51%, #FFFFFF -56.62%, #94E7F9 14.08%, #34D399 116.74%)"
                          : "#F2F9F9",
                      color: activeFilter === filter ? "#FFFFFF" : "#059669",
                      cursor: "pointer",
                      fontWeight: "bold",
                      transition: "all 0.3s ease",
                      boxShadow:
                        activeFilter === filter
                          ? "0 6px 18px rgba(52, 211, 153, 0.3)"
                          : "none",
                      minHeight: "100px", // Optional: ensures a minimum height
                      minWidth: "200px", // Optional: ensures a minimum width
                      display: "flex", // Use flexbox
                      justifyContent: "center", // Center horizontally
                      alignItems: "center", // Center vertically
                    }}
                  >
                    {filter}
                  </div>

                  {/* Close button */}
                  {(filter == "Industry Backgrounds" ||
                    filter == "Industry Average Work ex") && (
                    <button
                      className="absolute top-2 right-2 text-gray-500 bg-white p-2 rounded-lg focus:outline-none"
                      aria-label="Close modal"
                      onClick={() => handleCloseButtonClick(filter)} // Assuming you have a function for this
                    >
                      <svg
                        className="w-5 h-4"
                        fill="#059669"
                        viewBox="0 0 297.00 297.00"
                        stroke="#059669"
                        strokeWidth="0.00297"
                        transform="matrix(1, 0, 0, 1, 0, 0)"
                      >
                        <g>
                          <path d="M148.5,190.718c9.881,0,17.918-8.038,17.918-17.919c0-9.88-8.037-17.918-17.918-17.918 c-9.881,0-17.918,8.038-17.918,17.918C130.582,182.68,138.619,190.718,148.5,190.718z"></path>
                          <path d="M297,107.999c0-9.881-8.038-17.918-17.918-17.918c-9.881,0-17.919,8.037-17.919,17.918c0,3.205,0.856,6.209,2.337,8.815 l-16.17,16.17c-4.822-11.349-7.186-28.849-7.824-42.229c5.039-3.175,8.403-8.773,8.403-15.156c0-9.88-8.038-17.918-17.918-17.918 c-9.881,0-17.919,8.038-17.919,17.918c0,4.453,1.643,8.522,4.34,11.66c-10.464,18.023-25.075,35.371-34.143,45.427 c-7.97-16.629-16.955-49.719-22.527-75.554c4.067-3.287,6.676-8.309,6.676-13.933c0-9.881-8.037-17.918-17.918-17.918 c-9.881,0-17.918,8.037-17.918,17.918c0,5.624,2.609,10.646,6.676,13.933c-5.569,25.837-14.554,58.932-22.528,75.56 c-9.063-10.047-23.667-27.381-34.143-45.433c2.698-3.138,4.34-7.207,4.34-11.66c0-9.88-8.037-17.918-17.918-17.918 c-9.88,0-17.918,8.038-17.918,17.918c0,6.387,3.367,11.985,8.409,15.159c-0.629,13.379-2.99,30.878-7.824,42.232L33.5,116.815 c1.48-2.606,2.336-5.61,2.336-8.815c0-9.881-8.037-17.918-17.918-17.918C8.038,90.081,0,98.119,0,107.999 c0,7.24,4.324,13.475,10.519,16.3c6.352,32.257,14.764,78.529,14.764,89V261.9c0,5.422,4.396,9.818,9.817,9.818h226.8 c5.423,0,9.818-4.396,9.818-9.818v-48.601c0-10.469,8.41-56.742,14.766-89C292.676,121.474,297,115.24,297,107.999z M44.357,155.443c3.835,3.833,10.05,3.833,13.886,0c9.319-9.321,14.089-26.038,16.529-41.044 c15.884,22.439,33.386,40.044,34.385,41.044c3.835,3.833,10.05,3.833,13.886,0c9.89-9.891,18.954-36.98,25.457-61.117 c6.503,24.137,15.567,51.227,25.457,61.117c1.842,1.84,4.339,2.875,6.943,2.875c2.604,0,5.101-1.035,6.942-2.875 c0.999-1,18.501-18.605,34.385-41.044c2.441,15.006,7.21,31.723,16.529,41.044c3.836,3.833,10.051,3.833,13.886,0l9.743-9.744 c-3.645,19.507-7.85,43.309-9.541,57.783H44.155c-1.691-14.475-5.896-38.276-9.54-57.783L44.357,155.443z M252.082,252.081H44.918 v-28.964h207.164V252.081z"></path>
                        </g>
                      </svg>
                    </button>
                  )}
                </div>
              ))}
            </div>

            {/* Chart Section */}
            <div className="min-w-md mx-auto bg-white rounded-lg overflow-hidden !rounded-xl mb-5">
              {/* Card Header */}
              <div
                style={{
                  borderBottom: "1px solid #f0f0f0",
                  padding: "10px",
                  marginBottom: "20px",
                }}
              >
                <h3 style={{ margin: 0, fontSize: "18px" }}>
                  {activeFilter} - For Alchemy
                </h3>
              </div>

              {/* Chart Content */}
              <div
                style={{
                  width: "100%",
                  borderRadius: "14.46px",
                  opacity: "1", // Change opacity to 1 if you want it fully visible
                  display: "flex",
                  alignItems: "center",
                  gap: "1px", // Reduced the gap to 10px
                  padding: "10px", // Reduced padding
                }}
              >
                {/* Chart */}
                <div style={{ flex: 0.6, height: "400px" }}>
                  <Doughnut data={chartData} options={chartOptions} />
                </div>

                {/* Custom Legend */}
                <div style={{ marginLeft: "30px" }}>
                  <ul
                    style={{
                      listStyleType: "none",
                      padding: 0,
                      fontSize: "14px",
                    }}
                  >
                    {chartData.labels.map((label, index) => (
                      <li
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "12px",
                            height: "12px",
                            backgroundColor:
                              chartData.datasets[0].backgroundColor[index],
                            borderRadius: "50%",
                            marginRight: "8px",
                          }}
                        ></div>
                        {label}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Candidates by Jobs Section */}
            <div
              style={{
                padding: "10px",
                marginBottom: "20px",
              }}
            >
              <h3 style={{ margin: 0, fontSize: "18px" }}>
                Candidates by Jobs
              </h3>
            </div>
            <div
              className="mb-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
              style={{
                gap: "16px",
              }}
            >
              {candidatesByJobs.map((job, index) => (
                <div
                  key={index}
                  onClick={() => handleCardClick(job.jobTitle)} // Handle click event
                  style={{
                    padding: "16px",
                    borderRadius: "12px",
                    textAlign: "center",
                    border:
                      activeJobCard === job.jobTitle
                        ? "2px solid #0596694D"
                        : "none",
                    background:
                      activeJobCard === job.jobTitle
                        ? "linear-gradient(60.98deg, #EBFFF5 -195.51%, #FFFFFF -56.62%, #94E7F9 14.08%, #34D399 116.74%)"
                        : "#F2F9F9",
                    color:
                      activeJobCard === job.jobTitle ? "#059669" : "#6B7280",
                    boxShadow:
                      activeJobCard === job.jobTitle
                        ? "0 4px 12px rgba(52, 211, 153, 0.3)"
                        : "0 2px 4px rgba(0, 0, 0, 0.05)",
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                  }}
                >
                  {/* Card Icon */}
                  <div
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      background: "#FCFFFF",
                      width: "30px",
                      height: "30px",
                      padding: "4px 4px 4px 5px",
                      gap: "0px",
                      borderRadius: "10px",
                      opacity: "0px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      className="mt-[1px] mr-4"
                      fill="none"
                    >
                      <path
                        d="M10.2453 16.2871H9.75358C9.17055 16.2863 8.69727 15.8138 8.69727 15.2308V12.5446C8.69727 11.9616 9.17057 11.4891 9.75358 11.4883H10.2453C10.8283 11.4891 11.3016 11.9616 11.3016 12.5446V15.2308C11.3016 15.8138 10.8283 16.2863 10.2453 16.2871ZM9.75358 12.0627C9.4869 12.0627 9.27146 12.2789 9.27066 12.5448V15.2309C9.27146 15.4968 9.4869 15.7131 9.75358 15.7131H10.2453C10.512 15.7131 10.7274 15.4968 10.7282 15.2309V12.5448C10.7274 12.2789 10.512 12.0627 10.2453 12.0627H9.75358Z"
                        fill="#059669"
                        stroke="#059669"
                        strokeWidth="0.25"
                      />
                      <path
                        d="M15.4866 7.9521C15.328 7.9521 15.1991 7.82397 15.1991 7.6654V5.76016C15.1991 4.22974 13.9538 2.98441 12.4234 2.98441H7.57651C6.04608 2.98441 4.80076 4.22974 4.80076 5.76016V7.6654C4.80076 7.82397 4.67182 7.9521 4.51326 7.9521C4.35469 7.9521 4.22656 7.82397 4.22656 7.6654V5.76016C4.22656 3.9126 5.72976 2.41016 7.57657 2.41016H12.4234C14.271 2.41016 15.7734 3.91335 15.7734 5.76016V7.6654C15.7734 7.74148 15.743 7.81436 15.6893 7.86802C15.6357 7.92168 15.5627 7.9521 15.4866 7.9521Z"
                        fill="#059669"
                        stroke="#059669"
                        strokeWidth="0.25"
                      />
                      <path
                        d="M13.7685 7.95049C13.61 7.95049 13.4818 7.82236 13.4818 7.66379V5.86988C13.481 5.1395 12.8884 4.54687 12.158 4.54607H7.84136C7.11098 4.54687 6.51836 5.1395 6.51756 5.86988V7.66379C6.51756 7.82236 6.38942 7.95049 6.23086 7.95049C6.07229 7.95049 5.94336 7.82236 5.94336 7.66379V5.86988C5.94496 4.82236 6.79386 3.97346 7.84138 3.97266H12.1581C13.2056 3.97346 14.0545 4.82236 14.0561 5.86988V7.66379C14.0561 7.82236 13.9271 7.95049 13.7685 7.95049Z"
                        fill="#059669"
                        stroke="#059669"
                        strokeWidth="0.25"
                      />
                      <path
                        d="M8.98443 13.9881C8.95961 13.9881 8.93478 13.9849 8.91075 13.9785L2.48239 12.2815C1.60866 12.0508 1 11.2604 1 10.357C1 10.1984 1.12814 10.0703 1.2867 10.0703C1.44526 10.0703 1.5734 10.1984 1.5734 10.357C1.5742 11.0001 2.00746 11.5623 2.62892 11.7265L9.05667 13.4235C9.19682 13.4603 9.28731 13.5949 9.2689 13.7382C9.25048 13.8808 9.12795 13.9881 8.9838 13.9881L8.98443 13.9881Z"
                        fill="#059669"
                        stroke="#059669"
                        strokeWidth="0.25"
                      />
                      <path
                        d="M11.0154 13.9881C10.8712 13.9881 10.7495 13.8808 10.7311 13.7382C10.7127 13.5949 10.8031 13.4603 10.9433 13.4235L17.3711 11.7265C17.9925 11.5623 18.4258 11.0001 18.4266 10.357C18.4266 10.1984 18.5547 10.0703 18.7133 10.0703C18.8718 10.0703 19 10.1984 19 10.357C19 11.2604 18.3913 12.0508 17.5176 12.2815L11.0892 13.9785C11.0652 13.9849 11.0402 13.9881 11.0154 13.9881Z"
                        fill="#059669"
                        stroke="#059669"
                        strokeWidth="0.25"
                      />
                      <path
                        d="M17.5128 19.149H2.48719C1.66631 19.1482 1.0008 18.4827 1 17.6618V8.86609C1.0008 8.04522 1.66631 7.37971 2.48719 7.37891H17.5128C18.3337 7.37971 18.9992 8.04522 19 8.86609V17.6618C18.9992 18.4827 18.3337 19.1482 17.5128 19.149ZM2.48719 7.95312C1.98266 7.95392 1.57422 8.36236 1.57342 8.86609V17.6618C1.57422 18.1655 1.98266 18.5748 2.48719 18.5748H17.5128C18.0173 18.5748 18.4258 18.1655 18.4266 17.6618V8.86609C18.4258 8.36236 18.0173 7.95392 17.5128 7.95312H2.48719Z"
                        fill="#059669"
                        stroke="#059669"
                        strokeWidth="0.25"
                      />
                    </svg>
                  </div>

                  <div className="mt-4">
                    {/* Job Title */}
                    <div
                      style={{
                        fontFamily: "Plus Jakarta Sans",
                        fontSize: "15px",
                        fontWeight: "500",
                        lineWeight: "22.68px",
                        textAlign: "left",
                        textUnderlinePosition: "from-font",
                        textDecorationSkipInk: "none",
                        color: "#1D1D1D",
                        gap: "0px",
                        opacity: "0px",
                      }}
                    >
                      {job.jobTitle}
                    </div>

                    {/* Candidate Count */}
                    <div
                      style={{
                        fontFamily: "Space Grotesk",
                        fontSize: "20px",
                        fontWeight: "700",
                        lineWeight: "38.28px",
                        textAlign: "left",
                        textUnderlinePosition: "from-font",
                        textDecorationSkipInk: "none",
                        color: "#059669",
                      }}
                    >
                      {job.count}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      ) : (
        <>
          <div className="flex items-center flex-col justify-center gap-2 w-2/4">
            <h1 className="plusJakartaSansFont text-3xl text-center font-bold">
              Ready to Discover Talent?
            </h1>
            <p className="plusJakartaSansFont m-0 text-base font-normal text-center text-[#3D3D3D]">
              Upload resumes to dive into a pool of qualified candidates.
            </p>
          </div>
          <div
            onClick={handleDivClick}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            className="cursor-pointer h-96 border-2 border-dashed border-[#059669] w-3/4 rounded-lg flex items-center justify-center flex-col"
          >
            <svg
              width="79"
              height="79"
              viewBox="0 0 79 79"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1599_7728)">
                <path
                  d="M46.0039 19.5781H52.5039V26.0781H68.7539C69.6159 26.0781 70.4425 26.4205 71.052 27.03C71.6615 27.6395 72.0039 28.4662 72.0039 29.3281V53.7031L52.5039 42.3281L52.6209 68.5296L59.8456 61.5421L65.6371 71.5781H29.7539C28.8919 71.5781 28.0653 71.2357 27.4558 70.6262C26.8463 70.0167 26.5039 69.1901 26.5039 68.3281V52.0781H20.0039V45.5781H26.5039V29.3281C26.5039 28.4662 26.8463 27.6395 27.4558 27.03C28.0653 26.4205 28.8919 26.0781 29.7539 26.0781H46.0039V19.5781ZM72.0039 56.4266V68.3281C72.0042 68.6668 71.9516 69.0034 71.8479 69.3259L65.4779 58.2954L72.0039 56.4266ZM13.5039 45.5781V52.0781H7.00391V45.5781H13.5039ZM13.5039 32.5781V39.0781H7.00391V32.5781H13.5039ZM13.5039 19.5781V26.0781H7.00391V19.5781H13.5039ZM13.5039 6.57812V13.0781H7.00391V6.57812H13.5039ZM26.5039 6.57812V13.0781H20.0039V6.57812H26.5039ZM39.5039 6.57812V13.0781H33.0039V6.57812H39.5039ZM52.5039 6.57812V13.0781H46.0039V6.57812H52.5039Z"
                  fill="#059669"
                />
              </g>
              <defs>
                <clipPath id="clip0_1599_7728">
                  <rect
                    width="78"
                    height="78"
                    fill="white"
                    transform="translate(0.503906 0.078125)"
                  />
                </clipPath>
              </defs>
            </svg>
            <p className="plusJakartaSansFont m-0 text-xl font-bold text-[#3D3D3D]">
              {resumeFiles && resumeFiles.length > 0
                ? resumeFiles.map((file) => file.name).join(", ")
                : "Drag and Drop or choose files"}
            </p>
            <input
              type="file"
              id="fileInput"
              onChange={handleFileInputChange}
              className="hidden"
              multiple
            />
          </div>
          <div className="flex items-center w-3/4">
            <Button
              onClick={backTODash}
              children={"Back"}
              className={
                "text-white font-medium text-sm px-4 py-2 rounded !bg-[#059669] plusJakartaSansFont"
              }
            />
            <Button
              onClick={saveResume}
              children={"Upload"}
              className={
                "text-white font-medium text-sm px-4 py-2 rounded ml-auto !bg-[#059669] plusJakartaSansFont"
              }
            />
          </div>
        </>
      )}
    </div>
  );
}
