import React, { useEffect, useState } from "react";
import { ReactComponent as SadFace } from "../../assets/sad_face.svg";
import { ReactComponent as NeutralFace } from "../../assets/neutral_face.svg";
import { ReactComponent as HappyFace } from "../../assets/happy_face.svg";
import { ReactComponent as Message } from "../../assets/message_icon.svg";
import { ReactComponent as Forward } from "../../assets/forward.svg";
import { ReactComponent as Reply } from "../../assets/reply.svg";
import { ReactComponent as ReplyAll } from "../../assets/reply_all.svg";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { ReactComponent as Pencil } from "../../assets/pencil.svg";
import { ReactComponent as Event } from "../../assets/event.svg";
import { ReactComponent as World } from "../../assets/world.svg";
import { ReactComponent as File } from "../../assets/file.svg";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const EmailView = () => {
    const location=useLocation();
    const navigate=useNavigate();
    const sender=location.state.sender;
    const sentiment=location.state.sentiment;
    const content=location.state.content;
    const subject=location.state.subjectView;
    const viewsynchedOne=location.state.viewsynchedOne;
    const viewjid=location.state.view_jid;
    const viewcid=location.state.view_cid;
    const candidate_email=location.state.view_cmail;
    const view_mid=location.state.viewMID;
    let parent_email=location.state.view_pmail;
    console.log("parrrrrr:",parent_email)
    if(parent_email){
    let parentMail=parent_email.split("wrote:")
    console.log("-------:",parentMail)

    if (parentMail.length > 1) {
        let extracted_content = parentMail[1].trim();
        parent_email=extracted_content
        console.log("Extracted Content:\n", extracted_content);
    } else {
        console.log("No 'wrote:' found in the email.");
    }
}
    // const [sentimentState,setSentimentState]=useState('');
    // console.log("sender: ",sender);
    // useEffect(()=>{
    //     if(sentiment==="Unhappy"){
    //         setSentimentState("unhappy");
    //     }
    //     else if(sentiment==="happy"){
    //         setSentimentState("happy");
    //     }
    //     else if(sentiment==="neutral"){
    //         setSentimentState("neutral");
    //     }
    // },[])
    console.log("sentiment: ",sentiment);
    const handleSmartReply= (view,sender,subject,jid,cid,view_mid) =>{
        navigate("/ViewMessage", {state: {view:view,viewSender:sender,viewSubject:subject,viewJID:jid,viewCID:cid,mid_view:view_mid}})
    }
    const handleViewMail = () =>{
        navigate("/triage")
    }

    return (
        <div className="px-3 flex">
            <div className="w-4/12 p-4">
                <div className="border-b-2 pb-8 ml-4">
                    <p className="text-black font-semibold">Initial Message &gt; Response</p>
                    <div className="flex space-x-8 mt-4">
                        <div className="flex flex-col flex-grow">
                            <label className="mb-1">Language</label>
                            <select className="border border-gray-300 rounded px-2 py-1 bg-white w-full">
                                <option value="">English</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                            </select>
                        </div>
                        <div className="flex flex-col flex-grow">
                            <label className="mb-1">Section</label>
                            <select className="border border-gray-300 rounded px-2 py-1 bg-white w-full">
                                <option value=""><File width="10" height="10" className="mr-2" />Lorem Ipsum</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex space-x-8 mt-4">
                        <div className="flex flex-col flex-grow">
                            <label className="flex items-center justify-between mb-1">
                                Assign To
                                <span className="text-[#059669] text-[10px] px-2 py-1 rounded ml-auto">
                                    Assign To Me
                                </span>
                            </label>

                            <select className="border border-gray-300 rounded px-2 py-1 bg-white w-full">
                                <option value="">Anton Bragas</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                            </select>
                        </div>
                        <div className="flex flex-col flex-grow">
                            <label className="mb-1">Status</label>
                            <select className="border border-gray-300 rounded px-2 py-1 bg-white w-full">
                                <option value=""> --- </option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex space-x-8 mt-4">
                        <div className="flex flex-col">
                            <p className="m-0">Sentiment</p>
                            <p className="m-0">Identified sentiment for this message</p>
                        </div>
                    </div>
                    <div className="flex justify-center space-x-8 mt-4">
                        <p className={`flex items-center text-[#FF3D00] ${sentiment==="Unhappy."?"bg-gradient-to-r from-[#EBFFF5] via-[#94E7F9] to-[#34D399] text-white p-2 rounded-xl":""}`}>
                            <SadFace width="20" height="20" className="mr-2" />
                            Unhappy
                        </p>
                        <p className={`flex items-center text-[#34D399] ${sentiment==="Neutral"?"bg-gradient-to-r from-[#EBFFF5] via-[#94E7F9] to-[#34D399] text-white p-2 rounded-xl":""}`}>
                            <NeutralFace width="20" height="20" className="mr-2" />
                            Neutral
                        </p>
                        <p className={`flex items-center text-[#059669] ${sentiment==="happy"?"bg-gradient-to-r from-[#EBFFF5] via-[#94E7F9] to-[#34D399] text-white p-2 rounded-xl":""}`}>
                            <HappyFace width="20" height="20" className="mr-2" />
                            Happy</p>
                    </div>
                    <div className="flex space-x-8 mt-4 border border-gray-400 rounded-xl">
                        <div className="flex flex-col p-1 ml-2 w-full">
                            <p className="flex items-center justify-between font-semibold mb-0">
                                <span className="flex items-center">
                                    <Message width="20" height="20" className="mr-2" />
                                    Comments CRM
                                </span>
                                <span className="text-[#059669] px-2 py-1 rounded">Manage:</span>
                            </p>
                            <p className="flex justify-center items-center text-[#747474] rounded-md mt-0">
                                No Comments
                            </p>
                        </div>
                    </div>
                    <div className="flex space-x-8 mt-4 border border-gray-400 rounded-xl">
                        <div className="flex flex-col p-1 ml-2 w-full">
                            <p className="flex items-center justify-between font-semibold mb-0">
                                <span className="flex items-center">
                                    <Message width="20" height="20" className="mr-2" />
                                    NER
                                </span>
                            </p>
                            <p className="flex justify-start items-start text-[#747474] rounded-md mt-1">
                                NER Detected In Email Body
                            </p>
                        </div>
                    </div>
                    <div className="flex space-x-8 mt-4 border border-gray-400 rounded-xl">
                        <div className="flex flex-col p-1 ml-2 w-full">
                            <p className="flex items-center justify-between mb-0">Intent</p>
                            <p className="flex items-center justify-between mb-0">Select Or Create A New Intent<span className="bg-yellow-500 text-black w-6 h-6 flex items-center justify-center mr-4 rounded-full text-sm">1</span>
                            </p>
                            <select className="border border-gray-300 rounded px-2 py-1 mt-1 bg-[#BFE0E2]">
                                <option value="security-reason">Security Reason</option>
                                <option value="privacy">Privacy</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-1 p-6 shadow-md">
                <div className="flex items-center justify-between space-x-4">
                    <div className="flex items-center space-x-4">
                        
                        <p className="flex items-center border-2 border-[#059669] text-[#059669] px-2 py-1 rounded hover:cursor-pointer" onClick={()=>handleSmartReply(viewsynchedOne,sender,subject,viewjid,viewcid,view_mid)}>
                            <svg width="16" height="16" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="none">
                                <path stroke="#059669" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.608 12.526l7.04-6.454C13.931 4.896 16 5.806 16 7.546V11c13 0 11 16 11 16s-4-10-11-10v3.453c0 1.74-2.069 2.65-3.351 1.475l-7.04-6.454a2 2 0 010-2.948z" />
                            </svg>
                            Smart Reply
                        </p>
                        <p className="text-[#059669] font-[500] px-2 py-1  border-2 border-[#059669] rounded-md text-md hover:cursor-pointer" onClick={()=>handleViewMail()}>Back</p>
                        <p className="flex items-center space-x-2 hidden">
                            <Reply width="16" height="16" />
                            <ReplyAll width="16" height="16" />
                            <Forward width="16" height="16" />
                            <Delete width="16" height="16" />
                        </p>
                    </div>
                    <div className="flex items-center space-x-4">
                        <p className="flex items-center text-[#059669]">
                            <Pencil width="16" height="16" className="mr-2" />
                            Annotate
                        </p>
                        <p className="flex items-center text-[#059669]">
                            <Event width="16" height="16" className="mr-2" />
                            Events
                        </p>
                        <p className="flex items-center text-[#059669]">
                            <World width="16" height="16" className="mr-2" />
                            Translate Message
                        </p>
                    </div>
                </div>
                <div className="border-t border-gray-300 pt-4">
                    <h2 className="text-xl font-semibold mb-4">{subject?subject:""}</h2>
                </div>
                <div className="mb-6">
                    <div className="flex justify-between items-center">
                        <p className="text-sm text-gray-700">
                            From<span className="ml-3">&lt;{sender}&gt;</span>
                        </p>
                        <span className="text-sm text-gray-700 hidden">10:33 am</span>
                    </div>
                    <div className="border-b border-gray-300 mt-2"></div>
                </div>
                <div className="text-sm text-gray-700">
                    <p className="text-sm text-gray-700">
                        To <span className="ml-6">&lt;Me&gt;</span>
                    </p>
                    <p className="mb-0 mt-4"><span className="text-md font-bold mr-2">Candidate's Reply:  </span>{candidate_email?candidate_email:"No Reply"}</p>
                    <p className="mb-0 mt-4"><span className="text-md font-bold mr-2">Parent Mail:  </span>{parent_email?parent_email:"No Data"}</p>

                </div>
            </div>
        </div>
    );
}
export default EmailView;