import React, { useState, useEffect, useContext } from "react";
import Swal from 'sweetalert2';
import Textinput from "../elements/TextInput/TextInput";
import Button from "../elements/Button/Button";
import Jobscreen from "./JobScreen";
import Jobscreening from "./JobScreening";
import JobTeam from "./JobTeam";
import { useNavigate } from "react-router-dom";
import { useCommonContext } from "../../context/CommonContext";
import { AuthContext } from "../../context/AuthContext";
import CryptoJS from "crypto-js";
import JobSchedule from "./JobSchedule";
import './JobScreen.css'
import ButtonHallow from "../elements/Button/ButtonHallow";
const JobPost = () =>{
  const [Heading, setHeading] = useState("Post a New Job");
  const { token } = useContext(AuthContext);
  const { jobCreationData, setValidationErrors, setJobCreationData } = useCommonContext();
    const encryptedMetadata = localStorage.getItem('metadata');

    const [nextScreen, setNextScreen] = useState(1);
    const navigate = useNavigate();
    // useEffect(() => {
        
    // }, [jobCreationData]);
    
    // Retrieve jobCreationData from localStorage during initialization
    useEffect(() => {
        const savedFormData = localStorage.getItem("jobCreationData");
        if (savedFormData) {
            setJobCreationData(JSON.parse(savedFormData));
        }
    }, []);
    const [isLoading, setIsLoading] = useState(false);
    const sendData = async (data) => {
      if (!data.jobid || data.jobid === "") {
        console.log("jobid is empty");
        data.jobid = Math.floor(1000 + Math.random() * 9000).toString();
    } else {
        data.jobid = data.jobid.toString();
    }
      console.log(data)
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRETKEY).toString();
      const encryptjson = { data: encryptedData };
    
      try {
        setIsLoading(true);
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/job/createJob`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            token: token,
          },
          body: JSON.stringify(encryptjson),
        });
    
        if (response.ok) {
          localStorage.removeItem("jobCreationData");
          Swal.fire({
            icon: 'success',
            title: 'Job successfully added to verification!',
            text: 'Once verified, it will go live.',
            // showConfirmButton: false,
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.href = '/Dashboard';
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There was an issue adding the job. Please try again.',
          });
        }
      } catch (error) {
        console.log('Error adding JD to db:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred. Please try again later.',
        });
      } finally {
        setIsLoading(false);
      }
    };

  const handleSave = async (force=false) => {
    const errors = {};
    const firstStageJC = ['jobTitle', 'company', 'workplaceType', 'jobLocation', 'jobType', 'description','selectedSkills']
    const secondStageJC = ['questions']
    if (force && nextScreen === 2) {
    console.log("error");
      await setJobCreationData((prevData) => ({
        ...prevData,
        questions: [],
    }));
    }
  
    Object.keys(jobCreationData).forEach((key) => {
      if(nextScreen === 1){
        if (firstStageJC.includes(key)) {
          if (!jobCreationData[key] || (Array.isArray(jobCreationData[key]) && jobCreationData[key].length === 0)) {
              errors[key] = true; 
          }
        }
      }else if(nextScreen === 2 && !force){
          if (!jobCreationData['questions'] || Array.isArray(jobCreationData['questions']) && jobCreationData['questions'].length < 3) {
            errors['questions'] = true;
          }else {
            jobCreationData.questions.forEach((question, index) => {
              if (!question.question_desc || question.question_desc.trim() === "") {
                errors['desc-'+index] = true;
              }
            });
            jobCreationData.questions.forEach((question, index) => {
              if (!question.minimum || question.minimum.trim() === "") {
                errors['minimum-'+index] = true;
              }
            });

            jobCreationData.questions.forEach((question, index) => {
              if (question.question_desc && question.question_desc?.includes("[value]")) {
                errors['value-'+index] = true;
              }
            });
          }
      }else if(nextScreen === 3){
        if (!jobCreationData['collaborators'] || Array.isArray(jobCreationData['collaborators']) && jobCreationData['collaborators'].length === 0) {
          errors['collaborators'] = true;
        }
      }
      else if (nextScreen === 4 && !force) {
        if (
          !jobCreationData['interviewers'] ||
          (Array.isArray(jobCreationData['interviewers']) && jobCreationData['interviewers'].length === 0)
        ) {
          errors['interviewers'] = true;
        }
        if (
          !jobCreationData['timezone'] ||
          (Array.isArray(jobCreationData['timezone']) && jobCreationData['timezone'].length === 0)
        ) {
          errors['timezone'] = true;
        }
        if (
          !jobCreationData['interview_duration'] ||
          (Array.isArray(jobCreationData['interview_duration']) && jobCreationData['interview_duration'].length === 0)
        ) {
          errors['interview_duration'] = true;
        }
        if (
          !jobCreationData['schedule_config_days'] ||
          (Array.isArray(jobCreationData['schedule_config_days']) && jobCreationData['schedule_config_days'].length === 0)
        ) {
          errors['schedule_config_days'] = true;
        }
        if (
          !jobCreationData['interview_location'] ||
          (Array.isArray(jobCreationData['interview_location']) && jobCreationData['interview_location'].length === 0)
        ) {
          errors['interview_location'] = true;
        }
      
        if (
          !jobCreationData['slots'] || 
          (typeof jobCreationData['slots'] === 'object' && Object.keys(jobCreationData['slots']).length === 0)
        ) {
          errors['slots'] = true;
        } else {
          // Validate that each slot has both 'from' and 'to' times
          Object.entries(jobCreationData['slots']).forEach(([day, timeRange]) => {
            const [fromTime, toTime] = timeRange.split(" - ");
            if (!fromTime || !toTime) {
              errors['slots'] = true;
            }
          });
        }
        
      }
      
    });
    localStorage.setItem("jobCreationData", JSON.stringify(jobCreationData));
    if (encryptedMetadata){
      const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
      setJobCreationData((prev) => ({
      ...prev,
      company_id: decryptedMetadata.cid
    }))
  }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      console.log("Validation failed:", errors);
      return;
    }
  
    setValidationErrors({});
    
    if(nextScreen === 4){
      console.log(jobCreationData);
      if(force){
        const updatedJobCreationData = {
          ...jobCreationData,
          interview_duration: "_blank",
          interview_location: "_blank",
          timezone: "_blank",
          schedule_config_days: "5 Days",
        };
        sendData(updatedJobCreationData);
      }else{
        sendData(jobCreationData);
      }
    }
    setNextScreen((prevScreen) => (prevScreen < 4 ? prevScreen + 1 : prevScreen));
    if(nextScreen === 3){
      setHeading("Set Schedule")
    }
    console.log("next: ", nextScreen);
  };
  const handleBack = () => {
    if(nextScreen === 1){
      navigate('/JobDashboard')
    }else{
      setNextScreen((prevScreen) => prevScreen>1?prevScreen-1:prevScreen);
      console.log("next: ", nextScreen);
    }
  };
  const handleJobPrevie = () => {
    localStorage.removeItem("jobCreationData");
  }

    return(
        <div>
            <div className="ml-12 mt-2">
                <p className="font-[700] text-[32px] mb-0">{Heading}</p>
            
            <div className="flex mt-3">
                <p className="pl-5 pr-5 pt-2.5 pb-2 rounded-full text-white bg-[#059669]">1</p>
                <div className="w-[4.5%] border-b-2 border-gray-400 h-6"></div>
                <p className={`pl-4 pr-4 pt-2 pb-2 rounded-full  border-2 border-[#059669] ${nextScreen===3 || nextScreen === 4?"bg-[#059669] text-white":"bg-teal-50 text-green-900"}`}>2</p>
                <div className="w-[4.5%] border-b-2 border-gray-400 h-6"></div>
                <p className={`pl-4 pr-4 pt-2 pb-2 rounded-full  border-2 border-[#059669] ${nextScreen===4?"bg-[#059669] text-white":"bg-teal-50 text-green-900"}`}>3</p>
                <div className="w-[4.5%] border-b-2 border-gray-400 h-6"></div>
                <p className="pl-4 pr-4 pt-2.5 pb-2 rounded-full text-green-900 border-2 border-[#059669] bg-teal-50">4</p>
            </div>
            </div>
            {isLoading && (
              <div className="flex items-center justify-center h-full w-full bg-opacity-70 bg-gray-50 fixed top-0 left-0">
                <div className="text-center" style={{justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                  <div className="loader"></div>
                  <p className="text-lg font-medium mt-4 animate-pulse" style={{color: '#ffffff'}}>
                    Submitting your job request... Please wait!
                  </p>
                </div>
              </div>
            )}
            <div className="w-[94.5%] bg-white bg-opacity-50 mobile-no-margin mobile-full-width ml-12 mt-4 rounded-xl pr-[5%]">
            {nextScreen === 1 ? <Jobscreen /> : null}
            {nextScreen === 2 ? <Jobscreening /> : null}
            {nextScreen === 3 ? <JobTeam /> : null}
            {nextScreen === 4 ? <JobSchedule /> : null}
            <div className="flex mt-[32px] pb-10 ml-7">
                <div>
                <Button className="bg-white border-[1px] border-green-700 font-[500] text-green-700 w-[71px] h-[40px] p-[5px] rounded-[8px]" onClick={ ()=>handleBack()}>Back</Button>
                </div>
                <div className="ml-auto w-auto flex text-nowrap" >
                {nextScreen === 3 && <Button onClick={() => {handleJobPrevie()}} className="bg-white border-[1px]  border-green-700 font-[500] w-[90px] text-green-800 h-[40px] p-[5px] rounded-[8px]">Preview</Button>}
                <Button className="text-white ml-[14px] h-[40px] font-[500] p-[8px] rounded-[8px]" onClick={ ()=>handleSave()}>{nextScreen === 4 ? "Submit For Approval": "Save & Proceed"}</Button>
                {(nextScreen === 2) && <ButtonHallow className="w-[auto] mt-0 ml-4 !p-[8px] font-[500] h-[40px]" onClick={ ()=>handleSave(true)} content={nextScreen === 4 ? "Skip & Submit" : "Skip"}/>}
                </div>
            </div>
            </div>
        </div>
    );
}
export default JobPost
