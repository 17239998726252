import React from 'react';

function Card({ header, body, footer,CardClass, CardBodyClass, CardFooterClass, customdiv, onClick, CardHeaderClass }) {
  return (
    <div className={`card ${CardClass}`}>
      {header && (
        <div className={`card-header px-6 py-2 bg-blue-500 text-white text-lg font-semibold ${CardHeaderClass}`}>
          {header}
        </div>
      )}
      {body && (
        <div className={`card-body ${CardBodyClass}`} onClick={typeof onClick === 'function' ? onClick : undefined}>
          {body}
        </div>
      )}
      {footer && (
        <div className={`card-footer ${CardFooterClass}`}>
          {footer}
        </div>
      )}
      {customdiv && (
        <>{customdiv}</>
      )}
    </div>
  );
}

export default Card;
