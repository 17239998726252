// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
  position: absolute; /* Change from fixed to absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(109, 105, 105, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it sits on top */
  pointer-events: none; /* Disables clicks or any other interactions with the overlay */
}

.overlay-text {
  color: black;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Team/Overlayer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAE,kCAAkC;EACtD,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,0CAA0C,EAAE,gCAAgC;EAC5E,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,6BAA6B;EAC5C,oBAAoB,EAAE,+DAA+D;AACvF;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".overlay {\n  position: absolute; /* Change from fixed to absolute */\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(109, 105, 105, 0.5); /* Semi-transparent background */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000; /* Make sure it sits on top */\n  pointer-events: none; /* Disables clicks or any other interactions with the overlay */\n}\n\n.overlay-text {\n  color: black;\n  font-size: 2rem;\n  font-weight: bold;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
