import React, { useState } from "react";
import './ViewBooking.css';
import Topbar from "../../components/Recruiter/Topbar/Topbar";
import ButtonSolid from "../../components/elements/Button/ButtonSolid";

const ViewBookingPage = () => {

    return (
        <div className="radial-gradient-bg">
            <Topbar />
            <div className="main-bg-view-booking">
                <div className="view-booking-container !m-auto">
                    <div className="left-section">
                        <p className={`mb-2`}>Selvam</p>
                        <h4 className="title">UX Designer</h4>
                        <p className="description">
                            30 Mins
                        </p>
                    </div>

                    {/* Center Section */}
                    <div className="center-section">
                        <h4 className={`mb-4`}>Enter Details</h4>
                        <div className='w-[830px] mx-auto'>
                            <p className="mb-1 mt-1">Full Name*</p>
                            <input
                                labelclass="font-normal"
                                name="Email"
                                divclass="relative"
                                placeholder="What is your name?"
                                style={{
                                    padding: '10px',
                                }}
                                className="profile_sidebar_progress_text border-[2px] h-12 border-sky-100 w-[550px] rounded-lg focus:ring-0  pr-10"
                                required={true}
                                type="text"
                            />
                            <p className="mb-1 mt-3">Email ID*</p>
                            <input
                                labelclass="font-normal"
                                name="Email"
                                divclass="relative"
                                placeholder="Tell us your Email ID"
                                style={{
                                    padding: '10px',
                                }}
                                className="profile_sidebar_progress_text border-[2px] h-12 border-sky-100 w-[550px] rounded-lg focus:ring-0  pr-10"
                                required={true}
                                type="text"
                            />
                            <p className="mb-1 mt-3">Please Share Anything That Will Help Prepare For Our Meeting.</p>
                            <textarea
                                labelclass="font-normal"
                                name="Notes"
                                divclass="relative"
                                placeholder="Write your notes here"
                                style={{
                                    padding: '10px',
                                }}
                                className="profile_sidebar_progress_text border-[2px] h-32 border-sky-100 w-[550px] rounded-lg focus:ring-0  pr-10"
                                required={true}
                            />

                            <p className="mt-4 text-xs" style={{ color: '#949494' }}>
                                By proceeding, you confirm that you have read and agree to <span style={{ textDecoration: 'underline', textDecorationSkipInk: false }}>Calendly's Terms of use</span> and <span style={{ textDecoration: 'underline' }}>Privacy Notice.</span>
                            </p>
                            <ButtonSolid
                                content="Schedule Event"
                                style={{ maxWidth: '140px' }}
                                type="button"
                            />
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default ViewBookingPage;
