import React from "react";
import "./Overlayer.css";

const Overlay = ({ message }) => {
  return (
    <div className="overlay">
      <div className="overlay-text">{message}</div>
    </div>
  );
};

export default Overlay;
