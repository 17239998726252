// Basic imports
import React, { useState, useRef, useContext,useEffect } from 'react';
import Topbar from '../components/Recruiter/Topbar/Topbar'
import Projects from '../components/Dashboard/Projects';
import star from '../assets/star-search.png';
import { useCommonContext } from '../context/CommonContext';
import SearchInput from '../components/elements/SearchInput/SearchInput';
import ButtonSolid from '../components/elements/Button/ButtonSolid';
import Notification from "../components/Notification/Notification";
import ClipLoader from "react-spinners/ClipLoader";
import CryptoJS from 'crypto-js';
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import './Dashboard.css'


const Dashboard = () => {
	const { searchTerm, setSearchTerm, setIsAIsearch, isAIsearch, showUpload, setShowUpload,aiSearchResult,setAiSearchResult } = useCommonContext();
	const [suggestions, setSuggestions] = useState([]);
	const navigate = useNavigate();
	const [notification, setNotification] = useState({ message: "", type: "" });
    const [isLoading, setIsLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const [roleID, setRoleID] = useState(false);
    const [jobDetails, setjobDetails] = useState({});
	const [jobStats, setJobStats] = useState([]);
	const [userName, setUserName] = useState("");

	const showNotification = (message, type) => {   //For Notification
        setNotification({ message, type });
        setTimeout(() => {
          setNotification({ message: "", type: "" });
        }, 3000); // Auto-hide after 3 seconds
    };

	useEffect(()=>{
		const setUSerName = () => {
			const encryptedMetadata = localStorage.getItem('metadata');
			const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
		setUserName(decryptedMetadata.name);
		}
		if(userName === ""){
			setUSerName();
		}
	},[userName])
	const [dashboardData, setDashboardData] = useState({
		isEmptyData: false,
		isResumeCount: false,
		jobStats: [],
	});
	
    useEffect(() => {
	    dashboardLoading();
	}, []);

	const defaultSuggestions = [
		"AI Engineer",
		"Data Scientist",
		"Machine Learning",
		"Deep Learning",
		"Natural Language Processing",
	];

    const dashboardLoading = async () =>{
    	let role_id;
    	let email;
    	let cid
    	const encryptedMetadata = localStorage.getItem('metadata');
	    if (encryptedMetadata){
	        const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
	        console.log(decryptedMetadata)
	        if(decryptedMetadata && decryptedMetadata.role_id)
	            role_id = decryptedMetadata.role_id
	        if(decryptedMetadata && decryptedMetadata.cid)
	            cid = decryptedMetadata.cid
	    }
	    setRoleID(role_id)
	    if(role_id == 1 || 1){
			const cardContent = {
				company_id: cid,
			};
			  const encryptedDashData = CryptoJS.AES.encrypt(JSON.stringify(cardContent),process.env.REACT_APP_SECRETKEY).toString();
			  const encryptDashjson = { data: encryptedDashData };
			  setIsLoading(true);
		  
			  try {
				const serverURL = process.env.REACT_APP_SERVER_URL;
				const response = await fetch(`${serverURL}/job/getDashboardData`, {
				  method: "POST",
				  headers: {
					"Content-Type": "application/json",
					token: token,
				  },
				  body: JSON.stringify(encryptDashjson),
				});
		  
				const responsedata = await response.json();
		  
				if (!response.ok) {
				  throw new Error(responsedata.error || "Something went wrong");
				}
				const decryptedData = JSON.parse(CryptoJS.AES.decrypt(responsedata.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
	  
				console.log("Dashboard decryptedData",decryptedData)
				console.log("Checking conditions...");

				// Check if statusCounts is an empty object and resumeCount is exactly 0
				const isStatusCountsEmpty = decryptedData.statusCounts && Object.keys(decryptedData.statusCounts).length === 0;
				const isResumeCountEmpty = (Number(decryptedData.resumeCount)) === 0;
				
				const isBothEmpty = isStatusCountsEmpty && isResumeCountEmpty;
				if (isBothEmpty) {
					console.log("Both statusCounts and resumeCount are empty.");
				} else if (isStatusCountsEmpty) {
					console.log("Only statusCounts is empty.");
				} else if (isResumeCountEmpty) {
					console.log("Only resumeCount is empty.");
				} else {
					console.log("statusCounts and resumeCount have data.");
				}
				
				const isResumeCount = isResumeCountEmpty
				const isEmptyData = isStatusCountsEmpty && isResumeCountEmpty;
				setDashboardData({
					isEmptyData,isResumeCount,
					jobStats: decryptedData.statusCounts ? [
					    {
							number: decryptedData.totalJobCount || 0,
							label: "Total Jobs",
					    },
					    {
							number: decryptedData.statusCounts.openjobs || 0,
							label: "Open Jobs",
						},
						{
							number: decryptedData.statusCounts.upcoming_int || 0,
							label: "Upcoming Interviews",
						},
						{
							number: decryptedData.statusCounts.pendingjobs || 0,
							label: "Approval Pending",
						},
					] : [],
				});
			} catch (error) {
				  console.log('Error:', error);
				  let errorMessage = "Something went wrong.";
				  if (error.response && error.response.data && error.response.data.msg) {
					  errorMessage = error.response.data.msg;
				  } 
				  else if (error.message) {
					  errorMessage = error.message;
				  }
			  }

			finally {
		        setIsLoading(false); // Always hide loader after the request
		    } 

	    }
    }

	const fetchJobList = async (status, jobType) => {
		let role_id;
		let cid;
		const encryptedMetadata = localStorage.getItem("metadata");
		if (encryptedMetadata) {
		  const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
		  console.log(decryptedMetadata);
		  role_id = decryptedMetadata?.role_id;
		  cid = decryptedMetadata?.cid;
		}
	  
		setRoleID(role_id);
	  
		if (role_id === 1) {
		  const jobRequestData = {
			company_id: cid,
			job_status: [status],
		  };
		  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jobRequestData),process.env.REACT_APP_SECRETKEY).toString();
	  
		  const serverURL = process.env.REACT_APP_SERVER_URL;
	  
		  try {
			const response = await fetch(`${serverURL}/job/fetchJobListing`, {
			  method: "POST",
			  headers: {
				"Content-Type": "application/json",
				token,
			  },
			  body: JSON.stringify({ data: encryptedData }),
			});
	  
			const responseData = await response.json();
			const decryptedData = JSON.parse(CryptoJS.AES.decrypt(responseData.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
	  
			if (!response.ok) {
			  throw new Error(responseData.error || "Something went wrong");
			}
	  
			console.log(decryptedData, status);
	  
			setjobDetails((prevDetails) => ({
			  ...prevDetails,
			  [jobType]: decryptedData,
			}));
	  
			setJobStats((prevStats) =>
			  prevStats.map((item, index) =>
				index === 0 ? { ...item, onclick: "JobListing" } : item
			  )
			);
		  } catch (error) {
			console.error("Error:", error.message || "Something went wrong.");
		  } finally {
			setIsLoading(false);
		  }
		}
	  };
	  
	  const handleJobsClick = async (label) => {
		setIsLoading(true);
		try {
		  if (label === "Total Jobs") {
			await fetchJobList("*", "totaljob");
			navigate("/JobListing", { state: { totaljob: true } });
		  } else if (label === "Open Jobs") {
			await fetchJobList("active", "openjob");
			navigate("/JobListing", { state: { openJobs: true } });
		  } else if (label === "Approval Pending") {
			await fetchJobList("requested", "pendingjob");
			navigate("/JobListing", { state: { approval: true } });
		  }
		} catch (error) {
		  console.error("Error handling stat click:", error.message);
		} finally {
		  setIsLoading(false);
		}
	  };
	  
	  const searchProject = async () => {
		if(isAIsearch){
			if(!searchTerm){
				showNotification("Please Enter Search Data", "error");
				return
			}
			var userId = '';
			var cid = '';
			const encryptedMetadata = localStorage.getItem('metadata');
	        if (encryptedMetadata){
	            const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
	            if(decryptedMetadata.uid){
	            	userId = decryptedMetadata.uid;
	            }
	            if(decryptedMetadata.cid){
	            	cid = decryptedMetadata.cid;
	            }
	        }
			const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({ search:searchTerm,userId:userId,cid:cid }), process.env.REACT_APP_SECRETKEY).toString();
			var searchData = { data:encryptedData}
			setIsLoading(true);
	        try {
	            const serverURL = process.env.REACT_APP_SERVER_URL;
	            const response = await fetch(`${serverURL}/JD/searchResumeData`, {
	                method: 'POST',
	                headers: {
	                  'Content-Type': 'application/json',
	                  token: token
	                },
	                body: JSON.stringify(searchData), 
	            });
	            const result = await response.json();
	            console.log("Server Response:", result);
	            if (!response.ok) {
	                throw new Error(result.error);
	            }
	            const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
	            const encryptedMetadata = localStorage.getItem('metadata');
	            if (encryptedMetadata){
	            	const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
	            	decryptedMetadata['jd_id'] = decryptedData.jd_id;
	            	decryptedMetadata['search'] = searchTerm;
	            	const encryptedUpdatedMetadata = CryptoJS.AES.encrypt(JSON.stringify(decryptedMetadata), process.env.REACT_APP_SECRETKEY).toString();
                    localStorage.setItem('metadata', encryptedUpdatedMetadata);
                }
	            navigate("/Homesearchresults",{state:{"searchTermState":searchTerm}})
	            return;
        		// console.log(decryptedData);
        		setAiSearchResult(decryptedData);
        		console.log(aiSearchResult)
	            showNotification(result.message, "success");
	            navigate("/Homesearchresults")
	        } catch (error) {
	            console.error("Error uploading file:", error);
	            showNotification(error.message, "error");
	        }finally {
	            setIsLoading(false); // Always hide loader after the request
	        }
	  	}
	  }
	  const changesearchvalue = (value) => {
		setSearchTerm(value);
	  }
	  		
	  const fetchSuggestions = async () => {
		const encryptedMetadata = localStorage.getItem("metadata");
		let company_id = "";
		if (encryptedMetadata) {
			const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
			company_id = decryptedMetadata?.cid || "";
		}
		const serverURL = process.env.REACT_APP_SERVER_URL;
		const jsonData = { cid: company_id, type: "Search" };
		const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData), process.env.REACT_APP_SECRETKEY).toString();
				
		setIsLoading(true);
		try {
			const response = await fetch(`${serverURL}/masters/selectData`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				token,
			},
			body: JSON.stringify({ data: encryptedData }),
		});
				
		const data = await response.json();
		if (data.status !== "success") {
			showNotification(data.message || "Failed to fetch data.", "error");
			return;
		}
		let decryptedData = data.data;
		if (typeof data.data === "string") {
			try {
				decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
			} catch (decryptionError) {
				showNotification("Failed to decrypt data.", "error");
				return;
			}
		}
		const fetchedSuggestions = decryptedData.map((item) => item.search_key);
		setSuggestions(fetchedSuggestions);
		} catch (error) {
			showNotification("Something went wrong while loading data.", "error");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchSuggestions();
	}, []);
	const handleCreateClick = () => {
		localStorage.removeItem("jobCreationData");
		navigate('/JobDashboard');
	  
	};
	const handleResumeClick = () => {
		navigate('/Resume');
	  
	};
  

	return (
		<div className="dashboard radial-gradient-bg">
		  {isLoading && (
			<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
			  <div className="flex flex-col items-center">
				<ClipLoader color="#3498db" loading={isLoading} size={50} />
				<span className="text-white mt-4">Fetching...</span>
			  </div>
			</div>
		  )}
		  <Notification
			message={notification.message}
			type={notification.type}
			onClose={() => setNotification({ message: "", type: "" })}
		  />
		  <Topbar />
		  <div className="center-container">
			{!dashboardData.isEmptyData ? (
			  <>
				<h1 className="WelcomeUser">Welcome {userName}</h1>
				<div className="search-box-container" style={{ height: '110px' }}>
				  <SearchInput />
				</div>
	  
				{/* Render suggestions and button */}
				{!showUpload && (
				  <>
					<div className="suggestions-container">
					  {(isAIsearch && suggestions.length > 0 ? suggestions : defaultSuggestions).map((suggestion, index) => (
  						<span value={suggestion} onClick={() => changesearchvalue(suggestion)}
    						key={`${suggestions.length > 0 ? 'suggestion' : 'default'}-${index}`}
    						className="suggestion-pill"
						>
							<img className="search-star" src={star} alt="star" />
							{suggestion}
						</span>
						))}
					</div>
					<ButtonSolid
					  content="Find Profile"
					  className="my-custom-button"
					  onClick={() => searchProject()}
					  style={{ maxWidth: '120px', overflow: 'visible' }}
					  id="button-search"
					  type="button"
					/>
				  </>
				)}
	  			{!dashboardData.isResumeCount ? (
				  <div className="count-section">
					<div className="other-content mobile-grid-two-col">
					  {dashboardData.jobStats.map((stat, index) => (
						<div className="container-green" key={index} onClick={() => handleJobsClick(stat.label)}>
						  <h2 className="count-num">{stat.number}</h2>
						  <p className="count-name">{stat.label}</p>
						</div>
					  ))}
					</div>
				  </div>
				) : (
				<div className="w-full h-auto mb-6 p-6 sm:p-9 mt-2 mx-auto bg-white bg-opacity-50 flex justify-between" style={{ maxWidth: 'calc(100% - 250px)' }}>
				<div className="w-[35%] pr-4 p-6 rounded-lg">
					<p className="font-[Plus Jakarta Sans] text-[24px] font-semibold leading-[30.24px] text-left">
					Let’s first upload your database to begin seeing The eeezo magic!
					</p>
					<p className="mt-3 font-[Plus Jakarta Sans] text-[14px] font-normal leading-[17.64px] text-left">
					Upload resumes and start searching for the perfect candidates to build your team.
					</p>
				</div>
				<div className="w-[65%] flex items-center justify-end">
					<button
					onClick={handleResumeClick}
					className="w-full max-w-[161px] h-[40px] px-[16px] py-[8px] gap-[4px] rounded-lg bg-[#059669] text-white text-center"
					>
					Upload Resumes
					</button>
				</div>
				</div>
				)}
	  			<div className="projects-section w-full">
				  <Projects />
				</div>
			  </>
			) : (
			  <>
				<h1 className="WelcomeUser text-center mb-6">Welcome {userName}</h1>
				<div className="cards-container grid grid-cols-1 sm:grid-cols-2 gap-6 px-4">
				  <div className="card w-full sm:w-[400px] lg:w-[540px] h-auto mb-6 rounded-tl-[12px] rounded-br-[0px] rounded-bl-[0px] rounded-tr-[0px] bg-white bg-opacity-50 shadow-lg p-6 sm:p-12">
					<p className="font-[Plus Jakarta Sans] text-[24px] font-semibold leading-[30.24px] text-left">
					  Upload Your Existing Database Into The Resumebox To Unlock Hiring Efficiency
					</p>
					<p className="mt-3 font-[Plus Jakarta Sans] text-[14px] font-normal leading-[17.64px] text-left">
					  Instantly access a pool of pre-screened candidates perfectly matched to your open roles.
					</p>
					<button
					  onClick={handleResumeClick}
					  className="mt-7 w-full max-w-[161px] h-[40px] px-[16px] py-[8px] gap-[4px] rounded-tl-[8px] rounded-tr-[8px] rounded-br-[8px] rounded-bl-[8px] bg-[#059669] text-white text-center"
					>
					  Upload Resumes
					</button>
				  </div>
	  
				  <div className="card w-full sm:w-[400px] lg:w-[540px] h-auto mb-6 rounded-tl-[12px] rounded-br-[0px] rounded-bl-[0px] rounded-tr-[0px] bg-white bg-opacity-50 shadow-lg p-6 sm:p-12">
					<p className="font-[Plus Jakarta Sans] text-[24px] font-semibold leading-[30.24px] text-left">
					  Take The First Step Towards Finding The Perfect Candidate
					</p>
					<p className="mt-3 font-[Plus Jakarta Sans] text-[14px] font-normal leading-[17.64px] text-left">
					  Start your hiring journey by posting a job now, and connect with a pool of qualified candidates.
					</p>
					<button
					  onClick={handleCreateClick}
					  className="mt-14 w-full max-w-[101px] h-[40px] px-[16px] py-[8px] gap-[4px] rounded-tl-[8px] rounded-tr-[8px] rounded-br-[8px] rounded-bl-[8px] bg-[#059669] text-white text-center"
					>
					  Create +
					</button>
				  </div>
				</div>
			  </>
			)}
		  </div>
		</div>
	  );
				};

export default Dashboard;
