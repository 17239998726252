import React from "react";
import { useCommonContext } from "../../context/CommonContext";
import "./UserExperience.css";

const ExperienceSelector = () => {
  const { experience, setExperience } = useCommonContext();

  const handleSelection = (value) => {
    setExperience(value);
  };

  return (
    <div className="experience-selector">
      <div className="options-container">
        {/* Fresher Option */}
        <div
          className={`option-card ${experience === "fresher" ? "selected" : ""}`}
          onClick={() => handleSelection("fresher")}
        >
            <div className="tick-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                <path d="M9 16.2l-4.2-4.2-1.4 1.4L9 19l11-11-1.4-1.4L9 16.2z"></path>
                </svg>
            </div>
          <div className="background-image fresher-bg"></div>
          <p className="option-text">I'm Fresher</p>
        </div>

        {/* Experienced Option */}
        <div
          className={`option-card ${
            experience === "experienced" ? "selected" : ""
          }`}
          onClick={() => handleSelection("experienced")}
        >
        <div className="tick-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path d="M9 16.2l-4.2-4.2-1.4 1.4L9 19l11-11-1.4-1.4L9 16.2z"></path>
            </svg>
        </div>
          <div className="background-image experienced-bg"></div>
          <p className="option-text">I'm Experienced</p>
        </div>
      </div>
    </div>
  );
};

export default ExperienceSelector;
