import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Eeezo from "../../Common/Logo";
import List from "../../elements/Lists/Lists";
import Selectbox from "../../elements/SelectBox/SelectBox";
import Profile from "../../../assets/Profile.png";
import Textinput from "../../elements/TextInput/TextInput";
import Button from "../../elements/Button/Button";
import { AuthContext, useAuth } from "../../../context/AuthContext";
import ProjectCreation from "../../ProjectPipeline/ProjectCreation";
import Person from "../../../assets/Images/person.jpg";
import CryptoJS from "crypto-js";
import "../Topbar/Topbar.css";
import Swal from "sweetalert2";
// import { profile } from "console";
const Topbar = () => {
  var cid;
  var userName;
  var userEmail;
  var userPhoto;
  var userCompany;
  const { token } = useContext(AuthContext);
  const encryptedMetadata = localStorage.getItem("metadata");
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isCompanyIdset, setiscompanyIdset] = useState(false);
  const notificationRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setIsNotificationOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  console.log(token, "token");
  const fetchNotification = async () => {
    const encryptedMetadata = localStorage.getItem("metadata");
    const decryptedMetadata = JSON.parse(
      CryptoJS.AES.decrypt(
        encryptedMetadata,
        process.env.REACT_APP_SECRETKEY
      ).toString(CryptoJS.enc.Utf8)
    );
    const CID = {
      company_id: decryptedMetadata.cid,
      user_id: decryptedMetadata.uid,
    };
    console.log(CID);
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(CID),
      process.env.REACT_APP_SECRETKEY
    ).toString();
    const encryptjson = { data: encryptedData };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/notification/fetchNotification`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
          body: JSON.stringify(encryptjson),
        }
      );

      const status = await response.json();
      const Notification = JSON.parse(
        CryptoJS.AES.decrypt(
          status.data,
          process.env.REACT_APP_SECRETKEY
        ).toString(CryptoJS.enc.Utf8)
      );
      console.log("Notification", Notification);
      const NotificationData = Notification.map((notifdata) => {
        const timeDifference = calculateTimeDifference(notifdata.created_at);
        return {
          text: notifdata.info,
          id: notifdata.ref_id,
          time: timeDifference,
          type: notifdata.type,
        };
      });
      setNotifications(NotificationData);
      console.log("NotificationData", NotificationData);
    } catch (err) {
      console.error("Error Fetching Job List:", err);
    }
  };
  const approvJobFun = (job_id_Get, type) => {
    navigate("/JobApprove", { state: { job_id_Get, type } });
  };
  const calculateTimeDifference = (createdAt) => {
    const createdTime = new Date(createdAt);
    const currentTime = new Date();

    const diffInSeconds = Math.floor((currentTime - createdTime) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds}s`;
    } else if (diffInSeconds < 3600) {
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      return `${diffInMinutes}m`;
    } else if (diffInSeconds < 86400) {
      const diffInHours = Math.floor(diffInSeconds / 3600);
      return `${diffInHours}h`;
    } else {
      const diffInDays = Math.floor(diffInSeconds / 86400);
      return `${diffInDays}d`;
    }
  };
  const handleNotification = async () => {
    const notification = await fetchNotification();
    setIsNotificationOpen(!isNotificationOpen);
  };
  // console.log(encryptedMetadata,"encryptedMetadatai ")
  if (encryptedMetadata) {
    // && encryptedMetadata.data) {
    // console.log(encryptedMetadata,"encryptedMetadata")
    const decryptedMetadata = JSON.parse(
      CryptoJS.AES.decrypt(
        encryptedMetadata,
        process.env.REACT_APP_SECRETKEY
      ).toString(CryptoJS.enc.Utf8)
    );
    cid = decryptedMetadata.cid;
    userName = decryptedMetadata.name;
    userEmail = decryptedMetadata.email;
    if (decryptedMetadata.profile && decryptedMetadata.profile.startsWith("http")) {
      userPhoto = decryptedMetadata.profile;
    } else {
      userPhoto = `${process.env.REACT_APP_SERVER_BASEURL}/profile/${decryptedMetadata.profile}`;
    }
    if (decryptedMetadata.company_name)
      userCompany = decryptedMetadata.company_name;
    console.log(userPhoto, "userPhoto");
    console.log(decryptedMetadata, "decryptedMetadata");
  }
  const loadProfile = async () => {
    try {
      const serverURL = process.env.REACT_APP_SERVER_URL;
      const response = await fetch(`${serverURL}/company/fetchCompanyUsers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });
      const data = await response.json();

      const decryptedData = JSON.parse(
        CryptoJS.AES.decrypt(
          data.data,
          process.env.REACT_APP_SECRETKEY
        ).toString(CryptoJS.enc.Utf8)
      );
      console.log("following:", decryptedData);
      // if (!response.ok) {
      //     var errorMessage = "Something Went Wrong";
      //     if(data.error)
      //         errorMessage = data.error;
      //     showNotification(errorMessage,"error");
      //     throw new Error(data.error);
      // }
      // else{
      //     userName.current.value=decryptedData[0]['name'];
      //     userEmail.current.value=decryptedData[0]['email'];
      //     userPhoneNo.current.value=decryptedData[0]['phone_no'];
      //     set_Phone(decryptedData[0]['phone_no']);
      //     if(decryptedData[0]['notification'] && decryptedData[0]['notification'] == "true"){
      //       userCheckNotification.current.checked=true;
      //     }
      // }
    } catch (error) {
      console.log("Error:", error);
      let errorMessage = "Something went wrong.";
      if (error.response && error.response.data && error.response.data.msg) {
        errorMessage = error.response.data.msg;
      } else if (error.message) {
        errorMessage = error.message;
      }
      // showNotification(errorMessage, "error");
    }
  };
  useEffect(() => {
    // loadProfile();
  }, []);

  // const { token } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [IsCrPrModalOpen, setIsCrPrModalOpen] = useState(false);
  const dropdownRef = useRef(null);

  const navigate = useNavigate();
  const profile_navbar_dropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleJobNavigation = () => {
    navigate("/JobListing");
  };
  const handleProjectNavigation = () => {
    navigate("/calendar");
  };
  const handleResumeNavigation = () => {
    navigate("/Resume");
  };
  const handleTriageNavigation = () => {
    navigate("/Triage");
  };

  const goToManageWorkSpace = () => {
    navigate("/ManageWorkspace");
  };

  const [OrganizationSelect, setIndustryValue] = useState("");
  const { handleLogout } = useContext(AuthContext);
  const handleOrganizationChange = (event) => {
    setIndustryValue(event.target.value);
  };
  const Organization = [
    { id: "0", label: "Organization" },
    { id: "1", label: "Small (1-10 employees)" },
    { id: "2", label: "Medium (11-50 employees)" },
  ];

  const [isWorkspaceSubmenuOpen, setIsWorkspaceSubmenuOpen] = useState(false); // State to toggle the visibility of the submenu

  const [workspaces, setWorkspaces] = useState([]);

  const handleSubmenuToggle = () => {
    // Toggle the workspace submenu
    setIsWorkspaceSubmenuOpen(!isWorkspaceSubmenuOpen);

    // If the submenu is opening, increase the parent div height
    if (!isWorkspaceSubmenuOpen) {
      loadWorkspace();
    }
  };

  const handleWorkspaceClick = (workspace) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to change the workspace?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "No, cancel!",
    }).then(async (swalResult) => {
      if (swalResult.isConfirmed) {
        const { workspace_id, email, company_id, name } = workspace;

        const workspaceData = { workspace_id, email };

        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(workspaceData),
          process.env.REACT_APP_SECRETKEY
        ).toString();

        const requestData = { data: encryptedData };

        try {
          const serverURL = process.env.REACT_APP_SERVER_URL;
          const response = await fetch(
            `${serverURL}/company/updateActiveWorkspace`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                token: token,
              },
              body: JSON.stringify(requestData),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to update workspace");
          }

          const result = await response.json();

          const decryptedData = JSON.parse(
            CryptoJS.AES.decrypt(
              result.data,
              process.env.REACT_APP_SECRETKEY
            ).toString(CryptoJS.enc.Utf8)
          );

          if (decryptedData.message) {
            Swal.fire("Changed!", decryptedData.message, "success").then(() => {
              const encryptedMetadata = localStorage.getItem("metadata");
              if (encryptedMetadata) {
                const decryptedMetadata = JSON.parse(
                  CryptoJS.AES.decrypt(
                    encryptedMetadata,
                    process.env.REACT_APP_SECRETKEY
                  ).toString(CryptoJS.enc.Utf8)
                );

                decryptedMetadata.cid = company_id;
                decryptedMetadata.company_name = name;

                const encryptedCID = CryptoJS.AES.encrypt(
                  JSON.stringify(decryptedMetadata),
                  process.env.REACT_APP_SECRETKEY
                ).toString();

                localStorage.setItem("metadata", encryptedCID);
                navigate("/Dashboard");
              }
            });
          } else {
            Swal.fire(
              "Error!",
              "Something went wrong. Please try again.",
              "error"
            );
          }
        } catch (error) {
          console.log("Error:", error);
          Swal.fire("Error!", "An unexpected error occurred.", "error");
        }
      }
    });
  };

  const handleClickOutside = (event) => {
    // Check if the click is outside the dropdown and the dropdown is open
    if (
      isOpen &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false);
      setIsWorkspaceSubmenuOpen(false);
    }
  };

  const loadWorkspace = async () => {
    try {
      const serverURL = process.env.REACT_APP_SERVER_URL;
      const response = await fetch(`${serverURL}/company/fetchWorkspace`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });
      const data = await response.json();

      const decryptedData = JSON.parse(
        CryptoJS.AES.decrypt(
          data.data,
          process.env.REACT_APP_SECRETKEY
        ).toString(CryptoJS.enc.Utf8)
      );
      if (decryptedData) {
        console.log("company workspace data", decryptedData);
        setWorkspaces(decryptedData); // Update the state with the fetched workspaces
      }
    } catch (error) {
      console.log("Error:", error);
      let errorMessage = "Something went wrong.";
      if (error.response && error.response.data && error.response.data.msg) {
        errorMessage = error.response.data.msg;
      } else if (error.message) {
        errorMessage = error.message;
      }
    }
  };

  useEffect(() => {
    // Attach the event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const [AlchemySelect, setAlchemyValue] = useState("");

  const handleAlchemyChange = (event) => {
    setAlchemyValue(event.target.value);
  };
  const Alchemy = [
    { id: "0", label: "Alchemy" },
    { id: "1", label: "Small (1-10 employees)" },
    { id: "2", label: "Medium (11-50 employees)" },
  ];

  const handleCreateClick = () => {
    localStorage.removeItem("jobCreationData");
    navigate("/JobDashboard");
  };
  const navigateDash = () => {
    navigate("/Dashboard");
  };
  const navigateMessage = () => {
    navigate("/Message");
  };
  return (
    <div className="flex items-center gap-2 px-4">
      <div onClick={navigateDash} className="cursor-pointer">
        <Eeezo />
      </div>
      {IsCrPrModalOpen && (
        <ProjectCreation onClose={() => setIsCrPrModalOpen(false)} />
      )}
      <List
        ulClass="navbar_content flex items-center px-4 mb-0 ml-8 mobile-hidden"
        lists={
          <>
            <li className="list-none cursor-pointer mr-6">
              <p className="mb-0" onClick={handleJobNavigation}>
                Jobs
              </p>
            </li>
            {/* <li className ="list-none cursor-pointer mr-6"><p className='mb-0' onClick={handleProjectNavigation}>Projects</p></li>
                <li className='list-none cursor-pointer mr-6'>Reports</li> */}
            {/* <li className ="cursor-pointer w-2/6"> */}
            {/* <button className="flex items-center justify-between w-full ">Organization <svg className="w-2.5 h-2.5 ms-2.5" aria-hidden="true"  fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                        </svg>
                    </button> */}
            {/* <Selectbox
                        labelClass="hidden"
                        name="exampleSelect"
                        value={OrganizationSelect}
                        onChange={handleOrganizationChange}
                        list={Organization}
                        className="border-none !w-full mr-4"
                        required
                    />
                </li> */}
            <li
              className="list-none cursor-pointer ml-4 mr-6"
              onClick={handleProjectNavigation}
            >
              Schedule
            </li>
            <li
              className="list-none cursor-pointer ml-4 mr-6"
              onClick={handleResumeNavigation}
            >
              Resume Box
            </li>
            <li
              className="list-none cursor-pointer ml-4"
              onClick={handleTriageNavigation}
            >
              Triage
            </li>
          </>
        }
      />
      <div className="flex  items-center ml-auto">
        {/* <div><button className='profile_create_button text-white px-4 py-2.5 rounded-lg'>Create +</button></div> */}
        <div className="w-2/5 text-nowrap">
          <Button
            onClick={handleCreateClick}
            className="mobile-create-button border-none mr-5 bg-green-650 text-white rounded-lg"
          >
            Create +
          </Button>
        </div>
        <div className="flex w-3/4 mobile-full-width">
          {/* <button
                  type="button"
                  className="bg-gray-100 inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-style-search"
                  onClick={profile_navbar_dropdown}
                >
                  Alchemy

                  <svg
                    className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#059669" viewBox="0 0 24 24" aria-hidden="true">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 10l5 5 5-5H7z"
                    />
                  </svg>
                </button> */}
          {/* <Selectbox
                        labelClass="hidden"
                        name="exampleSelect"
                        value={AlchemySelect}
                        onChange={handleAlchemyChange}
                        list={Alchemy}
                        className="!w-4/6 border-gray-400 !py-1 !bg-gray-100"
                        required
                    /> */}

          {/* {isOpen && (
                <div className="absolute right-0 z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <a className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 no-underline">
                      Alchemy 1
                    </a>
                    <a className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 no-underline"
                    >
                      Alchemy 2
                    </a>
                    <a className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 no-underline">
                      Alchemy 3
                    </a>
                  </div>
                </div>
              )} */}
          <div className="relative">
            <Button
              type="button"
              onClick={() => handleNotification()}
              className="!bg-gray-100 ml-3 inline-flex justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <img src="/image/notification.png" className="w-5" />
            </Button>
            {isNotificationOpen && (
              <div
                ref={notificationRef}
                className="no-scrollbar Notification-mobile absolute right-0 px-[10px] mt-2 w-[30rem] shadow-lg border border-gray-200 rounded-lg z-50"
                style={{ backgroundColor: "#F2F9F9" }}
              >
                <div className="no-scrollbar px-[10px] py-[10px] max-h-96 overflow-y-auto">
                  {notifications && notifications.length > 0 ? (
                    notifications.map((notification, index) => (
                      <div
                        onClick={() =>
                          approvJobFun(notification.id, notification.type)
                        }
                        key={index}
                        className="mb-3 flex justify-between items-center p-4 hover:bg-gray-50 border-b last:border-none"
                        style={{
                          backgroundColor: "#FFFFFF",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          borderRadius: "16px",
                        }}
                      >
                        <span
                          className="text-gray-600 text-sm truncate max-w-[80%]"
                          style={{
                            display: "inline-block",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                          title={notification.text}
                        >
                          {notification.text}
                        </span>
                        <span className="text-gray-400 text-xs ml-2">
                          {notification.time}
                        </span>
                      </div>
                    ))
                  ) : (
                    <div className="p-4 text-gray-500 text-sm">
                      No notifications
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <Button
            onClick={navigateMessage}
            type="button"
            className="!bg-gray-100 inline-flex ml-3 justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <img src="/image/message.png" className="w-5" />
          </Button>

          <div className="relative inline-block text-left" ref={dropdownRef}>
            {/* Dropdown Button */}
            <Button
              type="button"
              onClick={() => setIsOpen(!isOpen)}
              className="!bg-gray-100 inline-flex ml-3 justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <img src={Profile} className="w-5" alt="Dropdown Icon" />
            </Button>

            {/* Dropdown Menu */}
            {isOpen && (
              <div
                className="absolute px-[36px] py-[18px] right-0 mt-2 w-[435px]  rounded-[12px] shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                style={{ height: "auto" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="129"
                  height="50"
                  className="absolute inset-x-[350px] inset-y-[-25px]"
                  viewBox="0 0 129 116"
                  fill="none"
                >
                  <g filter="url(#filter0_d_1246_7829)">
                    <path
                      d="M63.6256 36.375C64.0142 35.875 64.9858 35.875 65.3744 36.375L82.8632 58.875C83.2519 59.375 82.7661 60 81.9888 60H47.0112C46.2339 60 45.7481 59.375 46.1368 58.875L63.6256 36.375Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_1246_7829"
                      x="0"
                      y="0"
                      width="129"
                      height="116"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="10" />
                      <feGaussianBlur stdDeviation="23" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.74902 0 0 0 0 0.878431 0 0 0 0 0.886275 0 0 0 0.4 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1246_7829"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1246_7829"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>

                {/* Profile Section */}
                <div className="border-b-2">
                  <div className="w-[274px] flex pr-[18px] pb-[18px] mt-3">
                    <img
                      src={userPhoto}
                      className="w-[77.97px] h-[76.13px] rounded-full object-cover"
                    />
                    <div>
                      <p className="text-[20px] font-medium mt-[1px] ml-[22px] mb-0">
                        {userName}
                      </p>
                      <p className="text-[14px] font-[400] ml-[22px] mb-0">
                        {userEmail}
                      </p>
                      <p
                        className="text-[14px] text-[#438E96] ml-[22px] hover:cursor-pointer"
                        onClick={() => navigate("/Usersettings")}
                      >
                        Manage Profile
                      </p>
                    </div>
                  </div>
                </div>

                {/* Credits Section */}
                <div className="mt-3 h-full">
                  <p className="hidden text-[14px] font-medium flex">
                    <img src={Profile} className="w-[18px] h-[18px] mr-2" />
                    {userCompany ? userCompany : ""}
                  </p>
                  <div className="hidden h-[137px] px-[16px] py-[24px] w-[363px] border-[1.5px] border-[#BFE0E2] rounded-[8px]">
                    <div className="flex w-full">
                      <p className="text-[14px] w-[50%] mb-0">
                        Monthly Credits
                      </p>
                      <div className="w-[50%]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="21"
                          className="float-right"
                          viewBox="0 0 20 21"
                          fill="none"
                        >
                          <path
                            d="M19.9992 10.9663V18.7712C19.9992 19.3534 19.7679 19.9117 19.3563 20.3234C18.9446 20.735 18.3863 20.9663 17.8042 20.9663H2.19503C1.61287 20.9663 1.05456 20.735 0.642911 20.3234C0.231262 19.9117 0 19.3534 0 18.7712V3.16143C0 2.57925 0.231262 2.02091 0.642911 1.60925C1.05456 1.19758 1.61287 0.966309 2.19503 0.966309H9.9996C10.1937 0.966309 10.3798 1.0434 10.517 1.18062C10.6542 1.31784 10.7313 1.50396 10.7313 1.69802C10.7313 1.89208 10.6542 2.07819 10.517 2.21541C10.3798 2.35263 10.1937 2.42972 9.9996 2.42972H2.19503C2.00098 2.42972 1.81488 2.50681 1.67766 2.64404C1.54044 2.78126 1.46336 2.96737 1.46336 3.16143V18.7712C1.46336 18.9652 1.54044 19.1514 1.67766 19.2886C1.81488 19.4258 2.00098 19.5029 2.19503 19.5029H17.8042C17.9982 19.5029 18.1843 19.4258 18.3215 19.2886C18.4588 19.1514 18.5358 18.9652 18.5358 18.7712V10.9663C18.5358 10.7722 18.6129 10.5861 18.7501 10.4489C18.8874 10.3117 19.0735 10.2346 19.2675 10.2346C19.4616 10.2346 19.6477 10.3117 19.7849 10.4489C19.9221 10.5861 19.9992 10.7722 19.9992 10.9663Z"
                            fill="#059669"
                          />
                          <path
                            d="M20.0001 1.69802V6.44436C20.0001 6.63842 19.923 6.82453 19.7858 6.96175C19.6486 7.09897 19.4625 7.17606 19.2685 7.17606C19.0744 7.17606 18.8883 7.09897 18.7511 6.96175C18.6139 6.82453 18.5368 6.63842 18.5368 6.44436V3.46387L10.5176 11.4834C10.3789 11.6126 10.1954 11.683 10.0059 11.6796C9.81633 11.6763 9.63547 11.5995 9.50141 11.4655C9.36736 11.3314 9.29057 11.1505 9.28722 10.961C9.28388 10.7714 9.35424 10.5879 9.48348 10.4492L17.5027 2.42972H14.5223C14.3283 2.42972 14.1421 2.35263 14.0049 2.21541C13.8677 2.07819 13.7906 1.89208 13.7906 1.69802C13.7906 1.50396 13.8677 1.31784 14.0049 1.18062C14.1421 1.0434 14.3283 0.966309 14.5223 0.966309H19.2685C19.3605 0.967049 19.4515 0.985258 19.5367 1.01997C19.6266 1.05676 19.7083 1.11107 19.7769 1.17975C19.8456 1.24842 19.8999 1.33008 19.9367 1.41997C19.976 1.50755 19.9976 1.60205 20.0001 1.69802Z"
                            fill="#059669"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="mt-1">
                      <p className="text-[18px] font-semibold mb-0">
                        1394/1500 credits left
                      </p>
                      <p className="text-[12px] mt-1 opacity-50">
                        Lorem ipsum dolor sit amet consectetur. Diam enim
                        aliquet tempor nibh nisi. Etiam pharetra
                      </p>
                    </div>
                  </div>
                  {/* Change Workspace Button */}
                  <button
                    onClick={handleSubmenuToggle}
                    className="plusJakartaSansFont font-bold rounded-lg text-base mt-3 text-center flex items-center justify-between w-full"
                  >
                    Change Workspace
                  </button>

                  {/* Workspace Submenu */}
                  {isWorkspaceSubmenuOpen && (
                    <div className="overflow-y-auto">
                      <ul className="py-2 text-sm px-0">
                        {workspaces.length > 0 ? (
                          workspaces.map((workspace) => {
                            // Return the list item only if the status is 'active'
                            if (workspace.status === "active") {
                              return (
                                <li
                                  key={workspace.workspace_id}
                                  className="flex justify-between hover:bg-gray-100 cursor-pointer"
                                  onClick={() =>
                                    handleWorkspaceClick(workspace)
                                  }
                                >
                                  <span className="py-2 font-medium plusJakartaSansFont text-base flex items-center cursor-pointer">
                                    {workspace.name}
                                  </span>
                                  {workspace.company_id === cid && (
                                    <span className="py-2 font-medium plusJakartaSansFont text-base flex items-center cursor-pointer">
                                      ✔️
                                    </span> // Add tick icon
                                  )}
                                </li>
                              );
                            }
                            return null; // Ensure something is returned, even if the condition is not met
                          })
                        ) : (
                          <li className="py-2 text-center">
                            No workspaces available
                          </li>
                        )}
                      </ul>

                      <div
                        className="flex justify-end mt-2"
                        onClick={goToManageWorkSpace}
                      >
                        <p className="text-green-600 font-medium plusJakartaSansFont text-sm flex items-center cursor-pointer">
                          Manage Workspace
                        </p>
                      </div>
                    </div>
                  )}

                  {/* Sign Out Button */}
                  <p
                    className="mb-0 mt-2 font-bold text-[14px] hover:cursor-pointer"
                    onClick={handleLogout}
                  >
                    Sign Out
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <button type="button" className="bg-gray-100 inline-flex justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <img src="/image/notification.png" className='w-5' />
            </button>
            <button type="button" className="bg-gray-100 inline-flex justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <img src="/image/message.png" className='w-5' />
            </button> */}
    </div>
  );
};
export default Topbar;
