import React, { useState, useEffect, useContext,useRef } from "react";
import Button from "../elements/Button/Button";
import ModalBox from "../elements/ModalBox/ModalBox";
import Textinput from "../elements/TextInput/TextInput";
import CryptoJS from "crypto-js"; // Ensure you import CryptoJS
import { AuthContext } from "../../context/AuthContext";
import Notification from "../Notification/Notification";
import Toggle from "../elements/ToggleButton/ToggleButton";
import Swal from 'sweetalert2';
import ClipLoader from "react-spinners/ClipLoader";

const MastersPage = ({ type }) => {
  const { token } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [questionKey, setQuestionKey] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [questionDesc, setQuestionDesc] = useState("");
  const [questionValue, setQuestionValue] = useState("");
  const [userTableJson, userTableJsonFun] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isRequired, setIsRequired] = useState(false);
  const inputRef = useRef(null);
  const questioncatRef = useRef(null);
  const questionTypeRef = useRef(null);
  const questionDesRef = useRef(null);

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification({ message: "", type: "" });
    }, 3000);
  };

  const openForm = () => {
    setIsModalOpen(true);
  }

  const closeForm = () => {
    setIsModalOpen(false);
    setInputValue("");
    setQuestionKey("");
    setQuestionType("");
    setQuestionDesc("");
    setQuestionValue("");
    setIsRequired(false);
  }

  useEffect(()=>{
    const setUSerName = () => {
      const encryptedMetadata = localStorage.getItem('metadata');
      const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
    setUserName(decryptedMetadata.name);
    }
    if(userName === ""){
      setUSerName();
    }
  },[userName])

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    inputRef.current.classList.remove("!border-red-600");
  };
  const setQuestionKeyFun = (event) => {
    setQuestionKey(event);
    questioncatRef.current.classList.remove("!border-red-600");
  }
  const setQuestionTypeFun = (event) => {
    setQuestionType(event);
    questionTypeRef.current.classList.remove("border-red-600");
  }
  const setQuestionDescFun = (event) => {
    setQuestionDesc(event);
    questionDesRef.current.classList.remove("!border-red-600");
  }

  const saveData = async () => {
    const encryptedMetadata = localStorage.getItem("metadata");
    let company_id = "";
    if (encryptedMetadata) {
      const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
      company_id = decryptedMetadata?.cid || "";
    }
  
    const serverURL = process.env.REACT_APP_SERVER_URL;
  
    let jsonData = { cid: company_id, created_by: userName, type };
  
    if (type === "Specialities") {
      jsonData.spec_key = inputValue;
      if(!inputValue){
        inputRef.current.classList.add("!border-red-600");
        inputRef.current.focus();
        // showNotification("Please Enter Speciality", "error");
        return;
      }
    } else if (type === "Skills") {
      jsonData.skill = inputValue;
      if(!inputValue){
        inputRef.current.classList.add("!border-red-600");
        inputRef.current.focus();
        // showNotification("Please Enter Speciality", "error");
        return;
      }
    } else if (type === "Search") {
      jsonData.search_key = inputValue;
      if(!inputValue){
        inputRef.current.classList.add("!border-red-600");
        inputRef.current.focus();
        // showNotification("Please Enter Speciality", "error");
        return;
      }
    } else if (type === "Questions") {
      jsonData.question_key = questionKey;
      jsonData.questiontype = questionType;
      jsonData.question_desc = questionDesc;
      jsonData.value = questionValue === "" ? false : questionValue;
      if(!questionKey){
        questioncatRef.current.classList.add("!border-red-600");
        questioncatRef.current.focus();
        // showNotification("Please Enter Question Key", "error");
        return;
      }
      if(!questionType){
        questionTypeRef.current.classList.add("border-red-600");
        questionTypeRef.current.focus();
        // showNotification("Please Enter Question Type", "error");
        return;
      }
      if(!questionDesc){
        questionDesRef.current.classList.add("!border-red-600");
        questionDesRef.current.focus();
        // showNotification("Please Enter Question Description", "error");
        return;
      }
    }
  
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData),process.env.REACT_APP_SECRETKEY).toString();
    setIsLoading(true);
    try {
      const response = await fetch(`${serverURL}/masters/insertMasterData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
        body: JSON.stringify({ data: encryptedData }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        showNotification(errorText || "Something went wrong", "error");
        throw new Error(errorText);
      }
  
      const data = await response.json();
      showNotification(`${type} saved successfully!`, "success");
      closeForm();
      loadProfile();
    } catch (error) {
      showNotification("An error occurred while saving the data", "error");
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    loadProfile();
  }, [type]);


  const loadProfile = async () => {
    const encryptedMetadata = localStorage.getItem("metadata");
    let company_id = "";
    if (encryptedMetadata) {
      const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
      company_id = decryptedMetadata?.cid || "";
    }
  
    const serverURL = process.env.REACT_APP_SERVER_URL;
    const jsonData = { cid: company_id, type };
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData),process.env.REACT_APP_SECRETKEY).toString();
  
    setIsLoading(true);
    try {
      const response = await fetch(`${serverURL}/masters/selectData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token,
        },
        body: JSON.stringify({ data: encryptedData }),
      });
  
      const data = await response.json();
  
      if (data.status !== "success") {
        showNotification(data.message || "Failed to fetch data.", "error");
        return;
      }
  
      let decryptedData = data.data;
      if (typeof data.data === "string") {
        try {
          decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        } catch (decryptionError) {
          showNotification("Failed to decrypt data.", "error");
          return;
        }
      }
      const dataArray = Array.isArray(decryptedData) ? decryptedData : [decryptedData];
      userTableJsonFun(dataArray);
  
    } catch (error) {
      showNotification("Something went wrong while loading data.", "error");
    } finally {
      setIsLoading(false);
    }
  };
  

  const dataDelete = async (uid, type) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `You want to delete this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });
  
    if (result.isConfirmed) {
      const serverURL = process.env.REACT_APP_SERVER_URL;
      const jsonData = { item_id: uid, item_type: type };
  
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData),process.env.REACT_APP_SECRETKEY).toString();
  
      try {
        const response = await fetch(`${serverURL}/masters/deleteItem`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
          body: JSON.stringify({ data: encryptedData }),
        });
  
        if (!response.ok) {
          const errorText = await response.text();
          showNotification(errorText || "Something went wrong", "error");
          throw new Error(errorText);
        }
  
        const data = await response.json();
        if (data.status === "success") {
          showNotification(`${type} deleted successfully!`, "success");
          loadProfile();
        } else {
          showNotification(data.message || "Failed to delete", "error");
        }
      } catch (error) {
        showNotification("An error occurred while deleting the data", "error");
      }
    }
  };
    const modal_Body = (
    <div className="w-[500px] h-[90%]">
      <div className="w-90% h-[85%] px-2">
        {type === "Questions" ? (
          <>
            <Textinput label="Question Category"
              className="w-4/5 border-2 border-blue-100 mb-4 focus:outline-none focus:ring-0"
              placeholder="Enter Question Category"
              value={questionKey}
              InputRef={questioncatRef}
              onChange={(e) => setQuestionKeyFun(e.target.value)}
            />
            <div className="w-full mb-4">
              <label className="block mb-2 font-bold text-black-700">Question Type</label>
              <select 
                className="w-full border-2 bg-white border-blue-100 p-2 rounded focus:outline-none focus:ring-0" 
                value={questionType}
                ref={questionTypeRef}
                onChange={(e) => setQuestionTypeFun(e.target.value)}
              >
                <option value="" disabled>Select Question Type</option>
                <option value="Yes/No">Yes/No</option>
                <option value="Text">Text</option>
              </select>
            </div>
            <Textinput
              label="Question"
              className="w-4/5 border-2 border-blue-100 mb-4 focus:outline-none focus:ring-0"
              placeholder="Enter Question"
              value={questionDesc}
              InputRef={questionDesRef}
              onChange={(e) => setQuestionDescFun(e.target.value)}
            />
          </>
        ) : (
          <Textinput
            label={`${type}`}
            className="w-4/5 border-2 border-blue-100 mb-4 focus:outline-none focus:ring-0"
            placeholder={`Enter ${type}`}
            value={inputValue}
            InputRef={inputRef}
            onChange={handleInputChange}
          />
        )}
      </div>
    </div>
  );
  const Modal_Footer = (
    <>
      <div className="flex justify-between">
        <Button className="border-2 border-green-800 bg-white mt-2 text-sm text-green-700 mt-4 py-2 px-4 rounded" onClick={closeForm}>Cancel</Button>
        <Button className="border-2 border-green-800 bg-green-700 text-sm text-white ml-4 mt-4 py-2 px-4 rounded" onClick={saveData}>Save</Button>
      </div>
    </>
  );
  const data = Array.isArray(userTableJson) ? userTableJson : [];

  return (
    <div className="w-8/12 mr-2 p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="flex flex-col items-center">
                <ClipLoader color="#3498db" loading={isLoading} size={50} />
                <span className="text-white mt-4">Fetching...</span>
            </div>
        </div>
      )}
      <Notification
        message={notification.message}
        type={notification.type}
        onClose={() => setNotification({ message: "", type: "" })}
      />
      <div className="flex">
        <div className="mt-3">
          <p className="text-black text-lg font-medium">{type}</p>
        </div>
        <div className="ml-auto">
          <button className="pt-2 mt-2 pb-2 px-4 rounded-lg bg-green-700 text-white" onClick={openForm}>Add New {type}</button>
        </div>
      </div>

      {isModalOpen && (
        <ModalBox Modal_Body={modal_Body} modal_Fooder_Class="border-t-0 border-gray-300 mt-1" Modal_Footer={Modal_Footer} Modal_Header={`Add New ${type}`} Modal_Close_Func={closeForm}/>
      )}

      <div className="relative flex flex-col w-full h-2/4 text-gray-700 bg-white rounded-xl bg-clip-border">
        <table className="w-full text-left mt-4 table-auto">
          <thead>
            <tr className="bg-sky-50">
              <th className="p-1 border-b w-1/4 border-blue-gray-100 bg-blue-gray-50">
                <p className="block mt-2 ml-4 mt-2 font-sans text-md antialiased font-normal leading-none text-black">S No.</p>
              </th>
              {type === "Questions" ? (
                <>
                  <th className="p-1 border-b w-1/4 border-blue-gray-100 bg-blue-gray-50">
                    <p className="block mt-2 ml-4 mt-2 font-sans text-md antialiased font-normal leading-none text-black">Question Category</p>
                  </th>
                  <th className="p-1 border-b w-1/4 border-blue-gray-100 bg-blue-gray-50">
                    <p className="block mt-2 ml-4 mt-2 font-sans text-md antialiased font-normal leading-none text-black">Question Type</p>
                  </th>
                  <th className="p-1 border-b w-1/4 border-blue-gray-100 bg-blue-gray-50">
                    <p className="block mt-2 ml-4 mt-2 font-sans text-md antialiased font-normal leading-none text-black">Question</p>
                  </th>
                </>
              ) : (
                <th className="p-1 border-b w-1/4 border-blue-gray-100 bg-blue-gray-50">
                  <p className="block mt-2 ml-4 mt-2 font-sans text-md antialiased font-normal leading-none text-black">{type}</p>
                </th>
              )}
              <th className="p-1 border-b w-1/4 border-blue-gray-100 bg-blue-gray-50 text-right">
                <p className="block mt-2 mr-4 mt-2 font-sans text-md antialiased font-normal leading-none text-black">Action</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colSpan={type === "Questions" ? 5 : 2} className="p-4 text-center text-gray-500">
                  No data available
                </td>
              </tr>
            ) : (
              data.map((item, index) => {
                return type === "Questions" ? (
                  <tr key={index} className="border-b border-blue-gray-50">
                    <td className="p-1 border-b border-blue-gray-50">
                      <p className="block ml-4 mt-2 font-sans text-sm text-blue-gray-900">
                        {index + 1}
                      </p>
                    </td>
                    <td className="p-1 border-b border-blue-gray-50">
                      <p className="block ml-4 mt-2 font-sans text-sm text-blue-gray-900">
                        {item.question_key}
                      </p>
                    </td>
                    <td className="p-1 border-b border-blue-gray-50">
                      <p className="block ml-4 mt-2 font-sans text-sm text-blue-gray-900">
                        {item.questiontype}
                      </p>
                    </td>
                    <td className="p-1 border-b border-blue-gray-50">
                      <p className="block ml-4 mt-2 font-sans text-sm text-blue-gray-900">
                        {item.question_desc}
                      </p>
                    </td>
                    <td className="p-1 border-blue-gray-50 flex justify-end items-center mr-4">
                      <a href="#" className="font-sans text-sm antialiased font-medium leading-normal">
                        <svg onClick={() => dataDelete(item.question_id,type)} xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 16 16" className="bi bi-trash ml-2 mt-0.5" fill="green">
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg>
                      </a>
                    </td>
                  </tr>
                ) : (
                  <tr key={index} className="border-b border-blue-gray-50">
                    <td className="p-1 border-b border-blue-gray-50">
                      <p className="block ml-4 mt-2 font-sans text-sm text-blue-gray-900">
                        {index + 1}
                      </p>
                    </td>
                    <td className="p-1 border-b border-blue-gray-50">
                      <p className="block ml-4 mt-2 font-sans text-sm text-blue-gray-900">
                        {type === "Specialities" ? item.spec_key : type === "Skills" ? item.skill : item.search_key}
                      </p>
                    </td>
                    <td className="p-1 border-blue-gray-50 flex justify-end items-center mr-4">
                      <a href="#" className="font-sans text-sm antialiased font-medium leading-normal ">
                        <svg onClick={() => dataDelete(type === "Specialities" ? item.spec_id : type === "Skills" ? item.skill_id : item.search_id, type)} xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 16 16" className="bi bi-trash ml-2 mt-0.5" fill="green">
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg>
                      </a>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MastersPage;
