import React, { useRef, useState,useEffect ,useContext} from "react";
import Topbar from "../Recruiter/Topbar/Topbar";
// import ProjectsBar from "../elements/ProjectSidebar/ProjectSideBar";
import Card from "../elements/Card/Card";
import Textinput from "../elements/TextInput/TextInput";
import Person from "../../assets/Images/person.jpg"
import Button from "../elements/Button/Button";
import ModalBox from "../elements/ModalBox/ModalBox";
import RadioInput from "../elements/RadioInput/RadioInput";
import { AuthContext } from "../../context/AuthContext";
import CryptoJS from 'crypto-js';
import ProjectCreation from "./ProjectCreation";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation } from "react-router-dom";
import Notification from "../Notification/Notification";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
const Pipeline = () =>{
    const dropdownRef=useRef(null);
    const dropdownRefOne=useRef(null);
    const dropdownRefTwo=useRef(null);
    const dropdownRefThree=useRef(null);
    const location = useLocation();
    const job_id = location.state?.jobId;
    const jobDesc_id=location.state?.jd_id;
    const [jobIdValue,setJobId]=useState(job_id);
    const { token } = useContext(AuthContext);
    const [ResumeDetails,setResumeDetails] = useState([]);
    const [isResumeOption,setResumeOption]=useState(null);
    const [isResumeOpen, setIsResumeOpen] = useState(null);
    const [candidateStatus,setCandidateStatus]=useState("All");
    const[seeLocations,showMoreLocations]=useState(false)
    const [updatedStatus,setUpdatedStatus]=useState('');
    const [candidateType,setType]=useState('');
    const[allCandidates,setAllCandidates]=useState('');
    const[unContacted,setUncontacted]=useState('');
    const [processingMessage, setProcessingMessage] = useState('No resume data available.');
    const[contacted,setContacted]=useState('');
    const[replied,setReplied]=useState('');
    const[seeAssessments,showMoreAssessments]=useState(false)
    const[seeCompanies,showMoreCompanies]=useState(false)
    const[seeKeywords,showMoreKeywords]=useState(false)
    const[seeDegree,showMoreDegrees]=useState(false)
    const[seeRecruiting,showMoreRecruiting]=useState(false)
    const [filteredResults, setFilteredResults] = useState();
    const [pipeID,setPipeID]=useState([])
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const jobTitle = location.state?.job_title;

    const [isProjectCreation,setProjectCreation]=useState('');
    const handleProjectCreation= () =>{
          handleCloseProject();
          setProjectCreation((prev)=>!prev);
    }
        const encryptedMetadataAll = localStorage.getItem('metadata');
      var decryptedMetadataAll;
        if (encryptedMetadataAll){
           decryptedMetadataAll = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadataAll, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        }
    const [notification, setNotification] = useState({ message: "", type: "" });
        
          const showNotification = (message, type) => {   //For Notification
            setNotification({ message, type });
            setTimeout(() => {
              setNotification({ message: "", type: "" });
            }, 3000); // Auto-hide after 3 seconds
          };
    
        const [isOpen, setIsOpen] = useState(false);
      
        const toggleDropdownPipeline = () => {
          setIsOpen((prev) => !prev);
        };
        const toggleDropdownResumeOption = (index) => {
          setIsOpen(true);
          setResumeOption((prev) => prev===index?null:index);
          // console.log(ResumeDetails['891fcc57bccb4fc3a21a2bc84a2f346b']['candidate']['name'])
        };
        const toggleDropdownResumePipeline = (index) => {
          setIsOpen(true);
          setIsResumeOpen((prev) => prev===index?null:index);
        };

        const [saveProject, setSaveProject] = useState(false);
      
        const handleSaveToProject = () => {
          setSaveProject((prev) => !prev);
        };
        const [isOpenMessage, setIsOpenMessage] = useState(false);
      
        const toggleDropdownMessage = () => {
          setIsOpenMessage((prev) => !prev);
        };
        const [filterOpen, setfilter]=useState(false);
        const handleAllFilters = () =>{
          console.log("filter open")
          setfilter(true)
        }
        const handleCloseFilter =()=>{
          setfilter(false)
        }
        const handleCloseProject = () =>{
          setSaveProject(false);
        }
        const handleCloseNewProject = () =>{
          setProjectCreation(false);
        }


        const pipelineListing = async () => {
                  var jobId = job_id;
                  let decryptedMetadata;
                  console.log("jobid: ",jobId);
                  console.log("candidateType val: ",candidateType);
                  const encryptedMetadata = localStorage.getItem('metadata');
                      if (encryptedMetadata){
                          decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                          console.log(decryptedMetadata,"decryptedMetadata")}
                          const encryptedData = CryptoJS.AES.encrypt(
                            JSON.stringify({
                              job_id: jobId,
                              user_id: decryptedMetadata.uid,
                              company_id: decryptedMetadata.cid,
                              ...(candidateType !== "all" ? { type: candidateType } : {}),
                              status:"Active"
                            }),
                            process.env.REACT_APP_SECRETKEY
                          ).toString();
                          
                          var searchData = { data: encryptedData };
                          
                  setIsLoading(true);
                  try {
                      const serverURL = process.env.REACT_APP_SERVER_URL;
                      const response = await fetch(`${serverURL}/job/fetchPipeline`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            token: token
                          },
                          body: JSON.stringify(searchData), 
                      });
                      const result = await response.json();
                      console.log("Server Response pipeline:", result);
                      if (!response.ok) {
                          throw new Error(result.error);
                      }
                      const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                      if(decryptedData['message']){
                          setProcessingMessage(decryptedData['message'])
                          return;
                      }

                     console.log(decryptedData.pipeline,"pipeline data");
                     if(candidateType==="all" || decryptedData.overallCount)
                        setAllCandidates(decryptedData.overallCount)
                     if(candidateType==="uncontacted" || decryptedData.typewiseCount.uncontacted)
                        setUncontacted(decryptedData.typewiseCount.uncontacted)
                     if(candidateType==="contacted" || decryptedData.typewiseCount.contacted)
                        setContacted(decryptedData.typewiseCount.contacted)
                     if(candidateType==="Replied" || decryptedData.typewiseCount.Replied)
                        setReplied(decryptedData.typewiseCount.Replied)

                     console.log(decryptedData.typewiseCount,"type data");
                      if(decryptedData.pipeline.length!==0){
                      decryptedData.pipeline.forEach((item, index) => {
                        console.log("res_data: ", decryptedData.pipeline[index]);
                        setResumeDetails(decryptedData.pipeline);
                        setPipeID(decryptedData.pipeline)
                        setFilteredResults(decryptedData.pipeline);
                        setUpdatedStatus(decryptedData.pipeline[index].type)
                      });}
                      else{
                        setResumeDetails([]);
                      }
                      // console.log("res_data: ", decryptedData[0]["resume"]);
                      
                      // showNotification(result.message, "success");
                  } catch (error) {
                      console.error("Error uploading file:", error);
                      // showNotification(error.message, "error");
                  }finally {
                      setIsLoading(false); // Always hide loader after the request
                  }
        }
        const isResumeDetailsEmpty = !ResumeDetails || Object.keys(ResumeDetails).length < 1;
        var totalResumeCount = 0;
        if(!isResumeDetailsEmpty){
            totalResumeCount = Object.keys(ResumeDetails).length
        }

        const handleClickOutside = (event) => {
          console.log("event: ",event.target);
          if ((isResumeOpen || isResumeOption) &&
          !(
            dropdownRefOne.current?.contains(event.target) ||
            dropdownRefTwo.current?.contains(event.target) ||
            dropdownRefThree.current?.contains(event.target)
          )
        ) {
            setIsResumeOpen(null);
            setResumeOption(null);
            
            setIsOpen(false);
            setIsOpenMessage(false)
          }
          
      };
      const handleStatus=(status,type)=>{
        setCandidateStatus(status)
        setType(type)
        // pipelineListing();
    }
    useEffect(() => {
      if (candidateType) {
        pipelineListing();
      }
      // if(candidateStatus){
      //   pipelineListing();
      // }
    }, [candidateType]);
      
      useEffect(() => {
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
              document.removeEventListener("mousedown", handleClickOutside);
          };
      }, [isOpen,isOpenMessage]);

      useEffect(()=>{
        pipelineListing();
      },[])
    const SearchFront=(<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M17.5 17.5007L13.1692 13.1698M13.1692 13.1698C14.3414 11.9977 14.9999 10.4079 14.9999 8.75023C14.9999 7.09257 14.3414 5.5028 13.1692 4.33065C11.9971 3.1585 10.4073 2.5 8.74962 2.5C7.09196 2.5 5.50219 3.1585 4.33004 4.33065C3.15789 5.5028 2.49939 7.09257 2.49939 8.75023C2.49939 10.4079 3.15789 11.9977 4.33004 13.1698C5.50219 14.342 7.09196 15.0005 8.74962 15.0005C10.4073 15.0005 11.9971 14.342 13.1692 13.1698Z" stroke="black" stroke-opacity="0.3" strokeWidth="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>);

    const AnotherProject=(
      <div>
          <Textinput placeholder="Choose a project" inputPre={SearchFront} inputPreClass="ml-1" className="w-full outline-none flex-grow border-0  text-sm font-[400]" label="Project Name" divclass="flex items-center rounded-[8px] profile_sidebar_progress_text border-[1px]"/>
      </div>
    );
    const SaveProjectFooter=(
      <div className="flex items-center">
          <div className="">
            <p className="mb-0 text-base font-[700] text-[#059669] hover:cursor-pointer" onClick={handleProjectCreation}>Create A New Project</p>
          </div>
          <div className="ml-auto flex gap-4">
            <Button className="text-[#059669] bg-white text-base font-[500] border-[#059669] w-[87px] h-[40px] rounded-[8px]">Cancel</Button>
            <Button className="text-white bg-[#059669] text-base font-[500] w-[70px] h-[40px] rounded-[8px]">Save</Button>
          </div>
      </div>
    )
    const ProjectHeader=(
      <>
        Save To Another Project
      </>
    );
    const Locations=[
      "chennai",
      "kolkata",
      "Banglore",
      "kerala",
      "coimbatore",
      "Delhi",
      "kannur"
    ]
    const assessments=[
      "Project Management",
      "Testing",
      "Programming",
      "Code Development",
      "Web Development",
      "System Design",
    ]

    const Companies=[
      "Amazon",
      "Amazon Logistics",
      "TCS",
      "ZOHO",
      "WIPRO",
      "Atlassian",
      "Google"
    ]
    const Keywords=[
      "Ananya (17)",
      "Jhon Doe",
      "micheal",
      "antony",
      "Raj",
      "David"
    ]
    const Degrees =[
      "B.Tech",
      "Master Degree",
      "Post Graduate",
      "Diploma"
    ]

    const Recruiting = [
      "sample 1",
      "sample 2",
      "sample 3",
      "sample 4",
      "sample 5",
    ]
    
    const ModalBody=(
    <div>
     <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12 p-2 mt-7 !rounded-xl !hidden"
        body={<>
        <Textinput placeholder="Search for a Project" labelclass="hidden" className="!w-full border-2 pl-10 pr-4 py-2.5 !border border-gray-150 rounded-md focus:outline-none focus:ring-2 text-black mt-1 focus:ring-cyan-200"/>
        </>}
      />
      <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12 p-2 mt-7 !rounded-xl"
        body={<>
        <p className="mb-0 font-medium">Job Titles</p>
        <Textinput placeholder="Search for a Job" labelclass="hidden" className="!w-full border-2 pl-10 pr-4 py-2.5 !border border-gray-150 rounded-md focus:outline-none focus:ring-2 text-black mt-1 focus:ring-cyan-200"/>
        </>}
      />
      <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12 p-2 mt-7 !rounded-xl"
        body={<>
        <p className="mb-0 font-medium">Location</p>
                    {
                        Locations.slice(0,3).map((location,index) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={location}
                            postlabelclass="ml-2"
                            key={location}
                            />
                        ))
                    }
                    
                    {seeLocations &&
                    Locations.slice(3).map((location,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={location}
                        postlabelclass="ml-2"
                        key={location}
                        
                        />
                    ))}

                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={()=>showMoreLocations((prev)=>!prev)}>show more...</p>
        </>
        }
      />
      <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12 p-2 mt-7 !rounded-xl"
        body={<>
        <p className="mb-0 font-medium">Skills and assessments</p>
                    {
                        assessments.slice(0,4).map((assessments) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={assessments}
                            postlabelclass="ml-2"
                            key={assessments}
                            />
                        ))
                    }
                    
                    {seeAssessments &&
                    assessments.slice(4).map((assessments,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={assessments}
                        postlabelclass="ml-2"
                        key={assessments}
                        />
                    ))}

                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={()=>showMoreAssessments((prev)=>!prev)}>Show More...</p>
        </>}
      />
      <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12 p-2 mt-7 !rounded-xl"
        body={<>
        <p className="mb-0 font-medium">Companies</p>
                    {
                        Companies.slice(0,2).map((company) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={company}
                            postlabelclass="ml-2"
                            key={company}
                            />
                        ))
                    }
                    
                    {seeCompanies &&
                    Companies.slice(2).map((company,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={company}
                        postlabelclass="ml-2"
                        key={company}
                        />
                    ))}

                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={()=>showMoreCompanies((prev)=>!prev)}>show more...</p>
        </>}
      />
      <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12 p-2 mt-7 !rounded-xl"
        body={<>
        <p className="mb-0 font-medium">Keywords</p>
                    {
                        Keywords.slice(0,2).map((keyword) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={keyword}
                            postlabelclass="ml-2"
                            key={keyword}
                            />
                        ))
                    }
                    
                    {seeKeywords &&
                    Keywords.slice(2).map((keyword,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={keyword}
                        postlabelclass="ml-2"
                        key={keyword}
                        />
                    ))}

                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={()=>showMoreKeywords((prev)=>!prev)}>show more...</p>
        </>}
      />
      <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12 p-2 mt-7 !rounded-xl"
        body={<>
        <p className="mb-0 font-medium">Degrees</p>
                    {
                        Degrees.slice(0,3).map((degree) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={degree}
                            postlabelclass="ml-2"
                            key={degree}
                            />
                        ))
                    }
                    
                    {seeDegree &&
                    Degrees.slice(3).map((degree,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={degree}
                        postlabelclass="ml-2"
                        key={degree}
                        />
                    ))}

                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={()=>showMoreDegrees((prev)=>!prev)}>show more...</p>
                    
        </>}
      />
      <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12 p-2 mt-7 !rounded-xl"
        body={<>
        <p className="mb-0 font-medium">Recruiting</p>
        {
                        Recruiting.slice(0,3).map((degree) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={degree}
                            postlabelclass="ml-2"
                            key={degree}
                            />
                        ))
                    }
                    
                    {seeRecruiting &&
                    Recruiting.slice(3).map((degree,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={degree}
                        postlabelclass="ml-2"
                        key={degree}
                        />
                    ))}
                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={()=>showMoreRecruiting((prev)=>!prev)}>show more...</p>

        
        </>}
      />
      {/* <div className="absolute h-10 inset-y-64 ml-10 flex pt-1.5  pl-3">
                    <svg
                      className="w-5 h-5 text-blue-500"
                      fill="none" 
                      stroke="#9effef"  
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
                      />
                    </svg>
                    
                </div> */}
                <div className="absolute h-10 inset-y-32 ml-10 flex pt-2.5 mt-1  pl-3">
                    <svg
                      className="w-5 h-5 text-blue-500"
                      fill="none" 
                      stroke="#9effef"  
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
                      />
                    </svg>
                    
                </div>
    </div>);


        // const getResponse = async() =>{
        //         var jd_id = jobDesc_id;
        //         const encryptedMetadata = localStorage.getItem('metadata');
        //         // if (encryptedMetadata){
        //         //     const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        //         //     if(decryptedMetadata.jd_id){
        //         //         jd_id = decryptedMetadata.jd_id;
        //         //     }
        //         // }
        //         // setJDvalue(jd_id);
        //         const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({jd_id:jd_id,fromJob:true,job_id:job_id}), process.env.REACT_APP_SECRETKEY).toString();
        //         var searchData = { data:encryptedData}
        //         console.log(searchData,"searchData");
        //         // setIsLoading(true);
        //         try {
        //             const serverURL = process.env.REACT_APP_SERVER_URL;
        //             const response = await fetch(`${serverURL}/JD/getJdDetail`, {
        //                 method: 'POST',
        //                 headers: {
        //                   'Content-Type': 'application/json',
        //                   token: token
        //                 },
        //                 body: JSON.stringify(searchData), 
        //             });
        //             const result = await response.json();
        //             console.log("Server Response:", result);
        //             if (!response.ok) {
        //                 throw new Error(result.error);
        //             }
        //             const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        //             if(decryptedData['message']){
        //                 console.log("message:",decryptedData['message'])
        //                 setProcessingMessage(decryptedData['message'])
        //                 return;
        //             }
        
        //             console.log("resumeDetails: ",decryptedData[jd_id]["resumedetail"])
                    
        //             const recommendedStatus = decryptedData[jd_id]["resumedetail"]
        //             console.log("resumestatus: ",recommendedStatus);
        //             Object.keys(recommendedStatus).forEach((resName)=>{
        //               console.log("resName: ",recommendedStatus[resName].pipeline_type);
        //               setUpdatedStatus(recommendedStatus[resName].pipeline_type)
        //             })
        //             console.log("resumeDetails: ",recommendedStatus)
        //             const keyval=Object.keys(decryptedData);
        //             console.log(keyval.length,"diccc");
        //             // if(keyval.length<1000){
        //             //   setCandidates(keyval.length);
        //             // }
        //             // if(keyval.length>=1000){
        //             //   setCandidates(1200/1000+"k")
        //             // }
        //             // setAiSearchResult(decryptedData[jd_id]);
        //             setResumeDetails(decryptedData[jd_id]['resumedetail'])
        //             // console.log(decryptedData)
        //             // showNotification(result.message, "success");
        //         } catch (error) {
        //             console.error("Error uploading file:", error);
        //             // showNotification(error.message, "error");
        //         }finally {
        //             // setIsLoading(false); // Always hide loader after the request
        //         }
        //       }

    const ModalHeader=(
      <p className="text-black font-medium ml-4 mt-1">All Filters</p>
      
    );
    // const ResumeDetailsSample = {
    //   johnDoe: { name: "John Doe", skills: "React, Node.js", experience: "3 years" },
    //   janeSmith: { name: "Jane Smith", skills: "Python, Django", experience: "5 years" },
    //   mikeBrown: { name: "Mike Brown", skills: "Java, Spring Boot", experience: "4 years" },
    // };
    const [searchInput, setSearchInput] = useState("");
    console.log("filtered data: ",filteredResults)

    const handleSearch = (e) => {
      const value = e.target.value.toLowerCase();
      setSearchInput(value);
      
      const results = Object.entries(ResumeDetails).filter(([key, details]) => {
        if (
          details &&
          details.resume &&
          details.resume.candidate &&
          (
            details.resume.name.toLowerCase().includes(value.toLowerCase()) ||
            details.resume.skills.toLowerCase().includes(value.toLowerCase()) ||
            details.resume.email.toLowerCase().includes(value.toLowerCase())
          )
        ) {
          return true;
        }
        return false;
      });
      console.log("search data: ",results);
      setFilteredResults(results)
    };
    const handlePipelineStatus = async(value,pipelineID) =>{
          const JdData = {
                job_pipeline_id:pipelineID,
                type:value
                
              };
              console.log("jddata:",JdData)
          
              const encryptedData = CryptoJS.AES.encrypt(
                JSON.stringify(JdData),
                process.env.REACT_APP_SECRETKEY
              ).toString();
              const encryptjson = { data: encryptedData };
              
              const userConfirmed = await Swal.fire({
                icon: "warning",
                title: `Are you sure to make change as ${value}?`,
                text: "",
                confirmButtonText: "OK",
                showCancelButton: true,
              });
            
              if (!userConfirmed.isConfirmed) {
                console.log("User canceled pipeline addition.");
                return;
              }
              
              setIsLoading(true);
                try {
                  const response = await fetch(
                    `${process.env.REACT_APP_SERVER_URL}/job/updatePipelineProfileType`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        token: token,
                      },
                      body: JSON.stringify(encryptjson),
                    }
                  );
              
                  const data = await response.json();
              
                  const decryptedData = JSON.parse(
                    CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8)
                  );
                  showNotification(
                    `Candidate added to pipeline as ${value}` ,
                    "success"
                  );
                  console.log(decryptedData, "decrypt");
                  pipelineListing()
                  // getResponse();
                  setClicked(false);
                } catch (error) {
                  console.error("Error occurred:", error);
                }
                finally {
                  setIsLoading(false);
                }
              
              
        }

        const handleHiddenCandidate = async(value,jobPipelineId) =>{
              const HiddenData = {
                  job_pipeline_id: jobPipelineId,
                  status: value            
                  };
                  console.log("jddata:",HiddenData)
              
                  const encryptedData = CryptoJS.AES.encrypt(
                    JSON.stringify(HiddenData),
                    process.env.REACT_APP_SECRETKEY
                  ).toString();
                  const encryptjson = { data: encryptedData };
                  
                  const userConfirmed = await Swal.fire({
                    icon: "warning",
                    title: "Are you sure to Hide This Candidate",
                    text: "The Candidate will be added to hidden Section.",
                    confirmButtonText: "OK",
                    showCancelButton: true,
                  });
                
                  if (!userConfirmed.isConfirmed) {
                    console.log("User canceled pipeline addition.");
                    return;
                  }
                  

                    try {
                      const response = await fetch(
                        `${process.env.REACT_APP_SERVER_URL}/job/updatePipelineProfileStatus`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            token: token,
                          },
                          body: JSON.stringify(encryptjson),
                        }
                      );
                  
                      const data = await response.json();
                  
                      const decryptedData = JSON.parse(
                        CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8)
                      );
                      showNotification(
                        `Candidate added to ${value}` ,
                        "success"
                      );
                      console.log(decryptedData, "decrypt");
                      pipelineListing();
                      // getResponse();
                    } catch (error) {
                      console.error("Error occurred:", error);
                    }
                  
                  
            }
        



    
    return(
       
            <div className="mt-2 pt-4 pl-4 w-[80%] flex bg-white bg-opacity-50  pt-2 mr-2 rounded-xl">
              <Notification
            message={notification.message}
            type={notification.type}
            onClose={() => setNotification({ message: "", type: "" })}
        />
        {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        <span className="text-white mt-4">fetching Details...</span>
                    </div>
                </div>
            )}
                
                <div className="">
                    <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 " CardClass="min-w-md mx-auto bg-white !shadow-md rounded-lg overflow-hidden border-white" CardBodyClass="grid gap-2.5"
                    body={
                        <>
                        <p className={`mb-0 hover:cursor-pointer ${candidateStatus=="All"?"text-green-600 font-medium":"text-black"}`} onClick={()=>handleStatus("All","all")}>All Candidates<span className="ml-20 pl-2.5 ">{allCandidates||0}</span></p>
                        <p className={`mb-0 hover:cursor-pointer ${candidateStatus=="Uncontacted"?"text-green-600 font-medium":"text-black"}`} onClick={()=>handleStatus("Uncontacted","uncontacted")}>Uncontacted<span className="ml-24 pl-2">{unContacted||0}</span></p>
                        <p className={`mb-0 hover:cursor-pointer ${candidateStatus=="Contacted"?"text-green-600 font-medium":"text-black"}`} onClick={()=>handleStatus("Contacted","contacted")}>Contacted<span className="ml-28 pl-2.5">{contacted||0}</span></p>
                        <p className={`mb-0 hover:cursor-pointer ${candidateStatus=="Replied"?"text-green-600 font-medium":"text-black"}`} onClick={()=>handleStatus("Replied","Replied")}>Replied<span className="ml-32  pl-3.5">{replied||0}</span></p>
                        </>
                        }
                    />
                    <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 " CardClass="min-w-md mx-auto bg-white shadow-md rounded-lg overflow-hidden border-white mt-4"
                    body={<>
                    <p className="mt-2 font-medium">Archieved Candidates<span className="ml-10">{`0`}</span></p></>}/>
                </div>
                <div className="w-8/12 ml-8 rounded-xl">
                <div className="flex w-full">
                <div className="w-9/12 relative">
                <Textinput placeholder="Search Candidates" value={searchInput} onChange={handleSearch} labelclass="hidden" className="!w-full border-2 pl-10 pr-4 py-2 !border border-gray-150 rounded-md focus:outline-none focus:ring-2 text-black mt-1 focus:ring-cyan-200"/></div>
                <p className="mb-0 mt-2 ml-20 text-green-700 font-medium hover:cursor-pointer" onClick={handleAllFilters}>All Filters</p>

                <div className="absolute h-10 inset-y-28 flex mt-[18px] pt-[13px] pl-3">
                    <svg
                      className="w-5 h-5 text-blue-500"
                      fill="none" 
                      stroke="#9effef"  
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
                      />
                    </svg>
                    
                </div>
                {/* <div className="results mt-4">
        {filteredResults.length > 0 ? (
          filteredResults.map(([key, details]) => (
            <div key={key} className="p-3 border border-gray-300 rounded-md mb-2">
              <h4 className="font-semibold text-lg">{details.name}</h4>
              <p>Skills: {details.skills}</p>
              <p>Experience: {details.experience}</p>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No results found.</p>
        )}
      </div> */}
                </div>
                {/* <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 " CardClass="min-w-md mx-auto bg-white shadow rounded-lg no-max-width  overflow-hidden mt-4 mb-4 !rounded-xl shadow border-white w-full"
                body={<>
                <div className="flex">
                    <img src={Person} className="w-28 h-28 rounded-full object-cover"></img>
                    <div className="pl-2 absolute ml-6 inset-6 mt-14 h-9 bg-green-50 shadow-lg shadow-green-700  w-20 pb-1  rounded-md ">
                    <div className="relative size-6 mt-1">
                    <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-neutral-700" strokeWidth="4"></circle>

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-teal-400 dark:text-blue-500" strokeWidth="4" stroke-dasharray="100" stroke-dashoffset="6" stroke-linecap="round"></circle>
                    </svg>
    
                    </div>
      
         
                    <div className="absolute rounded-lg inset-2 ml-8 mt-2 mr-2 flex items-center  justify-center  text-gray-700 dark:text-white">
                        
                        <div className="h-14">
                          <p className="mb-0 text-black font-medium mt-1 ml-3">{94}%</p>
                          
                        </div>
                    </div>
                    <p className="text-xs mb-0 absolute inset-y-4 ml-8">Match</p>
                    
                    </div>

                    <div className="ml-6">
                        <p className="text-xl font-medium text-black mt-3 mb-0">Sharad Bhat</p>
                        <p className="text-sm mb-0">UI/UX Designer</p>
                        <p className="text-xs mt-1">Bengaluru,Karnataka</p>
                    </div>
                    <div>
                    <div className="relative inline-block text-left">
      <div className="ml-20 pl-3 mt-4">
        <Button
          type="button"
          className="inline-flex border-[#059669] w-full justify-center gap-x-1.5 rounded-md bg-white px-3  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdownPipeline}
        >
          <p className="mt-2 mb-0 text-green-600">Save to pipeline</p>
          <svg
            className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-green-600"
            viewBox="0 0 20 20"
            fill="green"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
      </div>
      {filterOpen &&(
        <ModalBox Modal_Body={ModalBody} Modal_Header={ModalHeader} modal_Close_Class="mt-2.5 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-6" Modal_Close_Func={handleCloseFilter} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-full !rouded-none !w-96 opacity-none overflow-y-auto" ModalTopClass="!items-right !justify-end "/>
      )}
      {saveProject &&(
        <ModalBox Modal_Body={AnotherProject} Modal_Footer={SaveProjectFooter} Modal_Header={ProjectHeader} modal_Header_Text="text-2xl font-[500] mb-0" modal_Close_Class="mt-3 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-5" Modal_Close_Func={handleCloseProject} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-auto grid gap-[30px] p-[30px] !rouded-xl no-max-width !w-[719px] opacity-none overflow-y-auto"/>
      )}

      {isProjectCreation &&(
        <ModalBox Modal_Body={<ProjectCreation onClose={handleCloseNewProject}/>}  modal_Close_Class="mt-3 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-5" Modal_Close_Func={handleCloseNewProject} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-auto grid gap-[30px] p-[30px] !rouded-xl no-max-width !w-[719px] opacity-none overflow-y-auto"/>
      )}
      
      {isOpen && (
        <div
          className="absolute left-24  z-10 w-8/12 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="p-1 space-y-0.5">
      <a className="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Uncontacted
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Contacted
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Replied
      </a>
      
    </div>
        </div>
      )}
      {isOpenMessage && (
        <div
          className="absolute left-24  z-10 w-10/12 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="p-1 space-y-0.5">
      <a className="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Archieve Candidate
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Add Note
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Share For Review
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" onClick={handleSaveToProject} href="#">
      Save To Another Project
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save To Pdf
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save To Another Project
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Find More People Like This
      </a>
      
    </div>
        </div>
      )}
    </div>
    
                    </div>
                    <Button type="button" className="!bg-gray-100 inline-flex ml-3 h-9 mt-7 justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={toggleDropdownMessage}>
                <img src="/image/message.png" className='w-5' />
            </Button>
            <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" className="inline-flex items-center p-1 h-5 mt-9  text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
<svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
<path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
</svg>
</button>






                </div>
                <div className="mt-4 flex">
                  <p className="ml-2 text-black">Experience</p>
                  <div className="ml-14">
                    <p className="text-xs mb-0">4+ years of experience in UX design</p>
                    <p className="text-black text-sm flex mb-0"><span className="text-green-700 font-medium mr-1">Senior UX Designer</span> at NovaByte Solutions <li className="ml-2 mb-0 text-gray-700">Current</li></p>
                    <p className="text-black text-sm flex mb-0">UX Designer CodeSphere Technologies <li className=" mb-0 text-gray-700 ml-2">2021-2022</li></p>
                    <p className="text-black text-sm flex mb-0">UX Designer BlueShift Dynamics <li className=" mb-0 text-gray-700 ml-2">2019-2021</li></p>
                  </div>
                </div>
                <div className="mt-4 flex">
                  <p className="ml-2 text-black">Education</p>
                  <div className="ml-16">
                    
                    <p className="text-black flex text-sm mb-0">Bachelor of Design, Srishti Manipal Institute<li className=" mb-0 text-gray-700 ml-2">2014-2017</li></p>
                    <p className="text-black flex text-sm mb-0">Masters Strategic Design Management, NID<li className=" mb-0 text-gray-700 ml-2">2017-2019</li></p>
                  </div>
                </div>
                <div className="mt-4 flex">
                  <p className="ml-2 text-black">Skills Match</p>
                  <div className="ml-12">
                    
                    <p className="flex text-sm text-green-700 mb-0 font-medium">User Research <li className="text-sm ml-2">User-Centered Design Thinking</li><li className="ml-2">Problem-solving</li></p>
                  </div>
                </div>
                <div className="mt-1 flex mb-4">
                  <p className="ml-2 text-black">Activity</p>
                  <div className="ml-16">
                    
                    <p className="text-black flex text-sm ml-4 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, Masters StrLorem ipsum dolor sit amet, consectetur adipiscing elit, ategic Design Management, NID</p>
                    
                  </div>
                </div>
                </>}
                /> */}
                <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 " CardClass="min-w-md mx-auto bg-white shadow rounded-lg no-max-width  overflow-hidden mt-4 mb-4 !rounded-xl shadow border-white w-full !hidden"
                body={<>
                <div className="flex">
                    <img src={Person} className="w-28 h-28 rounded-full object-cover"></img>
                    <div className="pl-2 absolute ml-6 inset-6 mt-14 h-9 bg-green-50 shadow-lg shadow-green-700  w-20 pb-1  rounded-md ">
                    <div className="relative size-6 mt-1">
                    <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-neutral-700" strokeWidth="4"></circle>

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-teal-400 dark:text-blue-500" strokeWidth="4" stroke-dasharray="100" stroke-dashoffset="6" stroke-linecap="round"></circle>
                    </svg>
    
                    </div>
      
         
                    <div className="absolute rounded-lg inset-2 ml-8 mt-2 mr-2 flex items-center  justify-center  text-gray-700 dark:text-white">
                        
                        <div className="h-14">
                          <p className="mb-0 text-black font-medium mt-1 ml-3">{94}%</p>
                          
                        </div>
                    </div>
                    <p className="text-xs mb-0 absolute inset-y-4 ml-8">Match</p>
                    
                    </div>

                    <div className="ml-6 hover:cursor-pointer">
                        <p className="text-xl font-medium text-black mt-3 mb-0">Sharad Bhat</p>
                        <p className="text-sm mb-0">UI/UX Designer</p>
                        <p className="text-xs mt-1">Bengaluru,Karnataka</p>
                    </div>
                   <div className="ml-auto mt-4"> 
                    <div className="relative items-center flex text-left">
      
        <Button
          type="button"
          className="inline-flex border-[1px] border-[#059669]  justify-center gap-x-1.5 rounded-md bg-white px-3  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdownPipeline}
        >
          <p className="mt-2 mb-0 text-[#059669]">Save to pipeline</p>
          <svg
            className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-[#059669]"
            viewBox="0 0 20 20"
            fill="#059669"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
      
      
      
      {isOpen && (
        <div
          className="absolute z-10 w-8/12 mt-2 top-9 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
          ref={dropdownRef}
        >
          <div className="p-1 space-y-0.5">
      <a className="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focisOpenus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Uncontacted
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Contacted
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Replied
      </a>
      
    </div>
        </div>
      )}
      {isOpenMessage && (
        <div
          className="absolute left-4 z-10 w-10/12 top-11 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
          ref={dropdownRefOne}
        >
          <div className="p-1 space-y-0.5">
      <a className="flex items-center gap-x-3.5  py-2 px-3  text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Reject Candidate
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Hide Candidate
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Add Note
      </a>
      {/* <a className="flex items-center gap-x-3.5 py-2 px-3   text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" onClick={handleSaveToProject} href="#">
      Save To Another Project
      </a> */}
      <a className="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Share for Review
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#" onClick={handleSaveToProject}>
      Save To Another Project
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save to PDF
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3  text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Find More People Like This
      </a>
      
    </div>
        </div>
      )}
    
    
                    
                    <Button type="button" className="!bg-gray-100  ml-3 h-9 items-center justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={toggleDropdownMessage}>
                <img src="/image/message.png" className='w-5' />
            </Button>
            <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" className="p-1 h-5 mb-1 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
            <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
            </svg>
            </button>
            </div>
            </div>






                </div>
                <div className="grid gap-[32px]">
                <div className="mt-4 flex gap-[32px]">
                    <div className="w-[80px]">
                  <p className="ml-2 text-black mb-0 text-[12px] font-[500]">Experience</p></div>
                  <div>
                    <p className="text-xs opacity-[50%] mb-0 font-[500]">4+ years of experience in UX design</p>
                    <p className="text-black text-xs flex font-[400] mb-0"><span className="text-green-700 font-[700] mr-1">Senior UX Designer</span> at NovaByte Solutions <li className="ml-2 mb-0 text-gray-700">Current</li></p>
                    <p className="text-black text-xs font-[400] flex mb-0">UX Designer CodeSphere Technologies <li className=" mb-0 text-gray-700 ml-2">2021-2022</li></p>
                    <p className="text-black text-xs font-[400] flex mb-0">UX Designer BlueShift Dynamics <li className=" mb-0 text-gray-700 ml-2">2019-2021</li></p>
                  </div>
                </div>
                <div className="flex gap-[32px]">
                    <div className="w-[80px]">
                  <p className="ml-2 text-black mb-0 text-xs font-[500]">Education</p></div>
                  <div>
                    
                    <p className="text-black flex text-xs mb-0">Bachelor of Design, Srishti Manipal Institute<li className=" mb-0 text-gray-700 ml-2">2014-2017</li></p>
                    <p className="text-black flex text-xs mb-0">Masters Strategic Design Management, NID<li className=" mb-0 text-gray-700 ml-2">2017-2019</li></p>
                  </div>
                </div>
                <div className="flex gap-[32px]">
                <div className="w-[80px]">
                  <p className="ml-2 text-xs mb-0 font-[500] text-black">Skills Match</p></div>
                  <div>
                    
                    <p className="flex text-xs text-green-700 mb-0 font-medium">User Research <li className="text-xs ml-2">User-Centered Design Thinking</li><li className="ml-2">Problem-solving</li></p>
                  </div>
                </div>
                <div className="flex mb-4 gap-[32px]">
                    <div className="w-[80px]">
                  <p className="ml-2 text-black mb-0 text-xs font-[500]">Activity</p></div>
                  <div className="w-[381px] justify-between">
                    
                    <p className="text-black flex text-xs mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, Masters StrLorem ipsum dolor sit amet, consectetur adipiscing elit, ategic Design Management, NID</p>
                    
                  </div>
                </div>
                </div>
                </>}
                />
      {filterOpen &&(
        <ModalBox Modal_Body={ModalBody} Modal_Header={ModalHeader} modal_Close_Class="mt-2.5 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-6" Modal_Close_Func={handleCloseFilter} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-full !rouded-none !w-96 opacity-none overflow-y-auto" ModalTopClass="!items-right !justify-end "/>
      )}
      {saveProject &&(
        <ModalBox Modal_Body={AnotherProject} Modal_Footer={SaveProjectFooter} Modal_Header={ProjectHeader} modal_Header_Text="text-2xl font-[500] mb-0" modal_Close_Class="mt-3 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-5" Modal_Close_Func={handleCloseProject} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-auto grid gap-[30px] p-[30px] !rouded-xl no-max-width !w-[719px] opacity-none overflow-y-auto"/>
      )}

      {isProjectCreation &&(
        <ModalBox Modal_Body={<ProjectCreation onClose={handleCloseNewProject}/>}  modal_Close_Class="mt-3 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-5" Modal_Close_Func={handleCloseNewProject} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-auto grid gap-[30px] p-[30px] !rouded-xl no-max-width !w-[719px] opacity-none overflow-y-auto"/>
      )}
                {isResumeDetailsEmpty ? (
            processingMessage == "Data Updation Processing" ? (
              <div className="bg-white m-4 rounded-lg text-center py-32">
      <p>Please wait while we process your request. Resume matching is in progress... Loading.. </p>
      <ClipLoader color="#3498db" size={50} />
    </div>
              ):(
              <div className="bg-white  m-4 rounded-lg text-center py-32">{processingMessage}</div>)
          ) : (
            Object.entries(filteredResults).map(([project, index]) => (
          <Card CardFooterClass=" !bg-[#059669] !rounded-l-none w-3.5/12 ml-auto  justify-end" CardClass="min-w-md mx-auto pl-2 bg-white shadow rounded-lg overflow-hidden mt-4 mb-4 !rounded-3xl shadow-none border w-12/12"
           body={
            <>
            <div>
            <div className="w-full">
              <div className="flex mb-2">
            <p className="text-[24px] font-medium mb-0">{ResumeDetails[project] && ResumeDetails[project]['resume'] && ResumeDetails[project]['resume']['candidate'] && ResumeDetails[project]['resume']['candidate']['name']?ResumeDetails[project]["resume"]["candidate"]["name"]:"Name Not Provided"}</p>
            <div className="pl-2 relative ml-6   h-9 bg-white mt-1 w-20 pb-1  rounded-md ">
                    <div className="relative size-6 mt-1">
                    <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-neutral-700" strokeWidth="4"></circle>

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-teal-400 dark:text-blue-500" strokeWidth="4" stroke-dasharray="100" stroke-dashoffset={100 - (ResumeDetails[project] && ResumeDetails[project]['score'] ? ResumeDetails[project]['score']:"")} stroke-linecap="round"></circle>
                    </svg>
    
                    </div>
      
         
                    <div className="absolute rounded-lg inset-2 ml-8 mt-2 mr-2 flex items-center  justify-center  text-gray-700 dark:text-white">
                        
                        <div className="h-14">
                          <p className="mb-0 text-black font-medium mt-1 ml-3">{ResumeDetails[project] && ResumeDetails[project]['score'] ? ResumeDetails[project]['score']:""}%</p>
                          
                        </div>
                    </div>
                    <p className="text-xs mb-0 absolute inset-y-4 ml-8">Match</p>
                    
                    </div>
            <div className="ml-auto">
      <div className="flex relative" ref={dropdownRef}>
    <div className="pl-8">
    <Button
      type="button"
      className="inline-flex w-[190px] justify-center gap-x-1.5 mr-3 rounded-md bg-white px-3 border-green-500  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      id="menu-button"
      aria-expanded={isOpen}
      aria-haspopup="true"
      onClick={()=>toggleDropdownResumePipeline(index)}
    >
      <p className="mt-2 mb-0 text-[16px] font-medium text-green-600">{ResumeDetails[project]["type"]?ResumeDetails[project]["type"]:"Save to pipeline"}</p>
      <svg
        className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-green-600"
        viewBox="0 0 20 20"
        fill="green"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
          clipRule="evenodd"
        />
      </svg>
    </Button>
  
  

  
  {isResumeOpen===index && (
    <div
      className="absolute left-34  z-10 !w-[190px] mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
      ref={dropdownRefTwo}
    >
      <div class="p-1 space-y-0.5">
  <a class="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3 hover:cursor-pointer  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline"  onClick={()=>handlePipelineStatus("uncontacted",pipeID[project]["job_pipeline_id"])}>
    Uncontacted
  </a>
  <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100 hover:cursor-pointer text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline"  onClick={()=>handlePipelineStatus("contacted",pipeID[project]["job_pipeline_id"])}>
    Contacted
  </a>
  <a class="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:cursor-pointer hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline"  onClick={()=>handlePipelineStatus("Replied",pipeID[project]["job_pipeline_id"])}>
    Replied
  </a>
  
</div>
    </div>
    )}
    </div>
    <Button className="bg-white h-6 mt-2 border-none" onClick={()=>toggleDropdownResumeOption(index)}>
        <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={20}
                height={20}
                className="ml-auto "
                fill="black"
                aria-hidden="true"
                >
                <circle cx="5" cy="12" r="2" />
                <circle cx="12" cy="12" r="2" />
                <circle cx="19" cy="12" r="2" />
                </svg>
                </Button>
{isResumeOption===index && (
    <div
      className="absolute left-[65px] top-12  z-50 !w-[190px] mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
      ref={dropdownRefThree}
    >
      <div class="p-1 space-y-0.5">
  <a class="flex items-center gap-x-3.5  py-2 px-3 hover:cursor-pointer  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline"  onClick={()=>handleHiddenCandidate("hidden",ResumeDetails[project]['job_pipeline_id'])}>
    Hide Candidate
  </a>
  <a class="flex items-center gap-x-3.5  py-2 px-3 hover:cursor-pointer  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline"
  target="_blank"
  href={
    project && ResumeDetails[project]["candidate_id"] && decryptedMetadataAll && decryptedMetadataAll['cid'] && decryptedMetadataAll['uid']
      ? `${process.env.REACT_APP_SERVER_BASEURL}/resumes/${decryptedMetadataAll['cid']}/resume/${decryptedMetadataAll['uid']}/${ResumeDetails[project]["candidate_id"]}.pdf`
      : "#"
  }
  >
    View Resume
  </a>
  
  
</div>
    </div>
    )}
            </div>

                </div>
                </div>
            <p className="text-[14px]">{ResumeDetails[project] && ResumeDetails[project]['resume'] && ResumeDetails[project]['resume']['candidate'] && ResumeDetails[project]['resume']['candidate']['objective']?ResumeDetails[project]['resume']['candidate']['objective']:"Objective Not Provided"}</p>
            <p className="text-[16px] font-semibold mb-0">Contact Details</p>
            
            <p className="flex text-[16px]"><div className="flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" className="mt-2 mr-2" viewBox="0 0 15 11" fill="none">
<path d="M14 2V9C14 9.39782 13.842 9.77936 13.5607 10.0607C13.2794 10.342 12.8978 10.5 12.5 10.5H2.5C2.10218 10.5 1.72064 10.342 1.43934 10.0607C1.15804 9.77936 1 9.39782 1 9V2M14 2C14 1.60218 13.842 1.22064 13.5607 0.93934C13.2794 0.658035 12.8978 0.5 12.5 0.5H2.5C2.10218 0.5 1.72064 0.658035 1.43934 0.93934C1.15804 1.22064 1 1.60218 1 2M14 2V2.162C14 2.4181 13.9345 2.66994 13.8096 2.89353C13.6848 3.11712 13.5047 3.30502 13.2867 3.43933L8.28667 6.516C8.05014 6.66169 7.7778 6.73883 7.5 6.73883C7.2222 6.73883 6.94986 6.66169 6.71333 6.516L1.71333 3.44C1.49528 3.30569 1.31525 3.11779 1.19038 2.8942C1.06551 2.6706 0.999972 2.41876 1 2.16267V2" stroke="#059669" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
              
              <p className="mt-[1px]">{ResumeDetails[project] && ResumeDetails[project]['resume'] && ResumeDetails[project]['resume']['candidate'] && ResumeDetails[project]['resume']['candidate']['email']?ResumeDetails[project]['resume']['candidate']['email']:"Email Not Provided"}</p></div>
<div className="flex ml-4">
<svg xmlns="http://www.w3.org/2000/svg" className="mt-1.5 mr-2" width="15" height="15" viewBox="0 0 15 15" fill="none">
<path d="M1 4C1 9.52267 5.47733 14 11 14H12.5C12.8978 14 13.2794 13.842 13.5607 13.5607C13.842 13.2794 14 12.8978 14 12.5V11.5853C14 11.2413 13.766 10.9413 13.432 10.858L10.4833 10.1207C10.19 10.0473 9.882 10.1573 9.70133 10.3987L9.05467 11.2607C8.86667 11.5113 8.542 11.622 8.248 11.514C7.15659 11.1128 6.16544 10.4791 5.34319 9.65681C4.52094 8.83457 3.88725 7.84341 3.486 6.752C3.378 6.458 3.48867 6.13333 3.73933 5.94533L4.60133 5.29867C4.84333 5.118 4.95267 4.80933 4.87933 4.51667L4.142 1.568C4.10143 1.4058 4.00781 1.2618 3.87604 1.15889C3.74426 1.05598 3.58187 1.00006 3.41467 1H2.5C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V4Z" stroke="#059669" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  <p>{ResumeDetails[project] && ResumeDetails[project]['resume'] && ResumeDetails[project]['resume']['candidate'] && ResumeDetails[project]['resume']['candidate']['phone_number']?ResumeDetails[project]['resume']['candidate']['phone_number']:"Phone No Not Provided"}</p>
  </div></p></div>
    
  </div>
            </>
           }
           footer={
            <>
            <p className="mb-0 text-white">From Resumebox</p>
            </>
           }
          />
          )))}
                {/* <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 " CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden mt-4 mb-4 !rounded-xl shadow border-white w-full"
                body={<>
                <div className="flex">
                    <img src={Person} className="w-28 h-28 rounded-full object-cover"></img>
                    <div className="pl-2 absolute ml-6 inset-6 mt-14 h-9 bg-green-50 shadow-lg shadow-green-700  w-20 pb-1  rounded-md ">
                    <div className="relative size-6 mt-1">
                    <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-neutral-700" strokeWidth="4"></circle>

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-teal-400 dark:text-blue-500" strokeWidth="4" stroke-dasharray="100" stroke-dashoffset="6" stroke-linecap="round"></circle>
                    </svg>


                    
                    </div>
      
        


                    
                   

                    
                    <div className="absolute rounded-lg inset-2 ml-8 mt-2 mr-2 flex items-center  justify-center  text-gray-700 dark:text-white">
                        
                        <div className="h-14">
                          <p className="mb-0 text-black font-medium mt-1 ml-3">{94}%</p>
                          
                        </div>
                    </div>
                    <p className="text-xs mb-0 absolute inset-y-4 ml-8">Match</p>
                    
                    </div>

                    <div className="ml-6">
                        <p className="text-xl font-medium text-black mt-3 mb-0">Sharad Bhat</p>
                        <p className="text-sm mb-0">UI/UX Designer</p>
                        <p className="text-xs mt-1">Bengaluru,Karnataka</p>
                    </div>
                    <div>
                    <div className="relative inline-block text-left">
      <div className="ml-20 pl-3 mt-4">
        <Button
          type="button"
          className="inline-flex  w-full justify-center gap-x-1.5 rounded-md bg-white px-3  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdownPipeline}
        >
          <p className="mt-2 mb-0 text-green-600">Save to pipeline</p>
          <svg
            className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-green-600"
            viewBox="0 0 20 20"
            fill="green"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
      </div>

      
      {isOpen && (
        <div
          className="absolute left-24  z-10 w-8/12 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="p-1 space-y-0.5">
      <a className="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Uncontacted
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Contacted
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Replied
      </a>
      
    </div>
        </div>
      )}
      {isOpenMessage && (
        <div
          className="absolute left-24  z-10 w-10/12 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="p-1 space-y-0.5">
      <a className="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Archieve Candidate
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Add Note
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Share For Review
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save To Another Project
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save To Pdf
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save To Another Project
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Find More People Like This
      </a>
      
    </div>
        </div>
      )}
    </div>
    
                    </div>
                    <Button type="button" className="!bg-gray-100 inline-flex ml-3 h-9 mt-7 justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={toggleDropdownMessage}>
                <img src="/image/message.png" className='w-5' />
            </Button>
            <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" className="inline-flex items-center p-1 h-5 mt-9  text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
<svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
<path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
</svg>
</button>






                </div>
                <div className="mt-4 flex">
                  <p className="ml-2 text-black">Experience</p>
                  <div className="ml-14">
                    <p className="text-xs mb-0">4+ years of experience in UX design</p>
                    <p className="text-black text-sm flex mb-0"><span className="text-green-700 font-medium mr-1">Senior UX Designer</span> at NovaByte Solutions <li className="ml-2 mb-0 text-gray-700">Current</li></p>
                    <p className="text-black text-sm flex mb-0">UX Designer CodeSphere Technologies <li className=" mb-0 text-gray-700 ml-2">2021-2022</li></p>
                    <p className="text-black text-sm flex mb-0">UX Designer BlueShift Dynamics <li className=" mb-0 text-gray-700 ml-2">2019-2021</li></p>
                  </div>
                </div>
                <div className="mt-4 flex">
                  <p className="ml-2 text-black">Education</p>
                  <div className="ml-16">
                    
                    <p className="text-black flex text-sm mb-0">Bachelor of Design, Srishti Manipal Institute<li className=" mb-0 text-gray-700 ml-2">2014-2017</li></p>
                    <p className="text-black flex text-sm mb-0">Masters Strategic Design Management, NID<li className=" mb-0 text-gray-700 ml-2">2017-2019</li></p>
                  </div>
                </div>
                <div className="mt-4 flex">
                  <p className="ml-2 text-black">Skills Match</p>
                  <div className="ml-12">
                    
                    <p className="flex text-sm text-green-700 mb-0 font-medium">User Research <li className="text-sm ml-2">User-Centered Design Thinking</li><li className="ml-2">Problem-solving</li></p>
                  </div>
                </div>
                <div className="mt-1 flex mb-4">
                  <p className="ml-2 text-black">Activity</p>
                  <div className="ml-16">
                    
                    <p className="text-black flex text-sm ml-4 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, Masters StrLorem ipsum dolor sit amet, consectetur adipiscing elit, ategic Design Management, NID</p>
                    
                  </div>
                </div>
                </>}
                /> */}
                </div>
                
            </div>
    );
}
export default Pipeline;
