import React, { useEffect, useState, useContext, useRef, use } from "react";
import Topbar from "../Recruiter/Topbar/Topbar";
import Textinput from "../elements/TextInput/TextInput";
import Button from "../elements/Button/Button";
import Card from "../elements/Card/Card";
import Aerolite from "../../assets/Images/Aerolite.png"
import ModalBox from "../elements/ModalBox/ModalBox";
import Selectbox from "../elements/SelectBox/SelectBox";
import Textarea from "../elements/TextArea/TextArea";
import Edit from "../../assets/Images/edit.jpg"
import { AuthContext } from "../../context/AuthContext";
import CryptoJS from 'crypto-js';
import Notification from "../Notification/Notification";
import ClipLoader from "react-spinners/ClipLoader";
// import { set } from "mongoose";

const Companyoverview = () => {
    const { token } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [companyName, setName] = useState('');
    const [companyProfile, setProfile] = useState('');
    const [companyProfilefullpath, setProfilefullpath] = useState('');
    const [companyOverview, setOverview] = useState('');
    const [companyWebsite, setWebsite] = useState('');
    const [companyIndustry, setIndustry] = useState('');
    const [companyIndustryVal, setIndustryval] = useState(0)
    const [companySize, setSize] = useState('');
    const [companySizeVal, setSizeval] = useState(0)
    const [companyHeadquarters, setHeadquarters] = useState('');
    const [companyFounded, setFounded] = useState('');
    const [companySpecialities, setCompanySpecialities] = useState([]);
    const [companyAddress, setAddress] = useState('');
    const [companyLocation, setLocation] = useState('');
    const [companyAbout, setAbout] = useState('')
    const formcompany = useRef(null)
    const formcompanysize = useRef(null)
    const formHeadquarter = useRef(null)
    const formIndustry = useRef(null)
    const formAddress = useRef(null)
    const formLocation = useRef(null)
    const formAbout = useRef(null)
    const formWebsite = useRef(null)
    const formFounded = useRef(null)
    const formSpecialties = useRef(null)
    const [suggestions, setSuggestions] = useState([]);
    const [defaultSuggestions, setDefaultSuggestions] = useState([
            "Talent Acquisition",
            "Full Cycle Recruiting",
            "Recruitment Marketing",
            "Global Talent Acquisition",
            "Employee Retention Strategies",
            "Custom"
        ]);
    const [selectedSpecialty, setSelectedSpecialty] = useState(null); // Track highlighted suggestion
    const [Specialitiesdefault, setSpecialitiesdefault] = useState([]);
    const fetchCompanyDetails = async () => {
        // setIsLoading(true);
        try {
            const serverURL = process.env.REACT_APP_SERVER_URL;
            const response = await fetch(`${serverURL}/company/fetchCompanyDetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    token: token
                },
            });
            const data = await response.json();

            const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            console.log("Company Details: ", decryptedData);
            if (!response.ok) {
                var errorMessage = "Something Went Wrong";
                if (data.error)
                    errorMessage = data.error;
                showNotification(errorMessage, "error");
                throw new Error(data.error);
            }
            else {               
                //   companyName.current.value=decryptedData[0]['name'];
                setName(decryptedData[0]['name']);
                setProfile(decryptedData[0]['profile'])
                setProfilefullpath(`${process.env.REACT_APP_SERVER_BASEURL}/companyprofile/${decryptedData[0]['profile']}`)
                setOverview(decryptedData[0]['about'])
                setWebsite(decryptedData[0]['url'])
                setHeadquarters(decryptedData[0]['headquarters'])
                setFounded(decryptedData[0]['founded_year'])
                setSizeval(decryptedData[0]['size'])
                setIndustryval(decryptedData[0]['industry'])
                setLocation(decryptedData[0]['location'])
                setAbout(decryptedData[0]['about'])
                setCompanySpecialities(decryptedData[0]['specialities'] || [])
                console.log("name: ", companyName)
                if (decryptedData[0]['industry']) {
                    for (var i in Industry) {
                        if (Industry[i]['id'] == decryptedData[0]['industry']) {
                            setIndustry(Industry[i]['label'])
                        }
                    }
                }
                if (decryptedData[0]['size']) {
                    for (var i in Companysize) {
                        if (Companysize[i]['id'] == decryptedData[0]['size']) {
                            setSize(Companysize[i]['label'])
                        }
                    }
                }
            }
        } catch (error) {
            console.log('Error:', error);
            let errorMessage = "Something went wrong.";
            if (error.response && error.response.data && error.response.data.msg) {
                errorMessage = error.response.data.msg;
            }
            else if (error.message) {
                errorMessage = error.message;
            }
            //   showNotification(errorMessage, "error");
        } finally {
            //   setIsLoading(false); // Always hide loader after the request
        }
    }
    useEffect(() => {
        fetchCompanyDetails()
    }, [])

    const UploadImageTemp = async (file) => {
        setIsLoading(true);

        if (!file) return;

        const formData = new FormData();
        formData.append("file", file);
        try {
            const serverURL = process.env.REACT_APP_SERVER_URL;
            const response = await fetch(`${serverURL}/signUpImageTemp`, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                throw new Error('Failed to upload file');
            }

            const result = await response.json();
            var result_path = result && result['files'] && result['files']['file'] && result['files']['file'][0] ? result['files']['file'][0] : '';
            var newpath = result_path && result_path['newFilename'] ? result_path['newFilename'] : companyProfile
            setProfile(newpath)
            setProfilefullpath(`${process.env.REACT_APP_SERVER_BASEURL}/images/temp/${newpath}`)
            setIsLoading(false);

        } catch (error) {
            console.error("Error uploading file:", error);
            setIsLoading(false);
        }
    };

    const [notification, setNotification] = useState({ message: "", type: "" });
    const showNotification = (message, type) => {   //For Notification
        setNotification({ message, type });
        setTimeout(() => {
            setNotification({ message: "", type: "" });
        }, 3000);
    };

    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const Sampleopen = () => setIsModalOpen(true);
    const closeForm = () => {
        setIsModalOpen(false)
        fetchCompanyDetails()
    };
    const profile_navbar_dropdown = () => {                   //Dropdown in navbar
        setIsOpen(!isOpen);
    };
    const [newSpecialty, setNewSpecialty] = useState('');
    const [showInput, setShowInput] = useState(false);
   
    const handleCustomSpecialty = () => {
        if (newSpecialty.trim()) {
            setCompanySpecialities([...companySpecialities, newSpecialty.trim()]);
            setNewSpecialty("");
            setShowInput(false);
        }
    };

    const handleSuggestionClick = (specialty) => {
        if (companySpecialities.includes(specialty)) {
            setCompanySpecialities(companySpecialities.filter((item) => item !== specialty));
        } else {
            setCompanySpecialities([...companySpecialities, specialty]);
        }
    };

    const removeSpecialty = (specialty) => {
        setCompanySpecialities(companySpecialities.filter((item) => item !== specialty));
    };

    const fetchSuggestions = async () => {
        const encryptedMetadata = localStorage.getItem("metadata");
        let company_id = "";
        if (encryptedMetadata) {
            const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            company_id = decryptedMetadata?.cid || "";
        }
              
        const serverURL = process.env.REACT_APP_SERVER_URL;
        const jsonData = { cid: company_id, type: "Specialities" };
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData), process.env.REACT_APP_SECRETKEY).toString();
              
        setIsLoading(true);
        try {
            const response = await fetch(`${serverURL}/masters/selectData`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token,
                },
                body: JSON.stringify({ data: encryptedData }),
            });
              
            const data = await response.json();
              
            if (data.status !== "success") {
                showNotification(data.message || "Failed to fetch data.", "error");
                return;
            }
              
            let decryptedData = data.data;
            if (typeof data.data === "string") {
                try {
                    decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                } catch (decryptionError) {
                    showNotification("Failed to decrypt data.", "error");
                    return;
                }
            }
            const fetchedSuggestions = decryptedData.map((item) => item.spec_key);
            setSuggestions([...fetchedSuggestions, "Custom"]);
        } catch (error) {
            showNotification("Something went wrong while loading data.", "error");
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchSuggestions();
    }, []);
    
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const file = e.dataTransfer.files[0];
        if (file) {
            UploadImageTemp(file);
        }
    };
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            UploadImageTemp(file);
        }
    };    
    const company_input = (e) => {
        const input = e.target.value.replace(/[^a-zA-Z.\s]/g, '');
        if (input)
            formcompany.current.classList.remove("border-red-600");
        setName(input)
    }

    const form_company_size = () => {
        formcompanysize.current.classList.remove("border-red-600");
        setSizeval(formcompanysize.current.value)
    }

    const form_Headquarters = () => {
        formHeadquarter.current.classList.remove("border-red-600");
        setHeadquarters(formHeadquarter.current.value)
    }

    const form_Industry = () => {
        formIndustry.current.classList.remove("border-red-600");
        setIndustryval(formIndustry.current.value)
    }

    const form_Address = () => {
        formAddress.current.classList.remove("border-red-600");
        setAddress(formAddress.current.value)
    }

    const form_location = () => {
        formLocation.current.classList.remove("border-red-600");
        setLocation(formLocation.current.value)
    }

    const form_about = () => {
        formAbout.current.classList.remove("border-red-600");
        setAbout(formAbout.current.value)
    }

    const form_website = () => {
        formWebsite.current.classList.remove("border-red-600");
        setWebsite(formWebsite.current.value)
    }

    const form_Founded = (e) => {
        const input = e.target.value.replace(/\D/g, '');
        if (input)
            formFounded.current.classList.remove("border-red-600");
        setFounded(input)
    }
    const updateForm = async () => {
        var update_company = formcompany.current.value;
        var update_company_size = formcompanysize.current.value;
        var update_company_headquarter = formHeadquarter.current.value;
        var update_company_industry = formIndustry.current.value;
        var update_company_address = formAddress.current.value;
        var update_company_location = formLocation.current.value;
        var update_company_about = formAbout.current.value;
        var update_company_website = formWebsite.current.value;
        var update_company_founded = formFounded.current.value;
        const update_specialties = JSON.stringify(companySpecialities);
        var update_company_profile = companyProfile;
        if (!update_company) {
            showNotification("Enter Company Name","error");
            formcompany.current.classList.add("border-red-600");
            return;
        }
        if (!update_company_size || update_company_size == 0) {
            showNotification("Select Company Size","error");
            formcompanysize.current.classList.add("border-red-600");
            return;
        }
        if (!update_company_headquarter) {
            showNotification("Enter Headquarter","error");
            formHeadquarter.current.classList.add("border-red-600");
            return;
        }
        if (!update_company_industry || update_company_industry == 0) {
            showNotification("Select Industry","error");
            formIndustry.current.classList.add("border-red-600");
            return;
        }
        if (!update_company_address) {
            showNotification("Enter Address","error");
            formAddress.current.classList.add("border-red-600");
            return;
        }
        if (!update_company_location) {
            showNotification("Enter Location","error");
            formLocation.current.classList.add("border-red-600");
            return;
        }
        if (!update_company_about) {
            showNotification("Enter About","error");
            formAbout.current.classList.add("border-red-600");
            return;
        }
        if (!update_company_website) {
            showNotification("Enter Website","error");
            formWebsite.current.classList.add("border-red-600");
            return;
        }
        if (!update_company_founded) {
            showNotification("Enter Founded","error");
            formFounded.current.classList.add("border-red-600");
            return;
        }
        if (!update_specialties) {
            showNotification("Enter Specialties","error");
            formSpecialties.current.classList.add("border-red-600");
            return;
        }
        if (!update_company_profile) {
            showNotification("Upload Company Logo","error");
            return;
        }

        const companyData = { name: update_company, size: update_company_size, head_quarters: update_company_headquarter, industry: update_company_industry, address: update_company_address, location: update_company_location, description: "nill", about: update_company_about, url: update_company_website, founded_year: update_company_founded,specialities: update_specialties, profile: update_company_profile };
        const encryptedMetadata = localStorage.getItem('metadata');
        if (encryptedMetadata) {
            const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            if(decryptedMetadata && decryptedMetadata.cid){
                companyData.company_id = decryptedMetadata.cid;
            }
        }
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(companyData), process.env.REACT_APP_SECRETKEY).toString();
        const serverURL = process.env.REACT_APP_SERVER_URL;
        var encryptjson = { data: encryptedData }
        setIsLoading(true);
        try {
            const response = await fetch(`${serverURL}/company/updateCompany`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    token: token
                },
                body: JSON.stringify(encryptjson),
            });
            const data = await response.json();
            if (!response.ok) {
                var errorMessage = "Something Went Wrong";
                if(data.error)
                    errorMessage = data.error;
                showNotification(errorMessage,"error");
                throw new Error(data.error);
            }
            var notificationStatus = ''
            var notificationContent = ''
            if (data.status) {
                notificationStatus = data.status;
            }
            if (data.message) {
                notificationContent = data.message;
            }
            if (data.error) {
                notificationContent = data.error;
            }
            showNotification(notificationContent, notificationStatus);
            if (response.ok) {
                console.log(response,"response");
                setIsModalOpen(false);
                await fetchCompanyDetails()
            } else {
                throw new Error(data.error || "Error");
            }
        } catch (error) {
            console.log('Error:', error);
            let errorMessage = "Something went wrong.";
            if (error.response && error.response.data && error.response.data.msg) {
                errorMessage = error.response.data.msg;
            } 
            else if (error.message) {
                errorMessage = error.message;
            }
            showNotification(errorMessage, "error");
        }
        finally {
            setIsLoading(false);
        }
    }

    const Companysize = [
        { id: '0', label: 'Select Size' },
        { id: '1', label: 'Small (1-10 employees)' },
        { id: '2', label: 'Medium (11-50 employees)' }
    ];
    const Industry = [
        { id: '0', label: 'Select Industry' },
        { id: '1', label: ' Information Technology Consulting & Professional Services' },
        { id: '2', label: 'Finance, Banking & FinTech' },
        { id: '3', label: 'Accounting' },
        { id: '4', label: ' Agriculture & Forestry' },
        { id: '5', label: 'Mining & Extraction' },
        { id: '6', label: 'Construction & Infrastructure' },
        { id: '7', label: 'Manufacturing' },
        { id: '8', label: 'Wholesale & Distribution' },
        { id: '9', label: 'Retail & E-Commerce' },
        { id: '10', label: 'Transportation & Logistics' },
        { id: '11', label: ' Utilities & Energy' },
        { id: '12', label: 'Telecommunications' },
        { id: '13', label: 'Real Estate & Property' },
        { id: '14', label: ' Marketing, Advertising & PR' },
        { id: '15', label: 'Media & Entertainment' },
        { id: '16', label: 'Hospitality & Travel' },
        { id: '17', label: ' Healthcare & Pharmaceuticals' },
        { id: '18', label: 'Education & Training' },
        { id: '19', label: 'Nonprofit & Social Services' },
        { id: '20', label: ' Government & Public Administration' },
        { id: '21', label: 'Defense & Aerospace' },
        { id: '22', label: 'Environmental & Sustainability' },
        { id: '23', label: 'Automotive & Transportation Equipment' },
        { id: '24', label: 'Chemical & Petrochemical' },
        { id: '25', label: 'Other / Miscellaneous' }
    ];

    const filteredSuggestions = suggestions?.filter((item) => item !== "Custom") || [];
    const displayedSuggestions = filteredSuggestions.length > 0 ? suggestions : defaultSuggestions;
        const modalBodyOverview = (
            
            <div className="w-3/4 ml-9">

            <p className="ml-6 text-black text-md mt-6">Upload Company Logo<span className="text-red-600">*</span></p>

            <div className="flex ml-6 items-center justify-center w-full" onDragOver={handleDragOver} onDrop={handleDrop}>
                <label for="dropzone-file" className="flex items-center w-full h-22 border-2 border-green-300 border-dashed rounded-lg cursor-pointer bg-blue-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    <img src={Edit} className="w-6 h-6 border-2 ml-12 fixed-0 mb-10 mr-3 absolute"></img>
                    <img src={companyProfilefullpath ? companyProfilefullpath : Aerolite} className="w-14 h-14 border-2 ml-4 mt-2 mb-2 mr-3 rounded-xl"></img>
                    <div className="flex flex-col items-center justify-center pt-2 pb-2">

                        <p className="mt-3 ml-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold  text-green-500 mr-1">Edit or Click here</span>to upload new Logo</p>

                    </div>
                    <input id="dropzone-file" onChange={handleFileChange}  type="file" className="hidden" />
                </label>
            </div>
            <Textinput label="Company Name" labelclass="ml-6 mb-2 mt-4" InputRef={formcompany} OnInput={company_input} span="*" value={companyName} className="border-1 border-[#BFE0E2] ml-6 w-full p-2.5 rounded-lg text-md" />
            <Selectbox
                span="*"
                label="Company Size"
                labelClass="ml-6 mt-4 mb-2"
                name="exampleSelect"
                value={companySizeVal}
                list={Companysize}
                InputRef={formcompanysize}
                onChange={form_company_size}
                className="ml-6 p-2.5"
                required
            />
            <Textinput label="Headquarter" OnInput={form_Headquarters} InputRef={formHeadquarter} labelclass="ml-6 mb-2 mt-4" span="*" value={companyHeadquarters} className="border-1 border-[#BFE0E2] ml-6 w-full  p-2.5 rounded-lg text-md" />
            <Selectbox
                span="*"
                label="Industry"
                labelClass="ml-6 mt-4 mb-2"
                name="exampleSelect"
                value={companyIndustryVal}
                list={Industry}
                InputRef={formIndustry}
                onChange={form_Industry}
                className="ml-6 p-2.5"
                required
            />
            <Textarea label="Address" OnInput={form_Address} InputRef={formAddress} labelClass="ml-6 mt-4 mb-2" span="*" value={companyAddress ? companyAddress : 'XYZ, Street 123, Bengaluru, 558946'} className="resize-none px-1 ml-6" />
            <div className="flex ml-6 w-full">
                <div className="w-7/12">
                    <Textinput className="w-full mt-2 p-2.5 rounded-lg text-md" OnInput={form_location} InputRef={formLocation} value={companyLocation} labelclass="mt-9" label="Location URL" span="*" />
                </div>
                <div className="mt-[4.3%] flex">
                    <p className="p-1 bg-gray-50 w-6 h-6 ml-4 mt-16 text-xs rounded-full text-gray-500">Or</p>
                    <Button className="bg-teal-50 text-green-700 font-normal text-xs pt-2 pb-2 h-10 mt-14 pl-2 ml-3 rounded-lg pr-2 ">Current Location</Button>
                </div>
            </div>
            <Textarea label="About" OnInput={form_about} InputRef={formAbout} span="*" labelClass="ml-6 mt-4 mb-2" value={companyAbout} className="border-1 border-[#BFE0E2]  ml-6 w-full p-2 rounded-lg text-md resize-none" />
            <Textinput label="Company Website Link" OnInput={form_website} InputRef={formWebsite} value={companyWebsite} className="border-1 border-[#BFE0E2] ml-6 w-full p-2.5 rounded-lg text-md" span="*" labelclass="ml-6 mt-4 mb-2" />
            <Textinput label="Founded" OnInput={form_Founded} InputRef={formFounded} value={companyFounded} className="border-1 border-[#BFE0E2] ml-6 w-full p-2.5 rounded-lg " span="*" labelclass="ml-6 mt-4 mb-2" />
            <label className="ml-6 mt-4 mb-2">Specialities</label>
                <div className="border-1 border-blue-200 w-full min-h-10 max-h-fit ml-6 rounded-lg p-2 flex flex-wrap gap-x-3 gap-y-2">
                    {companySpecialities.length > 0 ? (
                        companySpecialities.map((speciality, index) => (
                            <span key={index} className="flex items-center p-1 rounded-lg text-xs text-gray-700 bg-teal-50 border-2 border-gray-200">{speciality}
                                <Button onClick={() => removeSpecialty(speciality)} className="ml-2 text-black !bg-teal-50 border-none font-bold hover:text-red-500">
                                ×
                                </Button>
                            </span>
                        ))
                    ) : (
                        <span className="text-gray-500 text-sm">No Specialities available</span>
                    )}
                </div>

            <label className="ml-6 mt-4 mb-2">Suggestions</label>
                <div className="border border-[#BFE0E2] w-full min-h-10 max-h-fit ml-6 rounded-lg p-2 flex flex-wrap gap-x-3 gap-y-2">
                <div>
                {displayedSuggestions.map((speciality, index) =>
                        speciality === "Custom" ? (
                            showInput ? (
                                <div key={index} className="flex items-center">
                                    <input type="text" value={newSpecialty} onChange={(e) => setNewSpecialty(e.target.value)} className="border-2 border-gray-200 px-3 py-1 rounded-lg text-xs"placeholder="Add custom specialty"/>
                                    <Button onClick={() => handleCustomSpecialty(newSpecialty)} className="px-3 py-1 ml-2 text-xs bg-teal-100 text-teal-700 border-teal-300">Save</Button>
                                </div>
                            ) : (
                                <Button key={index} onClick={() => setShowInput(true)} className="px-3 py-1 m-1 border-2 bg-white rounded-lg text-xs text-gray-700 hover:border-teal-400">{speciality}</Button>
                            )
                        ) : (
                            <Button key={index} onClick={() => handleSuggestionClick(speciality)} className={`px-3 py-1 m-1 border-2 bg-white rounded-lg text-xs cursor-pointer ${companySpecialities.includes(speciality)? "border-teal-300 bg-teal-100 text-teal-700": "border-teal-100 text-gray-700"}`}>
                                {speciality}
                            </Button>
                        )
                    )}
                </div>
            </div>
    </div>
    )

    const ModalFooterOverview = (<>
        <div className="mb-4 mt-10 ml-3">
            <Button className=" border-1 border-green-800 bg-white mt-2 text-sm text-green-700 w-1/4 ml-12 pt-2 pb-2 pl-8 pr-8 rounded outline-none"
                onClick={closeForm}>Cancel</Button>
            <Button className=" border-1 border-green-800 bg-green-700 text-sm text-white w-[25%] ml-4 pt-2 pb-2  rounded outline-none"
                onClick={updateForm}>Save</Button>
        </div>
    </>
    )
    
    return (
        <>
            <Notification
                message={notification.message}
                type={notification.type}
                onClose={() => setNotification({ message: "", type: "" })}
            />
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        <span className="text-white mt-4">Company Adding...</span>
                    </div>
                </div>
            )}
            <Card CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-4/6 mt-10 mb-10 border-white shadow" CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2"
                body={
                    <>
                        <diV className="flex border-b-2 border-gray-100">
                            <div className="flex justify-between w-full mr-4">
                                <div className="flex">
                                    <img src={companyProfilefullpath ? companyProfilefullpath : Aerolite} className="w-14 h-14 border-2 mt-3 mr-3 mb-3 rounded-xl"></img>
                                        <p className="text-xl mt-3 font-normal text-black dark:text-white">
                                        {companyName ? companyName : "Aerolite"}
                                        {/* <div className="flex w-full">
                                            <p className="text-xs p-1 rounded-lg bg-sky-50">MNC</p>
                                            <p className="text-xs p-1 ml-2 rounded-lg bg-sky-50">Software</p>
                                            <p className="text-xs p-1 ml-2 rounded-lg bg-sky-50">Bengaluru</p>
                                            <p className="text-xs p-1 ml-2 rounded-lg bg-sky-50">Software</p>
                                        </div> */}
                                    </p>
                                </div>
                                <div className="flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" onClick={Sampleopen} className="ml-20  mt-8 cursor-pointer" viewBox="0 0 24 24" fill="green">
                                        <path d="M13.0207 5.82839L15.8491 2.99996L20.7988 7.94971L17.9704 10.7781M13.0207 5.82839L3.41405 15.435C3.22652 15.6225 3.12116 15.8769 3.12116 16.1421V20.6776H7.65669C7.92191 20.6776 8.17626 20.5723 8.3638 20.3847L17.9704 10.7781M13.0207 5.82839L17.9704 10.7781" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    {isModalOpen && (
                                        <div>
                                            <ModalBox Modal_Body={modalBodyOverview} Modal_Footer={ModalFooterOverview} Modal_Close_Func={closeForm} Modal_Header="Edit Company Profile" modal_Header_Text="pl-4 m-0" modal_Header_Class="border-gray-300 border-b-2 z-50 bg-white font-medium sticky top-0 py-3 flex items-center" modal_Close_Class="border-2 border-gray-300 rounded-lg pl-2 pt-1 pb-1 pr-2" cardClass="bg-white rounded-2xl shadow-lg overflow-y-scroll w-2/4 h-[95%] p-0  max-w-xl" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </diV>
                        <p className="text-black mt-4">Overview<p className="text-gray-500 text-sm mt-2">{companyOverview ? companyOverview : "Aerolite is a leading development company specializing in cutting-edge solutions for web, mobile, and cloud-based applications. With a focus on innovation and quality, Aerolite partners with global clients to deliver high-performance software products tailored to their needs. Our team of experts excels in crafting scalable, efficient, and future-ready solutions, ensuring businesses thrive in the digital age. At Aerolite, we are committed to pushing the boundaries of development to create technology that drives success."} </p></p>
                        <p className="text-black mt-4">Website<p className="text-gray-500 text-xs mt-2"><a href="">{companyWebsite ? companyWebsite : "www.aeroliteipsum.com"}</a></p></p>
                        <p className="text-black mt-4">Industry<p className="text-gray-500 text mt-2">{companyIndustry ? companyIndustry : "Software"}</p></p>
                        <p className="text-black mt-4">Company Size<p className="text-gray-500 text mt-2">{companySize ? companySize : "51-200"}</p></p>
                        <p className="text-black mt-4">Headquarters<p className="text-gray-500 text mt-2">{companyHeadquarters ? companyHeadquarters : "Bengaluru"}</p></p>
                        <p className="text-black mt-4">Founded<p className="text-gray-500 text mt-2">{companyFounded ? companyFounded : "2008"}</p></p>
                        <p className="text-black mt-4">Specialities<p className="text-gray-500 text-sm mt-2">{companySpecialities ? companySpecialities.join(', ')  : "No Specialities available"}</p></p>
                        <p className="text-black mt-4">Address<p className="text-gray-500 text mt-2">{companyAddress ? companyAddress : 'XYZ, Street 123, Bengaluru, 558946'}</p></p>
                        <p className="text-black mt-4">Location</p>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.041465472381!2d144.9630932153119!3d-37.81410797975148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577f9cd6f620e33!2sFederation%20Square!5e0!3m2!1sen!2sau!4v1602117830944!5m2!1sen!2sau"
                            className="w-full h-64"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </>
                }
            />
        </>



    );
}
export default Companyoverview