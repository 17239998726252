import React, { useState, useEffect, useRef, useContext } from "react";
import Button from "../elements/Button/Button";
import Card from "../elements/Card/Card";
import Textinput from "../elements/TextInput/TextInput";
import RadioInput from "../elements/RadioInput/RadioInput";
import Selectbox from "../elements/SelectBox/SelectBox";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import ButtonSolid from "../elements/Button/ButtonSolid";
import { useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import ClipLoader from "react-spinners/ClipLoader";
import ModalBox from "../elements/ModalBox/ModalBox";
import { useCommonContext } from "../../context/CommonContext";
import Swal from "sweetalert2";
import ShareJobModal from "../../components/Team/ShareJob";

const ProjectDashboard = () =>{
  useEffect(() => {
    localStorage.removeItem('jobCreationData');
  },[])
  const { setJobCreationData } = useCommonContext();
  const { token } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refreshKey, setRefreshKey] = useState(0);
  const handleJobLists = (jobId,status,jd_id,job_title) =>{
    console.log("sending id : ",jobId);
    navigate('/Projects',{ state: { jobId , status, jd_id, job_title} })
  }
    const [showShareJobModal, setShowShareJobModal] = useState(false);
  const [shareProject, setshareProject] = useState(null); // Holds the selected project data

  const handleSharedJobLists = (project) => {
    setshareProject(project); // Store the selected project
    setShowShareJobModal(true);
  };

  const closeSharejobModal = () => {
    setShowShareJobModal(false);
  };
  const [projects, setProjects] = useState([]);
  const [isShortlisted, setIsShortlisted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const sortTypes=[
    {id:'desc',label:'Date posted: Newest to oldest'},
    {id:'asc',label:'Date posted: oldest to Newest'}
  ]
  // const jobStatus=["Active","Close","In Review","Rejected","Pause", "Archive"];
  const jobStatus = ["Active", "Closed", "In Review", "Rejected", "Paused", "Archived"];
  const jobLocation=["Bangalore","Mumbai","Kerala","Kovilpatti","Chennai"];
  const dropdownRef = useRef(null); 
  const dropDownOption = useRef(null); 
  const [openDropdown, setOpenDropdown] = useState(null);
  const [ companyId, setCompanyID ] = useState("");
  const [ roleId, setRoleID ] = useState("");
  const [visibleCount, setVisibleCount] = useState(4);
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingLoader, setIsLoadingLoader] = useState(false);
  const [fetchedJobcount, setfetchedJobcount] = useState(0);
  const { totaljob } = location.state || {};
  const { openJobs } = location.state || {};
  const { approval } = location.state || {};
  const [filterName, setFilterName] = useState([]);
  const [filterJobStatus, setFilterJobStatus] = useState([]);
  const [filterLocation, setFilterLocation] = useState([]);
  const [DoProcessAfterLoader, setDoProcessAfterLoader] = useState(false);
  const [isReassignOpen,setReassignOpen]=useState(false)
  const [isOwnershipDetails, setOwnershipDetails]=useState(false);
  const [isOwnerName,setOwnerName]=useState();
  const [transferJobTitle, setJobTitle]=useState(false);
  const { job_id_Get } = location.state || {};
  const [notification, setNotification] = useState({ message: "", type: "" });
  const showNotification = (message, type) => {   //For Notification
    setNotification({ message, type });
    setTimeout(() => {
      setNotification({ message: "", type: "" });
    }, 3000); // Auto-hide after 3 seconds
  };

  const setIdtoLocalStorage = (key, value) => {
    console.log(key,value);
    localStorage.setItem(key, value);
  }
  const clearLocalStorage = () => {
    localStorage.removeItem('job_id');
  }

  useEffect(()=>{
    const encryptedMetadata = localStorage.getItem('metadata');
    const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
    setCompanyID(decryptedMetadata.cid);
    setRoleID(decryptedMetadata.role_id);
      const loadProfile = async () => {
        setIsLoadingLoader(true);
          try {
                const serverURL = process.env.REACT_APP_SERVER_URL;
                const response = await fetch(`${serverURL}/company/fetchCompanyUsers`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      token: token
                    },
                });
                const responsedata = await response.json();
        
                const decryptedData = JSON.parse(CryptoJS.AES.decrypt(responsedata.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                console.log(decryptedData);
                if (!response.ok) {
                    var errorMessage = "Something Went Wrong";
                    if(responsedata.error)
                        errorMessage = responsedata.error;
                    throw new Error(responsedata.error);
                }
                else{
                    console.log(decryptedData)
                    const data = Array.isArray(decryptedData) ? decryptedData : [];
                    setUserData(data);
                    setDoProcessAfterLoader(true)
                }
            } catch (error) {
                console.log('Error:', error);
                let errorMessage = "Something went wrong.";
                if (error.response && error.response.data && error.response.data.msg) {
                    errorMessage = error.response.data.msg;
                } 
                else if (error.message) {
                    errorMessage = error.message;
                }
            } finally {
              setIsLoadingLoader(false);
            }
        }
        if(isLoading){
          loadProfile();
        }

  },[isLoading])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalJobs, setTotalJobs] = useState(0);
  const [locationFilter, setLocationFilter] = useState([]);
  const fetchJobList = async (location = null, createdBy = null, jobStatus = null) => {
    let loc = location || "";
    let created_by = createdBy || "";
    console.log(jobStatus)
    let job_status = jobStatus || "";
    const page_start = (currentPage - 1) * 10; 
    const offset = 10;

    const JLData = {
      company_id: companyId,
      page_start: page_start,
      offset:offset, loc:loc, created_by:created_by, job_status:job_status
    };

    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(JLData),
      process.env.REACT_APP_SECRETKEY
    ).toString();
    const encryptjson = { data: encryptedData };
    setIsLoadingLoader(true);
    const APIstatusMapping = {
      requested: "In Review",
      active: "Active",
      close: "Closed",
      pause: "Paused",
      archive: "Archived",
      rejected: "Rejected",
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/job/fetchJobListing`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
          body: JSON.stringify(encryptjson),
        }
      );

      const status = await response.json();
      const JobListData = JSON.parse(CryptoJS.AES.decrypt(status.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
      console.log(JobListData,"current job in pipeline")
      const customizedProjects = JobListData.jobs.map((job) => {
        const matchedUser = users.find((user) => user.email === job.created_by);
        const createdByName = matchedUser ? matchedUser.name : "Unnamed User";
        const createdAtDate = new Date(job.created_at);
        console.log("current: ",job.job_pipeline.length)
        return {
          id: job.job_id,
          title: job.title,
          location: job.location,
          WorkplaceType: job.workplace_type,
          JobType: job.job_type,
          Department: job.department,
          JobDescription: job.job_desc,
          jobDescId: job.jd_id,
          Skills: job.skills.join(", "),
          pipeline_count: job.pipeline_count || 0,
          applicants_new: job.applicants_new || 0,
          applicants_total: job.applicants_total || 0,
          created_by: createdByName,
          created_at: createdAtDate.toLocaleString(),
          job_pipeline: job.job_pipeline.length,
          status:
          APIstatusMapping[job.status] ||
               job.status.charAt(0).toUpperCase() + job.status.slice(1),
        };
      });
      setLocationFilter((prev) => [
        ...new Set([...prev, ...customizedProjects.map((loc) => loc.location)]),
      ]);      
      // console.log("jd_meta: ",customizedProjects[0]["jd_id"])
      // const encryptedMetadata = localStorage.getItem('metadata');
      // if (encryptedMetadata){
      //     const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
      //     decryptedMetadata['jd_id'] = JobListData.jd_id;
      //     const encryptedUpdatedMetadata = CryptoJS.AES.encrypt(JSON.stringify(decryptedMetadata), process.env.REACT_APP_SECRETKEY).toString();
      //     localStorage.setItem('metadata', encryptedUpdatedMetadata);
      // }
      // Update state
      setProjects(customizedProjects);
      const totalJobs = JobListData.totalCount;
      setfetchedJobcount(customizedProjects.length);
      setTotalJobs(totalJobs);
      setTotalPages(Math.ceil(totalJobs / offset));
    } catch (err) {
      console.error("Error Fetching Job List:", err);
    }finally {
        setIsLoadingLoader(false); // Always hide loader after the request
    }
  };
  
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    setTimeout(() => window.scrollTo(0, 0), 0);
    setIsShortlisted(false);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
    setIsShortlisted(false);
  };
  const users = Object.values(userData); 
  const [JobFetchded, setJobFetched]=useState(false)
  useEffect(()=>{
      if(companyId && users && !JobFetchded && !totaljob && !openJobs && !approval){
        fetchJobList();
        setJobFetched(true);
      }
    
  },[companyId, users])
  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 5);
  };
  const handleClickOutside = (event) => {
    if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target) && dropDownOption.current && !dropDownOption.current.contains(event.target)) {
        setIsOpen(false);
        setOpenDropdown(null);
    }
};

// const handleJobStatusChange = (e) =>{
//   console.log(e.target.checked, e.target.name);
//   const jobstatus = e.target.name === 'In Review' ? "requested"   : e.target.name === 'Active' ? "active" : e.target.name.charAt(0).toLowerCase() + e.target.name.slice(1);
//   console.log(jobstatus,'jobstattus')
//   console.log(pendingJobs,openJobs,filterJobStatus)
//   if (e.target.checked) {
//     setFilterJobStatus((prev) => ({
//       ...prev,
//       [jobstatus]: true
//     }));
//   } else {
//     setFilterJobStatus((prev) => {
//       const prevData = { ...prev };
//       delete prevData[jobstatus];
//       return prevData;
//     });
//   }

//   setTimeout(() => window.scrollTo(0, 0), 0);
// }
const handleJobStatusChange = (e) => {
  console.log(e.target.checked, e.target.name);
  const statusMapping = {
    "In Review": "requested",
    "Active": "active",
    "Closed": "close",
    "Paused": "pause",
    "Archived": "archive",
    "Rejected": "rejected",
  };

  const jobstatus = statusMapping[e.target.name] || e.target.name.charAt(0).toLowerCase() + e.target.name.slice(1);
  console.log(jobstatus, "jobstatus");
  console.log(totaljob, openJobs, filterJobStatus, approval);

  if (e.target.checked) {
    setFilterJobStatus((prev) => ({
      ...prev,
      [jobstatus]: true,
    }));
  } else {
    setFilterJobStatus((prev) => {
      const prevData = { ...prev };
      delete prevData[jobstatus];
      return prevData;
    });
  }

  setTimeout(() => window.scrollTo(0, 0), 0);
};
const handleJobLocationChange = (e) =>{
  console.log(e.target.checked, e.target.name);
  if (e.target.checked) {
    setFilterLocation((prev) => ({
      ...prev,
      [e.target.name]: true
    }));
  } else {
    setFilterLocation((prev) => {
      const prevData = { ...prev };
      delete prevData[e.target.name];
      return prevData;
    });
  }

  setTimeout(() => window.scrollTo(0, 0), 0);
}
const handleOwnerChange = (e) => {
  console.log(e.target.checked, e.target.name);
  const matchedUser = users.find(user => user.name === e.target.name);
  const createdByName = matchedUser ? matchedUser.email : "Unnamed User";

  if (e.target.checked) {
    setFilterName((prev) => ({
      ...prev,
      [e.target.name]: createdByName
    }));
  } else {
    setFilterName((prev) => {
      const prevData = { ...prev };
      delete prevData[e.target.name];
      return prevData;
    });
  }

  setTimeout(() => window.scrollTo(0, 0), 0);
};
const handleFilterJobContaining = (e) => {
  const { name, checked } = e.target;
  if(name === 'sortlist'){
    if (!isShortlisted) {
      const filteredProjects = projects.filter(
        (job) => job.pipeline_count > 0
      );
      setProjects(filteredProjects);
    } else {
      fetchJobList();
    }
  }else {
    fetchJobList();
  }
  setIsShortlisted((prevState) => !prevState);
};
const [sortBySelect, setSortBySelect] = useState('desc'); // Default to ascending

const sortProjects = (order) => {
  const sortedProjects = [...projects].sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      console.log("Created at 1 :",a.created_at, new Date(a.created_at))
      console.log("Created at 2 :",b.created_at, new Date(b.created_at))
      console.log('Comparing:', dateA, dateB);
      if (order === 'asc') {
          return dateA - dateB; // Ascending order
      } else if (order === 'desc') {
          return dateB - dateA; // Descending order
      }
      return 0;
  });
  console.log('Sorted Projects:', sortedProjects);
  setProjects(sortedProjects);
};



const handleSortBy = (value) => {
    setSortBySelect(value);
    sortProjects(value);
};
useEffect(() => {
    if(DoProcessAfterLoader){
      console.log(filterJobStatus,'filterJobStatus')
      const createdByNames = Object.values(filterName);
      const jobStatus = Object.keys(filterJobStatus);
      const location = Object.keys(filterLocation);
      fetchJobList(location, createdByNames, jobStatus);
    }
}, [currentPage,filterName,filterJobStatus,filterLocation,DoProcessAfterLoader,refreshKey]);



useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, [isOpen]);
const resetFilter=()=>{
  fetchJobList();
  setFilterName([]);
  setFilterJobStatus([]);
  setFilterLocation([]);
}
useEffect(()=>{
  if(totaljob){
      // const timeout = setTimeout(() => {
        setFilterJobStatus([]);
        console.log("nillll")
        // }, 500);
      // return () => clearTimeout(timeout);
  }
  else if (openJobs){
    setFilterJobStatus((prev) => ({
      ...prev,
      ['active']: true
    }));
  }
  else if (approval){
    setFilterJobStatus((prev) => ({
      ...prev,
      ['requested']: true
    }));
  }
},[totaljob],[openJobs],[approval])
const approvJobFun = (job_id_Get) => {
  navigate("/JobApprove",{ state: { job_id_Get } })
}
const WeekDay = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
const abbreviations = {
  Sunday: "sun",
  Monday: "mon",
  Tuesday: "tue",
  Wednesday: "wed",
  Thursday: "thu",
  Friday: "fri",
  Saturday: "sat",
};
const toIST = (date) => {
  const istOffset = 330; // IST is UTC +5:30
  const localTime = new Date(date).getTime();
  const istTime = new Date(localTime + istOffset * 60 * 1000);
  return istTime.toISOString().split("T")[0];
};
const fetchJobDetails = async (job_id) => {
  const JLData = { job_id: job_id };

  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(JLData),
    process.env.REACT_APP_SECRETKEY
  ).toString();
  const encryptjson = { data: encryptedData };

  try {
    const serverURL = process.env.REACT_APP_SERVER_URL;
    const response = await fetch(`${serverURL}/job/fetchJobDetails`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify(encryptjson),
    });

    if (!response.ok) {
      const errorMessage = responsedata?.message || "Something Went Wrong";
      throw new Error(errorMessage);
    }

    const responsedata = await response.json();
    const jobData = JSON.parse(
      CryptoJS.AES.decrypt(
        responsedata.data,
        process.env.REACT_APP_SECRETKEY
      ).toString(CryptoJS.enc.Utf8)
    );

    const jobDetails = jobData.job_details[0];
    const jobCollaborators = jobData.job_collab;
    const jobTeam = jobData.job_team;
    const jobInterviewer = jobData.job_interviewer;
    const questions = jobData.job_screen_question;
    const jobSlot = jobData.job_weeklyslot;
    const job_dateoverride = jobData.job_dateoverride;

    return {
      jobid: "",
      job_id: "",
      job_id_old: jobDetails.job_id,
      company_id: companyId,
      jobTitle: jobDetails.title || "",
      company: "",
      workplaceType: jobDetails.workplace_type,
      jobLocation: jobDetails.location,
      jobType: jobDetails.job_type,
      description: jobDetails.job_desc || "",
      selectedSkills: jobDetails.skills,
      collaborators: jobCollaborators.map((collaborator) => collaborator.uid),
      team: jobTeam.map((team) => ({
        name: team.name,
        designation: team.designation,
        url: team.url,
      })) || [{ name: "", designation: "", url: "" }],
      questions: questions,
      slots: jobSlot.reduce((acc, value) => {
        const day = WeekDay[value.day_of_week === 7 ? 0 : value.day_of_week];
        const abbreviatedDay = abbreviations[day];
        const formatTime = (time) => time.slice(0, 5);
          const formattedStartTime = formatTime(value.start_time);
          const formattedEndTime = formatTime(value.end_time);
          acc[abbreviatedDay] = `${formattedStartTime} - ${formattedEndTime}`;
          return acc;
        }, {}),
      schedule_config_days: "",
      override: job_dateoverride.reduce((acc,value)=> {
        const date = toIST(value.date);
        const formatTime = (time) => time.slice(0, 5);
        const formattedStartTime = formatTime(value.start_time);
        const formattedEndTime = formatTime(value.end_time);
        acc[date] = `${formattedStartTime} - ${formattedEndTime}`;
        return acc;
      },{}),
      interviewers: jobInterviewer.map((interviewer) => interviewer.interviewer_id),
      interview_location: jobDetails.interview_location,
      interview_duration: jobDetails.interview_duration,
      timezone: jobDetails.timezone,
    };
  } catch (error) {
    console.error("Error fetching job details:", error);
    throw error;
  }
};

const handleEditJob = async (job_data) => {
  try {
    const jobDataNew = await fetchJobDetails(job_data.id);
    console.log(jobDataNew); // Logs the correct data
    localStorage.setItem("jobCreationData", JSON.stringify(jobDataNew));
    navigate("/NewJobPost");
  } catch (error) {
    console.error("Error fetching job details:", error);
  }
};


const handleReassign = (job) =>{
  setJobTitle(job)
  setOpenDropdown(false)
  setReassignOpen(true);
}

const ownerShipTransfer =[
  {name:"Ananya",email:"ananya123@gmail.com",role:"Admin"},
  {name:"Bala",email:"bala123@gmail.com",role:"HR"},
  {name:"Arun",email:"arun123@gmail.com",role:"Senior"},
  {name:"Ganesh",email:"ganesh123@gmail.com",role:"Senior"},
]

const handleTransferOwnership = (index) =>{
  setReassignOpen(false)
  setOwnershipDetails(true)
  setOwnerName(index)
}

const ModalBody=(
  <>
  <table className="w-full text-left mt-2 border-collapse rounded-xl min-w-max">
    <thead>
      <tr className='border-b-4 border-white  border-collapse'>
        <th className="p-1  rounded-l-2xl">
          <p className="block mt-2  mt-2 font-sans text-xs antialiased font-normal leading-none text-gray-500 ">
            User Name
          </p>
        </th>
        <th className="p-1 ">
          <p className="block mt-2  mt-2 font-sans text-xs antialiased font-normal leading-none text-gray-500 ">
              User Email ID
          </p>
        </th>
        <th className="p-1 ">
          <p className="block mt-2  mt-2 font-sans text-xs antialiased font-normal leading-none text-gray-500 ">
            Role
          </p>
        </th>
        <th className="p-1   rounded-r-2xl">
          <p className="block mt-2  mt-2 font-sans text-md antialiased font-normal leading-none text-black "> </p>
        </th>
      </tr>
    </thead>
    <tbody>
      {ownerShipTransfer.map((key,index)=>(
      <tr className="bg-sky-50 mb-2 border-collapse border-b-4 border-white" key={index}>
        <td className="p-1  border-blue-gray-50 rounded-l-2xl">
          <p className="block  mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
            {key.name}
          </p>
        </td>
        <td className="p-1  border-blue-gray-50">
          <p className="block  mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
            {key.email}
          </p>
        </td>
        <td className="p-1  border-blue-gray-50">
          <p className="block  mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
            {key.role}
          </p>
        </td>
        <td className="p-1  border-blue-gray-50 rounded-r-2xl text-end">
        <Button className="bg-green-700 pl-4 pr-4 pt-1 pb-1 rounded-lg text-white" onClick={()=>handleTransferOwnership(index)}>Transfer Ownership</Button>
        </td>
      </tr>
      ))}
    </tbody>
  </table>
  </>
)
const ApproveJob = async(status,job_id) =>{
    var cid = "";
    var userId = "";
    const encryptedMetadata = localStorage.getItem('metadata');
    if (encryptedMetadata){
        const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        if(decryptedMetadata && decryptedMetadata.cid)
            cid = decryptedMetadata.cid
        if(decryptedMetadata && decryptedMetadata.uid)
            userId = decryptedMetadata.uid
     }
    const serverURL = process.env.REACT_APP_SERVER_URL;
    let jsonData = {job_id:job_id,cid:cid,userId:userId,status:status};
    let url=`${serverURL}/job/approveJob`;
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData), process.env.REACT_APP_SECRETKEY).toString();
    var encryptjson = { data:encryptedData}
    setIsLoading(true);
    try {
        const response = await fetch(`${url}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              token: token
            },
            body: JSON.stringify(encryptjson),
        });
        const data = await response.json();
        console.log(data)

        // const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        // console.log(decryptedData);
        if (!response.ok) {
            var errorMessage = "Something Went Wrong";
            if(data.error)
                errorMessage = data.error;
            showNotification(errorMessage,"error");
            throw new Error(data.error);
        }
        else{
            showNotification("Job "+status+" Successfully","success");
            handleJobNavigation();
        }
    } catch (error) {
        console.log('Error:', error);
        let errorMessage = "Something went wrong.";
        if (error.response && error.response.data && error.response.data.msg) {
            errorMessage = error.response.data.msg;
        } 
        else if (error.message) {
            errorMessage = error.message;
        }
        showNotification(errorMessage, "error");
    } finally {
        setIsLoading(false); // Always hide loader after the request
    }
  }
  useEffect(() => {
    if (location.state?.refresh) {
      setRefreshKey((prev) => prev + 1);
    }
  }, [location.state]);

  const handleJobNavigation = () => {
    navigate('/JobListing', { state: { refresh: true }, replace: true });
  };
  

  const handleApproveJob = (status, job_id) => {
    console.log("Button clicked: ", status, job_id);
    Swal.fire({
      title: `Are you sure you want to ${status} this job?`,
      text: `This action cannot be undone.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${status} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("Confirmed action");
        ApproveJob(status, job_id);
      }
    });
  };
  
  
// console.log(projects,'proects')
const jobOptions =(project) => {
  console.log("Project passed to jobOptions:", project);

  return (
    <div className="py-1" ref={dropDownOption}>
      <Button
        className="w-full text-left px-4 bg-white py-2 border-white text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        onClick={() => handleApproveJob("archive", project.id)}
      >
        Archive Job
      </Button>
      <Button
        className="w-full text-left px-4 py-2 bg-white border-white text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 opacity-50"
      >
        Reassign Owner
      </Button>
      <Button
        className="w-full text-left px-4 py-2 bg-white border-white text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        onClick={() =>
          handleJobLists(
            project.id,
            project.status,
            project.jobDescId,
            project.title
          )
        }
      >
        View Job Details
      </Button>
      <Button
        className="w-full text-left px-4 py-2 bg-white border-white text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 "
        onClick={() => handleSharedJobLists(project)}
      >
        Share Job
      </Button>
      <Button className="w-full text-left px-4 py-2 bg-white border-white text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 opacity-50">
        Duplicate Job
      </Button>
      <Button
        className="w-full text-left px-4 py-2 bg-white border-white text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        onClick={() => handleApproveJob("pause", project.id)}
      >
        Pause Job
      </Button>
      <Button
        className="w-full text-left px-4 py-2 bg-white border-white text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        onClick={() => handleApproveJob("close", project.id)}
      >
        Close Job
      </Button>
      {showShareJobModal && (
        <ShareJobModal
          project={shareProject}
          closeSharejobModal={closeSharejobModal}
        />
      )}
    </div>
  );
}
const handleOptionClick = (id) => {
  setOpenDropdown((prev) => (prev === id ? null : id));
  setIsOpen(true);
}

    return(<>
      {isOwnershipDetails && <ModalBox Modal_Body={<><p className="mb-0">{`Are you sure to transfer the ownership of ${transferJobTitle} job Post to ${ownerShipTransfer[isOwnerName]["name"]}?`}</p><p className="text-sm text-[#747474]">You Can't undo this Action</p><div className="ml-auto w-5/12"><Button className="bg-white text-[#059669] border-[#059669] pt-2 pb-2 mr-2 pl-4 pr-4 ml-auto flaot-right" onClick={()=>setOwnershipDetails((prev)=>!prev)}>Cancel</Button><Button className="!bg-[#059669] text-white p-2 ml-auto float-right">Transfer Ownership</Button></div></>} modal_Close_Class="hidden" cardClass="no-max-width w-6/12 !p-4"/>}
      {isReassignOpen && <ModalBox Modal_Header="Reassign Job" modal_Header_Class="border-b-2 pb-2" Modal_Body={ModalBody} modal_Close_Class="top-4 right-4" cardClass="no-max-width w-6/12 !p-4" modal_Header_Text="text-xl mb-0 font-normal text-gray-900" Modal_Close_Func={()=>setReassignOpen((prev)=>!prev)}/>}
        <div className="flex">
          {isLoadingLoader && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        <span className="text-white mt-4">Loading...</span>
                    </div>
                </div>
          )}
            <div className="w-[24%] bg-white p-2 ml-6">
                <div className='flex mt-6 items-center'>
                  <p className='font-semibold mb-0'>Filters</p>
                  <Button onClick={()=> resetFilter()} className="bg-white flex text-gray-500 pl-2 mr-4 ml-auto pr-2 border-white"><svg className="w-5 h-5 mt-1 mr-2" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" fill="#059669" stroke="#059669"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g fill="none" fillRule="evenodd" stroke="#059669" strokeLinecap="round" strokeLinejoin="round" transform="matrix(0 1 1 0 2.5 2.5)"> <path d="m3.98652376 1.07807068c-2.38377179 1.38514556-3.98652376 3.96636605-3.98652376 6.92192932 0 4.418278 3.581722 8 8 8s8-3.581722 8-8-3.581722-8-8-8"></path> <path d="m4 1v4h-4" transform="matrix(1 0 0 -1 0 6)"></path> </g> </g></svg>Reset filters</Button>
                </div>
                <Card CardFooterClass="px-6 py-2 bg-gray-100 justify-end gap-2 "  CardClass="!hidden min-w-md mx-auto  bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <div>
                    <Textinput placeholder="Search for a Job" labelclass="hidden" className="!w-full border-2 pl-10 pr-4 py-2.5 !border border-gray-150 rounded-md focus:outline-none focus:ring-2 text-black mt-1 focus:ring-cyan-200"/>
                    <div className="absolute h-10 inset-y-[32%]  flex pt-1  pl-3">
                    <svg
                      className="w-5 h-5 text-blue-500"
                      fill="none" 
                      stroke="#059669"  
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
                      />
                    </svg>
                    
                </div>
                </div>
                    </>}
                />
                {/* Location Filter */}
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                      <p className="mb-0 font-medium">Location</p>
                      <div className="relative">
                        <Textinput placeholder="Search Cities" labelclass="hidden" className="!hidden !w-full border-2 pl-10 pr-4 py-2.5 !border border-gray-150 rounded-md focus:outline-none focus:ring-2 text-black mt-1 focus:ring-cyan-200"/>
                        <div className="!hidden absolute h-10 inset-y-[24%] flex pt-1 fixed  pl-3">
                          <svg className="w-5 h-5 text-blue-500" fill="none" stroke="#059669" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"/>
                          </svg>
                        </div>
                      </div>
                      {locationFilter.slice(0, visibleCount).map((loc)=>(
                        <Textinput key={loc} type="checkbox" name={loc} checked={filterLocation[loc]} onChange={(e)=> handleJobLocationChange(e)} labelclass="hidden" className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1" divclass="flex h-6 mt-2" postlabel={loc} postlabelclass="ml-2"/>  
                      ))}
                      {visibleCount < locationFilter.length && ( // Show "Show More" only if there are more users to show
                              <p
                              className="text-sm text-nowrap mt-2 hover:cursor-pointer text-green-800 hover:cursor-pointer w-[82px]"
                              onClick={handleShowMore}
                              >
                              Show more...
                              </p>
                          )}
                    </>}/>
                    {/* Job Containing Filter*/}
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Filter Jobs Containing</p>
                    <Textinput type="checkbox" labelclass="hidden" className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1" divclass="flex h-6 mt-2" postlabel={`All`} postlabelclass="ml-2"/>
                    {/* <Textinput type="checkbox" labelclass="hidden" className="mt-1 !w-1/12 w-5 h-5" divclass="flex mt-1 h-6" postlabel={`Only Searches (${50})`} postlabelclass="ml-2"/> */}
                    {/* <Textinput type="checkbox" labelclass="hidden" className="mt-1 !w-1/12 w-5 h-5 !rounded-xl" divclass="flex mt-1 h-6" postlabel={`Jobs (${50})`} postlabelclass="ml-2"/> */}
                    <Textinput name="sortlist" checked={isShortlisted} onChange={(e)=> handleFilterJobContaining(e)} type="checkbox" labelclass="hidden" className="mt-1 !w-1/12 w-5 h-5 !rounded-xl" divclass="flex mt-1" postlabel={`Shortlisted Participants`} postlabelclass="ml-2"/>
                    
                    </>}
                />
                {/* Job Status Filter */}
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end"  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Job Status</p>
                    {jobStatus.map((status)=>(
                      <Textinput key={status} type="checkbox" name={status} checked={
                        filterJobStatus[
                          {
                            "In Review": "requested",
                            "Active": "active",
                            "Closed": "close",
                            "Paused": "pause",
                            "Archived": "archive",
                            "Rejected": "rejected",
                          }[status] || status.charAt(0).toLowerCase() + status.slice(1)
                        ]
                      } onChange={(e) => handleJobStatusChange(e)} labelclass="hidden" className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1" divclass="flex h-6 mt-2" postlabel={status} postlabelclass="ml-2"/>
                    ))}</>}
                />
                 <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                      body={
                          <>
                          <p className="mb-0 font-medium">Project Owner</p>
                          {users.slice(0, visibleCount).map((user, index) => (
                              <Textinput key={user.uid || index} name={user.name} checked={filterName[user.name]} onChange={(e) => handleOwnerChange(e)} type="checkbox" postlabel={user.name || "Unnamed User"} labelclass="hidden" className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1" postlabelclass="ml-2" divclass="flex h-6 mt-2"
                              />
                          ))}
                          {visibleCount < users.length && ( // Show "Show More" only if there are more users to show
                              <p
                              className="text-sm text-nowrap mt-2 hover:cursor-pointer text-green-800 hover:cursor-pointer w-[82px]"
                              onClick={handleShowMore}
                              >
                              Show more...
                              </p>
                          )}
                          </>
                      }
                  />
	    
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end"  CardClass="!hidden min-w-md mx-auto bg-white shadow rounded-lg  overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Workspace</p>
                    <Textinput type="checkbox" labelclass="hidden" className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1" divclass="flex h-6 mt-2" postlabel={`Alchemy`} postlabelclass="ml-2"/>
                    <Textinput type="checkbox" labelclass="hidden" className="mt-1 !w-1/12 w-5 h-5" divclass="flex mt-1 h-6" postlabel={`NovaByte Solutions`} postlabelclass="ml-2"/>
                    <Textinput type="checkbox" labelclass="hidden" className="mt-1 !w-1/12 w-5 h-5" divclass="flex mt-1 h-6" postlabel={`ZenithSoft Labs1`} postlabelclass="ml-2"/>
                    <p className="text-sm mt-2 hover:cursor-pointer text-green-800">Show more...</p>
                    
                    </>}
                />
                
            </div>
            {totalPages === 0 ? 
            <div id="divid" className="w-full align-center justify-center pt-[5%] text-center">
              <span>No Jobs Found!!! Try Clearing Filter</span>
            </div>
            :
            <div className="w-[70%] bg-white rounded-xl bg-opacity-50 p-1 ml-6">
             <div className="flex mt-4">
              <p className="ml-10 mb-0 mt-0.5">Viewing {fetchedJobcount} of {totalJobs} Jobs</p>
              <p className="mb-0 ml-auto">Sort by</p>
              <Selectbox
              labelclass="hidden"
              className="!border-[#BFE0E2] bg-[white] !w-4/12 h-7 ml-2 mr-10"
              value={sortBySelect}
              list={sortTypes}
              onChange={(e) => handleSortBy(e.target.value)}
              />
            </div>
            <div id="divid">
          {projects.map((project) => (
            <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end" key={project.id} CardBodyClass={"flex gap-4"}  CardClass="min-w-md mx-auto bg-white relative shadow rounded-lg w-11/12  mt-7 !rounded-xl"
            body={<>
            <div className="w-[80%] text-sm">
              <p className="text-lg font-bold ml-[2%]">{project.title}</p>
            {/* <Textinput type="checkbox" labelclass="hidden" className="text-lg !w-[4.5%] w-4 h-4 mb-0 mt-[1.5%]" divclass="flex" postlabel={project.title} postlabelclass="ml-2 mb-2 text-xl font-bold"/> */}
            <div className="flex gap-4">
              <p className="text-black ml-[2%] mb-0 font-medium"><span style={{fontWeight:'500'}}>Created By : </span> {project.created_by}</p>
              <p className="before:content-['•'] before:text-green-700 before:mr-2 mb-2 list-none font-medium"><span style={{fontWeight:'500'}}>Posted : </span> {project.created_at}</p>
            </div>
            <p className="ml-[2%] text-black mb-2 font-medium"><span style={{fontWeight:'500'}}>Workplace : </span> {project.location}</p>
            <p className="ml-[2%] text-black flex font-medium"><span style={{fontWeight:'500'}}>Job : </span> {project.title} 
            <li 
             className={`before:content-['•'] before:mr-2 list-none pl-2 pr-2 pt-0.5 rounded-full text-xs ml-4 ${
              project.status === 'In Review'
                ? 'before:text-yellow-700 bg-yellow-200 text-yellow-900'
                : project.status === 'Active'
                ? 'before:text-green-700 bg-green-200 text-green-900'
                : project.status === 'Closed' || project.status === 'Rejected'
                ? 'before:text-red-700 bg-red-200 text-red-900'
                : project.status === 'Paused' || project.status === 'Archived'
                ? 'before:text-sky-700 bg-sky-200 text-sky-900'
                : ''
            }`}
            >{project.status}</li></p>
            </div>
            {project.status === 'Rejected' && <div className='w-[20%]'>
                <Button children="Edit Job" className="text-center px-4 bg-green-700 py-2 border-green-700 text-sm text-white float-right" onClick={() => handleEditJob(project)} />
              </div>}
            {project.status != 'In Review' && project.status != 'Waiting' && project.status != 'Rejected'?
            (
              <>
                <div className=" w-[14%] hover:cursor-pointer flex flex-col justify-center items-center text-center"  onClick={()=>handleJobLists(project.id,project.status,project.jobDescId,project.title)}>
                  <p className="text-green-600 text-xl font-medium">{project.job_pipeline}</p>
                  <p>Pipeline</p>
                </div>
                <div className=" w-[14%] hover:cursor-pointer flex flex-col justify-center items-center text-center" onClick={()=>handleJobLists(project.id,project.status,project.jobDescId,project.title)}>
                  <p className="text-green-600 text-xl font-medium">{project.applicants_total}{`(${project.applicants_new})`}New</p>
                  <p>Applicants</p>
                </div>
                <div className=" pointer w-[10%] relative justify-center flex flex-col items-center text-center" ref={dropdownRef}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} className="" fill="black" aria-hidden="true"
                    onClick={() =>
                      handleOptionClick(project.id)
                    }>
                      <circle cx="5" cy="12" r="2" />
                      <circle cx="12" cy="12" r="2" />
                      <circle cx="19" cy="12" r="2" />
                  </svg>
                </div>
              </>
            ):
            roleId == 1 && project.status == 'In Review'?(
              <div className='w-[20%]'>
                <Button children="Review Job" className="text-center px-4 bg-green-700 py-2 border-green-700 text-sm text-white float-right" onClick={() => approvJobFun(project.id)} />
              </div>
            ):
            roleId == 1 && project.status == 'Waiting' ?(
              <div className='w-full' onClick={()=>setIdtoLocalStorage("job_id",project.id)}>
                <a
                href="https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events&prompt=consent&response_type=code&client_id=1062697773487-2jlq54gs6fj8qnjdaqr3fdi6ajnhkrhu.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fbeta.eeezo.com%2Fgmeet%3F"
                className="no-underline border-1 bg-green-700 rounded-md hover:transform transition duration-150 hover:scale-105 text-center px-4 py-2 border-green-700 text-sm text-white float-right"
              >
                Generate Meeting
              </a>
              </div>
            ):(
            <>
            </>
            )
            }
            {openDropdown === project.id && (
              <div className="absolute  top-24 z-50 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10" style={{right:'5%'}}>
                {jobOptions(project)}
              </div>
            )}
            </>}
        />
          ))}
          <div className={`flex w-11/12 mt-8 mb-8`} style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            <div className="flex" style={{ alignItems: 'center' }}>
              <ButtonSolid
                disabled={currentPage === 1}
                onClick={handlePreviousPage}
                style={{ padding: '10px 30px', marginRight: '10px' }}
                className={`overflow-visible mt-0 border rounded-l-md ${currentPage === 1 ? '!bg-gray-200 hover:bg-gray-300' : ''}`}
                content="Previous"
              />
              <span className="text-nowrap px-4 py-2">
                Page {currentPage} of {totalPages}
              </span>
              <ButtonSolid
                disabled={currentPage === totalPages}
                onClick={handleNextPage}
                style={{ padding: '10px 30px', marginLeft: '10px' }}
                className={`overflow-visible mt-0 border rounded-r-md ${currentPage === totalPages ? '!bg-gray-200 hover:bg-gray-300' : ''}`}
                content="Next"
              />
            </div>
          </div>
        </div> 
            </div>}
            </div>
        </>
    );
}
export default ProjectDashboard;
