import React,{ useState } from "react";
import TeamMember from "../TeamMember/TeamMember";
import Profile from "./UserProfile";
import MessageTemplate from "../MessageTemplate/messageTemplate";
import Companyoverview from "../CompanyProfile/CompanyOverview";
import Button from "../elements/Button/Button";
import MastersPage from "../Masters/masters";
const AddMember = () => {
  const[sideChange,setSideState]=useState("company");
  const [showMastersButtons, setShowMastersButtons] = useState(false);
  const [masterType, setMasterType] = useState("");
  const handleSideBar = (state) => {
    setSideState(state);
    if (state === "masters") {
      setShowMastersButtons((prev) => !prev);
      setMasterType("Specialities"); 
    } else {
      setShowMastersButtons(false);
    }
  };
    
  
    return(
 <div>   
<div className="flex gap-28">
            <div className="w-1/5">
                <Button className={`ml-11 pl-2 pr-4  pt-3 pb-3 rounded-xl flex w-full mt-10  border-white ${sideChange==="company" ? "bg-gradient-to-r from-sky-300 to-emerald-100 ..." : "bg-white"}`} onClick={ () => handleSideBar("company")}><svg xmlns="http://www.w3.org/2000/svg" fill="#00945b" className="mr-4" width="24px" height="24px" viewBox="0 0 24 24" stroke="#00945b"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path></g></svg>Company Overview</Button>
                <Button className={`ml-11 pl-2 pr-4  pt-3 pb-3 rounded-xl flex w-full mt-2  border-white ${sideChange==="manage" ? "bg-gradient-to-r from-sky-300 to-emerald-100 ..." : "bg-white"}`} onClick={() => handleSideBar("manage")}><svg xmlns="http://www.w3.org/2000/svg" fill="#00945b" className="mr-4" width="24px" height="24px" viewBox="0 0 24 24" stroke="#00945b"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path></g></svg>Manage Users</Button>
                <Button className={`ml-11 pl-2 pr-4  pt-3 pb-3 rounded-xl flex w-full mt-2  border-white ${sideChange==="message"?"bg-gradient-to-r from-sky-300 to-emerald-100 ...": "bg-white"}`} onClick={ () => handleSideBar("message")}><svg xmlns="http://www.w3.org/2000/svg" fill="#00945b" className="mr-4" width="24px" height="24px" viewBox="0 0 24 24" stroke="#00945b"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path></g></svg>Message Templates</Button>
                <Button className={`ml-11 pl-2 pr-4  pt-3 pb-3 rounded-xl flex w-full mt-2  border-white ${sideChange==="user"?"bg-gradient-to-r from-sky-300 to-emerald-100 ..." : "bg-white"}`} onClick={ () => handleSideBar("user")}><svg xmlns="http://www.w3.org/2000/svg" fill="#00945b" className="mr-4" width="24px" height="24px" viewBox="0 0 24 24" stroke="#00945b"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path></g></svg>User Profile</Button>
                <Button className={`ml-11 pl-2 pr-4  pt-3 pb-3 rounded-xl flex w-full mt-2  border-white ${sideChange==="masters"?"bg-gradient-to-r from-sky-300 to-emerald-100 ..." : "bg-white"}`} onClick={ () => handleSideBar("masters")}><svg xmlns="http://www.w3.org/2000/svg" fill="#00945b" className="mr-4" width="24px" height="24px" viewBox="0 0 24 24" stroke="#00945b"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path></g></svg>Masters</Button>
                {showMastersButtons && (
                  <div className="ml-6 mt-2 space-y-2">
                    <Button className={`ml-11 pl-2 pr-4 pt-3 pb-3 rounded-xl flex w-full mt-2 border-white ${masterType === "Specialities" ? "bg-gradient-to-r from-sky-300 to-emerald-100 ..." : "bg-white"}`} onClick={() => setMasterType("Specialities")}>Specialities</Button>
                    <Button className={`ml-11 pl-2 pr-4 pt-3 pb-3 rounded-xl flex w-full mt-2 border-white ${masterType === "Skills" ? "bg-gradient-to-r from-sky-300 to-emerald-100 ..." : "bg-white"}`}onClick={() => setMasterType("Skills")}>Skills</Button>
                    <Button className={`ml-11 pl-2 pr-4 pt-3 pb-3 rounded-xl flex w-full mt-2 border-white ${masterType === "Search" ? "bg-gradient-to-r from-sky-300 to-emerald-100 ..." : "bg-white"}`}onClick={() => setMasterType("Search")}>Search Keyword</Button>
                    <Button className={`ml-11 pl-2 pr-4 pt-3 pb-3 rounded-xl flex w-full mt-2 border-white ${masterType === "Questions" ? "bg-gradient-to-r from-sky-300 to-emerald-100 ..." : "bg-white"}`}onClick={() => setMasterType("Questions")}>Screening Questions</Button>
                  </div>
                )}
            </div>
            {sideChange === "manage" && <TeamMember />}
            {sideChange==="company" && <Companyoverview/>}
            {sideChange==="message" && <MessageTemplate/>}
            {sideChange==="user" && <Profile/>}
            {sideChange==="masters" && masterType && <MastersPage type={masterType} />}
            </div>
</div>
)
};
export default AddMember;