import React, { useState } from 'react';

const Reassign = () => {

  return (

<div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
    <div className="relative p-4 w-full max-w-2xl max-h-full">
        
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            
            <div className="items-center justify-between p-2 ml-3 mr-3 md:p-2 ">
                <div className="flex border-b-2  dark:border-gray-600">
                <h3 className="text-xl mt-2 font-normal text-gray-900 dark:text-white">
                    Reassign Owner
                </h3>
                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal">
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
                </div>
                <div
  >
  <table className="w-full text-left mt-2 border-collapse rounded-xl min-w-max">
    <thead>
      <tr className='border-b-4 border-white  border-collapse'>
        <th className="p-1  rounded-l-2xl">
          <p className="block mt-2  mt-2 font-sans text-xs antialiased font-normal leading-none text-gray-500 ">
            User Name
          </p>
        </th>
        <th className="p-1 ">
          <p className="block mt-2  mt-2 font-sans text-xs antialiased font-normal leading-none text-gray-500 ">
              User Email ID
          </p>
        </th>
        <th className="p-1 ">
          <p className="block mt-2  mt-2 font-sans text-xs antialiased font-normal leading-none text-gray-500 ">
            Role
          </p>
        </th>
        <th className="p-1   rounded-r-2xl">
          <p className="block mt-2  mt-2 font-sans text-md antialiased font-normal leading-none text-black "> </p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr className="bg-sky-50 mb-2 border-collapse border-b-4 border-white">
        <td className="p-1  border-blue-gray-50 rounded-l-2xl">
          <p className="block  mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
            Ananya Bhatt
          </p>
        </td>
        <td className="p-1  border-blue-gray-50">
          <p className="block  mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
            ananya@gmail.com
          </p>
        </td>
        <td className="p-1  border-blue-gray-50">
          <p className="block  mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
            Owner
          </p>
        </td>
        <td className="p-1  border-blue-gray-50 rounded-r-2xl text-end">
        <button className="bg-green-700 pl-4 pr-4 pt-1 pb-1 rounded-lg text-white">Transfer Ownership</button>
        </td>
      </tr>
      <tr className="bg-sky-50 border-b-4 border-white">
        <td className="p-1 rounded-l-xl">
          <p className="block  mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
            John Doe
          </p>
        </td>
        <td className="p-1 ">
          <p className="block  mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
            johnd@gmail.com
          </p>
        </td>
        <td className="p-1 ">
          <p className="block  mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
            Collaborator
          </p>
        </td>
        <td className="p-1  border-blue-gray-50 rounded-r-xl text-end">
          <button className="bg-green-700 pl-4 pr-4 pt-1 pb-1 rounded-lg text-white">Transfer Ownership</button>
        </td>
      </tr>
      <tr className="bg-sky-50 ">
        <td className="p-1 rounded-l-xl">
          <p className="block  mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
            John Doe2
          </p>
        </td>
        <td className="p-1 ">
          <p className="block  mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
            johnd2@gmail.com
          </p>
        </td>
        <td className="p-1 ">
          <p className="block  mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
            Admin
          </p>
        </td>
        <td className="p-1  border-blue-gray-50 rounded-r-xl text-end">
        <button className="bg-green-700 pl-4 pr-4 pt-1 pb-1 rounded-lg text-white">Transfer Ownership</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
            </div>
            
            
            
            {/* <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button data-modal-hide="static-modal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">I accept</button>
                <button data-modal-hide="static-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Decline</button>
            </div> */}
        </div>
    </div>
</div>
  );
};
export default Reassign;