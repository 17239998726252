import React, { useContext, useEffect, useState } from "react";
import Textinput from "../elements/TextInput/TextInput";
import Selectbox from "../elements/SelectBox/SelectBox";
import { useCommonContext } from "../../context/CommonContext";
import './JobSchedule.css';
import ButtonHallow from '../../components/elements/Button/ButtonHallow'
import { AuthContext } from "../../context/AuthContext";
import CryptoJS from "crypto-js";
import CalendarModal from "./ScheduleCalendar";
const RefreshIcon = () => {
    return(<svg id="Layer_1" className="mr-2"height="15" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.04 122.88"><path d="M16.08,59.26A8,8,0,0,1,0,59.26a59,59,0,0,1,97.13-45V8a8,8,0,1,1,16.08,0V33.35a8,8,0,0,1-8,8L80.82,43.62a8,8,0,1,1-1.44-15.95l8-.73A43,43,0,0,0,16.08,59.26Zm22.77,19.6a8,8,0,0,1,1.44,16l-10.08.91A42.95,42.95,0,0,0,102,63.86a8,8,0,0,1,16.08,0A59,59,0,0,1,22.3,110v4.18a8,8,0,0,1-16.08,0V89.14h0a8,8,0,0,1,7.29-8l25.31-2.3Z"/></svg>
      )
}
const CalendarIcon = () => {
    return(<svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="icon-calendar mr-2"
        width="18"
        height="18"
      >
        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
        
        <line x1="3" y1="10" x2="21" y2="10"></line>
        
        <line x1="8" y1="14" x2="8" y2="14"></line>
        <line x1="12" y1="14" x2="12" y2="14"></line>
        <line x1="16" y1="14" x2="16" y2="14"></line>
        <line x1="8" y1="18" x2="8" y2="18"></line>
        <line x1="12" y1="18" x2="12" y2="18"></line>
        <line x1="16" y1="18" x2="16" y2="18"></line>
        
        <line x1="8" y1="2" x2="8" y2="6"></line>
        <line x1="16" y1="2" x2="16" y2="6"></line>
      </svg>
      )
}
const JobSchedule = () => {
    const { token } = useContext(AuthContext);
        useEffect(() => {
            window.scrollTo(0, 0)
          }, [])
    const { jobCreationData, setJobCreationData, validationErrors } = useCommonContext();
    useEffect(() => {
    if (Object.keys(validationErrors).length > 0) {
        const errorElement = document.querySelector(".validation-check-failed");
        console.log("Validation error found but not found the div")
        if (errorElement) {
        console.log("Validation error found, scrolling...");
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        errorElement.focus();
        }
    }
    }, [validationErrors]);
    const [DurationVal, SetDurationVal] = useState();
    const [LocationVal, SetLocationVal] = useState();
    const [TimeZone, SetTimeZone] = useState("");
    const [visibleCount, setVisibleCount] = useState(4);
    const [DaysCountval, setDaysCountval] = useState("");
    const [Hours, setHours] = useState("");
    const TimeZones = [
        {id: 0, label: "Choose Timezone"},
        {id: 1, label: "Indian Standard Time"},
        {id: 2, label: "UTC"},
    ]
    const DaysCount = [
        {id: 0, label: "0 Days"},
        {id: 1, label: "5 Days"},
        {id: 2, label: "10 Days"},
        {id: 3, label: "15 Days"},
        {id: 4, label: "20 Days"},
        {id: 5, label: "25 Days"},
        {id: 6, label: "30 Days"},
        {id: 7, label: "60 Days"},
    ]
    const HourstoNotify = [
        {id: 0, label: "30 minutes"},
        {id: 1, label: "1 hour"},
        {id: 2, label: "2 hours"},
        {id: 3, label: "3 hours"},
        {id: 4, label: "4 hours"},
        {id: 5, label: "5 hours"},
        {id: 6, label: "6 hours"},
        {id: 7, label: "7 hours"},
    ]
    const WeekDay = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ]
    const Duration = [
        { id: 0, label: "Choose Duration" },
        { id: 1, label: "15 Minutes" },
        { id: 2, label: "30 Minutes" },
        { id: 3, label: "45 Minutes" },
        { id: 4, label: "60 Minutes" },
        { id: 5, label: "90 Minutes" },
        { id: 6, label: "120 Minutes" },
    ];
    const Location = [
        { id: 0, label: "Choose Location" },
        { id: 1, label: "Google Meet" },
        { id: 2, label: "Zoom Meeting" },
        { id: 3, label: "Microsoft Teams" },
        { id: 4, label: "Skype" },
        { id: 5, label: "Webex" },
        { id: 6, label: "In-Person Meeting" },
        { id: 7, label: "Phone Call" },
    ];    
    const handleTimeChange = (e, day, type) => {
    const timeValue = e.target.value;
    setJobCreationData((prev) => {
        const updatedSlots = { ...prev.slots };
        if (!updatedSlots[day]) {
        updatedSlots[day] = type === "from" ? `${timeValue}` : ` ${timeValue}`;
        } else {
        const [fromTime, toTime] = updatedSlots[day].split(" - ");
        updatedSlots[day] =
            type === "from" ? `${timeValue} - ${toTime || ""}` : `${fromTime || ""} - ${timeValue}`;
        }
        return { ...prev, slots: updatedSlots };
    });
    console.log(type,e,day);
    };
    

    const [indexDay, setIndexDay] = useState(1);

    useEffect(() => {
        const handleSlotsUpdate = async (indexDay) => {
            // for (let i = 1; i <= indexDay; i++) {
                const dayKey = WeekDay[indexDay].toLowerCase().slice(0, 3);
                handleTimeChange({ target: { value: "09:00" } }, dayKey, "from");
                handleTimeChange({ target: { value: "17:00" } }, dayKey, "to");
            // }
        };

        if (indexDay <= 5) {
            handleSlotsUpdate(indexDay); 
            setIndexDay((prev) => prev + 1);
            console.log(jobCreationData)
        }
    }, [indexDay]);

    const setValueChange = (e) => {
        console.log(jobCreationData);
        const { id, value } = e.target;
        switch (id) {
            case "SetDurationVal":
                SetDurationVal(value);
                setJobCreationData((prev) => ({
                    ...prev,
                    ['interview_duration']: Duration.find((item) => item.id === parseInt(value, 10)).label,
                  }));
                break;
            case "SetLocationVal":
                SetLocationVal(value);
                setJobCreationData((prev) => ({
                    ...prev,
                    ['interview_location']: Location.find((item) => item.id === parseInt(value, 10)).label,
                  }));
                break;
            case "SetTimeZone":
                SetTimeZone(value);
                setJobCreationData((prev) => ({
                    ...prev,
                    ['timezone']: TimeZones.find((item) => item.id === parseInt(value, 10)).label,
                  }));
                break;
            case "SetDays":
                setDaysCountval(value);
                setJobCreationData((prev) => ({
                    ...prev,
                    ['schedule_config_days']: DaysCount.find((item) => item.id === parseInt(value, 10)).label,
                  }));
                  break;
            case "SetHours":
                setHours(value);
                // setJobCreationData((prev) => ({
                //     ...prev,
                //     ['hours_to_notify']: HourstoNotify.find((item) => item.id === parseInt(value, 10)).label,
                // }));
                break;
            default:
                console.warn(`No Value Updated :${id}`);
        }
    }
    const [interviewerFetched, setInterviewerFetched] = useState(false);
    const [interviewerData,setInterviewerData] = useState({});
    const fetchInterviewer = async (role_id) => {
        const InterViewerRole = {
            role_id: role_id,
          }
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(InterViewerRole), process.env.REACT_APP_SECRETKEY).toString();
        const encryptjson = { data: encryptedData };
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/company/fetchCompanyInterviewer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                token: token,
            },
            body: JSON.stringify(encryptjson),
            });
            const status = await response.json();
            const InterviewerData = JSON.parse(CryptoJS.AES.decrypt(status.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            if(response.ok){
                console.log(InterviewerData);
                setInterviewerData(InterviewerData);
            }
        } catch (error) {
            console.log('Error fetching interviewer', error);
        } finally {
            setInterviewerFetched(true);
        }
    }
    useEffect(()=>{
        if(!interviewerFetched){
            console.log("twice called !!!!!!")
            fetchInterviewer(5);
        }
    },[interviewerFetched])
    const handleShowMore = () => {
        setVisibleCount((prevCount) => prevCount + 5);
      };
      const handleInterviewerChange = (e, user) => {
        console.log("inteviewer data : ",user);
        const { checked } = e.target;
    
        setJobCreationData((prevData) => {
            const updatedInterviewer = checked
                ? [...prevData.interviewers, user.uid]
                : prevData.interviewers.filter((collab) => collab !== user.uid);
    
            return {
                ...prevData,
                interviewers: updatedInterviewer,
            };
        });
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [scheduleData, setScheduleData] = useState({});
  
    const handleOpenModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  
    const handleSaveSchedule = (updatedSchedule) => {
        setJobCreationData((prevData) => {
          return {
            ...prevData,
            override: {
              ...prevData.override,
              ...updatedSchedule,
            },
          };
        });
        setScheduleData(updatedSchedule);
        setIsModalOpen(false);
      };
      const ValueFind = (value, type) => {
        if(type != "" && type != undefined && type != "_blank"){
            const data = value.find((key) => key.label === type);
            console.log(data);
            return data.id;
        }
        else{
            return 0;
        }
      }
return(
    <section className={`JobSchedule-Main-Section p-8`}>
        <div className={`flex flex-col w-11/12 mobile-full-width`}>
        <Textinput id="Title" label="Title" labelclass="font-bold" value={jobCreationData.jobTitle || ""} disabled className="mobile-full-width !w-[30%] outline-none" />

        <Selectbox id="SetDurationVal" value={ValueFind(Duration, jobCreationData.interview_duration)} onChange={(e) => setValueChange(e)} label="Duration" labelClass="mt-4 font-bold" className={`mobile-full-width !w-[30%] !border-gray-200 bg-white !foucs:ring-[#059669] mt-2 p-2 !outline-none ${validationErrors.interview_duration ? "!border-red-500 border-2 validation-check-failed" : ""}`} list={Duration}/>

        <Selectbox id="SetLocationVal" value={ValueFind(Location, jobCreationData.interview_location)} onChange={(e) => setValueChange(e)} label="Location" labelClass="mt-4 font-bold" className={`mobile-full-width !w-[30%] !border-gray-200 bg-white !foucs:ring-[#059669] mt-2 p-2 !outline-none ${validationErrors.interview_location ? "!border-red-500 border-2 validation-check-failed" : ""}`} list={Location}/>

        <label className={`font-bold mt-4`}>Availability</label>
        <label className={`mt-2`}>Date Range</label>
        <p className={`text-nowrap flex mobile-flex-column`}>Invitees can schedule 
        <Selectbox id="SetDays" value={DaysCountval} onChange={(e)=> setValueChange(e)} className={`!mt-0 !w-[80px] text-emerald-600 !bg-transparent  !ring-transparent !foucs:ring-0 !outline-none p-2 ${validationErrors.schedule_config_days ? "!border-red-500 border-2 validation-check-failed" : "!border-none"}`} list={DaysCount} />
         with atleast 
         <Selectbox id="SetHours" value={Hours} onChange={(e)=> setValueChange(e)} className={`!mt-0 !w-[100px] text-emerald-600 !bg-transparent  !ring-transparent !border-none !foucs:ring-0 !outline-none p-2`} list={HourstoNotify} />
          notice</p>
        <div className={`w-full flex mobile-flex-column`}>
            <div className={`Weekly-Div mobile-full-width ${validationErrors.slots ? "!border-red-500 border-2" : ""}`}>
                <span className={`mt-4 font-bold text-nowrap flex`}><RefreshIcon />  Weekly hours</span>
                <p>Set when you are typically available for meetings</p>
                {WeekDay.map((value, index) => {
                    const dayKey = value.toLowerCase().slice(0, 3);
                    const [fromTime, toTime] = jobCreationData.slots?.[dayKey]?.split(" - ") || ["", ""] ;
                    return(
                    <div key={index} className="circle-container">
                        <div className="circle-div">{value.charAt(0)}</div>
                        <div className="time-picker-container">
                            <Textinput value={fromTime} onChange={(e)=>handleTimeChange(e, dayKey, "from")} type="time" id={`FromTime-${index}`} className={`bg-gray-50 border !w-[120px] leading-none text-gray-900 !focus:ring !ring-[#059669] text-sm rounded-lg p-2.5`} />
                            <Textinput value={toTime} onChange={(e)=>handleTimeChange(e, dayKey, "to")} type="time" id={`ToTime-${index}`} className={`bg-gray-50 border !w-[120px] leading-none text-gray-900 !focus:ring !ring-[#059669] text-sm rounded-lg p-2.5`} />
                        </div>
                    </div>)
                })}
                <Selectbox id="SetTimeZone" value={ValueFind(TimeZones, jobCreationData.timezone)} onChange={(e)=> setValueChange(e)} className={`!w-[150px] mt-4 text-emerald-600 !bg-transparent !ring-transparent !foucs:ring-0 !outline-none p-2 ${validationErrors.timezone ? "!border-red-500 border-2 validation-check-failed" : "!border-none"}`} list={TimeZones} />
            </div>
            <div className={`Weekly-Div mobile-full-width`}>
                <div className={`Date-Specific-Header flex`}>
                    <div className={`w-9/12`}>
                        <span className={`mt-4 font-bold text-nowrap flex`}><CalendarIcon />  Date-specific hours</span>
                        <p>Adjust hours for specific days</p>
                    </div>
                    <ButtonHallow onClick={()=>handleOpenModal()} className={`!w-[20%] mt-[25px] mb-[25px]`} content="+ Hours"/>
                </div>
                <CalendarModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onSave={handleSaveSchedule}
                />
                <div className="mt-4">
                <ul className="list-disc pl-6">
                {Object.entries(jobCreationData.override).map(([date, time]) => (
                    <li key={date} className="mb-1">
                    {date}: {time}
                    </li>
                ))}
                </ul>
            </div>
            </div>
        </div>
        <label className={`font-bold mt-4`}>Add Interviewers</label>
        {Object.values(interviewerData).length<=0 && <p className={`mt-4`}>No Interviewers Found, Try adding user with Interviewer profile!!!</p>}
        {Object.values(interviewerData).splice(0,visibleCount).map((user,index)=>(
            <Textinput
                key={user.uid || index} 
                type="checkbox"
                postlabel={user.name || "Unnamed User"}
                labelclass="hidden mt-4"
                className={`!w-[18.1px] mt-2 `}
                postlabelclass="ml-2 mb-0 mt-2"
                divclass={`flex mt-1 ${validationErrors.interviewers ? "!border-red-500 border-2 validation-check-failed" : ""} w-[200px]`}
                onChange={(e) => handleInterviewerChange(e, user)} 
                checked={jobCreationData.interviewers.some((collab) => collab === user.uid)} 
            />
        ))}
            {visibleCount < Object.values(interviewerData).length && ( 
                <p
                className="text-[12px] font-[400] mt-1 hover:cursor-pointer w-[82px]"
                onClick={handleShowMore}
                >
                Show more...
                </p>
            )}
        </div>
    </section>
)
}
export default JobSchedule;