import React from "react";
import Message from "../../components/Message/Message";
import Topbar from "../../components/Recruiter/Topbar/Topbar";
const MessagePage = () =>{
    return(
        <div className="radial-gradient-bg">
            <div><Topbar/></div>
            <Message/>
        </div>
    );
}
export default MessagePage;