import React,{useState,useEffect,useRef,useContext} from "react";
import Card from "../elements/Card/Card";
import Button from "../elements/Button/Button";
import Person from "../../assets/Images/person.jpg"
import ClipLoader from "react-spinners/ClipLoader";
import { AuthContext } from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import Notification from "../Notification/Notification";
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
// import Applicant from "./Applicants";

const HiddenCandidates = () =>{
    const dropdownRef = useRef(null);
    const location = useLocation();
    const job_id = location.state?.jobId;
    const [ResumeDetails,setResumeDetails] = useState([]);
    const [isResumeOption,setResumeOption]=useState(false);
    const [candidates,setCandidates]=useState('');
    const { token } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [processingMessage, setProcessingMessage] = useState('No resume data available.');
    const [updatedStatus,setUpdatedStatus]=useState('');
    const [isOpen, setIsOpen] = useState(false);
      
        const toggleDropdownPipeline = () => {
          setIsOpen((prev) => !prev);
        };
        const toggleDropdownResumeOption = (index) => {
          setResumeOption((prev) => prev===index?null:index);
          // console.log(ResumeDetails['891fcc57bccb4fc3a21a2bc84a2f346b']['candidate']['name'])
        };
    const [isOpenMessage, setIsOpenMessage] = useState(false);
      
        const toggleDropdownMessage = () => {
          setIsOpenMessage((prev) => !prev);
        };
      const [notification, setNotification] = useState({ message: "", type: "" });
              
                const showNotification = (message, type) => {   //For Notification
                  setNotification({ message, type });
                  setTimeout(() => {
                    setNotification({ message: "", type: "" });
                  }, 3000); // Auto-hide after 3 seconds
                };
    // const [applicants,setApplicantStatus]=useState(1);
    // const handleApplicant = () =>{
    //     setApplicantStatus(2);
    // }
    const handleClickOutside = (event) => {
        if ((isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target))||(isOpenMessage && dropdownRef.current && !dropdownRef.current.contains(event.target))) {
            setIsOpen(false);
            setIsOpenMessage(false)
        }
        
    };
    
    const pipelineListing = async () => {
                      var jobId = job_id;
                      let decryptedMetadata;
                      console.log("jobid: ",jobId);
                      const encryptedMetadata = localStorage.getItem('metadata');
                          if (encryptedMetadata){
                              decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                              console.log(decryptedMetadata,"decryptedMetadata")}
                              console.log("setted data: ",jobId,decryptedMetadata.uid,decryptedMetadata.cid)
                              const encryptedData = CryptoJS.AES.encrypt(
                                JSON.stringify({
                                  job_id: jobId,
                                  user_id: decryptedMetadata.uid,
                                  company_id: decryptedMetadata.cid,
                                  status: "hidden"
                                }),
                                process.env.REACT_APP_SECRETKEY
                              ).toString();
                              
                              var searchData = { data: encryptedData };
                              
                      setIsLoading(true);
                      try {
                          const serverURL = process.env.REACT_APP_SERVER_URL;
                          const response = await fetch(`${serverURL}/job/fetchPipeline`, {
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json',
                                token: token
                              },
                              body: JSON.stringify(searchData), 
                          });
                          const result = await response.json();
                          console.log("Server Response pipeline:", result);
                          if (!response.ok) {
                              throw new Error(result.error);
                          }
                          const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                          if(decryptedData['message']){
                              setProcessingMessage(decryptedData['message'])
                              return;
                          }
                          console.log("first data: ",decryptedData);
                         console.log(decryptedData.pipeline,"pipeline data");
                        //  if(candidateType==="all" || decryptedData.overallCount)
                        //     setAllCandidates(decryptedData.overallCount)
                        //  if(candidateType==="uncontacted" || decryptedData.typewiseCount.uncontacted)
                        //     setUncontacted(decryptedData.typewiseCount.uncontacted)
                        //  if(candidateType==="contacted" || decryptedData.typewiseCount.contacted)
                        //     setContacted(decryptedData.typewiseCount.contacted)
                        //  if(candidateType==="Replied" || decryptedData.typewiseCount.replied)
                        //     setReplied(decryptedData.typewiseCount.replied)
    
                        //  console.log(decryptedData.typewiseCount,"type data");
                        const keyval=Object.keys(decryptedData.pipeline);
                          console.log(keyval.length,"diccc");
                          if(keyval.length<1000){
                            setCandidates(keyval.length);
                          }
                          if(keyval.length>=1000){
                            setCandidates(1200/1000+"k")
                          }
                          if(decryptedData.pipeline.length!==0){
                          decryptedData.pipeline.forEach((item, index) => {
                            console.log("res_data: ", decryptedData.pipeline[index]);
                            setResumeDetails(decryptedData.pipeline);
                            // setFilteredResults(decryptedData.pipeline);
                            setUpdatedStatus(decryptedData.pipeline[index].type)
                          });}
                          else{
                            setResumeDetails([]);
                          }
                          // console.log("res_data: ", decryptedData[0]["resume"]);
                          
                          // showNotification(result.message, "success");
                      } catch (error) {
                          console.error("Error uploading file:", error);
                          // showNotification(error.message, "error");
                      }finally {
                          setIsLoading(false); // Always hide loader after the request
                      }
            }
            const isResumeDetailsEmpty = !ResumeDetails || Object.keys(ResumeDetails).length < 1;
        var totalResumeCount = 0;
        if(!isResumeDetailsEmpty){
            totalResumeCount = Object.keys(ResumeDetails).length
        }


     useEffect(()=>{
      pipelineListing()
     },[])   

     const handleHiddenCandidate = async(value,jobPipelineId) =>{
                   const HiddenData = {
                       job_pipeline_id: jobPipelineId,
                       status: value            
                       };
                       console.log("jddata:",HiddenData)
                   
                       const encryptedData = CryptoJS.AES.encrypt(
                         JSON.stringify(HiddenData),
                         process.env.REACT_APP_SECRETKEY
                       ).toString();
                       const encryptjson = { data: encryptedData };
                       
                       const userConfirmed = await Swal.fire({
                         icon: "warning",
                         title: "Are you sure to UnHide This Candidate",
                         text: "The Candidate will be Removed from hidden Section.",
                         confirmButtonText: "OK",
                         showCancelButton: true,
                       });
                     
                       if (!userConfirmed.isConfirmed) {
                         console.log("User canceled pipeline addition.");
                         return;
                       }
                       
                       setIsLoading(true);
                         try {
                           const response = await fetch(
                             `${process.env.REACT_APP_SERVER_URL}/job/updatePipelineProfileStatus`,
                             {
                               method: "POST",
                               headers: {
                                 "Content-Type": "application/json",
                                 token: token,
                               },
                               body: JSON.stringify(encryptjson),
                             }
                           );
                       
                           const data = await response.json();
                       
                           const decryptedData = JSON.parse(
                             CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8)
                           );
                           showNotification(
                             `Candidate Removed from hidden` ,
                             "success"
                           );
                           console.log(decryptedData, "decrypt");
                           pipelineListing();
                         } catch (error) {
                           console.error("Error occurred:", error);
                         }
                         finally {
                           setIsLoading(false);
                         }
                       
                       
                 }
    
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen,isOpenMessage]);
    return(
        <>
        <div className="bg-white ml-12 gap-2 w-[70%] p-4 bg-opacity-[50%] rounded-xl">
            <div>
            <Notification
            message={notification.message}
            type={notification.type}
            onClose={() => setNotification({ message: "", type: "" })}
        />

        {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        <span className="text-white mt-4">fetching Details...</span>
                    </div>
                </div>
            )}
                <div>
                <div className="flex gap-[16px]">
                        <div className="w-[177px] h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[20px] pb-[20px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`18`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto">Total Jobs</p>
                        </div>
                        <div className="w-[177px] h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[20px] pb-[20px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`21`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto">Total Resumes</p>
                        </div>
                        <div className="w-[177px] h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[14px] pb-[14px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`07`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto text-center">Open Jobs</p>
                        </div>
                        <div className="w-[177px] h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[20px] pb-[20px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`18`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto">Upcoming Interviews</p>
                        </div>
                        <div className="w-[177px] h-[100px] bg-[#F2F9F9] rounded-[17px] relative pt-[14px] pb-[14px] pl-[10px] pr-[10px] grid gap-[4px]">
                            <p className="mb-0 text-[26px] font-[700] mx-auto items-center text-[#059669]">{`1`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto text-center ">Candidates Contacted</p>
                        </div>
                    </div> 
                </div>
            </div>
            <div>
                        <p className="mb-0 text-xl mt-4 font-[700]">Hidden Candidates Search <span className="text-xs font-[400] text-[#747474]">{candidates||0} results</span></p>
                    </div>
            <div>
            <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 " CardClass="min-w-md mx-auto bg-white shadow rounded-lg no-max-width  overflow-hidden mt-4 mb-4 !rounded-xl shadow border-white w-full !hidden"
                body={<>
                <div className="flex">
                    <img src={Person} className="w-28 h-28 rounded-full object-cover"></img>
                    <div className="pl-2 absolute ml-6 inset-6 mt-14 h-9 bg-green-50 shadow-lg shadow-green-700  w-20 pb-1  rounded-md ">
                    <div className="relative size-6 mt-1">
                    <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-neutral-700" strokeWidth="4"></circle>

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-teal-400 dark:text-blue-500" strokeWidth="4" stroke-dasharray="100" stroke-dashoffset="6" stroke-linecap="round"></circle>
                    </svg>
    
                    </div>
      
         
                    <div className="absolute rounded-lg inset-2 ml-8 mt-2 mr-2 flex items-center  justify-center  text-gray-700 dark:text-white">
                        
                        <div className="h-14">
                          <p className="mb-0 text-black font-medium mt-1 ml-3">{94}%</p>
                          
                        </div>
                    </div>
                    <p className="text-xs mb-0 absolute inset-y-4 ml-8">Match</p>
                    
                    </div>

                    <div className="ml-6 hover:cursor-pointer">
                        <p className="text-xl font-medium text-black mt-3 mb-0">Sharad Bhat</p>
                        <p className="text-sm mb-0">UI/UX Designer</p>
                        <p className="text-xs mt-1">Bengaluru,Karnataka</p>
                    </div>
                   <div className="ml-auto mt-4"> 
                    <div className="relative items-center flex text-left">
      
        <Button
          type="button"
          className="inline-flex border-[1px] border-[#059669]  justify-center gap-x-1.5 rounded-md bg-white px-3  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdownPipeline}
        >
          <p className="mt-2 mb-0 text-[#059669]">Save to pipeline</p>
          <svg
            className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-[#059669]"
            viewBox="0 0 20 20"
            fill="#059669"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
      
      {/* {filterOpen &&(
        <ModalBox Modal_Body={ModalBody} Modal_Header={ModalHeader} modal_Close_Class="mt-2.5 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-6" Modal_Close_Func={handleCloseFilter} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-full !rouded-none !w-96 opacity-none overflow-y-auto" ModalTopClass="!items-right !justify-end "/>
      )}
      {saveProject &&(
        <ModalBox Modal_Body={AnotherProject} Modal_Footer={SaveProjectFooter} Modal_Header={ProjectHeader} modal_Header_Text="text-2xl font-[500] mb-0" modal_Close_Class="mt-3 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-5" Modal_Close_Func={handleCloseProject} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-auto grid gap-[30px] p-[30px] !rouded-xl no-max-width !w-[719px] opacity-none overflow-y-auto"/>
      )}

      {isProjectCreation &&(
        <ModalBox Modal_Body={<ProjectCreation onClose={handleCloseNewProject}/>}  modal_Close_Class="mt-3 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-5" Modal_Close_Func={handleCloseNewProject} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-auto grid gap-[30px] p-[30px] !rouded-xl no-max-width !w-[719px] opacity-none overflow-y-auto"/>
      )} */}
      
      {isOpen && (
        <div
          className="absolute z-10 w-8/12 mt-2 top-9 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
          ref={dropdownRef}
        >
          <div className="p-1 space-y-0.5">
      <a className="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Uncontacted
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Contacted
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Replied
      </a>
      
    </div>
        </div>
      )}
      {isOpenMessage && (
        <div
          className="absolute left-4 z-10 w-10/12 top-11 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
          ref={dropdownRef}
        >
          <div className="p-1 space-y-0.5">
      <a className="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        UnHide Candidate
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Add Note
      </a>
      {/* <a className="flex items-center gap-x-3.5 py-2 px-3   text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" onClick={handleSaveToProject} href="#">
      Save To Another Project
      </a> */}
      <a className="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Share for Review
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save To Another Project
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save to PDF
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3  text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Find More People Like This
      </a>
      
    </div>
        </div>
      )}
    
    
                    
                    <Button type="button" className="!bg-gray-100  ml-3 h-9 items-center justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={toggleDropdownMessage}>
                <img src="/image/message.png" className='w-5' />
            </Button>
            <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" className="p-1 h-5 mb-1 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
            <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
            </svg>
            </button>
            </div>
            </div>






                </div>
                <div className="grid gap-[32px]">
                <div className="mt-4 flex gap-[32px]">
                    <div className="w-[80px]">
                  <p className="ml-2 text-black mb-0 text-[12px] font-[500]">Experience</p></div>
                  <div>
                    <p className="text-xs opacity-[50%] mb-0 font-[500]">4+ years of experience in UX design</p>
                    <p className="text-black text-xs flex font-[400] mb-0"><span className="text-green-700 font-[700] mr-1">Senior UX Designer</span> at NovaByte Solutions <li className="ml-2 mb-0 text-gray-700">Current</li></p>
                    <p className="text-black text-xs font-[400] flex mb-0">UX Designer CodeSphere Technologies <li className=" mb-0 text-gray-700 ml-2">2021-2022</li></p>
                    <p className="text-black text-xs font-[400] flex mb-0">UX Designer BlueShift Dynamics <li className=" mb-0 text-gray-700 ml-2">2019-2021</li></p>
                  </div>
                </div>
                <div className="flex gap-[32px]">
                    <div className="w-[80px]">
                  <p className="ml-2 text-black mb-0 text-xs font-[500]">Education</p></div>
                  <div>
                    
                    <p className="text-black flex text-xs mb-0">Bachelor of Design, Srishti Manipal Institute<li className=" mb-0 text-gray-700 ml-2">2014-2017</li></p>
                    <p className="text-black flex text-xs mb-0">Masters Strategic Design Management, NID<li className=" mb-0 text-gray-700 ml-2">2017-2019</li></p>
                  </div>
                </div>
                <div className="flex gap-[32px]">
                <div className="w-[80px]">
                  <p className="ml-2 text-xs mb-0 font-[500] text-black">Skills Match</p></div>
                  <div>
                    
                    <p className="flex text-xs text-green-700 mb-0 font-medium">User Research <li className="text-xs ml-2">User-Centered Design Thinking</li><li className="ml-2">Problem-solving</li></p>
                  </div>
                </div>
                <div className="flex mb-4 gap-[32px]">
                    <div className="w-[80px]">
                  <p className="ml-2 text-black mb-0 text-xs font-[500]">Activity</p></div>
                  <div className="w-[381px] justify-between">
                    
                    <p className="text-black flex text-xs mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, Masters StrLorem ipsum dolor sit amet, consectetur adipiscing elit, ategic Design Management, NID</p>
                    
                  </div>
                </div>
                </div>
                </>}
                />



      {isResumeDetailsEmpty ? (
            processingMessage == "Data Updation Processing" ? (
              <div className="bg-white m-4 rounded-lg text-center py-32">
      <p>Please wait while we process your request. Resume matching is in progress... Loading.. </p>
      <ClipLoader color="#3498db" size={50} />
    </div>
              ):(
              <div className="bg-white  m-4 rounded-lg text-center py-32">{processingMessage}</div>)
          ) : (
            Object.entries(ResumeDetails).map(([project, index]) => (
          <Card CardFooterClass=" !bg-[#059669] !rounded-l-none w-3.5/12 ml-auto  justify-end" CardClass="min-w-md mx-auto pl-2 bg-white shadow rounded-lg overflow-hidden mt-4 mb-4 !rounded-3xl shadow-none border w-12/12"
           body={
            <>
            <div>
            <div className="w-full">
              <div className="flex mb-2">
            <p className="text-[24px] font-medium mb-0">{ResumeDetails[project] && ResumeDetails[project]['resume'] && ResumeDetails[project]['resume']['candidate'] && ResumeDetails[project]['resume']['candidate']['name']?ResumeDetails[project]["resume"]["candidate"]["name"]:"Name Not Provided"}</p>
            <div className="ml-auto">
      <div className="flex">
    <div className="pl-8">
    {/* <Button
      type="button"
      className="inline-flex w-[190px] justify-center gap-x-1.5 mr-3 rounded-md bg-white px-3 border-green-500  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      id="menu-button"
      aria-expanded={isOpen}
      aria-haspopup="true"
      onClick={()=>toggleDropdownResumePipeline(index)}
    >
      <p className="mt-2 mb-0 text-[16px] font-medium text-green-600">{updatedStatus?updatedStatus:"Save to pipeline"}</p>
      <svg
        className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-green-600"
        viewBox="0 0 20 20"
        fill="green"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
          clipRule="evenodd"
        />
      </svg>
    </Button>
  
  

  
  {isResumeOpen===index && (
    <div
      className="absolute left-34  z-10 !w-[190px] mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
      ref={dropdownRef}
    >
      <div class="p-1 space-y-0.5">
  <a class="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3 hover:cursor-pointer  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline"  onClick={()=>handlePipelineStatus("uncontacted",index,ResumeDetails[project]["resume"]["candidate"]["name"],ResumeDetails[project]["resume"]['candidate']['email'],jobTitle)}>
    Uncontacted
  </a>
  <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100 hover:cursor-pointer text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline"  onClick={()=>handlePipelineStatus("contacted",index,ResumeDetails[project]["resume"]["candidate"]["name"],ResumeDetails[project]["resume"]['candidate']['email'],jobTitle)}>
    Contacted
  </a>
  <a class="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:cursor-pointer hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline"  onClick={()=>handlePipelineStatus("Replied",index,ResumeDetails[project]["resume"]["candidate"]["name"],ResumeDetails[project]["resume"]['candidate']['email'],jobTitle)}>
    Replied
  </a>
  
</div>
    </div>
    )} */}
    </div>
    <Button className="bg-white h-6 mt-2 border-none" onClick={()=>toggleDropdownResumeOption(index)}>
        <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={20}
                height={20}
                className="ml-auto "
                fill="black"
                aria-hidden="true"
                >
                <circle cx="5" cy="12" r="2" />
                <circle cx="12" cy="12" r="2" />
                <circle cx="19" cy="12" r="2" />
                </svg>
                </Button>
{isResumeOption===index && (
    <div
      className="absolute left-[75%] top-12  z-50 !w-[190px] mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
      ref={dropdownRef}
    >
      <div class="p-1 space-y-0.5">
  <a class="flex items-center gap-x-3.5  py-2 px-3 hover:cursor-pointer  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline"  onClick={()=>handleHiddenCandidate("Active",ResumeDetails[project]['job_pipeline_id'])}>
    UnHide Candidate
  </a>
  
  
</div>
    </div>
    )}
            </div>

                </div>
                </div>
            <p className="text-[14px]">{ResumeDetails[project] && ResumeDetails[project]['resume'] && ResumeDetails[project]['resume']['candidate'] && ResumeDetails[project]['resume']['candidate']['objective']?ResumeDetails[project]['resume']['candidate']['objective']:"Objective Not Provided"}</p>
            <p className="text-[16px] font-semibold mb-0">Contact Details</p>
            
            <p className="flex text-[16px]"><div className="flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" className="mt-2 mr-2" viewBox="0 0 15 11" fill="none">
<path d="M14 2V9C14 9.39782 13.842 9.77936 13.5607 10.0607C13.2794 10.342 12.8978 10.5 12.5 10.5H2.5C2.10218 10.5 1.72064 10.342 1.43934 10.0607C1.15804 9.77936 1 9.39782 1 9V2M14 2C14 1.60218 13.842 1.22064 13.5607 0.93934C13.2794 0.658035 12.8978 0.5 12.5 0.5H2.5C2.10218 0.5 1.72064 0.658035 1.43934 0.93934C1.15804 1.22064 1 1.60218 1 2M14 2V2.162C14 2.4181 13.9345 2.66994 13.8096 2.89353C13.6848 3.11712 13.5047 3.30502 13.2867 3.43933L8.28667 6.516C8.05014 6.66169 7.7778 6.73883 7.5 6.73883C7.2222 6.73883 6.94986 6.66169 6.71333 6.516L1.71333 3.44C1.49528 3.30569 1.31525 3.11779 1.19038 2.8942C1.06551 2.6706 0.999972 2.41876 1 2.16267V2" stroke="#059669" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
              
              <p className="mt-[1px]">{ResumeDetails[project] && ResumeDetails[project]['resume'] && ResumeDetails[project]['resume']['candidate'] && ResumeDetails[project]['resume']['candidate']['email']?ResumeDetails[project]['resume']['candidate']['email']:"Email Not Provided"}</p></div>
<div className="flex ml-4">
<svg xmlns="http://www.w3.org/2000/svg" className="mt-1.5 mr-2" width="15" height="15" viewBox="0 0 15 15" fill="none">
<path d="M1 4C1 9.52267 5.47733 14 11 14H12.5C12.8978 14 13.2794 13.842 13.5607 13.5607C13.842 13.2794 14 12.8978 14 12.5V11.5853C14 11.2413 13.766 10.9413 13.432 10.858L10.4833 10.1207C10.19 10.0473 9.882 10.1573 9.70133 10.3987L9.05467 11.2607C8.86667 11.5113 8.542 11.622 8.248 11.514C7.15659 11.1128 6.16544 10.4791 5.34319 9.65681C4.52094 8.83457 3.88725 7.84341 3.486 6.752C3.378 6.458 3.48867 6.13333 3.73933 5.94533L4.60133 5.29867C4.84333 5.118 4.95267 4.80933 4.87933 4.51667L4.142 1.568C4.10143 1.4058 4.00781 1.2618 3.87604 1.15889C3.74426 1.05598 3.58187 1.00006 3.41467 1H2.5C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V4Z" stroke="#059669" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  <p>{ResumeDetails[project] && ResumeDetails[project]['resume'] && ResumeDetails[project]['resume']['candidate'] && ResumeDetails[project]['resume']['candidate']['phone_number']?ResumeDetails[project]['resume']['candidate']['phone_number']:"Phone No Not Provided"}</p>
  </div></p></div>
    
  </div>
            </>
           }
           footer={
            <>
            <p className="mb-0 text-white">From Resumebox</p>
            </>
           }
          />
          )))}


            </div>

        </div>
    </>
    );
}
export default HiddenCandidates;