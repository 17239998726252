import React from "react";
import EmailSidebar from "../../components/Triage/emailSidebar";
import Topbar from "../../components/Recruiter/Topbar/Topbar";
const TriageSidebar = () => {
    return (
        <div className="radial-gradient-bg">
                <div>
                <Topbar/>
                </div>
                <EmailSidebar />
            </div>
    );
}
export default TriageSidebar;