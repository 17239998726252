import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import searchlens from '../../assets/search-lens.png'
import { AuthContext } from "../../context/AuthContext";
import CryptoJS from 'crypto-js';
import { useCommonContext } from '../../context/CommonContext';

const Projects = () => {
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const toggleDropdown = (id) => {
    setDropdownOpen(dropdownOpen === id ? null : id);
  };
  const { searchTerm, setSearchTerm } = useCommonContext();
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useContext(AuthContext);
  const [roleID, setRoleID] = useState(false);
  const [userName, setUserName] = useState("");
  const [userDesignation, setUserDesignation] = useState("");
  const [recentlyViewedProjects, setRecentlyViewedProjects] = useState([]);
  const [recentlySearched, setRecentlySearched] = useState([]);

  useEffect(() => {
    recentProject();
  }, []);

  useEffect(() => {
    const initializeUserData = () => {
      try {
        const encryptedMetadata = localStorage.getItem('metadata');
        const decryptedString = CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8);
        const decryptedMetadata = JSON.parse(decryptedString);

        if (!userName)
          setUserName(decryptedMetadata.name || "");
        if (!userDesignation)
          setUserDesignation(decryptedMetadata.designation || "");

      } catch (error) {
        console.error("Error initializing user data:", error);
      }
    };
    if (!userName && !userDesignation) {
      initializeUserData();
    }
  }, []);
  const navigate = useNavigate()

  const handleJobNavigation = () => {
    navigate('/Projects')
  }

  const handleSearch = async (searchTerm) => {
    try {
      setSearchTerm(searchTerm);
      var cid;
      const encryptedMetadata = localStorage.getItem('metadata');

      const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));

      if (decryptedMetadata.cid) {
        cid = decryptedMetadata.cid;
      }

      const searchDetails = { company_id: cid };
      const encryptedDashData = CryptoJS.AES.encrypt(JSON.stringify(searchDetails), process.env.REACT_APP_SECRETKEY).toString();

      const encryptDashjson = { data: encryptedDashData };
      setIsLoading(true);

      const serverURL = process.env.REACT_APP_SERVER_URL;
      const response = await fetch(`${serverURL}/job/getDashboardData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify(encryptDashjson),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Something went wrong");
      }

      const responsedata = await response.json();
      const decryptedData = JSON.parse(CryptoJS.AES.decrypt(responsedata.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));

      console.log("Decrypted Search Data:", decryptedData);

      decryptedMetadata['jd_id'] = decryptedData.jd_id;
      decryptedMetadata['search'] = searchTerm;
      const encryptedUpdatedMetadata = CryptoJS.AES.encrypt(JSON.stringify(decryptedMetadata), process.env.REACT_APP_SECRETKEY).toString();
      localStorage.setItem('metadata', encryptedUpdatedMetadata);

      navigate("/Homesearchresults", { state: { searchTermState: searchTerm } });
      setSearchTerm("");

    } catch (error) {
      console.log('Error:', error);
      let errorMessage = "Something went wrong.";
      if (error.response && error.response.data && error.response.data.msg) {
        errorMessage = error.response.data.msg;
      } else if (error.message) {
        errorMessage = error.message;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const recentProject = async () => {
    let role_id;
    let email;
    let cid;
    const encryptedMetadata = localStorage.getItem('metadata');
    if (encryptedMetadata) {
      const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
      console.log(decryptedMetadata);
      if (decryptedMetadata && decryptedMetadata.role_id)
        role_id = decryptedMetadata.role_id;
      if (decryptedMetadata && decryptedMetadata.cid)
        cid = decryptedMetadata.cid;
    }
    setRoleID(role_id);

    if (role_id === 1) {
      const cardContent = {
        company_id: cid,
      };

      const encryptedDashData = CryptoJS.AES.encrypt(JSON.stringify(cardContent), process.env.REACT_APP_SECRETKEY).toString();
      const encryptDashjson = { data: encryptedDashData };
      setIsLoading(true);

      try {
        const serverURL = process.env.REACT_APP_SERVER_URL;
        const response = await fetch(`${serverURL}/job/getDashboardData`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
          body: JSON.stringify(encryptDashjson),
        });

        const responsedata = await response.json();
        if (!response.ok) {
          throw new Error(responsedata.error || "Something went wrong");
        }

        const decryptedData = JSON.parse(CryptoJS.AES.decrypt(responsedata.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        console.log("Recent decryptedData", decryptedData);

        const jobDetails = decryptedData.jobDetails;
        const jobStats = jobDetails.slice(0, 3).map(job => {
          const createdAt = new Date(job.create_at);

          return {
            job_id: job.job_id,
            title: job.job_title,
            created_by: userName || job.created_by,
            created_at: createdAt.toLocaleDateString("en-GB"),
            pipeline: job.pipeline || 0,
            applicants_total: job.applicants_total || 0,
            applicants_new: job.applicants_news_total || 0,

          };
        });

        setRecentlyViewedProjects(jobStats);

        const recentSearches = decryptedData.recentSearches;
        const recentSearchStats = recentSearches.slice(0, 3).map((search) => {
          const createdAt = new Date(search.created_at);
          return {
            jd_id: search.jd_id,
            search: search.search,
            created_at: createdAt.toLocaleDateString("en-GB"),
          };
        });
        console.log("Recent Searches", recentSearchStats);
        setRecentlySearched(recentSearchStats);


      } catch (error) {
        console.log('Error:', error);
        let errorMessage = "Something went wrong.";
        if (error.response && error.response.data && error.response.data.msg) {
          errorMessage = error.response.data.msg;
        } else if (error.message) {
          errorMessage = error.message;
        }
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleCreateClick = () => {
    navigate('/JobDashboard');
  
};
  return (
    <div className="dashboard-project mobile-flex-column">
      {/* Left Side - Main Content */}
      <div className="main-content-project">
        <div className="recently-viewed">
          <h3>Recently Viewed Jobs</h3>
          {recentlyViewedProjects.length > 0 ? (
            recentlyViewedProjects.map((project) => (
            <div className="card" key={project.id} onClick={handleJobNavigation}>
              <div className="card-left">
                <h4 className='font-bold'>{project.title}</h4>
                <p className='mb-2'>Created By : {project.created_by} <span className='dot-status active inline-block'></span> Posted : {project.created_at}</p>
                <p className='mb-2'>Job : {project.title}</p>
              </div>

              {/* Center Section */}
              <div className="card-center">
                <div className="number-box">{project.pipeline}</div>
                <p className="separator">Pipeline</p>
              </div>

              {/* Right Section */}
              <div className="card-right">
                <div className="number-box">{project.applicants_total}{`(${project.applicants_new} New)`}</div>
                <p className="separator">Applicants</p>
              </div>
              <div className="menu">
                <span className="three-dots" onClick={() => toggleDropdown(project.id)}>
                  <span style={{ height: '5px', width: '5px', backgroundColor: '#059669', display: 'inline-block', borderRadius: '10px', marginRight: '2px' }}></span>
                  <span style={{ height: '5px', width: '5px', backgroundColor: '#059669', display: 'inline-block', borderRadius: '10px', marginRight: '2px' }}></span>
                  <span style={{ height: '5px', width: '5px', backgroundColor: '#059669', display: 'inline-block', borderRadius: '10px', marginRight: '2px' }}></span>
                </span>
                {dropdownOpen === project.id && (
                  <div className="dropdown absolute">
                    <p>Edit</p>
                    <p>Delete</p>
                    <p>View Details</p>
                  </div>
                )}
              </div>
            </div>
          ))
          ) : (
          <>
          <div className="flex items-center flex-col justify-center gap-2">
            <img src="./image/ResumeBoxBg.svg" alt="" />
            <div className="w-full max-w-[400px] text-center">
              <h1 className="plusJakartaSansFont text-3xl">
                No Jobs Posted Yet!
              </h1>
              <p className="mt-2 max-w-[80%] mx-auto text-center">
                Kickstart your hiring journey and connect with pre-screened candidates tailored to your roles.
              </p>
              <button
                onClick={handleCreateClick}
                className="mt-4 text-white font-medium text-lg px-4 py-2 rounded bg-[#059669] plusJakartaSansFont"
              >
                Create Job
              </button>
            </div>
          </div>
        </>
    )}
        </div>
      </div>

      {/* Right Side - Continue search section */}
      <div className="sidebar-project">
        <div className="continue-search">
          <h3>Continue Your Search</h3>
          {recentlySearched.length > 0 ? (
            recentlySearched.map((search) => (
            <div className="search-item" key={search.jd_id} onClick={() => handleSearch(search.search)}>
              <div className='search-date'>
                <p><img src={searchlens} className='search-star ' /> Search</p>
                <p>{search.created_at}</p>
              </div>
              <p className='ml-2 font-normal'>{search.search}</p>
            </div>
        ))
        ) : (
        <div className="mt-3">
          <div className="bg-white bg-opacity-80 text-center p-4  rounded-xl">
            <h1 className="plusJakartaSansFont text-2xl text-center">Your Search History is Empty</h1>
            <p className="mt-2 max-w-[70%] mx-auto text-center">Share the role you're hiring for and let our AI find you ideal candidates.</p>
          </div>
        </div>                  
        )}
        </div>
      </div>
    </div>
  );
};

export default Projects;
