import React, { useState,useContext, useEffect } from "react";
import Textinput from "../elements/TextInput/TextInput";
import Button from "../elements/Button/Button";
import JobDashCss from "./JobDashboard.css";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import CryptoJS from "crypto-js";
import { AuthContext } from "../../context/AuthContext";
import ModalBox from "../elements/ModalBox/ModalBox";
import { useCommonContext } from "../../context/CommonContext";
const JobDashboard = () =>{
    const { token } = useContext(AuthContext);
    const {
        isModalVisible, 
        setIsModalVisible,
        modalHtmlcontent, 
        setModalHtmlContent, setJobCreationData, jobCreationData } = useCommonContext();


    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeIndexPrev, setActiveIndexPrev] = useState(null);
    const [prevJD, setPrevJD] = useState([]);
    const [jdOpen, setJdOpen] = useState("");
    // const [isModalVisible, setIsModalVisible] = useState(false);
    useEffect(() => {
        const fetchNextQuestion = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/JD/fetchAllJD`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      token: token
                    }
                });        
                const data = await response.json();
                const JdData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                const lastFiveEntries = JdData.slice(-5);
                setPrevJD(lastFiveEntries);
            } catch (error) {
                console.error("Failed to fetch the next question:", error);
            } finally {
                // console.log(prevJD);
            }
        };
        if(prevJD.length === 0){
            fetchNextQuestion();
        }

    },[])


    const recentData = ["Ux Designer","Ux Designer"];
    const previousData = ["Ux Designer","Ux Designer","Ux Designer","Ux Designer","Ux Designer","Ux Designer"];
    useEffect(()=>{
        localStorage.setItem("jobCreationData", JSON.stringify(jobCreationData));
    },[jobCreationData])
    const setJD = (jd, jdtitle) => {
        setJobCreationData((prev) => ({
            ...prev,
            jobTitle: jdtitle,
            description: jd,
        }));
        setTimeout(() => {
            navigate('/NewJobPost');
            setIsModalVisible(false);
            setModalHtmlContent(null);
        }, 100);
    };
    

    const makeButtonActive = async (index,type,jd,jobTitle) =>{   
        const jdHtml = (
            <div>
                <h3>Job Title:</h3>
                <p>{jobTitle}</p>
                <h3>Responsibilities:</h3>
                <ul>
                    {jd
                        .split("\n\n")[1]
                        .split("\n- ")
                        .slice(1)
                        .map((item, idx) => (
                            <li key={`responsibility-${idx}`}>{item}</li>
                        ))}
                </ul>
                <h3>Skills:</h3>
                <ul>
                    {jd
                        .split("\n\n")[2]
                        .split("\n- ")
                        .slice(1)
                        .map((item, idx) => (
                            <li key={`skill-${idx}`}>{item}</li>
                        ))}
                </ul>
                <h3>Qualifications:</h3>
                <ul>
                    {jd
                        .split("\n\n")[3]
                        .split("\n- ")
                        .slice(1)
                        .map((item, idx) => (
                            <li key={`qualification-${idx}`}>{item}</li>
                        ))}
                </ul>
                <div
                    className="w-full p-4"
                    style={{
                        display: "flex",
                        justifyContent: "right",
                        alignItems: "right",
                    }}
                >
                    <button
                        onClick={() => setJD(jd, jobTitle)} // Call setJD directly
                        className="signin-button button-next w-auto"
                        style={{
                            padding: "10px 30px",
                            marginRight: "10px",
                            backgroundColor: "#059669",
                            borderRadius: "10px",
                            color: "white",
                        }}
                    >
                        Proceed
                    </button>
                </div>
            </div>
        );
        await setJdOpen(jdHtml);
        setIsModalVisible(true);
        openModal(jdHtml);

        if(type == "recent"){
            setActiveIndex(index);
            setActiveIndexPrev(null);
        }
        else{
            setActiveIndex(null);
            setActiveIndexPrev(index);
        }
    }
    const returntocreate = () => {
        window.location.reload();
    }
    
    // setModalHtmlContent(<div dangerouslySetInnerHTML={{ __html: jdOpen }} ></div>);
    const openModal = (jdHtml) => {
        const modalHTMLcontent = (<div dangerouslySetInnerHTML={{ __html: jdHtml }} ></div>)
        console.log(modalHTMLcontent);
        setModalHtmlContent(jdHtml);
    }
    return(
        <div>
        {/* {isModalVisible && 
        <ModalBox cardClass="no-max-width" Modal_Body={modalHTMLcontent} Modal_Close_Func={Modal_on_Close} modal_Header_Class="" />} */}
            <div className="flex gap-2 px-3 mx-4 rounded-2xl py-3 font-family" style={{ backgroundColor: 'rgba(255, 255, 255, 0.6)' }}>
                <div className="w-full p-4 rounded-2xl" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                    <div className='flex justify-center'>
                        <Button onClick={() => returntocreate()} children="+ Create New JD" className="profile_create_button text-green-700 px-4 py-2 bg-white border-green-700" />
                    </div>
                    <div className="my-8">
                        <p className="navbar_content !font-semibold mb-1">Recents</p>
                        {prevJD && prevJD.map((value,index) => (
                            <p key={index} className={`navbar_content mb-0 p-[12px] ${activeIndex === index ? "bg-gradient-to-tr from-[#EBFFF5] via-[#94E7F9] to-[#34D399]" : ""} rounded-md cursor-pointer mb-2`} onClick={(e) => makeButtonActive(index,"recent",value.jd,value.title)}>{value.title}</p>
                            ))
                        }
                    </div>
                    <div className="my-8">
                        <p className="navbar_content !font-semibold mb-1">Previous 30 days</p>
                        {prevJD && prevJD.map((value,index) => (
                            <p key={index} className={`navbar_content mb-0 p-[12px] ${activeIndexPrev === index ? "bg-gradient-to-tr from-[#EBFFF5] via-[#94E7F9] to-[#34D399]" : ""} rounded-md cursor-pointer mb-2`} onClick={(e) => makeButtonActive(index,"previous",value.jd,value.title)}>{value.title}</p>
                            ))
                        }
                    </div>
                </div>
            </div>
    
        </div>
    );
}
export default JobDashboard