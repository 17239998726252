import Eeezo from "../components/Common/Logo";
import Button from "../components/elements/Button/Button";
import Card1 from "../assets/web_card_1.png";
import Card2 from "../assets/web_card_2.png";
import Card3 from "../assets/web_card_3.png";
import feature1 from "../assets/web_feature_1.svg";
import feature2 from "../assets/web_feature_2.svg";
import feature3 from "../assets/web_feature_3.svg";
import feature4 from "../assets/web_feature_4.svg";
import getStartedBG from "../assets/web_get_started_bg.svg";
import PR_form from "../assets/web_PR_form.svg";
import PR_tick from "../assets/web_PR_tick.svg";
import PR_company from "../assets/web_PR_company.svg";
import PR_collaborators from "../assets/web_PR_collaborators.svg";
import FB_icon from "../assets/web_footer_FB_icon.svg";
import Insta_icon from "../assets/web_footer_Insta_icon.svg";
import YT_icon from "../assets/web_footer_YT_icon.svg";
import LI_icon from "../assets/web_footer_LI_icon.svg";
import X_icon from "../assets/web_footer_X_icon.svg";
import Location_icon from "../assets/web_Location_icon.svg";
import Phone_icon from "../assets/web_Phone_icon.svg";
import Mail_icon from "../assets/web_Mail_icon.svg";
import star from "../assets/star-search.png";
import React, { useState, useRef, useEffect } from "react";
import './Web.css';
import { useNavigate } from "react-router-dom";

const Web = () => {
  const navigate = useNavigate();  
  const containerRef = useRef(null); // Ref to target the container holding lazy-load elements

  useEffect(() => {
    const lazyLoadElements = containerRef.current.querySelectorAll(".lazy-load");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    lazyLoadElements.forEach((element) => observer.observe(element));

    return () => {
      observer.disconnect(); // Clean up the observer when the component unmounts
    };
  }, []);
  const suggestions = [
    "Enabling a faster hiring process",
    "Access to pre-screened candidates",
    "AI-powered matching for precision",
    "Easy-to-use dashboard.",
  ];

  const features = [
    {
      Title: "Faster hiring process",
      desription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
      icon: feature1,
    },
    {
      Title: "Access to pre-screened candidates",
      desription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
      icon: feature2,
    },
    {
      Title: "AI-powered matching for precision",
      desription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
      icon: feature3,
    },
    {
      Title: "Easy-to-use dashboard",
      desription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
      icon: feature4,
    },
    {
      Title: "AI-powered matching for precision",
      desription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
      icon: feature3,
    },
    {
      Title: "Easy-to-use dashboard",
      desription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
      icon: feature4,
    },
    {
      Title: "Access to pre-screened candidates",
      desription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
      icon: feature2,
    },
    {
      Title: "Access to pre-screened candidates",
      desription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
      icon: feature2,
    },
  ];

  return (
    <div>
      <div className="flex flex-wrap items-center gap-2 px-4">
        <div className="w-full sm:w-auto">
          <Eeezo />
        </div>

        <div className="flex items-center ml-auto w-full sm:w-auto">
          <div className="w-full sm:w-2/5 text-nowrap">
            <Button
              className="border-[1px] !border-green-700 !bg-transparent rounded-lg px-4 py-2 text-black w-full sm:w-auto"
              type="button"
              children="Claim Your Early Access"
              onClick={() => navigate('/SignIn')}
            />
          </div>
        </div>
      </div>
      {/* Card 1 */}
      <div
        className="rounded-lg justify-center mx-4 my-4"
        style={{
          background: `#000000`,
        }}
      >
        <img
          src={Card1}
          className="w-full h-auto object-cover rounded-lg"
          alt="Card 1"
        />
      </div>
      {/* Suggestions */}
      <div
        className="w-full px-4 mt-8 flex justify-start overflow-x-auto"
        style={{
          scrollbarWidth: "none",
        }}
      >
        <div className="suggestions-container flex gap-5">
          {suggestions.map((suggestion, index) => (
            <span
              className="flex items-center gap-2 p-2 bg-white text-lg md:text-xl sm:text-lg lg:text-lg "
              key={index}
            >
              <img className="search-star w-4 h-4" src={star} alt="star" />
              {suggestion}
            </span>
          ))}
        </div>
      </div>

      {/* Features */}
      <div className="gap-2 px-4 mt-8">
        <h1
          className="flex items-center justify-center font-semibold text-center mobile-flex-column"
          style={{
            fontFamily: "Plus Jakarta Sans",
          }}
        >
          <span
            style={{
              background: `linear-gradient(19.63deg, #EBFFF5 -205.81%, #FFFFFF -76.06%, #94E7F9 -10.02%, #34D399 85.89%)`,
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            {" "}
            What’s Waiting{" "}
          </span>{" "}
          for You in Early Access
        </h1>
        <span
          className="flex items-center justify-center text-center text-sm sm:text-sm md:text-base lg:text-sm"
          style={{
            color: `#00000099`,
          }}
        >
          Gain access to powerful features designed to simplify and enhance your
          hiring process:
        </span>
      </div>

      {/* Features Cards */}
      <div className="flex items-center justify-center flex-wrap gap-4 px-4 mt-8">
        <div className="mb-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10">
          {features.map((feature, index) => (
            <div
              key={index}
              className="p-4 rounded-lg text-center backdrop-blur-lg border border-gray-200"
            >
              {/* Card Icon */}
              <div className="p-2 text-center bg-green-100 w-8 h-8 rounded-md">
                <img src={feature.icon} alt="Feature Icon" />
              </div>

              <div className="mt-4 w-full">
                {/* Feature Title */}
                <div className="font-semibold text-left text-gray-800">
                  {feature.Title}
                </div>

                {/* Feature Description */}
                <div className="mt-2 text-left text-gray-600 text-sm">
                  {feature.desription}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* upcomming feature */}
      <div className="flex items-center justify-center mt-8 w-full px-4">
        <span
          className="flex items-center justify-center font-medium text-center text-xs sm:text-sm md:text-base lg:text-lg w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3"
          style={{
            color: "black",
          }}
        >
          We’re constantly innovating! As an early access user, you’ll be the
          first to explore these upcoming features
        </span>
      </div>

      {/* card 2*/}
      <div
        className="rounded-lg justify-center mx-4 my-4"
        style={{
          background: `#000000`,
        }}
      >
        <img
          src={Card2}
          className="w-full h-auto object-cover rounded-lg"
          alt="Card 2"
        />
      </div>

      {/* Get Started */}
      <div className="gap-2 px-4 mt-8">
        <h1
          className="flex items-center justify-center font-semibold text-center "
          style={{
            fontFamily: "Plus Jakarta Sans",
            background: `linear-gradient(62.65deg, #EBFFF5 -158.85%, #FFFFFF -50.88%, #94E7F9 4.08%, #34D399 83.89%)`,
            WebkitBackgroundClip: "text",
            color: "transparent",
          }}
        >
          How to get started
        </h1>
        <span
          className="flex items-center justify-center text-center text-sm sm:text-sm md:text-base lg:text-sm"
          style={{
            color: `#00000099`,
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod
        </span>
      </div>

      {/* Steps for Profile Registering */}
      <div className="flex items-center justify-between gap-4 px-4 mt-8 mobile-flex-column">
        <div
          className="mx-10 p-4 flex items-center justify-center rounded-2xl"
          style={{
            width: "100%",
            maxWidth: "80vh",
            height: "auto",
            background: `url(${getStartedBG}) center center #F2F9F9`,
            backgroundSize: "110%",
          }}
        >
          <div
            className="p-4 flex items-center justify-center flex-wrap gap-4 rounded-lg"
            style={{
              height: "auto",
              width: "100%",
              maxWidth: "50vh",
              background: `linear-gradient(60.98deg, #EBFFF5 -195.51%, #FFFFFF -56.62%, #94E7F9 14.08%, #34D399 116.74%)`,
            }}
          >
            <div className="font-semibold text-left text-gray-800">
              Steps for Profile Registering
            </div>
            <div className="flex items-center justify-center gap-5 p-4 rounded-lg bg-emerald-50">
              <div className="flex items-center justify-center gap-2">
                <div className="w-8 h-8">
                  <img src={PR_form} alt="Form Icon" />
                </div>
                <div className="mt-2">
                  <p className="font-medium text-left text-gray-800">
                    Fill out the form
                  </p>
                </div>
              </div>
              <div className="mt-2 w-8 h-8">
                <img src={PR_tick} alt="Tick Icon" />
              </div>
            </div>
            <div className="flex items-center justify-center gap-5 p-4 rounded-lg bg-emerald-50">
              <div className="flex items-center justify-center gap-2">
                <div className="w-8 h-8">
                  <img src={PR_company} alt="Company Icon" />
                </div>
                <div className="mt-2">
                  <p className="font-medium text-left text-gray-800">
                    Company Details
                  </p>
                </div>
              </div>
              <div className="mt-2 w-8 h-8">
                <img src={PR_tick} alt="Tick Icon" />
              </div>
            </div>
            <div className="flex items-center justify-center gap-5 p-4 rounded-lg bg-emerald-50">
              <div className="flex items-center justify-center gap-2">
                <div className="w-8 h-8">
                  <img src={PR_collaborators} alt="Collaborators Icon" />
                </div>
                <div className="mt-2">
                  <p className="font-medium text-left text-gray-800">
                    Add Collaborators
                  </p>
                </div>
              </div>
              <div className="mt-2 w-8 h-8">
                <img src={PR_tick} alt="Tick Icon" />
              </div>
            </div>
          </div>
        </div>

        {/* Steps for Profile Registering */}
        <div className="p-4 flex items-center justify-center flex-col gap-1  max-w-30vh" ref={containerRef}>
          <div className="flex items-left justify-center flex-col gap-2 p-2 w-full lazy-load">
            <div className="text-green-700 bg-emerald-50 p-1 rounded-lg w-20 text-center">
              Step : 1
            </div>
            <div className="font-medium text-left text-gray-800">
              Sign up and create your company profile
            </div>
            <hr
              className="w-full"
              style={{
                border: "none",
                borderTop: "2px solid",
                borderImage:
                  "linear-gradient(to right,rgb(11, 188, 123) 50%,rgb(185, 182, 182) 50%) 1",
              }}
            ></hr>
          </div>
          <div className="flex items-left justify-center flex-col gap-2 p-2 w-full lazy-load">
            <div className="text-green-700 border-2 border-green-100 p-1 rounded-lg w-20 text-center">
              Step : 2
            </div>
            <div className="font-medium text-left text-gray-800">
              Upload your database of profiles to resumebox
            </div>
            <hr
              className="w-full"
              style={{
                border: "1px solid rgb(185, 182, 182)",
              }}
            ></hr>
          </div>
          <div className="flex items-left justify-center flex-col gap-2 p-2 w-full lazy-load">
            <div className="text-green-700 border-2 border-green-100 p-1 rounded-lg w-20 text-center">
              Step : 3
            </div>
            <div className="font-medium text-left text-gray-800">
              Leverage our AI to create accurate job descriptions for available
              positions
            </div>
            <hr
              className="w-full"
              style={{
                border: "1px solid rgb(185, 182, 182)",
              }}
            ></hr>
          </div>
          <div className="flex items-left justify-center flex-col gap-2 p-2 w-full lazy-load">
            <div className="text-green-700 border-2 border-green-100 p-1 rounded-lg w-20 text-center">
              Step : 4
            </div>
            <div className="font-medium text-left text-gray-800">
              Use AI recommendations to shortlist & hire
            </div>
          </div>
        </div>
      </div>

      {/* Card 3 */}
      <div
        className="rounded-lg justify-center mx-4 my-4"
        style={{
          background: `#000000`,
        }}
      >
        <img
          src={Card3}
          className="w-full h-auto object-cover rounded-lg"
          alt="Card 3"
        />
      </div>

      <div
        className="flex items-center flex-col justify-between gap-5 px-4  mt-8 w-full"
        style={{
          background: "#E0EBEA",
          height: "auto",
          width: "auto",
        }}
      >
        <div className="flex items-center justify-between px-28 pt-10 w-full mobile-flex-column mobilepadding-10 mobile-gap-15">
          <div>
            <div className=" flex items-center justify-center flex-col gap-1">
              <div
                className="flex items-left justify-center flex-col gap-2  w-full"
                style={{
                  textAlign: "left",
                  border: "none",
                }}
              >
                <div>
                  <Eeezo />
                </div>
                <p
                  className=" w-80 text-wrap"
                  style={{
                    fontFamily: "Plus Jakarta Sans",
                    fontSize: "15px",
                    fontWeight: "400",
                    lineWeight: "22.68px",
                    textAlign: "left",
                    color: "#000000",
                    gap: "0px",
                    opacity: "0px",
                  }}
                >
                  Eeezo is lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. Ut enim ad minim veniam, quis nostrud
                </p>
                <div className="flex items-left justify-left gap-4 ">
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      gap: "0px",
                      opacity: "0px",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="https://www.facebook.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={FB_icon} alt="Facebook" />
                    </a>
                  </div>
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      gap: "0px",
                      opacity: "0px",
                    }}
                  >
                    <a
                      href="https://www.instagram.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={Insta_icon} alt="Instagram" />
                    </a>
                  </div>
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      gap: "0px",
                      opacity: "0px",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="https://in.linkedin.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={LI_icon} alt="Linkedin" />
                    </a>
                  </div>
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      gap: "0px",
                      opacity: "0px",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="https://www.X.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={X_icon} alt="X" />
                    </a>
                  </div>
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      gap: "0px",
                      opacity: "0px",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="https://www.youtube.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={YT_icon} alt="YouTube" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-full-width">
            <div className="flex items-left justify-left flex-col gap-1">
              <p
                style={{
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineWeight: "28.77px",
                  textAlign: "left",
                  textUnderlinePosition: "from-font",
                  textDecorationSkipInk: "none",
                  color: "#363848",
                  gap: "0px",
                  opacity: "0px",
                }}
              >
                Get in Touch with Us
              </p>
              <div className="flex items-left justify-left gap-2 ">
                <div
                  className="mt-2"
                  style={{
                    width: "12px",
                    height: "12px",
                    gap: "0px",
                    opacity: "0px",
                  }}
                >
                  {<img src={Location_icon} />}
                </div>
                <div className="">
                  <span
                    style={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: "12px",
                      fontWeight: "300",
                      lineWeight: "22.68px",
                      textAlign: "left",
                      color: "#000000",
                      gap: "0px",
                      opacity: "0px",
                    }}
                  >
                    Bengaluru, India
                  </span>
                </div>
              </div>
              <div className="flex items-left justify-left gap-2 ">
                <div
                  className="mt-2"
                  style={{
                    width: "12px",
                    height: "12px",
                    gap: "0px",
                    opacity: "0px",
                  }}
                >
                  {<img src={Phone_icon} />}
                </div>
                <div className="">
                  <span
                    style={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: "12px",
                      fontWeight: "300",
                      lineWeight: "22.68px",
                      textAlign: "left",
                      color: "#000000",
                      gap: "0px",
                      opacity: "0px",
                    }}
                  >
                    +123 345123 556
                  </span>
                </div>
              </div>
              <div className="flex items-left justify-left gap-2 ">
                <div
                  className="mt-2"
                  style={{
                    width: "12px",
                    height: "12px",
                    gap: "0px",
                    opacity: "0px",
                  }}
                >
                  {<img src={Mail_icon} />}
                </div>
                <div className="">
                  <span
                    style={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: "12px",
                      fontWeight: "300",
                      lineWeight: "22.68px",
                      textAlign: "left",
                      color: "#000000",
                      gap: "0px",
                      opacity: "0px",
                    }}
                  >
                    support@eeezo.in
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p
          style={{
            fontFamily: "Plus Jakarta Sans",
            fontSize: "10px",
            fontWeight: "400",
            lineWeight: "20.77px",
            textAlign: "left",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
            color: "#363848",
            gap: "0px",
            opacity: "0px",
          }}
        >
          © 2024, Eeezo Limited. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Web;
