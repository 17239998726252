import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
// Router Import
import SignIn from "./pages/SignIn";
import SignInJobSeeker from "./pages/JobSeeker/SignIn";
import SignUpJobSeeker from "./pages/JobSeeker/SignUp";
import Dashboard from "./pages/Dashboard";
import Web from "./pages/Web";
import ShowSharedJob from "./pages/ShowSharedJob";
import RegisterForm from "./pages/SignUp";
import RecruiterProfile from "./pages/Recruiter/Profile";
// import ProjectsBar from "./components/elements/ProjectSidebar/ProjectSideBar";
import ProjectPipe from "./pages/Piplines/ProjectPipeline";
import AddTeam from "./pages/AddTeam/UserSettings";
import AddTeamMember from "./pages/AddTeam/TeamMember";
import CompanyDetail from "./pages/CompayProfiles/CompanyAdd";
import CompanySummary from "./pages/CompayProfiles/CompanySummary";
import NotFound from "./pages/NotFound"; // Import the NotFound page
import GetAuthCode from "./components/GetAuthCode";
// import GoogleCalendarIntegrationSI from "./pages/GoogleCalendarIntegration";
import GmailSI from "./pages/GmailSignIn";
import HomeSearch from "./pages/HomeSearchResults/HomeSearchResults";
import ResumeBox from "./pages/ResumeBox/ResumeBox";
import ResumeMatch from "./pages/ResumeBox/ResumeMatch";
import ManageWorkspace from "./pages/CompayProfiles/ManageWorkspace";
import JobSeekerOnboard from "./pages/UserExperience/Onboard";
// import EmailTriage from "./pages/Triage/Triage";
// Context Import
import { AuthProvider, useAuth } from "./context/AuthContext";
import Team from "./pages/TeamMember/TeamMember";
import Message from "./pages/MessageTemplate/messageTemplate";
import JobFilter from "./pages/JobListing/JobListing";
import NewJobPost from "./pages/JobPost/JobPost";
import AiJobPost from "./pages/JobPost/AiJobPost";
import JobDashboard from "./pages/JobPost/JobDashboard";
import { CommonContextProvider } from "./context/CommonContext";
import JobCreation from "./pages/JobCreation/JobCreation";
import JobApplicant from "./pages/Applicants/Applicants";
import MessagePage from "./pages/Message/Message";
import JobApprove from "./pages/JobApprove/JobApprove";
import CalendlyComponent from "./components/Calendar/Calendly";
import Reassign from "./components/Team/Reassign";
import ViewBooking from "./pages/ViewBooking/ViewBooking";
import ViewBookingPage from "./pages/ViewBooking/ViewBookingPage";
import TriageSidebar from "./pages/Triage/emailSidebar";
import EmailView from "./pages/Triage/emailview";
import ViewMessage from "./pages/Triage/ViewMessage";
import LinkedInSignInI from "./pages/LinkedInSignIn";
// Custom Auth Route Component
const AuthRoute = ({ element, path }) => {
  const { token } = useAuth(); // Get the token from context

  // If the path is '/SignUp', always allow access
  if (path === "/signup" && !token && path === "/" && path === "/showjob") {
    return element; // <Navigate to="/signUp" />;
  }

  // If token exists, allow access to other routes, otherwise redirect to '/'
  return token ? element : <Navigate to="/SignIn" />;
  // return element;
};
// Custom Redirect Route Component
const RedirectRoute = ({ element }) => {
  const { isAuthenticated } = useAuth(); // Check if the user is authenticated
  const location = useLocation();

  if (
    isAuthenticated &&
    (location.pathname === "/showjob" ||
      location.pathname === "/" ||
      location.pathname === "/SignUp")
  ) {
    return <Navigate to="/Dashboard" />;
  }

  return element;
};

const AppContent = () => {
  const { verifyUserToken } = useAuth();
  const location = useLocation();

  // Verify token on location change
  useEffect(() => {
    verifyUserToken();
  }, [location, verifyUserToken]);

  return (
    <Routes>
      <Route
        path="/ViewBooking/:job_id/:candidate_id"
        element={<ViewBooking />}
      />
      <Route path="/ViewBookingPage" element={<ViewBookingPage />} />
      <Route path="/SignIn" element={<RedirectRoute element={<SignIn />} />} />
      <Route
        path="/SignUp"
        element={<RedirectRoute element={<RegisterForm />} />}
      />
      <Route path="/" element={<Web />} />
      <Route path="/showjob" element={<ShowSharedJob />} />
      <Route
        path="/Dashboard"
        element={<AuthRoute element={<Dashboard />} />}
      />
      <Route
        path="/AddCompany"
        element={<AuthRoute element={<CompanyDetail />} />}
      />
      <Route
        path="/UserSettings"
        element={<AuthRoute element={<AddTeam />} />}
      />
      <Route
        path="/TeamMember"
        element={<AuthRoute element={<AddTeamMember />} />}
      />
      <Route
        path="/CompanyOverview"
        element={<AuthRoute element={<CompanySummary />} />}
      />
      <Route
        path="/Recruiter/Profile"
        element={<AuthRoute element={<RecruiterProfile />} />}
      />
      {/* <Route path="/sideview" element={<AuthRoute element={<ProjectsBar />} />} /> */}
      <Route
        path="/Projects"
        element={<AuthRoute element={<ProjectPipe />} />}
      />
      <Route
        path="/JobListing"
        element={<AuthRoute element={<JobFilter />} />}
      />
      <Route
        path="/NewJobPost"
        element={<AuthRoute element={<NewJobPost />} />}
      />
      <Route
        path="/HomeSearchResults"
        element={<AuthRoute element={<HomeSearch />} />}
      />
      <Route
        path="/AiJobPost"
        element={<AuthRoute element={<AiJobPost />} />}
      />
      <Route
        path="/JobDashboard"
        element={<AuthRoute element={<JobDashboard />} />}
      />
      <Route
        path="/UserExperience"
        element={<AuthRoute element={<JobSeekerOnboard />} />}
      />
      <Route
        path="/JobCreation"
        element={<AuthRoute element={<JobCreation />} />}
      />
      <Route
        path="/JobApprove"
        element={<AuthRoute element={<JobApprove />} />}
      />
      {/* Catch-all route for undefined routes */}
      <Route
        path="/GmailSignIn"
        element={<RedirectRoute element={<GmailSI />} />}
      />
      <Route
        path="/Resume"
        element={<RedirectRoute element={<ResumeBox />} />}
      />
      <Route
        path="/Reassign"
        element={<RedirectRoute element={<Reassign />} />}
      />
      {/* <Route path="/GMEET" element={<RedirectRoute element ={<GoogleCalendarIntegrationSI />} />} /> */}
      <Route
        path="/Resumematch"
        element={<RedirectRoute element={<ResumeMatch />} />}
      />
      <Route
        path="/ManageWorkspace"
        element={<RedirectRoute element={<ManageWorkspace />} />}
      />
      <Route
        path="/GMEET"
        element={<RedirectRoute element={<GetAuthCode />} />}
      />
      <Route
        path="/Triage"
        element={<RedirectRoute element={<TriageSidebar />} />}
      />
      <Route
        path="/emailView"
        element={<RedirectRoute element={<EmailView />} />}
      />
      <Route
        path="/ViewMessage"
        element={<RedirectRoute element={<ViewMessage />} />}
      />
      <Route
        path="/LinkedInSignIn"
        element={<RedirectRoute element={<LinkedInSignInI />} />}
      />

      {/* <Route path="/GMEET" element={<RedirectRoute element ={<GoogleCalendarIntegrationSI />} />} /> */}
      {/* Catch-all route for undefined routes */}

      <Route path="*" element={<NotFound />} />
      <Route
        path="/Applicants"
        element={<AuthRoute element={<JobApplicant />} />}
      />
      <Route
        path="/Message"
        element={<AuthRoute element={<MessagePage />} />}
      />
      <Route
        path="/calendar"
        element={<AuthRoute element={<CalendlyComponent />} />}
      />

      {/* JobSeeker Routes */}
      <Route
        path="/JobSeeker/SignIn"
        element={<RedirectRoute element={<SignInJobSeeker />} />}
      />
      <Route
        path="/JobSeeker/SignUp"
        element={<RedirectRoute element={<SignUpJobSeeker />} />}
      />
    </Routes>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <CommonContextProvider>
        <AppContent />
      </CommonContextProvider>
    </AuthProvider>
  );
};

export default App;
