import React, { useState, useEffect, useContext } from "react";
import Webenza from "../../assets/Images/webenza.jpg";
import CryptoJS from "crypto-js";
import { AuthContext } from "../../context/AuthContext";
import Notification from "../Notification/Notification";

const ShareJob = ({ project, closeSharejobModal }) => {
  const { token } = useContext(AuthContext);
  const [metaData, setMetaData] = useState(null);
  const [jobCreatedBy, setjobCreatedBy] = useState("");
  const [companyProfile, setCompanyProfile] = useState("");
  const [companyProfileName, setCompanyProfileName] = useState("");
  const [shareJobLink, setshareJobLink] = useState("");
  const [isDataReady, setIsDataReady] = useState(false);
  const [notification, setCoppyNotification] = useState({
    message: "",
    type: "",
  });
  const showCoppyNotification = (message, type) => {
    //For Notification
    setCoppyNotification({ message, type });
    setTimeout(() => {
      setCoppyNotification({ message: "", type: "" });
    }, 3000);
  };

  useEffect(() => {
    try {
      const encryptedMetadata = localStorage.getItem("metadata");
      if (encryptedMetadata) {
        const decryptedMetadata = JSON.parse(
          CryptoJS.AES.decrypt(
            encryptedMetadata,
            process.env.REACT_APP_SECRETKEY
          ).toString(CryptoJS.enc.Utf8)
        );
        setMetaData(decryptedMetadata);
        setjobCreatedBy(decryptedMetadata.name);
        CompanyProfile(decryptedMetadata.email); // Use decrypted metadata email
      }
    } catch (error) {
      console.error("Error during decryption:", error);
    }
  }, []);

  const CompanyProfile = async (email) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(email),
      process.env.REACT_APP_SECRETKEY
    ).toString();

    const encryptjson = { data: encryptedData };

    const serverURL = process.env.REACT_APP_SERVER_URL;

    try {
      const response = await fetch(`${serverURL}/company/fetchCompanyDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify(encryptjson),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const decryptedData = JSON.parse(
        CryptoJS.AES.decrypt(
          data.data,
          process.env.REACT_APP_SECRETKEY
        ).toString(CryptoJS.enc.Utf8)
      );

      if (decryptedData && decryptedData[0] && decryptedData[0]["profile"]) {
        const companyLogoUrl =
          process.env.REACT_APP_SERVER_BASEURL +
          "/companyprofile/" +
          decryptedData[0]["profile"];
        setCompanyProfile(companyLogoUrl);
        setCompanyProfileName(decryptedData[0]["profile"]);
      } else {
        throw new Error("Invalid company data");
      }
    } catch (error) {
      console.error("Error fetching company profile:", error);
    }
  };

  // Only run when project, companyProfileName, jobCreatedBy are set
  useEffect(() => {
    if (project && companyProfileName && jobCreatedBy) {
      setIsDataReady(true);
    }
  }, [project, companyProfileName, jobCreatedBy]);

  useEffect(() => {
    if (isDataReady) {
      GenerateShareJobLink();
    }
  }, [isDataReady]);

  const GenerateShareJobLink = async () => {
    console.log("Generating link with:", {
      project,
      companyProfileName,
      jobCreatedBy,
    });
    if (companyProfileName && jobCreatedBy && project) {
      const dynamicLink = `${process.env.REACT_APP_FRONTEND_URL}/showjob?id=${project.id}&status=${project.status}&profile=${companyProfileName}&userName=${jobCreatedBy}`;
      setshareJobLink(dynamicLink);
      console.log("Generated Share Job Link:", dynamicLink);
    } else {
      console.error("Missing required data to generate share job link.");
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showCoppyNotification("Link copied to clipboard!", "success");
        console.log("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  return (
    <>
      <Notification
        message={notification.message}
        type={notification.type}
        onClose={() => setCoppyNotification({ message: "", type: "" })}
      />

      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
        <div className="relative p-4 w-full max-w-xl sm:max-w-2xl lg:max-w-3xl">
          <div className="bg-white rounded-2xl shadow-md p-4">
            {/* Header */}
            <div className="flex flex-col sm:flex-row items-center justify-between sm:space-x-4">
              <div className="flex flex-col sm:flex-row items-center">
                <img
                  src={companyProfile || Webenza}
                  className="w-12 h-12 border-1 border-gray-300 rounded-lg object-cover"
                />
                <div className="ml-4 mt-4 sm:mt-0">
                  <p className="text-xs sm:text-sm text-gray-500">
                    {metaData?.company_name || ""}
                  </p>
                  <p className="text-base sm:text-lg font-medium text-gray-800 truncate">
                    {project?.title || ""}
                  </p>

                  {/* Job Details */}
                  <div className="flex flex-wrap gap-2 mt-2">
                    {project?.experiance && (
                      <span className="text-xs bg-green-50 px-2 py-1 rounded-lg">
                        {project.experiance || "Fresher"}
                      </span>
                    )}
                    {project?.WorkplaceType && (
                      <span className="text-xs bg-green-50 px-2 py-1 rounded-lg">
                        {project.WorkplaceType || "On-Site"}
                      </span>
                    )}
                    {project?.location && (
                      <span className="text-xs bg-green-50 px-2 py-1 rounded-lg">
                        {project.location}
                      </span>
                    )}
                    {project?.salary && (
                      <span className="text-xs bg-green-50 px-2 py-1 rounded-lg">
                        {project.salary}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {/* Close Button */}
              <button
                className="absolute top-10 right-10 text-gray-500 bg-green-50 p-2 rounded-full focus:outline-none"
                aria-label="Close modal"
                onClick={closeSharejobModal}
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>

            <hr className="my-4" />
            <div className="px-4 mb-4">
              <span className="text-sm font-medium text-gray-800 bg-yellow-400 p-1 border-1 border-yellow-200 rounded-lg text-black">
                Share Job
              </span>

              <div className="flex flex-wrap items-center justify-between mt-4 gap-2">
                {/* Share Icons */}
                <div className="flex gap-2">
                  {/* Linked In*/}
                  <div
                    onClick={() => {
                      const message = shareJobLink;
                      const linkedInMessageUrl = `https://www.linkedin.com/messaging/compose/?body=${encodeURIComponent(
                        message
                      )}`;
                      window.open(linkedInMessageUrl, "_blank");
                    }}
                    className="border-1 border-gray-300 rounded-full object-cover bg-green-50 cursor-pointer p-2"
                  >
                    <svg
                      version="1.1"
                      id="Icons"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32 32"
                      fill="#000000"
                      height="15"
                      width="15"
                    >
                      <path
                        style={{ fill: "#0097D3" }}
                        d="M23,0H9C4,0,0,4,0,9v14c0,5,4,9,9,9h14c5,0,9-4,9-9V9C32,4,28,0,23,0z"
                      ></path>
                      <path
                        style={{ fill: "#FFFFFF" }}
                        d="M12,25c0,0.6-0.4,1-1,1H7c-0.6,0-1-0.4-1-1V13c0-0.6,0.4-1,1-1h4c0.6,0,1,0.4,1,1V25z M9,11c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S10.7,11,9,11z M26,25c0,0.6-0.4,1-1,1h-3c-0.6,0-1-0.4-1-1v-3.5v-1v-2c0-0.8-0.7-1.5-1.5-1.5S18,17.7,18,18.5v2v1V25c0,0.6-0.4,1-1,1h-3c-0.6,0-1-0.4-1-1V13c0-0.6,0.4-1,1-1h4c0.3,0,0.5,0.1,0.7,0.3c0.6-0.2,1.2-0.3,1.8-0.3c3,0,5.5,2.5,5.5,5.5V25z"
                      ></path>
                    </svg>
                  </div>
                  {/* What's APP*/}
                  <div
                    onClick={() => window.open("https://wa.me/", "_blank")}
                    className="border-1 border-gray-300 rounded-full object-cover bg-green-50 cursor-pointer p-2"
                  >
                    <svg
                      fill="#25D366"
                      height="15"
                      width="15"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 308 308"
                      // onClick={() => {
                      //   const phoneNumber = "919698273271"; // Replace with the recipient's phone number
                      //   const message =
                      //     "Hello! I wanted to reach out about [insert topic here].";
                      //   const encodedMessage = encodeURIComponent(message);
                      //   const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
                      //   window.open(whatsappUrl, "_blank");
                      // }}
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <g id="XMLID_468_">
                          <path
                            id="XMLID_469_"
                            d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156 c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687 c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887 c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153 c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348 c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802 c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922 c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0 c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458 C233.168,179.508,230.845,178.393,227.904,176.981z"
                          ></path>
                          <path
                            id="XMLID_470_"
                            d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716 c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396 c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188 l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867 C276.546,215.678,222.799,268.994,156.734,268.994z"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                  {/* Facebook*/}
                  <div
                    onClick={() => {
                      const message = shareJobLink;
                      const facebookMessageUrl = `https://m.me/?text=${encodeURIComponent(
                        message
                      )}`;
                      window.open(facebookMessageUrl, "_blank");
                    }}
                    className="border-1 border-gray-300 rounded-full object-cover bg-green-50 cursor-pointer p-2"
                  >
                    <svg
                      fill="#0866FF"
                      height="16"
                      width="16"
                      viewBox="0 0 32 32"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M30.996 16.091c-0.001-8.281-6.714-14.994-14.996-14.994s-14.996 6.714-14.996 14.996c0 7.455 5.44 13.639 12.566 14.8l0.086 0.012v-10.478h-3.808v-4.336h3.808v-3.302c-0.019-0.167-0.029-0.361-0.029-0.557 0-2.923 2.37-5.293 5.293-5.293 0.141 0 0.281 0.006 0.42 0.016l-0.018-0.001c1.199 0.017 2.359 0.123 3.491 0.312l-0.134-0.019v3.69h-1.892c-0.086-0.012-0.185-0.019-0.285-0.019-1.197 0-2.168 0.97-2.168 2.168 0 0.068 0.003 0.135 0.009 0.202l-0.001-0.009v2.812h4.159l-0.665 4.336h-3.494v10.478c7.213-1.174 12.653-7.359 12.654-14.814v-0z"></path>
                    </svg>
                  </div>
                  {/* x (Twitter)*/}
                  <div
                    onClick={() => {
                      const message = shareJobLink;
                      const twitterMessageUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                        message
                      )}`;
                      window.open(twitterMessageUrl, "_blank");
                    }}
                    className="border-1 border-gray-300 rounded-full object-cover bg-green-50 cursor-pointer p-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      enable-background="new 0 0 72 72"
                      viewBox="0 0 72 72"
                      id="twitter-x"
                      height="15"
                      width="15"
                    >
                      <path d="M42.5,31.2L66,6h-6L39.8,27.6L24,6H4l24.6,33.6L4,66h6l21.3-22.8L48,66h20L42.5,31.2z M12.9,10h8l38.1,52h-8L12.9,10z"></path>
                    </svg>
                  </div>
                </div>

                {/* Job Link */}
                <div
                  className="flex items-center justify-between px-2 bg-green-50 rounded-lg w-full sm:w-auto gap-2 cursor-pointer"
                  onClick={() => copyToClipboard(shareJobLink)} // Trigger the copy on click
                >
                  <p className="text-gray-400 mt-2.5 text-sm truncate w-80">
                    {shareJobLink}
                  </p>
                  <svg
                    className="w-4 h-4"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M15.7285 3.88396C17.1629 2.44407 19.2609 2.41383 20.4224 3.57981C21.586 4.74798 21.5547 6.85922 20.1194 8.30009L17.6956 10.7333C17.4033 11.0268 17.4042 11.5017 17.6976 11.794C17.9911 12.0863 18.466 12.0854 18.7583 11.7919L21.1821 9.35869C23.0934 7.43998 23.3334 4.37665 21.4851 2.5212C19.6346 0.663551 16.5781 0.905664 14.6658 2.82536L9.81817 7.69182C7.90688 9.61053 7.66692 12.6739 9.51519 14.5293C9.80751 14.8228 10.2824 14.8237 10.5758 14.5314C10.8693 14.2391 10.8702 13.7642 10.5779 13.4707C9.41425 12.3026 9.44559 10.1913 10.8809 8.75042L15.7285 3.88396Z"
                        fill="#059669"
                      ></path>{" "}
                      <path
                        d="M14.4851 9.47074C14.1928 9.17728 13.7179 9.17636 13.4244 9.46868C13.131 9.76101 13.1301 10.2359 13.4224 10.5293C14.586 11.6975 14.5547 13.8087 13.1194 15.2496L8.27178 20.1161C6.83745 21.556 4.73937 21.5863 3.57791 20.4203C2.41424 19.2521 2.44559 17.1408 3.88089 15.6999L6.30473 13.2667C6.59706 12.9732 6.59614 12.4984 6.30268 12.206C6.00922 11.9137 5.53434 11.9146 5.24202 12.2081L2.81818 14.6413C0.906876 16.5601 0.666916 19.6234 2.51519 21.4789C4.36567 23.3365 7.42221 23.0944 9.33449 21.1747L14.1821 16.3082C16.0934 14.3895 16.3334 11.3262 14.4851 9.47074Z"
                        fill="#059669"
                      ></path>{" "}
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareJob;
