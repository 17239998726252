import React,{ useState,useRef,useContext,useEffect } from "react";
import ModalBox from "../elements/ModalBox/ModalBox";
import Button from "../elements/Button/Button";
import Textinput from "../elements/TextInput/TextInput";
import RadioInput from "../elements/RadioInput/RadioInput";
import {validateEmail} from "../Validation/Validation"
import Notification from "../Notification/Notification";
import ClipLoader from "react-spinners/ClipLoader";
import { AuthContext } from "../../context/AuthContext";
import  Badge from "../elements/Badge/Badge";
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
const TeamMember = (props) => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate()
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [levelOfAccess, setLevelOfAccess] = useState("");
  const userEmail = useRef(null);
  const userName = useRef(null);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [userAddNameVal,userNameFun] = useState("");
  const [addUserCheckVal, addUserCheckFun] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [roleVal, setRoleFun] = useState(['Admin','Collaborator','Limited Access User','Interviewer']);
  const [userTableJson, userTableJsonFun] = useState(false);
  const [selectedEmail, selectedEmailFun] = useState(false);
  const [inputDisableEmail, inputDisableEmailFun] = useState(false);
  const [setUserId, setUserIdFun] = useState(false);
  const { showNextBtn } = location.state || false;
  const showNotification = (message, type) => {   //For Notification
    setNotification({ message, type });
    setTimeout(() => {
      setNotification({ message: "", type: "" });
    }, 3000); // Auto-hide after 3 seconds
  };
  const openForm = (uid,edit) =>{ 
    setIsModalOpen(true) 
    if(edit){
      const userData = Array.isArray(userTableJson) ? userTableJson.find((user) => user.uid === uid) : null;
      console.log(userData,"nill")
      selectedEmailFun(userData.email)
      userNameFun(userData.name)
      setLevelOfAccess(Number(userData.role_id));
      inputDisableEmailFun(true)
      setUserIdFun(userData.uid)
    }

  };
  const closeForm = () =>{ 
    setIsModalOpen(false)
    selectedEmailFun("")
    userNameFun("")
    setLevelOfAccess(false);
    inputDisableEmailFun(false);
    setUserIdFun(false)
  };
  const profile_navbar_dropdown = () => {
    setIsOpen(!isOpen);
  };
  const levelOfAccessChange = (event) => {
    setLevelOfAccess(Number(event.target.value)); // Update state on change
  };
  const validateName = (e) => {    //Name Validation only allow sting ,space and dot
      const input = e.target.value.replace(/[^a-zA-Z.\s]/g, '');
      if(input)
      userName.current.classList.remove("border-red-600");
      userNameFun(input);
  };
  const emailOninputFun = (e) =>{
    const input = e.target.value;
    selectedEmailFun(input);
    userEmail.current.classList.remove("border-red-600");
  }
  const addUserCheck = (event) => {   //Get the value of notification checkbox
    addUserCheckFun(event.target.checked);
};
  const saveUserDetails = async() =>{
    var userEmailVal = userEmail.current.value
    var userNameVal = userName.current.value
    if(!userEmailVal){
      showNotification("Enter Email","error");
      userEmail.current.classList.add("border-red-600");
      userEmail.current.focus();
      return;
    }
    else if(!validateEmail(userEmailVal)){
        showNotification("Enter Valid Email","error");
        userEmail.current.classList.add("border-red-600");
        userEmail.current.focus();
        return;
    }
    if(!userNameVal){
      showNotification("Enter Name","error");
      userName.current.classList.add("border-red-600");
      userName.current.focus();
      return;
    }
    if(!levelOfAccess){
      showNotification("Choose The Level Of Access","error");
      return;
    }
    var company_id = "";
    const encryptedMetadata = localStorage.getItem('metadata');
    if (encryptedMetadata){
      const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
      if(decryptedMetadata && decryptedMetadata.cid)
        company_id = decryptedMetadata.cid
     }
    const serverURL = process.env.REACT_APP_SERVER_URL;
    console.log(setUserId)
    let jsonData;
    let url;
    if(setUserId){
      jsonData = {name:userNameVal,role:levelOfAccess,userId:setUserId};
      url=`${serverURL}/companyUserUpdate`;
    }
    else{
      jsonData = {email:userEmailVal,name:userNameVal,role:levelOfAccess,access:addUserCheckVal,company_id,company_id,phone_no:"9089999999",password:""};
      url=`${serverURL}/signUp`;

    }
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData), process.env.REACT_APP_SECRETKEY).toString();
    var encryptjson = { data:encryptedData}
    setIsLoading(true);
    try {
        const response = await fetch(`${url}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              token: token
            },
            body: JSON.stringify(encryptjson),
        });
        const data = await response.json();
        console.log(data)

        // const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        // console.log(decryptedData);
        if (!response.ok) {
            var errorMessage = "Something Went Wrong";
            if(data.error)
                errorMessage = data.error;
            showNotification(errorMessage,"error");
            throw new Error(data.error);
        }
        else{
            showNotification("User Added Successfully","success");
            closeForm();
            userEmail.current.value = "";
            userNameFun("")
            setLevelOfAccess("")

    }
    } catch (error) {
        console.log('Error:', error);
        let errorMessage = "Something went wrong.";
        if (error.response && error.response.data && error.response.data.msg) {
            errorMessage = error.response.data.msg;
        } 
        else if (error.message) {
            errorMessage = error.message;
        }
        showNotification(errorMessage, "error");
    } finally {
        setIsLoading(false); // Always hide loader after the request
    }
  } 

  useEffect(() => {
    loadProfile();
}, [isModalOpen]);


  const loadProfile = async () => {
  setIsLoading(true);
  try {
        const serverURL = process.env.REACT_APP_SERVER_URL;
        const response = await fetch(`${serverURL}/company/fetchCompanyUsers`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              token: token
            },
        });
        const data = await response.json();

        const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        console.log(decryptedData);
        if (!response.ok) {
            var errorMessage = "Something Went Wrong";
            if(data.error)
                errorMessage = data.error;
            showNotification(errorMessage,"error");
            throw new Error(data.error);
        }
        else{
            console.log(decryptedData)
            const data = Array.isArray(decryptedData) ? decryptedData : [];
            userTableJsonFun(data);
        }
    } catch (error) {
        console.log('Error:', error);
        let errorMessage = "Something went wrong.";
        if (error.response && error.response.data && error.response.data.msg) {
            errorMessage = error.response.data.msg;
        } 
        else if (error.message) {
            errorMessage = error.message;
        }
        showNotification(errorMessage, "error");
    } finally {
        setIsLoading(false); // Always hide loader after the request
    }
}
const companyUserDelete = async (uid) => {
  Swal.fire({
            text: 'Are you sure you want deactive this user',
            icon: 'warning',
            confirmButtonText: 'OK',
        }).then(async(result) => {
            if (result.isConfirmed) {
              if(!uid){
                showNotification("Don't Have User ID", "error");
                return;
              }
              const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({userId:uid}), process.env.REACT_APP_SECRETKEY).toString();
              var encryptjson = { data:encryptedData}
              setIsLoading(true);
              try {
                    const serverURL = process.env.REACT_APP_SERVER_URL;
                    const response = await fetch(`${serverURL}/companyUserDelete`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          token: token
                        },
                        body: JSON.stringify(encryptjson),
                    });
                    const data = await response.json();

                    
                    if (!response.ok) {
                        var errorMessage = "Something Went Wrong";
                        if(data.error)
                            errorMessage = data.error;
                        showNotification(errorMessage,"error");
                        throw new Error(data.error);
                    }
                    else{
                        loadProfile();
                    }
              } catch (error) {
                  console.log('Error:', error);
                  let errorMessage = "Something went wrong.";
                  if (error.response && error.response.data && error.response.data.msg) {
                      errorMessage = error.response.data.msg;
                  } 
                  else if (error.message) {
                      errorMessage = error.message;
                  }
                  showNotification(errorMessage, "error");
              } finally {
                  setIsLoading(false); // Always hide loader after the request
              }
            }
  });
}

const locateDashboard = () =>{
  navigate("/Dashboard")
}


  const modal_Body=(
    
    <div className="h-[auto]">
      <div className="w-3/4 h-[auto] px-14">
      <Textinput disable={inputDisableEmail} value={selectedEmail || ""} onChange={emailOninputFun} InputRef={userEmail} label="Email Address" labelclass="mt-3" span="*" className="w-2/5 border-2 border-blue-100 mb-3" placeholder="Enter Address"/>
      <Textinput value={userAddNameVal} onChange={validateName} InputRef={userName} label="Full Name" labelclass="mt-1" span="*" className="w-2/5 border-2 border-blue-100 mb-3" placeholder="Enter Full name"/>
      <p className=" mt-2 w-full mb-2 font-semibold">What level of Access would you like to provide?</p>
      <RadioInput labelClass="text-sm" className="custom-radio-input" type="radio" name="levelOfAccess" checked={levelOfAccess === 2} onChange={levelOfAccessChange} value={2} label="Admin" subtext=""subtextClass="mb-2"/><br/>
      <RadioInput labelClass="text-sm" className="custom-radio-input" type="radio" name="levelOfAccess" checked={levelOfAccess === 3} onChange={levelOfAccessChange} value={3} label="Collaborator" subtext=""subtextClass="mb-2"/><br></br>
      <RadioInput labelClass="text-sm" className="custom-radio-input" type="radio" name="levelOfAccess" checked={levelOfAccess === 4} onChange={levelOfAccessChange} value={4} label="Limited Access User" subtext="" subtextClass="mb-2"/><br></br>
      <RadioInput labelClass="text-sm" className="custom-radio-input" type="radio" name="levelOfAccess" checked={levelOfAccess === 5} onChange={levelOfAccessChange} value={5} label="Interviewer" subtext="" subtextClass="mb-2"/>
      </div>
    </div>
    
    
  )
 const Modal_Footer=(<>
    <div className="flex pt-4 w-full">
      <Textinput
              type="checkbox"
              onChange={addUserCheck}
              className="mr-3 w-5 h-6 ml-8 mr-2 mt-1 !border-[1px] !border-[#BFE0E2]"
              required = {true}
          />
          <div className="ml-2">
        <p className="ml-4 text-xs text-gray-500 w-[93%] ml-4 flex mb-2">By clicking Save and notify new users, I agree Indeed may share access to Webenza India Pvt. Ltd.'s account with this user, that I am authorized to grant such access, and for my email address to be shared with the user in this email notification. This user's access will match the role(s) I have selected for them.
    </p></div></div>
    <Button className=" border-2 border-green-800 bg-white mt-2 text-sm text-green-700 w-1/4 ml-12 pt-2 pb-2 pl-8 pr-8 rounded "
        onClick={closeForm}>Cancel</Button>
    <Button className=" border-2 border-green-800 bg-green-700 text-sm text-white w-2/5 ml-4 pt-2 pb-2 pr-2 rounded "
        onClick={saveUserDetails}>Save and Notify Users</Button></>
      )
 const data = Array.isArray(userTableJson) ? userTableJson : [];
    return(

<div className={`w-8/12 mr-2 p-6  bg-white  border border-none rounded-xl shadow ${props.className}`}>
  {isLoading && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="flex flex-col items-center">
                  <ClipLoader color="#3498db" loading={isLoading} size={50} />
                  <span className="text-white mt-4">Loading...</span>
              </div>
          </div>
      )}
      <Notification
            message={notification.message}
            type={notification.type}
            onClose={() => setNotification({ message: "", type: "" })}
        />
    <div className="flex">
        <div className="mt-3"><p className="text-black text-lg font-medium">Company Profile / Users</p></div>
        <div className="ml-auto"><button className="pt-2 mt-2 pb-2 px-4  rounded-lg bg-green-700 text-white" onClick={openForm}>Add New User</button>{isModalOpen && (
                                <div>
                                    <ModalBox  Modal_Body={modal_Body} modal_Body_Class="" modal_Fooder_Class="border-t-2 border-gray-300 mt-1" Modal_Close_Func={closeForm} Modal_Header="Add New User" modal_Header_Text="mt-4 mb-3 ml-14" Modal_Footer={Modal_Footer} modal_Header_Class="border-gray-300 border-b-2 h-[auto] font-medium" modal_Close_Class="mt-2 mr-2 rounded-lg pl-1.5 pt-0.5 pb-0.5 pr-1.5" cardClassCustom="pb-4" cardClass="bg-white rounded-2xl shadow-lg w-5/12 h-[auto] max-w-xl"/>
                                </div>
                            )}</div>
    </div>
    
    <div
  className="relative flex flex-col w-full h-2/4  text-gray-700 bg-white  rounded-xl bg-clip-border">
  <table className="w-full text-left mt-4 table-auto">
    <thead>

      <tr className="bg-sky-50 rounded-lg">
        <th className="p-1 border-none w-1/4 border-blue-gray-100 bg-blue-gray-50">
          <p className="block mt-2 ml-4 mt-2 font-sans text-md antialiased font-normal leading-none text-black ">
            Name
          </p>
        </th>
        <th className="p-1 border-none w-1/4 border-blue-gray-100 bg-blue-gray-50">
          <p className="block mt-2 ml-4 mt-2 font-sans text-md antialiased font-normal leading-none text-black ">
              User Email ID
          </p>
        </th>
        <th className="p-1 border-none  border-blue-gray-100 bg-blue-gray-50">
          <p className="block mt-2 ml-4 mt-2 font-sans text-md antialiased font-normal leading-none text-black ">
            Role
          </p>
        </th>
        <th className="p-1  border-blue-gray-100 bg-blue-gray-50 w-2/12"><p className="block mt-2 ml-4 mt-2 font-sans text-md antialiased font-normal leading-none text-black ">Status</p></th>
        <th className="p-1  border-blue-gray-100 bg-blue-gray-50 w-2/12">
          <p className="block mt-2 ml-4 mt-2 font-sans text-md antialiased font-normal leading-none text-black "></p>
        </th>
      </tr>
    </thead>
    <tbody>
    {data.map((user,index) => {
      if(user.role_id === 1) return null;
      return(
      <tr className={`border-none border-blue-gray-50 rounded-lg ${index%2 === 0 ? `bg-[#F8F8F8]`:``}`}>
        <td className="p-1 border-none border-blue-gray-50">
          <p className="block ml-4 mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
            {user.name}
          </p>
        </td>
        <td className="p-1 border-none border-blue-gray-50">
          <p className="block ml-4 mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
            {user.email}
          </p>
        </td>
        <td className="p-1 border-none border-blue-gray-50">
          <p className="block ml-4 mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
            {roleVal[user['role_id']-2]}
          </p>
        </td>
        <td className="p-1 border-none border-blue-gray-50">
        {user.status ? (
          <Badge className="ml-4 bg-green-50 text-green-700 ring-green-600/10" badgeText="Active" />
        ):(
          <Badge className="ml-4 bg-red-50 text-red-700 ring-red-600/10" badgeText="Inactive" />
          )
        }
        </td>
        <td className="p-1  border-blue-gray-50 mt-2 mr-2 float-right">
          {user.status && (
            <a href="#" className="block flex ml-4 font-sans text-sm antialiased font-medium leading-normal ">
            <svg onClick={() => openForm(user.uid,"edit")} xmlns="http://www.w3.org/2000/svg" width="20" height="20"  viewBox="0 0 24 24" fill="green">
              <path d="M13.0207 5.82839L15.8491 2.99996L20.7988 7.94971L17.9704 10.7781M13.0207 5.82839L3.41405 15.435C3.22652 15.6225 3.12116 15.8769 3.12116 16.1421V20.6776H7.65669C7.92191 20.6776 8.17626 20.5723 8.3638 20.3847L17.9704 10.7781M13.0207 5.82839L17.9704 10.7781" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg onClick={() => companyUserDelete(user.uid)} xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 16 16" className="bi bi-trash ml-2 mt-0.5" fill="green">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
              <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
            </svg>
            </a>
          )}
        </td>
      </tr>
      );
  })}
    </tbody>
  </table>
  {showNextBtn &&
    <div className="flex mt-4">
    <Button children="Next" onClick={locateDashboard} className="bg-emerald-700 text-white px-4 py-2 ml-auto" />
    </div>
  }
</div>

    

</div>


)
};
export default TeamMember;