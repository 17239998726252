import React, { useState,useEffect } from "react";
import './ViewBooking.css';
import Topbar from "../../components/Recruiter/Topbar/Topbar";
import Calendar from "react-calendar";
import ButtonSolid from "../../components/elements/Button/ButtonSolid";
import Selectbox from "../../components/elements/SelectBox/SelectBox";
import CryptoJS from "crypto-js";
import Swal from 'sweetalert2';
import { useParams } from "react-router-dom";
const ViewBooking = () => {
    const [TimeZone, SetTimeZone] = useState("");
    const TimeZones = [
        {id: 1, label: "Indian Standard Time"},
        {id: 2, label: "UTC"},
    ]
    const [selectedDate, setSelectedDate] = useState("");
    const [timeSlots, setTimeSlots] = useState([
        { time: "10:00 AM", available: true },
        { time: "11:30 AM", available: false },
        { time: "1:00 PM", available: true },
        { time: "3:00 PM", available: false },
        { time: "4:30 PM", available: true },
    ]);

    // Current date
    const currentDate = new Date();
    const [formattedDate,setFormattedDate] = useState(currentDate.toLocaleDateString('en-US', {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    }))
    const setValueChange = (e) => {
        // console.log(e);
    }
    // need to pass it by url
    const {job_id,candidate_id} = useParams();
    const [jobId, setjobId] = useState();
    const [resumeId, setresumeId] = useState(14);

    useEffect(() => {
        if (job_id) {
          setjobId(parseInt(job_id));
          console.log(job_id);
        }
        if(candidate_id){
            setresumeId(candidate_id);
        }
    },[job_id, candidate_id])
    
    const [userName, setUserName] = useState("");
    const [jobName, setjobName] = useState("");
    const [interviewDuration, setinterviewDuration] = useState("");
    const [isRegistered, setIsRegistered] = useState(false);
    const checkRegisterStatus = async (job,candidate) => {

        const JLData = {
            job_id: job,
            candidate_id: candidate
          };
      
          const encryptedData = CryptoJS.AES.encrypt(
            JSON.stringify(JLData),
            process.env.REACT_APP_SECRETKEY
          ).toString();
          const encryptjson = { data: encryptedData };
        setIsFetching(true);
        try {
            const serverURL = process.env.REACT_APP_SERVER_URL;
            const response = await fetch(`${serverURL}/calendly/checkCandidateRegistration`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(encryptjson)
            });
            const responsedata = await response.json();
            console.log(responsedata.registered);
            if (!response.ok) {
                var errorMessage = "Something Went Wrong";
                if(responsedata.error)
                    errorMessage = responsedata.message;
                throw new Error(responsedata.error);
            }
            else{
                setIsRegistered(responsedata.registered)
            }
        } catch (error) {
            let errorMessage = "Something went wrong.";
            if (error.response && error.response.data && error.response.data.msg) {
                errorMessage = error.response.data.msg;
            } 
            else if (error.message) {
                errorMessage = error.message;
            }
        } finally {
        }
}

    const fetchJobDetails = async (job) => {

            const JLData = {
                job_id: job,
              };
          
              const encryptedData = CryptoJS.AES.encrypt(
                JSON.stringify(JLData),
                process.env.REACT_APP_SECRETKEY
              ).toString();
              const encryptjson = { data: encryptedData };
            setIsFetching(true);
            try {
                const serverURL = process.env.REACT_APP_SERVER_URL;
                const response = await fetch(`${serverURL}/job/getJobDetailsForUser`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(encryptjson)
                });
                const responsedata = await response.json();
                // console.log(responsedata.data);
                if (!response.ok) {
                    var errorMessage = "Something Went Wrong";
                    if(responsedata.error)
                        errorMessage = responsedata.message;
                    throw new Error(responsedata.error);
                }
                else{
                    setUserName(responsedata.data.created_by_name)
                    setjobName(responsedata.data.title);
                    setinterviewDuration(responsedata.data.interview_duration);
                }
            } catch (error) {
                let errorMessage = "Something went wrong.";
                if (error.response && error.response.data && error.response.data.msg) {
                    errorMessage = error.response.data.msg;
                } 
                else if (error.message) {
                    errorMessage = error.message;
                }
            } finally {
            }
    }
    useEffect(()=>{
        checkRegisterStatus(jobId,candidate_id);
        if(!isRegistered){
            fetchJobDetails(jobId);
        }
    },jobId, candidate_id)
    


    const [selectedSlot, setSelectedSlot] = useState([]);
    const [ isFetching, setIsFetching] = useState(false);
    const [isSlotAvailable, setisSlotAvailable] = useState(false);
    const [slotDate, setSlotDate] = useState("");
    const fetchDateSchedule = async (date) => {
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
        // console.log(jobId, formattedDate);
        setSlotDate(formattedDate)
        setFormattedDate(date.toLocaleDateString('en-US', {
            weekday: 'long',
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        }));
            const JLData = {
                job_id: jobId,
                date: formattedDate,
              };
          
              const encryptedData = CryptoJS.AES.encrypt(
                JSON.stringify(JLData),
                process.env.REACT_APP_SECRETKEY
              ).toString();
              const encryptjson = { data: encryptedData };
            setIsFetching(true);
            try {
                const serverURL = process.env.REACT_APP_SERVER_URL;
                const response = await fetch(`${serverURL}/calendly/fetchTiemSlot`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(encryptjson)
                });
                const responsedata = await response.json();
                // console.log(responsedata);
                if (!response.ok) {
                    var errorMessage = "Something Went Wrong";
                    setSelectedSlot([]);
                    if(responsedata.error)
                        errorMessage = responsedata.message;
                    throw new Error(responsedata.error);
                }
                else{
                    const slotData = Array.isArray(responsedata.slots) ? responsedata.slots : [];
                    setSelectedSlot(slotData);
                }
            } catch (error) {
                setSelectedSlot([]);
                let errorMessage = "Something went wrong.";
                if (error.response && error.response.data && error.response.data.msg) {
                    errorMessage = error.response.data.msg;
                } 
                else if (error.message) {
                    errorMessage = error.message;
                }
            } finally {
                setIsFetching(false);
            }
    }


    const bookSchedulefinal = async (job_id, date, email, start_time, end_time, query, candidate_id, name) => {
            const JLData = {
                job_id: job_id,
                date: date,
                email: email,
                start_time: start_time,
                end_time: end_time,
                query: query,
                candidate_id: candidate_id,
                name: name
              };
          
              const encryptedData = CryptoJS.AES.encrypt(
                JSON.stringify(JLData),
                process.env.REACT_APP_SECRETKEY
              ).toString();
              const encryptjson = { data: encryptedData };
            setIsFetching(true);
            try {
                const serverURL = process.env.REACT_APP_SERVER_URL;
                const response = await fetch(`${serverURL}/calendly/scheduleInterview`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(encryptjson)
                });
                    if (response.ok) {
                        Swal.fire({
                        icon: 'success',
                        title: 'Slot Booked successfully!',
                        confirmButtonText: 'OK',
                        }).then(() => {
                            setIsRegistered(true);
                        });
                    } else {
                        Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'There was an issue adding the job. Please try again.',
                        });
                    }
            } catch (error) {
                let errorMessage = "Something went wrong.";
            } 
    }
    const handleDateChange = (date) => {
        fetchDateSchedule(date);
        // console.log("Selected date:", date);
        setSelectedDate(date); // Update the state
      };
      const [slotTime, setSlotTime] = useState("");
      
      const [slotendTime, setSlotendTime] = useState("");
    const setSlot = (time, end_time, id, date) => {
        setisSlotAvailable(true);
        setSlotTime(time);
        setSlotendTime(end_time);
    }
    const [formData, setFormData] = useState({
        candidate_name: "",
        email: "",
        query: "",
      });
    
      const [error, setError] = useState("");
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      };
    
      const BookEvent = () => {
        const requiredFields = ["candidate_name", "email"]; // Specify only required fields
        const missingFields = requiredFields.filter(
          (field) => formData[field].trim() === ""
        );
    
        if (missingFields.length > 0) {
          setError(`Please fill the required fields: ${missingFields.join(", ")}`);
          return;
        }
    
        setError(""); 
        // console.log("Booking event with data:", formData);
        bookSchedulefinal(jobId, slotDate, formData.email, slotTime, slotendTime, formData.query,resumeId, formData.candidate_name)
      };

    return (
        <div className={`radial-gradient-bg ${isRegistered ? `flex`: ``}`}>
            {/* <Topbar /> */}
            {!isRegistered ? <div className="main-bg-view-booking">
            <div className="view-booking-container !m-auto">
                {/* Left Section */}
                <div className="left-section">
                    <p className={`mb-2`}>{userName}</p>
                    <h4 className="title">{jobName}</h4>
                    <p className="description">
                        {interviewDuration}
                    </p>
                </div>
                {!isSlotAvailable ? 
                <>
                {/* Center Section */}
                <div className="center-section">
                    <h4 className={`mb-4`}>Select the date and time</h4>
                <div className='Calendar-Div mobile-full-width'>
                    <Calendar
                    onChange={handleDateChange}
                    value={selectedDate}
                    />   
                </div>
                <Selectbox id="SetTimeZone" value={TimeZone} onChange={(e)=> setValueChange(e)} className={`!w-[150px] mt-4 text-emerald-600 !bg-transparent !ring-transparent !foucs:ring-0 !outline-none p-2 border-none`} list={TimeZones} />
                </div>

                {/* Right Section */}
                <div className="right-section">
                    <p className="date">{formattedDate}</p>
                    {!isFetching ? 
                    <ul className="time-list">
                        {selectedSlot.length > 0 ? 
                        selectedSlot.map((slot, index) => (
                            <li key={index} className="time-item">
                                <span>{slot.start_time}</span>
                                {slot.slotStatus === "Available" && (
                                    <ButtonSolid
                                        className="!w-[90px] m-0"
                                        onClick={() => setSlot(slot.start_time, slot.end_time ,slot.job_id,slot.date)}
                                        content={`Next`}
                                        />
                                )}
                            </li>
                        ))
                        : 
                        <p>No slots Available</p>
                        }
                        
                    </ul>
                    :<p>Fetching Available Slots....</p>}
                </div>
                </>
                :
                <>                    <div className="center-section">
                <h4 className={`mb-4`}>Enter Details</h4>
                <div className="w-[830px] mx-auto">
                <p className="mb-1 mt-1">Full Name*</p>
                <input
                    name="candidate_name"
                    placeholder="What is your name?"
                    style={{ padding: "10px" }}
                    className="profile_sidebar_progress_text border-[2px] h-12 border-sky-100 w-[550px] rounded-lg focus:ring-0 pr-10"
                    value={formData.candidate_name}
                    onChange={handleInputChange}
                    type="text"
                    required
                />
                <p className="mb-1 mt-3">Email ID*</p>
                <input
                    name="email"
                    placeholder="Tell us your Email ID"
                    style={{ padding: "10px" }}
                    className="profile_sidebar_progress_text border-[2px] h-12 border-sky-100 w-[550px] rounded-lg focus:ring-0 pr-10"
                    value={formData.email}
                    onChange={handleInputChange}
                    type="text"
                    required
                />
                <p className="mb-1 mt-3">
                    Please Share Anything That Will Help Prepare For Our Meeting.
                </p>
                <textarea
                    name="query"
                    placeholder="Write your notes here"
                    style={{ padding: "10px" }}
                    className="profile_sidebar_progress_text border-[2px] h-32 border-sky-100 w-[550px] rounded-lg focus:ring-0 pr-10"
                    value={formData.query}
                    onChange={handleInputChange}
                    required
                />
                <p className="mt-4 text-xs" style={{ color: "#949494" }}>
                    By proceeding, you confirm that you have read and agree to{" "}
                    <span style={{ textDecoration: "underline" }}>Calendly's Terms of Use</span>{" "}
                    and <span style={{ textDecoration: "underline" }}>Privacy Notice</span>.
                </p>
                {error && <p className="text-red-500">{error}</p>}
                <ButtonSolid
                    style={{ maxWidth: "200px" }}
                    type="button"
                    onClick={BookEvent}
                    className="bg-blue-500 text-white rounded-lg px-4 py-2"
                    content="Schedule Event" />
                    
                </div>
            </div></>
                
                }



            </div>
            </div>
            :
            <div className="flex items-center justify-center h-full w-full fixed top-0 left-0 bg-opacity-70 bg-green-50">
            <div className="job-registration-card theme-alert">
              <p className="job-registration-message">
                <strong>Job Registering Successful!</strong>
              </p>
              <p className="job-registration-submessage">
                Thank you for registering your job posting!
              </p>
            </div>
          </div>
            }
        </div>
    );
};

export default ViewBooking;
