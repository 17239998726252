import React, { useState } from "react";
import Button from "../elements/Button/Button";
import Pipeline from "../ProjectPipeline/Pipeline";
import ProjectJob from "../ProjectJob/ProjectJob";
import Applicant from "../Applicants/Applicants";
import RecruiterSearch from "../RecruiterSearch/RecruiterSearch";
import ApplicantsFirst from "../Applicants/ApplicantsFirstPage";
import RecommendedMatches from "../RecommendedMatches/RecommendedMatches";
import HiddenCandidates from "../HiddenCandidates/HiddenCandidates";
import Overview from "../Overview/Overview";

const ProjectsBar = (props) => {
  const [selectedKey, setSelectedKey] = useState("JobPost");

  const handleSelection = (key) => {
    setSelectedKey(key);
  };
  const [openTalentPool,setTalentPool]=useState(false);
  const handleTalentPool = () =>{
    setTalentPool((prev) => !prev);
  }

  return (
    <div  className="flex gap-8 w-full">
      <div className={`${props.sidebarCss}my-[127px] py-5 ml-6`}>
        <p className={`py-[16px] mb-0 w-[217px] text-black hover:transform transition duration-150 hover:scale-105 h-[51px] text-[14px] hover:cursor-pointer flex ml-8 rounded-xl ${selectedKey === "Overview" ? "bg-gradient-to-r from-gray-50 to-teal-300 px-1" : ""}`} onClick={() => handleSelection("Overview")}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="mt-[1px] mr-4" viewBox="0 0 20 20" fill="none">
<path d="M8.46499 4.30809H8.46498V4.31032V5.84694C8.46498 6.64709 8.25486 7.17837 7.9217 7.51154C7.58853 7.8447 7.05724 8.05483 6.2571 8.05483H4.72048C3.92033 8.05483 3.38905 7.8447 3.05588 7.51154C2.72272 7.17837 2.51259 6.64709 2.51259 5.84694L2.51259 4.31032C2.51259 3.51018 2.7227 2.97917 3.05651 2.64611C3.39049 2.31287 3.92381 2.10244 4.72856 2.10244L6.26519 2.10244C7.06551 2.10244 7.59643 2.31264 7.92839 2.64534C8.26015 2.97785 8.46866 3.50836 8.46499 4.30809Z" stroke="#059669" strokeWidth="0.970498"/>
<path d="M13.9724 2.10244L15.5091 2.10244C16.3092 2.10244 16.8405 2.31256 17.1736 2.64572C17.5068 2.97889 17.7169 3.51018 17.7169 4.31032V5.84694C17.7169 6.64709 17.5068 7.17837 17.1736 7.51154C16.8405 7.8447 16.3092 8.05483 15.5091 8.05483H13.9724C13.1723 8.05483 12.641 7.8447 12.3078 7.51154C11.9747 7.17837 11.7645 6.64709 11.7645 5.84694V4.31032C11.7645 3.51018 11.9747 2.97889 12.3078 2.64573C12.641 2.31256 13.1723 2.10244 13.9724 2.10244Z" stroke="#059669" strokeWidth="0.970498"/>
<path d="M13.9724 11.3446H15.5091C16.3092 11.3446 16.8405 11.5547 17.1736 11.8879C17.5068 12.2211 17.7169 12.7524 17.7169 13.5525V15.0891C17.7169 15.8893 17.5068 16.4206 17.1736 16.7537C16.8405 17.0869 16.3092 17.297 15.5091 17.297H13.9724C13.1723 17.297 12.641 17.0869 12.3078 16.7537C11.9747 16.4206 11.7645 15.8893 11.7645 15.0891V13.5525C11.7645 12.7524 11.9747 12.2211 12.3078 11.8879C12.641 11.5547 13.1723 11.3446 13.9724 11.3446Z" stroke="#059669" strokeWidth="0.970498"/>
<path d="M8.46499 13.5584H8.46498V13.5606V15.0972C8.46498 15.8974 8.25486 16.4286 7.9217 16.7618C7.58853 17.095 7.05724 17.3051 6.2571 17.3051H4.72048C3.92034 17.3051 3.38933 17.095 3.05626 16.7612C2.72302 16.4272 2.51259 15.8939 2.51259 15.0891L2.51259 13.5525C2.51259 12.7524 2.7227 12.2214 3.05651 11.8883C3.39049 11.5551 3.92381 11.3446 4.72856 11.3446H6.26519C7.0655 11.3446 7.59615 11.5548 7.92801 11.8882C8.25985 12.2215 8.46866 12.754 8.46499 13.5584Z" stroke="#059669" strokeWidth="0.970498"/>
</svg>
Overview</p>


<p className="py-[16px] mb-0 w-[217px] text-black hover:transform transition duration-150 hover:scale-105 h-[51px] text-[14px] hover:cursor-pointer flex ml-8 rounded-xl" onClick={handleTalentPool}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21" className="mt-[1px] mr-3" fill="none">
<path d="M13.5223 11.8789H10.4327C9.28286 11.878 8.17979 12.4317 7.36597 13.4182C6.55215 14.4047 6.09419 15.7432 6.09277 17.1396C6.09419 17.8985 6.34324 18.6257 6.78535 19.162C7.22747 19.6983 7.8266 19.9999 8.4515 20.0008H15.4955C16.1202 19.9999 16.719 19.6982 17.1607 19.1618C17.6024 18.6254 17.8509 17.8982 17.8516 17.1396C17.8509 15.7452 17.3946 14.4081 16.583 13.4218C15.7713 12.4355 14.6706 11.8806 13.5223 11.8789ZM15.4955 19.0319H8.45953C8.25435 19.0332 8.051 18.9852 7.86117 18.8906C7.67134 18.796 7.49879 18.6568 7.35346 18.4809C7.20813 18.305 7.09289 18.096 7.01437 17.8658C6.93585 17.6356 6.89561 17.3888 6.89597 17.1396C6.89632 16.5762 6.9881 16.0183 7.16606 15.4979C7.34402 14.9776 7.60468 14.5049 7.93313 14.1069C8.26158 13.709 8.65139 13.3935 9.08028 13.1786C9.50918 12.9636 9.96874 12.8535 10.4327 12.8543H13.5223C14.4575 12.856 15.354 13.3082 16.015 14.1115C16.676 14.9149 17.0477 16.0039 17.0484 17.1396C17.0484 17.3873 17.0082 17.6325 16.9302 17.8613C16.8521 18.0901 16.7378 18.2979 16.5936 18.4731C16.4494 18.6482 16.2782 18.7871 16.0898 18.8818C15.9014 18.9766 15.6995 19.0254 15.4955 19.0254V19.0319Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
<path d="M15.3218 6.46785C15.3223 5.66582 15.1269 4.88162 14.7604 4.21444C14.3939 3.54726 13.8727 3.02709 13.2626 2.71972C12.6526 2.41235 11.9813 2.33159 11.3335 2.48767C10.6857 2.64374 10.0905 3.02963 9.62334 3.59653C9.15616 4.16342 8.83792 4.88585 8.70888 5.67242C8.57984 6.459 8.6458 7.27438 8.89841 8.01542C9.15103 8.75646 9.57894 9.38987 10.128 9.83552C10.6771 10.2812 11.3227 10.519 11.9832 10.519C12.8679 10.5182 13.7163 10.0912 14.3422 9.33173C14.9681 8.57226 15.3204 7.54233 15.3218 6.46785ZM9.44773 6.46785C9.4472 5.85874 9.59545 5.26312 9.87372 4.75635C10.152 4.24958 10.5478 3.85442 11.011 3.62088C11.4743 3.38733 11.9841 3.3259 12.4761 3.44433C12.9681 3.56277 13.4202 3.85576 13.775 4.28624C14.1299 4.71673 14.3716 5.26535 14.4697 5.86272C14.5677 6.46008 14.5177 7.07934 14.3258 7.64215C14.134 8.20496 13.809 8.68603 13.392 9.0245C12.975 9.36297 12.4847 9.54364 11.9832 9.54364C11.3114 9.54278 10.6673 9.21855 10.1921 8.64201C9.71681 8.06547 9.44915 7.28364 9.44773 6.46785Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
<path d="M16.5109 7.75162C16.5109 8.35031 16.6571 8.93555 16.9309 9.43334C17.2048 9.93113 17.5941 10.3191 18.0496 10.5482C18.505 10.7773 19.0062 10.8373 19.4897 10.7205C19.9732 10.6037 20.4174 10.3154 20.766 9.89205C21.1146 9.46871 21.352 8.92935 21.4481 8.34216C21.5443 7.75498 21.495 7.14635 21.3063 6.59324C21.1176 6.04012 20.7982 5.56737 20.3883 5.23475C19.9784 4.90214 19.4964 4.72461 19.0035 4.72461C18.3428 4.72633 17.7096 5.0458 17.2425 5.6131C16.7753 6.1804 16.5123 6.94934 16.5109 7.75162ZM20.6928 7.75162C20.6928 8.15739 20.5938 8.55405 20.4081 8.89144C20.2225 9.22882 19.9587 9.49178 19.65 9.64706C19.3413 9.80234 19.0016 9.84297 18.6739 9.76381C18.3462 9.68465 18.0451 9.48925 17.8089 9.20233C17.5726 8.91541 17.4117 8.54984 17.3465 8.15187C17.2813 7.7539 17.3148 7.34139 17.4427 6.96651C17.5705 6.59163 17.7871 6.27121 18.0649 6.04577C18.3427 5.82034 18.6693 5.70002 19.0035 5.70002C19.4513 5.70088 19.8806 5.9173 20.1972 6.30187C20.5139 6.68643 20.6921 7.20777 20.6928 7.75162Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
<path d="M20.2001 11.4355H17.8066C17.5591 11.4353 17.3123 11.4669 17.0703 11.5298C16.9931 11.5504 16.9226 11.5983 16.8676 11.6673C16.8127 11.7364 16.7757 11.8236 16.7614 11.918C16.7471 12.0125 16.7561 12.1099 16.7873 12.1982C16.8184 12.2865 16.8703 12.3616 16.9364 12.4142C17.4938 12.8521 17.952 13.4525 18.2691 14.1608C18.5862 14.869 18.7521 15.6625 18.7516 16.4687C18.7498 16.7508 18.7072 17.0303 18.6258 17.2945C18.6031 17.3691 18.5958 17.4494 18.6047 17.5282C18.6136 17.607 18.6383 17.682 18.6767 17.7464C18.7137 17.8107 18.763 17.8632 18.8204 17.8995C18.8777 17.9357 18.9414 17.9546 19.006 17.9545H21.7315C22.2402 17.9537 22.7277 17.7077 23.0872 17.2706C23.4466 16.8335 23.6485 16.241 23.6485 15.6233C23.6471 14.5132 23.2833 13.449 22.6369 12.664C21.9905 11.879 21.1142 11.4373 20.2001 11.4355ZM21.7315 16.9791H19.5174C19.5386 16.8124 19.5493 16.6439 19.5495 16.4752C19.5503 14.9555 19.06 13.4962 18.1841 12.411H20.2001C20.9014 12.4118 21.5738 12.7505 22.0697 13.3528C22.5657 13.955 22.8446 14.7716 22.8453 15.6233C22.8453 15.9823 22.728 16.3267 22.5192 16.5809C22.3104 16.835 22.0271 16.9783 21.7315 16.9791Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
<path d="M4.99565 10.7786C5.48875 10.7793 5.97093 10.6023 6.38118 10.2701C6.79144 9.93785 7.11134 9.46532 7.30041 8.91227C7.48948 8.35921 7.53922 7.75048 7.44335 7.16308C7.34747 6.57568 7.11029 6.03601 6.7618 5.61235C6.41331 5.18869 5.96918 4.90007 5.48559 4.78301C5.002 4.66595 4.50069 4.72571 4.04507 4.95472C3.58946 5.18373 3.20001 5.57171 2.926 6.06957C2.65199 6.56743 2.50574 7.1528 2.50574 7.75162C2.50644 8.55361 2.76892 9.32255 3.23563 9.88995C3.70235 10.4573 4.33525 10.7769 4.99565 10.7786ZM4.99565 5.70002C5.32989 5.69937 5.65678 5.81915 5.93495 6.04419C6.21312 6.26924 6.43007 6.58943 6.55835 6.96425C6.68663 7.33908 6.72046 7.75169 6.65558 8.14987C6.5907 8.54806 6.43001 8.91393 6.19385 9.20117C5.95769 9.48842 5.65668 9.68414 5.3289 9.76357C5.00111 9.84299 4.6613 9.80256 4.35244 9.64737C4.04359 9.49219 3.77959 9.22923 3.59384 8.89177C3.40808 8.55432 3.30893 8.15753 3.30893 7.75162C3.30964 7.20833 3.48749 6.68748 3.80358 6.30301C4.11967 5.91854 4.54828 5.70173 4.99565 5.70002Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
<path d="M5.00377 17.9571C5.06793 17.9569 5.1311 17.938 5.18801 17.9021C5.24492 17.8661 5.29391 17.8141 5.33088 17.7504C5.36784 17.6868 5.39171 17.6133 5.40048 17.5361C5.40924 17.4589 5.40266 17.3803 5.38128 17.3069C5.29909 17.0428 5.25559 16.7633 5.25277 16.481C5.25307 15.675 5.41929 14.8819 5.73631 14.1738C6.05334 13.4657 6.51111 12.8651 7.06799 12.4266C7.13414 12.374 7.18603 12.2988 7.21716 12.2106C7.24829 12.1223 7.25727 12.0248 7.24297 11.9304C7.22868 11.8359 7.19174 11.7487 7.1368 11.6797C7.08186 11.6106 7.01135 11.5628 6.93412 11.5422C6.69148 11.4767 6.44375 11.4429 6.19518 11.4414H3.79898C2.88531 11.4431 2.00942 11.8844 1.3631 12.6687C0.716789 13.453 0.35271 14.5163 0.350586 15.6259C0.351294 16.2439 0.553769 16.8364 0.913618 17.2734C1.27347 17.7104 1.76132 17.9563 2.27023 17.9571H5.00377ZM1.15378 15.6259C1.15449 14.7742 1.43341 13.9576 1.92933 13.3554C2.42524 12.7531 3.09765 12.4144 3.79898 12.4136H5.82571C4.94803 13.4977 4.45663 14.9575 4.4576 16.4778C4.457 16.6464 4.46685 16.8149 4.48705 16.9817H2.27023C1.97413 16.9817 1.69016 16.8389 1.48078 16.5846C1.27141 16.3303 1.15378 15.9855 1.15378 15.6259Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
</svg>
Talent Pool
<div className="w-[100px]">
<svg
            className="w-3 h-3 mt-[5px] float-right"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="green"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="m1 1 4 4 4-4"
            />
          </svg></div></p>
          {openTalentPool&&(
          <div>
          <p className={`py-[16px] mb-0 w-[217px] text-black hover:transform transition duration-150 hover:scale-105 h-[51px] text-[14px] hover:cursor-pointer flex ml-8 rounded-xl ${
            selectedKey === "Recruiter" ? "bg-gradient-to-r from-gray-50 to-teal-300 px-1" : ""
          }`} onClick={() => handleSelection("Recruiter")}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" className="mt-[1px] mr-3 ml-7" viewBox="0 0 18 19" fill="none">
          <path d="M17.8262 17.3946L12.2464 11.8148C13.2646 10.6073 13.8803 9.04957 13.8803 7.35032C13.8803 3.52338 10.7671 0.410156 6.94016 0.410156C3.11323 0.410156 0 3.52338 0 7.35032C0 11.1773 3.11323 14.2905 6.94016 14.2905C8.63949 14.2905 10.197 13.6738 11.4047 12.6556L16.9844 18.2353C17.1004 18.3513 17.2531 18.4098 17.4048 18.4098C17.5565 18.4098 17.7091 18.3513 17.8251 18.2353C18.0581 18.0043 18.0582 17.6276 17.8262 17.3946ZM1.18973 7.35032C1.18973 4.17973 3.76945 1.60001 6.94004 1.60001C10.1106 1.60001 12.6903 4.17973 12.6903 7.35032C12.6903 10.5209 10.1106 13.1006 6.94004 13.1006C3.76945 13.1006 1.18973 10.5219 1.18973 7.35032Z" fill="#059669"/>
          </svg>
          Recruiter Search</p>
        
          <p className={`py-[16px] px-1 mb-0 w-[217px] text-black opacity-30 hover:transform transition duration-150 hover:scale-105 h-[51px] text-[14px] hover:cursor-pointer flex ml-8 rounded-xl  ${
            selectedKey === "Applicants" ? "bg-gradient-to-r from-gray-50 to-teal-300 px-1" : ""
          }`} onClick={() => handleSelection("Applicants")}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="mt-[1px] mr-3 ml-6" viewBox="0 0 20 20" fill="none">
          <path d="M15.3486 13.2891H10.0434C9.77815 13.2891 9.55078 13.5164 9.55078 13.7817C9.55078 14.047 9.77815 14.2743 10.0434 14.2743H15.3486C15.6139 14.2743 15.8413 14.047 15.8413 13.7817C15.8413 13.5164 15.6139 13.2891 15.3486 13.2891Z" fill="#059669"/>
          <path d="M15.3486 10.7305H10.0434C9.77815 10.7305 9.55078 10.9578 9.55078 11.2231C9.55078 11.4884 9.77815 11.7157 10.0434 11.7157H15.3486C15.6139 11.7157 15.8413 11.4884 15.8413 11.2231C15.8413 10.9578 15.6139 10.7305 15.3486 10.7305Z" fill="#059669"/>
          <path d="M15.3486 15.8477H10.0434C9.77815 15.8477 9.55078 16.075 9.55078 16.3403C9.55078 16.6055 9.77815 16.8329 10.0434 16.8329H15.3486C15.6139 16.8329 15.8413 16.6055 15.8413 16.3403C15.8413 16.075 15.6139 15.8477 15.3486 15.8477Z" fill="#059669"/>
          <path d="M18.3799 8.07971L14.9694 4.66921C14.7231 4.4229 14.401 4.29027 14.041 4.29027H11.9189C11.2747 2.60397 9.66415 1.41029 7.75048 1.41029C5.30629 1.39134 3.29789 3.3808 3.29789 5.84394C3.29789 6.88604 3.65788 7.85235 4.28314 8.62918L2.1421 10.7702C1.95263 10.9597 1.95263 11.2818 2.1421 11.4713C2.23684 11.566 2.36947 11.6228 2.48315 11.6228C2.61578 11.6228 2.72947 11.585 2.8242 11.4713L4.96524 9.33023C5.43892 9.70918 5.98839 9.99338 6.5947 10.1639V18.1028C6.5947 18.8228 7.18207 19.4102 7.90206 19.4102H17.4325C18.1525 19.4102 18.7399 18.8228 18.7399 18.1028V9.00813C18.7778 8.64813 18.6262 8.32603 18.3799 8.07971ZM14.8746 5.95763L17.0915 8.17445H15.1967C15.0262 8.17445 14.8746 8.02287 14.8746 7.85235V5.95763ZM7.75048 2.3766C9.66415 2.3766 11.2178 3.93027 11.2178 5.84394C11.2178 7.75761 9.66415 9.31128 7.75048 9.31128C5.83681 9.31128 4.28314 7.75761 4.28314 5.84394C4.28314 3.93027 5.83681 2.3766 7.75048 2.3766ZM17.4515 18.406H7.92101C7.75048 18.406 7.59891 18.2544 7.59891 18.0839V10.2965C7.65575 10.2965 7.71259 10.2965 7.75048 10.2965C10.2136 10.2965 12.2031 8.30708 12.2031 5.84394C12.2031 5.65447 12.1841 5.44605 12.1652 5.25658H13.8894V7.8334C13.8894 8.55339 14.4767 9.14076 15.1967 9.14076H17.7925V18.0649C17.7925 18.2544 17.6409 18.406 17.4515 18.406Z" fill="#059669"/>
          <path d="M6.36734 7.83222H9.13364C9.26627 7.83222 9.37995 7.71854 9.37995 7.56696L9.30416 6.90381C9.24732 6.43013 8.92522 6.07013 8.50838 5.9375C8.28101 6.05118 8.0347 6.12697 7.76944 6.12697C7.50418 6.12697 7.25786 6.07013 7.0305 5.95645C6.61366 6.07013 6.27261 6.44908 6.21576 6.92276L6.13998 7.58591C6.12103 7.71854 6.21576 7.83222 6.36734 7.83222Z" fill="#059669"/>
          <path d="M7.75089 5.59748C8.34735 5.59748 8.83088 5.11395 8.83088 4.51749C8.83088 3.92103 8.34735 3.4375 7.75089 3.4375C7.15443 3.4375 6.6709 3.92103 6.6709 4.51749C6.6709 5.11395 7.15443 5.59748 7.75089 5.59748Z" fill="#059669"/>
          </svg>
          Applicants<div className="w-[90px]"><p className="bg-yellow-500 mb-0 pl-1 pr-1 rounded-full float-right">10</p></div></p>
          <p className={`py-[16px] px-1 mb-0 w-[217px] text-black hover:transform transition duration-150 hover:scale-105 h-[51px] text-[14px] hover:cursor-pointer flex ml-8 rounded-xl  ${
            selectedKey === "RecommendedMatches" ? "bg-gradient-to-r from-gray-50 to-teal-300 px-1 h-[61px]" : ""
          }`} onClick={() => handleSelection("RecommendedMatches")}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" className="mt-[7px] mr-3 ml-6" viewBox="0 0 20 21" fill="none">
          <path d="M1.61362 12.0377L2.07751 9.27023C2.10145 9.17576 2.15508 9.09146 2.23053 9.02977C2.30597 8.96807 2.39923 8.93223 2.49657 8.92753L4.9047 8.538C5.16022 8.4978 5.39252 8.36634 5.55855 8.16799C5.3688 8.09804 5.18547 8.01177 5.01062 7.91016C4.95073 7.94692 4.8842 7.97159 4.81482 7.98278L2.41308 8.37135C2.19653 8.39283 1.99273 8.48372 1.83205 8.63048C1.67138 8.77723 1.56244 8.97199 1.52147 9.18571L1.00388 12.2724C0.997114 12.3128 0.999218 12.3541 1.01004 12.3935C1.02087 12.4329 1.04016 12.4695 1.06656 12.5007C1.09297 12.5319 1.12586 12.5569 1.16294 12.5741C1.20002 12.5913 1.24041 12.6002 1.28128 12.6002H4.06228C4.0951 12.3992 4.17034 12.2075 4.28299 12.0377H1.61362Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
          <path d="M6.3938 7.79289C6.4253 7.60667 6.47189 7.42331 6.5331 7.24465C6.07244 7.19958 5.63476 7.02174 5.27322 6.73272C4.91168 6.4437 4.64183 6.05594 4.49643 5.6165C4.35103 5.17707 4.33633 4.70487 4.45413 4.25725C4.57193 3.80962 4.81715 3.40583 5.16002 3.09489C5.50289 2.78395 5.92867 2.57924 6.38564 2.50562C6.84261 2.43201 7.31113 2.49264 7.7343 2.68017C8.15748 2.8677 8.5171 3.17406 8.76952 3.56204C9.02193 3.95003 9.15627 4.40295 9.15623 4.86581C9.15623 4.88959 9.15335 4.91263 9.15266 4.93624C9.33915 4.89834 9.52842 4.87566 9.71859 4.86844L9.71873 4.86581C9.71922 4.2975 9.55571 3.74112 9.24783 3.26343C8.93994 2.78574 8.50076 2.40703 7.98299 2.17275C7.46521 1.93848 6.89083 1.85858 6.32877 1.94265C5.76671 2.02672 5.24084 2.27118 4.81428 2.64671C4.38771 3.02223 4.07856 3.51287 3.92392 4.05974C3.76929 4.60661 3.77573 5.18648 3.94249 5.72978C4.10924 6.27308 4.42922 6.75672 4.86403 7.12267C5.29884 7.48862 5.83001 7.72133 6.3938 7.79289Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
          <path d="M7.89694 15.5533H4.70737L5.17126 12.7859C5.19519 12.6914 5.24883 12.6071 5.32427 12.5455C5.39972 12.4838 5.49298 12.4479 5.59032 12.4433L7.99838 12.0537C8.25395 12.0135 8.48629 11.882 8.65234 11.6836C8.46259 11.6137 8.27926 11.5274 8.10441 11.4258C8.0445 11.4625 7.97796 11.4872 7.90856 11.4983L5.50682 11.8869C5.29029 11.9085 5.0865 11.9994 4.92583 12.1461C4.76516 12.2929 4.65621 12.4876 4.61522 12.7013L4.09763 15.788C4.09086 15.8284 4.09297 15.8697 4.10379 15.9091C4.11462 15.9485 4.1339 15.9851 4.16031 16.0163C4.18672 16.0475 4.2196 16.0725 4.25669 16.0897C4.29377 16.1069 4.33415 16.1158 4.37503 16.1158H7.51389C7.59699 15.9016 7.72802 15.7092 7.89694 15.5533Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
          <path d="M6.90625 8.38284C6.90729 9.15916 7.21373 9.90389 7.75936 10.4561C8.30498 11.0083 9.04598 11.3237 9.82223 11.3341C9.87058 11.1393 9.93567 10.9491 10.0168 10.7655C9.96449 10.7689 9.91249 10.7735 9.85936 10.7735C9.36505 10.7755 8.88214 10.625 8.47659 10.3424C8.07103 10.0598 7.7626 9.65884 7.59342 9.19438C7.42424 8.72991 7.40255 8.22455 7.53131 7.7473C7.66008 7.27005 7.93303 6.84418 8.31288 6.52785C8.69273 6.21152 9.16096 6.02016 9.65364 5.97989C10.1463 5.93962 10.6394 6.05241 11.0656 6.30286C11.4918 6.55331 11.8302 6.9292 12.0348 7.37921C12.2394 7.82922 12.3 8.3314 12.2085 8.81717C12.4004 8.75877 12.5969 8.71703 12.796 8.6924C12.838 8.29375 12.7985 7.89072 12.6797 7.50785C12.5609 7.12499 12.3655 6.77031 12.1052 6.46543C11.845 6.16055 11.5253 5.91185 11.1659 5.73449C10.8064 5.55712 10.4146 5.4548 10.0142 5.43376C9.61394 5.41272 9.21354 5.4734 8.83745 5.61211C8.46135 5.75081 8.11743 5.96464 7.82665 6.24056C7.53586 6.51648 7.3043 6.84871 7.14606 7.21701C6.98782 7.58532 6.90623 7.98198 6.90625 8.38284Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
          <path d="M18.9961 19.5849L18.4798 16.5067C18.4407 16.2923 18.3331 16.0964 18.1733 15.9482C18.0135 15.8 17.81 15.7076 17.5933 15.6848L15.1852 15.2952C15.1158 15.284 15.0493 15.2594 14.9894 15.2227C14.8145 15.3243 14.6312 15.4105 14.4415 15.4805C14.6075 15.6789 14.8398 15.8104 15.0954 15.8505L17.5098 16.2411C17.6074 16.2469 17.7004 16.2841 17.7751 16.3473C17.8497 16.4104 17.9019 16.496 17.9238 16.5913L18.3864 19.3502H8.08237L8.54626 16.5828C8.57019 16.4883 8.62383 16.404 8.69927 16.3423C8.77472 16.2807 8.86798 16.2448 8.96532 16.2402L11.3734 15.8505C11.629 15.8104 11.8613 15.6789 12.0273 15.4805C11.8376 15.4105 11.6543 15.3243 11.4794 15.2227C11.4195 15.2594 11.353 15.284 11.2836 15.2952L8.88182 15.6838C8.66529 15.7053 8.4615 15.7962 8.30083 15.943C8.14016 16.0897 8.03121 16.2845 7.99022 16.4982L7.47263 19.5849C7.46586 19.6252 7.46797 19.6665 7.47879 19.706C7.48962 19.7454 7.5089 19.782 7.53531 19.8132C7.56172 19.8444 7.5946 19.8694 7.63169 19.8866C7.66877 19.9038 7.70915 19.9127 7.75003 19.9127H18.7187C18.7596 19.9127 18.8 19.9038 18.8371 19.8866C18.8742 19.8694 18.9071 19.8444 18.9335 19.8132C18.9599 19.782 18.9792 19.7454 18.99 19.706C19.0008 19.6665 19.0029 19.6252 18.9961 19.5849Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
          <path d="M10.2813 12.1797C10.2812 12.7637 10.4544 13.3347 10.7789 13.8203C11.1034 14.306 11.5646 14.6845 12.1043 14.908C12.6439 15.1315 13.2376 15.19 13.8105 15.076C14.3833 14.9621 14.9095 14.6808 15.3225 14.2678C15.7355 13.8548 16.0168 13.3286 16.1307 12.7558C16.2447 12.183 16.1862 11.5892 15.9627 11.0496C15.7392 10.51 15.3607 10.0487 14.875 9.72425C14.3894 9.39976 13.8184 9.22656 13.2344 9.22656C12.4514 9.22745 11.7008 9.53887 11.1472 10.0925C10.5936 10.6461 10.2821 11.3967 10.2813 12.1797ZM13.2344 9.78906C13.7072 9.78906 14.1694 9.92927 14.5625 10.192C14.9557 10.4546 15.2621 10.828 15.443 11.2648C15.6239 11.7017 15.6713 12.1823 15.579 12.6461C15.4868 13.1098 15.2591 13.5358 14.9248 13.8701C14.5905 14.2044 14.1645 14.4321 13.7008 14.5244C13.237 14.6166 12.7563 14.5693 12.3195 14.3883C11.8827 14.2074 11.5093 13.901 11.2466 13.5078C10.984 13.1147 10.8437 12.6525 10.8437 12.1797C10.8445 11.5459 11.0966 10.9382 11.5447 10.49C11.9929 10.0419 12.6006 9.78977 13.2344 9.78906Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
          </svg><div>Recommended<span className="ml-4"> Matches</span></div></p>
          
          <p className={`py-[16px] px-1 mb-0 mt-3 w-[217px] text-black hover:transform transition duration-150 hover:scale-105 h-[51px] text-[14px] hover:cursor-pointer flex ml-8 rounded-xl ${
            selectedKey === "HiddenCandidates" ? "bg-gradient-to-r from-gray-50 to-teal-300 px-1" : ""
          }`} onClick={() => handleSelection("HiddenCandidates")}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="21"  className="mt-[1px] mr-3 ml-6" viewBox="0 0 20 21" fill="none">
<path d="M3.91604 17.3724C3.79696 17.3705 3.68102 17.3339 3.58252 17.2669C3.48401 17.2 3.40724 17.1057 3.36167 16.9956C3.31609 16.8856 3.3037 16.7646 3.32603 16.6476C3.34835 16.5306 3.4044 16.4227 3.48729 16.3372L4.87132 14.9532C3.25259 14.0118 1.93433 12.6303 1.06982 10.9693C1.02407 10.8825 1.00011 10.7858 1 10.6877C0.999895 10.5896 1.02365 10.4929 1.06921 10.4061C3.33221 5.7896 9.2947 3.83543 13.9821 5.84234L15.6552 4.1693C15.7696 4.05924 15.9227 3.99847 16.0815 4.00003C16.2403 4.00159 16.3921 4.06537 16.5044 4.17765C16.6167 4.28994 16.6804 4.44178 16.682 4.60057C16.6835 4.75935 16.6227 4.91241 16.5127 5.02687L15.1305 6.4091C16.752 7.35236 18.0705 8.73903 18.9308 10.406C18.9764 10.493 19.0001 10.5898 19 10.688C18.9999 10.7862 18.9759 10.8829 18.9302 10.9698C16.649 15.5771 10.7036 17.5267 6.01907 15.5205L4.34481 17.1947C4.28857 17.2511 4.22174 17.2959 4.14816 17.3264C4.07457 17.3568 3.99569 17.3725 3.91604 17.3724ZM6.96008 14.58C10.915 16.0065 15.6956 14.3821 17.7025 10.6856C16.8943 9.2498 15.691 8.07614 14.2356 7.30396L13.0482 8.49136C15.4788 11.992 11.2593 16.1282 7.8052 13.7343L6.96008 14.58ZM2.29749 10.6856C3.10927 12.1169 4.31232 13.2871 5.76557 14.0589L6.95177 12.8727C6.43817 12.1489 6.19733 11.2667 6.27189 10.3824C6.34646 9.49811 6.73162 8.66873 7.35914 8.0412C7.98667 7.41367 8.81605 7.02851 9.70037 6.95395C10.5847 6.87939 11.4669 7.12023 12.1906 7.63382L13.041 6.78339C9.08432 5.35589 4.28887 6.98301 2.29749 10.6856ZM8.67399 12.8656C9.16204 13.1541 9.73217 13.272 10.2946 13.201C10.8571 13.1299 11.3799 12.8737 11.7809 12.4729C12.1818 12.072 12.438 11.5492 12.5092 10.9868C12.5804 10.4243 12.4625 9.85418 12.1741 9.36609L8.67399 12.8656ZM10.0101 8.14018C7.70346 8.24516 6.97497 10.3943 7.82059 12.0039L11.3159 8.50793C10.9223 8.26856 10.4707 8.14139 10.0101 8.14018Z" fill="#059669"/>
</svg>Hidden Candidates</p>

          </div>)}
            
            
            
            <p className={`py-[16px] mb-0 w-[217px] text-black hover:transform transition duration-150 hover:scale-105 h-[51px] text-[14px] hover:cursor-pointer flex ml-8 rounded-xl ${
            selectedKey === "pipeline" ? "bg-gradient-to-r from-gray-50 to-teal-300" : ""
            }`} onClick={() => handleSelection("pipeline")}><svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" className="mt-[1px] mr-4" viewBox="0 0 21 20" fill="none">
            <path d="M17.1082 2.55302H9.35855L7.67968 0.55704C7.64097 0.511052 7.59267 0.474079 7.53818 0.448708C7.48368 0.423336 7.4243 0.410179 7.36419 0.410156H1.41207C1.35795 0.410142 1.30436 0.420791 1.25436 0.441495C1.20436 0.462198 1.15893 0.492551 1.12066 0.530818C1.08239 0.569085 1.05204 0.614517 1.03134 0.664519C1.01063 0.71452 0.999986 0.768111 1 0.822229V13.6955C1.00059 14.197 1.20008 14.6778 1.55473 15.0325C1.90937 15.3871 2.39021 15.5866 2.89175 15.5872H17.1082C17.6098 15.5866 18.0906 15.3871 18.4453 15.0325C18.7999 14.6778 18.9994 14.197 19 13.6955V4.44456C18.9994 3.94304 18.7999 3.46223 18.4452 3.10762C18.0906 2.75301 17.6098 2.55356 17.1082 2.55302ZM1.82414 1.2343H7.17223L8.28169 2.55302H1.82414V1.2343ZM18.1759 13.6955C18.1756 13.9785 18.063 14.2499 17.8628 14.45C17.6627 14.6502 17.3913 14.7628 17.1082 14.7631H2.89175C2.6087 14.7628 2.33732 14.6502 2.13717 14.45C1.93702 14.2499 1.82445 13.9785 1.82414 13.6955V3.37718H17.1082C17.3913 3.37747 17.6626 3.49001 17.8628 3.69012C18.0629 3.89022 18.1755 4.16154 18.1759 4.44456V13.6955Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
            </svg>Pipeline</p>

            <p className={`py-[16px] mb-0 w-[217px] text-black hover:transform transition duration-150 hover:scale-105 h-[51px] text-[14px] hover:cursor-pointer flex ml-8 rounded-xl ${
            selectedKey === "JobPost" ? "bg-gradient-to-r from-gray-50 to-teal-300" : ""
            }`} onClick={() => handleSelection("JobPost")}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className="mt-[1px] mr-4" fill="none">
            <path d="M10.2453 16.2871H9.75358C9.17055 16.2863 8.69727 15.8138 8.69727 15.2308V12.5446C8.69727 11.9616 9.17057 11.4891 9.75358 11.4883H10.2453C10.8283 11.4891 11.3016 11.9616 11.3016 12.5446V15.2308C11.3016 15.8138 10.8283 16.2863 10.2453 16.2871ZM9.75358 12.0627C9.4869 12.0627 9.27146 12.2789 9.27066 12.5448V15.2309C9.27146 15.4968 9.4869 15.7131 9.75358 15.7131H10.2453C10.512 15.7131 10.7274 15.4968 10.7282 15.2309V12.5448C10.7274 12.2789 10.512 12.0627 10.2453 12.0627H9.75358Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
            <path d="M15.4866 7.9521C15.328 7.9521 15.1991 7.82397 15.1991 7.6654V5.76016C15.1991 4.22974 13.9538 2.98441 12.4234 2.98441H7.57651C6.04608 2.98441 4.80076 4.22974 4.80076 5.76016V7.6654C4.80076 7.82397 4.67182 7.9521 4.51326 7.9521C4.35469 7.9521 4.22656 7.82397 4.22656 7.6654V5.76016C4.22656 3.9126 5.72976 2.41016 7.57657 2.41016H12.4234C14.271 2.41016 15.7734 3.91335 15.7734 5.76016V7.6654C15.7734 7.74148 15.743 7.81436 15.6893 7.86802C15.6357 7.92168 15.5627 7.9521 15.4866 7.9521Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
            <path d="M13.7685 7.95049C13.61 7.95049 13.4818 7.82236 13.4818 7.66379V5.86988C13.481 5.1395 12.8884 4.54687 12.158 4.54607H7.84136C7.11098 4.54687 6.51836 5.1395 6.51756 5.86988V7.66379C6.51756 7.82236 6.38942 7.95049 6.23086 7.95049C6.07229 7.95049 5.94336 7.82236 5.94336 7.66379V5.86988C5.94496 4.82236 6.79386 3.97346 7.84138 3.97266H12.1581C13.2056 3.97346 14.0545 4.82236 14.0561 5.86988V7.66379C14.0561 7.82236 13.9271 7.95049 13.7685 7.95049Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
            <path d="M8.98443 13.9881C8.95961 13.9881 8.93478 13.9849 8.91075 13.9785L2.48239 12.2815C1.60866 12.0508 1 11.2604 1 10.357C1 10.1984 1.12814 10.0703 1.2867 10.0703C1.44526 10.0703 1.5734 10.1984 1.5734 10.357C1.5742 11.0001 2.00746 11.5623 2.62892 11.7265L9.05667 13.4235C9.19682 13.4603 9.28731 13.5949 9.2689 13.7382C9.25048 13.8808 9.12795 13.9881 8.9838 13.9881L8.98443 13.9881Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
            <path d="M11.0154 13.9881C10.8712 13.9881 10.7495 13.8808 10.7311 13.7382C10.7127 13.5949 10.8031 13.4603 10.9433 13.4235L17.3711 11.7265C17.9925 11.5623 18.4258 11.0001 18.4266 10.357C18.4266 10.1984 18.5547 10.0703 18.7133 10.0703C18.8718 10.0703 19 10.1984 19 10.357C19 11.2604 18.3913 12.0508 17.5176 12.2815L11.0892 13.9785C11.0652 13.9849 11.0402 13.9881 11.0154 13.9881Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
            <path d="M17.5128 19.149H2.48719C1.66631 19.1482 1.0008 18.4827 1 17.6618V8.86609C1.0008 8.04522 1.66631 7.37971 2.48719 7.37891H17.5128C18.3337 7.37971 18.9992 8.04522 19 8.86609V17.6618C18.9992 18.4827 18.3337 19.1482 17.5128 19.149ZM2.48719 7.95312C1.98266 7.95392 1.57422 8.36236 1.57342 8.86609V17.6618C1.57422 18.1655 1.98266 18.5748 2.48719 18.5748H17.5128C18.0173 18.5748 18.4258 18.1655 18.4266 17.6618V8.86609C18.4258 8.36236 18.0173 7.95392 17.5128 7.95312H2.48719Z" fill="#059669" stroke="#059669" strokeWidth="0.25"/>
            </svg>Job Post</p>

            {/* <Button className={`bg-white pl-4 pr-4 w-64 rounded-xl pt-2 pb-2 flex border-white ml-6 ${
            selectedKey === "JobPost" ? "bg-gradient-to-r from-gray-50 to-teal-300" : ""
          }`}
          onClick={() => handleSelection("JobPost")}><svg xmlns="http://www.w3.org/2000/svg" fill="#00945b" className="mr-4" width="24px" height="24px" viewBox="0 0 24 24" stroke="#00945b"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path></g></svg>Job Post</Button>           */}
                         
      </div>
      {selectedKey==="pipeline"?<Pipeline/>:""}
      {selectedKey==="JobPost"?<ProjectJob/>:""}
      {selectedKey==="Applicants"?<ApplicantsFirst/>:""}
      {selectedKey==="Recruiter"?<RecruiterSearch/>:""}
      {selectedKey==="RecommendedMatches"?<RecommendedMatches/>:""}
      {selectedKey==="HiddenCandidates"?<HiddenCandidates/>:""}
      {selectedKey==="Overview"?<Overview/>:""}
    </div>
  );
};

export default ProjectsBar;
