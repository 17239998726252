import React , { useState, useRef ,useContext} from "react";
import Logo from "../Common/Logo";
import Textinput from "../elements/TextInput/TextInput";
import Button from "../elements/Button/Button";
import Card from "../elements/Card/Card";
import ImageIcon from "../elements/ImageIcon/ImageIcon";
import CryptoJS from 'crypto-js';
import axios from 'axios';
import Swal from 'sweetalert2';
import Notification from "../Notification/Notification";
import ClipLoader from "react-spinners/ClipLoader";
import GoogleSignUp from "../GmailSignUp/GmailSignUp";
import PasswordStrength from "../elements/PasswordStrength/PasswordStrength";
import './SignUp.css';
import Edit from "../../assets/Images/edit.jpg"
const SignUpDetails = () => {
  const [error, setError] = useState({name:'', email: '', password: '', password: '',phone: '',signUpImage:'' }); // Track field errors
    const [signUpImage, signUpImageFun] = useState(false); 
    const [signUpImageName, signUpImageNameFun] = useState(false); 
    const [signUpNotification, signUpNotificationCheckFun] = useState(false);
    const [notification, setNotification] = useState({ message: "", type: "" });
    const [signUpPhoneVal, signUpPhoneNoFun] = useState(); 
    const [signUpNameVal, signUpNameFun] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const signUpName = useRef(null);       // Ref for name input
    const signUpEmail = useRef(null);      // Ref for email input
    const signUpPassword = useRef(null);   // Ref for password input
    const signUpMobileNo = useRef(null);   // Ref for mobile number input
    const [signUpimagepath, signUpimagepathFun] = useState('');
    const SignUpImageTemp = async (event) => {   //Upload Image Fun
      
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", signUpImage);
    // console.log(formData.get("file"));
    try {
        const serverURL = process.env.REACT_APP_SERVER_URL;
        const response = await fetch(`${serverURL}/signUpImageTemp`, {
            method: 'POST',
            body: formData, // Automatically sets the correct Content-Type
        });
        if (!response.ok) {
            throw new Error('Failed to upload file');
        }

        const result = await response.json();
        console.log("Server Response:", result);
        signUpImageFun(result.files.file[0]['newFilename'])
        signUpImageNameFun(result.files.file[0]['originalFilename'])
        var newPath = result.files.file[0]['newFilename'] ? result.files.file[0]['newFilename'] : '';
        signUpimagepathFun(`${process.env.REACT_APP_SERVER_BASEURL}/images/temp/${newPath}`)
        // console.log(result.files.file[0]['newFilename'])
    } catch (error) {
        console.error("Error uploading file:", error);
    }
};
const showNotification = (message, type) => {   //For Notification
    setNotification({ message, type });
    setTimeout(() => {
      setNotification({ message: "", type: "" });
    }, 3000); // Auto-hide after 3 seconds
};
const signUpImageInsideLabelCode = (            //lable without Image name
        <div className="flex pt-6 pb-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" height="32" viewBox="0 0 32 32" width="32"><path d="m16 8a5 5 0 1 0 5 5 5 5 0 0 0 -5-5z"/><path d="m16 2a14 14 0 1 0 14 14 14.0158 14.0158 0 0 0 -14-14zm7.9925 22.9258a5.0016 5.0016 0 0 0 -4.9925-4.9258h-6a5.0016 5.0016 0 0 0 -4.9925 4.9258 12 12 0 1 1 15.985 0z"/><path d="m0 0h32v32h-32z" fill="none"/></svg>
            <p className="mb-1 ml-2 w-1/2 text-xs text-gray-500 dark:text-gray-400">Add profile<br></br> picture</p> 
        </div>
)
const signUpImageInsideLabelCodeTemp = (        //label with Image name
    <div className="flex w-full items-center gap-2 justify-center">
      <img src={Edit} className="w-6 h-6 border-2 ml-12 fixed-0 mb-10 mr-3 absolute"></img>
        <img src={signUpimagepath} className="w-10 h-10 rounded-full object-fit" />
        {/* <p className="w-1/2 m-0 text-xs text-gray-500 dark:text-gray-400 overflow-scroll no-scrollbar ">
            {signUpImageName}
        </p>  */}
    </div>


)
const validateSignUpPhoneNo = (e) => {          //Mobile Number Validation                
    const input = e.target.value.replace(/\D/g, '').slice(0, 10);
    if(input)
    signUpMobileNo.current.classList.remove("!border-red-600");
    signUpPhoneNoFun(input);
};
const validateSignUpNameOnchangeFun = (e) => {    //Name Validation only allow sting ,space and dot
    const input = e.target.value.replace(/[^a-zA-Z.\s]/g, '');
    if(input)
    signUpName.current.classList.remove("!border-red-600");
    signUpNameFun(input);
};
const emailOnchange = () =>{
    signUpEmail.current.classList.remove("!border-red-600");
}
const passwordOnchange = () =>{
    signUpPassword.current.classList.remove("!border-red-600");
}
const validateEmail = (email) => {             //Mail Validation Fun
    const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{1,3}$/;
    return emailRegex.test(email);
};
const SignUpRegister = async () =>{         //Send Details of SignUp to backend
    var name=signUpName.current.value
    var email=signUpEmail.current.value
    var password=signUpPassword.current.value
    var phone=signUpMobileNo.current.value;
    const errors = {};
    if(!name){
        showNotification("Enter Name","error");
        errors.name = "Enter Name.";
        signUpName.current.classList.add("!border-red-600");
        signUpName.current.focus();        
      }

    if(!email){
        showNotification("Enter Email","error");
        errors.email = "Enter Valid Email.";
        signUpEmail.current.classList.add("!border-red-600");
        signUpEmail.current.focus();    
      }
    else if(!validateEmail(email)) {
        showNotification("Enter Valid Email","error");
        errors.email = "Enter Valid Email.";
        signUpEmail.current.classList.add("!border-red-600");
        signUpEmail.current.focus();    
      }
    
    if(!password){
        showNotification("Enter Password","error");
        errors.password = "Enter password.";
        signUpPassword.current.classList.add("!border-red-600");
        signUpPassword.current.focus();
    }
    if(!phone){
        showNotification("Enter Mobile Number","error");
        errors.phone = "Enter Mobile Number.";
        signUpMobileNo.current.classList.add("!border-red-600");
        signUpMobileNo.current.focus();
    }
    else if(phone.length <10){
        showNotification("Enter Valid Mobile No","error");
        errors.phone = "Enter Valid Mobile No.";
        signUpMobileNo.current.classList.add("!border-red-600");
        signUpMobileNo.current.focus();
    }
    if(!signUpImage){
        showNotification("Please Upload Profile","error");
        errors.signUpImage = "Please Upload Profile.";
    }
    setError(errors);

    if (Object.keys(errors).length > 0) {
        return false; // Stop if there are errors
    }

    var signUpdata = {};
    signUpdata['name'] = name;
    signUpdata['email'] = email;
    signUpdata['password'] = password;
    signUpdata['phone_no'] = phone;
    signUpdata['image'] = signUpImage;
    signUpdata['notification'] = signUpNotification;
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({ name:name,email:email,password:password,phone_no:phone,image:signUpImage,notification:signUpNotification }), process.env.REACT_APP_SECRETKEY).toString();
    const serverURL = process.env.REACT_APP_SERVER_URL;
    var encryptjson = { data:encryptedData}
    console.log(encryptjson,"nilll")
    setIsLoading(true);
    try {
        const response = await fetch(`${serverURL}/signUp`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(encryptjson),
        });
        const data = await response.json();
        console.log(data)

        // const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        // console.log(decryptedData);
        if (!response.ok) {
            var errorMessage = "Something Went Wrong";
            if(data.error)
                errorMessage = data.error;
            showNotification(errorMessage,"error");
            throw new Error(data.error);
        }
        else{
            Swal.fire({
            title: 'Account created',
            text: 'Please check your mail and activate your account.',
            icon: 'success',
            confirmButtonText: 'OK',
        }).then((result) => {
            if (result.isConfirmed) {
                window.location = "/SignIn"; // Redirect to the SignIn page
            }
        });
    }
    } catch (error) {
        console.log('Error:', error);
        let errorMessage = "Something went wrong.";
        if (error.response && error.response.data && error.response.data.msg) {
            errorMessage = error.response.data.msg;
        } 
        else if (error.message) {
            errorMessage = error.message;
        }
        showNotification(errorMessage, "error");
    } finally {
        setIsLoading(false); // Always hide loader after the request
    }
}
const singUpNotificationCheck = (event) => {   //Get the value of notification checkbox
    signUpNotificationCheckFun(event.target.checked);
};
const eyeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-5 h-5 text-teal-300"
      fill="none"
      stroke="gray"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z"
      />
    </svg>
  );
  const eyeOffIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-5 h-5 text-teal-300"
      fill="none"
      stroke="gray"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        d="m17.069 6.546 2.684-2.359c.143-.125.32-.187.497-.187.418 0 .75.34.75.75 0 .207-.086.414-.254.562l-16.5 14.501c-.142.126-.319.187-.496.187-.415 0-.75-.334-.75-.75 0-.207.086-.414.253-.562l2.438-2.143c-1.414-1.132-2.627-2.552-3.547-4.028-.096-.159-.144-.338-.144-.517s.049-.358.145-.517c2.111-3.39 5.775-6.483 9.853-6.483 1.815 0 3.536.593 5.071 1.546zm2.319 1.83c.966.943 1.803 2.014 2.474 3.117.092.156.138.332.138.507s-.046.351-.138.507c-2.068 3.403-5.721 6.493-9.864 6.493-1.297 0-2.553-.313-3.729-.849l1.247-1.096c.795.285 1.626.445 2.482.445 3.516 0 6.576-2.622 8.413-5.5-.595-.932-1.318-1.838-2.145-2.637zm-3.434 3.019c.03.197.046.399.046.605 0 2.208-1.792 4-4 4-.384 0-.756-.054-1.107-.156l1.58-1.389c.895-.171 1.621-.821 1.901-1.671zm-.058-3.818c-1.197-.67-2.512-1.077-3.898-1.077-3.465 0-6.533 2.632-8.404 5.5.853 1.308 1.955 2.567 3.231 3.549l1.728-1.519c-.351-.595-.553-1.289-.553-2.03 0-2.208 1.792-4 4-4 .925 0 1.777.315 2.455.843zm-2.6 2.285c-.378-.23-.822-.362-1.296-.362-1.38 0-2.5 1.12-2.5 2.5 0 .36.076.701.213 1.011z"
      />
    </svg>
  );
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {                
    setShowPassword(!showPassword);
  };
  const [showStrengthConfirm,setStrengthTextConfirm]=useState("");
  const [nowTextConfirm,setChangeConfirm]=useState("");
  const [strengthFirstConfirm,setStrengthFirstConfirm]=useState("");
  const [strengthSecondConfirm,setStrengthSecondConfirm]=useState("");
  const [strengthThirdConfirm,setStrengthThirdConfirm]=useState("");
  const [strengthFourthConfirm,setStrengthFourthConfirm]=useState("");
  const handleChangeConfirm = (e) => {
    const value = e.target.value;
    setChangeConfirm(value);
  
    let strengthScoreConfirm = 0;

    if (value.length === 0) strengthScoreConfirm = 0;
    if (/[a-z]/.test(value)) strengthScoreConfirm++;
    if (/[A-Z]/.test(value)) strengthScoreConfirm++;
    if (/[0-9]/.test(value)) strengthScoreConfirm++;
    if (/[\W_]/.test(value)) strengthScoreConfirm++;
  
    console.log("strength: ", strengthScoreConfirm, "length: ", value.length);
  
    if (strengthScoreConfirm > 0 && strengthScoreConfirm <= 1) {
      setStrengthFirstConfirm("bg-red-500");
      setStrengthTextConfirm("Very Weak");
      setStrengthSecondConfirm("");
      setStrengthThirdConfirm("");
      setStrengthFourthConfirm("");
    } else if (strengthScoreConfirm === 2) {
      setStrengthFirstConfirm("bg-yellow-500");
      setStrengthSecondConfirm("bg-yellow-500");
      setStrengthTextConfirm("Weak");
      setStrengthThirdConfirm("");
      setStrengthFourthConfirm("");
    } else if (strengthScoreConfirm === 3) {
      setStrengthFirstConfirm("bg-green-500");
      setStrengthSecondConfirm("bg-green-500");
      setStrengthThirdConfirm("bg-green-500");
      setStrengthTextConfirm("Good");
      setStrengthFourthConfirm("");
    } else if (strengthScoreConfirm === 4) {
      setStrengthFirstConfirm("bg-green-500");
      setStrengthSecondConfirm("bg-green-500");
      setStrengthThirdConfirm("bg-green-500");
      setStrengthFourthConfirm("bg-green-500");
      setStrengthTextConfirm("Strong");
    } else {
      setStrengthTextConfirm("");
      setStrengthFirstConfirm("");
      setStrengthSecondConfirm("");
      setStrengthThirdConfirm("");
      setStrengthFourthConfirm("");
    }
  
    
  };

    return(
        <div className="SignUpCard-Body flex ml-0 w-100 h-100 fixed top-0">
        <div className="ml-4 mr-4  w-1/4 SignUp-Page">
        <Logo />
	     <p className="text-black font-medium text-lg pl-6">Register and get access to:</p>

        <div className="bg-white h-screen">
           

            
      <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "
      CardClass="min-w-md ml-6 bg-white shadow-mild mb-4 rounded-lg overflow-hidden w-5/6 !border-none"
        body={
          <>
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        <span className="text-white mt-4">Registering...</span>
                    </div>
                </div>
            )}
            <Notification
                message={notification.message}
                type={notification.type}
                onClose={() => setNotification({ message: "", type: "" })}
            />
            <div className="flex items-center mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 0 11 11" width="28">
                <g fill="none">
                  <path d="M5.5 11A5.5 5.5 0 1 1 5.5 0a5.5 5.5 0 0 1 0 11z" fill="#059669" />
                  <path d="M3.205 5.72a.66.66 0 0 0-.91.956l1.475 1.406a.66.66 0 0 0 .894.016l4.43-3.938a.66.66 0 1 0-.878-.987l-3.974 3.534z" fill="#fff" />
                </g>
              </svg>
              <p className="text-xs mt-3 pl-4 text-gray-700 dark:text-gray-400">Bigger talend pool</p>
            </div>
            <hr />
            <div className="flex items-center mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 0 11 11" width="28">
                <g fill="none">
                  <path d="M5.5 11A5.5 5.5 0 1 1 5.5 0a5.5 5.5 0 0 1 0 11z" fill="#059669" />
                  <path d="M3.205 5.72a.66.66 0 0 0-.91.956l1.475 1.406a.66.66 0 0 0 .894.016l4.43-3.938a.66.66 0 1 0-.878-.987l-3.974 3.534z" fill="#fff" />
                </g>
              </svg>
              <p className="text-xs pl-4 mt-3 text-gray-700 dark:text-gray-400">AI-powered matches</p>
            </div>
            <hr />
            <div className="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 0 11 11" width="28">
                <g fill="none">
                  <path d="M5.5 11A5.5 5.5 0 1 1 5.5 0a5.5 5.5 0 0 1 0 11z" fill="#059669" />
                  <path d="M3.205 5.72a.66.66 0 0 0-.91.956l1.475 1.406a.66.66 0 0 0 .894.016l4.43-3.938a.66.66 0 1 0-.878-.987l-3.974 3.534z" fill="#fff" />
                </g>
              </svg>
              <p className="text-xs pl-4 mt-3 text-gray-700 dark:text-gray-400">Data-backed hiring insights</p>
            </div>
          </>
        }
      />
    </div>

        </div>
        <div className="bg-white w-[80%] bg-opacity-50 px-4">
    <Card CardClass="SignUp-Flex-Card flex mt-4 pl-4 w-full mr-2 !border-none !rounded-xl"
    customdiv={<>
    <div className="SignUp-Flex-Card-Content flex mt-4 ml-0">
    <div className="w-full p-8">
        <p className="text-lg font-bold">Create your profile</p>
        <div className="signup-form">
            <div className="flex">
            <div className="w-full">
            <div className="Flex-Mobile flex">
            <div className="w-4/5 mobile-w-full">
                <Textinput className="!border-[#BFE0E2]"  label="Full Name" onInput={(e) => {setError((prev) => ({ ...prev, name: '' }));}} InputRef={signUpName} value={signUpNameVal} onChange={validateSignUpNameOnchangeFun} span="*" placeholder="What is your name?" />
                {error.name && <p className="error-message">{error.name}</p>}
            </div>
            
<div className="w-1/3 mt-2 pl-7 SignUP-Profile_img">
{/* <svg xmlns="http://www.w3.org/2000/svg" className="absolute ml-28" height="24" width="24"><g transform="translate(0 -1028.4)"><path d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z" fill="#27ae60" transform="translate(0 1029.4)"/><path d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z" fill="#2ecc71" transform="translate(0 1028.4)"/><path d="m6.0001 1042.4h4.9999v5h2v-5h5v-2h-5v-5h-2v5h-4.9999z" fill="#27ae60"/><path d="m6 1041.4h5v5h2v-5h5v-2h-5v-5h-2v5h-5z" fill="#ecf0f1"/></g></svg>
    <label for="dropzone-file" className="mt-2 flex-col items-center justify-center w-32 h-14 border-2 border-gray-300 border-none rounded-lg cursor-pointer bg-gray-100 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
        <div className="flex pt-6 pb-2">
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" height="32" viewBox="0 0 32 32" width="32"><path d="m16 8a5 5 0 1 0 5 5 5 5 0 0 0 -5-5z"/><path d="m16 2a14 14 0 1 0 14 14 14.0158 14.0158 0 0 0 -14-14zm7.9925 22.9258a5.0016 5.0016 0 0 0 -4.9925-4.9258h-6a5.0016 5.0016 0 0 0 -4.9925 4.9258 12 12 0 1 1 15.985 0z"/><path d="m0 0h32v32h-32z" fill="none"/></svg>
        <p className="mb-1 ml-2 w-1/2 text-xs text-gray-500 dark:text-gray-400">Add profile<br></br> picture</p> 
        </div>
        <input id="dropzone-file" type="file" className="hidden" />
    </label> */}
    <ImageIcon OnChange={(event) =>SignUpImageTemp(event)} OnInput={() => setError((prev) => ({ ...prev, signUpImage: '' }))} ImageClass={`!block`}  insideLabel={signUpImage ?  signUpImageInsideLabelCodeTemp : signUpImageInsideLabelCode} labelClass="mt-2 flex-col items-center justify-center w-32 h-14 border-2 border-gray-300 border-none rounded-lg cursor-pointer bg-[#F8F8F8]" inputid="dropzone-file" outersvg={<svg xmlns="http://www.w3.org/2000/svg" className="absolute ml-28" height="24" width="24"><g transform="translate(0 -1028.4)"><path d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z" fill="#059669" transform="translate(0 1029.4)"/><path d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z" fill="#059669" transform="translate(0 1028.4)"/><path d="m6.0001 1042.4h4.9999v5h2v-5h5v-2h-5v-5h-2v5h-4.9999z" fill="#27ae60"/><path d="m6 1041.4h5v5h2v-5h5v-2h-5v-5h-2v5h-5z" fill="#ecf0f1"/></g></svg>
}/>
    {error.signUpImage && <p className="error-message">{error.signUpImage}</p>}
    
</div> 

            
            </div>
            <div className="form-group">
                <Textinput label="Email Id" span="*" onChange={emailOnchange} InputRef={signUpEmail}    onInput={(e) => {setError((prev) => ({ ...prev, email: '' }));}} placeholder="Tell us your Email ID" className="w-full !border-[#BFE0E2]"/>
                {error.email && <p className="error-message">{error.email}</p>}
                <p className="text-xs text-gray-500">We'll send relevant jobs and updates to this email</p>
            </div>
            </div>
            <div className="SignUp-OR">
            <p className="mt-20 ml-8 absolute pr-2 pt-1 pb-1 pl-2 rounded-full bg-[#F2F9F9] text-[#059669] font-medium">Or</p>
            <div className="h-16 ml-12 mt-2 border-l-2 border-gray-200"></div>
            <div className="h-16 ml-12 mt-12 border-l-2 border-gray-200"></div>
            </div>
            </div>
            <div className="mobile-w-full w-11/12">
            <div className="w-full mb-8">
            <Textinput  svgicon ={showPassword ? eyeIcon : eyeOffIcon} OnInput={(e) => { handleChangeConfirm(e);setError((prev) => ({ ...prev, password: '' }));}}
             inputPassPostClass="absolute right-3 top-5 transform -translate-y-1/2 cursor-pointer" type={showPassword ? 'text' : 'password'} divclass = "relative" inputPassPostOnclick={togglePasswordVisibility} onChange={passwordOnchange} label="Password" span="*" InputRef={signUpPassword} placeholder="(Minimum 6 characters)" className="!w-full !border-[#BFE0E2]"/>
            {error.password && <p className="error-message">{error.password}</p>}
            <PasswordStrength Strength_Box_First={`${strengthFirstConfirm}`} Strength_Box_Second={`${strengthSecondConfirm}`} Strength_Box_Third={`${strengthThirdConfirm}`} Strength_Box_Fourth={`${strengthFourthConfirm}`} Strength_Box_Class="w-3/12 h-1 w-full bg-gray-100 rounded-lg" Strength_Hole_Class="flex items-center gap-2 mt-2" />
            <p className="profile_sidebar_progress_text text-gray-400 flex mb-0 float-right mt-1">{showStrengthConfirm?showStrengthConfirm:'Password Strength'}</p>
            </div>
            <div className="">
            <Textinput label="Mobile Number" span="*" value={signUpPhoneVal} onChange={validateSignUpPhoneNo} OnInput={() => setError((prev) => ({ ...prev, phone: '' }))} InputRef={signUpMobileNo} placeholder="Enter Mobile Number" className="w-3/5 !border-[#BFE0E2]"/>
            {error.phone && <p className="error-message">{error.phone}</p>}
            </div>
            </div>
            <div >
                <p className="text-sm flex mb-0">
                    <Textinput type="checkbox" onChange={singUpNotificationCheck} className="text-xs mt-1 !border-[#BFE0E2]"/>
                    <p className="mb-2 ml-2">Send me important updates & promotions via sms,email and </p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 mr-1 mt-1" fill="none" height="15" viewBox="0 0 92 92" width="15">
                      <rect fill="#00d95f" height="91.5618" rx="45.7809" width="91.5618"/><g fill="#fff">
                        <path d="m22.8906 66.9759 3.2847-12.2132c-2.649-4.7577-3.4906-10.3095-2.3704-15.6366 1.1202-5.3272 4.1271-10.0724 8.4689-13.3649 4.3418-3.2924 9.7273-4.9113 15.1683-4.5596 5.4409.3517 10.5723 2.6505 14.4525 6.4745 3.8803 3.824 6.2491 8.9167 6.6718 14.3436.4226 5.4269-1.1291 10.824-4.3706 15.2009-3.2415 4.3768-7.9553 7.4399-13.2763 8.6271-5.3211 1.1872-10.8925.4189-15.6919-2.1639zm12.9318-7.8624.763.4518c3.4763 2.0571 7.5366 2.9084 11.5482 2.4212 4.0115-.4873 7.7489-2.2856 10.6298-5.1148 2.8809-2.8293 4.7434-6.5304 5.2973-10.5267.5539-3.9964-.2319-8.0634-2.235-11.5673s-5.1109-6.2479-8.8392-7.8044c-3.7283-1.5564-7.8675-1.8379-11.7728-.8005-3.9052 1.0373-7.357 3.3353-9.8175 6.5357s-3.7915 7.1234-3.7855 11.1578c-.0032 3.3452.9234 6.6257 2.6769 9.4762l.4785.7876-1.8363 6.8167z"/><path clipRule="evenodd" d="m54.3404 47.0031c-.4473-.3602-.971-.6137-1.5313-.7412-.5602-.1275-1.1422-.1257-1.7017.0053-.8405.3486-1.3837 1.6654-1.9268 2.3239-.1145.1578-.2828.2685-.4733.3113s-.39.0147-.5612-.079c-3.0774-1.2032-5.6568-3.4099-7.3194-6.2615-.1419-.1779-.209-.4039-.1873-.6302.0218-.2264.1306-.4355.3036-.5834.6058-.5987 1.0506-1.3402 1.2932-2.156.0539-.8998-.1526-1.7961-.5948-2.5821-.3419-1.1018-.9926-2.0829-1.8751-2.8273-.4552-.2044-.96-.2729-1.4533-.1973s-.9542.292-1.3271.6233c-.6473.5575-1.161 1.2529-1.5034 2.0349-.3425.7821-.5048 1.6308-.4751 2.4837.0019.479.0628.956.181 1.4202.3003 1.1153.7621 2.1809 1.3708 3.163.4391.7523.9183 1.4807 1.4354 2.1819 1.6805 2.3032 3.793 4.2587 6.2202 5.758 1.218.7619 2.5197 1.3814 3.8795 1.8462 1.4126.6392 2.9722.8846 4.5132.71.878-.1326 1.7099-.4787 2.4225-1.0078.7126-.529 1.284-1.2248 1.664-2.0261.2232-.484.291-1.0252.1939-1.5492-.2327-1.0716-1.6682-1.7042-2.5475-2.2206z" fillRule="evenodd"/></g>
                        </svg> Whatsapp
                </p>
                <p className="text-xs">
                By clicking Register, you agree to the<a href="#">Terms and Conditions</a> & <a href="#">Privacy Poilicy</a> of Eeezo.com
            </p>
            </div>
            
            <Button name="Register" onClick={SignUpRegister} className="mobile-w-full text-white mt-4 bg-green-700 pl-4 pr-4 pt-2 pb-2">Register</Button>
            
        </div>
    </div>
    <div className="w-3/5 ml-2 mt-4 mobile-w-full mobile-margin-no mobile-flex-center">
    <Card CardClass="w-10/12 shadow-none ml-10 mobile-margin-no border-white"
      customdiv={ 
        <>
        <div className="bg-gradient-to-r from-sky-50 to-teal-400 mt-14 p-8 rounded-xl">
            <GoogleSignUp showNotification={showNotification} setIsLoading={setIsLoading} />
            <Button className="google-button bg-white text-black border-none flex !pl-4 pt-2.5 pb-2.5 pr-5  mb-2 text-sm"><svg xmlns="http://www.w3.org/2000/svg" className="mr-4" fill="none" height="24" viewBox="0 0 24 24" width="24"><path d="m19.0413 19.0413h-2.9635v-4.6409c0-1.1067-.0197-2.5313-1.5412-2.5313-1.5435 0-1.7797 1.2057-1.7797 2.4507v4.7211h-2.96337v-9.54349h2.84487v1.30419h.0399c.5792-.99038 1.6602-1.5841 2.8069-1.54152 3.0035 0 3.5573 1.97562 3.5573 4.54582zm-12.59151-10.84845c-.94342 0-1.72009-.77622-1.72009-1.71964s.77631-1.71973 1.71973-1.71973c.94333 0 1.71955.77613 1.71973 1.71937 0 .94324-.77613 1.71991-1.71937 1.72zm1.48169 10.84845h-2.96651v-9.54389h2.96651zm12.58712-17.0398h-17.04282c-.80002-.00903-1.46603.6415-1.47578 1.44152v17.11358c.00939.8004.67531 1.4517 1.47578 1.4431h17.04282c.8024.0101 1.4707-.641 1.4814-1.4431v-17.11475c-.0107-.80171-.6799-1.45216-1.4814-1.4416z" fill="#0a66c2"/></svg>Register with LinkedIn</Button>
        </div>
        <p className="signup-link pt-4">
                Already have an account? <a href="/SignIn" className="!text-[#059669]">Sign In</a>
            </p>
        </>
            }
    />
    </div></div>
</>}
/>
</div>
</div>
)
};
export default SignUpDetails;
