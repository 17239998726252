import React, { useState, useEffect, useContext } from "react";
import ModalBox from "../elements/ModalBox/ModalBox";
import Button from "../elements/Button/Button";
import Textarea from "../elements/TextArea/TextArea";
import Textinput from "../elements/TextInput/TextInput";
import { AuthContext } from "../../context/AuthContext";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import Notification from "../Notification/Notification";
import Swal from 'sweetalert2';

const MessageTemplate = () => {
    const { token } = useContext(AuthContext);
    const [notification, setNotification] = useState({ message: "", type: "" });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [templateName, setTemplateName] = useState("");
    const [templateText, setTemplateText] = useState("");
    const [messageTemplates, setMessageTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [selectedRow, setSelectedRow] = useState(null);

    const showNotification = (message, type) => {
        setNotification({ message, type });
        setTimeout(() => {
            setNotification({ message: "", type: "" });
        }, 3000);
    };

    const locateDashboard = () => {
        navigate("/Dashboard");
    };

    const openForm = () => {
        setIsModalOpen(true);
    };

    const closeForm = () => {
        setIsModalOpen(false);
        setTemplateName("");
        setTemplateText("");
    };

    useEffect(() => {
        messageDetails();
    }, []);


    const saveTemplateDetails = async () => {
        if (!templateName.trim()) {
            showNotification("Enter the template name", "error");
            return;
        }
        
        if (!templateText.trim()) {
            showNotification("Enter the template text", "error");
            return;
        }

        const encryptedMetadata = localStorage.getItem("metadata");
        let company_id = "";
        if (encryptedMetadata) {
            const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            company_id = decryptedMetadata?.cid || "";
        }
        
        const serverURL = process.env.REACT_APP_SERVER_URL;
        const jsonData = { cid: company_id, title: templateName, template: templateText };
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData), process.env.REACT_APP_SECRETKEY).toString();
        
        setIsLoading(true);
        try {
            const response = await fetch(`${serverURL}/messagetemp/insertMessageTemplate`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token,
                },
                body: JSON.stringify({ data: encryptedData }),
            });
            
            const data = await response.json();
            
            if (!response.ok) {
                showNotification(data.error || "Something went wrong", "error");
                throw new Error(data.error);
            }
            showNotification("Template saved successfully!", "success");
            closeForm();
            messageDetails();
        } catch (error) {
            console.error("Error saving template:", error);
            showNotification("An error occurred while saving the template", "error");
        } finally {
            setIsLoading(false);
        }
    };
    
    const messageDetails = async () => {
        const encryptedMetadata = localStorage.getItem("metadata");
        let company_id = "";
        if (encryptedMetadata) {
            const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            company_id = decryptedMetadata?.cid || "";
        }

        const serverURL = process.env.REACT_APP_SERVER_URL;
        const jsonData = { cid: company_id };
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData), process.env.REACT_APP_SECRETKEY).toString();

        setIsLoading(true);
        try {
            const response = await fetch(`${serverURL}/messagetemp/getMessageTemplates`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token,
                },
                body: JSON.stringify({ data: encryptedData }),
            });

            const data = await response.json();
            
            if (!response.ok) {
                showNotification(data.error || "Something went wrong", "error");
                throw new Error(data.error);
            }
            const messageTemplates = data.data || [];
            setMessageTemplates(messageTemplates);
            
            // Set default selected row based on active status
            const activeTemplate = messageTemplates.find((template) => template.status === true);
            if (activeTemplate) {
                setSelectedRow(activeTemplate.aid);
            }
        } catch (error) {
            console.error("Error Fetching template:", error);
            showNotification("An error occurred while fetching the template", "error");
        } finally {
            setIsLoading(false);
        }
    };

    const updateStatus = async () => {
        if (!selectedRow) {
            console.log("No row selected. Exiting function.");
            return;
        }

        const encryptedMetadata = localStorage.getItem("metadata");
        let company_id = "";
        if (encryptedMetadata) {
            const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            company_id = decryptedMetadata?.cid || "";
        }
        const serverURL = process.env.REACT_APP_SERVER_URL;
        const jsonData = { aid: selectedRow, cid: company_id };
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData), process.env.REACT_APP_SECRETKEY).toString();
        
        setIsLoading(true);
        
        try {
            const response = await fetch(`${serverURL}/messagetemp/setUpdateStatus`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token,
                },
                body: JSON.stringify({ data: encryptedData }),
            });
            
            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.error || "Something went wrong");
            }
            
            showNotification("Template status updated successfully", "success");
            messageDetails();
            setSelectedRow(null);
        } catch (error) {
            showNotification("An error occurred while updating the template status", "error");
        } finally {
            setIsLoading(false);
        }
    };
    
    const handleRowSelection = (template) => {
        if (template.status !== true || template.status !== false) {
            setSelectedRow(template.aid);
        }
    };

    const handleActivate = () => {
        if (selectedRow) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Do you want to activate this template?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, activate it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    updateStatus();
                }
            });
        }
    };
    
    const modal_Body = (
        <div className="h-[60%]">
            <div className="w-3/4 h-[85%] ml-14">
                <Textinput value={templateName || ""} onChange={(e) => setTemplateName(e.target.value)} label="Template Name" labelclass="mt-3" span="*" className="w-2/5 mb-3 border-2 border-blue-100" placeholder="Enter Template Name" />
                <Textarea value={templateText} onChange={(e) => setTemplateText(e.target.value)} label="Text" labelclass="mt-1" span="*" className="w-2/5 h-[247px] border-2 border-blue-100" placeholder="Enter the Text" />
            </div>
        </div>
    );

    const Modal_Footer = (
        <>
            <Button className="border-2 border-green-800 bg-white mt-2 text-sm text-green-700 w-1/4 ml-12 pt-2 pb-2 pl-8 pr-8 rounded" onClick={closeForm}>Cancel</Button>
            <Button className="border-2 border-green-800 bg-green-700 text-sm text-white w-2/5 ml-4 pt-2 pb-2 pr-2 rounded" onClick={saveTemplateDetails}>Save</Button>
        </>
    );
    return (
        <div className="w-8/12 p-6 bg-white border border-gray-200 rounded-lg shadow">
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <span className="text-white mt-4">Loading...</span>
                    </div>
                </div>
            )}

            <Notification
                message={notification.message}
                type={notification.type}
                onClose={() => setNotification({ message: "", type: "" })}
            />
            <div className="flex items-center">
                <div className="mt-3">
                    <p className="text-black text-lg font-medium">Message Template</p>
                </div>
                <div className="ml-auto flex items-center gap-2">
                    {selectedRow && messageTemplates.find((template) => template.aid === selectedRow)?.status !== true && (
                        <button className="pt-2 pb-2 px-4 rounded-lg bg-green-700 text-white" onClick={handleActivate}>Activate</button>
                    )}
                    <button className="pt-2 pb-2 px-4 rounded-lg bg-green-700 text-white" onClick={openForm}>Add New Template</button>
                    {isModalOpen && (
                        <div>
                            <ModalBox Modal_Body={modal_Body}
                                modal_Body_Class="h-[70%]"
                                modal_Fooder_Class="border-t-2 border-gray-300 mt-1"
                                Modal_Close_Func={closeForm}
                                Modal_Header="Add New Template"
                                modal_Header_Text="mt-4 mb-3 ml-14"
                                Modal_Footer={Modal_Footer}
                                modal_Header_Class="border-gray-300 border-b-2 h-[9%] font-medium"
                                modal_Close_Class="mt-2 mr-2 border-2 border-gray-300 rounded-lg pl-1.5 pt-0.5 pb-0.5 pr-1.5"
                                cardClass="bg-white rounded-2xl shadow-lg w-5/12 h-[85%] p-0 max-w-xl"
                            />
                        </div>
                    )}
                </div>
            </div>
            <table className="w-full text-left mt-4 table-auto">
                <thead>
                    <tr className="bg-sky-50">
                        <th className="p-1 border-b w-1/12 border-blue-gray-100 bg-blue-green-50">
                            <input type="checkbox" disabled className="ml-4 appearance-none h-4 w-4 border border-gray-300 rounded-sm checked:bg-green-500 checked:border-green-500 focus:outline-none" />
                        </th>
                        <th className="p-1 border-b w-1/4 border-blue-gray-100 bg-blue-gray-50">
                            <p className="block mt-2 font-sans text-md antialiased font-normal leading-none text-black">Template Name</p>
                        </th>
                        <th className="p-1 border-b w-1/4 border-blue-gray-100 bg-blue-gray-50">
                            <p className="block mt-2 font-sans text-md antialiased font-normal leading-none text-black">Text</p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {messageTemplates && messageTemplates.length > 0 ? (
                        messageTemplates.map((template, index) => (
                            <tr key={index} className="border-b border-blue-gray-50">
                                <td className="p-1 border-b border-blue-gray-50">
                                    <input
                                        type="checkbox"
                                        className="ml-4 h-4 w-4 border border-gray-300 rounded-sm checked:bg-green-500 checked:border-green-500 focus:outline-none"
                                        checked={template.aid === selectedRow}
                                        onChange={() => handleRowSelection(template)}
                                    />
                                </td>
                                <td className="p-1 border-b border-blue-gray-50">
                                    <p className="block ml-4 mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                                        {template.title}
                                    </p>
                                </td>
                                <td className="p-1 border-b border-blue-gray-50">
                                    <p className="block ml-4 mt-2 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                                        {template.template}
                                    </p>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3" className="py-2 px-4 border-b text-center">No templates available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default MessageTemplate;
