// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hamburger-menu {
    width: 20px;
    height: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

/* Individual lines (bars) */
.line {
    width: 100%;
    height: 2px;
    background-color: #d9d9d9;
    border-radius: 5px;
}

.button-type {
    padding: 10px 20px;
    border: 1px solid #BFE0E2;
    border-radius: 15px;
    margin: 10px 10px 10px 0px;
    cursor: pointer;
}
.button-type.active {
    background-color: #059669 !important;
    color: white;
}
.div-border {
    border: 1px solid grey;
    border-radius: 10px;
    padding: 10px;
}
.top-section-email {
    border-bottom: 1px solid #d9d9d9;
}
.second-section-subject {
    border-bottom: 1px solid #d9d9d9;
}
.mild-colour {
    color: #747474;
}`, "",{"version":3,"sources":["webpack://./src/pages/Triage/ViewMessage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;AACnB;;AAEA,4BAA4B;AAC5B;IACI,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,0BAA0B;IAC1B,eAAe;AACnB;AACA;IACI,oCAAoC;IACpC,YAAY;AAChB;AACA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,gCAAgC;AACpC;AACA;IACI,gCAAgC;AACpC;AACA;IACI,cAAc;AAClB","sourcesContent":[".hamburger-menu {\n    width: 20px;\n    height: 15px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    cursor: pointer;\n}\n\n/* Individual lines (bars) */\n.line {\n    width: 100%;\n    height: 2px;\n    background-color: #d9d9d9;\n    border-radius: 5px;\n}\n\n.button-type {\n    padding: 10px 20px;\n    border: 1px solid #BFE0E2;\n    border-radius: 15px;\n    margin: 10px 10px 10px 0px;\n    cursor: pointer;\n}\n.button-type.active {\n    background-color: #059669 !important;\n    color: white;\n}\n.div-border {\n    border: 1px solid grey;\n    border-radius: 10px;\n    padding: 10px;\n}\n.top-section-email {\n    border-bottom: 1px solid #d9d9d9;\n}\n.second-section-subject {\n    border-bottom: 1px solid #d9d9d9;\n}\n.mild-colour {\n    color: #747474;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
