import React, { useState } from 'react';
import Calendar from 'react-calendar';
import './ScheduleCalendar.css';
import ButtonSolid from '../elements/Button/ButtonSolid';
import ButtonHallow from '../elements/Button/ButtonHallow';

const CalendarModal = ({ isOpen, onClose, onSave }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [schedule, setSchedule] = useState({});

  const handleSave = () => {
    if (selectedDate && startTime && endTime) {
      const istOffset = 5.5 * 60; // IST is UTC+5:30
      const adjustedDate = new Date(selectedDate.getTime() + (istOffset * 60 * 1000));
      const formattedDate = adjustedDate.toISOString().split('T')[0];
      const newSchedule = {
        ...schedule,
        [formattedDate]: `${startTime} - ${endTime}`,
      };
      setSchedule(newSchedule);
      onSave(newSchedule);
      setStartTime('');
      setEndTime('');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-[90%] max-w-4xl">
        <div className="flex justify-between items-center border-b pb-4 mb-4">
          <h2 className="text-xl font-bold">Select the date's you want to assign specific hours</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 font-bold text-lg"
          >
            &times;
          </button>
        </div>

        <div className="flex gap-4 mobile-flex-column">
          <div className='Calendar-Div mobile-full-width'>
            <Calendar
              onChange={setSelectedDate}
              value={selectedDate}
            />
          </div>

          <div className="flex flex-col">
            {selectedDate && (
              <>
                <h3 className="text-lg font-semibold mb-2">
                  Selected Date: {selectedDate.toDateString()}
                </h3>
                <p>What hours are you available ?</p>
                <div className="mb-4 flex gap-2">
                  <input
                    type="time"
                    className="border rounded px-2 py-1 w-full"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  />-
                  <input
                    type="time"
                    className="border rounded px-2 py-1 w-full"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="mt-4 text-right">

        <ButtonSolid
            onClick={handleSave}
            className="bg-blue-500 !w-[100px] mr-4 text-white px-4 py-2 rounded"
            content='Save'/>
          <ButtonHallow
            onClick={onClose}
            className="bg-gray-500 !w-[100px] px-4 py-2 rounded"
            content="Cancel"
            />
        </div>
      </div>
    </div>
  );
};

export default CalendarModal;
