import React from "react";
import ProjectDashboard from "../../components/JobDetailApprove/JobDetailApprove";
import Topbar from "../../components/Recruiter/Topbar/Topbar";

const JobApprove = () =>{
    return(
        <div className="radial-gradient-bg">
            <Topbar/>
            <ProjectDashboard/>
        </div>
    )
}
export default JobApprove;