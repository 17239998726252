import React, { useState,useEffect, useContext } from "react";
import Card from "../elements/Card/Card";
import Selectbox from "../elements/SelectBox/SelectBox";
import Textinput from "../elements/TextInput/TextInput";
import Profile from "../../assets/Images/person.jpg";
import Button from "../elements/Button/Button";
import { AuthContext } from "../../context/AuthContext";
import CryptoJS from "crypto-js";
import { useCommonContext } from "../../context/CommonContext";
const JobTeam = () =>{
    const { token } = useContext(AuthContext);
    const { jobCreationData, setJobCreationData, validationErrors } = useCommonContext();
    const [addTeamMember,setTeamMember]=useState([0]);
    const handleTeamMember = () => {
        // console.log(addTeamMember, "value");
        setJobCreationData((prevData) => ({
            ...prevData,
            team: [...prevData.team, { name: "", designation: "", url: "" }] // Add a new member object with default values
        }));
        // setTeamMember((prev) => [...prev, prev.length + 1]);
    };
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const [selectedValue,setSelectedValue]=useState();
    const handleProjectDropdown = (e) =>{
        setSelectedValue(e.target.value)
    }
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [userName, setUserName] = useState("");
    const [userDesignation, setUserDesignation] = useState(""); 
     const [userPhoto, setUserPhoto] = useState(Profile); // Default photo
    useEffect(()=>{
        const setUSerName = () => {
            const encryptedMetadata = localStorage.getItem('metadata');
            const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
	    setUserName(decryptedMetadata.name);
            setUserDesignation(decryptedMetadata.designation)
		if(decryptedMetadata.profile && decryptedMetadata.profile.startsWith("http")){
	 setUserPhoto(decryptedMetadata.profile ? decryptedMetadata.profile : Profile);
		}else
        setUserPhoto(decryptedMetadata.profile ? `${process.env.REACT_APP_SERVER_BASEURL}/profile/${decryptedMetadata.profile}` : Profile);
	}
        if(userName === "" && userDesignation === ""){
            setUSerName();
        }
    },[userName,userDesignation])
    useEffect(()=>{
        const loadProfile = async () => {
            try {
                  const serverURL = process.env.REACT_APP_SERVER_URL;
                  const response = await fetch(`${serverURL}/company/fetchCompanyUsers`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        token: token
                      },
                  });
                  const responsedata = await response.json();
          
                  const decryptedData = JSON.parse(CryptoJS.AES.decrypt(responsedata.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                  console.log(decryptedData);
                  if (!response.ok) {
                      var errorMessage = "Something Went Wrong";
                      if(responsedata.error)
                          errorMessage = responsedata.error;
                      throw new Error(responsedata.error);
                  }
                  else{
                      console.log("Company Users : ",decryptedData)
                      const data = Array.isArray(decryptedData) ? decryptedData : [];
                      setUserData(data);
                  }
              } catch (error) {
                  console.log('Error:', error);
                  let errorMessage = "Something went wrong.";
                  if (error.response && error.response.data && error.response.data.msg) {
                      errorMessage = error.response.data.msg;
                  } 
                  else if (error.message) {
                      errorMessage = error.message;
                  }
              } finally {
                  setIsLoading(false); // Always hide loader after the request
              }
          }
          if(isLoading){
            loadProfile();
          }

    },[isLoading])

    const [visibleCount, setVisibleCount] = useState(4); // Number of initially visible users

    const handleShowMore = () => {
      setVisibleCount((prevCount) => prevCount + 5); // Show 5 more names on click
    };
    const handleFormTeamChange = (e, index) => {
        const { id, value } = e.target;
    
        setJobCreationData((prevData) => ({
            ...prevData,
            team: prevData.team.map((member, idx) =>
                idx === index
                    ? { ...member, [id]: value } // Update the specific member
                    : member // Keep other members unchanged
            ),
        }));
    };

    const handleCollaboratorChange = (e, user) => {
        const { checked } = e.target;
    
        setJobCreationData((prevData) => {
            const updatedCollaborators = checked
                ? [...prevData.collaborators, user.uid] // Add user if checked
                : prevData.collaborators.filter((collab) => collab !== user.uid); // Remove user if unchecked
    
            return {
                ...prevData,
                collaborators: updatedCollaborators,
            };
        });
    };
    
    
    const users = Object.values(userData); 
    const projectList = [
    { id: '0', label: 'Select Project' },
    { id: '1', label: 'Lorem ispum dolor sit avc?' },
    { id: '2', label: 'UX Designer' },
    { id: '3', label: 'Software Engineer / Developer' },
    { id: '4', label: 'IT Support Specialist' },
    { id: '5', label: 'DevOps Engineer' },
    { id: '6', label: 'Cybersecurity Analyst' },
    { id: '7', label: 'Mobile App Developer' }
    ]
    const handleDeleteTeamMember = (index) => {
        setJobCreationData((prevState) => {
          const updatedTeam = [...prevState.team];
          updatedTeam.splice(index, 1); // Remove the member at the specified index
          return {
            ...prevState,
            team: updatedTeam,
          };
        });
      };
      
    return(
        <div className="pt-1 pl-1 ml-7">
            {/* <Card CardBodyClass={"gap-[12px]"} CardClass="min-w-md bg-white relative shadow rounded-lg w-[1175px]  mt-4 !rounded-xl"
                body={
                    <>
                    <p className="text-[20px] font-[700] mb-0">Add to a project*</p>
                    <p className="text-[16px] font-[400]">Your job will be created within a project to help you manage applicants, source passive candidates and get recommended matches in one place</p>
                    <Selectbox value={selectedValue} list={projectList} className="!w-[400px] pt-[12px] pb-[12px] pr-[16px] pl-[16px]" onChange={handleProjectDropdown}/>
                    </>
                }
            /> */}
            <Card CardBodyClass={"gap-[12px]"} CardClass={`mobile-full-width min-w-md bg-white relative shadow rounded-lg w-[full]  mt-4 !rounded-xl ${validationErrors.collaborators ? "!border-red-500 border-2" : ""}`}
                body={
                    <>
                    <p className="text-[20px] font-[700] mb-0">add collaborators</p>
                    {/* <p className="text-[16px] font-[400] mb-0">Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor</p> */}
                    {users.slice(0, visibleCount).map((user, index) => (
                        <Textinput
                        key={user.uid || index} // Use user.uid if unique, otherwise fallback to index
                        type="checkbox"
                        postlabel={user.name || "Unnamed User"} // Ensure postlabel is a string
                        labelclass="hidden"
                        className="!w-[18.1px] mt-1"
                        postlabelclass="ml-2 mb-0"
                        divclass="flex mt-1"
                        onChange={(e) => handleCollaboratorChange(e, user)} // Pass event and user
                        checked={jobCreationData.collaborators.some((collab) => collab === user.uid)} // Reflect checked state

                        />
                    ))}
                    {visibleCount < users.length && ( // Show "Show More" only if there are more users to show
                        <p
                        className="text-[12px] font-[400] mt-1 hover:cursor-pointer w-[82px]"
                        onClick={handleShowMore}
                        >
                        Show more...
                        </p>
                    )}
                    </>
                }
            />
            <Card CardBodyClass={"gap-[12px]"} CardClass="mobile-full-width min-w-md bg-white relative shadow rounded-lg w-[full]  mt-4 !rounded-xl"
                body={
                    <>
                    <p className="text-[20px] font-[700] mb-0">add a profile to your Job Post</p>
                    <p className="text-[16px] font-[400] mb-0">Give your job a personal touch to attract more applicants</p>
                    <div className="flex gap-2">
                    <Textinput type="checkbox" className={`!h-[20px] !w-[20px]`} divclass={`justify-center items-center flex p-[10px]`}
                    postlabelclass="ml-2 w-[15%]"/>
                    <div className="w-[274px] bg-teal-50 flex p-[24px]">
                        <img src={userPhoto} className="w-[77.97px] h-[76.13px] rounded-full object-cover ml-2"/>
                        <div>
                            <p className="text-[20px] font-[500] mt-[12px] ml-[12px] mb-0">{userName}</p>
                            <p className="text-[12px] text-gray-600 font-[400] ml-[12px]">{userDesignation ? userDesignation : 'User'}</p>
                        </div>
                    </div>
                    </div>
                    </>
                }
            />
            <Card CardBodyClass={"gap-[12px]"} CardClass="mobile-full-width min-w-md bg-white relative shadow rounded-lg w-[full]  mt-4 !rounded-xl"
                body={
                    <>
                    <p className="text-[20px] font-[700] mb-0">Meet Your Team</p>
                    <p className="text-[16px] font-[400] mb-0">Add members below which will be visible to the Job seekers under ‘Meet your teams’ sections</p>
                    {jobCreationData.team.map((member, index) => (
                        <div key={index} className="flex mobile-flex-column gap-[20px] mt-3 items-center">
                            <div>
                            <Textinput
                                id="name"
                                label="Name"
                                value={member.name || ""}
                                onChange={(e) => handleFormTeamChange(e, index)}
                                className="!w-[300px] outline-none"
                            />
                            </div>
                            <div>
                            <Textinput
                                id="designation"
                                label="Designation"
                                value={member.designation || ""}
                                onChange={(e) => handleFormTeamChange(e, index)}
                                className="!w-[300px] outline-none"
                            />
                            </div>
                            <div>
                            <Textinput
                                id="url"
                                label="Linked URL"
                                value={member.url || ""}
                                onChange={(e) => handleFormTeamChange(e, index)}
                                className="!w-[300px] outline-none"
                            />
                            </div>
                            {index > 0 && ( 
                            <div
                                onClick={() => handleDeleteTeamMember(index)}
                                className="cursor-pointer text-red-500 hover:text-red-700"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute hover:cursor-pointer" x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
                                <path d="M 24 4 C 20.491685 4 17.570396 6.6214322 17.080078 10 L 10.238281 10 A 1.50015 1.50015 0 0 0 9.9804688 9.9785156 A 1.50015 1.50015 0 0 0 9.7578125 10 L 6.5 10 A 1.50015 1.50015 0 1 0 6.5 13 L 8.6386719 13 L 11.15625 39.029297 C 11.427329 41.835926 13.811782 44 16.630859 44 L 31.367188 44 C 34.186411 44 36.570826 41.836168 36.841797 39.029297 L 39.361328 13 L 41.5 13 A 1.50015 1.50015 0 1 0 41.5 10 L 38.244141 10 A 1.50015 1.50015 0 0 0 37.763672 10 L 30.919922 10 C 30.429604 6.6214322 27.508315 4 24 4 z M 24 7 C 25.879156 7 27.420767 8.2681608 27.861328 10 L 20.138672 10 C 20.579233 8.2681608 22.120844 7 24 7 z M 11.650391 13 L 36.347656 13 L 33.855469 38.740234 C 33.730439 40.035363 32.667963 41 31.367188 41 L 16.630859 41 C 15.331937 41 14.267499 40.033606 14.142578 38.740234 L 11.650391 13 z M 20.476562 17.978516 A 1.50015 1.50015 0 0 0 19 19.5 L 19 34.5 A 1.50015 1.50015 0 1 0 22 34.5 L 22 19.5 A 1.50015 1.50015 0 0 0 20.476562 17.978516 z M 27.476562 17.978516 A 1.50015 1.50015 0 0 0 26 19.5 L 26 34.5 A 1.50015 1.50015 0 1 0 29 34.5 L 29 19.5 A 1.50015 1.50015 0 0 0 27.476562 17.978516 z"></path>
                                </svg>
                            </div>
                            )}
                        </div>
                        ))}
                    <Button className="p-[6px] mt-[12px] w-[158px] h-[40px] text-[16px] font-[500] text-[#059669] bg-white border-[#059669]" onClick={handleTeamMember}>Add Member +</Button>
                    </>
                }
            />
        </div>
    );
}
export default JobTeam;
