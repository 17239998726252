// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
    .signup-card{
        display: flex;
        flex-direction: column;
        padding: 20px;
    }
    .left-side {
        width: 100%;
        padding: 0;
    }
    .form-options {
        flex-direction: column;
    }
    .sigin-button-page {
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
        text-align: center !important;
    }
    .SignIn-Card {
        width: 90%;
        margin-top: 5px !important;
    }
    .right-side {
        width: 100%;
    }
    .linkedin-button {
        font-size: 14px;
        text-wrap: nowrap;
    }
    .or-divider .line {
        width: 40px;
        height: 1px;
    }
    .or-divider {
        flex-direction: row;
        width: 100%;
    }
    .SignInLogo{
        position: relative;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/SignIn/SignIn.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,aAAa;IACjB;IACA;QACI,WAAW;QACX,UAAU;IACd;IACA;QACI,sBAAsB;IAC1B;IACA;QACI,sBAAsB;QACtB,8BAA8B;QAC9B,kCAAkC;QAClC,6BAA6B;IACjC;IACA;QACI,UAAU;QACV,0BAA0B;IAC9B;IACA;QACI,WAAW;IACf;IACA;QACI,eAAe;QACf,iBAAiB;IACrB;IACA;QACI,WAAW;QACX,WAAW;IACf;IACA;QACI,mBAAmB;QACnB,WAAW;IACf;IACA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":["@media only screen and (max-width: 600px) {\n    .signup-card{\n        display: flex;\n        flex-direction: column;\n        padding: 20px;\n    }\n    .left-side {\n        width: 100%;\n        padding: 0;\n    }\n    .form-options {\n        flex-direction: column;\n    }\n    .sigin-button-page {\n        flex-direction: column;\n        align-items: center !important;\n        justify-content: center !important;\n        text-align: center !important;\n    }\n    .SignIn-Card {\n        width: 90%;\n        margin-top: 5px !important;\n    }\n    .right-side {\n        width: 100%;\n    }\n    .linkedin-button {\n        font-size: 14px;\n        text-wrap: nowrap;\n    }\n    .or-divider .line {\n        width: 40px;\n        height: 1px;\n    }\n    .or-divider {\n        flex-direction: row;\n        width: 100%;\n    }\n    .SignInLogo{\n        position: relative;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
