import React, { useState, useRef, useContext, useEffect } from 'react';
import { AuthContext } from "../../context/AuthContext";
import Meeting_Default from './meeting_details'
import Filterdrop from "./filterdrop";
import CryptoJS from 'crypto-js';
import './meetings.css';

const Meetings = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { token } = useContext(AuthContext);

    const [open, setOpen] = useState([false, false]);

    const [filterOpen, setFilteropen] = useState(false)

    const [filters, setFilters] = useState([false, false, false, false]);
    const [meetings, setMeetings] = useState([]);

    const handleFilterChange = (index) => {
        const newFilters = filters.map((filter, i) => i === index ? !filter : false);
        setFilters(newFilters);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.classList.contains('filtercls')) {
                setFilters([false, false, false, false]);
            }
        };
        document.addEventListener('click', handleClickOutside);
    }, []);
    const alljobs_arr = ['UX Designer', 'Software Developer', 'Senior Copywriter', 'Product Manager', 'Demo1', 'Demo2'];
    const allworkspaces_arr = ['Workspace 1', 'Workspace 2', 'Workspace 3', 'Workspace 4'];
    const activeevent = ['Active Event', 'Cancel Event'];
    const allinviteemail = ['abhat@gmail.com', 'abhat@gmail.com', 'abhat@gmail.com', 'abhat@gmail.com'];

    const [activeTab, setActiveTab] = useState("Upcoming");

    const navItems = ["Upcoming", "Past"];

    const changeActive = (tabName) => {
        setActiveTab(tabName);
    };

    useEffect(() => {
        meetingDetails();
    }, [activeTab]);
    
    function set_acc(index) {
        const newOpen = open.map((filter, i) => i === index ? !filter : false);
        setOpen(newOpen);
    }

    useEffect(() => {
        setOpen(Array(meetings.length).fill(false));
    }, [meetings]);

    
    const [isMeetingfetch, setIsMeetingfetch] = useState(false);
    const [time, setTime] = useState({});
    function formatDate(isoDate) {
        const date = new Date(isoDate);
        const day = String(date.getUTCDate()).padStart(2, "0"); 
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
      }
      const convertTo12HourFormat = (time) => {
        const [hours, minutes, seconds] = time.split(":").map(Number);
        const period = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
    };
    const meetingDetails = async () => {
        setIsMeetingfetch(true);
        const encryptedMetadata = localStorage.getItem("metadata");
        if (!encryptedMetadata) return;

        const decryptedMetadata = JSON.parse(
            CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8)
        );

        const type = activeTab.toLowerCase();

        const cardContent = { company_id: decryptedMetadata.cid, type: type };
        const encryptedDashData = CryptoJS.AES.encrypt(
            JSON.stringify(cardContent),
            process.env.REACT_APP_SECRETKEY
        ).toString();

        setIsLoading(true);


        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/calendly/fetchScheduleDetails`, {
                method: "POST",
                headers: { "Content-Type": "application/json", token },
                body: JSON.stringify({ data: encryptedDashData }),
            });

            const rawData = await response.text();
            const responsedata = JSON.parse(rawData);
            console.log(responsedata, "responsedata");

            if (!response.ok) throw new Error(responsedata.error || "Something went wrong");

            const decryptedData = responsedata.data;

            const meetingsData = decryptedData.filter(meeting => meeting && meeting.job_details).map((meeting) => {
                const date = new Date(meeting.date); 

                const [startHour, startMinute, startSecond] = meeting.start_time.split(":").map(Number);
                const [endHour, endMinute, endSecond] = meeting.end_time.split(":").map(Number);
            
                const formattedStartTime = new Date(date);
                formattedStartTime.setHours(startHour, startMinute, startSecond);
            
                const formattedEndTime = new Date(date);
                formattedEndTime.setHours(endHour, endMinute, endSecond);
            
                const timeZone = "Asia/Kolkata";
                const dateValue = date.toLocaleString("en-CA", {
                    timeZone,
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hourCycle: "h23",
                }).replace(", ", "T") + "+05:30";
            
                const startDateTime = formattedStartTime.toLocaleString("en-CA", {
                    timeZone,
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hourCycle: "h23",
                }).replace(", ", "T") + "+05:30";
            
                const endDateTime = formattedEndTime.toLocaleString("en-CA", {
                    timeZone,
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hourCycle: "h23",
                }).replace(", ", "T") + "+05:30";
                return{
                id: meeting.aid,
                name: meeting.name,
                title: meeting.job_details?.title,
                start_time: meeting.start_time,
                end_time: meeting.end_time,
                interviewer_id: meeting.interviewer_id,
                email: meeting.created_by, 
                question: meeting.query,
                job_id: meeting.job_id,
                zone: meeting.job_details?.timezone,
                create_at: meeting.job_details?.created_at ? new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).format(new Date(meeting.job_details.created_at)) : "Unknown Date",
                meeting_link: meeting.meeting_link,
                job_desc: meeting.job_details?.job_desc,
                interviewer_name: meeting.interviewer_name,
                date: dateValue,
                start_time: convertTo12HourFormat(meeting.start_time),
                end_time: convertTo12HourFormat(meeting.end_time),
                can_id: meeting.candidate_id,
                start: {
                    dateTime: startDateTime, 
                    timeZone,
                  },
                end: {
                    dateTime: endDateTime,
                    timeZone,
                },
                attendees: meeting.created_by,
                }
            });
            

            setMeetings(meetingsData);
            setTime({
                start: meetingsData[0]?.start,
                end: meetingsData[0]?.end,
            })
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsMeetingfetch(false);
            setIsLoading(false);
        }
    };

const groupedMeetings = Object.entries(meetings).map(([date, meetingsForDate]) => {
        return {
            date,
            meetings: meetingsForDate,
        };
    });

    return (
        <>
            <div className="ml-[5%] mt-8 py-4 px-4 rounded-xl bg-white bg-opacity-50">
                <h4 className="text-xl font-semibold mb-4">Meetings</h4>
                <div className="flex w-full justify-left mt-6" style={{ borderBottom: '1px solid grey' }}>
                    {navItems.map((item) => (
                        <button
                            key={item}
                            onClick={() => changeActive(item)}
                            className={`mx-4 py-2 text-lg ${activeTab === item
                                ? "text-green-600 border-b-4 border-green-600"
                                : "text-gray-600"
                                }`}
                        >
                            {item}
                        </button>
                    ))}
                </div>
                {filterOpen && (
                    <div className="flex w-full mt-3 px-4">
                        <div className="flex items-center justify-between w-4/6 pl-2">
                            <div className="flex flex-col">
                                <span className="text-[#747474] text-[14px] font-normal plusJakartaSansFont">Job</span>
                                <div>
                                    <button id="dropdownDefaultButton" onClick={() => handleFilterChange(0)} className="text-[#059669] font-medium rounded-lg text-sm text-center flex items-center justify-between w-full plusJakartaSansFont filtercls" type="button">
                                        All Jobs
                                        <svg className={filters[0] ? 'rotate-180 filtercls' : 'filtercls'} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_489_198002)"> <g clip-path="url(#clip1_489_198002)"> <path d="M4 6.5L7.58579 10.0858C8.25245 10.7525 8.58579 11.0858 9 11.0858C9.41421 11.0858 9.74755 10.7525 10.4142 10.0858L14 6.5" stroke="#059669" stroke-linecap="round" stroke-linejoin="round" /> </g> </g> <defs> <clipPath id="clip0_489_198002"> <rect width="18" height="18" fill="#059669" /> </clipPath> <clipPath id="clip1_489_198002"> <rect width="18" height="18" fill="#059669" transform="translate(18 18) rotate(180)" /> </clipPath> </defs> </svg>
                                    </button>
                                    {filters[0] && (
                                        <Filterdrop filterClass='filtercls' drop_data={alljobs_arr} id='alljobs' search={true} />
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-[#747474] text-[14px] font-normal plusJakartaSansFont">Workspace</span>
                                <div>
                                    <button id="dropdownDefaultButton" onClick={() => handleFilterChange(1)} className="text-[#059669] font-medium rounded-lg text-sm text-center flex items-center justify-between w-full plusJakartaSansFont filtercls" type="button">
                                        All Workspaces
                                        <svg className={filters[1] ? 'rotate-180 filtercls' : 'filtercls'} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_489_198002)"> <g clip-path="url(#clip1_489_198002)"> <path d="M4 6.5L7.58579 10.0858C8.25245 10.7525 8.58579 11.0858 9 11.0858C9.41421 11.0858 9.74755 10.7525 10.4142 10.0858L14 6.5" stroke="#059669" stroke-linecap="round" stroke-linejoin="round" /> </g> </g> <defs> <clipPath id="clip0_489_198002"> <rect width="18" height="18" fill="#059669" /> </clipPath> <clipPath id="clip1_489_198002"> <rect width="18" height="18" fill="#059669" transform="translate(18 18) rotate(180)" /> </clipPath> </defs> </svg>
                                    </button>
                                    {filters[1] && (
                                        <Filterdrop filterClass='filtercls' drop_data={allworkspaces_arr} id='allworkspaces' search={true} />
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-[#747474] text-[14px] font-normal plusJakartaSansFont">Status</span>
                                <div>
                                    <button id="dropdownDefaultButton" onClick={() => handleFilterChange(2)} className="text-[#059669] font-medium rounded-lg text-sm text-center flex items-center justify-between w-full plusJakartaSansFont filtercls" type="button">
                                        Active Event
                                        <svg className={filters[2] ? 'rotate-180 filtercls' : 'filtercls'} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_489_198002)"> <g clip-path="url(#clip1_489_198002)"> <path d="M4 6.5L7.58579 10.0858C8.25245 10.7525 8.58579 11.0858 9 11.0858C9.41421 11.0858 9.74755 10.7525 10.4142 10.0858L14 6.5" stroke="#059669" stroke-linecap="round" stroke-linejoin="round" /> </g> </g> <defs> <clipPath id="clip0_489_198002"> <rect width="18" height="18" fill="#059669" /> </clipPath> <clipPath id="clip1_489_198002"> <rect width="18" height="18" fill="#059669" transform="translate(18 18) rotate(180)" /> </clipPath> </defs> </svg>
                                    </button>
                                    {filters[2] && (
                                        <Filterdrop filterClass='filtercls' drop_data={activeevent} id='activeevent' search={false} />
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-[#747474] text-[14px] font-normal plusJakartaSansFont">Invitee Email ID</span>
                                <div>
                                    <button id="dropdownDefaultButton" onClick={() => handleFilterChange(3)} className="text-[#059669] font-medium rounded-lg text-sm text-center flex items-center justify-between w-full plusJakartaSansFont filtercls" type="button">
                                        All Invitee Emails
                                        <svg className={filters[3] ? 'rotate-180 filtercls' : 'filtercls'} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_489_198002)"> <g clip-path="url(#clip1_489_198002)"> <path d="M4 6.5L7.58579 10.0858C8.25245 10.7525 8.58579 11.0858 9 11.0858C9.41421 11.0858 9.74755 10.7525 10.4142 10.0858L14 6.5" stroke="#059669" stroke-linecap="round" stroke-linejoin="round" /> </g> </g> <defs> <clipPath id="clip0_489_198002"> <rect width="18" height="18" fill="#059669" /> </clipPath> <clipPath id="clip1_489_198002"> <rect width="18" height="18" fill="#059669" transform="translate(18 18) rotate(180)" /> </clipPath> </defs> </svg>
                                    </button>
                                    {filters[3] && (
                                        <Filterdrop filterClass='filtercls' drop_data={allinviteemail} id='allinviteemail' search={true} />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-end w-2/6">
                            <span className="plusJakartaSansFont text-[#059669] font-medium text-[14px]">Clear all Filters</span>
                        </div>
                    </div>
                )}
                <div className="mt-3">
                    <p className="plusJakartaSansFont font-medium text-base text-[#3D3D3D]">Thursday, 13th December 2024</p>
                </div>


                <div className="flex flex-col gap-4">
                    {isMeetingfetch ? (<div className="loader-container">
    <div className="spinner"></div>
    <h4>Fetching Meeting Details...</h4>
  </div>) : (
        <div>
        {groupedMeetings.length > 0 ? (
            groupedMeetings.map((group, groupIdx) => (
                <div key={groupIdx}>
                    <div className="mt-3">
                        <p className="plusJakartaSansFont font-medium text-base text-[#3D3D3D]">Thursday, 13th December 2024</p>
                    </div>
                    {group.meetings.length > 0 ? (
                        group.meetings.map((meeting, idx) => (
                            <div
                                key={meeting.id}
                                className="bg-white"
                                style={{
                                    boxShadow: '0px 4px 40px 0px #00000014',
                                    padding: '20px',
                                    borderRadius: '20px',
                                }}
                            >
                                <div
                                    className="flex items-center justify-between"
                                    onClick={() => set_acc(idx)}
                                >
                                    {/* Name */}
                                    <p className="m-0 plusJakartaSansFont font-bold text-xl text-[#1D1D1D]">
                                        {meeting.name}
                                    </p>

                                    {/* designation */}
                                    <p className="m-0 flex flex-col font-medium plusJakartaSansFont text-sm text-[#747474]">
                                        Job
                                        <span className="font-medium text-[#3D3D3D]">{meeting.title}</span>
                                    </p>

                                    {/* Time */}
                                    <p className="m-0 flex flex-col font-medium plusJakartaSansFont text-sm text-[#747474]">
                                        Time
                                        <span className="font-medium text-[#3D3D3D]">
                                            {meeting.start_time} - {meeting.end_time}
                                        </span>
                                    </p>

                                    {/* Interviewer */}
                                    <p className="m-0 flex flex-col font-medium plusJakartaSansFont text-sm text-[#747474]">
                                        Interviewer
                                        <span className="font-medium text-[#3D3D3D]">
                                            {meeting.interviewer_name}
                                        </span>
                                    </p>

                                    {/* Details */}
                                    <p className="m-0 flex items-center gap-1 text-[#059669] font-medium text-base">
                                        Details
                                        <svg
                                            className={`${open[idx] ? 'rotate-180' : ''}`}
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M12 4V20M12 20L18 14M12 20L6 14"
                                                stroke="#059669"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </p>
                                </div>
                                {open[idx] && (
                                    <Meeting_Default
                                        email={meeting.email}
                                        qus={meeting.question}
                                        index={idx}
                                        zone={meeting.zone}
                                        create_at={meeting.create_at}
                                        meeting_link={meeting.meeting_link}
                                        job_id={meeting.job_id}
                                        status={activeTab.toLowerCase()}
                                        job_desc={meeting.job_desc}
                                        interviewer={meeting.interviewer_name}
                                        date={meeting.date}
                                        time={time}
                                        attendees={meeting.attendees}
                                        title={meeting.title}
                                        name={meeting.name}
                                        candidate_id={meeting.can_id}
                                        startTime={meeting.start_time}
                                    />
                                )}
                            </div>
                        ))
                    ) : (
                        <div className="text-center text-gray-500 text-lg font-medium">
                            No {activeTab.toLowerCase()} meetings are available.
                        </div>
                    )}
                </div>
            ))
        ) : (
            <div className="text-center text-gray-500 text-lg font-medium">
                No {activeTab.toLowerCase()} are available.
            </div>
        )}
    </div>
)}
</div>

            </div>
        </>
    )
}

export default Meetings