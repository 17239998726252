// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
    .mobile-no-margin {
        margin: 0 !important;
    }
    .mobile-full-width {
        width: 100% !important;
    }
    .mobile-flex-column {
        flex-direction: column;
    }
    .MinimumCheckbox {
        width: 5% !important;
    }
    .RequireControl {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
    .MinimumTextValue {
        width: 50% !important;
        height: 30px;
    }
    .ResponseType {
        width: 30% !important;
    }
    .mobilepadding-10 {
        padding: 10px !important;
    }
}


.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #4caf50; /* Green */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .bg-opacity-70 {
    background-color: rgba(0, 0, 0, 0.7);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/JobPost/JobScreen.css"],"names":[],"mappings":"AAAA;IACI;QACI,oBAAoB;IACxB;IACA;QACI,sBAAsB;IAC1B;IACA;QACI,sBAAsB;IAC1B;IACA;QACI,oBAAoB;IACxB;IACA;QACI,aAAa;QACb,yBAAyB;QACzB,WAAW;IACf;IACA;QACI,qBAAqB;QACrB,YAAY;IAChB;IACA;QACI,qBAAqB;IACzB;IACA;QACI,wBAAwB;IAC5B;AACJ;;;AAGA;IACI,yBAAyB,EAAE,eAAe;IAC1C,6BAA6B,EAAE,UAAU;IACzC,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;EACpC;;EAEA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF;;EAEA;IACE,oCAAoC;EACtC","sourcesContent":["@media only screen and (max-width: 600px) {\n    .mobile-no-margin {\n        margin: 0 !important;\n    }\n    .mobile-full-width {\n        width: 100% !important;\n    }\n    .mobile-flex-column {\n        flex-direction: column;\n    }\n    .MinimumCheckbox {\n        width: 5% !important;\n    }\n    .RequireControl {\n        display: flex;\n        justify-content: flex-end;\n        width: 100%;\n    }\n    .MinimumTextValue {\n        width: 50% !important;\n        height: 30px;\n    }\n    .ResponseType {\n        width: 30% !important;\n    }\n    .mobilepadding-10 {\n        padding: 10px !important;\n    }\n}\n\n\n.loader {\n    border: 4px solid #f3f3f3; /* Light grey */\n    border-top: 4px solid #4caf50; /* Green */\n    border-radius: 50%;\n    width: 40px;\n    height: 40px;\n    animation: spin 1s linear infinite;\n  }\n  \n  @keyframes spin {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  \n  .bg-opacity-70 {\n    background-color: rgba(0, 0, 0, 0.7);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
