// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-card {
    border: 1px solid #ddd;
    margin: 8px 0;
    border-radius: 4px;
    overflow: hidden;
    width: 75%;
}

.accordion-header {
    background: #f5f5f5;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
}

.accordion-content {
    padding: 12px;
    background: #fff;
}

.slot-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}


.time-picker-container {
    display: flex;
    gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Calendar/CalendarList.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,iBAAiB;IACjB,6BAA6B;IAC7B,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;;AAGA;IACI,aAAa;IACb,QAAQ;AACZ","sourcesContent":[".accordion-card {\n    border: 1px solid #ddd;\n    margin: 8px 0;\n    border-radius: 4px;\n    overflow: hidden;\n    width: 75%;\n}\n\n.accordion-header {\n    background: #f5f5f5;\n    padding: 12px;\n    cursor: pointer;\n    font-weight: bold;\n    border-bottom: 1px solid #ddd;\n    display: flex;\n    justify-content: space-between;\n    text-align: center;\n    align-items: center;\n}\n\n.accordion-content {\n    padding: 12px;\n    background: #fff;\n}\n\n.slot-container {\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n}\n\n\n.time-picker-container {\n    display: flex;\n    gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
