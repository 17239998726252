import React, { useState, useEffect, useContext } from "react";
import Notification from "../Notification/Notification";
import Profile from "../../assets/Images/person.jpg";
import Button from "../elements/Button/Button";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { AuthContext } from "../../context/AuthContext";
import Swal from "sweetalert2";

export default function ManageWorkspace() {
  const [workspacelist, setWorkspacelist] = useState([]);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const { token } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    getWorkspace();
  }, []);

  const getWorkspace = async () => {
    try {
      const serverURL = process.env.REACT_APP_SERVER_URL;
      const response = await fetch(`${serverURL}/company/fetchWorkspace`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });
      const data = await response.json();

      const decryptedData = JSON.parse(
        CryptoJS.AES.decrypt(
          data.data,
          process.env.REACT_APP_SECRETKEY
        ).toString(CryptoJS.enc.Utf8)
      );
      if (decryptedData) {
        setWorkspacelist(decryptedData);
        console.log("manage workspace", decryptedData);
        console.log("checking process", process.env);
      }
    } catch (error) {
      console.error("Error:", error);
      showNotification("Something went wrong.", "error");
    }
  };

  const handleWorkspaceClick = (workspace) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to change the workspace?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "No, cancel!",
    }).then(async (swalResult) => {
      if (swalResult.isConfirmed) {
        const { workspace_id, email, company_id, name } = workspace;

        const workspaceData = { workspace_id, email };

        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(workspaceData),
          process.env.REACT_APP_SECRETKEY
        ).toString();

        const requestData = { data: encryptedData };

        try {
          const serverURL = process.env.REACT_APP_SERVER_URL;
          const response = await fetch(
            `${serverURL}/company/updateActiveWorkspace`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                token: token,
              },
              body: JSON.stringify(requestData),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to update workspace");
          }

          const result = await response.json();

          const decryptedData = JSON.parse(
            CryptoJS.AES.decrypt(
              result.data,
              process.env.REACT_APP_SECRETKEY
            ).toString(CryptoJS.enc.Utf8)
          );

          if (decryptedData.message) {
            Swal.fire("Changed!", decryptedData.message, "success").then(() => {
              const encryptedMetadata = localStorage.getItem("metadata");
              if (encryptedMetadata) {
                const decryptedMetadata = JSON.parse(
                  CryptoJS.AES.decrypt(
                    encryptedMetadata,
                    process.env.REACT_APP_SECRETKEY
                  ).toString(CryptoJS.enc.Utf8)
                );

                decryptedMetadata.cid = company_id;
                decryptedMetadata.company_name = name;

                const encryptedCID = CryptoJS.AES.encrypt(
                  JSON.stringify(decryptedMetadata),
                  process.env.REACT_APP_SECRETKEY
                ).toString();

                localStorage.setItem("metadata", encryptedCID);
                navigate("/Dashboard");
              }
            });
          } else {
            Swal.fire(
              "Error!",
              "Something went wrong. Please try again.",
              "error"
            );
          }
        } catch (error) {
          console.error("Error:", error);
          Swal.fire("Error!", "An unexpected error occurred.", "error");
        }
      }
    });
  };

  const goToWorkspaceCreate = () => {
    navigate("/AddCompany");
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification({ message: "", type: "" });
    }, 3000); // Auto-hide after 3 seconds
  };

  const renderWorkspaces = (status) => {
    const [showMenu, setShowMenu] = useState(null);

    const filteredWorkspaces = workspacelist.filter(
      (workspace) => workspace.status === status
    );

    const handleMenuClick = (workspace_id) => {
      setShowMenu((prev) => (prev === workspace_id ? null : workspace_id));
    };

    const handleInactivateClick = (workspace) => {
      setShowMenu(false);

      const encryptedMetadata = localStorage.getItem("metadata");

      if (encryptedMetadata) {
        const decryptedMetadata = JSON.parse(
          CryptoJS.AES.decrypt(
            encryptedMetadata,
            process.env.REACT_APP_SECRETKEY
          ).toString(CryptoJS.enc.Utf8)
        );

        if (decryptedMetadata.cid === workspace.company_id) {
          Swal.fire({
            title: "Error!",
            text: `The workspace '${workspace.name}' is currently active. Please change the workspace before archiving it.`,
            icon: "error",
            confirmButtonText: "OK",
          });
          return;
        }
      }

      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to Archive the workspace "${workspace.name}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, archive it!",
        cancelButtonText: "No, cancel!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await inactivateWorkspace(workspace);
          Swal.fire("Archived!", "The workspace has been archived.", "success");
        } else {
          Swal.fire("Cancelled", "The workspace was not inactivated.", "info");
        }
      });
    };

    const inactivateWorkspace = async (workspace) => {
      const { workspace_id } = workspace;
      const status = "archive";
      const workspaceData = { status, workspace_id };
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(workspaceData),
        process.env.REACT_APP_SECRETKEY
      ).toString();

      const requestData = { data: encryptedData };

      try {
        const serverURL = process.env.REACT_APP_SERVER_URL;
        const response = await fetch(
          `${serverURL}/company/updateWorkSpaceStatus`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: token,
            },
            body: JSON.stringify(requestData),
          }
        );

        const result = await response.json();
        if (response.ok && result.data) {
          const decryptedData = JSON.parse(
            CryptoJS.AES.decrypt(
              result.data,
              process.env.REACT_APP_SECRETKEY
            ).toString(CryptoJS.enc.Utf8)
          );

          getWorkspace();
        } else {
          throw new Error("Failed to archived workspace");
        }
      } catch (error) {
        console.error("Error archive workspace:", error);
        Swal.fire(
          "Error!",
          "An unexpected error occurred while archive the workspace.",
          "error"
        );
      }
    };

    var workspace_length = filteredWorkspaces.length;
    return filteredWorkspaces.length > 0
      ? filteredWorkspaces.map((workspace) => (
          <div
            key={workspace.workspace_id}
            style={{
              padding: "30px",
              borderRadius: "20px",
              textAlign: "center",
              background: "white",
              color: "#6B7280",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
              cursor: "pointer",
              transition: "all 0.3s ease",
              width: "100%",
              position: "relative",
            }}
          >
            <div className="flex justify-between">
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "5.7px",
                  border: "1px",
                }}
              >
                {workspace.profile ? (
                  <img
                    src={`${process.env.REACT_APP_SERVER_BASEURL}/companyprofile/${workspace.profile}`}
                    className="w-12 h-12 border-1 border-gray-300 rounded-lg object-cover"
                    alt="Profile"
                  />
                ) : (
                  <img
                    className="w-12 h-12 border-1 border-gray-300 rounded-lg object-cover"
                    alt="Profile"
                  />
                )}
              </div>

              <div
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: "#F6FFFA",
                  width: "35px",
                  height: "35px",
                  borderRadius: "99px",
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() => handleMenuClick(workspace.workspace_id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="15"
                  height="15"
                  fill="black"
                >
                  <circle cx="5" cy="12" r="2"></circle>
                  <circle cx="12" cy="12" r="2"></circle>
                  <circle cx="19" cy="12" r="2"></circle>
                </svg>
              </div>

              {showMenu === workspace.workspace_id && (
                <div
                  className="absolute top-20 z-50 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  style={{ right: "5%" }}
                >
                  <ul className="w-full text-sm text-center text-gray-700 m-0 pr-6 hover:bg-gray-200">
                    {workspace.status === "archive" ? (
                      <li className="w-full"></li>
                    ) : workspace_length > 1 ? (
                      <li className="w-full">
                        <button
                          onClick={() => handleInactivateClick(workspace)}
                          className="block px-3 py-1.5 text-sm text-red-500 rounded-lg w-full text-center"
                        >
                          Archive
                        </button>
                      </li>
                    ) : (
                      <li className="w-full"></li>
                    )}
                  </ul>
                </div>
              )}
            </div>

            <div>
              <div className="flex items-start justify-start py-2 text-black font-bold text-base">
                {workspace.name || ""}
              </div>
              <hr className="text-black font-medium" />
            </div>

            <div>
              {workspace.status === "archive" ? (
                <div className="flex items-center justify-center font-medium text-green-600 text-base cursor-pointer">
                  Select
                </div>
              ) : (
                <div
                  className="flex items-center justify-center font-medium text-green-600 text-base cursor-pointer"
                  onClick={() => handleWorkspaceClick(workspace)}
                >
                  Select
                </div>
              )}
            </div>
          </div>
        ))
      : null;
  };

  const archivedWorkspaces = renderWorkspaces("archive");

  return (
    <div className="flex-col gap-4 mx-5 p-5 bg-white bg-opacity-50 rounded-t-2xl h-full">
      <Notification
        message={notification.message}
        type={notification.type}
        onClose={() => setNotification({ message: "", type: "" })}
      />

      <div className="flex items-center justify-center">
        <h2>Manage Workspaces</h2>
      </div>

      {/* Active Workspaces */}
      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 w-full">
        {renderWorkspaces("active")}
      </div>

      {archivedWorkspaces && (
        <>
          <div className="flex items-start justify-start py-2 mt-5 text-black font-bold text-base cursor-pointer">
            Archived Workspace
          </div>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 w-full opacity-50">
            {archivedWorkspaces}
          </div>
        </>
      )}

      <div className="flex justify-end" onClick={goToWorkspaceCreate}>
        <Button
          className="border-[1px] !border-green-700 !bg-transparent !rounded-full px-4 py-2 text-green-700"
          type="button"
        >
          Create +
        </Button>
      </div>
    </div>
  );
}
