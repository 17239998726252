import React, { createContext, useContext, useState } from "react";

const CommonContext = createContext();

export const CommonContextProvider = ({ children }) => {
  const [experience, setExperience] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isAIsearch, setIsAIsearch] = useState(true);
  const [showUpload, setShowUpload] = useState(false); 
  const [jobTitle, setJobTitle] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [aiSearchResult, setAiSearchResult] = useState({});
  const [section, setSection] =useState('');
  const [jobCreationData, setJobCreationData] = useState({
    jobid:"",
    company_id: "",
    jobTitle: jobTitle || "",
    company: "",
    workplaceType: "",
    jobLocation: "",
    jobType: "",
    description: jobDescription || "",
    selectedSkills: [],
    collaborators: [],
    team: [{name:"",designation:"",url:""}],
    questions: [],
    slots: {
    },
    schedule_config_days: "",
    override: {},
    interviewers: [],
    interview_location: "",
    interview_duration: "",
    timezone: ""

  });
  const [modalHtmlcontent, setModalHtmlContent] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  return (
    <CommonContext.Provider value={{ 
      experience, 
      setExperience,
      searchTerm,
      setSearchTerm,
      isAIsearch,
      setIsAIsearch,
      showUpload,
      setShowUpload,
      jobTitle,
      setJobTitle,
      jobDescription,
      setJobDescription,
      aiSearchResult,
      setAiSearchResult,
      jobCreationData,
      setJobCreationData,
      validationErrors,
      setValidationErrors,
      section,
      setSection,
      isModalVisible, 
      setIsModalVisible,
      modalHtmlcontent, 
      setModalHtmlContent
      }}>
      {children}
    </CommonContext.Provider>
  );
};

export const useCommonContext = () => useContext(CommonContext);
