import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
const GetAuthCode = () => {
    const [authCode, setAuthCode] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tokens, setTokens] = useState(null);
    const [firstCall, setFirstCall] = useState(false);
    const serverURL = process.env.REACT_APP_SERVER_URL;

    // Extract the 'code' from the URL query parameters
    useEffect(() => {
//	if(!firstCall){
        const queryParams = new URLSearchParams(window.location.search);
console.log(queryParams,"queryParams")
	    const code = queryParams.get('code');
console.log(code,"codedata")
        if (code) {
            setAuthCode(code);
       // if(!firstCall)
       const getLocalStorageJob_details = localStorage.getItem('job_create_event_details');
       if(getLocalStorageJob_details){
        const { job_id, job_desc, interviewer, date, time, attendees, title } = JSON.parse(getLocalStorageJob_details);
        console.log("Job Details : ",job_id,job_desc, interviewer, date, time, attendees, title);
        exchangeCodeForTokens(code,job_id, job_desc, interviewer, date, time, attendees, title);
        localStorage.removeItem('job_create_event_details');
       }
    //    setJob_id(getLocalstoragejob_id);
    //    console.log(getLocalstoragejob_id,"getLocalstoragejob_id");
	// 	exchangeCodeForTokens(code,getLocalstoragejob_id);
    //     localStorage.removeItem('job_id');
        } else {
            setError('Authorization code not found in the URL');
            localStorage.removeItem('job_create_event_details');
            setLoading(false);
        }
//	setFirstCall(true);
//	}
    }, []);

    // Function to exchange the authorization code for tokens
    const exchangeCodeForTokens = async (code,job_id, job_desc, interviewer, date, time, attendees, title) => {
        try {
            const response = await axios.post(`${serverURL}/gmeet/getTokens`, {
                code,
            });
		console.log(response,"response",response.data, process.env.REACT_APP_SECRETKEY)
          const decryptedData = JSON.parse(CryptoJS.AES.decrypt(response.data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
          console.log(decryptedData,"decryptedData")
            if (!decryptedData.refreshToken) {
		    console.log("no token");
              setError('Tokens not received');
            } else {
		    console.log("i");
              setTokens(decryptedData.refreshToken);
		    console.log("2")
              const createEventWithToken = async (refreshToken) => {
            
		      try {
                  const response = await axios.post(`${serverURL}/gmeet/createEvent`, {
                    refreshToken: refreshToken,
                    job_id : job_id,
                    interviewer: interviewer,
                    date: date,
                    time: time,
                    title: title,
                    description: job_desc,
                    attendees: attendees,
                  });
                  if (response.status === 200) {
                    window.location.href = '/joblisting';
                  }
                } catch (error) {
                  console.error('Failed to create event:', error);
                }
	      };
              createEventWithToken(decryptedData.refreshToken);
            }
		//setTokens(response.data.tokens);
        } catch (err) {
          console.log(err,"err")
		setError('Error exchanging code for tokens');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ textAlign: 'center', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            {loading && (
                <div>
                    <p style={{ color: '#555' }}>We’re setting things up for you...</p>
                    <div className="spinner" style={{ margin: '20px auto', width: '40px', height: '40px', border: '4px solid #ccc', borderTop: '4px solid #007BFF', borderRadius: '50%', animation: 'spin 1s linear infinite' }} />
                </div>
            )}
            {error && (
                <div>
                    <p style={{ color: 'red', fontWeight: 'bold' }}>Oops! Something went wrong.</p>
                    <p>Please try again or contact support if the issue persists.</p>
                </div>
            )}
            {tokens && (
                <div>
                    <h3 style={{ color: '#28a745', marginBottom: '10px' }}>Your Meeting Link is Ready!</h3>
                </div>
            )}
            {!tokens && !error && !loading && (
                <p style={{ color: '#6c757d' }}>No meeting link has been created yet.</p>
            )}
            <style>
                {`
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                `}
            </style>
        </div>
    );
    
};

export default GetAuthCode;

