import React, { useState ,useRef,useContext, useEffect} from "react";
import Topbar from '../../components/Recruiter/Topbar/Topbar'
import ButtonHallow from "../../components/elements/Button/ButtonHallow";
import SettingsIcon from '../../assets/setting.png';
import './ViewMessage.css';
import Textinput from "../../components/elements/TextInput/TextInput";
import Textarea from "../../components/elements/TextArea/TextArea";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import ClipLoader from "react-spinners/ClipLoader";
import CryptoJS from "crypto-js";
import Swal from 'sweetalert2';
const ViewMessage = () =>{
    const location = useLocation();
    const mailSubject=location.state.subject?location.state.subject:location.state.viewSubject;
    const senderMail=location.state.fromMail?location.state.fromMail:location.state.view;
    const recieverMail=location.state.toMail?location.state.toMail:location.state.viewSender;
    const candidatejobId=location.state.jobID?location.state.jobID:location.state.viewJID;
    const candidate_id=location.state.candidateID?location.state.candidateID:location.state.viewCID;
    console.log("inside smreply::",candidatejobId,candidate_id);
    const { token } = useContext(AuthContext);
    const navigate=useNavigate();
    const mid=location.state.mid;
    console.log("mid:",mid);
    const [BlockMessage1, setBlockMessage1] = useState("10 Yes, Email Tree.Ai Takes Data Security ...");
    const [BlockMessage2, setBlockMessage2] = useState("Yes, Email Tree.Ai Takes Data Security And Priority...");
    const [composingType , setComposingType] = useState("AI Reply Suggestions");
    const [subject, setSubject] = useState('');
    const [fromMail, setFromMail] = useState("");
    const [toMail, setToMail] = useState("");
    const [Suggestions,setSuggestion]=useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [senderInput,setFromInput]=useState(senderMail);
    const [recieverInput,setRecieverInput]=useState(recieverMail);
    const [jobIdValue,setJobIdValue]=useState(candidatejobId);
    const [candidateID,setCandidateId]=useState(candidate_id);

    const [templateText, setName] = useState('');
    const formcompany = useRef(null)
    const composingMethod = [
        "AI Reply Suggestions",
        "Templates"
    ];
    const [confidentLevel , setConfidentLevel] = useState("99%");
    const confidentLevelList = [
        "99%",
        "25%"
    ];
    const handleType = (value, type) => {
        if(type === 'composing'){
            setComposingType(value);
            value==="AI Reply Suggestions"?getAiTemplate(mailSubject):"";
            value==="Templates"?setName(''):"";
        } else if(type === 'confident'){
            setConfidentLevel(value);
        }
    }
    const handleBack=()=>{
        navigate(-1)
    }



    const getAiTemplate = async (mailSubject) =>{
            
                //   var userId;
                //           const encryptedMetadata = localStorage.getItem('metadata');
                //           if (encryptedMetadata){
                //               const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                //               if(decryptedMetadata.cid){
                //                   userId = Number(decryptedMetadata.uid);
                //               }
                //           }

                    try {
                        setIsLoading(true)
                        console.log("Making Email Content for :  ",mailSubject);
                        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(mailSubject), process.env.REACT_APP_SECRETKEY).toString();
                        
                        const serverURL = process.env.REACT_APP_SERVER_URL; 
                        const response = await fetch(`${serverURL}/Triage/getAiTemplates`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                token: token
                            },
                            body: JSON.stringify({ data: encryptedData }),
                        });
                        const result = await response.json();
                        
                        console.log("Server Response:", result);
                        
                        if (!response.ok) {
                            // setTriageResult([]);
                            throw new Error(result.error);
                            
                        }
                        
                        // const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                        console.log(result.data,'data for mail content')
                        if(response.ok){
                        //   setTriageResult(decryptedData);
                        setName(result.data.reply)
                        setSubject(result.data.subjectReply)
                      }
                    } catch (error) {
                        console.error("Error uploading file:", error);
                    }
                    finally{
                        setIsLoading(false)

                    }
                
    }
    useEffect(()=>{
        getAiTemplate(mailSubject)
    },[])

    const BackIcon = () => {
        return(<svg
            className="mr-2 text-emerald-700 cursor-pointer"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            onClick={handleBack}
          >
            <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm3 5.753l-6.44 5.247 6.44 5.263-.678.737-7.322-6 7.335-6 .665.753z"/>
      </svg>)
    }
    const GlobeIcon = () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="none"
        >
          <circle cx="12" cy="12" r="10" stroke="#059669" strokeWidth="2" fill="none" />
          <path
            d="M6.5 12c0-5 2.2-9 5.5-9s5.5 4 5.5 9-2.2 9-5.5 9-5.5-4-5.5-9z"
            stroke="#059669"
            strokeWidth="1.5"
            fill="none"
          />
          <path d="M3 12h18" stroke="#059669" strokeWidth="1.5" fill="none" />
          <path
            d="M12 2c2.76 2 4.5 5.13 4.5 10s-1.74 8-4.5 10-4.5-5.13-4.5-10S9.24 4 12 2z"
            stroke="#059669"
            strokeWidth="1.5"
            fill="none"
          />
        </svg>
      );

      const company_input = (e) => {
        const input = e.target.value;
        // if (input)
        //     formcompany.current.classList.remove("border-red-600");
        setName(input)
    }
    const subjectInput = (e) => {
        const input = e.target.value;
        // if (input)
        //     formcompany.current.classList.remove("border-red-600");
        setSubject(input)
    }
    const fromInput = (e) => {
        const input = e.target.value;
        // if (input)
        //     formcompany.current.classList.remove("border-red-600");
        setFromInput(input)
    }
    const toInput = (e) => {
        const input = e.target.value;
        // if (input)
        //     formcompany.current.classList.remove("border-red-600");
        setRecieverInput(input)
    }

    //   const handleTemplate = (e) => {
    //     const input = e.target.value;
    //     setText(input);
    
    //     if (input.trim()) {
    //       fetchSuggestion(input);
    //     } else {
    //       setSuggestion('');
    //     }
    //   };

    const setRepliedTrue = async(mid,value) =>{
    
        try{

            const replied={mid:mid,reply:value}
            console.log("replied:",replied);
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(replied), process.env.REACT_APP_SECRETKEY).toString();
            const serverURL = process.env.REACT_APP_SERVER_URL;
            const response = await fetch(
                `${serverURL}/Triage/setReplied`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    token: token,
                  },
                  body: JSON.stringify({data:encryptedData}),
                }
              );
          
              const data = await response.json();
              console.log(data,"response data");

        }
        catch(error){
            console.error("Error occurred:", error);
        }

    }

    const handleMailSend = async(mailSender,mailReciever,mailSubject,mailContent,j_id,c_id) =>{
                            const MailData = {
                                mailSender: mailSender,
                                mailReciever: mailReciever,
                                mailSubject:mailSubject,
                                mailContent:mailContent,
                                job_id:j_id,
                                db_cand_id:c_id            
                               };
                               console.log("jddata:",MailData)
                           
                               const encryptedData = CryptoJS.AES.encrypt(
                                 JSON.stringify(MailData),
                                 process.env.REACT_APP_SECRETKEY
                               ).toString();
                               const encryptjson = { data: encryptedData };


                               const userConfirmed = await Swal.fire({
                                               icon: "warning",
                                               title: `Are you sure to Send This Mail?`,
                                               text: "",
                                               confirmButtonText: "OK",
                                               showCancelButton: true,
                                             });
                                           
                                             if (!userConfirmed.isConfirmed) {
                                               console.log("User cancelled Mail Send.");
                                               return;
                                             }

                                setIsLoading(true);
                                             try {
                                               const response = await fetch(
                                                 `${process.env.REACT_APP_SERVER_URL}/Triage/setSmartReply`,
                                                 {
                                                   method: "POST",
                                                   headers: {
                                                     "Content-Type": "application/json",
                                                     token: token,
                                                   },
                                                   body: JSON.stringify(encryptjson),
                                                 }
                                               );
                                           
                                               const data = await response.json();
                                            //    if(!data.ok){
                                            //         throw new Error(data.error);
                                            //    }
                                               setRepliedTrue(mid,"true");
                                               const decryptedData = JSON.parse(
                                                 CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8)
                                               );
                                               
                                               console.log(decryptedData, "decrypt");
                                             } catch (error) {
                                               console.error("Error occurred:", error);
                                             }
                                             finally {
                                               setIsLoading(false);
                                                const confirmlocate=await Swal.fire({
                                                               icon:'success',
                                                               title: `Message Sent Successfully`,
                                                               text: "",
                                                               confirmButtonText: "OK",
                                                               showCancelButton: true,
                                                             });
                                                             if (confirmlocate) {
                                                                console.log("User cancelled Mail Send.");
                                                                navigate('/Triage')
                                                                return;
                                                             }
                                                
                                             }
                               

    }



    return(
        <div className="radial-gradient-bg">
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        <span className="text-white mt-4">Generating Mail Template please wait...</span>
                    </div>
                </div>
            )}
            <Topbar />
            <div className={`flex p-4`}>
                {/* left section */}
                <div className="w-[25%] p-4">
                    <h4>Initial Message - Response</h4>
                    <span className="mt-8 block">Compose Using</span>
                    <div className={`flex`}>
                        {composingMethod.map((value)=>(
                            <div className={`button-type ${composingType === value ? `active`: ``}`} onClick={() => handleType(value,"composing")}>{value}</div>
                        ))}
                    </div>
                    <span className="mt-4 block">Suggestions By Confidence Level</span>
                    <div className={`flex`}>
                        {confidentLevelList.map((value)=> (
                            <div className={`button-type ${confidentLevel === value ? `active`: ``}`} onClick={()=>handleType(value, "confident")}>{value}</div>
                        ))}
                    </div>
                    <span className="font-bold mt-8 block">Blocks</span>
                    <div className="flex" style={{padding: '10px 10px 10px 0'}}>
                        <span className="mild-colour">Add Or Remove Blocks In This List To Form Your Response</span>
                        <img src={SettingsIcon} className="max-h-[20px] w-auto flex"/>
                    </div>
                    <div className="flex mt-2 items-center justify-center">
                        <div className="w-[10%] p-2">
                            <div class="hamburger-menu">
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                            </div>
                        </div>
                        <div className="w-[80%]">
                            <div className="div-border flex flex-column">
                                <span className="font-bold truncate">{BlockMessage1}</span>
                                <span className="mild-colour truncate">{BlockMessage2}</span>
                            </div>
                        </div>
                        {/* main section */}
                        <div className="w-[10%]"></div>
                    </div>
                </div>
                <div className="w-[75%] bg-white rounded-xl bg-opacity-50 p-1 ml-6">
                        <div className="flex top-section-email p-4" style={{justifyContent: 'space-between'}}>
                            <div className="flex" style={{color: '#059669'}}><BackIcon />Back</div>
                            <div className="flex" style={{color: '#059669'}}><GlobeIcon />Translate Message</div>
                        </div>
                        <div className="p-8 second-section-subject">
                            <div className="flex mb-4 gap-[10px]" style={{justifyContent: 'space-between',alignItems: 'center'}}>
                                <Textinput
                                    label="Subject"
                                    divclass="w-[95%]"
                                    name="Subject"
                                    OnInput={subjectInput}
                                    value={subject}
                                    labelclass="font-medium !mb-0"
                                    className={`w-[90%] outline-none`}
                                />
                            </div>
                            <div className="flex gap-[10px]" style={{justifyContent: 'space-between',alignItems: 'center'}}>
                                <Textinput
                                    label="From"
                                    divclass="w-[95%]"
                                    name="From"
                                    OnInput={fromInput}
                                    value={senderInput}
                                    labelclass="font-medium !mb-0"
                                    className={`w-[90%] outline-none`}
                                />
                            </div>
                        </div>
                        <div className="p-8">
                            <div className="flex gap-[10px]" style={{justifyContent: 'space-between',alignItems: 'center'}}>
                                <Textinput
                                    label="To"
                                    divclass="w-full"
                                    name="To"
                                    OnInput={toInput}
                                    value={recieverInput}
                                    labelclass="font-medium !mb-0"
                                    className={`w-[90%] outline-none`}
                                />
                                <span className="mild-colour">CC|BCC</span>
                            </div>
                            <Textarea
                            className={`w-[90%] min-h-[200px] mt-4`}
                            OnInput={company_input}
                            value={templateText}
                            />
                        </div>
                        <div className="flex p-8 w-full" style={{justifyContent: 'flex-end'}}>
                            <ButtonHallow
                            content="Send and Learn"
                            onClick={()=>handleMailSend(senderInput,recieverInput,subject,templateText,jobIdValue,candidateID)}
                            className="w-auto"
                            />
                        </div>
                </div>
            </div>
        </div>
    )
}
export default ViewMessage;