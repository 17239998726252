import React, { useState } from 'react';

const LinkedInSignIn = () => {
  const [authUrl, setAuthUrl] = useState('');
  const clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_LINKEDIN_REDIRECT_URI;

  const generateAuthUrl = () => {
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&state=DCEeFWf45A53sdfKef424&scope=r_liteprofile%2Cr_emailaddress%2Copenid%2Cemail%2Cprofile&redirect_uri=${redirectUri}`;

	  console.log(url,"URL")
    setAuthUrl(url);
  };

  return (
    <div>
      <h2>Sign in with LinkedIn</h2>
      <button onClick={generateAuthUrl}>
        <a href={authUrl || '#'}>Login with LinkedIn</a>
      </button>
    </div>
  );
};

export default LinkedInSignIn;

