import React ,{useRef, useState,useEffect, useContext}from "react";
import Card from "../elements/Card/Card";
import Button from "../elements/Button/Button";
import Textinput from "../elements/TextInput/TextInput";
import { useCommonContext } from "../../context/CommonContext"; 
import Person from "../../assets/Images/person.jpg"
import Notification from "../Notification/Notification";
import ClipLoader from "react-spinners/ClipLoader";
import { AuthContext } from "../../context/AuthContext";
import SearchInput from "../elements/SearchInput/SearchInput";
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import { useLocation } from "react-router-dom";
const RecruiterSearch = () =>{
    const serverSocketUrl = process.env.REACT_APP_SERVERSOCKET_URL;
    const location = useLocation();
    const { searchTerm, setSearchTerm} = useCommonContext();
    const dropdownRefRecruit=useRef(null);
    const [searchDataRecruit,setSearchDataRecruit]=useState('');
    const [isResumeOpenRecruit, setIsResumeOpenRecruit] = useState(false);
    const [notification, setNotification] = useState({ message: "", type: "" });
    const searchvalRecruit = useRef(null);
    const { token } = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);
    const [ResumeDetailsRecruit,setResumeDetailsRecruit] = useState([]);  
    const [fitmentRecruiter,setFitmentRecruiter]=useState([]);
    const job_id = location.state?.jobId;
    const jobTitleRecruiter = location.state?.job_title;
    const [jobIdValue,setJobId]=useState(job_id);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearching, setSearchingDetails] = useState(false);
    const [Locations,setLocation] = useState(false);
    const [assessments,setSkill] = useState(false);
    const [Companies,setCompanies] = useState(false);
    const [Keywords,setKeywords] = useState(false);
    const [Degrees,setDegrees] = useState(false);
    const [viewResumeOpen,setViewResumeOpen] = useState(false);
    const [fitment,setFitment] = useState(false);
    const [locationArr,setLocationArr] = useState([]);
    const [skillArr,setSkillArr] = useState([]);
    const [companyArr,setCompanyArr] = useState([]);
    const [keywordArr,setKeywordArr] = useState([]);
    const [degreeArr,setDegreeArr] = useState([]);
    const [checkedValue, setcheckedValue] = useState({});
    const [isFiltered, setIsFiltered] = useState(false);
    const [seeAllText, setSeeAllText] = useState({'location': 'view all','job':'view all','skill':'view all','company':'view all','keyword':'view all','degree':'view all'});
    const [JDvalue,setJDvalue] = useState("");
    let scoreRecruiter=0;
    // const [updatedStatus,setUpdatedStatus]=useState(false);
    const [processingMessage, setProcessingMessage] = useState('No resume data available.');
    const showNotification = (message, type) => {   //For Notification
        setNotification({ message, type });
        setTimeout(() => {
          setNotification({ message: "", type: "" });
        }, 3000); // Auto-hide after 3 seconds
    };

      
        const toggleDropdownPipeline = () => {
          setIsOpen((prev) => !prev);
        };
        const toggleDropdownResumePipeline = (index) => {
          setIsResumeOpenRecruit((prev) => prev===index?null:index);
        };
    const [isOpenMessage, setIsOpenMessage] = useState(false);
      
        const toggleDropdownMessage = () => {
          setIsOpenMessage((prev) => !prev);
        };
    // const Locations=[
    //     "chennai",
    //     "kolkata",
    //     "Banglore",
    //     "kerala",
    //     "coimbatore",
    //     "Delhi"
    //   ]
    //   const assessments=[
    //     "Project Management",
    //     "Testing",
    //     "Programming",
    //     "Code Development",
    //     "Web Development",
    //     "System Design",
    //   ]
    //   const Companies=[
    //     "Amazon",
    //     "Amazon Logistics",
    //     "TCS",
    //     "ZOHO",
    //     "WIPRO",
    //     "Atlassian",
    //     "Google"
    //   ]
    
    //   const Keywords=[
    //     "Ananya (17)",
    //     "Jhon Doe",
    //     "micheal",
    //     "antony",
    //     "Raj",
    //     "David"
    //   ]
    //   const Degrees =[
    //     "B.Tech",
    //     "Master Degree",
    //     "Post Graduate",
    //     "Diploma"
    //   ]
       const Recruiting =[
         "Planning",
         "Searching",
         "Evaluation",
         "Progress"
       ]
      const [projects, setProjects] = useState([
        {
          id: 1,
          createdBy: "Ananya",
          postedDate: "24/09/2024",
          workplace: "Alchemy",
          jobTitle: "Senior UX Designer",
          status: "Active",
          pipelineCount: 17,
          applicants: { total: 15, new: 5 },
        },
        {
          id: 2,
          createdBy: "Jhon Doe",
          postedDate: "20/09/2024",
          workplace: "NovaByte Solutions",
          jobTitle: "Frontend Developer",
          status: "Inactive",
          pipelineCount: 10,
          applicants: { total: 8, new: 3 },
        },
        {
          id: 3,
          createdBy: "Sarah Connor",
          postedDate: "18/09/2024",
          workplace: "ZenithSoft Labs",
          jobTitle: "Backend Engineer",
          status: "Active",
          pipelineCount: 12,
          applicants: { total: 6, new: 2 },
        },
      ]);
      const[seeLocations,showMoreLocations]=useState(false)
      const [viewAllLocations, setViewAllLocations] = useState(false);
      const toggleState = () => {
        showMoreLocations((prevState) => !prevState);
        setViewAllLocations((prevState) => !prevState);
      };
      const[seeAssessments,showMoreAssessments]=useState(false)
      const [viewAllAssesment, setViewAllAssessment] = useState(false);
      const toggleAssesment = () => {
        showMoreAssessments((prevState) => !prevState);
        setViewAllAssessment((prevState) => !prevState);       
      };
      const[seeCompanies,showMoreCompanies]=useState(false)
      const[viewAllCompanies, setViewAllCompanies] = useState(false);
      const toggleCompany = () => {
        showMoreCompanies((prevState) => !prevState);
        setViewAllCompanies((prevState) => !prevState);
      };
      const[seeKeywords,showMoreKeywords]=useState(false)
      const [viewAllKeywords, setViewAllKeywords] = useState(false);
      const toggleKeywords = () => {
        showMoreKeywords((prevState) => !prevState);
        setViewAllKeywords((prevState) => !prevState);
        
      };
      const[seeDegree,showMoreDegrees]=useState(false)
      const [viewAllDegree, setViewAllDegree] = useState(false);
      const toggleDegree = () => {
        showMoreDegrees((prevState) => !prevState);
        setViewAllDegree((prevState) => !prevState);
      };
      const[seeRecruiting,showMoreRecruiting]=useState(false)
      const [viewAllRecruiting, setViewAllRecruiting] = useState(false);
      const toggleRecruiting = () => {
        showMoreRecruiting((prevState) => !prevState);
        setViewAllRecruiting((prevState) => !prevState);
      };
      const handleClickOutside = (event) => {
        if ((isResumeOpenRecruit && dropdownRefRecruit.current && !dropdownRefRecruit.current.contains(event.target))) {
          setIsResumeOpenRecruit(false);
            // setIsOpen(false);
            // setIsOpenMessage(false)
        }
        
    };
    
    
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen,isOpenMessage]);
    var isResumeDetailsRecruitEmpty = !ResumeDetailsRecruit || Object.keys(ResumeDetailsRecruit).length === 0;
      var totalResumeCountRecruit = 0;
      if(!isResumeDetailsRecruitEmpty){
          totalResumeCountRecruit = Object.keys(ResumeDetailsRecruit).length
      }
    
    const getRecruitResponse = async() =>{
            var jd_id = "";
            const encryptedMetadata = localStorage.getItem('metadata');
            if (encryptedMetadata){
                const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                if(decryptedMetadata.jd_id){
                    jd_id = decryptedMetadata.jd_id;
                }
            }
            console.log("search jd: ",jd_id);
            setJDvalue(jd_id); 
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({jd_id:jd_id,fromJob:true,job_id:job_id}), process.env.REACT_APP_SECRETKEY).toString();
            var searchDataRecruit = { data:encryptedData}
            
            console.log(searchDataRecruit,"searchDataRecruit");
            
            try {
                const serverURL = process.env.REACT_APP_SERVER_URL;
                const response = await fetch(`${serverURL}/JD/getJdDetail`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      token: token
                    },
                    body: JSON.stringify(searchDataRecruit), 
                });
                
                const result = await response.json();
                console.log("Server Response:", result);
                
                if (!response.ok) {
                    throw new Error(result.error);
                }
                
                const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                console.log('decryptedData',decryptedData);
                
                if(decryptedData && decryptedData['message']){
                    console.log("message:",decryptedData['message'])
                    setProcessingMessage(decryptedData['message'])
                    // return;
                }

                if(decryptedData && decryptedData[jd_id] && decryptedData[jd_id]["resumedetail"]){

                  if(decryptedData[jd_id]["resumedetail"].length === 0){
                    setProcessingMessage("No resume data available.");
                  }else{

                    console.log("resumeDetailsRecruit: ",decryptedData[jd_id]["resumedetail"])
                    
                    const recommendedStatus = decryptedData[jd_id]["resumedetail"]
                    console.log("resumestatus: ",recommendedStatus);
                    const keyval=Object.keys(decryptedData);
                    console.log(keyval.length,"diccc");


                    setResumeDetailsRecruit(decryptedData[jd_id]['resumedetail'])
                    setLocation(decryptedData[jd_id]?.['filter_location'] || []);
                    setSkill(decryptedData[jd_id]?.['filter_skill'] || []);
                    setCompanies(decryptedData[jd_id]?.['filter_company'] || []);
                    setKeywords(decryptedData[jd_id]?.['filter_keyword'] || []);
                    setDegrees(decryptedData[jd_id]?.['filter_degree'] || []);
                    setFitment(decryptedData[jd_id]?.['fitment'] || []);
                    if(decryptedData[jd_id] && decryptedData[jd_id]["fitment"] && decryptedData[jd_id]["fitment"]["resumes"]){
                      setFitmentRecruiter(decryptedData[jd_id]["fitment"]["resumes"]);
                    }
                    setSearchingDetails(false);
                    showNotification(result.message, "success");
                  }
                }
                
    
            } catch (error) {
                console.error("Error uploading file:", error);
                setProcessingMessage("No resume data available.");
                showNotification(error.message, "error");
            }finally {
                setIsLoading(false); // Always hide loader after the request
            }
          }
    

    const searchRecruitProject = async () => {
          isResumeDetailsRecruitEmpty = false;
        
          if(!searchTerm){
            showNotification("Please Enter Search Data", "error");
            return
          }
          setSearchingDetails(true);
          var userId = '';
          var cid = '';
          const encryptedMetadata = localStorage.getItem('metadata');
              if (encryptedMetadata){
                  const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                  if(decryptedMetadata.uid){
                    userId = decryptedMetadata.uid;
                  }
                  if(decryptedMetadata.cid){
                    cid = decryptedMetadata.cid;
                  }
              }
              console.log("ids: ",userId,cid);
          const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({ search:searchTerm,userId:userId,cid:cid }), process.env.REACT_APP_SECRETKEY).toString();
          var searchDataRecruit = { data:encryptedData}
          console.log("search data: ",searchDataRecruit);
          setIsLoading(true);
              try {
                  const serverURL = process.env.REACT_APP_SERVER_URL;
                  const response = await fetch(`${serverURL}/JD/searchResumeData`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        token: token
                      },
                      body: JSON.stringify(searchDataRecruit), 
                  });
                  const result = await response.json();
                  console.log("Server Response:", result);
                  if (!response.ok) {
                      throw new Error(result.error);
                  }
                  const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                  const encryptedMetadata = localStorage.getItem('metadata');
                  if (encryptedMetadata){
                    const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                    decryptedMetadata['jd_id'] = decryptedData.jd_id;
                    decryptedMetadata['search'] = searchTerm;
                    
                    const encryptedUpdatedMetadata = CryptoJS.AES.encrypt(JSON.stringify(decryptedMetadata), process.env.REACT_APP_SECRETKEY).toString();
                        localStorage.setItem('metadata', encryptedUpdatedMetadata);
                    }
                 
                  showNotification(result.message, "success");
                  getRecruitResponse();
              } catch (error) {
                  console.error("Error uploading file:", error);
                  showNotification(error.message, "error");
              }finally {
                  setIsLoading(false); // Always hide loader after the request
              }
          
        }
        const documentSvg=(<svg
          className="w-5 h-5 text-blue-500 mt-1 mr-2"
          fill="none" 
          stroke="#9effef"  
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
          />
        </svg>);
        const handlePipelineStatus = async(value,index,name,email,jobtitle,scoreData) =>{
              console.log("checking Recruiter Search")
              var scoreNew =  Math.round(parseFloat(scoreData));
              const JdData = {
                    resume_id: index.doc_id,
                    candidate_id: "",
                    job_id:jobIdValue,
                    type:value,
                    job_title:jobtitle,
                    name:name,
                    candidate_mail:email||null,
                    score:scoreNew
                  };
                  console.log("jddata_Recruiter:",JdData)
              
                  const encryptedData = CryptoJS.AES.encrypt(
                    JSON.stringify(JdData),
                    process.env.REACT_APP_SECRETKEY
                  ).toString();
                  const encryptjson = { data: encryptedData };
                  
                  const userConfirmed = await Swal.fire({
                    icon: "warning",
                    title: "Are you sure to add to the pipeline?",
                    text: "Once added, an email will be sent.",
                    confirmButtonText: "OK",
                    showCancelButton: true,
                  });
                
                  if (!userConfirmed.isConfirmed) {
                    console.log("User canceled pipeline addition.");
                    return;
                  }
                  
                  
                    try {
                      const response = await fetch(
                        `${process.env.REACT_APP_SERVER_URL}/job/addPipeline`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            token: token,
                          },
                          body: JSON.stringify(encryptjson),
                        }
                      );
                  
                      const data = await response.json();
                  
                      const decryptedData = JSON.parse(
                        CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8)
                      );
                      showNotification(
                        `Candidate added to pipeline as ${value}` ,
                        "success"
                      );
                      console.log(decryptedData, "decrypt");
                      getRecruitResponse();
                      setClicked(false);
                    } catch (error) {
                      console.error("Error occurred:", error);
                    }
                  
                  
            }
            const [trying_ws, setTrying_ws] = useState(false);
                const [clientConnected, setClientConnected] = useState(false);
                const socketClientRef = useRef(null); // Use a ref to persist WebSocket across re-renders
                let tryReconnect_ws = useRef(null); // Use ref for reconnect timer
                function connect_ws() {
                    console.log("in connect_ws")
                  console.log("Connecting...");
                    // Determine the WebSocket URL based on serverSocketUrl
                    const protocol = serverSocketUrl.indexOf("localhost") >= 0 ? "ws://" : "wss://";
                    const url = protocol + serverSocketUrl;
                    // Create WebSocket
                    socketClientRef.current = new WebSocket(url, "server-socket-protocol");
                    socketClientRef.current.onerror = function () {
                        console.log("Connection Error");
                    };
                    socketClientRef.current.onopen = function () {
                        console.log("WebSocket Client Connected");
                        clearTimeout(tryReconnect_ws.current);
                        setClientConnected(true);
                        let userId="";
                const encryptedMetadata = localStorage.getItem('metadata');
                if (encryptedMetadata){
                    const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                    console.log(decryptedMetadata,"decryptedMetadata")
                  if(decryptedMetadata.uid){
                let userId = decryptedMetadata.uid;
                    console.log(userId,"userIduserIduserId");
              const req = {
                            mode: "register-client",
                            userId:userId,// "user-id-goes-here", // to-do
                        };
                       console.log(req,"reqqqq")
                    socketClientRef.current.send(JSON.stringify(req));
                  }
                }
                //setUserIdCom(userId)
                console.log("userId",userId)
            //		if(userId == "" )
              //		return;
                // Register client
                       /* const req = {
                            mode: "register-client",
                            userId:userId,// "user-id-goes-here", // to-do
                        };
                        socketClientRef.current.send(JSON.stringify(req));
                   */
                   };
                    socketClientRef.current.onclose = function () {
                        console.log("Socket Client Closed");
                        setClientConnected(false);
                        if (!trying_ws) {
                            setTrying_ws(true);
                            tryReconnect_ws.current = setTimeout(() => {
                                console.log("Reconnecting WebSocket...");
                                connect_ws();
                            }, 2000);
                        }
                    };
                    socketClientRef.current.onmessage = function (e) {
                        try {
                            const data = JSON.parse(e.data);
                            if (
                                data &&
                                data.status === 1 &&
                                data.type === "response" &&
                                data.mode === "webhook"
                            ) {
                                // window.location.reload();
                                getRecruitResponse();
                            }
                        } catch (error) {
                            console.error("Error parsing WebSocket message:", error);
                        }
                    };
                }
                // Cleanup on unmount
                useEffect(() => {
                    connect_ws();
                    return () => {
                        if (socketClientRef.current) {
                            socketClientRef.current.close();
                        }
                        clearTimeout(tryReconnect_ws.current);
                    };
                }, []);
                const locationCheckboxChange = (id, isChecked) => {
                  console.log(id, isChecked);
                  setLocationArr(prevItems => {
                    if (isChecked) {
                      return [...prevItems, Locations[id]];
                    } else {
                      return prevItems.filter(item => item !== Locations[id]);
                    }
                  });
                }
                const skillCheckboxChange = (id, isChecked) => {
                  console.log(id, isChecked);
                  setSkillArr(prevItems => {
                    if (isChecked) {
                      return [...prevItems, assessments[id]];
                    } else {
                      return prevItems.filter(item => item !== assessments[id]);
                    }
                  });
                }
                const companyCheckboxChange = (id, isChecked) => {
                  console.log(id, isChecked);
                  setCompanyArr(prevItems => {
                    if (isChecked) {
                      return [...prevItems, Companies[id]];
                    } else {
                      return prevItems.filter(item => item !== Companies[id]);
                    }
                  });
                }
                const keywordCheckboxChange = (id, isChecked) => {
                  console.log(id, isChecked);
                  setKeywordArr(prevItems => {
                    if (isChecked) {
                      return [...prevItems, Keywords[id]];
                    } else {
                      return prevItems.filter(item => item !== Keywords[id]);
                    }
                  });
                }
                const degreeCheckboxChange = (id, isChecked) => {
                  console.log(id, isChecked);
                  setDegreeArr(prevItems => {
                    if (isChecked) {
                      return [...prevItems, Degrees[id]];
                    } else {
                      return prevItems.filter(item => item !== Degrees[id]);
                    }
                  });
                }
    useEffect(() => {
        setcheckedValue(prevText => ({
          ...prevText,
          locations: locationArr,skill:skillArr,companies:companyArr,keywords:keywordArr,degrees:degreeArr
        }));
    }, [locationArr,skillArr,companyArr,keywordArr,degreeArr]);
    
    useEffect(() => {
        filterData();
    }, [checkedValue]);
    
    useEffect(() => {
        setcheckedValue(prevText => ({
          ...prevText,
          jd_id: JDvalue
        }));
    }, [JDvalue]);

  const filterData = async () => {
    console.log("newwwwww")
    console.log(checkedValue,"checked");
    if(checkedValue.jd_id == ""){
      return;
    }
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(checkedValue), process.env.REACT_APP_SECRETKEY).toString();
      var searchData = { data:encryptedData}
      setIsLoading(true);
      try {
          const serverURL = process.env.REACT_APP_SERVER_URL;
          const response = await fetch(`${serverURL}/JD/filterResume`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                token: token
              },
              body: JSON.stringify(searchData), 
          });
          const result = await response.json();
          console.log("Server Response:", result);
          if (!response.ok) {
              throw new Error(result.error);
          }
          const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
          if(decryptedData['message']){
              setProcessingMessage(decryptedData['message'])
              return;
          }
         console.log(decryptedData,"diccc");
         // setAiSearchResult(decryptedData[JDvalue]);
          setResumeDetailsRecruit(decryptedData[JDvalue]['resumedetail']);
          setProcessingMessage("No resume data available.");
          setIsFiltered(true);
          // console.log(decryptedData)
          // showNotification(result.message, "success");
      } catch (error) {
          console.error("Error uploading file:", error);
          showNotification(error.message, "error");
      }finally {
          setIsLoading(false); // Always hide loader after the request
      }
    };
  


    return(
        <>
        {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        <span className="text-white mt-4">Fetching...</span>
                    </div>
                </div>
            )}
        <Notification
                message={notification.message}
                type={notification.type}
                onClose={() => setNotification({ message: "", type: "" })}
            />
            <div className="!w-[75%] gap-2 p-3 bg-white bg-opacity-50 rounded-xl">
                    <div>
                        <p className="mb-0 text-xl mt-4 font-[700]">Recruiter Search <span className="text-xs font-[400] text-[#747474] hidden">1.6k results</span></p>
                    </div>
            
            <div className="search-box-container mt-4" style={{height:'110px'}}>
              <SearchInput />
            </div>
            <div className="w-full">
              <div className="mx-auto">
                <Button
                  className="p-2 bg-green-600 text-white flex mx-auto"
                  onClick={() => {
                    if (!isSearching) {
                      searchRecruitProject();
                    }
                  }}
                >
                  {isSearching ? "Searching..." : <>{documentSvg} Search</>}
                </Button>
              </div>
            </div>
            <div className="flex gap-[32px] mt-4">
                        <div className="w-2/12 h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[20px] pb-[20px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`18`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto">Total Jobs</p>
                        </div>
                        <div className="w-2/12 h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[20px] pb-[20px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`21`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto">Total Resumes</p>
                        </div>
                        <div className="w-2/12 h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[14px] pb-[14px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`07`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto text-center">Open Jobs</p>
                        </div>
                        <div className="w-2/12 h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[20px] pb-[20px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`18`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto">Upcoming Interviews</p>
                        </div>
                        <div className="w-2/12 h-[100px] bg-[#F2F9F9] rounded-[17px] relative pt-[14px] pb-[14px] pl-[10px] pr-[10px] grid gap-[4px]">
                            <p className="mb-0 text-[26px] font-[700] mx-auto items-center text-[#059669]">{`1`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto text-center ">Candidates Contacted</p>
                        </div>
                    </div>
                    {isResumeDetailsRecruitEmpty && !isFiltered ? (
                        processingMessage == "Data Updation Processing" ? (
                            <div className="bg-white m-4 rounded-lg text-center py-32">
                              <p>Please wait while we process your request. Resume matching is in progress... Loading.. </p>
                                <ClipLoader color="#3498db" size={50} />
                            </div>
                        ):(
                            <div className="bg-white  m-4 rounded-lg text-center py-32">{processingMessage}</div>
                        )
                    ) : (
                      <div className="flex">
                      <div className="bg-white w-[30%] p-2  grid gap-[22px]">
                          <div className="flex px-4 mt-2"><p className="text-[21px] font-[700] mb-0">Filters</p>
                          </div>
                          {/* <Card CardFooterClass="px-6 py-2 bg-gray-100 justify-end gap-2 "  CardClass="min-w-md mx-auto  bg-white shadow-md rounded-lg overflow-hidden w-11/12   !rounded-xl"
                              body={<>
                              <Textinput placeholder="Search for a Project" labelclass="hidden" className="!w-full border-2 pl-10 pr-4 py-2.5 !border border-gray-150 rounded-md focus:outline-none focus:ring-2 text-black mt-1 focus:ring-cyan-200"/>
                              <Textinput labelclass="hidden"  placeholder="Search for a Project"  InputRef={searchvalRecruit}  className="!w-12/12 !border-none outline-none pl-7 pr-4 py-2.5" svgicon={documentSvg} inputPassPostClass="ml-auto w-4 mr-2 mt-2.5 hover:cursor-pointer hover:transform transition duration-150 hover:scale-105" divclass="flex border-2  border-[#BFE0E2]  w-11/12 rounded-lg"/>
                              <div className="absolute h-10 inset-y-[30%]  flex pt-1  pl-1">
                              <svg
                                className="w-5 h-5 text-blue-500"
                                fill="none" 
                                stroke="#9effef"  
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
                                />
                              </svg>
                              
                          </div>
                              </>}
                          /> */}
                          <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Location</p>
                    {
			Array.isArray(Locations) && Locations.length > 0 && (
                        Locations.slice(0,3).map((locationNew,index) => (
                          locationNew && locationNew != "" && locationNew != " " && (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex mt-2"
                            postlabel={locationNew}
                            postlabelclass="ml-2 leading-normal mb-0"
                            key={locationNew}
                            onChange={e => locationCheckboxChange(index, e.target.checked)}
                            />
                          )
                        ))
			)
                    }
                    
                    {seeLocations && Array.isArray(Locations) && Locations.length > 0 && (
                    Locations.slice(3).map((locationNew,index) => (
                      locationNew && locationNew != "" && locationNew != " " && (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex mt-2"
                        postlabel={locationNew}
                        postlabelclass="ml-2 leading-normal mb-0"
                        key={locationNew}
                        onChange={e => locationCheckboxChange(index + 3, e.target.checked)}
                        />
                    ))))}

			    {Array.isArray(Locations) && Locations.length > 3 && (
			<p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleState}>{seeAllText['location']}...</p>
			    )}
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Skills and assessments</p>
                    {
                       Array.isArray(assessments) && assessments.length > 0 && (
			assessments.slice(0,4).map((assessmentsNew,index) => (
        assessmentsNew && assessmentsNew != "" && assessmentsNew != " " && (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-5 h-5 mb-0 mt-1"
                            divclass="flex mt-2 items-start"
                            postlabel={assessmentsNew}
                            postlabelclass="ml-2 leading-normal mb-0"
                            key={assessmentsNew}
                            onChange={e => skillCheckboxChange(index, e.target.checked)}
                            />
                        ))))
                    }
                    
                    {seeAssessments && Array.isArray(assessments) && assessments.length > 0 && (
                    assessments.slice(4).map((assessmentsNew,index) => (
                      assessmentsNew && assessmentsNew != "" && assessmentsNew != " " && (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-5 h-5 mb-0 mt-1"
                        divclass="flex mt-2 items-start"
                        postlabel={assessmentsNew}
                        postlabelclass="ml-2 leading-normal mb-0"
                        key={assessmentsNew}
                        onChange={e => skillCheckboxChange(index + 4, e.target.checked)}
                        />
                    ))))}

                    {Array.isArray(assessments) && assessments.length > 4 && (
			    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleAssesment}>{seeAllText['skill']}...</p>
		    )}
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Companies</p>
                    {
                        Array.isArray(Companies) && Companies.length > 0 && (
			Companies.slice(0,2).map((company,index) => (
        company && company != "" && company != " " && (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-5 h-5 mb-0 mt-1"
                            divclass="flex mt-2 items-start"
                            postlabel={company}
                            postlabelclass="ml-2 leading-normal mb-0"
                            key={company}
                            onChange={e => companyCheckboxChange(index, e.target.checked)}
                            />
                        ))))
                    }
                    
                    {seeCompanies && Array.isArray(Companies) && Companies.length > 0 && (
                    Companies.slice(2).map((company,index) => (
                      company && company != "" && company != " " && (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-5 h-5 mb-0 mt-1"
                        divclass="flex mt-2 items-start"
                        postlabel={company}
                        postlabelclass="ml-2 leading-normal mb-0"
                        key={company}
                        onChange={e => companyCheckboxChange(index + 2, e.target.checked)}
                        />
                    ))))}

                    {Array.isArray(Companies) && Companies.length > 2 && (
			    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleCompany}>{seeAllText['company']}...</p>
		    )}
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Keywords</p>
                    {
                       Array.isArray(Keywords) && Keywords.length > 0 && (
			Keywords.slice(0,2).map((keyword,index) => (
        keyword && keyword != "" && keyword != " " && (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-5 h-5 mb-0 mt-1"
                            divclass="flex mt-2 items-start"
                            postlabel={keyword}
                            postlabelclass="ml-2 leading-normal mb-0"
                            key={keyword}
                            onChange={e => keywordCheckboxChange(index, e.target.checked)}
                            />
                        ))))
                    }
                    
                    {seeKeywords && Array.isArray(Keywords) && Keywords.length > 0 && (
                    Keywords.slice(2).map((keyword,index) => (
                      keyword && keyword != "" && keyword != " " && (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-5 h-5 mb-0 mt-1"
                        divclass="flex mt-2 items-start"
                        postlabel={keyword}
                        postlabelclass="ml-2 leading-normal mb-0"
                        key={keyword}
                        onChange={e => keywordCheckboxChange(index + 2, e.target.checked)}
                        />
                    ))))}

                    {Array.isArray(Keywords) && Keywords.length > 2 && (
			    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleKeywords}>{seeAllText['keyword']}...</p>
		    )}
                    
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Degrees</p>
                    {
                       Array.isArray(Degrees) && Degrees.length > 0 && (
			Degrees.slice(0,3).map((degree,index) => (
        degree && degree != "" && degree != " " &&(
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-5 h-5 mb-0 mt-1"
                            divclass="flex mt-2 items-start"
                            postlabel={degree}
                            postlabelclass="ml-2 leading-normal mb-0"
                            key={degree}
                            onChange={e => degreeCheckboxChange(index, e.target.checked)}
                            />
                        ))))
                    }
                    
                    {seeDegree && Array.isArray(Degrees) && Degrees.length > 0 && (
                    Degrees.slice(3).map((degree,index) => (
                      degree && degree != "" && degree != " " &&(
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-5 h-5 mb-0 mt-1"
                        divclass="flex mt-2 items-start"
                        postlabel={degree}
                        postlabelclass="ml-2 leading-normal mb-0"
                        key={degree}
                        onChange={e => degreeCheckboxChange(index + 3, e.target.checked)}
                        />
                    ))))}

                    {Array.isArray(Degrees) && Degrees.length > 3 && (
			    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleDegree}>{seeAllText['degree']}...</p>
		    )}
                              </>}
                          />
                          <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow-md rounded-lg overflow-hidden w-11/12   !rounded-xl !hidden"
                              body={<>
                              <p className="mb-0 font-medium">Recruiting</p>
                              {
                                  Recruiting.slice(0,3).map((degree) => (
                                      <Textinput
                                      type="checkbox"
                                      labelclass="hidden"
                                      className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                                      divclass="flex h-6 mt-2"
                                      postlabel={degree}
                                      postlabelclass="ml-2"
                                      key={degree}
                                      />
                                  ))
                              }
                              
                              {seeRecruiting &&
                              Recruiting.slice(3).map((degree,index) => (
                                  <Textinput
                                  type="checkbox"
                                  labelclass="hidden"
                                  className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                                  divclass="flex h-6 mt-2"
                                  postlabel={degree}
                                  postlabelclass="ml-2"
                                  key={degree}
                                  />
                              ))}

                              <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleRecruiting}>{viewAllRecruiting?"view less...":"view more..."}</p>
                              </>}
                          />
                          
                      </div>
                      <div className="w-[70%]">
                              <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 " CardClass="min-w-md mx-auto bg-white shadow rounded-lg no-max-width  overflow-hidden mt-4 mb-4 !rounded-xl shadow border-white w-full !hidden"
                          body={<>
                          <div className="flex">
                              <img src={Person} className="w-28 h-28 rounded-full object-cover"></img>
                              <div className="pl-2 absolute ml-6 inset-6 mt-14 h-9 bg-green-50 shadow-lg shadow-green-700  w-20 pb-1  rounded-md ">
                              <div className="relative size-6 mt-1">
                              <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">

                                  <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-neutral-700" strokeWidth="4"></circle>

                                  <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-teal-400 dark:text-blue-500" strokeWidth="4" stroke-dasharray="100" stroke-dashoffset="6" stroke-linecap="round"></circle>
                              </svg>
              
                              </div>
                
                  
                              <div className="absolute rounded-lg inset-2 ml-8 mt-2 mr-2 flex items-center  justify-center  text-gray-700 dark:text-white">
                                  
                                  <div className="h-14">
                                    <p className="mb-0 text-black font-medium mt-1 ml-3">{94}%</p>
                                    
                                  </div>
                              </div>
                              <p className="text-xs mb-0 absolute inset-y-4 ml-8">Match</p>
                              
                              </div>

                              <div className="ml-6">
                                  <p className="text-xl font-medium text-black mt-3 mb-0">Sharad Bhat</p>
                                  <p className="text-sm mb-0">UI/UX Designer</p>
                                  <p className="text-xs mt-1">Bengaluru,Karnataka</p>
                              </div>
                              <div>
                              <div className="relative inline-block text-left">
                <div className="ml-24 pl-3 mt-4">
                  <Button
                    type="button"
                    className="inline-flex border-[1px] border-[#059669] w-full justify-center gap-x-1.5 rounded-md bg-white px-3  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    id="menu-button"
                    aria-expanded={isOpen}
                    aria-haspopup="true"
                    onClick={toggleDropdownPipeline}
                  >
                    <p className="mt-2 mb-0 text-[#059669]">Save to pipeline</p>
                    <svg
                      className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-[#059669]"
                      viewBox="0 0 20 20"
                      fill="#059669"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Button>
                </div>
                {/* {filterOpen &&(
                  <ModalBox Modal_Body={ModalBody} Modal_Header={ModalHeader} modal_Close_Class="mt-2.5 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-6" Modal_Close_Func={handleCloseFilter} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-full !rouded-none !w-96 opacity-none overflow-y-auto" ModalTopClass="!items-right !justify-end "/>
                )}
                {saveProject &&(
                  <ModalBox Modal_Body={AnotherProject} Modal_Footer={SaveProjectFooter} Modal_Header={ProjectHeader} modal_Header_Text="text-2xl font-[500] mb-0" modal_Close_Class="mt-3 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-5" Modal_Close_Func={handleCloseProject} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-auto grid gap-[30px] p-[30px] !rouded-xl no-max-width !w-[719px] opacity-none overflow-y-auto"/>
                )}

                {isProjectCreation &&(
                  <ModalBox Modal_Body={<ProjectCreation onClose={handleCloseNewProject}/>}  modal_Close_Class="mt-3 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-5" Modal_Close_Func={handleCloseNewProject} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-auto grid gap-[30px] p-[30px] !rouded-xl no-max-width !w-[719px] opacity-none overflow-y-auto"/>
                )} */}
                
                {isOpen && (
                  <div
                    className="absolute left-24  z-10 w-8/12 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex="-1"
                    ref={dropdownRefRecruit}
                  >
                    <div className="p-1 space-y-0.5">
                <a className="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
                  Uncontacted
                </a>
                <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
                  Contacted
                </a>
                <a className="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
                  Replied
                </a>
                
              </div>
                  </div>
                )}
                {isOpenMessage && (
                  <div
                    className="absolute left-24  z-10 w-10/12 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex="-1"
                    ref={dropdownRefRecruit}
                  >
                    <div className="p-1 space-y-0.5">
                <a className="flex items-center gap-x-3.5  py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
                  Reject Candidate
                </a>
                <a className="flex items-center gap-x-3.5 py-2 px-3   text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
                  Hide Candidate
                </a>
                <a className="flex items-center gap-x-3.5 py-2 px-3   text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
                Add Note
                </a>
                {/* <a className="flex items-center gap-x-3.5 py-2 px-3   text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" onClick={handleSaveToProject} href="#">
                Save To Another Project
                </a> */}
                <a className="flex items-center gap-x-3.5 py-2 px-3   text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
                Share for Review
                </a>
                <a className="flex items-center gap-x-3.5 py-2 px-3   text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
                Save To Another Project
                </a>
                <a className="flex items-center gap-x-3.5 py-2 px-3   text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
                Save to PDF
                </a>
                <a className="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
                Find More People Like This
                </a>
                
              </div>
                  </div>
                )}
              </div>
              
                              </div>
                              <Button type="button" className="!bg-gray-100 inline-flex ml-3 h-9 mt-7 justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={toggleDropdownMessage}>
                          <img src="/image/message.png" className='w-5' />
                      </Button>
                      <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" className="inline-flex items-center p-1 h-5 mt-9  text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
          <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
          <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
          </svg>
          </button>






                          </div>
                          <div className="grid gap-[32px]">
                          <div className="mt-4 flex gap-[32px]">
                              <div className="w-[80px]">
                            <p className="ml-2 text-black mb-0 text-[12px] font-[500]">Experience</p></div>
                            <div>
                              <p className="text-xs opacity-[50%] mb-0 font-[500]">4+ years of experience in UX design</p>
                              <p className="text-black text-xs flex font-[400] mb-0"><span className="text-green-700 font-[700] mr-1">Senior UX Designer</span> at NovaByte Solutions <li className="ml-2 mb-0 text-gray-700">Current</li></p>
                              <p className="text-black text-xs font-[400] flex mb-0">UX Designer CodeSphere Technologies <li className=" mb-0 text-gray-700 ml-2">2021-2022</li></p>
                              <p className="text-black text-xs font-[400] flex mb-0">UX Designer BlueShift Dynamics <li className=" mb-0 text-gray-700 ml-2">2019-2021</li></p>
                            </div>
                          </div>
                          <div className="flex gap-[32px]">
                              <div className="w-[80px]">
                            <p className="ml-2 text-black mb-0 text-xs font-[500]">Education</p></div>
                            <div>
                              
                              <p className="text-black flex text-xs mb-0">Bachelor of Design, Srishti Manipal Institute<li className=" mb-0 text-gray-700 ml-2">2014-2017</li></p>
                              <p className="text-black flex text-xs mb-0">Masters Strategic Design Management, NID<li className=" mb-0 text-gray-700 ml-2">2017-2019</li></p>
                            </div>
                          </div>
                          <div className="flex gap-[32px]">
                          <div className="w-[80px]">
                            <p className="ml-2 text-xs mb-0 font-[500] text-black">Skills Match</p></div>
                            <div>
                              
                              <p className="flex text-xs text-green-700 mb-0 font-medium">User Research <li className="text-xs ml-2">User-Centered Design Thinking</li><li className="ml-2">Problem-solving</li></p>
                            </div>
                          </div>
                          <div className="flex mb-4 gap-[32px]">
                              <div className="w-[80px]">
                            <p className="ml-2 text-black mb-0 text-xs font-[500]">Activity</p></div>
                            <div className="w-[381px] justify-between">
                              
                              <p className="text-black flex text-xs mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, Masters StrLorem ipsum dolor sit amet, consectetur adipiscing elit, ategic Design Management, NID</p>
                              
                            </div>
                          </div>
                          </div>
                          </>}
                          />

                  
                    { Object.entries(ResumeDetailsRecruit).map(([project, index]) => (
                    <Card CardFooterClass=" !bg-[#059669] !rounded-l-none w-4/12 ml-auto flex justify-end" CardClass="min-w-md mx-auto pl-2 bg-white shadow rounded-lg overflow-hidden mt-4 mb-4 !rounded-3xl shadow-none border w-12/12"
                    body={
                      <>
                      <div className="flex">
                      <div className="w-[50%]">
                      <p className="text-[24px] font-medium mb-0">{ResumeDetailsRecruit[project] && ResumeDetailsRecruit[project]['candidate'] && ResumeDetailsRecruit[project]['candidate']['name']?ResumeDetailsRecruit[project]['candidate']['name']:"Name Not Provided"}</p>
                      {fitmentRecruiter.forEach((fitmentRecruiter, index) => {
                          if (fitmentRecruiter.resume_id == ResumeDetailsRecruit[project]['doc_id']) {

                            scoreRecruiter = fitmentRecruiter.score;
                          }
                        })}
            <div className="pl-2 relative ml-6   h-9 bg-white mt-1 w-20 pb-1  rounded-md ">
                    <div className="relative size-6 mt-1">
                    <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-neutral-700" strokeWidth="4"></circle>

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-teal-400 dark:text-blue-500" strokeWidth="4" stroke-dasharray="100" stroke-dashoffset={100 - Math.floor(scoreRecruiter*100)} stroke-linecap="round"></circle>
                    </svg>
    
                    </div>
      
         
                    <div className="absolute rounded-lg inset-2 ml-8 mt-2 mr-2 flex items-center  justify-center  text-gray-700 dark:text-white">
                        
                        <div className="h-14">
                          <p className="mb-0 text-black font-medium mt-1 ml-3">{scoreRecruiter*100}%</p>
                          
                        </div>
                    </div>
                    <p className="text-xs mb-0 absolute inset-y-4 ml-8">Match</p>
                    
                    </div>
                      <p className="text-[14px]">{ResumeDetailsRecruit[project] && ResumeDetailsRecruit[project]['candidate'] && ResumeDetailsRecruit[project]['candidate']['objective']?ResumeDetailsRecruit[project]['candidate']['objective']:"Objective Not Provided"}</p>
                      <p className="text-[16px] font-semibold mb-0">Contact Details</p>
                      
                      <p className="flex text-[16px]"><div className="flex">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" className="mt-2 mr-2" viewBox="0 0 15 11" fill="none">
                    <path d="M14 2V9C14 9.39782 13.842 9.77936 13.5607 10.0607C13.2794 10.342 12.8978 10.5 12.5 10.5H2.5C2.10218 10.5 1.72064 10.342 1.43934 10.0607C1.15804 9.77936 1 9.39782 1 9V2M14 2C14 1.60218 13.842 1.22064 13.5607 0.93934C13.2794 0.658035 12.8978 0.5 12.5 0.5H2.5C2.10218 0.5 1.72064 0.658035 1.43934 0.93934C1.15804 1.22064 1 1.60218 1 2M14 2V2.162C14 2.4181 13.9345 2.66994 13.8096 2.89353C13.6848 3.11712 13.5047 3.30502 13.2867 3.43933L8.28667 6.516C8.05014 6.66169 7.7778 6.73883 7.5 6.73883C7.2222 6.73883 6.94986 6.66169 6.71333 6.516L1.71333 3.44C1.49528 3.30569 1.31525 3.11779 1.19038 2.8942C1.06551 2.6706 0.999972 2.41876 1 2.16267V2" stroke="#059669" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                                  
                                  <p className="mt-[1px]">{ResumeDetailsRecruit[project] && ResumeDetailsRecruit[project]['candidate'] && ResumeDetailsRecruit[project]['candidate']['email']?ResumeDetailsRecruit[project]['candidate']['email']:"Email Not Provided"}</p></div>
                    <div className="flex ml-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="mt-1.5 mr-2" width="15" height="15" viewBox="0 0 15 15" fill="none">
                    <path d="M1 4C1 9.52267 5.47733 14 11 14H12.5C12.8978 14 13.2794 13.842 13.5607 13.5607C13.842 13.2794 14 12.8978 14 12.5V11.5853C14 11.2413 13.766 10.9413 13.432 10.858L10.4833 10.1207C10.19 10.0473 9.882 10.1573 9.70133 10.3987L9.05467 11.2607C8.86667 11.5113 8.542 11.622 8.248 11.514C7.15659 11.1128 6.16544 10.4791 5.34319 9.65681C4.52094 8.83457 3.88725 7.84341 3.486 6.752C3.378 6.458 3.48867 6.13333 3.73933 5.94533L4.60133 5.29867C4.84333 5.118 4.95267 4.80933 4.87933 4.51667L4.142 1.568C4.10143 1.4058 4.00781 1.2618 3.87604 1.15889C3.74426 1.05598 3.58187 1.00006 3.41467 1H2.5C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V4Z" stroke="#059669" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                      <p className="whitespace-nowrap">{ResumeDetailsRecruit[project] && ResumeDetailsRecruit[project]['candidate'] && ResumeDetailsRecruit[project]['candidate']['phone_number']?ResumeDetailsRecruit[project]['candidate']['phone_number']:"Phone No Not Provided"}</p>
                      </div></p></div>
                        <div className="ml-auto">
                          <div className="flex">
                        <div className="pl-8">
                        {ResumeDetailsRecruit[project]["pipeline_type"]?
                        <p className="text-lg mt-1 mr-4 text-green-700">{ResumeDetailsRecruit[project]["pipeline_type"]}</p>:(
                        <Button
                          type="button"
                          className="inline-flex w-[190px] justify-center gap-x-1.5 rounded-md bg-white px-3 border-green-500  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          id="menu-button"
                          aria-expanded={isOpen}
                          aria-haspopup="true"
                          onClick={()=>toggleDropdownResumePipeline(index)}
                        >
                          <p className="mt-2 mb-0 text-[14px] font-medium text-green-600">Save to pipeline</p>
                          <svg
                            className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-green-600"
                            viewBox="0 0 20 20"
                            fill="green"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </Button>)}
                      
                      

                      
                      {isResumeOpenRecruit===index && (
                        <div
                          className="absolute left-34  z-10 !w-[190px] mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="menu-button"
                          tabIndex="-1"
                          ref={dropdownRefRecruit}
                        >
                          <div class="p-1 space-y-0.5">
                      <a class="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3 hover:cursor-pointer  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline"  onClick={()=>handlePipelineStatus("uncontacted",index,ResumeDetailsRecruit[project]['candidate']['name'],ResumeDetailsRecruit[project]['candidate']['email'],jobTitleRecruiter,scoreRecruiter*100)}>
                        Uncontacted
                      </a>
                      <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100 hover:cursor-pointer text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline"  onClick={()=>handlePipelineStatus("contacted",index,ResumeDetailsRecruit[project]['candidate']['name'],ResumeDetailsRecruit[project]['candidate']['email'],jobTitleRecruiter,scoreRecruiter*100)}>
                        Contacted
                      </a>
                      <a class="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:cursor-pointer hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline"  onClick={()=>handlePipelineStatus("Replied",index,ResumeDetailsRecruit[project]['candidate']['name'],ResumeDetailsRecruit[project]['candidate']['email'],jobTitleRecruiter,scoreRecruiter*100)}>
                        Replied
                      </a>
                      
                    </div>
                        </div>
                        )}
                        </div>
                        <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" className="inline-flex items-center p-1 h-3 ml-2   text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600 hidden" type="button">
                            <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width={20}
                                    height={20}
                                    className="ml-auto mt-[30px]"
                                    fill="black"
                                    aria-hidden="true"
                                    >
                                    <circle cx="5" cy="12" r="2" />
                                    <circle cx="12" cy="12" r="2" />
                                    <circle cx="19" cy="12" r="2" />
                                    </svg>
                    </button>
                    </div>

                        </div>
                      </div>
                      </>
                    }
                    footer={
                      <>
                      <p className="mb-0 text-white">From Resumebox</p>
                      </>
                    }
                    />
                    ))}
                    



                      </div>
                      </div>)}
            </div>
        </>
    )
}
export default RecruiterSearch;
