// Basic imports
import React from 'react';
import Logo from '../components/Common/Logo';
import LinkedInSignIn from '../components/LinkedInSignIn/LinkedInSignIn';

const LinkedInSignInI = () => {
        return (
                <div className="radial-gradient-bg">
                        <div className='ml-4 absolute'><Logo /></div>
                        <div className="signup-page">
                	<LinkedInSignIn />        
		</div>
                </div>
        );
};

export default LinkedInSignInI;
