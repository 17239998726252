// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
    .mobile-flex-column {
        flex-direction: column;
    }
    .center-container {
        height: 100%;
    }
    .mobile-grid-two-col {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
        gap: 10px;
    }
    .container-green {
        width: 100%;
        height: 130px;
    }
    .container-green h2{
        font-size: 30px;
    }
    .container-green p {
        font-size: 15px;
    }
    .search-box-container {
        height: 100% !important;
    }
    div.search-box{
        width: auto;
        margin-bottom: 10px;
        padding: 10px !important;
        display: none;
    }
    .search-box {
        width: auto;
        border-left: 3px solid rgba(52, 211, 153, 0.9) !important;
        border-radius: 10px !important;
    }
    .mic-icon-div{
        height: 30px;
        width: 30px;
        top: 10px;
    }

    .upload-icon-div{
        height: 30px;
        width: 30px;
        top: 10px;
    }
    .card-center, .card-right {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard.css"],"names":[],"mappings":"AAAA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,YAAY;IAChB;IACA;QACI,aAAa;QACb,qCAAqC,EAAE,iCAAiC;QACxE,SAAS;IACb;IACA;QACI,WAAW;QACX,aAAa;IACjB;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;IACA;QACI,uBAAuB;IAC3B;IACA;QACI,WAAW;QACX,mBAAmB;QACnB,wBAAwB;QACxB,aAAa;IACjB;IACA;QACI,WAAW;QACX,yDAAyD;QACzD,8BAA8B;IAClC;IACA;QACI,YAAY;QACZ,WAAW;QACX,SAAS;IACb;;IAEA;QACI,YAAY;QACZ,WAAW;QACX,SAAS;IACb;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":["@media only screen and (max-width: 600px) {\n    .mobile-flex-column {\n        flex-direction: column;\n    }\n    .center-container {\n        height: 100%;\n    }\n    .mobile-grid-two-col {\n        display: grid;\n        grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */\n        gap: 10px;\n    }\n    .container-green {\n        width: 100%;\n        height: 130px;\n    }\n    .container-green h2{\n        font-size: 30px;\n    }\n    .container-green p {\n        font-size: 15px;\n    }\n    .search-box-container {\n        height: 100% !important;\n    }\n    div.search-box{\n        width: auto;\n        margin-bottom: 10px;\n        padding: 10px !important;\n        display: none;\n    }\n    .search-box {\n        width: auto;\n        border-left: 3px solid rgba(52, 211, 153, 0.9) !important;\n        border-radius: 10px !important;\n    }\n    .mic-icon-div{\n        height: 30px;\n        width: 30px;\n        top: 10px;\n    }\n\n    .upload-icon-div{\n        height: 30px;\n        width: 30px;\n        top: 10px;\n    }\n    .card-center, .card-right {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
