import React from "react";
const Overview = () =>{
    return(
        <div className="flex gap-[16px]">
                        <div className="w-[177px] h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[20px] pb-[20px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`18`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto">Total Jobs</p>
                        </div>
                        <div className="w-[177px] h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[20px] pb-[20px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`21`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto">Total Resumes</p>
                        </div>
                        <div className="w-[177px] h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[14px] pb-[14px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`07`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto text-center">Open Jobs</p>
                        </div>
                        <div className="w-[177px] h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[20px] pb-[20px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`18`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto">Upcoming Interviews</p>
                        </div>
                        <div className="w-[177px] h-[100px] bg-[#F2F9F9] rounded-[17px] relative pt-[14px] pb-[14px] pl-[10px] pr-[10px] grid gap-[4px]">
                            <p className="mb-0 text-[26px] font-[700] mx-auto items-center text-[#059669]">{`1`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto text-center ">Candidates Contacted</p>
                        </div>
                    </div> 
    );
}
export default Overview