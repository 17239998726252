import React, { useContext, useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import './Calendar.css'
import { AuthContext } from '../../context/AuthContext';
import CryptoJS from 'crypto-js';
import ModalBox from '../elements/ModalBox/ModalBox';
const RightArrow = () => {
    return (
      <svg fill="#059669" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330">
        <path d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z"/>
      </svg>
    );
  };
const LeftArrow = () => {
    return(
        <svg fill="#059669" height="24px" width="24px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330" transform="matrix(-1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z"></path> </g></svg>);
};

const Calendar = () => {
    const { token } = useContext(AuthContext);
    const [calendatData, setCalendatData] = useState([]);
    const [rawData, setRawData] = useState([]);
    const [meetingDates, setMeetingDates] = useState([]);
    useEffect(()=>{
        const fetchAPI = async () => {
            const encryptedMetadata = localStorage.getItem('metadata');
            const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            const companyId = decryptedMetadata.cid;
            const JLData = {
                company_id: companyId,
                year: 2024,
              };
          
              const encryptedData = CryptoJS.AES.encrypt(
                JSON.stringify(JLData),
                process.env.REACT_APP_SECRETKEY
              ).toString();
              const encryptjson = { data: encryptedData };
            try {
                const serverURL = process.env.REACT_APP_SERVER_URL;
                const response = await fetch(`${serverURL}/job/getScheduleCalendarData`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      token: token
                    },
                    body: JSON.stringify(encryptjson)
                });
                const responsedata = await response.json();
        
                const decryptedData = JSON.parse(CryptoJS.AES.decrypt(responsedata.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                // console.log(decryptedData);
                if (!response.ok) {
                    var errorMessage = "Something Went Wrong";
                    if(responsedata.error)
                        errorMessage = responsedata.error;
                    throw new Error(responsedata.error);
                }
                else{
                    const formatTime = (time) => {
                        const [hour, minute] = time.split(':').map(Number);
                        const period = hour >= 12 ? 'pm' : 'am';
                        const formattedHour = hour % 12 || 12;
                        return `${formattedHour}:${minute.toString().padStart(2, '0')} ${period}`;
                      };
                  
                      // Transform data
                      const rawData = Array.isArray(decryptedData) ? decryptedData : [];
                      console.log(rawData);
                      const formattedEvents = Array.isArray(decryptedData)
                        ? decryptedData.map((item, index) => ({
                            id: index, // Unique ID
                            title: `${formatTime(item.start_time)} - ${formatTime(item.end_time)}`,
                            start: `${new Date(item.schedule_date).toISOString().split('T')[0]}T${item.start_time}`,
                            desc: item.job_title
                          }))
                        : [];
                        setMeetingDates(formattedEvents.map((index)=> new Date(index.start).toISOString().split('T')[0]));
                    setCalendatData(formattedEvents);
                    setRawData(rawData);
                }
            } catch (error) {
                console.log('Error:', error);
                let errorMessage = "Something went wrong.";
                if (error.response && error.response.data && error.response.data.msg) {
                    errorMessage = error.response.data.msg;
                } 
                else if (error.message) {
                    errorMessage = error.message;
                }
            } finally {
            //   setIsLoadingLoader(false);
            }
        }
        fetchAPI();
    },[])
  const calendarRef = useRef(null); 
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalEvents, setModalEvents] = useState([]);


  const handlePrevMonth = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev(); 
    setCurrentDate(new Date(calendarApi.getDate())); 
  };

  const handleNextMonth = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next(); 
    setCurrentDate(new Date(calendarApi.getDate())); 
  };

  const handleDateClick = (info) => {
    console.log(info);
  };

  const handleEventClick = (info) => {
    const { event } = info;
    setModalEvents([{
      id: event.id,
      title: event.title,
      desc: event.extendedProps.desc,
    }]);
    setIsModalOpen(true);
  };

  const handleModalOpen = (events) => {
    // console.log(events);
    setModalEvents(events);
    setIsModalOpen(true);
};

const handleModalClose = () => {
    setIsModalOpen(false);
    setModalEvents([]);
};
const [eventDate, setEventDate] = useState([]);
const [dayDate, setDayDate] = useState([]);
const renderDayContent = (day) => {
  // console.log(new Date(day.date).toISOString().split("T")[0]);
  // console.log(calendatData.map((event) => (new Date(event.start).toISOString().split("T")[0])));
  const toIST = (date) => {
    const istOffset = 330; // IST is UTC +5:30
    const localTime = new Date(date).getTime();
    const istTime = new Date(localTime + istOffset * 60 * 1000);
    return istTime.toISOString().split("T")[0];
  };

  const dayDateIST = toIST(day.date);
  const dayEvents = calendatData.filter((event) => {
    const eventDateIST = toIST(event.start);
    return eventDateIST === dayDateIST;
  });
//   const dayEvents = calendatData.filter((event) => {
//     const eventDateIST = new Date(event.start).toISOString().split("T")[0];
//     const dayDateIST = new Date(day.date).toISOString().split("T")[0];
//     if (eventDateIST === dayDateIST) {
//       return true;
//   }
//   return false;
// });


  return (
      <div className="relative h-full w-full">
          {/* <div className="absolute top-1 right-1">
              <button
                  className="refresh-button text-blue-500 text-xs"
                  onClick={() => alert(`Refresh clicked for ${day.dateStr}`)}
              >
                  🔄
              </button>
          </div> */}
          <div className="flex flex-col items-start p-2">
              <div className="text-md">{day.dayNumberText}</div>
              {dayEvents.length >= 3 && (
                  <button
                      className="more-events-button text-blue-500 text-sm mt-2"
                      onClick={() => handleModalOpen(dayEvents)}
                  >
                      ↓ More
                  </button>
              )}
          </div>
      </div>
  );
};

const renderDayTopContent = (day) => {
  return (
      <div className="relative w-full">
          <span className="text-xl font-bold">{day.dayNumberText}</span>
          <button
              className="refresh-button absolute top-1 right-1 text-blue-500 text-xs"
              onClick={() => alert(`Refresh clicked for ${day.dateStr}`)}
          >
              🔄
          </button>
      </div>
  );
};
const showdate = () => {
  console.log("EventDate :",eventDate)
  console.log("Day Date : ",dayDate)
}


const processCalendarData = (events) => {
  const groupedEvents = events.reduce((acc, event) => {
    const eventDate = event.start.split('T')[0];
    acc[eventDate] = acc[eventDate] || [];
    acc[eventDate].push(event);
    return acc;
  }, {});

  const filteredEvents = Object.values(groupedEvents).flatMap((dateEvents) =>
    dateEvents.length > 3 ? dateEvents.slice(0, 3) : dateEvents
  );

  return filteredEvents;
};

const slicedEvents = processCalendarData(calendatData);


  return (
    <div className="h-screen bg-transparent border-none mt-2 pt-2">

            {isModalOpen && (
              <ModalBox modal_Body_Class={`h-auto bg-white`} Modal_Close_Func={handleModalClose} Modal_Body={(<div className="">
                <div className="modal-content p-4 no-scrollbar" style={{maxHeight: '90vh',overflow: 'scroll'}}>
                  <h3 className="text-xl font-semibold mb-4">Event Details</h3>
                  <div className="space-y-4">
                    {modalEvents.map((event) => (
                      <div
                        key={event.id}
                        className="border rounded-lg p-4 bg-gray-50 shadow-md hover:shadow-lg transition-shadow duration-200"
                      >
                        <h4 className="text-lg font-bold text-gray-700">{event.desc}</h4>
                        <p className="text-sm text-gray-500 mt-2">Time : {event.title}</p>
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={handleModalClose}
                    className="mt-6 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors duration-200"
                  >
                    Close
                  </button>
                </div>
                  {/* <div className="modal-content">
                      <h3>Even</h3>
                      <ul>
                          {modalEvents.map((event) => (
                              <li key={event.id}>{event.desc}{event.title}</li>
                          ))}
                      </ul>
                      <button onClick={handleModalClose} className="close-button">
                          Close
                      </button>
                  </div> */}
              </div>)}/>  
            )}
      {/* Custom Toolbar */}
      <div className="flex items-center justify-start mb-2">
        
        <button onClick={handlePrevMonth} className="cursor-pointer">
        <LeftArrow />
        </button>
        <h2 className="mx-4 text-xl">
          {currentDate.toLocaleString('default', { month: 'long' })}{' '}
          {currentDate.getFullYear()}
        </h2>
        <button onClick={handleNextMonth} className="cursor-pointer">
        <RightArrow />
        </button>
      </div>

      <FullCalendar
        ref={calendarRef} 
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        initialDate={currentDate}
        events={slicedEvents}
        // dateClick={handleDateClick}
        eventClick={handleEventClick}
        headerToolbar={false} 
        dayCellContent={(day) => renderDayContent(day)}
        contentHeight="auto"
        className="bg-white rounded-lg shadow-md"
      />
    </div>
  );
};

export default Calendar;
